// @ts-nocheck

import { Menu, Transition } from "@headlessui/react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { Fragment, useState } from "react";
import { ImSpinner2, ImSpinner3 } from "react-icons/im";
import { IoMdMore } from "react-icons/io";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import {
  delectS3Object,
  deleteSingleMenu,
  downloadStoreItemCount,
  downloadStoreItems,
  handleUpdateStoreItemCount,
} from "../../../../../../../api/Menu";

import { getImage } from "../../../../../../../utils";
import Modal from "../../../../../../../components/Dashboard/Modal";
import { Link } from "react-router-dom";

const AllItemsNew = ({
  setProductSidePanelStep,
  setSelectedObject,
  selectedObject,
}) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  let allItems: any[] = [];
  let storeItemCount: 0;
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const getStoreItems = useInfiniteQuery<any, Error>({
    queryKey: ["getStoreItems"],
    queryFn: async ({ pageParam = "" }) => {
      const paginationKey = pageParam;
      const response = await downloadStoreItems(queryKey, paginationKey);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      // console.log({lastPage});
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
  });

  const getStoreItemCount = useQuery<any, Error>({
    queryKey: ["getStoreItemCount"],
    queryFn: async () => {
      const response = await downloadStoreItemCount(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      
    }
  });

  if (getStoreItemCount.data){
    storeItemCount = getStoreItemCount.data.Store_Item_Count;
  }

  if (getStoreItems.data) {
    if ("pages" in getStoreItems.data) {
      getStoreItems.data.pages.forEach((page) => {
        if ("StoreItems" in page) {
          allItems = [...allItems, ...page.StoreItems];
          // console.log(allItems)
        }
      });
    }
  }

  const deleteMenu = useMutation({
    mutationFn: async (data) => {
      const payload = {
        itemId: data.itemId,
      };
      return await deleteSingleMenu(payload);
    },
    onSuccess: () => {
      setSelectedObject({});
      queryClient.invalidateQueries({ queryKey: ["getMenuByCategory"] });
      queryClient.invalidateQueries({ queryKey: ["getStoreItems"] });
      setDeleteItemModal(false);
    },
  });

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        objectKey: data.objectKey,
      };
      return await delectS3Object(payload);
    },
  });

  const handleReduceItemCount = useMutation<any, Error>({
    mutationFn: async () => {
      const payload = {slugUrl: storeKey, updateType: "delete"};
      return await handleUpdateStoreItemCount(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getStoreItemCount"]);
    }
  });

  return (
    <>
      <Modal
        show={deleteItemModal}
        onToggle={() => {
          setDeleteItemModal(false);
          setSelectedObject({});
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-lg">
              You are about to delete this item from {selectedObject.itemCategory}
            </p>
          </div>
          <div className="flex flex-col justify-center md:flex-row gap-2 mt-5 md:mt-8">
            <div>
              <button
                disabled={deleteMenu.isLoading}
                className="px-4 py-2 text-xs font-semibold text-center text-white bg-blue-700 hover:bg-blue-800 border rounded-md w-full md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  deleteMenu.mutate({
                    itemId: selectedObject.menuItemId,
                  });
                  handleDeleteS3Object.mutate({
                    objectKey: selectedObject.s3ImageUrl[0].imageIdentifier,
                  });
                  handleReduceItemCount.mutate();
                }}
              >
                {deleteMenu.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 text-xs font-semibold text-center text-blue-700 bg-white hover:bg-gray-50 border border-blue-700 rounded-md  w-full md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedObject({});
                  setDeleteItemModal(false);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        size="medium"
        show={showModal}
        onToggle={() => {
          setShowModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 ">
          <div className="text-center">
            <img
              src={getImage("connection-error")}
              alt="icon"
              className="w-24 mx-auto"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-lg">
              Maximum of 25 products allowed.
            </h1>
            <p>
              To increase this limit, contact our <Link to="/support/limit-increase" className="text-red-600 hover:text-red-800" >support team.</Link>
            </p>
          </div>
        </div>
      </Modal>
      <div className="space-y-4 mb-4">
      <div className="flex items-center justify-between gap-2 flex-wrap mb-3 "><h1 className="text-lg text-gray-800 font-semibold">Products</h1> <p className="text-gray-600 font-semibold"><span>{storeItemCount ?? ""}</span> Items</p></div>
        <button
          type="button"
          className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
          onClick={() => {
            if (storeItemCount > 24) {
              setShowModal(true);
              return;
            }
            setProductSidePanelStep(7);
          }}
        >
          Add product
        </button>
      </div>
      <div>
        <h1
          className="text-lg text-gray-800 mb-3 font-semibold flex items-center gap-4 cursor-pointer hidden"
          onClick={() => setProductSidePanelStep(1)}
        >
          {" "}
          <MdOutlineKeyboardBackspace /> <span>Product</span>
        </h1>
        {getStoreItems.status === "loading" ? (
          <div className="flex items-center justify-start py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : getStoreItems.status === "error" ? (
          <p className="mt-8 font-semibold text-center text-gray-400">
            {getStoreItems.error.message}
          </p>
        ) : allItems.length > 0 ? (
          <div className="space-y-5">
            {allItems.map((item, index) => {
              return (
                <div className="flex items-center gap-4" key={item.menuItemId}>
                  <div className="h-[60px] w-[60px] min-w-[60px] bg-gray-100 rounded-md">
                    <img
                      src={item.s3ImageUrl}
                      alt="No image"
                      onError={(e) =>
                      (e.currentTarget.src =
                        "/media/image/productBg.png")
                      }
                      className="object-cover w-full h-full rounded"
                    />
                  </div>
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <p className="text-xs font-semibold truncate w-[90%]" >{item.itemName.length > 18 ? item.itemName.substring(0, 18) + "..." : item.itemName}</p>
                      <div className="flex items-center gap-2 ml-auto">
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="w-full text-blue-800 mt-1">
                              <IoMdMore />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 mt-1 w-36 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-10">
                              <div className="px-1 py-1 ">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${active
                                        ? "bg-slate-100 font-semibold"
                                        : "text-gray-900 font-medium"
                                        } w-full rounded-md px-4 py-2 text-xs text-left`}
                                      onClick={() => {
                                        setSelectedObject(item);
                                        setProductSidePanelStep(4);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${active
                                        ? "bg-slate-100 font-semibold"
                                        : "text-gray-900 font-medium"
                                        } w-full rounded-md px-4 py-2 text-xs text-left`}
                                      onClick={() => {
                                        setSelectedObject(item);
                                        setDeleteItemModal(true);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    <p className="text-xs">&#x20A6;{item.itemPriceDigit}</p>
                    <p className="text-xs truncate">
                      {item.description.length > 25 ? item.description.substring(0, 25) + "..." : item.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="mt-4 text-xs font-semibold text-center text-gray-800">
            No item available, click on add product.
          </p>
        )}
      </div>
    </>
  );
};

export default AllItemsNew;
