// @ts-nocheck

import { Menu, Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { BiBell, BiChevronDown } from "react-icons/bi";
import { classNames } from "../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks";
import { downloadNotifications, downloadNotificationsCount, handleReadNotification } from "../../../../api/Menu";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UserProfileModel } from "../../../../models";
import moment from "moment";

const OrderNotifications = ({ orderNotifications }) => {
  const { pathname } = useLocation();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const platformKey = user.queryKey.replace("%26", "&")

  const queueIds = orderNotifications.map(n => n.queueId)
  // console.log(queueIds)
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleClearNotification = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        platformKey,
        notificationSubject: "Order_Notification",
        queueId: queueIds
      }
      return await handleReadNotification(payload);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getNotificationCount"] });
      queryClient.invalidateQueries({ queryKey: ["getStoreNotifications"] });
    },
  });


  return (
    <div className="">
      <div onClick={() => setExpanded(!expanded)} className="flex items-center justify-between pr-2 py-2 bg-gray-50 rounded-md text-gray-800 text-sm cursor-pointer">
        <div className="flex items-center text-gray-800">
          <BiChevronDown size={24} className={classNames("transform duration-200 ease-linear transition text-gray-400", expanded && "rotate-180")} />
          <span className="font-semibold text-base ">Orders</span>
        </div>
        <div className="flex items-center space-x-1.5">
          {/* <span>See all</span> */}
          <div className="h-7 w-7 text-[10px] rounded-full bg-red-600 text-white text-center font-medium flex items-center justify-center"><span>{orderNotifications.length}</span></div>
        </div>
      </div>
      {expanded && (
        <>
          <div className="py-2 pb-0 space-y-1 divide-y divide-gray-200">
            {
              orderNotifications.slice(0, 3).map((notification: any, index: number) => {
                return (<div className="px-2 py-2.5" key={index}>
                  <div className="flex items-center justify-between mb-1 text-gray-600 font-medium text-xs">
                    <p>Order ID: {notification.orderId}</p>
                    <p>{moment(notification.creationTime).format(
                      "Do MMMM YYYY"
                    )}</p>
                  </div>
                  <div className="text-xs text-black space-y-1">
                    <p className="font-semibold" >{notification.notificationSubject}</p>
                    <div className="">
                      {notification.itemInformation.map((item: any, index: number) => (
                        // <p key={index} >{index + 1}. {item.itemName} x{item.quantityOrder}</p>
                        <p key={index} >{`${index + 1}. ${item.itemName.replace(/_/g, " ")}  x${item.quantityOrder}`}</p>
                      ))}
                    </div>
                  </div>
                </div>);
              })
            }

          </div>
          <p className="ml-2 -mt-2 text-blue-700 underline underline-offset-2 cursor-pointer text-xs" onClick={() => {
            handleClearNotification.mutate();
            navigate("/orders")
          }}>See more</p>
        </>
      )
      }
    </div>
  );
};

const ReviewNotifications = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="">
      <div onClick={() => setExpanded(!expanded)} className="flex items-center justify-between pr-2 py-2 bg-gray-50 rounded-md text-gray-800 text-sm cursor-pointer">
        <div className="flex items-center text-gray-800">
          <BiChevronDown size={24} className={classNames("text-gray-400 transform duration-200 ease-linear transition", expanded && "rotate-180")} />
          <span className="font-semibold text-base">Customer review</span>
        </div>
        <div className="flex items-center space-x-1.5">
          {/* <span>See all</span> */}
          <div className="h-4 w-4 text-xs rounded-full bg-blue-800 text-white text-center font-medium">2</div>
        </div>
      </div>
      {expanded && (
        <>
          <div className="py-2 pb-0 space-y-1 divide-y divide-gray-200 ">
            {
              [1, 2].map((item: any, index: number) => {
                return (<div className="px-2 py-1" key={index}>
                  <div className="flex items-center justify-between mb-1 text-gray-600 font-medium text-sm">
                    <p>johndoe@cloudpactai.com</p>
                    <p>11/10/2023</p>
                  </div>
                  <div className="text-sm text-black space-y-1">
                    <p>Your services are top notch! Thank you for doing your work exceptionally.</p>
                  </div>
                </div>);
              })
            }
          </div>
          <p className="ml-2 -mt-2 text-blue-700 underline underline-offset-2 cursor-pointer text-sm" onClick={() => navigate("/feedback-analysis/customer-review")}>See more</p>
        </>
      )}
    </div>
  );
};

const WalletNotifications = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="">
      <div onClick={() => setExpanded(!expanded)} className="flex items-center justify-between pr-2 py-2 bg-gray-50 rounded-md text-gray-800 text-sm cursor-pointer">
        <div className="flex items-center text-gray-800">
          <BiChevronDown size={24} className={classNames("text-gray-400 transform duration-200 ease-linear transition", expanded && "rotate-180")} />
          <span className="font-semibold text-base">Wallet & payout</span>
        </div>
        <div className="flex items-center space-x-1.5">
          {/* <span>See all</span> */}
          <div className="h-4 w-4 text-xs rounded-full bg-blue-800 text-white text-center font-medium">2</div>
        </div>
      </div>
      {expanded && (
        <>
          <div className="py-2 pb-0 space-y-1 divide-y divide-gray-200 ">
            {
              [1, 2].map((item: any, index: number) => {
                return (<div className="px-2 py-1" key={index}>
                  <div className="flex items-center justify-between mb-1 text-gray-600 font-medium text-sm">
                    <p>Payment received</p>
                    <p>11/10/2023</p>
                  </div>
                  <div className="text-sm text-black space-y-1">
                    <div>
                      <p>Name: Jonathan Doherty</p>
                      <p>Amount: N12,230.00</p>
                    </div>
                  </div>
                </div>);
              })
            }
          </div>
          <p className="ml-2 -mt-2 text-blue-700 underline underline-offset-2 cursor-pointer text-sm" onClick={() => navigate("/home/payments")}>See more</p>
        </>
      )}
    </div>
  );
};

const WebPageNotifications = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="">
      <div onClick={() => setExpanded(!expanded)} className="flex items-center justify-between pr-2 py-2 bg-gray-50 rounded-md text-gray-800 text-sm cursor-pointer">
        <div className="flex items-center text-gray-800">
          <BiChevronDown size={24} className={classNames("text-gray-400 transform duration-200 ease-linear transition", expanded && "rotate-180")} />
          <span className="font-semibold text-base">Web page</span>
        </div>
        <div className="flex items-center space-x-1.5">
          {/* <span>See all</span> */}
          <div className="h-4 w-4 text-xs rounded-full bg-blue-800 text-white text-center font-medium">2</div>
        </div>
      </div>
      {expanded && (
        <>
          <div className="py-2 pb-0 space-y-1 divide-y divide-gray-200 ">
            {
              [1, 2].map((item: any, index: number) => {
                return (<div className="px-2 py-1" key={index}>
                  <div className="flex items-center justify-between mb-1 text-gray-600 font-medium text-sm">
                    <p>Low stock count</p>
                    <p>11/10/2023</p>
                  </div>
                  <div className="text-sm text-black space-y-1">
                    <div>
                      <p>Item: Adidas all-stars</p>
                      <p>Available stock: 6</p>
                    </div>
                  </div>
                </div>);
              })
            }
          </div>
          <p className="ml-2 -mt-2 text-blue-700 underline underline-offset-2 cursor-pointer text-sm" onClick={() => navigate("/home/echo-webpage/customizer")}>See more</p>
        </>
      )}
    </div>
  );
};

const HelpDeskNotifications = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="">
      <div onClick={() => setExpanded(!expanded)} className="flex items-center justify-between pr-2 py-2 bg-gray-50 rounded-md text-gray-800 text-sm cursor-pointer">
        <div className="flex items-center text-gray-800">
          <BiChevronDown size={24} className={classNames("text-gray-400 transform duration-200 ease-linear transition", expanded && "rotate-180")} />
          <span className="font-semibold text-base">Helpdesk</span>
        </div>
        <div className="flex items-center space-x-1.5">
          {/* <span>See all</span> */}
          <div className="h-4 w-4 text-xs rounded-full bg-blue-800 text-white text-center font-medium">2</div>
        </div>
      </div>
      {expanded && (
        <>
          <div className="py-2 pb-0 space-y-1 divide-y divide-gray-200">
            {
              [1, 2].map((item: any, index: number) => {
                return (<div className="px-2 py-1" key={index}>
                  <div className="flex items-center justify-between mb-1 text-gray-600 font-medium text-sm">
                    <p>+2348012345678</p>
                    <p>11/10/2023</p>
                  </div>
                  <div className="text-sm text-black space-y-1">
                    <p>Your services are soo poor. This is probably the worst design {"i’ve"} seen in my lifetime</p>
                  </div>
                </div>);
              })
            }
          </div>
          <p className="ml-2 -mt-2 text-blue-700 underline underline-offset-2 cursor-pointer text-sm" onClick={() => navigate("/feedback-analysis/help-desk")}>See more</p>
        </>
      )}
    </div>
  );
};

const MessageNotifications = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="">
      <div onClick={() => setExpanded(!expanded)} className="flex items-center justify-between pr-2 py-2 bg-gray-50 rounded-md text-gray-800 text-sm cursor-pointer">
        <div className="flex items-center">
          <BiChevronDown size={24} className={classNames("transform duration-200 ease-linear transition", expanded && "rotate-180")} />
          <span className="font-semibold text-base">Messages</span>
        </div>
        <div className="flex items-center space-x-1.5">
          {/* <span>See all</span> */}
          <div className="h-4 w-4 text-xs rounded-full bg-blue-800 text-white text-center font-medium">2</div>
        </div>
      </div>
      {expanded && (<div className="py-2 space-y-1 divide-y divide-gray-200 ">
        {
          [1, 2].map((item: any, index: number) => {
            return (<div className="px-2 py-1">
              <div className="flex items-center justify-between mb-1 text-gray-600 font-medium text-sm">
                <p>Message sent</p>
                <p>11/10/2023</p>
              </div>
              <div className="text-sm text-black space-y-1">
                <div>
                  <p>To: johndoe@gmail.com and 9 others</p>
                  <p>Subject: Black Friday Promo</p>
                </div>
                <p className="text-primary underline underline-offset-2 cursor-pointer text-sm">See more</p>
              </div>
            </div>);
          })
        }
      </div>)}
    </div>
  );
};

const RewardNotifications = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="">
      <div onClick={() => setExpanded(!expanded)} className="flex items-center justify-between pr-2 py-2 bg-gray-50 rounded-md text-gray-800 text-sm cursor-pointer">
        <div className="flex items-center">
          <BiChevronDown size={24} className={classNames("transform duration-200 ease-linear transition", expanded && "rotate-180")} />
          <span className="font-semibold text-base">Customer reward</span>
        </div>
        <div className="flex items-center space-x-1.5">
          {/* <span>See all</span> */}
          <div className="h-4 w-4 text-xs rounded-full bg-blue-800 text-white text-center font-medium">2</div>
        </div>
      </div>
      {expanded && (<div className="py-2 space-y-1 divide-y divide-gray-200 ">
        {
          [1, 2].map((item: any, index: number) => {
            return (<div className="px-2 py-1">
              <div className="flex items-center justify-between mb-1 text-gray-600 font-medium text-sm">
                <p>Coupon claimed</p>
                <p>11/10/2023</p>
              </div>
              <div className="text-sm text-black space-y-1">
                <p>Jonathan Doherty has claimed his coupon for "Christmas sale".</p>
                <p className="text-primary underline underline-offset-2 cursor-pointer text-sm">See more</p>
              </div>
            </div>);
          })
        }
      </div>)}
    </div>
  );
};

const NotificationBell = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey
  const [show, setShow] = useState(false);
  let notificationCount = 0;
  let orderNotifications = [];
  const platformKey = user.queryKey.replace("%26", "&")

  let queueIds = [];

  const getNotificationCount = useQuery<any, error>({
    queryKey: ["getNotificationCount"],
    queryFn: async () => {
      const response = await downloadNotificationsCount(queryKey)
      return response.data;
    },
  })

  const getStoreNotifications = useInfiniteQuery<any, Error>({
    queryKey: ["getStoreNotifications"],
    queryFn: async ({ pageParam = "" }) => {
      const paginationKey = pageParam;
      const response = await downloadNotifications(queryKey, paginationKey);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
    onSuccess: (data) => {
      if (data.pages[0].Notification_Catalog.Order_Notification.length > 0) {
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 6000)
        
      }
    },
  });

  const handleClearNotification = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        platformKey,
        notificationSubject: "Order_Notification",
        queueId: queueIds
      }
      return await handleReadNotification(payload);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getNotificationCount"] });
      queryClient.invalidateQueries({ queryKey: ["getStoreNotifications"] });
    },
  });

  if (getNotificationCount.data) {
    notificationCount = getNotificationCount.data?.total_notification_count || 0;
  }

  if (getStoreNotifications.data) {
    if ("pages" in getStoreNotifications.data) {
      getStoreNotifications.data.pages.forEach((page) => {
        if ("Notification_Catalog" in page) {
          if ("Order_Notification" in page.Notification_Catalog) {
            orderNotifications = [...orderNotifications, ...page.Notification_Catalog.Order_Notification];
            queueIds = orderNotifications.map(n => n.queueId)
          }
        }
      });
    }
  }

  
  // useEffect(() => {
  //   if(pathname === "/orders" && orderNotifications.length > 0){
  //     handleClearNotification.mutate();
  //     console.log("delete")
  //   }
  // },[])

  return (
    <div className="mt-1 hide-help relative">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button>
            <div className="relative p-2 text-gray-700 transition duration-300 ease-linear bg-gray-100 rounded-full" onClick={() => setShow(false)}>
              <BiBell size={22} />
              {orderNotifications.length > 0 && <div className="absolute z-10 -top-2 -right-3 font-semibold rounded-full bg-red-700 sm:bg-[#F32121] w-6 h-6 flex items-center justify-center" ><span className="text-[13px] text-white">{orderNotifications.length}</span></div>}
            </div>
          </Menu.Button>
        </div>
        <Transition
          // as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {/* { orderNotifications.length > 0 && (
            
          )} */}

          <Menu.Items className="absolute -right-8 md:-right-16 lg:-right-28 mt-3 w-[300px] sm:w-[380px] rounded-md bg-white shadow-lg focus:outline-none max-h-[380px] min-h-44 overflow-y-auto notificationScrollbar">
            <div className="px-2 py-2 space-y-2">
              {orderNotifications.length > 0 ? (
                <OrderNotifications orderNotifications={orderNotifications} />
              ) : (
                <div className="py-1.5">
                  <p className="text-gray-800 font-medium text-center">No new notification.</p>
                </div>
              )}
              {/* <ReviewNotifications />
              <WalletNotifications />
              <WebPageNotifications />
              <HelpDeskNotifications /> */}
              {/* <MessageNotifications />
                        <RewardNotifications /> */}
            </div>

          </Menu.Items>


        </Transition>
      </Menu>
      {show && (<div className="absolute -right-10 mt-3 w-[185px] p-2 z-20 bg-white rounded-md shadow-lg flex items-center justify-center gap-2 cursor-pointer" onClick={() => {
        setShow(false);
      }}>
        <BiBell size={22} className="text-blue-600" />
        <p className="text-gray-800 font-medium text-center">New from orders</p>
      </div>)}
    </div>
  );
};

export default NotificationBell;