// @ts-nocheck
import { Link, useParams } from "react-router-dom";
import { base64Decode, base64Encode, classNames } from "../../../../utils";
import { CartItem, CartProps, SET_CART } from "../../../../redux/Utils";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import toast from "react-hot-toast";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { StoreItem } from "../../../../models";
import { useEffect, useState } from "react";
import Modal from "../../../Dashboard/Modal";
import { MdOutlineCancel } from "react-icons/md";
import { zIndex } from "html2canvas/dist/types/css/property-descriptors/z-index";

interface Props {
  product: CartItem | StoreItem;
}

const ProductCard = ({
  product = {
    ...product,
    itemVariations: product.itemVariations ? product.itemVariations : [],
  },
}: Props) => {
  const { salt, slug, category } = useParams();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [variantsArray, setVariantsArray] = useState([]);
  const selectedCategory = category
    ? base64Decode(category ?? "")
    : product.itemCategory;

  const cart: CartProps = useAppSelector((state) => state.utils.cart);
  const freeDeliveryStatus: string = useAppSelector(
    (state) => state.user.store.freeDelivery
  );

  const getCart = () => Object.values(cart || {});

  const currentProduct = getCart().find(
    (cartItem) => cartItem.itemName === product.itemName
  );

  const addToCart = (item: CartItem): void => {
    // console.log(item);
    const a = selectedItem.itemVariations
      .filter((i) => i.quantity > 0)
      .map((v) => {
        const itemCopy = {
          ...item,
          slugUrl: item.slugUrl ? item.slugUrl : `/${salt}/${slug}`,
        };
        // itemPriceSelectedPrice
        itemCopy.qty = v.quantity;
        itemCopy.selectedPriceForItem = v.discountPrice
          ? v.discountPrice
          : v.price;
        itemCopy["itemSize"] = v.size;
        itemCopy["itemColor"] = v.color;
        itemCopy["itemVariantArray"] = [v];
        // itemCopy["itemPriceSelectedPrice"] = ;
        const key = `${itemCopy.itemName}-${itemCopy.itemSize}-${itemCopy.itemColor}`;
        itemCopy["key"] = key;
        const updatedCart = { [key]: itemCopy, ...cart };

        return { ...itemCopy };
      });
    var result = {};

    a.forEach((i) => {
      result[i.key] = i;
    });

    const updatedCart = { ...result, ...cart };

    dispatch(SET_CART(updatedCart));
    toast.success(`${selectedItem.itemName} has been added to your cart.`);
    setShowModal(false);
    setSelectedItem({});
  };

  const addToCartWithoutVariations = (item: CartItem): void => {
    // console.log(item);

    const itemCopy = {
      ...item,
      slugUrl: item.slugUrl ? item.slugUrl : `/${salt}/${slug}`,
    };
    // console.log(Number(itemCopy.discountPrice))
    // console.log(Number(itemCopy.itemPrice))
    itemCopy.selectedPriceForItem =
      Number(itemCopy.discountPrice) === 0 ||
      isNaN(Number(itemCopy.discountPrice))
        ? Number(itemCopy.itemPrice)
        : Number(itemCopy.discountPrice);
    itemCopy.qty = 1;
    itemCopy["itemSize"] = 0;
    itemCopy["itemColor"] = "";
    const key = `${itemCopy.itemName}-${itemCopy.itemSize}-${itemCopy.itemColor}`;
    itemCopy["key"] = key;

    console.log(itemCopy);

    const updatedCart = { [key]: itemCopy, ...cart };

    dispatch(SET_CART(updatedCart));
    toast.success(`${itemCopy.itemName} has been added to your cart.`);
  };

  const handleRemoveCartProduct = (itemName: string): void => {
    const updatedCart = { ...cart };
    delete updatedCart[itemName];

    dispatch(SET_CART(updatedCart));
  };

  const handleUpdateCartQuantity = (item: CartItem, operation: Operation) => {
    const updatedCart = { ...cart };
    const key = `${item.itemName}-${0}-${""}`;
    const itemName = key;

    if (updatedCart[itemName]) {
      if (operation === "increase") {
        updatedCart[itemName] = {
          ...updatedCart[itemName],
          qty: updatedCart[itemName].qty + 1,
        };
      } else {
        updatedCart[itemName] = {
          ...updatedCart[itemName],
          qty: updatedCart[itemName].qty - 1,
        };
      }
    }

    dispatch(SET_CART(updatedCart));
  };

  // console.log(selectedItem);

  return (
    <div>
      <Modal
        show={showModal}
        onToggle={() => {
          setShowModal(false);
          setSelectedItem({});
          setVariantsArray([]);
        }}
        // style={{ zIndex: 9999 }}
      >
        <div className="relative p-3 text-sm text-black sm:py-6 sm:px-8">
          <div className="flex items-center justify-between">
            <p className="font-semibold text-lg">Please choose a variation</p>
            <MdOutlineCancel
              size={22}
              className="text-gray-700 cursor-pointer hidden md:block"
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
          {selectedItem.itemVariations &&
            selectedItem.itemVariations.map((variant: any, index: number) => {
              const currenVariant = selectedItem.itemVariations.find(
                (v) => v.variantId === variant.variantId
              );

              return (
                <div
                  className="text-sm py-3 border-b-[1px] border-gray-300 flex items-center justify-between"
                  key={variant.variantId || index}
                >
                  <div>
                    <div className="mb-2">
                      <p className="font-semibold">
                        {variant.size}
                        {variant.size && variant.color ? "," : ""}{" "}
                        {variant.color}
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <p>&#8358; {variant.discountPrice}</p>
                      <p className="line-through">
                        {variant.price === 0
                          ? `N ${selectedItem.itemPrice}`
                          : `N ${variant.price}`}
                      </p>
                      {/* <p>&#8358; {variant.discountPrice}</p> */}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2.5">
                    <button
                      onClick={() => {
                        // setVariantsArray((prev) => [
                        //   ...prev,
                        //   { variantId: variant.variantId },
                        // ]);
                      }}
                      className="w-6 h-6 text-base font-semibold text-gray-800 capitalize bg-gray-100 rounded-sm"
                    >
                      <AiOutlineMinus
                        size={16}
                        className=" mx-auto"
                        onClick={() => {
                          setSelectedItem((prev) => ({
                            ...prev,
                            itemVariations: selectedItem.itemVariations.map(
                              (obj) =>
                                obj.variantId === variant.variantId
                                  ? {
                                      ...variant,
                                      quantity:
                                        variant.quantity === 0
                                          ? 0
                                          : variant.quantity - 1,
                                    }
                                  : obj
                            ),
                          }));
                        }}
                      />
                    </button>
                    <p className="text-sm">{variant.quantity}</p>
                    <button
                      // onClick={() => handleUpdateCartQuantity(item, "increase")}
                      className="w-6 h-6 text-base font-semibold text-gray-800 capitalize bg-gray-100 rounded-sm"
                    >
                      <AiOutlinePlus
                        size={16}
                        className=" mx-auto"
                        onClick={() => {
                          setSelectedItem((prev) => ({
                            ...prev,
                            itemVariations: selectedItem.itemVariations.map(
                              (obj) =>
                                obj.variantId === variant.variantId
                                  ? {
                                      ...variant,
                                      quantity: variant.quantity + 1,
                                    }
                                  : obj
                            ),
                          }));
                        }}
                      />
                    </button>
                  </div>
                </div>
              );
            })}
          <button
            type="button"
            disabled={
              selectedItem.itemVariations?.filter((i) => i.quantity > 0)
                .length < 1
            }
            onClick={(e) => {
              const key = `${product.itemName}-${0}-${""}`;

              if (getCart().find((cartItem) => cartItem.key === key)) {
                handleUpdateCartQuantity(product, "increase");
                // toast.success(
                //   `${product.itemName} has been added to your cart.`
                // );
                // return;
              }
              // console.log(product);
              addToCart(selectedItem);
            }}
            className={classNames(
              "mt-4 w-full p-2.5 text-base text-[#381F7F] font-medium text-center hover:text-white bg-tranparent hover:bg-[#381F7F] border border-[#381F7F] rounded-md transition duration-200 ease-linear"
            )}
          >
            Add to cart
          </button>
        </div>
      </Modal>
      <div className="flex flex-col items-stretch justify-between space-y-2 max-w-[250px] sm:max-w-full">
        <div
          // onClick={() => {}}
          // to={`/store/${salt}/${slug}/all-products/category/${base64Encode(
          //   selectedCategory
          // )}/product/${base64Encode(product.menuItemId ?? product?.itemId)}`}
          key={product.itemId}
        >
          <div className="max-w-sm border-gray-200 rounded-md">
            <Link
              to={`/store/${salt}/${slug}/all-products/category/${base64Encode(
                selectedCategory
              )}/product/${base64Encode(
                product.menuItemId ?? product?.itemId
              )}`}
            >
              <div className="h-[170px] sm:h-[210px] md:h-[230px] mb-2 rounded-md relative overflow-hidden">
                <img
                  src={
                    product.s3ImageUrl ||
                    "https://images.pexels.com/photos/757432/pexels-photo-757432.jpeg"
                  }
                  alt="img"
                  width="0"
                  height="0"
                  className="h-full w-full object-cover rounded-md bg-gray-100"
                />
              </div>
            </Link>
            <div className="text-left space-y-1 md:space-y-1.5">
              <p className="text-[13px] text-gray-800 font-medium line-clamp-1">
                {product?.itemName || "Not available"}
              </p>
              <p className="text-[13px] text-gray-800 md:text-base font-medium">
                &#8358; {product.itemPrice}
              </p>
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  // console.log(product);
                  if (
                    !product.itemVariations ||
                    product.itemVariations.length < 1
                  ) {
                    const key = `${product.itemName}-${0}-${""}`;

                    if (getCart().find((cartItem) => cartItem.key === key)) {
                      handleUpdateCartQuantity(product, "increase");
                      toast.success(
                        `${product.itemName} has been added to your cart.`
                      );
                      return;
                    }
                    addToCartWithoutVariations(product);
                    return;
                  }
                  const newProduct = {
                    ...product,
                    itemVariations: product.itemVariations.map((i) => {
                      return {
                        ...i,
                        quantity: 0,
                      };
                    }),
                  };
                  setSelectedItem(newProduct);
                  setShowModal(true);
                }}
                className={classNames(
                  "w-full p-2 text-base text-[#381F7F] font-medium text-center hover:text-white bg-tranparent hover:bg-[#381F7F] border border-[#381F7F] rounded-md transition duration-200 ease-linear"
                )}
              >
                Add to cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <>
    //   <Modal
    //     show={showModal}
    //     onToggle={() => {
    //       setShowModal(false);
    //     }}
    //   >
    //     <div className="relative p-3 text-sm text-black sm:py-6 sm:px-8 spce-y-3 sm:space-y-6">
    //       <div>
    //         <p className="font-semibold text-xl">Transaction details</p>
    //       </div>
    //     </div>
    //   </Modal>
    //   <div className="flex flex-col items-stretch justify-between space-y-2 max-w-[250px] sm:max-w-full">
    //     <Link
    //       to={`/store/${salt}/${slug}/all-products/category/${base64Encode(
    //         selectedCategory
    //       )}/product/${base64Encode(product.menuItemId ?? product?.itemId)}`}
    //       key={product.itemId}
    //     >
    //       <div className="max-w-sm border-gray-200 rounded-md">
    //         <div className="h-[170px] sm:h-[210px] md:h-[230px] mb-2 rounded-md relative overflow-hidden">
    //           <img
    //             src={
    //               product.s3ImageUrl ||
    //               "https://images.pexels.com/photos/757432/pexels-photo-757432.jpeg"
    //             }
    //             alt="img"
    //             width="0"
    //             height="0"
    //             className="h-full w-full object-cover rounded-md bg-gray-100"
    //           />
    //         </div>
    //         <div className="text-left space-y-1 md:space-y-1.5">
    //           <p className="text-[13px] text-gray-800 font-medium line-clamp-1">
    //             {product?.itemName || "Not available"}
    //           </p>
    //           <p className="text-[13px] text-gray-800 md:text-base font-medium">
    //             &#8358; {product.itemPrice}
    //           </p>
    //           <button
    //             type="button"
    //             onClick={(e) => {
    //               // e.stopPropagation();
    //               // setShowModal(true);
    //               // return;
    //               e.preventDefault();
    //               const key = `${product.itemName}-${0}-${""}`;

    //               if (getCart().find((cartItem) => cartItem.key === key)) {
    //                 handleUpdateCartQuantity(product, "increase");
    //                 toast.success(
    //                   `${product.itemName} has been added to your cart.`
    //                 );
    //                 return;
    //               }
    //               // console.log(product)
    //               addToCart(product);
    //             }}
    //             className={classNames(
    //               "w-full p-2 text-base text-[#381F7F] font-medium text-center hover:text-white bg-tranparent hover:bg-[#381F7F] border border-[#381F7F] rounded-md transition duration-200 ease-linear"
    //             )}
    //           >
    //             Add to cart
    //           </button>
    //         </div>
    //       </div>
    //     </Link>
    //   </div>
    // </>
  );
};

export default ProductCard;
