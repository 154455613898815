import React, { useState, useEffect, Fragment } from "react";
import {
  ReviewStars,
  classNames,
  formatStoreName,
  getImage,
  getSvg,
  ratingsToNumber,
} from "../../../../../utils";
import Modal from "../../../../../components/Dashboard/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdOutlineClear, MdOutlineEdit } from "react-icons/md";
import {
  BASE_URL_WEBPAGE_IMAGE,
  addStoreAddress,
  delectS3Object,
  deleteProductCategory,
  deleteService,
  deleteSingleMenu,
  downloadStoreItemCount,
  downloadStoreItems,
  getFreeDeliveryStatus,
  getOpeningHours,
  getServices,
  getSiteTitleAndTagLine,
  getStoreAddress,
  getTemplateSections,
  handleUpdateStoreItemCount,
  updateOpeningHour,
  updateTemplateSection,
  uploadService,
  uploadSiteTitleAndTagLine,
  uploadStoreFooterDetails,
} from "../../../../../api/Menu";
import { StoreModel, UserProfileModel } from "../../../../../models";
import { useAppSelector } from "../../../../../hooks";
import { ImSpinner2, ImSpinner3 } from "react-icons/im";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Compressor from "compressorjs";
import { AiOutlineCloudUpload } from "react-icons/ai";
import InputMessage from "../../../../../components/Auth/Forms/InputMessage";
import { BiChevronLeft } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { getPublicFeedBacks, uploadWebUrl } from "../../../../../api/Utils";
import { ImSpinner8 } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import AddService from "./components/ServicesSection/AddService";
import AddItemNew from "./components/ProductsSection/AddItemNew";
import EditItemInCategory from "./components/ProductsSection/EditItemInCategory";
import FooterSection from "./components/FooterSection";
import AboutUsSection from "./components/AboutUsSection";
import AboutUsSidePanel from "./components/AboutUsSection/SidePanel";

interface InputProps {
  name?: string;
  label?: string;
  type?: string;
  placeHolder?: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
  showLabel?: boolean;
}

export const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
  showLabel = true,
}: InputProps) => {
  return (
    <>
      {showLabel && (
        <label className="block mb-1 text-xs font-normal text-gray-800">
          {label}
        </label>
      )}
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        disabled={disabled}
        className={classNames(
          className,
          "text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
        )}
      />
    </>
  );
};

const serviceValues: any = {
  service: "",
};

const addressValues: any = {
  address: "",
};

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const footerInitialValues: any = {
  phoneNumber: "",
  email: "",
  linkedIn_url: "",
  facebook_url: "",
  twitter_url: "",
  instagram_url: "",
  tiktok_url: "",
};

const footerValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().matches(
    phoneRegExp,
    "Phone number is not valid. Please use this format (e.g, +234)"
  ),
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
  twitter_url: Yup.string().test(
    "url",
    "Url must contain https://",
    (value: any) => {
      return value.includes("https://");
    }
  ),
  linkedIn_url: Yup.string().test(
    "url",
    "Url must contain https://",
    (value: any) => {
      return value.includes("https://");
    }
  ),
  facebook_url: Yup.string().test(
    "url",
    "Url must contain https://",
    (value: any) => {
      return value.includes("https://");
    }
  ),
  instagram_url: Yup.string().test(
    "url",
    "Url must contain https://",
    (value: any) => {
      return value.includes("https://");
    }
  ),
});

type ModalTypes =
  | "item"
  | "deleteCategory"
  | "aboutUs"
  | "openingDays"
  | "account"
  | "heroBackground"
  | "service"
  | "address"
  | "delete"
  | "deleteHeroBackgroundImage"
  | "settings"
  | "footer"
  | "deleteService"
  | "allowedCategory"
  | "allowedItem"
  | "deleteItemInCategory"
  | "publishWebPage";

type popOverTypes = "deleteItem" | "editCategory" | "heroDetailsUpdateButttons";

const EchoWebpage1 = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = `${store.name}`;
  const storeName = formatStoreName(storeKey);
  const queryKey = `${user.queryKey}`;
  const text = `${window.location.origin}/store${storeKey}`;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [copied, setCopied] = useState(false);
  const [showModals, setShowModals] = useState<ModalTypes | null>(null);
  const [popOver1, setPopOver1] = useState<popOverTypes | null>(null);
  const [selectedImage, setSelectedImage] = useState<any | null | undefined>(null);
  const [preview, setPreview] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [s3ImageUrl, setS3ImageUrl] = useState("");
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [activeCategory, setActiveCategory] = useState(null);
  const [editItemId, setEditItemId] = useState(null);
  const [showMenusByCategories, setShowMenuByCategories] = useState(false);
  const [selectedObject, setSelectedObject] = useState<any>({});
  const [showPopOver, setShowPopOver] = useState(false);
  const [heroDetails, setHeroDetails] = useState({
    siteTitle: "",
    tagLine: "",
  });
  const [openingDaysFields, setOpeningDaysFields] = useState<any>({
    start_time: "",
    close_time: "",
    switch_button: "0",
  });
  const [eventType, setEventType] = useState(-1);
  const [selectedSection, setSelectedSection] = useState<any | null>(null);
  const [heroImage, setHeroImage] = useState<any | null>(null);
  const [success, setSuccess] = useState(false);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [allOpeningHours, setAllOpeningHours] = useState([]);

  let deliveryStatus: string = "0";
  let comments: any[] = [];
  let allItems: any[] = [];
  let services: any[] = [];
  let templateSections: any[] = [];
  let storeAddress: string = "";
  let itemsInCategory: number = 0;
  let storeItemCount: number = 0;
  let heroImageUrl: string =
  "https://d1ntmcvl0pjnmq.cloudfront.net" + queryKey + "/heroImage/image.png";

  const formik1 = useFormik({
    initialValues: serviceValues,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (values.service === "") {
        return;
      }
      setSubmitting(true);
      const payload = {
        slugUrl: storeKey,
        services: [values.service.trim()],
      };

      try {
        const response = await uploadService(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries(["getServices"]);
          resetForm({});
          setSubmitting(false);
          setShowModals(null);
          return;
        }
        setError(response.data.message);
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  const formik3 = useFormik({
    initialValues: addressValues,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (values.service === "") {
        return;
      }
      setSubmitting(true);
      const payload = {
        platformKey,
        store_address: values.address.trim(),
      };

      try {
        const response = await addStoreAddress(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries(["getStoreAddress"]);
          resetForm({});
          setSubmitting(false);
          return;
        }
        setError(response.data.message);
      } catch (err) {
        console.log({ err });
        // setError(err.message);
      }
      setSubmitting(false);
    },
  });

  const formik5 = useFormik({
    initialValues: footerInitialValues,
    // validationSchema: footerValidationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const payload = {
        platformKey,
        contact_number: values.phoneNumber,
        contact_email: values.email,
        linkedin_url: values.linkedIn_url,
        facebook_url: values.facebook_url,
        twitter_url: values.twitter_url,
        instagram_url: values.instagram_url,
      };

      try {
        const response = await uploadStoreFooterDetails(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries(["getStoreFooterDetails"]);
          resetForm({});
          setSubmitting(false);
          setShowModals(null);
          return;
        }
        setError(response.data.message);
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  const address = useQuery<any, Error>({
    queryKey: ["getStoreAddress"],
    queryFn: async () => {
      const response = await getStoreAddress(storeKey);
      return response.data;
    },
    onSuccess: (data) => { },
  });

  if (address.data) {
    storeAddress = address.data.Store_Address;
  }

  const getStoreItemCount = useQuery<any, Error>({
    queryKey: ["getStoreItemCount"],
    queryFn: async () => {
      const response = await downloadStoreItemCount(queryKey);
      return response.data;
    },
  });

  if (getStoreItemCount.data){
    storeItemCount = getStoreItemCount.data.Store_Item_Count;
  }

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data: any) => {
      const payload = {
        objectKey: data.objectKey,
      };
      return await delectS3Object(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getHeroBackgroundImageUrl"],
      });
      setShowModals(null);
    },
  });

  const imageHandler = (e: any) => {
    if (showModals === "item" && step === 3 && editItemId) {
      setS3ImageUrl("to-upload");
    }
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    setSelectedImage(e.target.files[0]);
  };

  const handleCompressedUpload = async (file: File) => {
    setIsLoading(true);
    if (selectedImage?.size > 2072576) {
      alert("Please select an image with a size of less than or equal to 2MB");
      setSelectedImage(null);
      return;
    }
    new Compressor(file, {
      quality: 0.2,
      success: (compressedResult: any) => {
        let path: any;

        const heroImagePath = "/cusecho" + storeKey + "/heroImage/image.png";

        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };

        if (showModals === "heroBackground") {
          path = heroImagePath;
        }

        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then(() => {
                setIsLoading(false);
                setImageUploadSuccess(true);
                showModals === "heroBackground" &&
                  queryClient.invalidateQueries(["getHeroBackgroundImageUrl"]);
                if (showModals === "heroBackground") {
                  setSelectedImage(null);
                  setPreview(null);
                  setShowModals(null);
                }
                setS3ImageUrl(`${path}`);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };

  const uploadImage = useMutation({
    mutationFn: async (data: File) => {
      return handleCompressedUpload(data);
    },
  });

  const handleUpdateTemplateSection = useMutation<any, Error>({
    mutationFn: async (data: any) => {
      return await updateTemplateSection(
        queryKey,
        data.sectionVar,
        showModals === "delete" ? "0" : "1"
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["getTemplateSections"]);
      setShowModals(null);
      setSelectedSection(null);
    },
  });

  const handlePublishWebPage = useMutation<any, Error>({
    mutationFn: async () => {
      const data = {
        platformKey: queryKey.replaceAll("%26", "&"),
        webLink: "",
      };
      return await uploadWebUrl(data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["downloadWebUrl"]);
      setShowModals("publishWebPage");
    },
  });

  const freeDeliveryStatus = useQuery<any, Error>({
    queryKey: ["freeDeliveryStatus"],
    queryFn: async () => {
      const response = await getFreeDeliveryStatus(storeKey);
      return response.data;
    }
  });

  if (freeDeliveryStatus.data) {
    deliveryStatus = freeDeliveryStatus.data.Delivery_Status;
  }

  const loadTemplateSections = useQuery<any, Error>({
    queryKey: ["getTemplateSections"],
    queryFn: async () => {
      const response = await getTemplateSections(storeKey);
      return response.data;
    },
  });

  const handleDeleteProductCategory = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        platformKey,
        categoryName: data.categoryName,
      };
      return await deleteProductCategory(payload);
    },
    onSuccess: () => {
      setShowModals(null);
      setSelectedObject({});
      queryClient.invalidateQueries(["getAllMenuCategories"]);
    },
  });

  const deleteMenu = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        itemId: data.itemId,
      };
      return await deleteSingleMenu(payload);
    },
    onSuccess: () => {
      setStep(1);
      setPopOver1(null);
      setSelectedObject({});
      queryClient.invalidateQueries(["getMenuByCategory"]);
    },
  });

  const handleReduceItemCount = useMutation<any, Error>({
    mutationFn: async () => {
      const payload = {slugUrl: storeKey, updateType: "delete"};
      return await handleUpdateStoreItemCount(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getStoreItemCount"]);
    }
  });

  const handleDeleteService = useMutation({
    mutationFn: async (data: any) => {
      return await deleteService(storeKey, data.service);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getServices"]);
      setShowModals(null);
    },
  });

  const siteTitleAndTagLine = useQuery<any, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(storeKey);
      return response.data;
    },
    onSuccess: (data) => {
      setHeroDetails({
        siteTitle: data.Site_Title,
        tagLine: data.Tagline,
      });
    },
  });

  const allServices = useQuery<any, Error>({
    queryKey: ["getServices"],
    queryFn: async () => {
      const response = await getServices(queryKey, storeKey);
      return response.data;
    },
    onSuccess: (data) => { },
  });

  const updateHeroDetails = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      platformKey,
      siteTitle: heroDetails.siteTitle,
      tagLine: heroDetails.tagLine,
    };

    try {
      const response = await uploadSiteTitleAndTagLine(payload);
      // console.log(response);

      if (response.data.status_code === 200) {
        setPopOver1(null);
        setIsLoading(false);
        return;
      }
      setError(response.data.message);
    } catch (err) {
      console.log({ err });
      setIsLoading(false);
    }
  };

  const openingHours = useQuery<any, Error>({
    queryKey: ["getOpeningHours"],
    queryFn: async () => {
      const response = await getOpeningHours(storeKey);
      return response.data;
    },
    onSuccess: (data) => {
      var result = data.Opening_Hours.map((x: any) => {
        var day = Object.keys(x)[0];
        var dayObj = x[day][0];
        return { ...dayObj, day };
      });
      setAllOpeningHours(result);
    },
  });

  const handleUpdateOpeningHour = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        platformKey,
        openingDay: data.openingDay,
        eventPayload: {
          close_time: data.close_time,
          start_time: data.start_time,
          switch_button: data.switch_button,
        },
        eventType: data.eventType,
        switchButton: data.switchButton,
      };
      return await updateOpeningHour(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getOpeningHours"]);
      eventType === 0 && setShowModals(null);
      eventType === 1 &&
        setSelectedObject({
          ...selectedObject,
          switch_button: selectedObject.switch_button === "0" ? "1" : "0",
        });
      setEventType(-1);
    },
  });

  const getStoreItems = useInfiniteQuery<any, Error>({
    queryKey: ["getStoreItems"],
    queryFn: async ({ pageParam = "" }) => {
      const paginationKey = pageParam;
      const response = await downloadStoreItems(queryKey, paginationKey);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      // console.log({lastPage});
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
  });

  if (getStoreItems.data) {
    if ("pages" in getStoreItems.data) {
      getStoreItems.data.pages.forEach((page) => {
        if ("StoreItems" in page) {
          allItems = [...allItems, ...page.StoreItems];
          // console.log(allItems)
        }
      });
    }
  }

  if (allServices.data) {
    services = allServices.data.Business_Services;
    // console.log(services)
  }

  if (loadTemplateSections.data) {
    templateSections = loadTemplateSections.data.Template_Sections;
  }

  const satisfaction = useQuery<any, Error>({
    queryKey: ["getPublicFeedBacks"],
    queryFn: async () => {
      const response = await getPublicFeedBacks(storeKey);
      return response.data;
    },
    onSuccess: (data) => { },
  });

  if (satisfaction.data) {
    comments = satisfaction.data.Public_Feedbacks.slice(0, 4);
  }

  useEffect(() => {
    if (!selectedImage) {
      setPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  return (
    <>
      <Modal
        show={showModals === "item"}
        onToggle={() => {
          setStep(1);
          setShowMenuByCategories(false);
          setSelectedImage(null);
          setPreview(null);
          setSuccess(false);
          setImageUploadSuccess(false);
          setDropdown(false);
          setEditItemId(null);
          setPopOver1(null);
          itemsInCategory = 0;
          setShowModals(null);
        }}
      >
        {step === 1 ? (
          <div className="relative flex flex-col p-3 md:py-6 md:px-8 overflow-y-auto h-[65vh]">
            <div className="" >
              <AddItemNew setShowModals={setShowModals}
              showModals={showModals} allItems={allItems} setProductSidePanelStep={undefined} storeItemCount={undefined} />
            </div>
          </div>
        ) : (
          <div className="relative flex flex-col p-3 md:py-6 md:px-4 h-[65vh] overflow-y-auto">
            <div className="" >
              <EditItemInCategory setSelectedObject={setSelectedObject} selectedObject={selectedObject} setShowModals={setShowModals}
                showModals={showModals} setStep={setStep} setProductSidePanelStep={undefined} />
            </div>
          </div>
        )}
      </Modal>
      <Modal
        show={showModals === "service"}
        onToggle={() => {
          setShowModals(null);
        }}
      >
        <div className="relative flex flex-col p-3 md:py-6 md:px-8">
          <AddService setShowModals={setShowModals} showModals={showModals} setStep={undefined} />
        </div>
      </Modal>
      <Modal
        show={showModals === "address"}
        onToggle={() => {
          setShowModals(null);
        }}
      >
        <div className="relative flex flex-col p-3 md:py-6 md:px-8">
          <div className="mb-4">
            <p className="text=gray-800 font-semibold">Address</p>
            <p className="text-sm text-gray-500">Enter Your Address</p>
          </div>
          <form onSubmit={formik3.handleSubmit} className="">
            <div className="w-full">
              <input
                type="text"
                placeholder=""
                {...formik3.getFieldProps("address")}
                className={classNames(
                  "border border-gray-300 py-2 px-5 bg-white rounded w-full outline-none text-gray-900 placeholder:text-sm"
                )}
              />
            </div>
            <div className="flex items-center justify-center mt-6">
              <button
                type="submit"
                onClick={() => {
                  setShowModals(null);
                }}
                className="px-6 py-2 text-sm text-white bg-blue-700 rounded hover:bg-blue-800"
              >
                {formik3.isSubmitting ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Save Address"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        show={showModals === "aboutUs"}
        onToggle={() => {
          setShowModals(null);
        }}
      >
        <div className="relative flex flex-col p-3 md:py-6 md:px-8">
          <AboutUsSidePanel setShowModals={setShowModals}
            showModals={showModals} />
        </div>
      </Modal>
      <Modal
        show={showModals === "heroBackground"}
        onToggle={() => {
          setShowModals(null);
          setSelectedImage(null);
          setPreview(null);
          setImageUploadSuccess(false);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mb-3">
            <p className="text=gray-800 font-semibold mb-2">Background</p>
            <p className="text-sm font-medium text-gray-900">
              Select a custom background
            </p>
          </div>
          <div className="space-y-3">
            <div>
              <label className="block mb-1 text-xs font-normal text-gray-800">
                Choose from files
              </label>
              {!selectedImage || !preview ? (
                <div className="flex items-center justify-center w-full">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full border border-gray-300 rounded-md cursor-pointer h-44 bg-gray-white "
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <AiOutlineCloudUpload size={24} className="text-gray-500" />
                      <p className="mb-2 text-sm text-gray-500">
                        <span className="font-semibold">
                          Click to select an image
                        </span>
                      </p>
                      <p className="text-xs text-gray-500">PNG or JPG</p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      accept="image/jpeg, image/png, image/jpg"
                      onChange={imageHandler}
                    />
                  </label>
                </div>
              ) : (
                <div className="flex items-center gap-8">
                  <img
                    src={preview}
                    alt=""
                    className="w-[50%] h-44 object-contain"
                  />
                  <div>
                    <div className="flex flex-col items-center gap-4 md:flex-row">
                      <button
                        type="button"
                        className="flex items-center gap-2 px-4 py-2 text-xs font-semibold text-red-700 bg-transparent border border-red-700 rounded-md"
                        onClick={(e) => {
                          setSelectedImage(null);
                          setPreview(null);
                          setS3ImageUrl("");
                          setImageUploadSuccess(false);
                        }}
                      >
                        <span>Cancel</span>
                        <span>
                          {" "}
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 24 24"
                            fill="#334155"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                              fill="#334155"
                            />
                          </svg>
                        </span>
                      </button>
                      <button
                        type="button"
                        className="flex items-center gap-2 px-4 py-2 text-xs font-semibold text-white bg-blue-700 border rounded-md hover:bg-blue-800 border-primary"
                        onClick={() => uploadImage.mutate(selectedImage)}
                      >
                        <span>Upload</span>{" "}
                        {uploadImage.isLoading || isLoading ? (
                          <ImSpinner2
                            size={28}
                            className="mx-auto animate-spin"
                          />
                        ) : (
                          <AiOutlineCloudUpload size={25} />
                        )}
                      </button>
                    </div>
                    <div>
                      {imageUploadSuccess && (
                        <p className="mt-2 text-sm text-center text-green-500">
                          Image Upload was successful
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <div className="w-full overflow-x-hidden">
        <div className="flex items-center gap-3 py-1 mb-3">
          <div
            className="p-2 text-gray-800 bg-white rounded cursor-pointer"
            onClick={() => navigate("/home/echo-webpage")}
          >
            <BiChevronLeft />
          </div>
          <h4 className="text-lg text-gray-400 capitalize">Back</h4>
        </div>
        <section
          className="h-[50vh] md:h-[40vh] bg-gray-700 bg-no-repeat bg-center bg-cover relative py-20 md:py-16 lg:py-28 flex items-center justify-center"
        >
          <img
            src={heroImageUrl}
            alt="hero-image"
            className="object-cover w-full h-[50vh] md:h-[40vh] absoulute"
            onError={(e) =>
              (e.currentTarget.src = "/media/image/servicesBg.png")
            }
          />
          <div className="flex items-center justify-center w-full h-full absolute z-20 max-w-[90%] mx-auto">
            <div className="w-full mx-auto space-y-4 text-center text-white">
              <form className="w-full space-y-1" onSubmit={updateHeroDetails}>
                {isLoading ? (
                  <ImSpinner2 size={28} className="mx-auto mb-6 animate-spin" />
                ) : (
                  <div>
                    <div className="w-full">
                      {/* <h1 className="text-xl font-semibold text-center bg-transparent outline-none md:text-4xl">
                        {storeName ?? "Store_Name"}
                      </h1> */}
                      <input
                        name="siteTitle"
                        type="text"
                        placeholder="Enter Store Name"
                        defaultValue={heroDetails.siteTitle}
                        onChange={(e) =>
                          setHeroDetails({
                            ...heroDetails,
                            siteTitle: e.target.value,
                          })
                        }
                        onClick={() => setPopOver1("heroDetailsUpdateButttons")}
                        className="w-full text-xl font-semibold text-center bg-transparent outline-none md:text-4xl placeholder:text-xl"
                      />
                    </div>
                    <div className="w-full">
                      <input
                        name="tagLine"
                        type="text"
                        placeholder="Enter a Tagline here"
                        defaultValue={heroDetails.tagLine}
                        onChange={(e) =>
                          setHeroDetails({
                            ...heroDetails,
                            tagLine: e.target.value,
                          })
                        }
                        onClick={() => setPopOver1("heroDetailsUpdateButttons")}
                        className="w-full text-base font-semibold text-center bg-transparent outline-none md:text-lg placeholder:text-lg"
                      />
                    </div>
                  </div>
                )}
                {popOver1 === "heroDetailsUpdateButttons" && (
                  <div className="flex items-center justify-center mt-4">
                    {" "}
                    <div className="flex items-center gap-4">
                      <button
                        type="submit"
                        className="text-xs py-1 px-2.5 bg-white rounded text-green-700"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setPopOver1(null);
                        }}
                        className="text-xs py-1 px-2.5 bg-white rounded text-red-700"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </form>
              <div className="flex flex-col items-center gap-4 md:flex-row md:justify-center">
                <button
                  className="px-6 py-2 text-xs text-gray-800 bg-gray-300 rounded sm:text-sm"
                  onClick={() => {
                    setSelectedImage(null);
                    setPreview(null);
                    setSuccess(false);
                    setImageUploadSuccess(false);
                    setActiveCategory(null);
                    setShowModals("heroBackground");
                  }}
                >
                  Change Background
                </button>
                <button
                  className="px-6 py-2 text-xs text-red-800 bg-white rounded sm:text-sm"
                  onClick={() => {
                    setShowModals("deleteHeroBackgroundImage");
                  }}
                >
                  Delete Background
                </button>
              </div>
            </div>
          </div>
          <div className="absolute top-0 z-10 w-full h-full bg-gray-700 bg-blend-overlay opacity-40"></div>
        </section>
        <section
          className={classNames(
            "relative px-4 py-6 space-y-6 bg-white",
            !templateSections.includes("Product") === true ? "" : "hidden"
          )}
        >
          <div className="text-center">
            <p className="text-lg font-semibold">Products</p>
            <p className="font-semibold text-gray-600"><span>{storeItemCount ?? ""}</span> Items</p>
          </div>
          {getStoreItems.status === "loading" ? (
            <div className="flex items-center justify-start py-5">
              <ImSpinner3
                size={18}
                className="mx-auto animate-spin fill-primary"
              />
            </div>
          ) : getStoreItems.status === "error" ? (
            <p className="mt-8 font-semibold text-center text-gray-400">
              {getStoreItems.error.message}
            </p>
          ) : allItems.length > 0 ? (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
              {allItems.map((item, index) => (
                <div key={item.menuItemId}>
                  <div
                    className="rounded-md cursor-pointer relative h-[180px] flex items-center justify-center"
                  >
                    <img
                      src={item.s3ImageUrl}
                      onError={(e) =>
                        (e.currentTarget.src = "/media/image/productBg.png")
                      }
                      alt="Item image"
                      className="absolute object-cover w-full h-full rounded-md bg-slate-50"
                    />
                    <div className="absolute z-10 w-full h-full text-sm transition bg-black rounded-md bg-opacity-10 duration-350 hover:bg-opacity-30"></div>
                    <p className="absolute z-20 hidden mt-1 font-semibold text-center text-white uppercase">
                      {item.itemName}
                    </p>
                    {(deliveryStatus === "1" && item.freeDelivery === "1") && (<p className="absolute bottom-3 left-2 py-1 px-1.5 bg-blue-700 text-white rounded text-xs">Free delivery</p>)}
                    {/* {deliveryStatus === "1" ? (<p className="absolute bottom-3 left-2 py-1 px-1.5 bg-blue-700 text-white rounded text-xs">Free delivery</p>) : item.freeDelivery === "1" ? (<p className="absolute bottom-3 left-2 py-1 px-1.5 bg-blue-700 text-white rounded text-xs">Free delivery</p>) : null} */}
                    {/* {item.freeDelivery === "1"  && deliveryStatus !== "0"  ? (<p className="absolute bottom-3 left-2 py-1 px-1.5 bg-blue-700 text-white rounded text-xs">Free delivery</p>) : null} */}
                    <div className="absolute z-30 flex items-center gap-3 top-2 right-2">
                      <p className="p-1 text-xs font-semibold text-white bg-gray-600 rounded bg-opacity-60 hover:bg-opacity-80">
                        <MdOutlineEdit
                          size={22}
                          onClick={() => {
                            setSelectedObject(item);
                            setShowModals("item");
                            setStep(2);
                          }}
                        />
                      </p>
                      <p className="p-1 text-xs font-semibold text-white bg-gray-600 rounded bg-opacity-60 hover:bg-opacity-80">
                        <MdOutlineClear
                          size={22}
                          onClick={() => {
                            setSelectedObject(item);
                            setShowModals("deleteItemInCategory");
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between gap-3 mt-1 text-sm" >
                    <p className="font-semibold text-gray-900 capitalize">
                      {item.itemName}
                    </p>
                    <p className="font-semibold text-gray-900 ">
                      &#x20A6;{item.itemPriceDigit}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="mt-8 font-semibold text-center text-gray-800">
              No product available, add your products
            </p>
          )}

          {(allItems.length > 0 && getStoreItems.hasNextPage) && (<button onClick={() => getStoreItems.fetchNextPage()}
            disabled={
              !getStoreItems.hasNextPage || getStoreItems.isFetchingNextPage
            } className="block w-40 py-1.5 mx-auto mt-12 text-center text-white bg-blue-700 hover:bg-blue-800 rounded text-sm">{getStoreItems.isFetchingNextPage ? "Please wait..." : "See more"}</button>)}
          {(allItems.length > 0 && !getStoreItems.hasNextPage) && (<p className="mt-12 text-base font-medium text-center text-gray-400">That's it!</p>)}

          <div className="flex items-center justify-center">
            <button
              className="px-6 py-2 text-sm text-white bg-blue-700 rounded hover:bg-blue-800"
              onClick={() => {
                if (allItems.length > 24) {
                  setShowModals("allowedItem");
                  return;
                }
                setSelectedImage(null);
                setPreview(null);
                setSuccess(false);
                setImageUploadSuccess(false);
                setShowModals("item");
              }}
            >
              Add product
            </button>
          </div>
          <MdOutlineClear
            size={24}
            className="absolute text-blue-700 cursor-pointer hover-blue-800 right-4 top-2"
            onClick={() => {
              setShowModals("delete");
              setSelectedSection("Product");
            }}
          />
        </section>
        <section
          className={classNames(
            "relative px-4 py-6 bg-white",
            !templateSections.includes("Services") === true ? "" : "hidden"
          )}
        >
          <div className="mb-8 text-center">
            <p className="text-xl font-semibold">Services</p>
          </div>
          {allServices.status === "loading" ? (
            <div className="flex items-center justify-start py-5">
              <ImSpinner3
                size={18}
                className="mx-auto animate-spin fill-primary"
              />
            </div>
          ) : allServices.status === "error" ? (
            <p className="mt-8 font-semibold text-center text-gray-400">
              {allServices.error.message}
            </p>
          ) : services.length > 0 ? (
            <div className="grid grid-cols-2 gap-4 mt-4 lg:grid-cols-3">
              {services.map((service, index) => (
                <div
                  className="rounded-md cursor-pointer relative h-[120px] flex items-center justify-center"
                  key={index}
                >
                  <img
                    src={service?.Service_S3ImageKey?.Image_Upload_Url}
                    onError={(e) =>
                      (e.currentTarget.src = "/media/image/servicesBg.png")
                    }
                    alt="No Image"
                    className="absolute object-cover w-full h-full rounded-md bg-slate-50"
                  />
                  <div className="absolute z-10 w-full h-full transition bg-black rounded-md bg-opacity-60 duration-350 hover:bg-opacity-80"></div>
                  <p className="absolute z-20 mt-1 font-semibold text-center text-white capitalize">
                    {service.Service}
                  </p>
                  <button
                    onClick={() => {
                      setShowModals("deleteService");
                      setSelectedObject(service);
                    }}
                    className="absolute z-20 text-white cursor-pointer top-3 right-2"
                  >
                    <MdOutlineClear size={22} />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="mt-6 font-semibold text-center text-gray-800">
              No service available; add your services
            </p>
          )}
          <div className="relative">
            {showPopOver && (
              <div className="flex items-center justify-center w-full">
                <div className="bg-white w-[350px] absolute z-30 bottom-12 rounded-lg shadow-lg p-4">
                  <div className="flex gap-3 mb-4">
                    <MdOutlineClear
                      size={30}
                      className="cursor-pointer"
                      onClick={() => setShowPopOver(false)}
                    />
                    <p className="text-sm font-medium text-gray-800">
                      Only a maximum of 6 services can be added.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center justify-center mt-6">
              <button
                className="px-6 py-2 text-sm text-white bg-blue-700 rounded hover:bg-blue-800"
                onClick={() => {
                  if (services.length > 5) {
                    setShowPopOver(true);
                  } else {
                    setShowModals("service");
                  }
                }}
              >
                Add service
              </button>
            </div>
          </div>
          <MdOutlineClear
            size={24}
            className="absolute text-blue-700 cursor-pointer hover-blue-800 right-4 top-7"
            onClick={() => {
              setShowModals("delete");
              setSelectedSection("Services");
            }}
          />
        </section>
        <section className={classNames(
          "bg-white",
          !templateSections.includes("About_Us") === true ? "" : "hidden"
        )}>
          <AboutUsSection setSelectedSection={setSelectedSection} templateSections={templateSections} setShowModals={setShowModals} showModals={showModals} setSidePanel={undefined} sidePanel={undefined} setShowDeleteModal={undefined} />

        </section>
        <section
          className={classNames(
            "relative py-6 space-y-6 bg-white",
            !templateSections.includes("Reviews") === true ? "" : "hidden"
          )}
        >
          <div className="text-center">
            <p className="text-lg font-semibold">Reviews</p>
            <p className="text-sm text-gray-500">
              Show Feedback From Customers On Your Website
            </p>
          </div>
          {satisfaction.status === "loading" ? (
            <div className="flex items-center justify-start py-5">
              <ImSpinner8 size={24} className="mx-auto animate-spin" />
            </div>
          ) : satisfaction.status === "error" ? (
            <span>Error: {satisfaction.error.message}</span>
          ) : (
            comments.length > 0 && (
              <div className="max-w-[95%] mx-auto grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {comments.map((comment: any, index: number) => (
                  <div
                    key={index}
                    className="px-3 py-3.5 border rounded-md space-y-1.5"
                  >
                    <p className="text-sm font-semibold truncate">
                      {comment.customer !== "" ? comment.customer : "Anonymous"}
                    </p>
                    <div className="w-[100px]">
                      <ReviewStars
                        reviews={Number(ratingsToNumber(comment.userRatings))}
                      />
                    </div>
                    <p className="text-xs text-gray-500">
                      {comment.userReview}
                    </p>
                  </div>
                ))}
              </div>
            )
          )}
          <MdOutlineClear
            size={24}
            className="absolute text-blue-700 cursor-pointer hover-blue-800 right-4 top-2"
            onClick={() => {
              setShowModals("delete");
              setSelectedSection("Reviews");
            }}
          />
        </section>
        {templateSections.length > 0 && (
          <section className="relative py-6 bg-white border-t-[16px] border-slate-100">
            <div className="mb-8 text-center">
              <p className="text-lg font-semibold">Add section</p>
            </div>
            <div className="max-w-[95%] mx-auto grid grid-cols-2 md:grid-cols-4 gap-8">
              {templateSections.map((section: string, index: number) => (
                <div
                  className="rounded-md cursor-pointer"
                  key={index}
                  onClick={(e) => {
                    const payload: any = {
                      sectionVar: section,
                    }
                    handleUpdateTemplateSection.mutate(payload);
                  }}
                >
                  <img
                    src={
                      section === "Product"
                        ? "/media/image/ProductSection.png"
                        : section === "Services"
                          ? "/media/image/ServicesSection.png"
                          : section === "Reviews"
                            ? "/media/image/ReviewsSection.png"
                            : "/media/image/ContactSection.png"
                    }
                    alt=""
                    onError={(e) =>
                      (e.currentTarget.src = "/media/image/productBg.png")
                    }
                    className="h-[100px] w-full bg-blue-100 rounded-md shadow"
                  />
                  <p className="text-sm font-semibold text-center text-gray-800" >
                    {section.replace(/_/g, ' ')}
                  </p>
                </div>
              ))}
            </div>
          </section>
        )}
        <div className="relative">
          <FooterSection setShowModals={setShowModals} showModals={showModals} formik5={formik5} setSelectedObject={setSelectedObject} setOpeningDaysFields={setOpeningDaysFields} formik3={formik3} setSidePanel={undefined} sidePanel={undefined} />
        </div>
        <div className="flex items-center justify-center mt-4">
          <div className="flex items-center gap-8">
            <button
              className="px-8 py-2 text-sm text-white bg-blue-700 rounded hover:bg-blue-800"
              onClick={() => {
                handlePublishWebPage.mutate();
              }}
            >
              {handlePublishWebPage.isLoading ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Publish"
              )}
            </button>
            <button
              className="px-8 py-2 text-sm text-white bg-blue-700 rounded hover:bg-blue-800"
              onClick={() => {
                window.open(`/store${storeKey}`);
              }}
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={showModals === "delete"}
        onToggle={() => {
          setShowModals(null);
          setSelectedSection(null);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-lg">
              Would you like to remove this section?
            </p>
          </div>
          <div className="flex flex-col justify-center gap-2 mt-5 md:flex-row md:mt-8">
            <div>
              <button
                className="w-full px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 border rounded-md hover:bg-blue-800 md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  const payload: any = {
                    sectionVar: selectedSection,
                  };
                  handleUpdateTemplateSection.mutate(payload);
                }}
              >
                {handleUpdateTemplateSection.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="w-full px-4 py-2 text-sm font-semibold text-center text-blue-700 bg-white border border-blue-700 rounded-md hover:bg-gray-50 md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedSection(null);
                  setShowModals(null);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModals === "deleteService"}
        onToggle={() => {
          setSelectedObject({});
          setShowModals(null);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-lg">
              Would you like to delete this service?
            </p>
          </div>
          <div className="flex flex-col justify-center gap-2 mt-5 md:flex-row md:mt-8">
            <div>
              <button
                className="w-full px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 border rounded-md hover:bg-blue-800 md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  handleDeleteService.mutate({
                    service: selectedObject.Service,
                  });

                  const payload: any = {
                    objectKey:
                      selectedObject.Service_S3ImageKey[0].imageIdentifier,
                  };
                  handleDeleteS3Object.mutate(payload);
                }}
              >
                {handleDeleteService.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="w-full px-4 py-2 text-sm font-semibold text-center text-blue-700 bg-white border border-blue-700 rounded-md hover:bg-gray-50 md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedSection(null);
                  setShowModals(null);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModals === "deleteHeroBackgroundImage"}
        onToggle={() => {
          setShowModals(null);
          setSelectedSection(null);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-4">
            <FiTrash2 size={50} className="mx-auto text-blue-700 w-28" />
          </div>
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700">
              You are about to delete the hero background image from your
              webpage
            </p>
          </div>
          <div className="flex flex-col justify-center gap-2 mt-5 md:flex-row md:mt-8">
            <div>
              <button
                className="w-full px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 border rounded-md md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  const payload: any = {
                    objectKey: "/cusecho" + store.name + "/heroImage/image.png",
                  };
                  handleDeleteS3Object.mutate(payload);
                }}
              >
                {handleDeleteS3Object.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="w-full px-4 py-2 text-sm font-semibold text-center text-gray-800 bg-white border rounded-md md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setShowModals(null);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModals === "deleteCategory"}
        onToggle={() => {
          setShowModals(null);
          setSelectedObject({});
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-lg">
              You are about to delete this category
            </p>
          </div>
          <div className="flex flex-col justify-center gap-2 mt-5 md:flex-row md:mt-8">
            <div>
              <button
                className="w-full px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 border rounded-md hover:bg-blue-800 md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  handleDeleteProductCategory.mutate({
                    categoryName: selectedObject.categoryName,
                  });
                  // handleDeleteS3Object.mutate({
                  //   objectKey: selectedObject.Service_S3ImageKey[0].imageIdentifier,
                  // })
                }}
              >
                {handleDeleteProductCategory.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="w-full px-4 py-2 text-sm font-semibold text-center text-blue-700 bg-white border border-blue-700 rounded-md hover:bg-gray-50 md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedObject({});
                  setShowModals(null);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        size="medium"
        show={showModals === "openingDays"}
        onToggle={() => {
          setShowModals(null);
          setSelectedObject({});
          setOpeningDaysFields({
            start_time: "",
            close_time: "",
          });
          setEventType(-1);
        }}
      >
        <div className="relative flex flex-col p-3 px-8">
          <div className="text-center">
            <p className="text-lg font-semibold">Edit time</p>
          </div>
          <p className="font-semibold uppercase lg:hidden">
            {selectedObject.day}
          </p>
          <div className="flex items-center justify-between mt-6">
            <p className="hidden font-semibold uppercase lg:block">
              {selectedObject.day}
            </p>
            <div className="flex items-center gap-4">
              <input
                type="startTime"
                placeholder=""
                className="p-2 text-sm border rounded-md outline-none w-28"
                value={openingDaysFields.start_time}
                onChange={(e) =>
                  setOpeningDaysFields({
                    ...openingDaysFields,
                    start_time: e.target.value,
                  })
                }
              />
              <input
                type="closeTime"
                placeholder=""
                className="p-2 text-sm border rounded-md outline-none w-28"
                value={openingDaysFields.close_time}
                onChange={(e) =>
                  setOpeningDaysFields({
                    ...openingDaysFields,
                    close_time: e.target.value,
                  })
                }
              />
              <div className="flex items-center gap-2">
                {handleUpdateOpeningHour.isLoading && eventType === 1 ? (
                  <ImSpinner2 size={22} className="mr-2 animate-spin" />
                ) : null}
                <label className="relative inline-flex items-center cursor-pointer ">
                  <input
                    type="checkbox"
                    checked={(Number(selectedObject.switch_button) === 1)}
                    className="sr-only peer"
                    onChange={() => {
                      setEventType(1);
                      handleUpdateOpeningHour.mutate({
                        openingDay: selectedObject.day,
                        close_time: selectedObject.close_time,
                        start_time: selectedObject.start_time,
                        switch_button: selectedObject.switch_button,
                        eventType: "1",
                        switchButton:
                          selectedObject.switch_button === "0" ? "1" : "0",
                      });
                    }}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-6">
            <button
              type="submit"
              onClick={() => {
                setEventType(0);
                handleUpdateOpeningHour.mutate({
                  openingDay: selectedObject.day,
                  close_time: openingDaysFields.close_time,
                  start_time: openingDaysFields.start_time,
                  switch_button: selectedObject.switch_button,
                  eventType: "0",
                  switchButton:
                    selectedObject.switch_button === "0" ? "1" : "0",
                });
              }}
              className="px-6 py-2 text-sm text-white bg-blue-700 rounded hover:bg-blue-800"
            >
              {handleUpdateOpeningHour.isLoading && eventType === 0 ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        size="medium"
        show={showModals === "publishWebPage"}
        onToggle={() => {
          setShowModals(null);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="text-center">
            <img src={getSvg("check")} alt="icon" className="w-24 mx-auto" />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-xl">
              Your store has been created successfully
            </h1>
          </div>
          <div className="flex flex-col justify-center gap-2 mt-5 md:flex-row md:mt-8">
            <button
              // className="px-2.5 py-1.5 text-sm text-blue-600 font-semibold rounded bg-white hover:bg-gray-50 border border-blue-700"
              className="w-full px-4 py-2 text-sm font-semibold text-center text-blue-600 bg-white border border-blue-700 rounded-md hover:bg-gray-50 md:w-44 md:px-6 lg:px-8"
              onClick={() => {
                navigator.clipboard.writeText(text);
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 4000);
                
              }}
            >
              Copy store link
            </button>
            <button
              type="button"
              className="w-full px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 border rounded-md hover:bg-blue-800 border-primary md:w-44 md:px-6 lg:px-8"
              onClick={() => {
                window.open(`/store${storeKey}`);
                // setShowPublishedModal(false);
              }}
            >
              View Store
            </button>
          </div>
          {copied && (
            <p className="mt-2 text-sm text-center text-green-700">
              Store link copied to clipboard
            </p>
          )}
        </div>
      </Modal>
      <Modal
        size="medium"
        show={showModals === "allowedCategory"}
        onToggle={() => {
          setShowModals(null);
        }}
      >
        <div className="relative flex flex-col p-6 ">
          <div className="text-center">
            <img
              src={getImage("connection-error")}
              alt="icon"
              className="w-24 mx-auto"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-lg">
              Maximum of 5 categories allowed
            </h1>
            <p>
              To increase this limit, contact our{" "}
              <Link
                to="/support/limit-increase"
                className="text-red-600 hover:text-red-800"
              >
                support team.
              </Link>
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        size="medium"
        show={showModals === "allowedItem"}
        onToggle={() => {
          setShowModals(null);
        }}
      >
        <div className="relative flex flex-col p-6 ">
          <div className="text-center">
            <img
              src={getImage("connection-error")}
              alt="icon"
              className="w-24 mx-auto"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-lg">
              Maximum of 25 products allowed.
            </h1>
            <p>
              To increase this limit, contact our{" "}
              <Link
                to="/support/limit-increase"
                className="text-red-600 hover:text-red-800"
              >
                support team.
              </Link>
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModals === "footer"}
        onToggle={() => {
          setShowModals(null);
        }}
      >
        <div className="relative flex flex-col p-3 md:py-6 md:px-8 h-[70vh] sm:h-full">
          <form onSubmit={formik5.handleSubmit} className="">
            <p className="text-lg text=gray-800 font-semibold mb-4">
              Edit Footer
            </p>
            <div className="space-y-4">
              <div className="w-full">
                <Input
                  formik={formik5}
                  name="phoneNumber"
                  label="Phone"
                  type="text"
                  placeHolder="Phone number must start with a plus (+) sign"
                />
                {formik5.values.phoneNumber && formik5.errors.phoneNumber && (
                  <InputMessage message={`${formik5.errors.phoneNumber}`} />
                )}
              </div>
              <div className="w-full">
                <Input
                  formik={formik5}
                  name="email"
                  label="Email"
                  type="email"
                  placeHolder=""
                />
                {formik5.values.email && formik5.errors.email && (
                  <InputMessage message={`${formik5.errors.email}`} />
                )}
              </div>
              <div className="w-full">
                <Input
                  formik={formik5}
                  name="linkedIn_url"
                  label="LinkedIn URL"
                  type="text"
                  placeHolder=""
                />
                {formik5.values.linkedIn_url && formik5.errors.linkedIn_url && (
                  <InputMessage message={`${formik5.errors.linkedIn_url}`} />
                )}
              </div>
              <div className="w-full">
                <Input
                  formik={formik5}
                  name="instagram_url"
                  label="Instagram URL"
                  type="text"
                  placeHolder=""
                />
                {formik5.values.instagram_url &&
                  formik5.errors.instagram_url && (
                    <InputMessage message={`${formik5.errors.instagram_url}`} />
                  )}
              </div>
              <div className="w-full">
                <Input
                  formik={formik5}
                  name="facebook_url"
                  label="Facebook URL"
                  type="text"
                  placeHolder=""
                />
                {formik5.values.facebook_url && formik5.errors.facebook_url && (
                  <InputMessage message={`${formik5.errors.facebook_url}`} />
                )}
              </div>
              <div className="w-full">
                <Input
                  formik={formik5}
                  name="twitter_url"
                  label="Twitter URL"
                  type="text"
                  placeHolder=""
                />
                {formik5.values.twitter_url && formik5.errors.twitter_url && (
                  <InputMessage message={`${formik5.errors.twitter_url}`} />
               )}
              </div>
              <div className="w-full">
                <Input
                  formik={formik5}
                  name="tiktok_url"
                  label="TikTok URL"
                  type="text"
                  placeHolder=""
                />
                {formik5.values.tiktok_url && formik5.errors.tiktok_url && (
                  <InputMessage message={`${formik5.errors.tiktok_url}`} />
                )}
              </div>
            </div>
            <div className="flex items-center justify-center mt-6 mb-6">
              <button
                type="submit"
                className="px-16 py-2 text-sm font-medium text-white bg-blue-700 rounded-md hover:bg-blue-800"
              >
                {formik5.isSubmitting ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        show={showModals === "deleteItemInCategory"}
        onToggle={() => {
          setShowModals(null);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-lg">
              You are about to delete this item from {selectedObject.itemCategory}
            </p>
          </div>
          <div className="flex flex-col justify-center gap-2 mt-5 md:flex-row md:mt-8">
            <div>
              <button
                disabled={deleteMenu.isLoading}
                className="w-full px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 border rounded-md hover:bg-blue-800 md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  deleteMenu.mutate({
                    itemId: selectedObject.menuItemId,
                  });
                  const payload: any = {
                    objectKey: selectedObject.s3ImageUrl[0].imageIdentifier,
                  };
                  handleDeleteS3Object.mutate(payload);
                  handleReduceItemCount.mutate();
                }}
              >
                {deleteMenu.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="w-full px-4 py-2 text-sm font-semibold text-center text-blue-700 bg-white border border-blue-700 rounded-md hover:bg-gray-50 md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedObject({});
                  setShowModals(null);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EchoWebpage1;
