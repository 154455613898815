import { useState } from "react";
import { BsArrowLeft, BsChevronRight } from "react-icons/bs";
import { classNames } from "../../utils";
import { BiSearch } from "react-icons/bi";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdKeyboardBackspace, MdOutlineArticle } from "react-icons/md";
import Modal from "../../components/Dashboard/Modal";

const menuArray = ["Appetizers", "Salads & Soups", "Desserts"];

const itemsArray = [
  "Spicy Lobster",
  "Spicy Lobster",
  "Spicy Lobster",
  "Spicy Lobster",
  "Spicy Lobster",
  "Spicy Lobster",
  "Spicy Lobster",
  "Spicy Lobster",
  "Spicy Lobster",
  "Spicy Lobster",
  "Spicy Lobster",
];

const ReservationPage = () => {
  const [selectedCategory, setSelectedcategory] = useState("");
  const [cart, setCart] = useState(false);
  const [order, setOrder] = useState<number[]>([]);
  const [showCheckoutModal, setCheckoutModal] = useState(false);
  const [step, setStep] = useState(1);

  return (
    <>
      <div
        className="relative hidden w-full h-screen overflow-hidden overflow-y-auto bg-center bg-no-repeat bg-cover md:block"
        style={{ backgroundImage: `url("/media/image/reservation-bg1.jpg")` }}
      >
        <div className="mt-10 mb-5">
          <h2 className="text-5xl md:text-6xl font-bold text-center text-white capitalize font-[Tangerine]">
            Welcome To The Emperor&apos;s Place Guesthouse
          </h2>
        </div>
        <div className="flex flex-col items-center justify-center h-full p-3 space-y-5 md:space-y-0 md:space-x-5 md:flex-row">
          <div className="w-full h-full p-2 overflow-x-hidden overflow-y-auto bg-white rounded-md md:w-1/2 lg:w-2/6">
            <div className="px-2 py-4 capitalize">
              <h4 className="text-2xl font-semibold">Make a reservation</h4>
              <p className="text-base text-gray-400">
                Reserve a spot at our guesthouse
              </p>
            </div>
            <div className="px-2 mt-5 space-y-8">
              <div className="text-left">
                <label className="block mb-1 font-semibold">Full Name</label>
                <input
                  type="text"
                  maxLength={4}
                  className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                  placeholder="John Doe"
                />
              </div>
              <div className="text-left">
                <label className="block mb-1 font-semibold">Phone Number</label>
                <input
                  type="text"
                  maxLength={4}
                  className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                  placeholder="+xxx xxx xxxx"
                />
                {/* <span className="text-xs text-gray-400 capitalize"><small>Please enter your loyalty card if you have one.</small></span> */}
              </div>
              <div className="flex justify-between space-x-10">
                <div className="w-1/2 text-left">
                  <label className="block mb-1 font-semibold">
                    Check In date
                  </label>
                  <input
                    type="date"
                    maxLength={4}
                    className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                    placeholder="17"
                  />
                </div>
                <div className="w-1/2 text-left">
                  <label className="block mb-1 font-semibold">
                    Check Out Date
                  </label>
                  <input
                    type="date"
                    maxLength={4}
                    className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                    placeholder="XD1234DF"
                  />
                </div>
              </div>
              <div className="flex justify-between space-x-10">
                <div className="w-1/2 text-left">
                  <label className="block mb-1 font-semibold">Adults</label>
                  <div className="flex items-center w-full space-x-6">
                    <button className="px-3.5 text-2xl font-bold text-center border border-gray-300 rounded-md outline-none">
                      -
                    </button>
                    <p className="text-lg font-bold text-center">{1}</p>
                    <button className="px-3 text-2xl font-semibold text-center border border-gray-300 rounded-md outline-none">
                      +
                    </button>
                  </div>
                </div>
                <div className="w-1/2 text-left">
                  <label className="block mb-1 font-semibold">Children</label>
                  <div className="flex items-center w-full space-x-6">
                    <button className="px-3.5 text-2xl font-bold text-center border border-gray-300 rounded-md outline-none">
                      -
                    </button>
                    <p className="text-lg font-semibold text-center">{1}</p>
                    <button className="px-3 text-2xl font-bold text-center border border-gray-300 rounded-md outline-none">
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <button className="mx-auto w-[80%] bg-primary p-3 mt-5 rounded-lg text-center text-white fonr-semibold hidden">
                  Place Order
                </button>
              </div>
            </div>
          </div>
          <div className="w-full h-full p-2 overflow-x-hidden overflow-y-auto bg-white rounded-md md:w-1/2 lg:w-2/6">
            <div className="px-2 py-4 capitalize">
              <h4 className="text-2xl font-semibold">Available Rooms</h4>
              <p className="text-base text-gray-400">
                Available rooms for the days you selected
              </p>
            </div>
            <div className="px-2 mt-5">
              <h4 className="mb-2 text-xl font-semibold">
                Please Select A Room
              </h4>
              <div className="grid grid-cols-2 gap-4">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setCheckoutModal(true)}
                      className="max-w-sm overflow-hidden rounded-md shadow-lg cursor-pointer shadow-gray-200"
                    >
                      <img
                        className="w-full"
                        src="/media/image/menu-bg.jpg"
                        alt="food"
                      />
                      <div className="flex items-start justify-between px-2 pt-2 pb-1">
                        <p className="text-base font-bold">Standard Room</p>
                        <p className="mt-0.5 text-sm font-normal text-gray-500">
                          {"$42/Night"}
                        </p>
                      </div>
                      <div className="px-2 pb-4">
                        <p className="text-sm font-normal">
                          Contains 1 bed, 2 chairs, 1 bathroom and a tv set
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {cart && (
            <div className="w-full h-full overflow-x-hidden overflow-y-auto bg-white rounded-md md:w-2/6 lg:w-1/4">
              {/* <div className="flex flex-col h-full">
                            <div className="w-full space-y-2 text-center">
                                <h4 className="px-2 pt-4 text-2xl font-bold text-center capitalize">Checkout</h4>
                                <div className="relative w-full px-4">
                                    <div className="pt-2 space-y-5">
                                        {
                                            itemsArray.slice(0, 3).map((item: any, index: number) => {
                                                return(
                                                    <div className="flex items-center justify-center space-x-2.5">
                                                        <span className="text-sm font-semibold text-left">{item}</span>
                                                        <span className="text-xs text-gray-300">- - - - - - - - - - - - - -</span>
                                                        <span className="text-primary">&#8358;{"2500"}</span>
                                                        <div className="flex items-center justify-between space-x-2 text-sm text-primary">
                                                            <button className="p-0.5 text-sm font-semibold text-center border rounded-md border-primary"><AiOutlineMinus size={12} className="fill-black" strokeWidth={12} /></button>
                                                            <span>{1}</span>
                                                            <button className="p-0.5 text-sm font-semibold text-center border rounded-md border-primary"><AiOutlinePlus size={12} className="fill-black" strokeWidth={12} /></button>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <hr className="w-[100%] mx-auto border border-gray-100 rounded-sm mt-10 mb-2"/>
                                    <div className="flex items-center justify-between space-x-2.5 px-1">
                                        <span className="text-sm font-semibold">Total</span>
                                        <span className="text-xs text-gray-300">- - - - - - - - - - - - - - - - - - - - - - - - - -</span>
                                        <span className="text-primary">&#8358;{"2500"}</span>
                                    </div>
                                    <div className="space-y-5 mt-14">
                                        <div className="text-left">
                                            <label className="block mb-1 font-semibold">Table Number</label>
                                            <input type="number" maxLength={4} className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300" placeholder="17" />
                                        </div>
                                        <div className="text-left">
                                            <label className="block mb-1 font-semibold">Loyalty Card ID</label>
                                            <input type="number" maxLength={4} className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300" placeholder="XD1234DF" />
                                            <span className="text-xs text-gray-400 capitalize"><small>Please enter your loyalty card if you have one.</small></span>
                                            <button className="mx-auto w-[80%] bg-primary p-3 mt-5 rounded-lg text-center text-white fonr-semibold">Place Order</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center justify-between p-8 space-y-10 bg-white md:p-10 md:space-y-0 md:flex-row">
          <div>
            <p className="text-sm font-semibold text-green-800 ml-14">
              Powered by
            </p>
            <img src="/media/image/logo.png" alt="logo" className="w-52" />
          </div>
          <div className="flex items-start space-x-20 md:space-x-10">
            <div>
              <h6 className="text-lg font-semibold capitalize">
                More about cusecho
              </h6>
              <p className="text-base">Visit Our Website</p>
              <p className="text-base">Contact Us</p>
            </div>
            <div>
              <h6 className="text-lg font-semibold capitalize">
                More about the emperor&apos;s place
              </h6>
              <p className="text-base">Our Services</p>
              <p className="text-base">Leave A Review</p>
              <p className="text-base">Leave A Complaint</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col h-screen py-2 overflow-hidden overflow-y-auto bg-center bg-no-repeat bg-cover md:hidden"
        style={{ backgroundImage: `url("/media/image/reservation-bg.jpg")` }}
      >
        {step === 1 && (
          <div className="w-full h-full p-2 overflow-x-hidden overflow-y-auto rounded-md md:w-1/2 lg:w-2/6">
            <div className="flex items-center gap-6 px-2 py-4 text-white"><MdKeyboardBackspace size={24} /><p className="font-semibold">Go Back To Homepage</p></div>
            <div className="px-2 py-4 text-white capitalize">
              <h4 className="text-2xl font-semibold">Make a reservation</h4>
              <p className="text-base text-gray-100">
                Reserve a spot at our restaurant for you and your loved ones
              </p>
            </div>
            <div className="px-2 mt-5 space-y-8">
              <div className="text-left">
                <label className="block mb-1 font-semibold text-white">
                  Full Name
                </label>
                <input
                  type="text"
                  maxLength={4}
                  className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                  placeholder="John Doe"
                />
              </div>
              <div className="text-left">
                <label className="block mb-1 font-semibold text-white">
                  Phone Number
                </label>
                <input
                  type="text"
                  maxLength={4}
                  className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                  placeholder="+xxx xxx xxxx"
                />
                {/* <span className="text-xs text-gray-400 capitalize"><small>Please enter your loyalty card if you have one.</small></span> */}
              </div>
              <div className="flex justify-between space-x-10">
                <div className="w-1/2 text-left">
                  <label className="block mb-1 font-semibold text-white">
                    Check In date
                  </label>
                  <input
                    type="date"
                    maxLength={4}
                    className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                    placeholder="17"
                  />
                </div>
                <div className="w-1/2 text-left">
                  <label className="block mb-1 font-semibold text-white">
                    Check Out Date
                  </label>
                  <input
                    type="date"
                    maxLength={4}
                    className="block w-full p-2 text-gray-300 transition duration-300 ease-linear border border-gray-300 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                    placeholder="XD1234DF"
                  />
                </div>
              </div>
              <div className="flex justify-between space-x-10">
                <div className="w-1/2 text-left">
                  <label className="block mb-1 font-semibold text-white">
                    Adults
                  </label>
                  <div className="flex items-center w-full space-x-6">
                    <button className="px-3.5 text-2xl font-bold text-white text-center border border-white rounded-md outline-none">
                      -
                    </button>
                    <p className="text-lg font-bold text-center text-white">
                      {1}
                    </p>
                    <button className="px-3 text-2xl font-semibold text-center text-white border border-white rounded-md outline-none">
                      +
                    </button>
                  </div>
                </div>
                <div className="w-1/2 text-left">
                  <label className="block mb-1 font-semibold text-white">
                    Children
                  </label>
                  <div className="flex items-center w-full space-x-6">
                    <button className="px-3.5 text-2xl font-bold text-white text-center border border-white rounded-md outline-none">
                      -
                    </button>
                    <p className="text-lg font-semibold text-center text-white">
                      {1}
                    </p>
                    <button className="px-3 text-2xl font-bold text-center text-white border border-white rounded-md outline-none">
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div className="max-w-[80%] mx-auto flex items-center justify-center">
                <button
                  className="p-3 mt-5 font-semibold text-center text-white rounded-lg bg-primary"
                  onClick={() => setStep(2)}
                >
                  View Available Rooms
                </button>
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="w-full h-full p-2 overflow-x-hidden overflow-y-auto rounded-md md:w-1/2 lg:w-2/6">
            <div className="px-2 py-4 text-white capitalize">
              <h4 className="text-2xl font-semibold">Available Rooms</h4>
              <p className="text-base">
                Available rooms for the days you selected
              </p>
            </div>
            <div className="px-2 mt-5">
              <h4 className="mb-2 text-xl font-semibold text-white">
                Please Select A Room
              </h4>
              <div className="grid grid-cols-2 gap-4">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setStep(3)}
                      className="max-w-sm overflow-hidden bg-white rounded-md shadow-lg cursor-pointer"
                    >
                      <img
                        className="w-full"
                        src="/media/image/menu-bg.jpg"
                        alt="food"
                      />
                      <div className="flex items-start justify-between px-2 pt-2 pb-1 ">
                        <p className="text-base font-bold">Standard Room</p>
                        <p className="mt-0.5 text-sm font-normal text-gray-500">
                          {"$42/Night"}
                        </p>
                      </div>
                      <div className="px-2 pb-4">
                        <p className="text-sm font-normal">
                          Contains 1 bed, 2 chairs, 1 bathroom and a tv set
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="relative p-2">
            <div className="flex flex-col mx-auto">
              <div className="w-full px-2 py-4 text-center text-white capitalize">
                <h4 className="text-2xl font-semibold">Check Out</h4>
                <p className="text-base">
                  Please confirm your details are correct
                </p>
              </div>
            </div>
            <div className="mx-auto">
              <div className="px-2 py-4 space-y-4 text-white border-b-[1px] border-gray-400">
                <div className="grid grid-cols-3 gap-3">
                  <p className="">Check In Date</p>
                  <p className="text-center">--------------</p>
                  <p className="">27th July, 2023</p>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <p className="">Check Out Date</p>
                  <p className="text-center">--------------</p>
                  <p className="">27th July, 2023</p>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <p className="">Selected Room</p>
                  <p className="text-center">--------------</p>
                  <p className="">Standard Room</p>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <p className="">Adults</p>
                  <p className="text-center">--------------</p>
                  <p className="">1</p>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <p className="">Children</p>
                  <p className="text-center">--------------</p>
                  <p className="">0</p>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-3 px-2 py-4 pb-0 text-white">
                <p className="">Total</p>
                <p className="text-center">---------------------</p>
                <p className="">$4500</p>
              </div>
              <div className="mt-4">
                <label className="block mb-1 text-xs font-normal text-gray-100">
                  Loyalty Card ID
                </label>
                <input
                  name="id"
                  type="text"
                  placeholder="XF#$%TY"
                  //   {...(formik ? formik.getFieldProps(name) : [])}
                  //   disabled={disabled}
                  className={classNames(
                    "border border-gray-300 py-2 px-5 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm"
                  )}
                />
                <p className="block mb-1 text-xs font-normal text-gray-100">
                  Please Add Your Loyalty Card If You Have One
                </p>
              </div>
              <div className="flex items-center justify-center mt-6 mb-10">
                <button
                  type="submit"
                  className="px-16 py-2 text-sm font-medium text-white rounded-md bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 "
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Make Reservation
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal show={showCheckoutModal} onToggle={() => setCheckoutModal(false)}>
        <div className="relative p-2">
          <div className="max-w-[80%] flex flex-col mx-auto bg-white">
            <div className="w-full px-2 py-4 text-center capitalize">
              <h4 className="text-2xl font-semibold">Check Out</h4>
              <p className="text-base text-gray-400">
                Please confirm your details are correct
              </p>
            </div>
          </div>
          <div className="max-w-[80%] mx-auto">
            <div className="px-2 py-4 space-y-4 border-b-[1px] border-gray-400">
            <div className="grid grid-cols-3 gap-3">
                  <p className="">Check In Date</p>
                  <p className="text-center">--------------</p>
                  <p className="">27th July, 2023</p>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <p className="">Check Out Date</p>
                  <p className="text-center">--------------</p>
                  <p className="">27th July, 2023</p>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <p className="">Selected Room</p>
                  <p className="text-center">--------------</p>
                  <p className="">Standard Room</p>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <p className="">Adults</p>
                  <p className="text-center">--------------</p>
                  <p className="">1</p>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <p className="">Children</p>
                  <p className="text-center">--------------</p>
                  <p className="">0</p>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-3 px-2 py-4 pb-0">
              <p className="">Total</p>
              <p className="text-center">--------------</p>
              <p className="text-primary">$4500</p>
            </div>
            <div className="mt-4">
              <label className="block mb-1 text-xs font-normal text-gray-800">
                Loyalty Card ID
              </label>
              <input
                name="id"
                type="text"
                placeholder="XF#$%TY"
                //   {...(formik ? formik.getFieldProps(name) : [])}
                //   disabled={disabled}
                className={classNames(
                  "border border-gray-300 py-2 px-5 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm"
                )}
              />
              <p className="block mb-1 text-xs font-normal text-gray-600">
                Please Add Your Loyalty Card If You Have One
              </p>
            </div>
            <div className="flex items-center justify-center mt-12 mb-10">
              <button
                type="submit"
                className="px-16 py-2 text-sm font-medium text-white rounded-md bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 "
                // onClick={(e) => {
                //   e.preventDefault();
                //   setShowModal(true);
                // }}
              >
                Make Reservation
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReservationPage;
