// @ts-nocheck

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import {
  getTemplateSections,
  updateTemplateSection,
} from "../../../../../../../api/Menu";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import { classNames } from "../../../../../../../utils";

const AddSections = ({ setSidePanel, sidePanel }) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;

  let templateSections: any[] = [];

  const handleUpdateTemplateSection = useMutation<any, Error>({
    mutationFn: async (data) => {
      return await updateTemplateSection(queryKey, data.sectionVar, "1");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("getStoreTemplateSections");
    },
  });

  const loadTemplateSections = useQuery<any, Error>({
    queryKey: ["getStoreTemplateSections"],
    queryFn: async () => {
      const response = await getTemplateSections(storeKey);
      return response.data;
    },
  });

  if (loadTemplateSections.data) {
    templateSections = loadTemplateSections.data.Template_Sections;
  }

  return (
    <div>
      {templateSections.length > 0 && (
        <section className="relative px-4 py-6 bg-white">
          <div className="mb-8 text-center">
            <p className="text-lg font-semibold">Add Section</p>
          </div>
          <div className="max-w-[95%] mx-auto grid grid-cols-2 md:grid-cols-3 gap-4 mb-4">
            {templateSections.map((section: string, index: number) => (
              <div
                className={classNames("rounded-md cursor-pointer", section === "Contact" ? "hidden" : "")}
                key={index}
                onClick={(e) => {
                  handleUpdateTemplateSection.mutate({
                    sectionVar: section,
                  });
                  if (section === "About_Us") {
                    setSidePanel("aboutus");
                    return;
                  }
                  if (section === "Product") {
                    setSidePanel("products");
                  } else {
                    setSidePanel(section.toLowerCase());
                  }
                  
                }}
              >
                <img
                  src={
                    section === "Product"
                      ? "/media/image/ProductSection.png"
                      : section === "Services"
                      ? "/media/image/ServicesSection.png"
                      : section === "Reviews"
                      ? "/media/image/ReviewsSection.png"
                      : "/media/image/ContactSection.png"
                  }
                  alt=""
                  onError={(e) =>
                    (e.currentTarget.src = "/media/image/productBg.png")
                  }
                  className={classNames("h-[100px] w-full bg-blue-100 rounded-md shadow", section === "Contact" ? "hidden" : "")}
                />
                <p className={classNames("text-xs mt-1 font-semibold text-center text-gray-800", section === "Contact" ? "hidden" : "")} >
                  {section.replace(/_/g, ' ')}
                </p>
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default AddSections;
