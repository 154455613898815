interface BackButtonProps {
    type?: any;
    onClick?: () => void;
}

const BackButton = ({ type = "button", onClick } : BackButtonProps) => {
    return(
        <button type={type} className="text-base text-gray-400" onClick={onClick}>{"< Back"}</button>
    )
};


export default BackButton;