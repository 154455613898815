// @ts-nocheck

import React, { Fragment, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Input } from "../../EchoWebpage1";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import {
  delectS3Object,
  deleteService,
  getServices,
  uploadService,
} from "../../../../../../../api/Menu";
import { ImSpinner2 } from "react-icons/im";
import AddService from "./AddService";
import { Menu, Transition } from "@headlessui/react";
import { IoMdMore } from "react-icons/io";

const serviceValues = {
  service: "",
};

const ServicesSidePanel = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  let services: any[] = [];
  const [step, setStep] = useState(1);
  const [editServicesValue, setEditServicesValue] = useState("");

  const formik = useFormik({
    initialValues: serviceValues,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (values.service === "") {
        return;
      }
      setSubmitting(true);
      const payload = {
        platformKey,
        services: [values.service],
      };

      try {
        const response = await uploadService(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries("getStoreServices");
          resetForm({});
          setSubmitting(false);
          setStep(1);
          return;
        }
      } catch (err) {
        console.log({ err });
        // setError(err.message);
      }
      setSubmitting(false);
    },
  });

  const allServices = useQuery<any, Error>({
    queryKey: ["getStoreServices"],
    queryFn: async () => {
      const response = await getServices(queryKey,storeKey);
      return response.data;
    },
    onSuccess: (data) => {},
  });

  const handleDeleteService = useMutation({
    mutationFn: async (data) => {
      return await deleteService(storeKey, data.service);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getServices");
    },
  });

  if (allServices.data) {
    services = allServices.data.Business_Services;
    // console.log(services)
  }

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        objectKey: data.key,
      };
      return await delectS3Object(payload);
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({
      //   queryKey: ["getHeroBackgroundImageUrl"],
      // });
    },
  });

  return (
    <div>
      {step === 1 ? (
        <div className="p-3">
          <h1 className="text-xl text-gray-800 mb-3 font-semibold">Services</h1>
          <button
            type="button"
            className="text-sm w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mb-3"
            onClick={() => setStep(2)}
          >
            Add service
          </button>
          <div className="space-y-2">
            {services.map((service: any, index: number) => (
              <div className="flex items-center justify-between" key={index}>
                <p className="text-gray-800 font-medium">{service.Service}</p>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="w-full text-blue-800">
                      <IoMdMore />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-1 w-36 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-20">
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? "bg-slate-100 font-semibold"
                                  : "text-gray-900 font-medium"
                              } w-full rounded-md px-4 py-2 text-sm text-left hidden`}
                              onClick={() => {
                                setEditServicesValue({
                                  service: service.Service,
                                });
                                setStep(2);
                                // setActiveCategory(category.Category);
                                // setProductSidePanelStep(3);
                              }}
                            >
                              Edit
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? "bg-slate-100 font-semibold"
                                  : "text-gray-900 font-medium"
                              } w-full rounded-md px-4 py-2 text-sm text-left`}
                              onClick={() => {
                                handleDeleteService.mutate({
                                  service: service.Service,
                                });
                                handleDeleteS3Object.mutate({
                                  key: service.Service_S3ImageKey[0]
                                    .imageIdentifier,
                                });
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // <div className="p-3">
        //   <h1
        //     className="text-2xl mb-1 font-semibold flex items-center gap-4 cursor-pointer"
        //     onClick={() => setStep(1)}
        //   >
        //     {" "}
        //     <MdOutlineKeyboardBackspace /> <span>Service</span>
        //   </h1>
        //   <form onSubmit={formik.handleSubmit} className="space-y-4">
        //     <div className="space-y-4">
        //       <div className="w-full">
        //         <Input
        //           formik={formik}
        //           name="service"
        //           label="Service"
        //           type="text"
        //           placeHolder=""
        //         />
        //       </div>
        //     </div>
        //     <button
        //       type="submit"
        //       className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
        //     >
        //       {formik.isSubmitting ? (
        //         <ImSpinner2 size={20} className="mx-auto animate-spin" />
        //       ) : (
        //         "Add"
        //       )}
        //     </button>
        //   </form>
        // </div>

        <AddService setStep={setStep} editServicesValue={editServicesValue} setEditServicesValue={setEditServicesValue} />
      )}
    </div>
  );
};

export default ServicesSidePanel;
