import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getAllMenuCategories } from "../../../api/Menu";
import { ImSpinner8 } from "react-icons/im";
import { Link, useLocation, useParams } from "react-router-dom";
import { base64Encode, classNames } from "../../../utils";

const CategoriesLayout = () => {
  const { salt, slug } = useParams();
  const { pathname } = useLocation();

  // console.log(pathname);

  const queryKey = `/${salt}/${slug}`;
  let categories: any[] = [];

  const allMenuCategories = useQuery<any, Error>({
    queryKey: ["getAllMenuCategories"],
    queryFn: async () => {
      const response = await getAllMenuCategories(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      // setActiveCategory(data.Menu_Categories[0].Category);
    },
  });

  if (allMenuCategories.data) {
    categories = [
      "All",
      ...Object.keys(allMenuCategories.data.Menu_Categories),
    ];

    // categories = [];
  }


  return (
    <div>
      <div className="w-full mb-6 text-center">
        <h4 className="text-2xl font-semibold text-gray-800">Products</h4>
      </div>
      <div className="mb-10">
        {allMenuCategories.status === "loading" ? (
          <div className="flex items-center justify-center gap-4 flex-wrap" >
            {[1, 2, 3, 4].map((index: number) => (
              <div
                className={
                  "h-[45px] w-[100px] bg-gray-100 text-sm font-medium rounded-lg"
                }
                key={index}
              ></div>
            ))}
          </div>
        ) : allMenuCategories.status === "error" ? (
          <p className="mt-8 font-semibold text-center text-gray-400">
            {allMenuCategories.error.message}
          </p>
        ) : categories.length > 0 ? (
          <div className="flex items-center justify-center gap-4 flex-wrap">
            {categories.map((category: any, index: number) => {
              return (
                <Link
                  to={
                    category === "All"
                      ? `/store/${salt}/${slug}/all-products`
                      : `/store/${salt}/${slug}/all-products/category/${base64Encode(
                          category
                        )}`
                  }
                  key={index}
                  className={classNames(
                    "py-2.5 px-6 text-sm font-medium text-center border border-gray-200 rounded-lg",
                    pathname ===
                      `/store/${salt}/${slug}/all-products/category/${base64Encode(
                        category
                      )}`
                      ? "border-gray-700 text-gray-900 font-semibold"
                      : "bg-white text-gray-800"
                  )}
                >
                  {category}
                </Link>
              );
            })}
          </div>
        ) : (
          <p className="mt-8 font-semibold text-center text-gray-800">
            No Items yet
          </p>
        )}
      </div>
    </div>
  );
};

export default CategoriesLayout;
