// @ts-nocheck

import React from "react";
import { MdOutlineCancel, MdOutlineClear } from "react-icons/md";
import { classNames } from "../../../../../../../utils";
import { ImSpinner3 } from "react-icons/im";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../../../../hooks";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { delectS3Object, deleteService, getServices } from "../../../../../../../api/Menu";
import { FiEdit2 } from "react-icons/fi";

const ServicesSection = ({ setSidePanel, sidePanel, setSelectedSection, setShowDeleteModal, templateSections }) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  
  let services: any[] = [];

  const handleDeleteService = useMutation({
    mutationFn: async (data) => {
      return await deleteService(storeKey, data.service);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getServices");
    },
  });

  const allServices = useQuery<any, Error>({
    queryKey: ["getStoreServices"],
    queryFn: async () => {
      const response = await getServices(queryKey,storeKey);
      return response.data;
    },
    onSuccess: (data) => {},
  });

  if (allServices.data) {
    services = allServices.data.Business_Services;
  }

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        objectKey: data.key,
      };
      return await delectS3Object(payload);
    },
    onSuccess: () => {},
  });

  return (
    <section
      className={classNames(
        "cursor-pointer",
        sidePanel === "services" ? "border-2 border-blue-800 p-3" : "", !templateSections.includes("Services") === true ? "" : "hidden"
      )}
      onClick={() => {
        setSidePanel("services");
      }}
    >
      <div  className={classNames(
        "relative px-4 py-6 cursor-pointer bg-[#f6f8fc]"
      )}>
      <div className="mb-8 text-center">
        <p className="text-xl font-semibold">Services</p>
      </div>
      {allServices.status === "loading" ? (
        <div className="flex items-center justify-start py-5">
          <ImSpinner3 size={20} className="mx-auto animate-spin fill-primary" />
        </div>
      ) : allServices.status === "error" ? (
        <p className="mt-8 font-semibold text-center text-gray-400">
          {allServices.error.message}
        </p>
      ) : services.length > 0 ? (
        <div className="grid gap-8 mt-4  md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div
              className="rounded-md cursor-pointer relative h-[100px] flex items-center justify-center"
              key={index}
            >
              <img
                src={service?.Service_S3ImageKey?.Image_Upload_Url}
                alt="No Image"
                onError={(e) =>
                  (e.currentTarget.src =
                    "/media/image/servicesBg.png")
                }
                className="absolute object-cover w-full h-full rounded-md bg-slate-50"
              />
              <div className="absolute w-full h-full transition bg-black rounded-md bg-opacity-60 duration-350 hover:bg-opacity-80"></div>
              <p className="absolute z-10 mt-1 font-semibold text-center text-white capitalize">
                {service.Service}
              </p>
              {/* <button
                onClick={() => {
                  handleDeleteService.mutate({
                    service : service.Service,
                  });
                  handleDeleteS3Object.mutate({
                    key : service.Service_S3ImageKey[0].imageIdentifier,
                  });
                }}
                className="absolute z-20 text-white cursor-pointer top-3 right-2"
              >
                <MdOutlineClear size={18} />
              </button> */}
            </div>
          ))}
        </div>
      ) : (
        <p className="mt-1 font-semibold text-center text-gray-800">
          No Service Available
        </p>
      )}
      <div className="relative">
        {/* {showPopOver && (
              <div className="flex items-center justify-center w-full">
                <div className="bg-white w-[350px] absolute z-30 bottom-12 rounded-lg shadow-lg p-4">
                  <div className="flex gap-3 mb-4">
                    <MdOutlineCancel
                      size={30}
                      className="cursor-pointer"
                      onClick={() => setShowPopOver(false)}
                    />
                    <p className="text-sm font-medium text-gray-800">
                      Only a maximum of 6 services can be added.
                    </p>
                  </div>
                </div>
              </div>
            )} */}
        <div className="flex items-center justify-center mt-6">
          {/* <button
                className="px-6 py-2 text-sm text-white rounded bg-primary"
                onClick={() => {
                  if (services.length > 5) {
                    setShowPopOver(true);
                  } else {
                    setShowModals("service");
                  }
                }}
              >
                Add Service
              </button> */}
        </div>
      </div>
      <MdOutlineClear
        size={18}
        className="absolute text-blue-800 cursor-pointer right-4 top-7"
        onClick={(e) => {
          e.stopPropagation();
          setShowDeleteModal(true);
          setSelectedSection("Services");
        }}
      />
      </div>
     
    </section>
  );
};

export default ServicesSection;
