import { useNavigate } from "react-router-dom";
import { getImage } from "../../../../../utils";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const SubssciptionModules = [
  {
    title: "Small Biz Plus",
    desc: "Essential marketing tools to manage your business and receive feedback.",
    image: "SmallBizPlus",
    href: "/home/small-biz-plus"
  },
  {
    title: "Growth Plan",
    desc: "Customized tools to accelerate business success.",
    image: "GrowthPlan",
    href: "/home/growth-plan"
  },
  {
    title: "Business Boost",
    desc: "More tools and data for business decisions.",
    image: "BusinessBoost",
    href: "/home/business-boost"
  },
  {
    title: "Enterpreneur Suite",
    desc: "A complete business solution for control and flexibility.",
    image: "EnterpreneurSuite",
    href: "/home"
  },
]

const HomePage = () => {
  const navigate = useNavigate();
  
  return (
    <div className="">
      <p className="text-2xl font-semibold mb-6 text-center">Customized Solutions For You</p>
      <div className="max-w-[700px] mx-auto grid grid-cols-1 lg:max-w-[900px] lg:grid-cols-2 gap-y-4 lg:gap-x-[10%]">
       {SubssciptionModules.map((module: any, index: number) => (
        <div className="" key={index} >
        <div className="h-[180px] w-full rounded-md" >
         <img src={getImage(module.image)} alt="" className="w-full h-[180px] object-cover rounded-md" />
        </div>
        <p className="font-semibold mt-1.5" >{module.title}</p>
        <p className="text-xs text-gray-700" >{module.desc}</p>
        <button className="text-sm px-4 py-2 bg-primary text-white rounded-md mt-1.5 flex items-center gap-4" onClick={() =>  navigate(module.href)}><span>Learn More</span><HiOutlineArrowNarrowRight /></button>
      </div>
       ))}
      </div>
    </div>
  );
};

export default HomePage;
