// @ts-nocheck

import { Fragment, useEffect, useState } from "react";
import { AiOutlinePlusCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import {
  HiOutlineBell,
  HiSearch,
  HiOutlineUser,
  HiOutlineQrcode,
} from "react-icons/hi";
import { MdClear, MdOutlineSupportAgent } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { SiPlanetscale } from "react-icons/si";
import { ImSpinner2 } from "react-icons/im";
import { FaInfo } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { UserProfileModel, StoreModel } from "../../../models";
import { LOGOUT, SET_APIKEY } from "../../../redux/Auth";
import { REMOVE_USER } from "../../../redux/User";
import { AppDispatch } from "../../../setup/redux/Store";
import UserPool from "../../../setup/userpool";
import {
  DASHBOARD_HELP_ARRAY,
  isAdmin,
} from "../../../utils";
import DropDown from "../DropDown";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { RESET_UTILS } from "../../../redux/Utils";
import { SlBadge } from "react-icons/sl";
import { Menu, Transition } from "@headlessui/react";
import { BiBell } from "react-icons/bi";
import NotificationBell from "./NotificationBell";
import { generateImageToken, generatePaymentToken, getPlatformAPIKEY } from "../../../api/Auth";

export const colors: string[] = [
  "bg-red-600",
  "bg-green-600",
  "bg-yellow-600",
  "bg-slate-600",
];

export const Searchbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="relative w-full">
      <input
        type="text"
        placeholder="Search..."
        className="w-full px-10 py-2 text-gray-300 transition duration-300 ease-linear bg-gray-100 border border-gray-300 outline-none rounded-xl placeholder:text-gray-300 focus:border-primary"
        onFocus={() => setShowDropdown(true)}
        onBlur={() => {
          setShowDropdown(false);
          setIsLoading(false);
        }}
        onChange={() => setIsLoading(true)}
      />
      <HiSearch
        size={20}
        strokeWidth={2}
        className="absolute text-gray-300 inset-y-3 left-3"
      />
      <Transition
        as={Fragment}
        show={showDropdown}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95 translate-y-10"
        enterTo="transform opacity-100 scale-100 translate-y-1"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100 translate-y-1"
        leaveTo="transform opacity-0 scale-95 translate-y-10"
      >
        <div className="absolute right-0 mt-1.5 origin-top-right bg-white border rounded-md shadow-lg border-gray-50 shadow-gray-100 focus:outline-none w-full fade-in-out">
          <div className="w-full h-32 max-h-32">
            {/* {isLoading ? (<div className="flex items-center justify-center h-full">
                                <ImSpinner2 size={28} className="text-gray-300 animate-spin"/>
                            </div>) : (<div className="w-full h-full space-x-2">
                                <div className="p-2 transition duration-300 ease-linear group hover:bg-gray-100">
                                    <h4 className="text-base text-black group-hover:text-primary">Search</h4>
                                    <p className="text-sm text-gray-300">hello</p>
                                </div>
                            </div>)} */}
            <div className="flex items-center justify-center h-full">
              <ImSpinner2 size={28} className="text-gray-300 animate-spin" />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

const Navbar = ({ showSidebar, setShowSidebar }) => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const sessionColor: number = useAppSelector(
    (state) => state.user.sessionColor
  );
  const { pathname } = useLocation();
  const param = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const profileImageUrl =
    "https://d2g9h872q6e9ee.cloudfront.net" + `/${user.platformAccountId}`;

  const singleHelp = DASHBOARD_HELP_ARRAY.find((h) => h.href === pathname);

  const logOut = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
    } else {
      localStorage.removeItem("amplify-redirected-from-hosted-ui");
      localStorage.removeItem("amplify-signin-with-hostedUI");
      sessionStorage.removeItem("ouath_pkce_key");
      for (var i in localStorage) {
        for (var i in localStorage) {
          if (
            localStorage.hasOwnProperty(i) &&
            i.indexOf("CognitoIdentityServiceProvider") > -1
          ) {
            localStorage.removeItem(i);
          }
        }
      }
      localStorage.removeItem("customerEchoNewUser");
      localStorage.removeItem("paymentToken");
      localStorage.removeItem("imageToken");
      localStorage.removeItem("tokenAPI_KEY");
      dispatch(LOGOUT());
      dispatch(REMOVE_USER());
      dispatch(RESET_UTILS());
      queryClient.clear();
      navigate("/login", { replace: true });
    }
  };

  const showWalkthrough = (e: any) => {
    e.preventDefault();
    localStorage.setItem("enableWalkthroughBeacon", "true");
    navigate("/feedback-analysis/customer-satisfaction");
  };

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("tokenAPI_KEY"),
    },
    body: null,
  };

  // useEffect(() => {
  //   (async () => {
  //     const response = await generatePaymentToken();
  //     if (response) {
  //       localStorage.setItem("paymentToken", response.access_token);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getPlatformAPIKEY(store.name, requestOptions);
        if (response) {
          dispatch(SET_APIKEY({ apiKey: response }));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await generateImageToken();
      if (response) {
        localStorage.setItem("imageToken", response.access_token);
      }
    })();
  }, []);

  return (
    <nav className="sticky top-0 z-50 flex bg-white shadow-sm">
      {/* medium to large screen */}
      <div className="hidden container md:flex items-center justify-between px-4 md:justify-end md:px-6 py-2 space-x-0 md:space-x-20">
        <div className="">
          <div className="flex items-center gap-3 md:hidden">
            <Link to="/feedback-analysis/customer-satisfaction">
              <img
                src="/media/image/logo.png"
                alt="logo"
                className="w-32 mx-auto"
              />
            </Link>
            <button
              disabled
              className="bg-[#FFECC0] text-gray-800 font-semibold px-3 rounded-sm cursor-pointer"
              onClick={() => setShowSidebar((prevState) => !prevState)}
            >
              Beta
            </button>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-1 md:space-x-4 tutorial-again">
          <div>
            <Link to={`/feedback${store.name}`} target="_blank">
              <div className="p-2 text-white bg-blue-700 sm:bg-purple-600 rounded-full">
                <AiOutlinePlusCircle size={18} strokeWidth={40} />
              </div>
            </Link>
          </div>
          <NotificationBell />
          <div>
            <button
              type="button"
              onClick={() => navigate("settings")}
              className="p-2 text-black transition duration-300 ease-linear bg-gray-50 hover:bg-gray-100 rounded-full"
            >
              <FiSettings size={22} />
            </button>
          </div>
          <div className="hidden">
            <button
              type="button"
              className="p-2 text-gray-700 transition duration-300 ease-linear bg-gray-50 hover:bg-gray-100 rounded-full "
              onClick={showWalkthrough}
            >
              <FaInfo size={20} />
            </button>
          </div>
          <div className="mt-1">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button>
                  <div className="p-2 text-gray-700 transition duration-300 ease-linear bg-gray-50 hover:bg-gray-100 rounded-full">
                    <AiOutlineQuestionCircle size={22} strokeWidth={3} />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                // as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                {!Object.keys(param).length && (
                  <Menu.Items className="absolute right-0 mt-3 w-[330px] rounded-md bg-white shadow-lg focus:outline-none">
                    <div className="px-4 py-2 pb-3">
                      <Menu.Item>
                        {({ active }) => (
                          <div className="space-y-1">
                            <div className="flex items-center justify-between gap-2">
                              <p className="font-semibold text-sm text-black">
                                {singleHelp?.title}
                              </p>
                              <MdClear size={20} className="cursor-pointer" />
                            </div>
                            <p className="text-xs text-gray-700">
                              {singleHelp?.description}
                            </p>
                          </div>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                )}
              </Transition>
            </Menu>
          </div>
          <div>
            <DropDown
              selectorWrapper={
                <div className="inline-flex items-center space-x-2">
                  <img
                    className="w-10 h-10 mb-1 border-2 rounded-full"
                    src={profileImageUrl ?? "/media/image/avatar.png"}
                    alt="Rounded avatar"
                    onError={(e) =>
                      (e.currentTarget.src = "/media/image/avatar.png")
                    }
                  />
                </div>
              }
              itemsWrapper={
                <div className="py-1 w-52 lg:w-64">
                  <ul className="space-y-1">
                    {isAdmin(user.email) ? (
                      <li>
                        <Link
                          to="/qr-generator"
                          className="inline-flex items-center w-full px-4 py-2 space-x-2 text-xs transition duration-300 ease-linear hover:bg-gray-100 hover:text-primary"
                        >
                          <HiOutlineQrcode size={14} />
                          <span>Generate QR Code</span>
                        </Link>
                      </li>
                    ) : null}
                    <li>
                      <Link
                        to="/settings"
                        className="inline-flex items-center w-full px-4 py-2 space-x-2 text-xs transition duration-300 ease-linear hover:bg-gray-100 hover:text-primary"
                      >
                        <FiSettings size={14} />
                        <span>Settings</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/support"
                        className="inline-flex items-center w-full px-4 py-2 space-x-2 text-xs transition duration-300 ease-linear hover:bg-gray-100 hover:text-primary"
                      >
                        <SiPlanetscale size={14} />
                        <span>Support</span>
                      </Link>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="inline-flex items-center w-full px-4 py-2 space-x-2 text-xs transition duration-300 ease-linear hover:bg-gray-100 hover:text-primary"
                        onClick={logOut}
                      >
                        <AiOutlineLogout size={14} />
                        <span>Logout</span>
                      </button>
                    </li>
                  </ul>
                </div>
              }
            />
          </div>
        </div>
      </div>

      {/* mobile screen */}
      <div className="flex container items-center justify-between px-2 sm:px-4 md:justify-end md:px-6 py-2 space-x-0 md:space-x-20 md:hidden">
        <div className="">
          <div className="flex items-center gap-3 ">
            <Link to="/feedback-analysis/customer-satisfaction">
              <img
                src="/media/image/logo.png"
                alt="logo"
                className="w-28 mx-auto"
              />
            </Link>
            <p className="bg-[#FFECC0] text-sm text-gray-800 font-semibold px-3 rounded-sm cursor-pointer">
              Beta
            </p>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-1.5 md:space-x-4">
          <div className="mt-1 hide-help">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button>
                  <div className="p-2 text-gray-700 transition duration-300 ease-linear bg-gray-100 rounded-full hover:bg-blue-700 sm:hover:bg-purple-600 hover:text-white">
                    <AiOutlineQuestionCircle size={20} strokeWidth={3} />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute -right-8 sm:right-0 mt-3 w-[300px] sm:w-[330px] rounded-md bg-white shadow-lg focus:outline-none">
                  <div className="px-4 py-2 pb-3">
                    <Menu.Item>
                      {({ active }) => (
                        <div className="space-y-1">
                          <div className="flex items-center justify-between gap-2">
                            <p className="font-semibold text-sm text-black">
                              {singleHelp?.title}
                            </p>
                            <MdClear size={20} className="cursor-pointer" />
                          </div>
                          <p className="text-xs text-gray-700">
                            {singleHelp?.description}
                          </p>
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div>
            <NotificationBell />
          </div>
          <div>
            <DropDown
              selectorWrapper={
                <div className="inline-flex items-center space-x-2">
                  <img
                    className="w-8 h-8 border-2 rounded-full"
                    src={profileImageUrl ?? "/media/image/avatar.png"}
                    alt="Rounded avatar"
                    onError={(e) =>
                      (e.currentTarget.src = "/media/image/avatar.png")
                    }
                  />
                </div>
              }
              itemsWrapper={
                <div className="py-1 w-52 lg:w-64">
                  <ul className="space-y-1">
                    {isAdmin(user.email) ? (
                      <li>
                        <Link
                          to="/qr-generator"
                          className="inline-flex items-center w-full px-4 py-2 space-x-2 text-xs transition duration-300 ease-linear hover:bg-gray-100 hover:text-primary"
                        >
                          <HiOutlineQrcode size={14} />
                          <span>Generate QR Code</span>
                        </Link>
                      </li>
                    ) : null}
                    <li>
                      <Link
                        to="/settings"
                        className="inline-flex items-center w-full px-4 py-2 space-x-2 text-xs transition duration-300 ease-linear hover:bg-gray-100 hover:text-primary"
                      >
                        <HiOutlineUser size={14} />
                        <span>Profile</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/support"
                        className="inline-flex items-center w-full px-4 py-2 space-x-2 text-xs transition duration-300 ease-linear hover:bg-gray-100 hover:text-primary"
                      >
                        <SiPlanetscale size={14} />
                        <span>Support</span>
                      </Link>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="inline-flex items-center w-full px-4 py-2 space-x-2 text-xs transition duration-300 ease-linear hover:bg-gray-100 hover:text-primary"
                        onClick={logOut}
                      >
                        <AiOutlineLogout size={14} />
                        <span>Logout</span>
                      </button>
                    </li>
                  </ul>
                </div>
              }
            />
          </div>
          <div
            className="hamburger ml-1.5 cursor-pointer"
            onClick={() => setShowSidebar((prevState) => !prevState)}
          >
            {showSidebar ? (
              <img src="/media/image/cancel.png" alt="" className="w-[30px]" />
            ) : (
              <img
                src="/media/image/hamburger_menu.png"
                alt=""
                className="w-[30px]"
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
