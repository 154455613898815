// @ts-nocheck

import React, { useState } from "react";
import { classNames } from "../../../../../utils";
import { useNavigate } from "react-router-dom";
import { downloadWebUrl, uploadWebUrl } from "../../../../../api/Utils";
import { useAppSelector } from "../../../../../hooks";
import { StoreModel, UserProfileModel } from "../../../../../models";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import SkeletonLoader from "../../../../../components/Dashboard/SkeletonLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMessage from "../../../../../components/Auth/Forms/InputMessage";
import { ImSpinner3, ImSpinner8 } from "react-icons/im";
import { getTemplateSections } from "../../../../../api/Menu";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const initialValues = {
  url: "",
};

const validationSchema = Yup.object().shape({
  url: Yup.string()
    .test("url", "Url must contain https://", (value: string) => {
      return value.includes("https://");
    })
    .required("Please type in your url"),
});

const UploadUrl = () => {
  const navigate = useNavigate();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const [success, setSuccess] = useState(false);
  const queryclient = useQueryClient();

  let templateSections: any[] = [];

  const { status, data, error } = useQuery<any, Error>({
    queryKey: ["downloadWebUrl"],
    queryFn: async () => {
      const response = await downloadWebUrl(storeKey);
      return response.data;
    },
  });

  const loadTemplateSections = useQuery<any, Error>({
    queryKey: ["getTemplateSections"],
    queryFn: async () => {
      const response = await getTemplateSections(storeKey);
      return response.data;
    },
  });

  if (loadTemplateSections.data) {
    templateSections = loadTemplateSections.data.Template_Sections;
  }

  const formik = useFormik({
    initialValues: { ...initialValues, url: data ? data.Web_Link : "" },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setSuccess(false);
      const data = {
        platformKey: queryKey.replaceAll("%26", "&"),
        webLink: values.url,
      };
      const response = await uploadWebUrl(data);
      console.log({ response });
      if (response.data.status_code === 200) {
        queryclient.refetchQueries(["downloadWebUrl"]);
        setSubmitting(false);
        setSuccess(true);
        return;
      }

      setSubmitting(false);
      setSuccess(false);
    },
  });

  return (
    <div className="w-full rounded-md h-[85vh] bg-white p-4">
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => {
          navigate("/home/echo-webpage");
        }}
      >
        <MdOutlineKeyboardBackspace size={22} />
        <h4 className="text-gray-800 capitalize text-xl font-semibold">Back</h4>
      </div>
      <div className="mt-10 text-center">
        <p className="text-2xl font-semibold text-gray-800">Upload Store URL</p>
        <p className="font-medium text-gray-500">
          If you have an online store, enter your store link here.
        </p>
      </div>
      <div className="max-w-[700px] mx-auto space-y-6">
        <form onSubmit={formik.handleSubmit}>
          {status === "loading" ? (
            <div className="flex items-center justify-start py-5">
              <ImSpinner3
                size={18}
                className="mx-auto animate-spin fill-primary"
              />
            </div>
          ) : status === "error" ? (
            <span>Error: {error.message}</span>
          ) : (
            <input
              name="url"
              type="text"
              placeholder="https://example.com/"
              {...formik.getFieldProps("url")}
              className={classNames(
                "border border-gray-300 py-4 px-5 mt-8 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm"
              )}
            />
          )}
          {formik.errors.url ? (
            <InputMessage message={formik.errors.url} />
          ) : null}
          <div className="flex items-center justify-center mt-6">
            <button
              type="submit"
              disabled={formik.isSubmitting || status === "loading"}
              className="py-2 text-sm text-center text-white rounded w-36 bg-primary"
            >
              {formik.isSubmitting ? (
                <ImSpinner8
                  size={20}
                  className="mx-auto animate-spin fill-white"
                />
              ) : (
                "Save Link"
              )}
            </button>
          </div>
          <p className="mt-1 text-center text-green-500 capitalize">
            {success && <small>saved successfully!</small>}
          </p>
        </form>
        {data?.Web_Link === "" && templateSections.length === 4 ? (
          <p className="mt-4 text-sm text-center">
            {"Don't have an online store? Click"}{" "}
            <span
              className="cursor-pointer text-primary"
              onClick={() => navigate("/home/echo-webpage/customizer")}
            >
              here
            </span>{" "}
            {"to create one"}
          </p>
        ) : (
          <p className="mt-4 text-sm text-center">
            {"Click"}{" "}
            <span
              className="cursor-pointer text-primary"
              onClick={() => navigate("/home/echo-webpage/customizer")}
            >
              here
            </span>{" "}
            {"to manage store"}
          </p>
        )}
        {/* {(templateSections.length > 0) && <p className="mt-4 text-sm text-center">
          {"Click"} <span className="cursor-pointer text-primary" onClick={() => navigate("/home/echo-webpage/customizer")}>here</span> {"to manage store"}
        </p>} */}
      </div>
    </div>
  );
};

export default UploadUrl;
