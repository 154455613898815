// @ts-nocheck
import {
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { CartProps, SET_CART } from "../../../redux/Utils";
import Modal from "../../Dashboard/Modal";
import { useState, useEffect } from "react";
import { ImSpinner3, ImSpinner8 } from "react-icons/im";
import InputMessage from "../../Auth/Forms/InputMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getDeliveryDetail } from "../../../api/Menu";
import { Link, useLocation, useParams } from "react-router-dom";
import { classNames } from "../../../utils";
import { placeProductOrder } from "../../../api/Utils";
import moment from "moment";
import MobileMenuWrapper from "../MobileMenuWrapper";
import { FaTimes } from "react-icons/fa";
import { BiTrash } from "react-icons/bi";
import toast from "react-hot-toast";

const initialValues = {
  fullName: "",
  email: "",
  phone: "",
};

const initialValues2 = {
  fullName: "",
  address: "",
  email: "",
  phone: "",
};

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const validationSchema = Yup.object().shape({
  phone: Yup.string().matches(
    phoneRegExp,
    "Phone number is not valid. Please use this format (e.g, +234)"
  ),
  fullName: Yup.string().max(40).required("Full name is required"),
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
});

const validationSchema2 = Yup.object().shape({
  phone: Yup.string().matches(
    phoneRegExp,
    "Phone number is not valid. Please use this format (e.g, +234)"
  ),
  fullName: Yup.string().max(40).required("Full name is required"),
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
  address: Yup.string().max(100).required("Address is required"),
});

interface Props {
  onOpen?: () => void;
}

const Cart = (props: Props) => {
  const { salt, slug, category } = useParams();
  const dispatch = useAppDispatch();
  const cart: CartProps = useAppSelector((state) => state.utils.cart);
  const [showCartModal, setShowCartModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState(0);
  const [toc, setToc] = useState(false);
  const [orderID, setOrderID] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [paymentOption, setPaymentOption] = useState(0);
  const queryKey = `/${salt}/${slug}`;
  let allDeliveryDetails: any[] = [];
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  let storeAddress = "32b ologun agbaje st, victoria island";

  const [paymentDetails, setPaymentDetails] = useState([]);

  const getCart = () => Object.values(cart || {});

  const storeCart = getCart().filter((x) => pathname.includes(x.slugUrl));
  // console.log(storeCart)

  const totalPrice = storeCart.reduce(
    (accumulator, product) => accumulator + product.selectedPriceForItem * product.qty,
    0
  );

  const freeDeliveryStatus: string = useAppSelector(
    (state) => state.user.store.freeDelivery
  );

  const handleRemoveCartProduct = (item: CartItem): void => {
    const updatedCart = { ...cart };
    const key = item.key;
    delete updatedCart[key];

    dispatch(SET_CART(updatedCart));
  };

  const handleUpdateCartQuantity = (item: CartItem, operation: Operation) => {
    const updatedCart = { ...cart };
    const key = item.key;
    const itemName = key;

    if (updatedCart[itemName]) {
      if (operation === "increase") {
        updatedCart[itemName] = {
          ...updatedCart[itemName],
          qty: updatedCart[itemName].qty + 1,
        };
      } else {
        updatedCart[itemName] = {
          ...updatedCart[itemName],
          qty: updatedCart[itemName].qty - 1,
        };
      }
    }

    dispatch(SET_CART(updatedCart));
  };

  const formatItemName = (name: string) => {
    return name.replaceAll("_", " ").toLowerCase();
  };

  useEffect(() => {}, [localStorage]);

  const formik = useFormik({
    initialValues: deliveryOption === 0 ? initialValues : initialValues2,
    validationSchema:
      deliveryOption === 0 ? validationSchema : validationSchema2,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      let payload = {
        queueName: "",
        orderId: "",
        apiKey: "",
        creationTime: "",
        description:
          paymentOption === 0
            ? "Payment via Bank Transfer."
            : "Payment via Paystack.",
        creationTimestamp: Number(new Date(Date.now()).getTime() / 1000),
        platformRouter: "",
        slugUrl: queryKey,
        orderPayload: [
          ...storeCart.map((item: CartItem, index: number) => {
            return {
              // itemName: item.itemName.replaceAll(" ", "_"),
              itemName: item.itemName,
              // itemPrice: item.itemPrice,
              itemPrice: item?.selectedPriceForItem,
              quantityOrder: item.qty,
              itemId: item.menuItemId,
            };
          }),
        ],
        transactionPayload: {
          totalAmount: `${totalPrice}`,
          discount: {
            discounted: "NO",
            "%Discounted": "1.5",
            amountDiscounted: "0.00",
          },
        },
        metadata: {
          tableName: "",
          loyaltyCardId: "XXXXXXPPP",
        },
        orderStatus: "Pending",
        orderNumber: "",
      };

      if (deliveryOption === 1) {
        payload["billingDetail"] = {
          fullName: values.fullName,
          address: values.address,
          email: values.email,
          phone: values.phone,
          deliveryDetail: {
            location: deliveryLocation,
            fee: deliveryFee,
          },
        };
      } else {
        payload["billingDetail"] = {
          fullName: values.fullName,
          email: values.email,
          phone: values.phone,
          deliveryDetail: {},
        };
      }

      const response = await placeProductOrder(payload);
      // console.log({response});
      if (response.data.status_code === 200) {
        setOrderID(response.data.orderNumber);
        if (response.data.bankDetails.status_code === 200) {
          if (response.data.bankDetails.Payment_Details.length > 0) {
            setPaymentDetails(response.data.bankDetails.Payment_Details);
          }
        }
        setSubmitting(false);
        // resetForm({});
        setShowCheckoutModal(false);
        setShowSuccessModal(true);
        // dispatch(SET_CART({}));
        // setDeliveryOption(0);
        // setDeliveryFee(0);
        // setDeliveryLocation("");
        queryClient.refetchQueries(["getOrders"]);
        return;
      }

      setSubmitting(false);
    },
  });

  const deliveryDetails = useQuery<any, Error>({
    queryKey: ["getDeliveryDetail"],
    queryFn: async () => {
      const response = await getDeliveryDetail(queryKey);
      return response.data;
    },
  });

  if (deliveryDetails.data) {
    storeAddress = deliveryDetails.data.Pickup_Location;
    if ("Location_Tag" in deliveryDetails.data) {
      let keys = Object.keys(deliveryDetails.data.Location_Tag);
      // console.log(keys[0].includes("location"))
      let v = Object.values(deliveryDetails.data.Location_Tag);

      const newData = Array.from(
        Object.entries(deliveryDetails.data.Location_Tag)
      );
      // console.log(newData);
      const realLocations = newData.filter(
        (x) => !x.at(0).includes("dummylocation")
      );
      //   console.log(realLocations)

      allDeliveryDetails = realLocations;
    }
  }

  const cartHasOnlyFreeProduct =
    storeCart.filter(
      (item: CartItem, index: number) => item.freeDelivery === "0"
    ).length === 0;

  return (
    <div className="relative font-dm-sans">
      <button
        type="button"
        onClick={() => setShowCartModal(true)}
        className="items-center flex space-x-1 px-1.5 py-1 sm:px-3 sm:py-2 text-sm font-semibold text-primary sm:text-white sm:bg-blue-600 rounded-md sm:rounded outline-none"
      >
        <AiOutlineShoppingCart size={24} className="hidden sm:inline-block " />
        <AiOutlineShoppingCart size={22} className="inline-block sm:hidden" />
        <sup className="font-features sups lg:hidden">{storeCart.length}</sup>
        <small className="hidden lg:inline-block">
          <span className="hidden lg:inline-block">Cart </span>
          {`(${storeCart.length})`}
        </small>
      </button>
      <button
        type="button"
        onClick={() => {
          setShowCartModal(true);
          if (props.onOpen !== undefined) {
            setTimeout(() => {
              props.onOpen();
            }, 500);
          }
        }}
        className="items-center justify-center w-full py-2.5 space-x-1 text-base font-semibold text-white bg-blue-500 rounded outline-none hidden"
      >
        <AiOutlineShoppingCart size={24} className="" />
        <small className="">Cart {`(${storeCart.length})`}</small>
      </button>
      <MobileMenuWrapper
        show={showCartModal}
        onToggle={() => setShowCartModal(false)}
        position="right"
      >
        <div className="relative flex flex-col w-screen sm:min-w-[400px] sm:w-full md:w-[34rem]  h-screen pt-16 pb-2 bg-white shadow-sm shadow-gray-300">
          <div className="container flex flex-col items-start flex-1 px-5 mt-5 space-y-8 overflow-y-auto text-lg font-medium outline-none font-inter text-themeBlack">
            <div className="w-full">
              <div className="mb-1 pb-2 flex items-center justify-between border-b-[1px] border-gray-200">
                <h4 className="text-lg font-semibold">Cart</h4>
                <button
                  onClick={() => setShowCartModal(false)}
                  className="absolute z-10 top-10 right-6"
                >
                  <FaTimes stroke="1" size={22} />
                </button>
              </div>
              <div className="w-full overflow-hidden overflow-y-auto md:overflow-x-hidden md:overflow-y-auto">
                <table className="table w-full table-auto">
                  <thead className="w-full hidden">
                    <tr className="w-full mb-2 text-sm text-left text-gray-800 capitalize border-b border-gray-200">
                      <th className="pb-2 font-medium">Item</th>
                      <th className="pb-2 font-medium">Description</th>
                      <th></th>
                      <th className="pb-2 font-medium">Quantity</th>
                      <th></th>
                      <th className="pb-2 font-medium text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storeCart.length > 0 &&
                      storeCart.map((item: CartItem, index: number) => {
                        const currentProduct = storeCart.find(
                          (cartItem) => cartItem.key === item.key
                        );

                        return (
                          <tr
                            key={index}
                            className="w-full text-center border-b border-gray-300"
                          >
                            <td className="px-2 py-3">
                              <div className="min-w-[80px] max-w-[120px]">
                                <img
                                  src={
                                    item.s3ImageUrl ||
                                    "https://images.pexels.com/photos/757432/pexels-photo-757432.jpeg"
                                  }
                                  alt="img"
                                  className="w-[100px] sm:w-[180px] h-[80px] rounded-md object-contain"
                                />
                              </div>
                            </td>
                            <td colSpan={2} className="py-0 text-left">
                              <div className="flex flex-col space-y-1">
                                <h4 className="text-sm font-medium sm:font-semibold text-gray-800 capitalize">
                                  {formatItemName(item.itemName)}
                                </h4>
                                <div className="flex flex-col items-start space-y-1 text-xs">
                                  {"itemSize" in item &&
                                    item?.itemSize !== 0 && (
                                      <h6 className="text-gray-600 ">
                                        Size: {item.itemSize}
                                      </h6>
                                    )}
                                  {"itemColor" in item &&
                                    item?.itemColor !== "" && (
                                      <h6 className="text-gray-600 ">
                                        Color: {item.itemColor}
                                      </h6>
                                    )}
                                </div>
                                <p className="hidden sm:block text-xs text-gray-600 font-normal line-clamp-1 text-ellipsis w-full" >
                                  {"description" in item &&
                                  item.description !== ""
                                    ? item.description
                                    : "itemDescription" in item &&
                                      item.itemDescription !== ""
                                    ? item.itemDescription
                                    : ""}
                                </p>
                                <div className="sm:hidden flex items-center w-full space-x-2.5">
                                  <button
                                    onClick={() => {
                                      if (currentProduct.qty === 1) {
                                        handleRemoveCartProduct(item);
                                        return;
                                      }
                                      handleUpdateCartQuantity(item);
                                    }}
                                    className="w-6 h-6 text-base font-semibold text-gray-800 capitalize bg-gray-100 rounded-sm"
                                  >
                                    <AiOutlineMinus
                                      size={16}
                                      className=" mx-auto"
                                    />
                                  </button>
                                  <p className="text-sm">
                                    {currentProduct.qty}
                                  </p>
                                  <button
                                    onClick={() =>
                                      handleUpdateCartQuantity(item, "increase")
                                    }
                                    className="w-6 h-6 text-base font-semibold text-gray-800 capitalize bg-gray-100 rounded-sm"
                                  >
                                    <AiOutlinePlus
                                      size={16}
                                      className=" mx-auto"
                                    />
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td colSpan={2}>
                              <div className="hidden sm:flex items-center w-full space-x-2.5">
                                <button
                                  onClick={() => {
                                    if (currentProduct.qty === 1) {
                                      handleRemoveCartProduct(item);
                                      return;
                                    }
                                    handleUpdateCartQuantity(item);
                                  }}
                                  className="w-6 h-6 text-base font-semibold text-gray-800 capitalize bg-gray-100 rounded-sm"
                                >
                                  <AiOutlineMinus
                                    size={16}
                                    className=" mx-auto"
                                  />
                                </button>
                                <p className="text-sm">{currentProduct.qty}</p>
                                <button
                                  onClick={() =>
                                    handleUpdateCartQuantity(item, "increase")
                                  }
                                  className="w-6 h-6 text-base font-semibold text-gray-800 capitalize bg-gray-100 rounded-sm"
                                >
                                  <AiOutlinePlus
                                    size={16}
                                    className=" mx-auto"
                                  />
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="flex items-center justify-end sm:justify-between gap-6">
                                <p className="text-sm">
                                  {/* &#8358;{item.qty * item.itemPrice} */}
                                  &#8358;{item.qty * item?.selectedPriceForItem}
                                </p>
                                <BiTrash
                                  className="hidden sm:inline text-gray-600 cursor-pointer"
                                  onClick={() => {
                                    handleRemoveCartProduct(item);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {storeCart.length === 0 && (
                      <tr className="text-sm text-center text-gray-800">
                        <td colSpan={11} className="p-2">
                          No Items in cart
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {!storeCart.length < 1 && (
                    <tfoot>
                      <tr className="pt-2 mt-2 text-base text-left text-gray-800 capitalize">
                        <td colSpan={5} className="p-2 text-base font-medium">
                          Total
                        </td>
                        <td
                          colSpan={5}
                          className="p-2 text-xl font-medium text-right text-gray-800"
                        >
                          &#8358;{totalPrice}
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              </div>
              {!storeCart.length < 1 && (
                <div className="mt-12">
                  <button className="w-full">
                    <Link
                      to={`/store/${salt}/${slug}/checkout`}
                      // target="_blank"
                      className={classNames(
                        "w-full bg-blue-600 text-white py-3 rounded-md block text-sm text-center capitalize disabled:bg-blue-600/95"
                      )}
                    >
                      checkout
                    </Link>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </MobileMenuWrapper>
      <Modal size="large" show={false} onToggle={() => setShowCartModal(false)}>
        <div className="p-4 space-y-5 overflow-hidden bg-white rounded-md md:p-10">
          <div className="text-center">
            <h4 className="text-2xl font-semibold text-black uppercase">
              Cart
            </h4>
          </div>
          <div className="w-full overflow-hidden overflow-y-auto md:overflow-x-hidden md:overflow-y-auto">
            <table className="hidden w-full md:table md:table-auto">
              <thead>
                <tr className="px-4 mb-2 text-center text-gray-800 uppercase border border-gray-500">
                  <th className="p-2 text-sm font-medium text-left">item</th>
                  <th className="p-2 text-sm font-medium text-left">
                    description
                  </th>
                  <th></th>
                  <th></th>
                  <th className="p-2 text-sm font-medium text-left">
                    quantity
                  </th>
                  <th className="p-2 text-sm font-medium text-center">price</th>
                  {/* <th className="p-2 text-sm font-medium text-center">sub-total</th> */}
                </tr>
              </thead>
              <tbody className="border border-gray-500">
                {storeCart.length > 0 &&
                  storeCart.map((item: CartItem, index: number) => {
                    const currentProduct = storeCart.find(
                      (cartItem) => cartItem.key === item.key
                    );

                    return (
                      <tr key={index} className="px-4 text-center text-black">
                        <td className="p-2">
                          <div className="w-[80px] h-[80px]">
                            <img
                              src={
                                !item.s3ImageUrl
                                  ? item.s3ImageUrl
                                  : "/media/image/servicesBg.png"
                              }
                              alt="img"
                              className="w-[80px] h-[80px] rounded-md"
                            />
                          </div>
                        </td>
                        <td colSpan={3} className="p-2 text-left">
                          <h6 className="text-base font-semibold capitalize">
                            {formatItemName(item.itemName)}
                          </h6>
                          <p className="text-sm line-clamp-1">{item.itemDescription}</p>
                        </td>
                        <td className="p-2 text-lg font-medium">
                          <div className="flex items-center w-full space-x-2.5">
                            <button
                              onClick={() => {
                                if (currentProduct.qty === 1) {
                                  handleRemoveCartProduct(item.itemName);
                                  return;
                                }
                                handleUpdateCartQuantity(item.itemName);
                              }}
                              className="w-6 px-1 py-1.5 text-base font-semibold text-black capitalize bg-white border border-gray-400 rounded-lg"
                            >
                              <AiOutlineMinus className="w-full mx-auto" />
                            </button>
                            <p className="text-base">{currentProduct.qty}</p>
                            <button
                              onClick={() =>
                                handleUpdateCartQuantity(
                                  item.itemName,
                                  "increase"
                                )
                              }
                              className="w-6 px-1 py-1.5 text-base font-semibold text-black capitalize bg-white border border-gray-400 rounded-lg"
                            >
                              <AiOutlinePlus className="w-full mx-auto" />
                            </button>
                          </div>
                        </td>
                        {/* <td className="p-2 text-lg font-bold">&#8358;{item.itemPrice}</td> */}
                        <td className="p-2 text-lg font-bold">
                          <div className="relative h-full">
                            <button
                              onClick={() =>
                                handleRemoveCartProduct(item.itemName)
                              }
                              className="absolute p-1.5 font-semibold text-red-500 outline-none -right-1 -top-10"
                            >
                              x
                            </button>
                            {/* <p>&#8358;{item.qty * item.itemPrice}</p> */}
                            <p>&#8358;{item.qty * item?.selectedPriceForItem}</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                {storeCart.length === 0 && (
                  <tr className="px-2 text-sm text-center text-gray-800">
                    <td colSpan={11} className="p-2">
                      No Items in cart
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="block space-y-2 md:hidden">
              {storeCart.length > 0 &&
                storeCart.map((item: cartItem, index: number) => {
                  const currentProduct = storeCart.find(
                    (cartItem) => cartItem.key === item.key
                  );
                  return (
                    <div key={index} className="space-y-2.5">
                      <div className="flex w-full max-w-sm space-x-2.5">
                        <div
                          className="flex-none w-2/6 h-auto overflow-hidden text-center bg-center bg-no-repeat bg-cover rounded-md"
                          title="product img"
                          style={{
                            backgroundImage: !item.s3ImageUrl
                              ? `url(${item.s3ImageUrl})`
                              : `url("/media/image/servicesBg.png")`,
                          }}
                        ></div>
                        <div className="relative flex flex-col w-full space-y-6">
                          <div className="flex flex-col space-y-1 w-[80%]">
                            <h6 className="text-base font-semibold capitalize">
                              {formatItemName(item.itemName)}
                            </h6>
                            <div className="flex items-center w-full space-x-2.5">
                              <button
                                onClick={() => {
                                  if (currentProduct.qty === 1) {
                                    handleRemoveCartProduct(item.itemName);
                                    return;
                                  }
                                  handleUpdateCartQuantity(item.itemName);
                                }}
                                className="w-6 px-1 py-1 text-base font-semibold text-black capitalize bg-white border border-gray-400 rounded-lg"
                              >
                                <AiOutlineMinus className="w-full mx-auto" />
                              </button>
                              <p className="text-base">{currentProduct.qty}</p>
                              <button
                                onClick={() =>
                                  handleUpdateCartQuantity(
                                    item.itemName,
                                    "increase"
                                  )
                                }
                                className="w-6 px-1 py-1 text-base font-semibold text-black capitalize bg-white border border-gray-400 rounded-lg"
                              >
                                <AiOutlinePlus className="w-full mx-auto" />
                              </button>
                            </div>
                          </div>
                          <h6 className="text-lg font-semibold">
                            {/* &#8358;{item.qty * item.itemPrice} */}
                            &#8358;{item.qty * item?.selectedPriceForItem}
                          </h6>
                          <button
                            onClick={() =>
                              handleRemoveCartProduct(item.itemName)
                            }
                            className="absolute p-0.5 right-0 text-base font-semibold text-red-500 outline-none -top-8"
                          >
                            x
                          </button>
                        </div>
                      </div>
                      <div className="w-full mx-auto border-t border-gray-500"></div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="hidden md:block w-[90%] mx-auto border-t border-gray-500"></div>
          <div className="flex items-center justify-between">
            <h4 className="text-2xl font-semibold text-gray-400 uppercase">
              Total
            </h4>
            <h4 className="text-2xl font-bold text-black uppercase">
              &#8358;{totalPrice}
            </h4>
          </div>
          <div className="flex items-center justify-center">
            {storeCart.length > 0 && (
              <button
                onClick={() => {
                  const thiscart = storeCart;
                  // console.log("cart", thiscart);
                  setShowCartModal(false);
                  setShowCheckoutModal(true);
                }}
                className="p-1.5 text-center text-white capitalize rounded-lg w-32 bg-primary"
              >
                Checkout
              </button>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        size="large"
        show={showCheckoutModal}
        onToggle={() => {
          setShowCheckoutModal(false);
          setDeliveryOption(0);
          setDeliveryFee(0);
          setDeliveryLocation("");
        }}
      >
        <div className="p-4 space-y-5 overflow-hidden bg-white rounded-md md:p-10">
          <div className="text-center">
            <h4 className="text-2xl font-semibold text-black uppercase">
              Billing & Delivery
            </h4>
          </div>
          <div className="mb-4">
            <h4 className="mb-3 text-lg font-semibold text-black md:mb-0">
              Select delivery option
            </h4>
            <div className="flex flex-row space-x-4 space-y-0 md:space-y-2 md:space-x-0 md:flex-col">
              <div className="">
                <label className="flex items-center space-x-1.5 text-base font-semibold cursor-pointer">
                  <input
                    type="radio"
                    value={0}
                    className="w-4 h-4"
                    checked={deliveryOption === 0}
                    onChange={(e) => {
                      setDeliveryOption(0);
                      setDeliveryFee(0);
                      setDeliveryLocation("");
                    }}
                  />
                  <span>Pickup</span>
                </label>
              </div>
              <div className="">
                <label className="flex items-center space-x-1.5 text-base font-semibold cursor-pointer">
                  <input
                    type="radio"
                    value={1}
                    className="w-4 h-4"
                    checked={deliveryOption === 1}
                    onChange={(e) => {
                      setDeliveryOption(1);
                      if (
                        freeDeliveryStatus === "1" &&
                        cartHasOnlyFreeProduct
                      ) {
                        setDeliveryFee(0);
                      } else {
                        setDeliveryFee(allDeliveryDetails[0][1]);
                      }
                      setDeliveryLocation(allDeliveryDetails[0][0]);
                    }}
                  />
                  <span>Delivery</span>
                </label>
              </div>
            </div>
          </div>
          {deliveryDetails.status === "loading" ? (
            <div className="flex items-center justify-start py-5">
              <ImSpinner3
                size={18}
                className="mx-auto animate-spin fill-primary"
              />
            </div>
          ) : deliveryDetails.status === "error" ? (
            <div className="flex items-center justify-start py-5">
              Error: {deliveryDetails.error.message}
            </div>
          ) : (
            deliveryOption === 1 && (
              <div className="mb-4">
                {/* <h4 className="mb-4 text-base font-semibold text-black md:mb-0">Set delivery location</h4> */}
                <div className="grid grid-cols-1 gap-2.5 md:grid-cols-3 lg:grid-cols-3">
                  {allDeliveryDetails.length > 0 &&
                    allDeliveryDetails.map((item: any, index: number) => {
                      return (
                        <div key={index} className="">
                          <label className="flex items-center space-x-1.5 text-base md:text-sm lg:text-base font-semibold cursor-pointer">
                            <input
                              type="radio"
                              value={0}
                              className="w-4 h-4"
                              checked={
                                deliveryFee === Number(item[1]) &&
                                deliveryLocation === item[0]
                              }
                              onChange={(e) => {
                                setDeliveryLocation(item[0]);
                                if (
                                  freeDeliveryStatus === "1" &&
                                  cartHasOnlyFreeProduct
                                ) {
                                  setDeliveryFee(0);
                                } else {
                                  setDeliveryFee(Number(item[1]));
                                }
                              }}
                            />
                            <span>
                              {item[0]} - &#8358;
                              {freeDeliveryStatus === "1" &&
                              cartHasOnlyFreeProduct
                                ? 0.0
                                : item[1]}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                  {allDeliveryDetails.length === 0 && (
                    <div>
                      <p className="hidden text-sm text-gray-600 capitalize">
                        No delivery location available
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
          <form onSubmit={formik.handleSubmit} className="space-y-4">
            {deliveryDetails.status === "loading" ? (
              <div className="flex items-center justify-start py-5">
                <ImSpinner3
                  size={18}
                  className="mx-auto animate-spin fill-primary"
                />
              </div>
            ) : deliveryDetails.status === "error" ? (
              <div className="flex items-center justify-start py-5">
                Error: {deliveryDetails.error.message}
              </div>
            ) : (
              deliveryOption === 0 && (
                <div>
                  <p className="p-2 text-base italic font-semibold text-blue-800 bg-blue-100 border border-blue-800 rounded-xl">{`Pickup location is ${
                    storeAddress === "" ? "not available" : storeAddress
                  }`}</p>
                </div>
              )
            )}
            <div>
              <h4 className="text-base font-semibold text-gray-800">
                {deliveryOption === 0 ? "Set contact" : "Set delivery location"}
              </h4>
              <div className="flex flex-col w-full mt-2 space-y-2.5">
                <div className="text-left text-gray-800">
                  <label className="block mb-1 text-sm font-medium">
                    Full name
                  </label>
                  <input
                    name="fullName"
                    type="text"
                    {...formik.getFieldProps("fullName")}
                    className="block w-full p-2 text-black transition duration-300 ease-linear border border-gray-400 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                  />
                  {formik.errors.fullName ? (
                    <div className="text-xs">
                      <InputMessage message={formik.errors.fullName} />
                    </div>
                  ) : null}
                </div>
                {deliveryOption === 1 && (
                  <div className="text-left text-gray-800">
                    <label className="block mb-1 text-sm font-medium">
                      Address
                    </label>
                    <input
                      name="address"
                      type="text"
                      {...formik.getFieldProps("address")}
                      className="block w-full p-2 text-black transition duration-300 ease-linear border border-gray-400 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                    />
                    {formik.errors.address ? (
                      <div className="text-xs">
                        <InputMessage message={formik.errors.address} />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start w-full mt-2 space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                <div className="w-full text-left text-gray-800">
                  <label className="block mb-1 text-sm font-medium">
                    Phone
                  </label>
                  <input
                    name="phone"
                    type="text"
                    {...formik.getFieldProps("phone")}
                    className="block w-full p-2 text-black transition duration-300 ease-linear border border-gray-400 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                  />
                  {formik.errors.phone ? (
                    <div className="text-xs">
                      <InputMessage message={formik.errors.phone} />
                    </div>
                  ) : null}
                </div>
                <div className="w-full text-left text-gray-800">
                  <label className="block mb-1 text-sm font-medium">
                    Email address
                  </label>
                  <input
                    name="email"
                    type="text"
                    {...formik.getFieldProps("email")}
                    className="block w-full p-2 text-black transition duration-300 ease-linear border border-gray-400 rounded-md outline-none focus:border-primary placeholder:text-gray-300"
                  />
                  {formik.errors.email ? (
                    <div className="text-xs">
                      <InputMessage message={formik.errors.email} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="w-full">
              <h4 className="text-lg font-semibold text-gray-800">
                Order summary
              </h4>
              <div className="flex items-end justify-between">
                <h6 className="text-base font-bold">Sub-total</h6>
                <h6 className="text-lg font-bold">&#8358;{totalPrice}</h6>
              </div>
              <div className="flex items-end justify-between">
                <h6 className="text-base font-bold">{`Delivery - (${
                  deliveryFee > 0 ? deliveryLocation : "Pickup"
                })`}</h6>
                <h6 className="text-lg font-bold">&#8358;{deliveryFee}</h6>
              </div>
              <div className="w-[98%] mx-auto border-t my-2 border-gray-500"></div>
              <div className="flex items-end justify-between">
                <h6 className="text-lg font-bold uppercase">Total</h6>
                <h6 className="text-lg font-bold">
                  &#8358;{totalPrice + deliveryFee}
                </h6>
              </div>
            </div>
            <div className="mt-10 space-y-1.5">
              <h5 className="text-lg font-semibold text-gray-800">
                Select payment option
              </h5>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {[0, 1].map((item: number, index: number) => {
                  return (
                    <label
                      key={index}
                      className={classNames(
                        "flex items-start space-x-2",
                        index === 0 ? "cursor-pointer" : "cursor-default"
                      )}
                    >
                      <input
                        type="radio"
                        checked={paymentOption === index}
                        disabled={index === 1}
                        onChange={() => setPaymentOption(index)}
                        className="mt-1.5"
                      />
                      <div className="space-y-1 text-left">
                        <div
                          className={classNames(
                            "flex items-center space-x-1.5 text-base font-semibold",
                            index === 1 && "text-gray-400"
                          )}
                        >
                          <span>
                            {index === 0 ? "Direct bank transfer" : "Paystack"}
                          </span>{" "}
                          {index === 1 && (
                            <span className="bg-red-500 text-white text-[10px] leading-[12px] font-medium px-2.5 py-1 rounded uppercase">
                              coming soon
                            </span>
                          )}
                        </div>
                        <p className="hidden text-sm italic text-gray-800">
                          {index === 0
                            ? "Make your payment directly into our bank account. please use your order ID as the payment reference. Your order will not be shipped until funds have cleared in our account."
                            : "Make payment via paystack link."}
                        </p>
                      </div>
                    </label>
                  );
                })}
              </div>
            </div>
            {paymentOption === 10 && (
              <div className="text-black bg-[#ebe9eb] rounded-md mt-10">
                {/* <div className="p-6 space-y-2">
                                <div className="flex flex-col items-center space-y-1 md:space-y-0 md:space-x-4 md:flex-row">
                                    <p className="text-base capitalize">Debit / Credit card</p>
                                    <img
                                        src="/media/svg/payment-cards.svg"
                                        alt="icon"
                                        className="mx-auto w-44"
                                    />
                                </div>
                                <p className="text-sm">
                                    Make payments using your debit and credit cards
                                </p>
                            </div> */}
                <div className="p-3 md:p-6">
                  <h5 className="text-base capitalize">
                    Authenticated by{" "}
                    <img
                      src="/media/svg/stripe.svg"
                      alt="stripe"
                      className="inline-flex h-8 -ml-1 md:-ml-3 w-14 md:w-24 md:h-10"
                    />
                  </h5>
                  <p className="mt-1 text-sm">
                    Make payments using your debit and credit cards
                  </p>
                </div>
                <div className="p-3 border-t border-gray-800 md:p-6">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      onChange={() => setToc(!toc)}
                      checked={toc}
                      className="outline-none cursor-pointer rounded-2xl"
                    />
                    <p className="text-sm">
                      I have read and agreed to the website{" "}
                      <span className="underline underline-offset-2">
                        terms and conditions
                      </span>{" "}
                      *
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center justify-center pt-12">
              <button
                type="submit"
                className="p-1.5 text-center text-white capitalize rounded-lg w-32 bg-primary disabled:opacity-60"
                disabled={
                  formik.isSubmitting ||
                  (!toc && !formik.isSubmitting && paymentOption === 1)
                }
              >
                {formik.isSubmitting ? (
                  <ImSpinner8
                    size={24}
                    className="mx-auto animate-spin fill-white"
                  />
                ) : (
                  "Place Order"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        show={showSuccessModal}
        onToggle={() => {
          setShowSuccessModal(false);
          formik.resetForm({});
          dispatch(SET_CART({}));
          setPaymentDetails([]);
          setDeliveryOption(0);
          setDeliveryFee(0);
          setDeliveryLocation("");
        }}
        size={paymentOption === 0 ? "large" : "medium"}
      >
        {paymentOption === 0 ? (
          <div className="relative flex flex-col p-4 md:py-12 md:px-16">
            <h2 className="text-lg font-medium text-center text-black uppercase">
              Check out
            </h2>
            <div className="mt-5 md:mt-10 flex items-start space-x-2.5">
              <img
                src="/media/svg/check.svg"
                alt="icon"
                className="w-8 h-8 -mt-0.5 md:-mt-1 md:w-10 md:h-10"
              />
              <div className="space-y-1">
                <h1 className="text-xl font-medium md:text-2xl">
                  Order received - ID {`(${orderID})`}
                </h1>
                {/* <h1 className="text-xl font-medium md:text-2xl">Order received</h1> */}
                <h6 className="text-sm font-normal md:text-base">
                  Thank you. Your order has been received.
                </h6>
              </div>
            </div>
            <div className="w-full mt-5 space-y-1.5 md:mt-10">
              <h4 className="mb-2.5 text-base font-semibold text-gray-800 uppercase">
                Order summary
              </h4>
              <p className="text-sm font-medium text-gray-800">
                Order ID - {`(${orderID})`}
              </p>
              <div className="flex items-end justify-between text-sm font-medium text-gray-800">
                <h6 className="">Sub-total</h6>
                <h6 className="">&#8358;{totalPrice}</h6>
              </div>
              <div className="flex items-end justify-between text-sm font-medium text-gray-800">
                <h6 className="">{`Delivery - (${
                  deliveryFee > 0 ? deliveryLocation : "Pickup"
                })`}</h6>
                <h6 className="">&#8358;{deliveryFee}</h6>
              </div>
              <div className="w-[98%] mx-auto border-t my-2 border-gray-500"></div>
              <div className="flex items-end justify-between text-sm font-medium text-gray-800">
                <h6 className="text-base font-semibold uppercase">Total</h6>
                <h6 className="text-base font-semibold">
                  &#8358;{totalPrice + deliveryFee}
                </h6>
              </div>
            </div>
            {deliveryOption === 1 && (
              <div className="w-full mt-4 space-y-1.5 md:mt-8">
                <h4 className="mb-2.5 text-base font-semibold text-gray-800 uppercase">
                  Delivery summary
                </h4>
                <div className="flex items-start space-x-2 text-sm font-medium text-gray-800">
                  <h6 className="font-semibold">Name:</h6>
                  <h6 className="">{formik.values.fullName}</h6>
                </div>
                <div className="flex items-start space-x-2 text-sm font-medium text-gray-800">
                  <h6 className="font-semibold">Address:</h6>
                  <h6 className="">{formik.values.address}</h6>
                </div>
                <div className="flex items-start space-x-2 text-sm font-medium text-gray-800">
                  <h6 className="font-semibold">Phone:</h6>
                  <h6 className="">{formik.values.phone}</h6>
                </div>
              </div>
            )}
            <div className="w-full mt-4 space-y-1.5 md:mt-8">
              <h4 className="mb-2.5 text-base font-semibold text-gray-800 uppercase">
                Payment option
              </h4>
              <div className="space-y-1 text-sm font-medium text-gray-800">
                <h6 className="font-semibold">Direct bank transfer</h6>
                <p className="w-full italic sm:w-[90%]">
                  Make your payment directly into any of our bank account.
                  Please use your order ID as the payment reference. Your order
                  will not be shipped until funds have cleared in our account.
                </p>
              </div>
            </div>
            {paymentDetails.length > 0 && (
              <div className="mt-4 md:mt-8">
                <div className="text-black bg-[#ebe9eb] rounded-md flex items-start md:flex-row flex-col justify-center md:space-y-0 space-y-5 md:space-x-10 p-5 md:px-14 lg:px-10 xl:px-14 md:py-10">
                  {paymentDetails.map((detail: any, index: number) => {
                    return (
                      <div key={index} className="w-full space-y-1.5">
                        <h4 className="mb-2.5 text-base font-semibold text-gray-800">
                          Bank Details {`(${index + 1})`}
                        </h4>
                        <div className="flex items-start space-x-2 text-sm font-medium text-gray-800">
                          <h6 className="">Name:</h6>
                          <h6 className="grow">{detail.accountName}</h6>
                        </div>
                        <div className="flex items-start space-x-2 text-sm font-medium text-gray-800">
                          <h6 className="">Bank:</h6>
                          <h6 className="grow">{detail.bankName}</h6>
                        </div>
                        <div className="flex items-start space-x-2 text-sm font-medium text-gray-800">
                          <h6 className="whitespace-nowrap">Account Number:</h6>
                          <h6 className="">{detail.accountNumber}</h6>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="relative flex flex-col p-6 md:py-12 md:px-16">
            <div className="mt-2 text-center md:mt-4">
              <img
                src="/media/svg/check.svg"
                alt="icon"
                className="w-24 mx-auto"
              />
            </div>
            <div className="mt-2 space-y-2 text-center md:mt-4">
              <h1 className="font-semibold md:text-2xl">
                Your Order was successfull!
              </h1>
              <h1 className="font-semibold md:text-xl">
                Order ID: <span className="text-gray-400">{orderID}</span>
              </h1>
              <h1 className="font-semibold md:text-xl">
                Status: <span className="text-gray-400">{"Pending"}</span>
              </h1>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Cart;
