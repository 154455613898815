import { Menu, Transition } from "@headlessui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { Fragment, useState } from "react";
import { IoMdMore } from "react-icons/io";
import { MdKeyboardBackspace } from "react-icons/md";
import {
  delectS3Object,
  deleteSingleMenu,
  getMenuByCategory,
  getSingleItem,
} from "../../../../api/Menu";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../../../components/Dashboard/Modal";
import { ImSpinner2 } from "react-icons/im";

const SingleProduct = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const productID: string = id || "";
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [selectedObject, setSelectedObject] = useState<any>({});
  let item: any;

  const loadSingleItem = useQuery<any, Error>({
    queryKey: ["getSingleItem", productID],
    queryFn: async () => {
      const response = await getSingleItem(productID);
      return response.data;
    },
    enabled: !!productID,
    // onSuccess: (data) => {
    //   setImageUrl(data.Menu_Item.s3ImageUrl);
    //   setItemPrice(data.Menu_Item.itemPrice);
    //   setItemDefaultPrice(data.Menu_Item.itemPrice);

    //  const itemSizesFetch = data.Menu_Item?.itemVariations?.reduce(
    //     (acc, item) => {
    //       const existingItem = acc.find((obj) => obj.size === item.size);
    //       if (!existingItem) {
    //         acc.push(item);
    //       } else {
    //         existingItem.stock += item.stock;
    //       }
    //       return acc;
    //     },
    //     []
    //   );

    //   const itemColorsFetch = data.Menu_Item?.itemVariations?.reduce(
    //     (acc, item) => {
    //       const existingItem = acc.find((obj) => obj.color === item.color);
    //       if (!existingItem) {
    //         acc.push(item);
    //       } else {
    //         existingItem.stock += item.stock;
    //       }
    //       return acc;
    //     },
    //     []
    //   );

    //   setItemSizes(itemSizesFetch);
    //   setItemColors(itemColorsFetch);
    // },
  });

  const deleteMenu = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        itemId: data.itemId,
        slugUrl: data.slugUrl,
        categoryName: data.categoryName,
      };
      return await deleteSingleMenu(payload);
    },
    onSuccess: () => {
      setSelectedObject({});
      queryClient.invalidateQueries({ queryKey: ["getMenuByCategory"] });
      queryClient.invalidateQueries({ queryKey: ["getStoreItems"] });
      setDeleteItemModal(false);
      navigate("/estore/products?type=items");
    },
  });

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data: any) => {
      const payload = {
        objectKey: data.objectKey,
      };
      return await delectS3Object(payload);
    },
  });

  if (loadSingleItem.data) {
    item = loadSingleItem.data.Menu_Item;
    console.log(item);
  }

  return (
    <>
      <Modal
        show={deleteItemModal}
        onToggle={() => {
          setDeleteItemModal(false);
          setSelectedObject({});
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700">
              You are about to delete this item from{" "}
              {selectedObject.itemCategory}
            </p>
          </div>
          <div className="flex flex-col justify-center md:flex-row gap-2 mt-5 md:mt-8">
            <div>
              <button
                disabled={deleteMenu.isLoading}
                className="px-4 py-2 text-xs font-semibold text-center text-white bg-blue-700 hover:bg-blue-800 border rounded-md w-full md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  deleteMenu.mutate({
                    itemId: selectedObject.menuItemId,
                    slugUrl: selectedObject.slugUrl,
                    categoryName: selectedObject.itemCategory,
                  });
                }}
              >
                {deleteMenu.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 text-xs font-semibold text-center text-blue-700 bg-white hover:bg-gray-50 border border-blue-700 rounded-md  w-full md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedObject({});
                  setDeleteItemModal(false);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {loadSingleItem.isLoading ? (
        <p className="text-sm">Loading</p>
      ) : (
        <div>
          <div className="flex items-center justify-between">
            <div
              className="flex items-center gap-1.5 cursor-pointer"
              onClick={() => navigate("/estore/products?type=items")}
            >
              <MdKeyboardBackspace />
              <p className="font-semibold">{item.itemName}</p>
            </div>
            <div className="flex items-center gap-2 ml-auto">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="w-full text-blue-800 mt-1">
                    <IoMdMore />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-1 w-36 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-10">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-slate-100 font-semibold"
                                : "text-gray-900 font-medium"
                            } w-full rounded-md px-4 py-2 text-xs text-left`}
                            onClick={() => {
                              navigate(
                                `/estore/products/edit-product/${item.menuItemId}`
                              );
                            }}
                          >
                            Edit Item
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-slate-100 font-semibold"
                                : "text-gray-900 font-medium"
                            } w-full rounded-md px-4 py-2 text-xs text-left`}
                            onClick={() => {
                              setSelectedObject(item);
                              setDeleteItemModal(true);
                            }}
                          >
                            Delete Item
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-4 mt-6">
              {item.s3ImageUrl !== "" && (
                <div className="w-[280px]">
                  <img src={item.s3ImageUrl} alt="" className="w-full h-full" />
                </div>
              )}
              {item.s3ImageUrlOne !== "" && (
                <div className="w-[280px]">
                  <img
                    src={item.s3ImageUrlOne}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              )}
              {item.s3ImageUrlTwo !== "" && (
                <div className="w-[280px]">
                  <img
                    src={item.s3ImageUrlTwo}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              )}
              {item.s3ImageUrlThree !== "" && (
                <div className="w-[280px]">
                  <img
                    src={item.s3ImageUrlThree}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="space-y-2 mt-8 text-[13px] text-gray-800 ">
            <p className="font-semibold text-sm">{item.itemName}</p>
            <p className="capitalize">{item.description}</p>
            <p>Category: {item.itemCategory}</p>
            <p>Price: {item.itemPrice}</p>
            <p>Variant: {item?.itemVariations?.length || ""}</p>
            <p>Stock: {item?.available_stock || ""}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleProduct;
