import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import OnBoardContainer from "../components/Auth/OnBoardContainer";
import DashBoardContainer from "../components/Dashboard/DashBoardContainer";
import RequireAuth from "../components/ProtectedRoute";
import LoginPage from "../pages/Auth/LoginPage";
import CustomerReviewPage1 from "../pages/Dashboard/CustomerReviewPage/CustomerReview1";
import CustomerSatisfactionPage from "../pages/Dashboard/Modules/FeedbackAnalytics/CustomerSatisfactionPage";
import DashBoardPage from "../pages/Dashboard/Modules/FeedbackAnalytics/DashBoardPage";
import DataHistoryPage from "../pages/Dashboard/DataHistoryPage";
import DataHistoryArchivePage from "../pages/Dashboard/DataHistoryPage/DataHistoryArchivePage";
import DataHistoryTrashPage from "../pages/Dashboard/DataHistoryPage/DataHistoryTrashPage";
import KPIPage from "../pages/Dashboard/KPIPage";
import DisplayBoard from "../pages/Dashboard/KPIPage/DisplayBoard/index";
import RoleManagerPage from "../pages/Dashboard/RoleManagerPage";
import ScanCodePage from "../pages/Dashboard/ScanCodePage";
import DirectReview from "../pages/Dashboard/ScanCodePage/DirectReview";
import FeedBackPage from "../pages/FeedbackPage";
import PasswordPage from "../pages/Auth/PasswordPage";
import SearchReviewsPage from "../pages/Dashboard/SearchReviewsPage";
import { UserProfileModel } from "../models";
import { useAppSelector } from "../hooks";
import { isAdmin } from "../utils";
import QRGeneratorPage from "../pages/Dashboard/QRGeneratorPage";
import IngestionLibraryPage from "../pages/Dashboard/IngestionLibraryPage";
import IngestionLibraryScanQRCode from "../pages/Dashboard/IngestionLibraryPage/IngestionLibraryScanQRCode";
import IngestionLibraryWebsite from "../pages/Dashboard/IngestionLibraryPage/IngestionLibraryWebsite";
import DirectTicket from "../pages/Dashboard/ScanCodePage/DirectTicket";
import TrainingRepoPage from "../pages/Dashboard/Modules/TrainingRepository/TrainingRepoPage";
import ContentManagerPage from "../pages/Dashboard/Modules/ContentManager/ContentManagerPage";
// import DataStorePage from "../pages/Dashboard/Modules/DataStore/DataStorePage";
import TaskWizardPage from "../pages/Dashboard/Modules/TaskWizard/TaskWizardPage";
import EchoChatPage from "../pages/Dashboard/Modules/EchoChat/EchoChatPage";
import TicketsPage from "../pages/Dashboard/Modules/FeedbackAnalytics/TicketsPage";
import HomePage from "../pages/Dashboard/Modules/Home/HomePage";
import EchoReservationPage from "../pages/Dashboard/Modules/Home/EchoReservationPage";
import MenuPage from "../pages/MenuPage";
import OrdersPage from "../pages/Dashboard/OrdersPage";
import CustomerLoyaltyPage from "../pages/Dashboard/Modules/FeedbackAnalytics/CustomerLoyaltyPage";
import DirectCustomerLoyaltyPage from "../pages/Dashboard/Modules/FeedbackAnalytics/DirectCustomerLoyalty";
import ReservationPage from "../pages/ReservationPage";
import EchoChatRoute from "../pages/Dashboard/EchoChatRoute";
import AllCustomerReviewPage from "../pages/Dashboard/CustomerReviewPage/All/All1";
import AllRoomsPage from "../pages/Dashboard/Modules/Home/EchoReservationPage/AllRoomsPage";
import WebStoreLandingPage from "../pages/WebStore";
import UploadUrl from "../pages/Dashboard/Modules/Home/UploadUrl";
import DataStorePage from "../pages/Dashboard/Modules/DataStore/DataStorePage";
import OrdersHistoryPage from "../pages/Dashboard/OrdersHistoryPage";
import WebStoreProductPage from "../pages/WebStore/Products";
import WalletPage from "../pages/Dashboard/Modules/Home/WalletPage";
import PayoutPage from "../pages/Dashboard/Modules/Home/PayoutPage";
import NewMessage from "../pages/Dashboard/Modules/FeedbackAnalytics/MessagesPage/NewMessage";
import SmallBizPlus from "../pages/Dashboard/Modules/Home/HomePage/SmallBizPlus";
import BusinessBoost from "../pages/Dashboard/Modules/Home/HomePage/BusinessBoost";
import GrowthPlan from "../pages/Dashboard/Modules/Home/HomePage/GrowthPlan";
import ReviewHistory from "../pages/Dashboard/ScanCodePage/ReviewData/ReviewHistory";
import Settings from "../pages/Dashboard/SettingsPage/Settings";
import MessagesPage from "../pages/Dashboard/Modules/FeedbackAnalytics/MessagesPage";
import SendSMSMessage from "../pages/Dashboard/Modules/FeedbackAnalytics/MessagesPage/SendSMSMessage";
import WebPage from "../pages/Dashboard/Modules/Home/WebPage";
import WebStoreSingleProductPage from "../pages/WebStore/Products/Single";
import StoreCustomizer from "../pages/Dashboard/Modules/Home/EchoWebpage";
import EchoWebpage1 from "../pages/Dashboard/Modules/Home/EchoWebpage/EchoWebpage1";
import PaymentsPage from "../pages/Dashboard/Modules/Home/PaymentsPage";
import SupportPage from "../pages/Dashboard/SupportPage";
import WebStoreAllProductPage from "../pages/WebStore/Products/All";
import WebStoreCancelOrderPage from "../pages/WebStore/Products/CancelOrder";
import WebStoreTrackOrderPage from "../pages/WebStore/Products/TrackOrder";
import DeliveryPage from "../pages/Dashboard/Modules/Home/DeliveryPage";
import RegisterPage from "../pages/Auth/ReferralPage";
import LoginPage1 from "../pages/Auth/LoginPage/LoginPage1";
import PasswordPage1 from "../pages/Auth/PasswordPage/PasswordPage1";
import ChooseProductType from "../pages/Dashboard/Modules/Home/WebPage/ChooseProductType";
import WebStoreCheckoutPage from "../pages/WebStore/Checkout";
import UploadProduct from "../pages/UploadProducts/UploadProduct";
import ProductsPreviewPage from "../pages/UploadProducts/ProductsPreviewPage";
import GroupAccessPage from "../pages/Dashboard/GroupAccessPage";
import CreateGroupAccessPage from "../pages/Dashboard/GroupAccessPage/CreateGroupAccessPage";
import UserAccessPage from "../pages/Dashboard/UserAccessPage";
import CreateUserAccessPage from "../pages/Dashboard/UserAccessPage/CreateUserAccessPage";
import RoleAccessPage from "../pages/Dashboard/RoleAccessPage";
import CreateRoleAccessPage from "../pages/Dashboard/RoleAccessPage/CreateRoleAccessPage";
import SingleUserAccessPage from "../pages/Dashboard/UserAccessPage/SingleUserAccessPage";
import SingleGroupAccessPage from "../pages/Dashboard/GroupAccessPage/SingleGroupAccessPage";
import WebStorePaymentCallbackPage from "../pages/WebStore/Checkout/PaymentCallbackPage";
import HomeSummaryPage from "../pages/Dashboard/Modules/Home/HomePage/HomeSummaryPage";
import Products from "../pages/Dashboard/Modules/Estore/Products";
import CustomerSatisfactionPageNew from "../pages/Dashboard/Modules/FeedbackAnalytics/CustomerSatisfactionPage/CustomerSatisfactionPageNew";
import EstoreSetttings from "../pages/Dashboard/Modules/Estore/EstoreSetttings";
import EstoreProfile from "../pages/Dashboard/Modules/Estore/_components/EstoreSettingsPages/EstoreProfile";
import EstoreSecurity from "../pages/Dashboard/Modules/Estore/_components/EstoreSettingsPages/EstoreSecurity";
import EstoreBusinessInformation from "../pages/Dashboard/Modules/Estore/_components/EstoreSettingsPages/EstoreBusinessInformation";
import EstoreDelivery from "../pages/Dashboard/Modules/Estore/_components/EstoreSettingsPages/EstoreDelivery";
import EstorePaymentInformation from "../pages/Dashboard/Modules/Estore/_components/EstoreSettingsPages/EstorePaymentInformation";
import EstoreSmartPricing from "../pages/Dashboard/Modules/Estore/_components/EstoreSettingsPages/EstoreSmartPricing";
import EstoreStoreAnalysis from "../pages/Dashboard/Modules/Estore/_components/EstoreSettingsPages/EstoreStoreAnalysis";
import EstoreDeliveryEdit from "../pages/Dashboard/Modules/Estore/_components/EstoreSettingsPages/EstoreDeliveryEdit";
import SmartPricingPage from "../pages/Dashboard/SmartPricingPage";
import StoreAnalyticsPage from "../pages/Dashboard/StoreAnalyticsPage";
import SingleProduct from "../pages/Dashboard/Modules/Estore/SingleProduct";
import AddProduct from "../pages/Dashboard/Modules/Estore/AddProduct";
import EditProduct from "../pages/Dashboard/Modules/Estore/EditProduct";
import MessagesPageNew from "../pages/Dashboard/Modules/FeedbackAnalytics/MessagesPage/MessagesPageNew";
import WalletPageNew from "../pages/Dashboard/Modules/Home/WalletPage/WalletPageNew";
import EstoreOrdersArchive from "../pages/Dashboard/Modules/Estore/_components/EstoreSettingsPages/EstoreOrdersArchive";
import DisplayBoardNew from "../pages/Dashboard/KPIPage/DisplayBoard/DisplayBoardNew";
import KPIReviews from "../pages/Dashboard/KPIPage/KPIReviews";
import FeedBackPageNew from "../pages/FeedbackPage/FeedbackPageNew";
import SingleOrderPage from "../pages/Dashboard/OrdersPage/_components/SingleOrderPage";
import CompleteRegisterationPage from "../pages/Auth/CompleteRegistration";

const AppRoutes = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<App />}>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/complete-registration/:email/:passcode" element={<CompleteRegisterationPage />} />
          <Route path="/login" element={<LoginPage1 />} />
          <Route path="" element={<LoginPage1 />} />
          <Route path="/forgot-password" element={<PasswordPage1 />} />
          <Route path="/feedback" element={null}>
            <Route path="" element={<Navigate to="/register" />} />
            <Route path=":salt/:slug" element={<FeedBackPageNew />} />
          </Route>
          {/* <Route path="/our-menu/:id" element={<WebStoreProductPage />} /> */}
          <Route path="/our-reservations" element={<ReservationPage />} />
          {/* <Route path="/templates/starter/:id/:section?" element={<WebStoreLandingPage />} /> */}
          <Route path="/store" element={null}>
            <Route path="" element={<Navigate to="/register" />} />
            <Route
              path=":salt/:slug/:section?"
              element={<WebStoreLandingPage />}
            />
            <Route
              path=":salt/:slug/all-products"
              element={<WebStoreAllProductPage />}
            />
            <Route
              path=":salt/:slug/all-products/category/:category"
              element={<WebStoreProductPage />}
            />
            <Route
              path=":salt/:slug/all-products/category/:category/product/:id"
              element={<WebStoreSingleProductPage />}
            />
            {/* <Route path=":salt/:slug/cancel-order" element={<WebStoreCancelOrderPage />} /> */}
            <Route
              path=":salt/:slug/order-status"
              element={<WebStoreCancelOrderPage />}
            />
            <Route
              path=":salt/:slug/checkout"
              element={<WebStoreCheckoutPage />}
            />
            <Route
              path=":salt/:slug/checkout/callback/payment"
              element={<WebStorePaymentCallbackPage />}
            />
          </Route>
        </Route>
        <Route element={<RequireAuth />}>
          <Route element={<DashBoardContainer />} >
            <Route path="/home">
              <Route index element={<HomePage />} />
              {isAdmin(`${user.email}`) ? (
                <Route path="upload-product" element={<UploadProduct />} />
              ) : null}

              {isAdmin(`${user.email}`) ? (
                <Route
                  path="upload-product/preview"
                  element={<ProductsPreviewPage />}
                />
              ) : null}
              <Route path="echo-webpage" element={<WebPage />} />
              <Route path="features" element={<HomeSummaryPage />} />
              <Route
                path="choose-product-type"
                element={<ChooseProductType />}
              />
              <Route path="echo-webpage/upload-url" element={<UploadUrl />} />
              <Route path="echo-booking" element={<EchoReservationPage />} />
              <Route
                path="echo-webpage/customizer"
                element={<StoreCustomizer />}
              />
              <Route
                path="echo-webpage/customizer/new"
                element={<EchoWebpage1 />}
              />
              <Route path="echo-booking/rooms" element={<AllRoomsPage />} />
              <Route path="delivery" element={<DeliveryPage />} />
              {/* <Route path="wallet" element={<WalletPage />} /> */}
              <Route path="wallet" element={<WalletPageNew />} />
              <Route path="payout" element={<PayoutPage />} />
              {/* <Route path="payments" element={<PaymentsPage />} /> */}
              <Route path="payments" element={<WalletPageNew />} />
              <Route path="small-biz-plus" element={<SmallBizPlus />} />
              <Route path="growth-plan" element={<GrowthPlan />} />
              <Route path="business-boost" element={<BusinessBoost />} />
            </Route>
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/orders/:id" element={<SingleOrderPage />} />
            <Route path="/estore/products" element={<Products />} />
            <Route path="/estore/products/add-product" element={<AddProduct />} />
            <Route path="/estore/products/edit-product/:id" element={<EditProduct />} />
            <Route path="estore/products/:id" element={<SingleProduct />} />
            <Route path="/estore/smart-pricing" element={<SmartPricingPage />} />
              <Route path="/estore/store-analytics" element={<StoreAnalyticsPage />} />
            <Route element={<EstoreSetttings />}>
              <Route
                path="/estore/settings/profile"
                element={<EstoreProfile />}
              />
              <Route
                path="/estore/settings/security"
                element={<EstoreSecurity />}
              />
              <Route
                path="/estore/settings/orders-archive"
                element={<EstoreOrdersArchive />}
              />
              <Route
                path="/estore/settings/business-information"
                element={<EstoreBusinessInformation />}
              />
              <Route
                path="/estore/settings/delivery"
                element={<EstoreDelivery />}
              />
              <Route
                path="/estore/settings/delivery/edit"
                element={<EstoreDeliveryEdit />}
              />
              <Route
                path="/estore/settings/payment-information"
                element={<EstorePaymentInformation />}
              />
              <Route
                path="/estore/settings/smart-pricing"
                element={<EstoreSmartPricing />}
              />
              <Route
                path="/estore/settings/store-analysis"
                element={<EstoreStoreAnalysis />}
              />
            </Route>
            <Route path="/orders/history" element={<OrdersHistoryPage />} />
            <Route path="/content-schedule" element={<DashBoardPage />} />
            <Route path="/content-suggester" element={<DashBoardPage />} />
            <Route path="/track-analytics" element={<DashBoardPage />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="role-manager" element={<RoleManagerPage />} />
            <Route path="support" element={<SupportPage />} />
            <Route path="support/:reason" element={<SupportPage />} />
            <Route path="feedback-analysis" element={null}>
              <Route
                path="customer-satisfaction1"
                element={<CustomerSatisfactionPage />}
              />
              <Route
                path="customer-satisfaction"
                element={<CustomerSatisfactionPageNew />}
              />
              <Route path="customer-review" element={<CustomerReviewPage1 />} />
              <Route
                path="customer-review/all"
                element={<AllCustomerReviewPage />}
              />
              <Route path="comments" element={<DashBoardPage />} />
              <Route path="help-desk" element={<TicketsPage />} />
              <Route path="messages" element={<MessagesPageNew />} />
              <Route
                path="messages/:recipentEmail"
                element={<MessagesPage />}
              />
              <Route path="messages/new-message" element={<NewMessage />} />
              <Route
                path="messages/new-message/sms"
                element={<SendSMSMessage />}
              />
              <Route path="messages/forward/:id" element={<NewMessage />} />
              <Route
                path="messages/new-message/:recipientEmail"
                element={<NewMessage />}
              />
              <Route
                path="messages/sms/:recipientPhoneNumber"
                element={<MessagesPage />}
              />
              <Route
                path="messages/:recipentPhoneNumber/:messageId"
                element={<MessagesPage />}
              />
              <Route path="data-history">
                <Route path="" element={<DataHistoryPage />} />
                <Route path="archive" element={<DataHistoryArchivePage />} />
                <Route path="trash" element={<DataHistoryTrashPage />} />
              </Route>
              <Route path="scan-qr-code" element={<ScanCodePage />} />
              <Route path="media-store" element={<DashBoardPage />} />
              <Route path="customer-log" element={<ReviewHistory />} />
              <Route path="customer-log/:email" element={<DirectReview />} />
              <Route path="direct-ticket/:email" element={<DirectTicket />} />
              <Route path="kpi" element={<KPIPage />} />
              <Route path="update-business-metrics" element={<KPIPage />} />
              {/* <Route path="business-metrics/:id" element={<KPIReviews />} /> */}
              <Route path="business-metrics">
                <Route path="" element={<DisplayBoardNew />} />
                <Route path=":id" element={<KPIReviews />} />
              </Route>
              <Route path="display-board1" element={<DisplayBoard />} />
              {/* <Route path="display-board" element={<DisplayBoardNew />} />
              <Route
                path="display-board/:successMetric"
                element={<DisplayBoard />}
              /> */}
              <Route
                path="ingestion-library"
                element={<IngestionLibraryPage />}
              />
              <Route
                path="ingestion-library/scan-qr-code"
                element={<IngestionLibraryScanQRCode />}
              />
              <Route
                path="ingestion-library/website"
                element={<IngestionLibraryWebsite />}
              />
              <Route path="search-reviews" element={<SearchReviewsPage />} />
              <Route
                path="customer-loyalty"
                element={<CustomerLoyaltyPage />}
              />
              <Route
                path="customer-loyalty/:email"
                element={<DirectCustomerLoyaltyPage />}
              />
            </Route>
            <Route path="access-manager" element={null}>
              <Route path="groups" element={null}>
                <Route path="" element={<GroupAccessPage />} />
                <Route path="create" element={<CreateGroupAccessPage />} />
                <Route path=":name" element={<SingleGroupAccessPage />} />
              </Route>
              <Route path="users" element={null}>
                <Route path="" element={<UserAccessPage />} />
                <Route path="create" element={<CreateUserAccessPage />} />
                <Route path=":name" element={<SingleUserAccessPage />} />
              </Route>
              <Route path="roles" element={null}>
                <Route path="" element={<RoleAccessPage />} />
                <Route path="create" element={<CreateRoleAccessPage />} />
              </Route>
            </Route>
            <Route path="/training-repository" element={<TrainingRepoPage />} />
            <Route path="/content-manager" element={<ContentManagerPage />} />
            <Route path="/data-store" element={<DataStorePage />} />
            <Route path="/task-wizard" element={<TaskWizardPage />} />
            <Route path="/echo-chat" element={<EchoChatPage />} />
            {isAdmin(`${user.email}`) ? (
              <Route path="/qr-generator" element={<QRGeneratorPage />} />
            ) : null}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
