import React from "react";
import { IoChevronForward } from "react-icons/io5";

const EstoreStoreAnalysis = () => {
  return (
    <div className="col-span-3 h-full bg-white p-3">
      <p className="text-gray-800 font-semibold">Store analysis</p>
      <div className="mt-2 grid grid-cols-4 gap-6">
        {[1, 2, 3, 4].map((index: number) => (
          <div
            className="p-3 text-[13px] space-y-1 border border-gray-300 rounded-md"
            key={index}
          >
            <p>Total sales</p>
            <p>141</p>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <p className="text-sm">Most product sales</p>
        <div className="border border-gray-200 mt-3">
          <div className="p-3 flex items-center justify-between text-sm bg-gray-100">
            <p>Item name</p>
            <p>Sales (₦)</p>
            <p>No.</p>
          </div>
          {[1, 2, 3, 4, 5].map((index: number) => (
            <div className="px-3 py-2 flex items-center justify-between text-sm" key={index} >
              <p>{index}. Nike all-stars</p>
              <p>50,000</p>
              <p>200</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EstoreStoreAnalysis;
