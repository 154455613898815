import {ImSpinner2} from "react-icons/im";
import { classNames } from "../../../../utils";

interface ButtonProps {
  type?: any;
  value: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?:boolean;
  changeBgColor?:boolean;
}

const Button = ({ type = "button", value, onClick, disabled = false, isLoading = false, changeBgColor = false }: ButtonProps) => {
  return (
    <button
      type={type}
      className={classNames("w-full py-2 text-sm font-semibold text-center text-white outline-none rounded-md", changeBgColor ? "bg-[#263C6B]" : "bg-primary")}
      onClick={onClick}
      disabled={disabled}
    >{isLoading ? (<ImSpinner2 size={28} className="mx-auto animate-spin"/>) : (value)}
    </button>
  );
};

export default Button;
