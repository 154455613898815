import { Link } from "react-router-dom";
import { getImage } from "../../../../../utils";

const ContentManagerPage = () => {
  return (
    <>
      <div className="space-y-1 hidden">
        <div className="h-[85vh] bg-gradient-to-b from-10% via-white via-30% to-90% from-blue-100 to-white rounded-md shadow-md py-10 px-4 overflow-y-hidden">
          <div className="w-full">
            <div className="w-10/12 mx-auto">
              <img
                src={getImage("content-manager-icon-group")}
                alt=""
                className="max-w-full mx-auto"
              />
            </div>
          </div>
          <div className="mt-10 text-center">
            <h4 className="text-2xl font-semibold">
              Get Content Tailored To Your Business
            </h4>
            <p className="mt-2 text-base font-medium leading-tight text-gray-700">
              You are currently on the free plan but getting more features is
              easy
            </p>
            <Link
              to="/settings/subscriptions"
              className="inline-block px-10 py-4 mt-8 text-xl font-semibold text-center text-white rounded-md bg-primary hidden"
            >
              Request An Upgrade
            </Link>
            <p className="mt-3 text-base font-medium leading-tight text-gray-700 hidden">
              Learn about our plans and pricing{" "}
              <Link
                to="/settings/subscriptions"
                className="underline text-primary underline-offset-1 hidden"
              >
                here
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="space-y-1">
        <div className="overflow-y-hidden relative">
          <div className="w-full">
            <div className="h-full mx-auto">
              <img
                src={getImage("coming-soon-bg")}
                alt=""
                className="max-w-full mx-auto"
              />
            </div>
          </div>
          <div className="mt-10 absolute bottom-16 pl-10 text-[#132C58] ">
            <h4 className="text-6xl font-bold uppercase ">Coming soon</h4>
            <p className="mt-2 text-lg font-medium max-w-[480px]">
              We’re working hard and fast to bring you features that will help
              scale your business.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentManagerPage;
