
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, FLUSH, PAUSE, PURGE, persistStore, REGISTER, REHYDRATE, PERSIST } from 'redux-persist'
import { rootReducer } from './RootReducer'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage'
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';


const config = {
    // TOGGLE_TODO will not be triggered in other tabs
    whitelist: ["utils"],
};
const middlewares = [createStateSyncMiddleware(config)];

const persistConfig = {
    key: `${process.env.REDUX_STORE_KEY}`,
    version: 1.0,
    storage,
    // blacklist: [ "user" ],
    transforms: [
        encryptTransform({
            secretKey: `${process.env.REDUX_STORE_SECRET_KEY}`,
            onError: function (error) {
                // Handle the error.
                console.log(error)
            },
        }),
    ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(middlewares),
    devTools: process.env.NODE_ENV !== 'production',
})


export const persistor = persistStore(store)
export default store;
export type AppDispatch = typeof store.dispatch