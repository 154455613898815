import React from "react";

const EstoreProfile = () => {
  return (
    <div className="col-span-3 h-full bg-white p-3">
      <p className="text-gray-800">Profile</p>
      <div className="mt-4">
        <p className="text-blue-700">Personal information</p>
        <div className="text-sm space-y-1 mt-4">
          <p>Fullname</p>
          <p className="text-gray-600">Doherty Jonathan</p>
        </div>
        <div className="text-sm space-y-1 mt-4">
          <p>Email</p>
          <p className="text-gray-600">joshuaubiri@gmail.com</p>
        </div>
        <div className="text-sm space-y-1 mt-4">
          <p>Phone number</p>
          <p className="text-gray-600">+2348130609629</p>
        </div>
      </div>
      <div className=" space-y-2 text-sm mt-4 border-t-[1px] border-gray-300 py-3">
        <p className="text-blue-600">Account management</p>
        <p>Disable account</p>
        <p className="text-red-600">Delete account</p>
      </div>
    </div>
  );
};

export default EstoreProfile;
