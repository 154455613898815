/* eslint-disable array-callback-return */

import { useInfiniteQuery } from "@tanstack/react-query";
import { getAllPublicProducts } from "../../../../api/Utils";
import Layout from "../../../../components/WebStore/Layout";
import { useParams } from "react-router-dom";
import { ImSpinner8 } from "react-icons/im";
import ProductCard from "../../../../components/WebStore/Products/ProductCard";
import { StoreItem } from "../../../../models";
import CategoriesLayout from "../../../../components/WebStore/CategoriesLayout";

const WebStoreAllProductPage = () => {
  const { salt, slug } = useParams();
  const queryKey = `/${salt}/${slug}`;

  let products: StoreItem[] = [];

  const allProducts = useInfiniteQuery<any, Error>({
    queryKey: ["getPaginatedPublicProducts"],
    queryFn: async ({ pageParam = "" }) => {
      const paginationKey = pageParam;
      const response = await getAllPublicProducts(queryKey, paginationKey);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      // console.log({ lastPage });
      if (lastPage !== null) {
        if ("Response" in lastPage) {
          if ("LastEvaluatedKey" in lastPage.Response) {
            if (lastPage.Response.LastEvaluatedKey === "") {
              return false;
            }
            return lastPage.Response.LastEvaluatedKey;
          }
        }
      }
    },
  });

  if (allProducts.data) {
    if ("pages" in allProducts.data) {
      allProducts.data.pages.forEach((page) => {
        if (page !== null) {
          if ("Response" in page) {
            if ("StoreItems" in page.Response) {
              products = [...products, ...page.Response.StoreItems];
            }
          }
        }
      });
    }
  }

  return (
    <Layout>
      <section className="bg-white">
        <div className="container px-4 pb-28 md:pb-14 pt-14 md:pt-14 md:px-14">
          <CategoriesLayout />
          {allProducts.status === "loading" ? (
            <div className="grid grid-cols-2 max-w-[1100px] mx-auto gap-x-4 sm:gap-x-6 gap-6 lg:gap-10 md:grid-cols-3 lg:grid-cols-4">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((index: number) => (
                <div
                  className="bg-gray-100 h-[230px] rounded-md"
                  key={index}
                ></div>
              ))}
            </div>
          ) : allProducts.status === "error" ? (
            <p className="mt-8 font-semibold text-center text-gray-400">
              {allProducts.error.message}
            </p>
          ) : products.length > 0 ? (
            <div className="grid grid-cols-2 max-w-[1100px] mx-auto gap-x-4 sm:gap-x-6 gap-6 lg:gap-10 md:grid-cols-3 lg:grid-cols-4">
              {products.map((product: StoreItem, index: number) => {
                return <ProductCard key={index} product={product} />;
              })}
            </div>
          ) : (
            <p className="mt-8 text-lg text-center text-gray-400">
              No products available
            </p>
          )}
          {products?.length > 0 && allProducts.hasNextPage && (
            <button
              onClick={() => allProducts.fetchNextPage()}
              disabled={
                !allProducts.hasNextPage || allProducts.isFetchingNextPage
              }
              className="block w-44 py-2.5 mx-auto text-center text-blue-700 underline underline-offset-2 mt-14 text-sm"
            >
              {allProducts.isFetchingNextPage ? "" : "See more"}
            </button>
          )}
          {products?.length > 0 && !allProducts.hasNextPage && (
            <p className="mt-12 text-sm font-medium text-center text-gray-700">
              That's it!
            </p>
          )}
          {allProducts.isFetchingNextPage && (
            <div className="grid grid-cols-2 -mt-8 max-w-[1100px] mx-auto gap-x-4 sm:gap-x-6 gap-6 lg:gap-10 md:grid-cols-3 lg:grid-cols-4">
              {[1, 2, 3, 4, 6, 7, 8].map((index: number) => (
                <div
                  className="bg-gray-100 h-[230px] rounded-md"
                  key={index}
                ></div>
              ))}
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default WebStoreAllProductPage;
