import React, { useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link, Outlet } from "react-router-dom";

const EstoreSetttings = () => {
  const [page, setPage] = useState(0);
  return (
    <div className="bg-gray-100">
      <div className="grid grid-cols-4 gap-4 h-[85vh] ">
        <div className="col-span-1 h-full">
          <Link
            to={"estore/products?type=items"}
            className="flex items-center gap-4 mb-4 cursor-pointer"
          >
            <MdOutlineKeyboardBackspace />
            <p className="text-sm">Back</p>
          </Link>
          <div className="space-y-4">
            <div className="bg-white p-3.5">
              <h1 className="text-sm font-semibold text-blue-600">Settings</h1>
              <div className="text-[13px] mt-3 space-y-4 flex flex-col">
                <Link
                  to="/estore/settings/security"
                  className="inline-flex items-center gap-2"
                >
                  <img
                    src="/media/svg/flash-circle.svg"
                    alt="no-image"
                    className="w-4"
                  />{" "}
                  Security
                </Link>
                <Link
                  to="/estore/settings/business-information"
                  className="inline-flex items-center gap-2"
                >
                  <img
                    src="/media/svg/briefcase.svg"
                    alt="no-image"
                    className="w-4"
                  />
                  Business information
                </Link>
                <Link
                  to="/estore/settings/delivery"
                  className="inline-flex items-center gap-2"
                >
                  {" "}
                  <img
                    src="/media/svg/box-tick.svg"
                    alt="no-image"
                    className="w-4"
                  />{" "}
                  Delivery
                </Link>
                <Link
                  to="/estore/settings/payment-information"
                  className="inline-flex items-center gap-2"
                >
                  {" "}
                  <img
                    src="/media/svg/money-2.svg"
                    alt="no-image"
                    className="w-4"
                  />
                  Payment information
                </Link>
                <Link
                  to="/estore/settings/orders-archive"
                  className="inline-flex items-center gap-2"
                >
                  {" "}
                  <img
                    src="/media/svg/archive-2.svg"
                    alt="no-image"
                    className="w-4"
                  />{" "}
                  Orders archive
                </Link>
              </div>
            </div>
            {/* <div className="bg-white p-3">
              <h1 className="text-sm font-semibold text-blue-600">
                E-Store management
              </h1>
              <div className="text-[13px] mt-3 space-y-1.5 flex flex-col">
                <Link to="/estore/settings/business-information">
                  Business information
                </Link>
                <Link to="/estore/settings/delivery">Delivery</Link>
                <Link to="/estore/settings/payment-information">
                  Payment information
                </Link>
              </div>
            </div>
            <div className="bg-white p-3">
              <h1 className="text-sm font-semibold text-blue-600">Analytics</h1>
              <div className="text-[13px] mt-3 space-y-1.5 flex flex-col">
                <Link to="/estore/settings/store-analysis">
                  Store analysis
                </Link>
                <Link to="/estore/settings/smart-pricing">
                  Smart pricing
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default EstoreSetttings;
