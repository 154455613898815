import { classNames } from "../../../../utils";

interface InputProps {
    name: string;
    label: string;
    type?: string;
    placeHolder: string;
    className?: any;
    formik?: any;
    disabled?: boolean;
}

const Input = ({ name, label, type = "text", placeHolder, className, formik, disabled= false} : InputProps) => {
    return(
        <>
            <label className="block mb-0.5 text-xs font-medium text-gray-900">{label}</label>
            <input name={name} type={type} placeholder={placeHolder} {...(formik ? formik.getFieldProps(name) : [])} disabled={disabled} className={classNames(className, "border border-gray-300 py-2 px-3 sm:px-5 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm")}/>
        </>
    )
};


export default Input;