// @ts-nocheck

import React, { Fragment, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { StoreModel } from "../../../../../models";
import { useAppSelector } from "../../../../../hooks";
import {
  deleteSingleMenu,
  downloadStoreMenuCategories,
} from "../../../../../api/Menu";
import { Menu, Transition } from "@headlessui/react";
import { IoMdMore } from "react-icons/io";
import Modal from "../../../../../components/Dashboard/Modal";
import { ImSpinner2, ImSpinner3 } from "react-icons/im";

const CategoriesType = () => {
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = `${store.name}`;
  let categories: any = [];
  const queryClient = useQueryClient();
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleCategories = useQuery<any, Error>({
    queryKey: ["getReviewTracker"],
    queryFn: async () => {
      const response = await downloadStoreMenuCategories(storeKey);
      return response.data;
    },
  });

  const deleteMenu = useMutation({
    mutationFn: async (data) => {
      const payload = {
        itemId: data.itemId,
      };
      return await deleteSingleMenu(payload);
    },
    onSuccess: () => {
      setSelectedObject({});
      queryClient.invalidateQueries({ queryKey: ["getMenuByCategory"] });
      queryClient.invalidateQueries({ queryKey: ["getStoreItems"] });
      setDeleteItemModal(false);
    },
  });

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        objectKey: data.objectKey,
      };
      return await delectS3Object(payload);
    },
  });

  if (handleCategories.data) {
    const obj = handleCategories.data?.Menu_Categories || {};
    const desiredArray = Object.entries(obj).map(([category, count]) => ({
      category: category.replace(/([A-Z])/g, (match) => ` ${match}`), // Capitalization fix
      // @ts-ignore
      count: count.toString(), // Ensure count is a string
    }));
    categories = desiredArray;
  }

  return (
    <>
      {/* <Modal
    show={deleteItemModal}
    onToggle={() => {
      setDeleteItemModal(false);
      setSelectedObject({});
    }}
  >
    <div className="relative flex flex-col p-6 md:py-12 md:px-16">
      <div className="mt-2 space-y-2 md:mt-2">
        <p className="font-semibold text-center text-gray-700" >
          You are about to delete this category
        </p>
      </div>
      <div className="flex flex-col justify-center md:flex-row gap-2 mt-5 md:mt-8">
        <div>
          <button
            disabled={deleteMenu.isLoading}
            className="px-4 py-2 text-xs font-semibold text-center text-white bg-blue-700 hover:bg-blue-800 border rounded-md w-full md:w-44 md:px-6 lg:px-8"
            onClick={(e) => {
              deleteMenu.mutate({
                itemId: selectedObject.menuItemId,
              });
              handleDeleteS3Object.mutate({
                objectKey: selectedObject.s3ImageUrl[0].imageIdentifier,
              });
              handleReduceItemCount.mutate();
            }}
          >
            {deleteMenu.isLoading ? (
              <ImSpinner2 size={24} className="mx-auto animate-spin" />
            ) : (
              "Delete"
            )}
          </button>
        </div>
        <div>
          <button
            type="button"
            className="px-4 py-2 text-xs font-semibold text-center text-blue-700 bg-white hover:bg-gray-50 border border-blue-700 rounded-md  w-full md:w-44 md:px-6 lg:px-8"
            onClick={() => {
              setSelectedObject({});
              setDeleteItemModal(false);
            }}
          >
            {"Cancel"}
          </button>
        </div>
      </div>
    </div>
  </Modal> */}

      <div className="">
        {handleCategories.status === "loading" ? (
          <div className="flex items-center justify-start py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : handleCategories.error ? (
          <p className="mt-8 text-sm font-semibold text-center text-gray-400">
            {handleCategorie.error.message}
          </p>
        ) : categories.length > 0 ? (
          <div className=" grid grid-cols-3 gap-y-4 gap-x-8">
            {categories.map((category: any, index: number) => (
              <div
                className="flex gap-2.5 items-center p-3.5 border border-gray-200 rounded"
                key={index}
              >
                <div className="hidden">
                  <img
                    src="https://images.pexels.com/photos/757432/pexels-photo-757432.jpeg"
                    alt=""
                    className="w-[50px] h-[50px] object-cover"
                  />
                </div>
                <div className="text-sm ">
                  <p className="font-semibold">{category.category}</p>
                  <p className="text-[13px]">No of items: {category.count}</p>
                </div>
                <div className="flex items-center gap-2 ml-auto">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="w-full text-blue-800 mt-1">
                        <IoMdMore />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-1 w-36 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-10">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-slate-100 font-semibold"
                                    : "text-gray-900 font-medium"
                                } w-full rounded-md px-4 py-2 text-xs text-left`}
                                // onClick={() => {
                                //   setSelectedObject(item);
                                //   setProductSidePanelStep(4);
                                // }}
                              >
                                Edit category
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-slate-100 font-semibold"
                                    : "text-gray-900 font-medium"
                                } w-full rounded-md px-4 py-2 text-xs text-left`}
                                onClick={() => {
                                  setSelectedObject(category);
                                  setDeleteItemModal(true);
                                }}
                              >
                                Delete category
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center mt-20" >
           <div>
           <img src="/media/image/empty-box-image.png" alt="empty-box" />
            <p className="mt-8 text-xs text-center text-gray-700">
            There are no categories yet!
            </p>
           </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CategoriesType;
