import HelpDeskHomeSummary from "./_components/HelpDeskHomeSummary";
import OrdersHomerSummary from "./_components/OrdersHomerSummary";
import ReviewsHomeSummary from "./_components/ReviewsHomeSummary";
import TaskManagerHomeSummary from "./_components/TaskManagerHomeSummary";

const HomeSummaryPage = () => {
  return (
    <div className="min-h-[calc(100vh-6.5rem)]">
      <p className="mb-4 font-semibold">Home</p>
      <div className="flex gap-4 h-full">
        <div className="h-full flex flex-col gap-4 w-[74%]">
          <div className="grid grid-cols-2 h-[220px] gap-4">
            <div className="h-full bg-gray-100 p-2.5 overflow-y-auto generalScrollbar">
              <OrdersHomerSummary />
            </div>
            <div className="h-full bg-gray-100 p-2.5 overflow-y-auto generalScrollbar">
              <HelpDeskHomeSummary />
            </div>
          </div>
          <div className="h-[340px] bg-gray-100 p-2.5 overflow-y-auto generalScrollbar">
            <TaskManagerHomeSummary />
          </div>
        </div>
        <ReviewsHomeSummary />
      </div>
    </div>
  );
};

export default HomeSummaryPage;
