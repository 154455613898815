// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Input } from "../../EchoWebpage1";
import { useFormik } from "formik";
import { ImSpinner2 } from "react-icons/im";
import { MdClear, MdOutlineKeyboardBackspace } from "react-icons/md";
import InputMessage from "../../../../../../../components/Auth/Forms/InputMessage";
import * as Yup from "yup";
import { useAppSelector } from "../../../../../../../hooks";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BASE_URL_WEBPAGE_IMAGE,
  getAllMenuCategories,
  uploadSingleMenu,
} from "../../../../../../../api/Menu";

import Compressor from "compressorjs";
import Modal from "../../../../../../../components/Dashboard/Modal";
import { classNames, getImage } from "../../../../../../../utils";
import { Link } from "react-router-dom";
import { AiOutlineCloudUpload, AiOutlinePlus } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { Combobox, Transition } from '@headlessui/react'
import { BiChevronDown } from "react-icons/bi";
import {  FiChevronRight } from "react-icons/fi";
import ItemOptions from "./ItemOptions";
import toast from "react-hot-toast";

const initialValues = {
  itemName: "",
  details: "",
  price: "",
  category: "",
  available_count: "",
};

const itemSchema = Yup.object().shape({
  itemName: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Item name is required"),
  details: Yup.string()
    .min(5, "Minimum 5 letters")
    .max(140, "Maximum 140 letters"),
  price: Yup.string().required("Price is required"),
  category: Yup.string(),
  available_count: Yup.number(),
});

const AddItemNew = ({
  setProductSidePanelStep,
  storeItemCount,
  setShowModals,
  showModals,
  allItems,
}) => {
  const [page, setPage] = useState(1);
  const [success, setSuccess] = useState(false);
  const [s3ImageUrl, setS3ImageUrl] = useState("");
  const [s3ImageUrl1, setS3ImageUrl1] = useState("");
  const [s3ImageUrl2, setS3ImageUrl2] = useState("");
  const [s3ImageUrl3, setS3ImageUrl3] = useState("");
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([])
  const [selected, setSelected] = useState({ id: "", name: "" })
  const [query, setQuery] = useState('')
  const [freeDelivery, setFreeDelivery] = useState("0")
  // const freeDeliveryStatus: string = useAppSelector((state) => state.user.store.freeDelivery);
  const [selectedForm, setSelectedForm] = useState("physical")
  const [imagesArray, setImagesArray] = useState([]);
  const [itemSizes, setItemSizes] = useState([])
  const [itemColors, setItemColors] = useState([])
 
  const allMenuCategories = useQuery<any, Error>({
    queryKey: ["getAllStoreCategories"],
    queryFn: async () => {
      const response = await getAllMenuCategories(storeKey);
      return response.data;
    },
    onSuccess: (data) => {
      if (data.Menu_Categories.length > 0) {
        const newCategories = data.Menu_Categories.map(cat => {
          return {
            id: cat.Category,
            name: cat.Category,
          }
        })
        const people = newCategories;
        setCategories(newCategories)
        if (!people.length > 0) {
          setSelected(people[0])
        }

      }
    },
  });


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: itemSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      setSubmitting(true);
      if (storeItemCount > 24) {
        setShowModal(true)
        return;
      }
      
      if (selected.name === "") {
        // alert("Please select a category.")
        alert("Category is required.")
        return;
      }

      if (selected.name.length < 3) {
        alert("Category must be at least 3 characters long.")
        return;
      }

      if (selected.name.length > 30) {
        alert("Category cannot exceed 30 characters.")
        return;
      }

      // if (values.available_count < (totalColorsCount + totalSizesCount)) {
      //   alert("Stock count cant be lesser than product attributes store count");
      //   return;
      // }
      
      // if (values.available_count < (totalColorsCount + totalSizesCount)) {
      //   alert("Stock count cant be lesser than product attributes store count");
      //   return;
      // }

      const itemImagePath =
        "/cusecho" +
        storeKey +
        "/products/" +
        selected.name.replace(/[^a-zA-Z0-9 ]/g, '') + "/" + uuidv4();

      if (imagesArray.length < 1) {
        alert("Default Image is compulsory")
        return;
      }

        if(s3ImageUrl === ""){
          if(s3ImageUrl1 !== ""){
            setS3ImageUrl(s3ImageUrl1);
            setS3ImageUrl1("");
          }
          else if(s3ImageUrl2 !== ""){
            setS3ImageUrl(s3ImageUrl2);
            setS3ImageUrl2("");
          }
          else if(s3ImageUrl3 !== ""){
            setS3ImageUrl(s3ImageUrl3);
            setS3ImageUrl3("");
          }
          else {
            alert('Please upload an image');
          }
        }

      const payload = {
        platformKey,
        slugUrl: storeKey,
        itemName: values.itemName.trim(),
        menuItemId: "None",
        s3ImageUrl: s3ImageUrl === "" ? itemImagePath : s3ImageUrl,
        s3ImageUrlOne: s3ImageUrl1 === "" ? "" : s3ImageUrl1,
        s3ImageUrlTwo: s3ImageUrl2 === "" ? "" : s3ImageUrl2,
        s3ImageUrlThree: s3ImageUrl3 === "" ? "" : s3ImageUrl3,
        itemPrice: values.price.toString(),
        description: values.details.trim(),
        itemCategory: selected.name.trim(),
        itemPriceDigit: values.price.toFixed(2),
        uploadTimeStamp: Date.now().toFixed(2),
        uploadTime: "None",
        itemUrl: "None",
        uploadFlag: "create",
        freeDelivery,
        itemSizes,
        itemColors,
      };

      // console.log(payload);

      try {
        const response = await uploadSingleMenu(payload);
        if (response.data.status_code === 200) {
          // const t = await handleUpdateStoreItemCount({slugUrl: storeKey, updateType: "write"})
          // console.log(t)
          queryClient.invalidateQueries(["getStoreItemCount"]);
          queryClient.invalidateQueries("getAllMenuCategories");
          queryClient.invalidateQueries("getMenuByCategory");
          setSubmitting(false);
          setPreview(null);
          setSuccess(true);
          resetForm({});
          setS3ImageUrl("");
          setS3ImageUrl1("");
          setS3ImageUrl2("");
          setS3ImageUrl3("");
          setImagesArray([])
          setItemSizes([]);
          setItemColors([]);
          setTimeout(() => {
            setSuccess(false)
          }, 3000)
          return;
        }
        setError(response.data.message);
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  const clearFields = () => {
    setPreview(null);
    formik.resetForm({});
    setS3ImageUrl("");
    setS3ImageUrl1("");
    setS3ImageUrl2("");
    setS3ImageUrl3("");
    setImagesArray([])
    setItemSizes([]);
    setItemsColor([]);
  }

  const imageHandler = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    setSelectedImage(e.target.files[0]);
  };

  const handleCompressedUpload = async (file: File) => {
    setIsLoading(true);
    if (selectedImage?.size > 2072576) {
      alert("Please select an image with a size of less than or equal to 2MB");
      setSelectedImage(null);
      setIsLoading(false);
      return;
    }
    if (selected.name === "") {
      alert("Fill the category field");
      setIsLoading(false);
      return;
    }
    new Compressor(file, {
      quality: 0.2,
      success: (compressedResult: any) => {
        let path;
        const category = selected.name.replace(/[^a-zA-Z0-9 ]/g, '');

        if (s3ImageUrl === "") {
          path = "/cusecho" +
            storeKey +
            "/products/" +
            category + "/" + uuidv4();
        } else if (s3ImageUrl1 === "") {
          path = "/cusecho" +
            storeKey +
            "/products/" +
            category + "/" + uuidv4();
        } else if (s3ImageUrl2 === "") {
          path = "/cusecho" +
            storeKey +
            "/products/" +
            category + "/" + uuidv4();
        } else {
          path = "/cusecho" +
            storeKey +
            "/products/" +
            category + "/" + uuidv4();
        }
        // https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/v1/signed_url_for_upload
        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };

        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log(data);
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then((res) => {
                // console.log(res)
                // console.log(path)
                // console.log(res.url + path)
                // // imagesArray.push(preview)
                setImagesArray([...imagesArray, preview])
                setImageUploadSuccess(true);
                setSelectedImage(null)
                setPreview(null);
                setTimeout(() => {
                  setImageUploadSuccess(false);
                }, 2000)
                setIsLoading(false);

                if (s3ImageUrl === "") {
                  setS3ImageUrl(path);
                  return;
                } else if (s3ImageUrl1 === "") {
                  setS3ImageUrl1(path);
                  return;
                } else if (s3ImageUrl2 === "") {
                  setS3ImageUrl2(path);
                  return;
                } else {
                  setS3ImageUrl3(path);
                  return;
                }

              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };



  useEffect(() => {
    if (!selectedImage) {
      setPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setPreview(objectUrl);
    // return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  // console.log(imagesArray)

  return (
    <>
      <Modal
        size="medium"
        show={showModal}
        onToggle={() => {
          setShowModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 ">
          <div className="text-center">
            <img
              src={getImage("connection-error")}
              alt="icon"
              className="w-24 mx-auto"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-lg">
              Maximum of 25 products allowed.
            </h1>
            <p>
              To increase this limit, contact our <Link to="/support/limit-increase" className="text-red-600 hover:text-red-800" >support team.</Link>
            </p>
          </div>
        </div>
      </Modal>

      {page === 1 ? (
        <div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2" >
              <MdOutlineKeyboardBackspace className="hidden cursor-pointer md:block" onClick={() => {
                setProductSidePanelStep(1);
              }} />
              <p className="text-lg font-semibold">Item</p>
            </div>
            {selectedForm === "physical" ?
              (<img src={getImage("PhysicalItemSwitch")} alt="" className="w-[40px] cursor-pointer hover:animate-spin"
                onClick={() => {
                  setSelectedForm("digital");
                  toast.success(`Switched to Digital Items mode`);
                  clearFields();
                }}
              />)
              :
              (<img src={getImage("DigitalItemSwitch")} alt="" className="w-[40px] cursor-pointer hover:animate-spin" onClick={() => {
                setSelectedForm("physical");
                toast.success(`Switched to Physical Items mode`);
                clearFields();
              }} />)
            }
          </div>

          {selectedForm === "physical" && (
            <form onSubmit={formik.handleSubmit} className="">
              <div className="space-y-3">
                <div className="w-full">
                  <Input
                    formik={formik}
                    name="itemName"
                    label="Item Name"
                    type="text"
                    placeHolder="Please enter item name"
                  />
                  {formik.errors.itemName && (
                    <InputMessage message={formik.errors.itemName} />
                  )}
                </div>
                <div className="w-full">
                  <label className="block mb-1 text-xs font-normal text-gray-800">
                    Details</label>
                  <textarea id="" name="details" value={formik.values.details} onChange={formik.handleChange} className="h-[100px] text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs" placeholder="Please enter details of item"></textarea>
                  {formik.errors.details && (
                    <InputMessage message={formik.errors.details} />
                  )}
                </div>
                <div className="w-full">
                  <Input
                    formik={formik}
                    name="price"
                    label="Price"
                    type="number"
                    placeHolder="Please enter price"
                  />
                  {formik.errors.price && (
                    <InputMessage message={formik.errors.price} />
                  )}
                </div>
                <div className="w-full">
                  <Input
                    formik={formik}
                    name="available_count"
                    label="Available Stock"
                    type="number"
                    placeHolder="Please enter available stock"
                  />
                  {formik.errors.available_count && (
                    <InputMessage message={formik.errors.available_count} />
                  )}
                </div>
                <div className="w-full">
                  <label className="block mb-1 text-xs font-normal text-gray-800">
                    Category
                  </label>
                  {!allMenuCategories.isLoading && (
                    <Combobox value={selected} onChange={setSelected}>
                      <div className="relative mt-1">
                        <div className="relative w-full overflow-hidden text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default sm:text-xs">
                          <Combobox.Input
                            className="w-full py-2 pl-3 pr-10 text-base leading-5 text-gray-900 outline-none"
                            displayValue={(person) => person.name}
                            onChange={(event) => setQuery(event.target.value)}
                          />
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <BiChevronDown
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Combobox.Button>
                        </div>
                        <Transition
                          // as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => {
                            if (selected.name !== query) {
                              setQuery("")
                            }
                            if (query !== "") {
                              setSelected({ id: query, name: query },)
                            }
                            // setQuery(query);
                          }}
                        >
                          {
                            categories.length > 0 && (
                              <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-xs" >
                                {
                                  categories.map((person) => (
                                    <Combobox.Option
                                      key={person.id}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                        }`
                                      }
                                      value={person}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {person.name}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-600'
                                                }`}
                                            >
                                              {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))
                                }

                              </Combobox.Options>
                            )
                          }
                        </Transition>
                      </div>
                    </Combobox>
                  )}
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-800">
                    Image
                  </label>
                  <div className="flex items-center gap-2 justify-evenly">
                    {imagesArray.map((imagePreview: string, index: number) => (
                      <div className="w-full h-[80px] relative" key={index} >
                        <img
                          src={imagePreview}
                          alt=""
                          className="object-contain w-full h-full"
                        />
                        <div className={classNames("absolute z-10 inset-0 m-auto font-semibold rounded-full bg-red-400 hover:bg-red-600 w-6 h-6 flex items-center justify-center cursor-pointer", selectedImage ? "hidden" : "")} onClick={() => {
                          if (index === 3) {
                            setS3ImageUrl3("")
                            // const newArray = [imagesIdentifierArray[0], imagesIdentifierArray[1], imagesIdentifierArray[2], ""];
                            // setImagesIdentifierArray(newArray)
                          }
                          if (index === 2) {
                            setS3ImageUrl2("")
                            // const newArray = [imagesIdentifierArray[0], imagesIdentifierArray[1], "" , imagesIdentifierArray[3]];
                            // setImagesIdentifierArray(newArray)
                          }
                          if (index === 1) {
                            setS3ImageUrl1("")
                            // const newArray = [imagesIdentifierArray[0], "", imagesIdentifierArray[2], imagesIdentifierArray[3]];
                            // setImagesIdentifierArray(newArray)
                            // imagesIdentifierArray[1] = "";
                          }
                          if (index === 0) {
                            setS3ImageUrl("")
                          }
                          setImagesArray(prevState => prevState.filter((i) => i !== imagePreview));
                          
                        }} ><span className="text-[13px] text-white"><MdClear size={22} /></span></div>
                      </div>
                    ))}
                    {!selectedImage || !preview ? (
                      <div className={classNames("w-full", imagesArray.length > 3 ? "hidden" : "")}>
                        <label
                          htmlFor="dropzone-file"
                          className="w-[60px] h-[60px] rounded-full bg-gray-200 flex items-center justify-center cursor-pointer"
                        >
                          <AiOutlinePlus size={28} />
                          <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            accept="image/jpeg, image/png, image/jpg"
                            onChange={imageHandler}
                          />
                        </label>
                      </div>
                    ) : null}
                  </div>
                  {!selectedImage || !preview ? null : (<div className="flex items-center gap-4 mt-2">
                    <img
                      src={preview}
                      alt=""
                      className="w-[50%] h-[80px] object-contain"
                    />
                    <div>
                      <div className="flex flex-col items-center gap-2">
                        <button
                          type="button"
                          className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-red-700 bg-transparent border border-red-700 rounded-md"
                          onClick={(e) => {
                            setSelectedImage(null);
                            setPreview(null);
                            // setS3ImageUrl("");
                            setImageUploadSuccess(false);
                          }}
                        >
                          <span>Cancel</span>
                          <span>
                            <MdClear />
                          </span>
                        </button>
                        <button
                          // disabled={!selectedImage || selectedImage === null}
                          type="button"
                          className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-white border rounded-md bg-blue-700 hover:bg-blue-800 border-primary"
                          onClick={() => {
                            handleCompressedUpload(selectedImage)
                          }}
                        >
                          <span>Upload</span>{" "}
                          {isLoading ? (
                            <ImSpinner2
                              size={20}
                              className="mx-auto animate-spin"
                            />
                          ) : (
                            <AiOutlineCloudUpload />
                          )}
                        </button>
                      </div>
                      {imageUploadSuccess && (
                        <p className="mt-2 text-xs text-center text-green-500">
                          Upload successful
                        </p>
                      )}
                    </div>
                  </div>)}

                </div>
                <div className="flex items-center justify-between gap-2 " >
                  <div className="text-xs">
                    <p className="font-semibold text-gray-800" >Item option</p>
                    <p className="font-normal text-gray-400" >Add item sizes and colours</p>
                  </div>
                  <FiChevronRight size={22} className="cursor-pointer" onClick={() => {
                    setPage(2)
                  }} />
                </div>
                <div className={classNames("mb-1.5 flex items-center")}>
                  <input
                    checked={Number(freeDelivery)}
                    onChange={(e) => {
                      if (Number(freeDelivery) === 0) {
                        setFreeDelivery("1");
                        return;
                      }

                      if (Number(freeDelivery) === 1) {
                        setFreeDelivery("0");
                        return;
                      }

                    }}
                    type="checkbox"
                    value="delivery"
                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  // disabled={(freeDeliveryStatus !== "1")}
                  />
                  <label
                    htmlFor="checked-checkbox"
                    className="ml-2 text-xs font-semibold text-gray-900"
                  >
                    Free delivery <span className="font-normal text-gray-400">{"(Click to enable free delivery for selected store products)"}</span>
                  </label>
                </div>
              </div>
              <div className="flex items-center justify-center mt-4">
                <button
                  type="submit"
                  // disabled={formik.isSubmitting}
                  className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
                >
                  {formik.isSubmitting ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Save Item"
                  )}
                </button>
              </div>
              <p className="mt-1 text-center text-green-500 capitalize">
                {success && <small>Saved successfully!</small>}
              </p>
            </form >
          )}

          {selectedForm === "digital" && (
            <form onSubmit={formik.handleSubmit} className="">
              <div className="space-y-3">
                <div className="w-full">
                  <Input
                    formik={formik}
                    name="itemName"
                    label="Item Name"
                    type="text"
                    placeHolder="Please enter item name"
                  />
                  {formik.errors.itemName && (
                    <InputMessage message={formik.errors.itemName} />
                  )}
                </div>
                <div className="w-full">
                  <label className="block mb-1 text-xs font-normal text-gray-800">
                    Details</label>
                  <textarea id="" name="details" value={formik.values.details} onChange={formik.handleChange} className="h-[100px] text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs" placeholder="Please enter details of item"></textarea>
                  {formik.errors.details && (
                    <InputMessage message={formik.errors.details} />
                  )}
                </div>
                <div className="w-full">
                  <Input
                    formik={formik}
                    name="price"
                    label="Price"
                    type="number"
                    placeHolder="Please enter price"
                  />
                  {formik.errors.price && (
                    <InputMessage message={formik.errors.price} />
                  )}
                </div>
                <div className="w-full">
                  <label className="block mb-1 text-xs font-normal text-gray-800">
                    Category
                  </label>
                  {!allMenuCategories.isLoading && (
                    <Combobox value={selected} onChange={setSelected}>
                      <div className="relative mt-1">
                        <div className="relative w-full overflow-hidden text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default sm:text-xs">
                          <Combobox.Input
                            className="w-full py-2 pl-3 pr-10 text-base leading-5 text-gray-900 outline-none"
                            displayValue={(person) => person.name}
                            onChange={(event) => setQuery(event.target.value)}
                          />
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <BiChevronDown
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Combobox.Button>
                        </div>
                        <Transition
                          // as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => {
                            if (selected.name !== query) {
                              setQuery("")
                            }
                            if (query !== "") {
                              setSelected({ id: query, name: query },)
                            }
                            // setQuery(query);
                          }}
                        >
                          {
                            categories.length > 0 && (
                              <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-xs" >
                                {
                                  categories.map((person) => (
                                    <Combobox.Option
                                      key={person.id}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                        }`
                                      }
                                      value={person}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {person.name}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-600'
                                                }`}
                                            >
                                              {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))
                                }

                              </Combobox.Options>
                            )
                          }
                        </Transition>
                      </div>
                    </Combobox>
                  )}
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-800">
                    Image
                  </label>
                  {!selectedImage || !preview ? (
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full border border-gray-300 rounded-md cursor-pointer h-28 bg-gray-white "
                      >
                        <div className="flex flex-col items-center justify-center py-5">
                          <p className="mb-2 text-xs text-center text-gray-500">
                            <span className="font-semibold">
                              Click to select an image
                            </span>
                          </p>
                          <p className="text-xs text-gray-500" >
                            PNG or JPG
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          accept="image/jpeg, image/png, image/jpg"
                          onChange={imageHandler}
                        />
                      </label>
                    </div>
                  ) : (
                    <div className="flex items-center gap-4">
                      <img
                        src={preview}
                        alt=""
                        className="w-[50%] h-28 object-contain shadow-sm"
                      />
                      <div>
                        <div className="flex flex-col items-center gap-2">
                          <button
                            type="button"
                            className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-red-700 bg-transparent border border-red-700 rounded-md"
                            onClick={(e) => {
                              setSelectedImage(null);
                              setPreview(null);
                              setS3ImageUrl("");
                              setImageUploadSuccess(false);
                            }}
                          >
                            <span>Cancel</span>
                            <span>
                              <MdClear />
                            </span>
                          </button>
                          <button
                            // disabled={!selectedImage || selectedImage === null}
                            type="button"
                            className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-white border rounded-md bg-blue-700 hover:bg-blue-800 border-primary"
                            onClick={() => {
                              handleCompressedUpload(selectedImage)
                            }}
                          >
                            <span>Upload</span>{" "}
                            {isLoading ? (
                              <ImSpinner2
                                size={20}
                                className="mx-auto animate-spin"
                              />
                            ) : (
                              <AiOutlineCloudUpload />
                            )}
                          </button>
                        </div>
                        {imageUploadSuccess && (
                          <p className="mt-2 text-xs text-center text-green-500">
                            Upload successful
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center mt-4">
                <button
                  type="submit"
                  disabled
                  className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
                >
                  {formik.isSubmitting ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Save Item"
                  )}
                </button>
              </div>
              <p className="mt-1 text-center text-green-500 capitalize">
                {success && <small>Saved successfully!</small>}
              </p>
            </form >
          )}

        </div >
      ) : (
        <ItemOptions setPage={setPage} itemColors={itemColors} setItemColors={setItemColors} setItemSizes={setItemSizes} itemSizes={itemSizes} />
      )}

    </>
  );
};

export default AddItemNew;
