import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { base64Decode, formatStoreName } from "../../../utils";
import { ImSpinner8 } from "react-icons/im";
import { useQuery } from "@tanstack/react-query";
import { getMenuByCategory, getSiteTitleAndTagLine } from "../../../api/Menu";
import { BsArrowLeft } from "react-icons/bs";
import ProductCard from "../../../components/WebStore/Products/ProductCard";
import Layout from "../../../components/WebStore/Layout";
import { IStoreInfoResponse, StoreItem } from "../../../models";
import CategoriesLayout from "../../../components/WebStore/CategoriesLayout";

const WebStoreProductPage = () => {
  const { salt, slug, category } = useParams();
  const queryKey = `/${salt}/${slug}`;
  let products: StoreItem[] = [];
  const selectedCategory = base64Decode(`${category}`);

  const allMenuByCategory = useQuery<any, Error>({
    queryKey: ["getMenuByCategory", selectedCategory],
    queryFn: async () => {
      const response = await getMenuByCategory(queryKey, selectedCategory);
      return response.data;
    },
    enabled: !!selectedCategory,
  });

  if (allMenuByCategory.data) {
    products = allMenuByCategory.data.Menu_Response;
  }

  return (
    <Layout>
      <section className="bg-white">
        <div className="container px-4 pb-28 md:pb-14 pt-14 md:pt-14 md:px-14">
          <CategoriesLayout />
          <div className="grid grid-cols-2 max-w-[1100px] mx-auto gap-x-4 sm:gap-x-6 gap-6 lg:gap-10 md:grid-cols-3 lg:grid-cols-4">
            {allMenuByCategory.status === "loading" ? (
              [1, 2, 3, 4, 5, 6, 7, 8].map((index: number) => (
                <div className="bg-gray-100 h-[230px] rounded-md" key={index}></div>
              ))
            ) : allMenuByCategory.status === "error" ? (
              <div className="flex items-start justify-start h-screen py-5">
                Error: {allMenuByCategory.error.message}
              </div>
            ) : products.length > 0 ? (
              products.map((product: StoreItem, index: number) => {
                return <ProductCard key={index} product={product} />;
              })
            ) : (
              <div className="text-sm text-center text-gray-600">
                No products available
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default WebStoreProductPage;
