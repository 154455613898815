import { getSvg } from "../../../utils";

const NoData = () => {
    return(
        <div className="flex items-center justify-center h-[80vh] bg-white rounded-md shadow-md">
            <div className="">
                <p className="text-2xl text-gray-400">Coming Soon</p>
            </div>
        </div>
    );
};

export default NoData;