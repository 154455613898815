import React, { useState } from "react";
import {
  MdOutlineKeyboardBackspace,
  MdOutlineEdit,
  MdOutlineDelete,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { BsFillCircleFill } from "react-icons/bs";
import { classNames } from "../../../utils";
import { getActiveKPIs } from "../../../api/KPIs";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../hooks";
import { UserProfileModel } from "../../../models";
import SkeletonLoader from "../../../components/Dashboard/SkeletonLoader";

const KPIReviews = () => {
  const navigate = useNavigate();
  const { id: metricNo } = useParams();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = `${user.queryKey}`;
  const platformKey = queryKey.replace("%26", "&");
  const queryClient = useQueryClient();
  const [filterSimilarityScore, setFilterSimilarityScore] = useState("NONE");
  const [filterReviewType, setFilterReviewType] = useState("NONE");
  const [startDateTimeStamp, setStartDateTimeStamp] = useState("0.000");
  const [endDateTimeStamp, setEndDateTimeStamp] = useState(
    `${new Date(Date.now()).getTime() / 1000}`
  );

  let kpi_load_items: any[] = [];
  let kpiStats: any[] = [];
  let metricName = "";

  const { error, status, data } = useInfiniteQuery<any, Error>({
    queryKey: ["getActiveKPIs", metricNo],
    queryFn: async ({ pageParam = "" }) => {
      const data = {
        // tagName: "Success_Metric_" + kpiTabs[activeTab - 1],
        tagName: `Success_Metric_${metricNo}`,
        platformKey,
        timestamp: [startDateTimeStamp, endDateTimeStamp],
        similarityScore: filterSimilarityScore,
        labelTag: filterReviewType,
        pagination_key: pageParam,
      };
      // console.log(data)
      const response = await getActiveKPIs(data);
      return response.data;
    },
    onSuccess: (data) => {
      console.log(data);
    },
    getNextPageParam: (lastPage, pages) => {
      // console.log(lastPage)
      if (
        lastPage.LastEvaluatedKey === "" ||
        lastPage.kpi_load.Items.length < 1
      ) {
        return false;
      }
      return lastPage.LastEvaluatedKey;
    },
  });

  if (status === "loading")
    return <SkeletonLoader className="rounded-md h-[85vh]" />;

  if (status === "error") return <span>Error: {error.message}</span>;

  if (data) {
    kpiStats = data.pages[0].kpi_stats;
    data.pages.forEach((page: any) => {
      kpi_load_items = [...kpi_load_items, ...page.kpi_load.Items];
    });
    // console.log(firstId);
    // metricName = firstId.find((x: any) => x.tag_name === `Success_Metric_${metricNo}`).metric;
    metricName = "Car park";
  }

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <div
          className="flex items-center gap-1.5 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <MdOutlineKeyboardBackspace />
          {/* <p className="text-sm font-semibold text-gray-800">Back</p> */}
          <h4 className="font-semibold text-sm">
            Related reviews for {`"${metricName}"`}
          </h4>
        </div>
        <div className="flex items-center justify-center space-x-3 hidden">
          <button
            className="px-3 py-1.5 text-black border border-[#999999] rounded-md text-sm font-normal"
            onClick={() => {}}
          >
            <MdOutlineEdit
              size={16}
              className="inline-flex mb-1 mr-1.5 fill-[#999999]"
            />
            <span className="">Edit</span>
          </button>
          <button
            className="px-3 py-1.5 text-black border border-[#999999] rounded-md text-sm font-normal"
            onClick={() => {}}
          >
            <MdOutlineDelete
              size={18}
              className="inline-flex mb-1 mr-1.5 fill-[#999999]"
            />
            <span>Delete</span>
          </button>
        </div>
      </div>
      {kpi_load_items.length > 0 && (
        <div className="max-w-5xl space-y-3">
          {kpi_load_items.map((comment: any, index: number) => {
            const similarity_score: string = comment.similarityScore;
            const source = comment.commentSource;
            const sentiment_tag: string = comment.sentimentTag;

            return (
              <div className="space-y-0.5" key={index}>
                <div className="flex items-center space-x-1.5">
                  <h5 className="text-sm font-semibold text-gray-800">
                    {comment.customer}
                  </h5>
                  <BsFillCircleFill
                    size={11}
                    className={classNames(
                      sentiment_tag === "NEG"
                        ? "fill-red-600"
                        : sentiment_tag === "POS"
                        ? "fill-green-700"
                        : "fill-orange-500"
                    )}
                  />
                </div>
                <p className="text-[13px] text-gray-700">
                  {comment.userReview}
                </p>
              </div>
            );
          })}
        </div>
      )}
      {kpi_load_items.length === 0 && (
        <div className="flex flex-col items-center justify-center py-5 h-[75vh]">
          <p className="text-lg text-gray-400">No reviews yet</p>
        </div>
      )}
    </div>
  );
};

export default KPIReviews;
