// @ts-nocheck

import { API_BASE_URL, classNames, getSvg } from "../../../../utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserPool from "../../../../setup/userpool";
import InputMessage from "../../../../components/Auth/Forms/InputMessage";
import { useState, useEffect } from "react";
import Alert from "../../../../components/Auth/Forms/Alert/indext";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import { StoreModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { SET_USER } from "../../../../redux/User";
import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Modal from "../../../../components/Dashboard/Modal";
import { ImSpinner2 } from "react-icons/im";
import { FaUserEdit } from "react-icons/fa";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeHolder: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
  readOnly?: boolean;
}

const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
  readOnly = false,
}: InputProps) => {
  return (
    <div className="w-full">
      <label className="block mb-0.5 text-xs font-normal text-gray-500">
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        readOnly={readOnly}
        disabled={disabled}
        className={classNames(
          className,
          "border border-gray-300 py-2 px-2 rounded w-full outline-none text-gray-900 placeholder:text-[13px] sm:text-sm",
          readOnly ? "bg-gray-50" : "bg-white"
        )}
      />
    </div>
  );
};

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const general: UserProfileModel = useAppSelector((state) => state.user);
  const store: StoreModel = useAppSelector((state) => state.user.store);

  let str;

  str = store.name.split("/");
  var newstore = str[str.length - 1];
  newstore = newstore.replaceAll("-", " ");
  var newStore1 = newstore.split(" ");

  for (var i = 0; i < newStore1.length; i++) {
    newStore1[i] = newStore1[i].charAt(0).toUpperCase() + newStore1[i].slice(1);
  }
  const newStore2 = newStore1.join(" ");

  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [preview, setPreview] = useState();
  const [showModal, setShowModal] = useState(false);
  const getImageURLEndpoint =
    "https://t2kqqkr425.execute-api.eu-west-2.amazonaws.com/dev/v1/signed_url_for_download?s3ObjectKey=customerEcho";

  let profileImageUrl: string = "/media/image/avatar.png";

  const initialValues1 = {
    fullName: user.fullName,
    country: store.country ?? "",
    employeeNumber: store.employeeNumber ?? "",
    phoneNumber: store.phoneNumber === "N/A" ? "" : store.phoneNumber ?? "",
    storeName: store.name === "N/A" ? "" : newStore2 ?? "",
    address: store.address === "N/A" ? "" : store.address ?? "",
  };

  const completeProfile1Schema = Yup.object().shape({
    // fullName: Yup.string().max(40).required(),
    country: Yup.string(),
    employeeNumber: Yup.number(),
    phoneNumber: Yup.string()
      .matches(
        phoneRegExp,
        "Phone number is not valid. Please use this format (e.g, +234)"
      )
      .required("Phone number is required"),
    storeName: Yup.string().max(40).required("Store name is required"),
    address: Yup.string().max(100).required("Address is required"),
  });

  const formik = useFormik({
    initialValues: initialValues1,
    // validationSchema: completeProfile1Schema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setError("");
      await new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              reject();
            } else {
              const attributes = await new Promise((resolve, reject) => {
                user.getUserAttributes((err, attributes) => {
                  if (err) {
                    reject(err);
                  } else {
                    const results = {};

                    for (let attribute of attributes) {
                      const { Name, Value } = attribute;
                      results[Name] = Value;
                    }

                    resolve(results);
                  }
                });
              });

              resolve({
                user,
                ...session,
                ...attributes,
              });
            }
          });
        } else {
          reject();
        }
      }).then(({ user }) => {
        // console.log(user);
        const attributes = [
          new CognitoUserAttribute({ Name: "address", Value: values.address }),
          new CognitoUserAttribute({
            Name: "locale",
            Value: values.country,
          }),
          // new CognitoUserAttribute({
          //   Name: "name",
          //   Value: values.firstName,
          // }),
          new CognitoUserAttribute({
            Name: "phone_number",
            Value: values.phoneNumber,
          }),

          new CognitoUserAttribute({
            Name: "given_name",
            Value: values.storeName,
          }),

          new CognitoUserAttribute({
            Name: "custom:employee",
            Value: values.employeeNumber.toString(),
          }),
        ];

        const userData: UserProfileModel = {
          ...user,
          // firstName: values.firstName,
          lastName: values.lastName,
          address: values.address,
          country: values.country,
        };

        const storeData: StoreModel = {
          name: values.storeName,
          address: values.address,
          country: values.country,
          currency: store.currency,
          phoneNumber:
            values.phoneNumber === "N/A" ? "N/A" : values.phoneNumber,
          employeeNumber: values.employeeNumber,
        };

        user.updateAttributes(attributes, (err, result) => {
          if (err) {
            // console.error(err);
            setError(err.message);
            setSubmitting(false);
          } else {
            setSubmitting(false);
            setError("");
            // console.log(result);
            // console.log("successful");

            dispatch(SET_USER({ ...general, store: storeData }));
            setShowModal(true);
          }
        });
      });
    },
  });

  const imageHandler = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const handleUpload = async (file) => {
    if (!file) {
      alert("Choose a file first");
      return;
    }
    setIsLoading(true);
    const path = "customerEcho/" + user.platformAccountId;
    const token = localStorage.getItem("imageToken");
    const headers = { Authorization: `Bearer ${token}` };

    fetch(`${API_BASE_URL}/signed_url_for_upload?profileImageId=${path}`, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        const { fields, url } =
          data.CustomersEchoProfileImageId[0].Image_Upload_Url;
        const formData = new FormData();
        formData.append("key", fields.key);
        formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
        formData.append("x-amz-credential", fields["x-amz-credential"]);
        formData.append("x-amz-date", fields["x-amz-date"]);
        formData.append("x-amz-security-token", fields["x-amz-security-token"]);
        formData.append("policy", fields.policy);
        formData.append("x-amz-signature", fields["x-amz-signature"]);
        formData.append("file", file);
        fetch(url, {
          method: "post",
          body: formData,
        }).then(() => {
          queryClient.invalidateQueries("getprofileImageUrl");
          setSelectedImage(null);
          setPreview(null);
          setIsLoading(false);
          setStep(1);
        }).catch((err) => {
          console.log(err)
          setIsLoading(false);
        })
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const uploadImage = useMutation({
    mutationFn: async (data) => {
      return await handleUpload(data);
    },
  });

  useEffect(() => {
    setError("");
    setSelectedImage(null);
    setPreview(null);
  }, [step]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedImage) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const profileImage = useQuery<any, Error>({
    queryKey: ["getprofileImageUrl"],
    queryFn: async () => {
      const token = localStorage.getItem("imageToken");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        getImageURLEndpoint +
          `/${user.platformAccountId}`,
        { headers }
      );
      return response.json();
    },
    onSuccess: (data) => {},
  });

  if (profileImage.data) {
    if (profileImage.data[0]?.Image_Upload_Url) {
      profileImageUrl = profileImage.data[0].Image_Upload_Url;
    } else {
      profileImageUrl = "/media/image/avatar.png";
    }
  }

  return (
    <>
      <Modal
        size="medium"
        show={showModal}
        onToggle={() => {
          setShowModal(false);
          setStep(1);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img src={getSvg("check")} alt="icon" className="w-24 mx-auto" />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-2xl">
              Successfully updated profile
            </h1>
          </div>
        </div>
      </Modal>
      {step === 1 ? (
        <div className="relative h-full ">
          <div className="absolute right-0 top-4 md:-top-12 hidden lg:block">
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-[13px] px-4 py-2 "
              onClick={() => setStep(2)}
            >
              Edit Profile
            </button>
          </div>
          <div className="absolute right-0 top-4 lg:hidden text-blue-600">
            <FaUserEdit
              size={22}
              className="cursor-pointer"
              onClick={() => setStep(2)}
            />
          </div>
          <div className="max-w-[800px] flex">
            <div className="w-full">
              <div className="">
                <img
                  className="w-16 h-16 rounded-full mb-1 border-2"
                  src={profileImageUrl ?? "/media/image/avatar.png"}
                  onError={(e) =>
                    (e.currentTarget.src = "/media/image/avatar.png")
                  }
                  alt="Rounded avatar"
                />
                <p className="text-xs text-gray-500 mt-2 mb-5">
                  Profile Picture
                </p>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:gap-x-8 gap-y-4 mb-4 w-full ">
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Full Name
                  </label>
                  <p className="text-sm">{user.fullName}</p>
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Store Name
                  </label>
                  <p className="text-sm">{newStore2}</p>
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Phone Number
                  </label>
                  <p className="text-sm">{store.phoneNumber}</p>
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Email
                  </label>
                  <p className="text-sm">{user.email}</p>
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Address
                  </label>
                  <p className="text-sm">{store.address}</p>
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Country of Residence
                  </label>
                  <p className="text-sm">{store.country}</p>
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Employee Number
                  </label>
                  <p className="text-sm">{store.employeeNumber}</p>
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Currency
                  </label>
                  <p className="text-sm">{store.currency}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative h-full ">
          <div className="max-w-[800px]">
            <label htmlFor="fileUpload" className="flex gap-4 w-16">
              {selectedImage ? (
                <img
                  className="w-16 h-16 rounded-full mb-1 border-2 cursor-pointer"
                  src={preview}
                  alt="Rounded avatar"
                  onError={(e) =>
                    (e.currentTarget.src =
                      "/media/image/avatar.png")
                    }
                />
              ) : profileImageUrl !== "" && !selectedImage ? (
                <img
                  className="w-16 h-16 rounded-full mb-1 border-2 cursor-pointer"
                  src={profileImageUrl}
                  alt="Rounded avatar"
                  onError={(e) =>
                    (e.currentTarget.src =
                      "/media/image/avatar.png")
                    }
                />
              ) : (
                <img
                  className="w-16 h-16 rounded-full mb-1 border-2 cursor-pointer"
                  src="/media/image/avatar.png"
                  alt="Rounded avatar"
                  onError={(e) =>
                    (e.currentTarget.src =
                      "/media/image/avatar.png")
                    }
                />
              )}
            </label>
            <p className="text-xs font-medium text-gray-600 mb-1">
              Click on the image above to choose a file
            </p>
            <>
              <input
                id="fileUpload"
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={imageHandler}
                required
                className="hidden"
              />
              <button
                onClick={() => uploadImage.mutate(selectedImage)}
                disabled={uploadImage.isLoading || isLoading}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold text-[13px] px-4 py-2 rounded w-44"
              >
                {uploadImage.isLoading || isLoading ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Upload Image"
                )}
              </button>
            </>

            <p className="text-xs text-gray-500 mt-3 mb-5 hidden">
              Select an avatar to personalise your account
            </p>
          </div>
          <div className="max-w-[800px] mt-4">
            <form className="w-full" onSubmit={formik.handleSubmit}>
              {error && <Alert type="error" message={error} />}
              <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 md:gap-x-8 gap-y-4 mb-6">
                <div>
                  <Input
                    formik={formik}
                    name="fullName"
                    label="Full Name"
                    type="text"
                    placeHolder=""
                    readOnly
                  />
                  {formik.values.fullName && formik.errors.fullName && (
                    <InputMessage message={formik.errors.fullName} />
                  )}
                </div>
                <div className="">
                  <Input
                    formik={formik}
                    name="storeName"
                    label="Name of Store"
                    type="text"
                    placeHolder="Please enter store name"
                    readOnly
                  />
                  {formik.values.storeName && formik.errors.storeName && (
                    <InputMessage message={formik.errors.storeName} />
                  )}
                </div>
                <div className="">
                  <Input
                    formik={formik}
                    name="phoneNumber"
                    label="Phone number"
                    type="text"
                    placeHolder="Phone number must start with a plus (+) sign"
                  />
                  {formik.values.phoneNumber && formik.errors.phoneNumber && (
                    <InputMessage message={formik.errors.phoneNumber} />
                  )}
                </div>
                <div className="">
                  <Input
                    formik={formik}
                    name="address"
                    label="Your address"
                    type="text"
                    placeHolder="Please enter address"
                  />
                  {formik.values.address && formik.errors.address && (
                    <InputMessage message={formik.errors.address} />
                  )}
                </div>
                <div>
                  <Input
                    formik={formik}
                    name="country"
                    label="Country"
                    type="text"
                    placeHolder="Please enter country"
                  />
                  {formik.values.country && formik.errors.country && (
                    <InputMessage message={formik.errors.country} />
                  )}
                </div>
                <div>
                  <Input
                    formik={formik}
                    name="employeeNumber"
                    label="Number of employees"
                    type="number"
                    placeHolder="Please enter employee number"
                  />
                  {formik.values.employeeNumber &&
                    formik.errors.employeeNumber && (
                      <InputMessage message={formik.errors.employeeNumber} />
                    )}
                </div>
              </div>
              <div className="flex items-center justify-center sm:block sm:w-[200px]">
                <button
                  type="submit"
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white text-[13px] text-semibold text-center px-4 py-2 rounded-md"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
          <div className="absolute right-0 top-4 md:-top-12 hidden lg:block">
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-[13px] px-8 py-2"
              onClick={() => setStep(1)}
            >
              <span className="mr-2">&larr;</span> Back
            </button>
          </div>
          <div className="absolute right-0 top-4 sm:-top-10 lg:hidden text-blue-600">
            {/* <FaUserEdit size={22} className="cursor-pointer" onClick={() => setStep(1)} /> */}
            <button
              type="button"
              className="font-medium"
              onClick={() => setStep(1)}
            >
              <span className="mr-2">&larr;</span> Back
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileSettings;
