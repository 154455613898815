import { useEffect, useRef, useState, Fragment } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { classNames } from "../../../../utils";
import { BsCircleFill } from "react-icons/bs";
import { ChartDataInterval } from "../../../../enums";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { filterRealtimePerceptionScores, getRealtimePerceptionScores } from "../../../../api/Utils";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { UserProfileModel } from "../../../../models";
import SkeletonLoader from "../../SkeletonLoader";
import { IoFilterSharp } from "react-icons/io5";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { Popover, Transition } from "@headlessui/react";
import { ImSpinner2 } from "react-icons/im";
import { SET_BPT_SCORES } from "../../../../redux/Utils";
import { IoMdInformationCircleOutline } from "react-icons/io";



const BusinessPerceptionTracker = () => {
  const [interval, setInterval] = useState<ChartDataInterval>(ChartDataInterval.Monthly);
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  const [startDateTimeStamp, setStartDateTimeStamp] = useState("0.000");
  const [endDateTimeStamp, setEndDateTimeStamp] = useState(`${(new Date(Date.now()).getTime() / 1000)}`);
  const queryclient = useQueryClient();
  const [filter, setFilter] = useState(0);
  const scores: number[] = useAppSelector((state) => state.utils.bpt_scores);
  const dispatch = useAppDispatch();
  const [tempScores, setTempScores] = useState<number[]>([]);

  const toggleChartData = (value: ChartDataInterval) => {
    setInterval(value);
  };

  const handleClearFilter = (e: any) => {
    e.preventDefault();
    setFilter(0);
    setStartDateTimeStamp("");
    setEndDateTimeStamp("");
    if(filter === 1) {
      dispatch(SET_BPT_SCORES(tempScores));
      queryclient.refetchQueries(["getRealtimePerceptionScores"]);
    }
  };

  const { status, data, error, isRefetching, isFetching } = useQuery<any, Error>({
    queryKey: ["getRealtimePerceptionScores"],
    initialData: scores,
    queryFn: async () => {
      const payload = {
        platformKey: queryKey,
        // timestampData: [startDateTimeStamp, endDateTimeStamp],
      };
      
      const response = await getRealtimePerceptionScores(payload);
      return response.data;
    },
    select: (data) => {
      if ("Items" in data) {
        if (data.Items.length > 0) {
          return {
            scores: data.Items.map((item: any, i: number) => { return item.perceptionScore?.toFixed(0); }),
            timestamps: data.Items.map((item: any, i: number) => { return item.uploadTime; }),
          }
        }
      }

      if ("bpt_index" in data) {
        // const prevScores = queryclient.getQueryData<any>(["getRealtimePerceptionScores"]);
        
        return {
          // scores: [data.bpt_index.perceptionScore?.toFixed(0), ...scores],
          scores: data.bpt_index.scoreProjections.map((item: any, i: number) => { return item.perceptionScore?.toFixed(0); }),
        }
      }

      return { scores: [], timestamps: [] };
    },
    onSuccess: (data) => {
        dispatch(SET_BPT_SCORES(data.scores));
    },
  }); 

  console.log(data);

  // const filteredScores = useQuery<any, Error>({
  //   queryKey: ["filterRealtimePerceptionScores", filter],
  //   queryFn: async () => {
  //     const payload = {
  //       platformKey: queryKey,
  //       timestampData: [startDateTimeStamp, endDateTimeStamp],
  //     };
      
  //     const response = await filterRealtimePerceptionScores(payload);
  //     return response.data;
  //   },
  //   select: (data) => {
  //     if ("Items" in data) {
  //       if (data.Items.length > 0) {
  //         return {
  //           scores: data.Items.map((item: any, i: number) => { return item.perceptionScore?.toFixed(0); }),
  //           timestamps: data.Items.map((item: any, i: number) => { return item.uploadTime; }),
  //         }
  //       }
  //     }

  //     if ("bpt_index" in data) {
  //       return {
  //         scores: [data.bpt_index.perceptionScore?.toFixed(0)],
  //         timestamps: [],
  //       }
  //     }

  //     return { scores: [], timestamps: [] };
  //   },
  // }); 



  const handleFilter = useMutation({
    mutationFn: async (data) => {
      const payload = {
        platformKey: queryKey,
        // timestampData: [`${(new Date((startDateTimeStamp !== "") ? startDateTimeStamp : startDate).getTime() / 1000)}`, `${(new Date((endDateTimeStamp !== "") ? endDateTimeStamp : endDate).getTime() / 1000)}`],
        timestampData: [startDateTimeStamp, endDateTimeStamp],
      };
      
      const response = await filterRealtimePerceptionScores(payload);
      return response.data;
    },
    onSuccess: (data) => {
      console.log({data});
      setFilter(1);
      if ("Items" in data) {
        if(data.Items.length > 0) {
          const newItems = data.Items.map((item: any) => item.perceptionScore);
          console.log({newItems});
          dispatch(SET_BPT_SCORES(newItems));
          queryclient.refetchQueries(["getRealtimePerceptionScores"]);
        }
      }
      dispatch(SET_BPT_SCORES([]));
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    // if(filter === 1) {
    //   dispatch(SET_BPT_SCORES([]));
    // }


    const timer = window.setInterval(() => {
      queryclient.refetchQueries(["getRealtimePerceptionScores"]);
      
      ApexCharts.exec("realtime", "updateSeries", [{
        data: data.scores.slice(0, 20),
      }]);
    }, 300000);

    return () => {
      window.clearInterval(timer);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) return <SkeletonLoader className="h-[calc(54vh_-_1.25rem)] rounded-md" />;

  if (status === 'error') return <span>Error: {error.message}</span>;

  const options: ApexOptions = {
    chart: {
      id: "realtime",
      type: "line",
      width: "100%",
      height: "100%",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      },
      background: "transparent",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000
        }
      },
      dropShadow: {
        enabled: true,
        opacity: 0.1,
        blur: 2.5,
        left: -7,
        top: 5
      },
      // events: {
      //   animationEnd: function (chartCtx: any) {
      //     const newSeries = chartCtx.w.config.series[0].data;
          
      //     window.setTimeout(function () {
      //       queryclient.refetchQueries(["getRealtimePerceptionScores"]);
            
      //       chartCtx.updateOptions(
      //         {
      //           series: [
      //             {
      //               data: newSeries
      //             },
      //           ],
      //         },
      //         false,
      //         false
      //       );

      //       console.log("Working...")
      //     }, 1000);
      //   }
      // },
    },
    annotations: {
      yaxis: [{
        y: 40,
        y2: 0,
        borderColor: "#EAB30860",
        fillColor: "#EAB30860",
        label: {
          borderColor: "#EAB308",
          style: {
            color: "#333333",
            background: "#EAB308",
          },
          text: "Caution Area",
        }
      }],
    },
    colors: ["#377DFF"],
    // xaxis: {
    //   type: "datetime",
    //   categories: data.timestamps.slice(),
    //   labels: {
    //     show: true,
    //     format: "dd MMM, h:mm",
    //   }
    //   // range: 2700000
    // },
    xaxis: {
      range: 10,
      type: "numeric",
      tickAmount: "dataPoints",
      tickPlacement: "on",
      labels: {
        show: false,
      },
    },
    yaxis: {
      // tickAmount: 5,
      min: 0,
      max: 100,
      labels: {
        style: {
          colors: ["#aaaaaa"],
        },
      },
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    markers: {
      size: 0,
      colors: ["#377DFF"],
    },
    stroke: {
      curve: "smooth",
      lineCap: "round",
      width: 2,
      colors: ["#377DFF"],
    },

    tooltip: {
      // show: true,
      marker: {
        show: true,
      },
      x: {
        show: true,
        // type: "datetime",
        format: "dd MMM, h:mm",
      },
      y: {
        formatter: undefined,
        title: {
            formatter: (seriesName: any) => seriesName,
        },
      },
      z: {
        formatter: undefined,
        title: "Size: "
      },
    },
  };

  const series: ApexAxisChartSeries = [
    {
      name: "Perception Score",
      data: data.scores.slice(0, 20),
    },
  ];

  return (
    <div className="flex flex-col h-[calc(54vh_-_1.25rem)] bg-white rounded-md shadow-md relative overflow-hidden">
      <div className="flex items-center justify-between px-5 py-3">
        <div className="inline-flex items-center space-x-1 lg:space-x-2">
          <p className="text-xs font-semibold text-gray-500 lg:text-sm">Brand reputation tracker </p>
          <IoMdInformationCircleOutline size={16} className="hidden mt-0.5 text-gray-500 lg:inline-block" />
          <IoMdInformationCircleOutline size={12} className="inline-block mt-0.5 text-gray-500 lg:hidden" />
        </div>
        <div>
          <div className="flex items-center gap-2 text-xs lg:gap-5">
            <div className="inline-flex mr-0 rounded-lg lg:gap-2">
              {/* <button onClick={() => setIsPositive(e => !e)} className={classNames("px-2 py-1", (!isPositive) ? "rounded-l bg-primary text-white" : "text-gray-400")}>
                Negative
              </button>
              <button onClick={() => setIsPositive(e => !e)} className={classNames("px-2 py-1", (isPositive) ? "rounded-r bg-primary text-white" : "text-gray-400")}>
                Positive
              </button> */}
              {/* <span className="inline-flex items-center gap-1 text-sm text-gray-400"><BsCircleFill className="fill-red-500" size={12} /> Negative</span> */}
              {/* <span className="inline-flex items-center gap-1 text-sm text-gray-400"><BsCircleFill className="fill-yellow-500" size={12} /> Caution Area</span> */}
              <span className="inline-flex items-center gap-1 text-xs text-gray-400 lg:text-xs"><BsCircleFill className="fill-blue-500" size={10} /> Perception Score</span>
            </div>
            <Popover as="div" className="relative">
              <div>
                <Popover.Button className={classNames("px-2 border rounded-md border-gray-300 py-1 text-xs inline-flex items-center gap-x-1 text-gray-500 fill-gray-500 focus:outline-none disabled:bg-gray-100")}>
                  <IoFilterSharp /> Filter
                </Popover.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Popover.Panel className="absolute right-0 z-10 mt-2 text-sm bg-white rounded-lg shadow">
                  {({ close }) => (
                    <div className="p-2 space-y-2">
                      <div className="flex items-center space-x-2">
                        <div date-rangepicker="true">
                          <span className="text-xs text-gray-500">Start Date</span>
                          <div className="relative">
                            <input
                              name="start"
                              type="date"
                              // value={startDateTimeStamp}
                              onChange={(e) =>
                                setStartDateTimeStamp(`${new Date(e.target.value).getTime() / 1000}`)
                              }
                              className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                              placeholder="Select date"
                            />
                          </div>
                        </div>
                        <div date-rangepicker="true">
                          <span className="text-xs text-gray-500">End Date</span>
                          <div className="relative">
                            <input
                              name="end"
                              type="date"
                              // value={endDateTimeStamp}
                              onChange={(e) =>
                                setEndDateTimeStamp(`${new Date(e.target.value).getTime() / 1000}`)
                              }
                              className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                              placeholder="Select date"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-center space-x-2 text-xs">
                        <button
                          className="w-1/2 px-4 py-2 font-bold text-gray-700 truncate bg-transparent border rounded"
                          onClick={(e) => {
                            handleClearFilter(e);
                            close();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="w-1/2 px-4 py-2 font-bold text-white truncate rounded bg-primary"
                          onClick={() => {
                            if (filter === 0) {
                              setTempScores(scores);
                              handleFilter.mutate();
                            } else {
                              setFilter(0);
                            }

                            close();
                          }}
                        >
                          {handleFilter.isLoading ? (
                            <ImSpinner2 size={22} className="mx-auto animate-spin" />
                          ) : (
                            "Filter"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </Popover>
            {/* <div className="inline-flex gap-1 px-2 py-1 border-2 border-gray-300 rounded-lg shadow-md shadow-gray-200">
              <button onClick={() => toggleChartData(ChartDataInterval.Monthly)} className={classNames("px-2 py-1", (interval === ChartDataInterval.Monthly) ? "rounded-l bg-primary text-white" : "text-gray-400")}>
                Monthly
              </button>
              <button onClick={() => toggleChartData(ChartDataInterval.Weekly)} className={classNames("px-2 py-1", (interval === ChartDataInterval.Weekly) ? "bg-primary text-white" : "text-gray-400")}>
                Weekly
              </button>
              <button onClick={() => toggleChartData(ChartDataInterval.Daily)} className={classNames("px-2 py-1", (interval === ChartDataInterval.Daily) ? "bg-primary rounded-r text-white" : "text-gray-400")}>
                Daily
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div ref={chartRef} id="perception-tracker-chart" className="z-0 flex justify-center text-left"></div> */}
      <div id="perception-tracker-chart" className="flex-1 w-full h-full">
        <ReactApexChart options={options} series={series} type="line" height={"100%"} />
      </div>
      {/* {isFetching ? <div className="absolute z-10 w-56 bg-white inset-1/2">
        <ImSpinner8 size={28} className="animate-spin fill-primary" />
      </div> : null} */}
    </div>
  );
};

export default BusinessPerceptionTracker;
