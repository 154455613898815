import { BiChevronDown, BiChevronUp, BiInfoCircle } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { classNames, getRelativeTime } from "../../../utils";
import { useState } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { StoreModel, UserProfileModel } from "../../../models";
import { useAppSelector } from "../../../hooks";
import {
  deleteOrders,
  downloadNotifications,
  getOrders,
  getSingleItem,
  handleReadNotification,
  updateOrderStatus,
} from "../../../api/Menu";
import { AiOutlineClockCircle } from "react-icons/ai";
import { ImSpinner3 } from "react-icons/im";
import { MdClear, MdDeleteOutline } from "react-icons/md";
import PendingOrders from "./_components/PendingOrders";
import CancelledOrders from "./_components/CancelledOrders";
import InProgressOrders from "./_components/InProgressOrders";
import CompletedOrders from "./_components/CompletedOrders";
import Modal from "../../../components/Dashboard/Modal";
import { BsArchive } from "react-icons/bs";

const OrderStatus = [
  { status: "Pending" },
  { status: "Active" },
  { status: "Completed" },
  { status: "Cancelled" },
];

const OrdersPage = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = `${user.queryKey}`;
  const platformKey = queryKey.replace("%26", "&");
  const location = useLocation();
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [ordersIdArray, setOrdersIdArray] = useState([]);

  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = `${store.name}`;
  const text = `${window.location.origin}/store${storeKey}`;

  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const navigate = useNavigate();

  // console.log(ordersIdArray);

  const handleDeleteOrders = useMutation({
    mutationFn: async () => {
      const payload = {
        orderIds: ordersIdArray,
      };

      return await deleteOrders(payload);
    },
    onSuccess: () => {
      // navigate(-1);
      setShowDeleteModal(false);
      queryClient.invalidateQueries({ queryKey: ["getPendingOrders"] });
      queryClient.invalidateQueries({ queryKey: ["getCompletedOrders"] });
      queryClient.invalidateQueries({ queryKey: ["getActiveOrders"] });
      // setOpenStatus(-1);
      alert("Deleted successfully");
    },
  });

  return (
    <>
      <Modal
        // size="medium"
        show={showDeleteModal}
        onToggle={() => {
          setShowDeleteModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 space-y-2">
          <p className="text-sm font-semibold">Delete order</p>
          <p className="text-[12.5px] ">
            Are you sure you want to delete selected order.
          </p>
          <div className="flex items-center justify-start gap-4">
            <button
              className="px-1 text-sm"
              onClick={() => setShowDeleteModal(false)}
            >
              No
            </button>
            <button
              className="px-1 text-sm"
              onClick={() => {
                handleDeleteOrders.mutate();
              }}
            >
              {handleDeleteOrders.isLoading ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        // size="medium"
        show={showArchiveModal}
        onToggle={() => {
          setShowArchiveModal(false);
          setShowCheckbox(false);
        }}
      >
        <div className="relative flex flex-col p-6 space-y-2">
          <p className="text-sm font-semibold">Archive order</p>
          <p className="text-[12.5px] ">
            Are you sure you want to archive selected order.
          </p>
          <div className="flex items-center justify-start gap-4">
            <button className="px-1 text-sm">No</button>
            <button className="px-1 text-sm">Delete</button>
          </div>
        </div>
      </Modal>

      <div className="bg-white relative rounded-md">
        <p className="mb-3 text-lg font-semibold text-left">Orders</p>
        <div className="mb-3 flex items-center justify-between gap-2">
          <div className="flex items-center gap-2 text-[13px] font-semibold text-gray-800">
            <p
              onClick={() => {
                navigate("/orders?type=pending");
              }}
              className={classNames(
                "pb-1 px-2 cursor-pointer",
                type === "pending" ? " border-b-[1px] border-gray-900" : ""
              )}
            >
              Pending
            </p>
            <p
              onClick={() => {
                navigate("/orders?type=active");
              }}
              className={classNames(
                "pb-1 px-2 cursor-pointer",
                type === "active" ? " border-b-[1px] border-gray-900" : ""
              )}
            >
              Active
            </p>
            <p
              onClick={() => {
                navigate("/orders?type=completed");
              }}
              className={classNames(
                "pb-1 px-2 cursor-pointer",
                type === "completed" ? " border-b-[1px] border-gray-900" : ""
              )}
            >
              Completed
            </p>
            <p
              onClick={() => {
                navigate("/orders?type=cancelled");
              }}
              className={classNames(
                "pb-1 px-2 cursor-pointer hidden",
                type === "cancelled" ? " border-b-[1px] border-gray-900" : ""
              )}
            >
              Cancelled
            </p>
          </div>
          <div className="flex items-center gap-4">
            <button
              disabled
              className="py-2 px-3 rounded bg-gray-100 text-[13px] inline-flex items-center gap-2.5 hidden"
              onClick={() => {
                if (showCheckbox) {
                  setShowArchiveModal(true);
                } else {
                  setShowCheckbox((prev) => !prev);
                }
              }}
            >
              <BsArchive size={19} />
              {showCheckbox ? " Archive orders" : "Add to archive"}
            </button>
            <button
              onClick={() => {
                if (showCheckbox) {
                  setShowDeleteModal(true);
                } else {
                  setShowCheckbox((prev) => !prev);
                }
              }}
              className="py-2 px-3 rounded bg-gray-100 text-[13px] inline-flex items-center gap-2.5"
            >
              <MdDeleteOutline size={20} />
              {showCheckbox ? "Delete orders" : "Delete"}
            </button>
          </div>
        </div>
        {type === "pending" && (
          <PendingOrders
            showCheckbox={showCheckbox}
            setShowCheckbox={setShowCheckbox}
            setOrdersIdArray={setOrdersIdArray}
            ordersIdArray={ordersIdArray}
          />
        )}
        {type === "completed" && <CompletedOrders />}
        {/* {type === "cancelled" && <CancelledOrders />} */}
        {type === "active" && <InProgressOrders />}
      </div>
    </>
  );
};

export default OrdersPage;
