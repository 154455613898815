import axios from "axios";

const CLIENT_ID = "2vq0s6965oeq62n01dbhq4rfaq";
const CLIENT_SECRET = "1iiaudint7iujtca9qb50ihh34flmui6hse8gpau4ek2jhdbob8q";
const URL =
  "https://cloudpactai-customerecho-ingestion-stackv1.auth.eu-west-2.amazoncognito.com/token";

const client_id1 = "2vq0s6965oeq62n01dbhq4rfaq";
const client_secret1 = "1iiaudint7iujtca9qb50ihh34flmui6hse8gpau4ek2jhdbob8q";
const url1 =
  "https://cloudpactai-customerecho-ingestion-stackv1.auth.eu-west-2.amazoncognito.com/token";

const client_id2 = "51oidbs7t3b4jc2ie8git3kp2c";
const client_secret2 = "m4cpjumtrfbt2k7d74nq56a9hna272s8cdrqmtnb2al350uocnp";
const url2 =
  "https://cloudpactai-cusecho-payment-stacks-app-v1.auth.eu-west-2.amazoncognito.com/token";

export const generateAuthToken = async () =>
  fetch(URL, {
    method: "POST",
    body:
      "grant_type=client_credentials&client_id=" +
      CLIENT_ID +
      "&client_secret=" +
      CLIENT_SECRET,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then((res) => {
    return res.json();
  });

export const generateImageToken = async () =>
  fetch(url1, {
    method: "POST",
    body:
      "grant_type=client_credentials&client_id=" +
      client_id1 +
      "&client_secret=" +
      client_secret1,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then((res) => {
    return res.json();
  });

export const generatePaymentToken = async () =>
  fetch(url2, {
    method: "POST",
    body:
      "grant_type=client_credentials&client_id=" +
      client_id2 +
      "&client_secret=" +
      client_secret2,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then((res) => {
    return res.json();
  });

export const getPlatformAPIKEY = async (slugUrl: string, requestOptions: any) =>
  fetch(
    `https://6xvjwq05xd.execute-api.eu-west-2.amazonaws.com/dev/v1/get_platform_api_key?slugUrl=${slugUrl}`,
    requestOptions
  ).then((response) => {
    return response.json();
  });

export const accountSetup = async (requestOptions: any) =>
  fetch(
    `https://6xvjwq05xd.execute-api.eu-west-2.amazonaws.com/dev/v1/account_setup?clientRequest=1`,
    requestOptions
  ).then((response) => response.json());

export const createSessionId = async (email: string, sessionId: string) =>
  axios.post(
    `https://9de7htizok.execute-api.eu-west-2.amazonaws.com/Prod/api/userSessions/${email}/${sessionId}`,
    null
  );

export const updateSessionLogout = async (email: string, sessionId: string) =>
  axios.put(
    `https://9de7htizok.execute-api.eu-west-2.amazonaws.com/Prod/api/userSessions/${email}/${sessionId}`,
    null
  );
  
