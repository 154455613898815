import { classNames, getSvg } from "../../../utils";
import { useState } from "react";
import Modal from "../../../components/Dashboard/Modal";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { UserProfileModel } from "../../../models";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  deleteKPI,
  embedKPI,
  getKPIEmbedStatus,
  getKPIs,
  switchKPIMode,
  uploadKPI,
} from "../../../api/KPIs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ImSpinner2, ImSpinner6 } from "react-icons/im";
import { FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SkeletonLoader from "../../../components/Dashboard/SkeletonLoader";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { KPIEmbedState, SET_KPI_EMBED } from "../../../redux/Utils";

const KPIPage = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const platform_key = `${user.platformAccountId}&${user.email}`;
  const queryKey = user.queryKey;
  const queryClient = useQueryClient();
  const [newKPI, setNewKPI] = useState("");
  const [updateKPI, setUpdateKPI] = useState("");
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentMetric, setCurrentMetric] = useState<any>({});
  const [isPublished, setPublished] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [newQuestn, setNewQuestn] = useState("");
  const [updateQuestn, setUpdateQuestn] = useState("");
  const [enableBeacon1, setEnableBeacon1] = useState(true);
  const [archiveStatus, setArchiveStatus] = useState(false);
  const [showNewEditModal, setShowNewEditModal] = useState(false);
  const dispatch = useAppDispatch();
  const kpi_embed_obj: KPIEmbedState = useAppSelector((state) => state.utils.kpi_embed);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleKPIAdd = useMutation({
    mutationFn: async (data: any) => {
      const body = {
        platform_key,
        request_trigger: "0",
        data: [
          {
            tag_name: data.tag_name,
            similarity_threshold: "80%",
            status: data.status,
            metric: data.metric,
            upload_id: uuidv4(),
            upload_time: moment().format(),
            upload_timestamp: moment().format(),
            map_event: [],
            question_tag: data.question_tag,
            question: data.question,
            related_reviews_count: 0,
          },
        ],
      };
      return await embedKPI(body);
    },
    onSuccess: () => {
      // setShowAddModal(true);
      dispatch(SET_KPI_EMBED({ status: "Pending", active: true }));
      // queryClient.invalidateQueries("getKPIs");
      // queryClient.invalidateQueries("getActiveFeedbackQuestions");
      // setNewKPI("");
      // setNewQuestn("");
      // setShowAddModal(false);
    },
  });

  const handleKPIUpdate = useMutation({
    mutationFn: async (data: any) => {
      const body = {
        platform_key,
        request_trigger: "1",
        data: [
          {
            tag_name: data.tag_name,
            similarity_threshold: "80%",
            status: data.status,
            metric: data.metric,
            upload_id: uuidv4(),
            upload_time: moment().format(),
            upload_timestamp: moment().format(),
            map_event: [],
            question_tag: data.question_tag,
            question: data.question,
            related_reviews_count: 0,
          },
        ],
      };
      return await uploadKPI(body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
      queryClient.invalidateQueries({ queryKey: ["getActiveFeedbackQuestions"] });
      setUpdateKPI("");
      setShowEditModal(false);
    },
  });

  const handleKPISwitch = useMutation({
    mutationFn: async (data: any) => {
      return await switchKPIMode(
        data.status,
        queryKey,
        data.tag_name,
        data.question_tag,
        data.question_tag_status
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
      queryClient.invalidateQueries({ queryKey: ["getActiveFeedbackQuestions"] });
    },
  });

  const { status, data, error } = useQuery<any, Error>({
    queryKey: ["getKPIs"],
    queryFn: async () => {
      const response = await getKPIs(queryKey);
      return response.data;
    },
    select: (data) => {
      const saved = data.Item;
      delete saved.kpi_metrics;
      delete saved.platform_key;
      delete saved.kpi_metadata;
      const newData = Object.values(saved);
      return newData;
    },
  });

  const kpiEmbedListener = useQuery<any, Error>({
    // queryKey: ["getKPIEmbedStatus", kpi_embed_obj.active, kpi_embed_obj.status],
    queryKey: ["getKPIEmbedStatus"],
    queryFn: async () => {
      const response = await getKPIEmbedStatus(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      console.log("EMBEDRESPONSE", data);
      if ("Progress_Status" in data) {
        if (data.Progress_Status === "Completed") {
          setNewKPI("");
          setNewQuestn("");
          queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
      queryClient.invalidateQueries({ queryKey: ["getActiveFeedbackQuestions"] });
          setShowAddModal(true);
          dispatch(SET_KPI_EMBED({ status: data.Progress_Status, active: false }));
          return;
        }

        if (data.Progress_Status === "Cancelled") {
          queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
      queryClient.invalidateQueries({ queryKey: ["getActiveFeedbackQuestions"] });
          setMessage("Sorry an error occurred");
          dispatch(SET_KPI_EMBED({ status: data.Progress_Status, active: false }));
          return;
        }
      }
    },
    enabled: (kpi_embed_obj?.active && kpi_embed_obj?.status === "Pending"),
  });

  const handleKPIDelete = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        platformKey: queryKey,
        data: [
          {
            archive_request: data.archive_status,
            metricTag: data.tag_name,
            questionTag: data.question_tag,
          },
        ],
      };
      return await deleteKPI(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKPIs"] });
      queryClient.invalidateQueries({ queryKey: ["getActiveFeedbackQuestions"] });
      setShowDeleteModal(false);
    },
  });

  if (status === "loading")
    return <SkeletonLoader className="rounded-md h-[85vh]" />;

  if (status === "error") return <span>Error: {error.message}</span>;

  if (data) {
    var activeKKPIs =
      data.filter((metric: any) => metric[0].status === "1") ?? [];
  }
  
  return (
    <>
      <Modal show={false}>
        <div className="relative flex flex-col p-6 md:py-8 md:px-10">
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-xl">
              Choosing A Key Performance Indicator
            </h1>
            <p className="text-gray-500 md:text-sm">
              The more detailed your KPI, the more accurate your results. Enhance your performance tracking by providing Detailed Descriptions. E.g Tracking the impact of our current promotional product offers.
            </p>
          </div>
          <div className="flex items-center justify-center gap-2 mt-6 md:mt-10">
            <div>
              <button
                className="px-4 py-2 font-bold text-center text-white border rounded-md w-44 md:px-6 lg:px-8 bg-primary"
                onClick={() => {
                  setShowWelcomeModal(false);
                  setEnableBeacon1(false) 
                }}
              >
                Got It
              </button>
            </div>
          </div>
          
        </div>
      </Modal>
      <Modal show={showAddModal}>
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img src={getSvg("check")} alt="icon" className="w-20 mx-auto" />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-lg">
              Success Metric Successfully Saved!
            </h1>
            <p className="hidden text-gray-500 md:text-xs">
              Edit your question in the input box of the saved metrics. You{" "}
              <br />
              can save it by pressing enter
            </p>
          </div>
          <MdOutlineCancelPresentation
            size={28}
            className="absolute cursor-pointer top-2 right-2"
            onClick={() => setShowAddModal((prevState) => !prevState)}
          />
        </div>
      </Modal>
      <Modal show={showDeleteModal}>
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-4">
            <FiTrash2 size={40} className="mx-auto text-red-700 w-28" />
          </div>
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-800 md:text-xl">
              Delete Success Metric
            </p>
            <div className="flex flex-col items-center">
              <label className="inline-flex items-center gap-2 mb-2 text-gray-800 md:text-sm">
                <input
                  type="radio"
                  checked={archiveStatus}
                  name="bordered-checkbox"
                  className="w-1.5 h-1.5 mr-1.5 bg-white border-white rounded-full appearance-none checked:bg-green-600 ring-gray-600 checked:ring-green-500 ring-1 ring-offset-white ring-offset-2"
                  onChange={() => setArchiveStatus(true)}
                />
                <span>Keep data archived</span>
                <AiOutlineInfoCircle />
              </label>
              <label className="inline-flex items-center gap-2 -ml-8 text-gray-800 md:text-sm">
                <input
                  type="radio"
                  checked={!archiveStatus}
                  name="bordered-checkbox"
                  className="w-1.5 h-1.5 mr-1.5 bg-white border-white rounded-full appearance-none checked:bg-green-600 ring-gray-600 checked:ring-green-500 ring-1 ring-offset-white ring-offset-2"
                  onChange={() => setArchiveStatus(false)}
                />
                <span> Delete all data</span>
                <AiOutlineInfoCircle />
              </label>
            </div>
          </div>
          <div className="flex justify-center gap-2 mt-6 text-sm md:mt-10">
            <div>
              <button
                className="px-4 py-2 font-bold text-center text-white bg-red-700 border rounded-md w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  handleKPIDelete.mutate({
                    e,
                    tag_name: currentMetric.tag_name,
                    archive_status: archiveStatus ? "1" : "0",
                    question_tag: currentMetric.question_tag,
                  });
                }}
              >
                {handleKPIDelete.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 font-bold text-center text-gray-800 bg-white border rounded-md w-44 md:px-6 lg:px-8"
                onClick={() => setShowDeleteModal(false)}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={showEditModal} onToggle={() => setShowEditModal(false)}>
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 space-y-2 text-center md:mt-8">
            <h1 className="font-medium text-gray-500 md:text-xl">
              You Have Updated Your Question. Do You Want To Keep Your Previous
              Feedback?
            </h1>
          </div>
          <div className="flex justify-center gap-2 mt-6 md:mt-10">
            <div>
              <button
                className="px-4 py-2 font-bold text-center text-white border rounded-md w-44 md:px-6 lg:px-8 bg-primary"
                onClick={(e) => {
                  setPublished(false);
                  handleKPIUpdate.mutate({
                    e,
                    metric: updateKPI,
                    tag_name: currentMetric.tag_name,
                    status: "1",
                    question_tag: currentMetric.question_tag,
                    question: updateQuestn,
                  });
                }}
              >
                {handleKPIUpdate.isLoading && !isPublished ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Yes, Keep"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 font-bold text-center text-gray-800 bg-transparent border rounded-md w-44 md:px-6 lg:px-8"
                onClick={() => setShowEditModal(false)}
              >
                No, Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={showLimitModal} onToggle={() => setShowLimitModal(false)}>
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="flex items-center justify-center">
            <img src="/media/image/connection-error.png" alt="" />
          </div>
          <div className="mt-5 space-y-2 text-center md:mt-8">
            <h1 className="font-semibold text-red-600 md:text-xl">
              Maximum Limit Reached
            </h1>
            <p className="md:text-sm">
              Max KPI Limit reached. Deactivate one to activate another
            </p>
          </div>
        </div>
      </Modal>
      <Modal size="medium" show={showNewEditModal} onToggle={() => setShowNewEditModal(false)}>
        <div className="px-[4%] py-4">
          <h5 className="text-lg font-semibold text-gray-800">Edit metric</h5>
          <form onSubmit={(e) => {
            e.preventDefault();
            setShowNewEditModal(false);
            setShowEditModal(true);
          }} className="flex flex-col mt-3 mb-1 space-y-3">
            <div className="flex flex-col space-y-1">
              <label className="text-xs font-medium text-gray-600">Metric name</label>
              <input type="text" defaultValue={currentMetric.metric} readOnly className="px-3 py-2 text-sm border border-gray-600 rounded-lg outline-none read-only:bg-gray-200 placeholder:text-gray-300" />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs font-medium text-gray-600">Question</label>
              <input type="text" defaultValue={currentMetric.question} onChange={(e) => setUpdateQuestn(e.target.value)} className="px-3 py-2 text-sm border border-gray-600 rounded-lg outline-none placeholder:text-gray-300" />
            </div>
            <button type="submit" className="p-1.5 mx-auto text-sm font-semibold text-center text-white rounded-md w-20 bg-primary">Save</button>
          </form>
        </div>
      </Modal>
      <div className="h-[85vh] overflow-y-auto overflow-x-hidden">
        <div className="flex items-center space-x-10">
          <Link to="" onClick={(e: any) => {
          e.preventDefault();
          navigate(-1);
        }} className="flex items-center space-x-2 text-gray-800" >
            <HiOutlineArrowNarrowLeft size={16} />
            <p className="text-sm font-semibold">Add Business Metrics</p>
          </Link>
          {/* <div className={classNames("flex text-primary font-semibold text-base items-center px-2.5 py-2 rounded-md border border-primary bg-primary/20", ((handleKPIAdd.isLoading && isPublished) || (handleKPISwitch.isLoading)) ? "opacity-100" : "opacity-0")}>
            <ImSpinner6 size={14} className="mr-1.5 animate-spin duration-300 ease-linear" />
            {"In progress"}
          </div> */}
          <div className={classNames("flex text-primary font-semibold text-sm items-center px-2.5 py-2 rounded-md border border-primary bg-primary/20", ((handleKPIAdd.isLoading && isPublished) || (kpi_embed_obj?.active && kpi_embed_obj?.status === "Pending") || (handleKPISwitch.isLoading)) ? "opacity-100" : "opacity-0")}>
            <ImSpinner6 size={14} className="mr-1.5 animate-spin duration-300 ease-linear" />
            {"In progress"}
          </div>
        </div>
        <div className="p-2 max-w-[900px]">
          {data.length < 20 && (
            <div>
              <form
                className="flex items-center justify-between gap-20"
              >
                <div className="w-full">
                  <label className="mb-1 text-xs font-normal text-gray-800">
                    Metric name
                  </label>
                  <div className="flex items-center justify-between mb-2">
                    <input
                      type="text"
                      name="kpi"
                      placeholder="E.g. Track the impact of discounts."
                      value={newKPI}
                      onClick={() => {
                        if (enableBeacon1 === true) {
                          setShowWelcomeModal(true);
                        }
                      }}
                      onChange={(e) => setNewKPI(e.target.value)}
                      className="w-1/2 px-3 py-2.5 text-sm text-gray-800 bg-white border border-gray-400 rounded outline-none inputkpi placeholder:text-xs"
                      required
                    />
                  </div>
                  <label className="mb-1 text-xs font-normal text-gray-800">
                    Question
                  </label>
                  <div className="flex items-center justify-between mb-2">
                    <input
                      type="text"
                      name="kpi"
                      placeholder="E.g. Will you shop with us without discounts?"
                      value={newQuestn}
                      onChange={(e) => setNewQuestn(e.target.value)}
                      className="w-1/2 px-3 py-2.5 text-sm text-gray-800 bg-white border border-gray-400 rounded outline-none placeholder:text-xs"
                      required
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (newKPI !== "") {
                        setMessage("");
                        setPublished(true);
                        handleKPIAdd.mutate({
                          e,
                          metric: newKPI,
                          tag_name: `Success_Metric_${data.length === 0 ? 1 : Number(data[data.length - 1][0].tag_name.toString().replaceAll("Success_Metric_", "")) + 1}`,
                          status: "1",
                          question_tag: `Question_${data.length === 0 ? 1 : Number(data[data.length - 1][0].question_tag.toString().replaceAll("Question_", "")) + 1}`,
                          question: newQuestn,
                        });
                      }
                    }}
                    type="submit"
                    className="w-28 text-white bg-primary hover:bg-blue-800 mt-3 font-medium rounded text-xs py-2.5 text-center disabled:opacity-60 disabled:cursor-default"
                    // disabled={(handleKPIAdd.isLoading && isPublished)}
                    disabled={((handleKPIAdd.isLoading && isPublished) || (kpi_embed_obj?.active && kpi_embed_obj?.status === "Pending"))}
                  >
                    {/* {handleKPIAdd.isLoading && isPublished ? (
                      <ImSpinner2
                        size={22}
                        className="mx-auto animate-spin"
                      />
                    ) : (
                      "Add"
                    )} */}
                    Add
                  </button>
                  {message !== "" && (<div className="mt-1.5">
                    <p className="text-xs font-semibold text-red-500">{message}</p>
                  </div>)}
                </div>
              </form>
            </div>
          )}  
          <h4 className="mt-6 mb-4 text-[13px] font-semibold text-gray-700">
            Saved business metric{data.length === 1 ? "" : "s"}
          </h4>
          {data.length > 0 &&
            data.map((kpi: any, index: number) => (
            <div key={index} className="flex items-start justify-between w-full p-4 mb-3 border border-gray-400 rounded-md md:w-4/5">
              <div className="flex flex-col space-y-3">
                <h5 className="text-sm font-semibold text-gray-800">{kpi[0].metric}</h5>
                <p className={classNames("mt-2 font-medium text-gray-600", (kpi[0].question === "") ? "italic text-xs" : "text-[13px]")}>{(kpi[0].question !== "") ? kpi[0].question : "No question set"}</p>
              </div>
              <div onClick={() => setCurrentMetric(kpi[0])} className="relative group">
                <button className="p-1">
                  <FiMoreVertical size={20} />
                </button>
                <div className="absolute top-0 right-0 z-10 flex-col hidden p-1 mt-8 space-y-1 origin-top-right bg-white border rounded shadow group-focus-within:flex shadow-gray-200 w-36">
                  <button 
                  type="button" 
                  onClick={() => {
                    if (
                      currentMetric.tag_name !== kpi[0].tag_name
                    ) {
                      setCurrentMetric(kpi[0]);
                    }
                    setShowNewEditModal(true);
                  }} className="px-1.5 py-1.5 w-full text-left font-semibold rounded text-sm text-black transition duration-200 ease-linear hover:bg-blue-200">Edit</button>
                  <button 
                  type="button"
                  onClick={() => {
                    if (
                      currentMetric.tag_name !== kpi[0].tag_name
                    ) {
                      setCurrentMetric(kpi[0]);
                    }
                    setShowDeleteModal(true);
                  }} className="px-1.5 py-1.5 w-full text-left font-semibold rounded text-sm text-black transition duration-200 ease-linear hover:bg-blue-200">
                    Delete
                  </button>
                  <button 
                  type="button"
                  onClick={() => {
                    if (
                      Number(kpi[0].status) === 0 &&
                      activeKKPIs.length === 5
                    ) {
                      setShowLimitModal(true);
                      return;
                    }
                    setCurrentMetric(kpi[0]);
                    handleKPISwitch.mutate({
                      tag_name: kpi[0].tag_name,
                      status: Number(currentMetric.status) === 0 ? 1 : 0,
                      question_tag: kpi[0].question_tag,
                      question_tag_status:
                        currentMetric.question !== "" ? "1" : "0",
                    });
                  }}
                  disabled={
                    handleKPISwitch.isLoading &&
                    currentMetric.tag_name === kpi[0].tag_name
                  } className="px-1.5 py-1.5 w-full text-left font-semibold rounded text-sm text-black transition duration-200 ease-linear hover:bg-blue-200">{Number(kpi[0].status) === 0 ? "Activate" : "Deactivate"}</button>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="flex flex-col items-center justify-center h-full py-5">
              <p className="text-lg text-gray-400">
                You don&apos;t have any saved business KPIs yet
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default KPIPage;
