import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { getImage } from "../../../../../utils";
import { useNavigate } from "react-router-dom";
import { FaLock } from "react-icons/fa";

const Services = [
  {
    title: "Customer Review",
    desc: "Unlock valuable insights and areas for continuous improvement from customers.",
    image: "HomeCustomerReview",
    href: "/echo-chat",
  },
  {
    title: "Customer Satisfaction",
    desc: "Easily analyze reviews to discover customer satisfaction and enhance your offerings.",
    image: "HomeCustomerSatisfaction",
    href: "/home/echo-booking",
  },
  {
    title: "Customer Loyalty",
    desc: "Offer loyalty cards and discounts to keep customers coming back for more.",
    image: "HomeCustomerLoyalty",
    href: "/home/echo-menu",
  },
  {
    title: "Help Desk",
    desc: "Effortlessly receive and resolve customer complaints to provide a seamless experience.",
    image: "HomeHelpDesk",
    href: "/task-wizard",
  },
  {
    title: "Business KPI Tracker",
    desc: "Set and track your business KPIs while receiving customer feedback that ensures you're hitting your targets.",
    image: "HomeBusinessKPI",
    href: "/data-store",
  },
  {
    title: "Store",
    desc: "Showcase your product, reach a wider audience, and sell from one convenient place.",
    image: "HomeStore",
    href: "/home/echo-webpage/upload-url",
  },
  {
    title: "Task Manager",
    desc: "Focus on the most important things, stay organized and boost your productivity.",
    image: "HomeTaskManager",
    href: "/training-repository",
  },
  {
    title: "Role Manager",
    desc: "Focus on the most important things, stay organized and boost your productivity.",
    image: "HomeRoleManager",
    href: "/training-repository",
  },
  {
    title: "Bulk Message",
    desc: "Focus on the most important things, stay organized and boost your productivity.",
    image: "HomeBulkMessage",
    href: "/training-repository",
  },
];

const GrowthPlan = () => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="absolute left-0 top-0 flex items-center gap-3 py-1">
        <div
          className="p-2 text-gray-800 bg-white rounded cursor-pointer"
          onClick={() => navigate("/home")}
        >
          <BiChevronLeft />
        </div>
      </div>
      <p className="text-2xl font-semibold mb-6 text-center">Growth Plan</p>
      <div className="max-w-[800px] mx-auto lg:max-w-[1000px]">
        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-4">
          {Services.map((service: any, index: number) => (
            <div
              className="px-3 py-4 bg-white rounded-md flex items-center gap-4 hover:bg-gray-50"
              key={index}
            >
              <div className="w-[60px] rounded-md">
                <img
                  src={getImage(service.image)}
                  alt=""
                  className="w-[80px]"
                />
              </div>
              <div className="w-full">
                <p className="font-semibold">{service.title}</p>
                <p className="text-xs text-gray-600">{service.desc}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center mt-8">
          <button
            className="flex items-center justify-between gap-4 px-4 py-2 text-sm w-28 text-center text-white rounded bg-primary"
            onClick={() => navigate("/settings/subscriptions")}
          >
            <p>Upgrade</p>
            <FaLock size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GrowthPlan;
