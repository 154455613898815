/* eslint-disable array-callback-return */

import { BiSearch } from "react-icons/bi";
import { FiMoreVertical } from "react-icons/fi";
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp,
  HiStar,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import {
  PlatformRatingModel,
  ReviewClassModel,
  StoreModel,
  UserProfileModel,
} from "../../../models";
import { useAppSelector } from "../../../hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCustomerSatisfactionScore,
  getSevenDayAverage,
  updateReviewStatus,
} from "../../../api/Utils";
import { calcPercentage, classNames } from "../../../utils";
import { useEffect, useState } from "react";
import moment from "moment";
import { ImSpinner3 } from "react-icons/im";
import { getPlatformRatings } from "../../../api/Ratings";
import { Menu } from "@headlessui/react";
import { BsEyeSlash, BsEye, BsFillCircleFill } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";

interface ReviewStarsProps {
  reviews?: number;
  size?: number;
}

export const ReviewStars = ({ reviews = 5, size = 12 }: ReviewStarsProps) => {
  return (
    <div className="flex items-center justify-between">
      {[0, 1, 2, 3, 4].map((review, index) => {
        return (
          <HiStar
            key={index}
            size={size}
            className={classNames(
              index < reviews ? "text-yellow-500" : "text-gray-400"
            )}
          />
        );
      })}
    </div>
  );
};

interface RatingProgressBarProps {
  percentage: number;
}

const RatingProgressBar = ({ percentage = 100 }: RatingProgressBarProps) => {
  return (
    <div className="w-[180px] h-1 bg-gray-300 rounded-full">
      <div
        className="h-1 bg-yellow-500 rounded-full"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

interface RatingChartProps {
  total: number;
  rating: ReviewClassModel;
}

const RatingChart = ({ total, rating }: RatingChartProps) => {
  const one = calcPercentage(rating.one ?? 0, total);
  const two = calcPercentage(rating.two ?? 0, total);
  const three = calcPercentage(rating.three ?? 0, total);
  const four = calcPercentage(rating.four ?? 0, total);
  const five = calcPercentage(rating.five ?? 0, total);

  useEffect(() => {}, [total, rating]);

  return (
    <div className="space-y-0.5">
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/red-heart.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={100} />
        <span>{rating.five ?? 0} </span>
      </span>
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/thumbs-up.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={80} />
        <span>{rating.four ?? 0} </span>
      </span>
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/straight-face.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={60} />
        <span>{rating.three ?? 0} </span>
      </span>
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/thumbs-down.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={40} />
        <span>{rating.two ?? 0} </span>
      </span>
      <span className="flex items-center space-x-1 text-sm text-gray-400">
        <img src="/media/image/face-vomiting.png" alt="" className="w-[15px]" />
        <RatingProgressBar percentage={20} />
        <span>{rating.one ?? 0} </span>
      </span>
    </div>
  );
};

export const MoreButton = (props: {
  customer: string;
  public: boolean;
  toggleStatus: () => void;
}) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="mr-2">
        <FiMoreVertical size={18} className="fill-black" />
      </Menu.Button>
      <Menu.Items className="absolute z-10 right-2 p-1.5 mt-2 origin-top-right bg-white rounded-md shadow-lg w-44 ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <Link
              to={`/feedback-analysis/messages/new-message/${props.customer}`}
            >
              <p className="w-full p-2 text-xs font-semibold text-black rounded-md hover:bg-blue-200">
                Respond
              </p>
            </Link>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <p
              onClick={props.toggleStatus}
              className="w-full p-2 text-xs font-medium text-black bg-white rounded-md cursor-pointer hover:bg-blue-200"
            >
              {props.public ? "Hide on store page" : "Show on store page"}
            </p>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

const CustomerReview1 = () => {
  const dispatch = useDispatch();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const slugUrl: any = store.name;
  const queryKey = user.queryKey;
  let comments: any[] = [];
  let average_rating: PlatformRatingModel = {
    Seven_Days_Average: "",
    Trend_Sign: "",
  };
  let dateRange: string = `February 2023 - ${moment().format("MMM YYYY")}`;
  const [startDateTimeStamp, setStartDateTimeStamp] = useState("0.000");
  const [endDateTimeStamp, setEndDateTimeStamp] = useState(
    `${new Date(Date.now()).getTime() / 1000}`
  );
  const queryclient = useQueryClient();
  const [filter, setFilter] = useState(0);
  const [commentLabel, setCommentLabel] = useState("No Data");
  const [commentSource, setCommentSource] = useState("NONE");
  const [searchComment, setSearchComment] = useState("");
  const [currentReview, setCurrentReview] = useState("");

  let last_rating_overview: {
    rating?: string;
    status?: string;
  } = {
    status: "good",
    rating: "20.4%",
  };

  const handlefilterCommentLabelChange = (event: any) => {
    setCommentLabel(event.target.value);
  };

  const handlefilterCommentSourceSelect = (value: any) => {
    if (commentSource === "NONE") {
      setCommentSource(value + ",");
      return;
    }

    if (commentSource.includes(value)) {
      setCommentSource((prevState) => prevState.replace(value + ",", ""));
      return;
    }

    setCommentSource((prev) => `${prev + value + ","}`);
  };

  const handleClearFilter = (e: any) => {
    e.preventDefault();
    setCommentSource("NONE");
    setCommentLabel("No Data");
    setStartDateTimeStamp("0.000");
    setEndDateTimeStamp(`${new Date(Date.now()).getTime() / 1000}`);
    if (filter === 1) {
      setFilter(0);
      queryclient.refetchQueries(["getCustomerSatisfactionScore"]);
      return;
    }
    setFilter(0);
  };

  const ratings = useQuery<PlatformRatingModel, Error>({
    queryKey: ["getPlatformRatings"],
    queryFn: async () => {
      const response = await getPlatformRatings(`${queryKey}`);
      return response.data;
    },
  });

  const { status, data, error, isRefetching } = useQuery<any, Error>({
    queryKey: ["getCustomerSatisfactionScore", searchComment],
    queryFn: async () => {
      const payload = {
        platformKey: {
          account_reference: { S: queryKey },
        },
        pagination_key: "",
        timestamp: ["0.00", `${new Date(Date.now()).getTime() / 1000}`],
        commentSource: "NONE",
        commentLabel: "No Data",
      };

      const response = await getCustomerSatisfactionScore(payload);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log({ data });
      if (data === null || !data) {
        comments = [];
      } else {
        comments = data;
      }
    },
  });

  const averageRatings = useQuery<PlatformRatingModel, Error>({
    queryKey: ["getSevenDayAverage"],
    queryFn: async () => {
      const response = await getSevenDayAverage(queryKey);
      return response.data;
    },
  });

  const handleReviewToggle = useMutation({
    mutationFn: async (data) => {
      const payload = {
        commentId: data.commentId,
        statusUpload: data.status,
      };
      return await updateReviewStatus(payload);
    },
    onSuccess: (data: any, variables: any) => {
      queryclient.invalidateQueries({
        queryKey: ["getCustomerSatisfactionScore"],
      });
      toast.success(
        variables.status === "public" ? "Review added!" : "Review removed!"
      );
    },
  });

  if (data) {
    if ("pages" in data) {
      console.log(data);
      data.pages.forEach((page: any) => {
        if (page !== null) {
          if ("Reviewers_Board" in page) {
            comments = [...comments, ...page.Reviewers_Board];
          }
        }

        dateRange = `${moment(comments[0]?.uploadTime).format(
          "MMMM YYYY"
        )} - ${moment(comments[comments.length - 1]?.uploadTime).format(
          "MMMM YYYY"
        )}`;
      });
    } else {
      if ("Reviewers_Board" in data) {
        comments = [...data.Reviewers_Board, ...comments];
      }
    }
  }

  // console.log(comments);

  if (ratings.data) {
    if ("last_rating" in ratings.data) {
      last_rating_overview = {
        ...last_rating_overview,
        rating: `${ratings.data.last_rating.rating}%`,
      };
      if (ratings.data.last_rating.status === "bad") {
        last_rating_overview = {
          ...last_rating_overview,
          status: ratings.data.last_rating.status,
        };
      }
    }
  }

  if (averageRatings.data) {
    average_rating = averageRatings.data;
  }

  return (
    <div className="h-full min-h-[85vh] sm:h-[85vh] sm:overflow-y-auto space-y-4 flex flex-col">
      <div className="flex items-center justify-between">
        <h4 className="text-base font-bold text-black capitalize">Summary</h4>
        {comments.length > 0 && (
          <div className="md:hidden">
            <Link
              to="/feedback-analysis/customer-review/all"
              className="p-2 text-sm font-semibold text-center text-white rounded-md bg-primary"
            >
              See your reviews
            </Link>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-4 grid-x-0 md:gap-y-0 md:gap-x-6 lg:gap-x-14">
        <div className="flex flex-col justify-center px-4 py-6 space-y-4 font-bold text-center text-gray-500 border border-gray-400 rounded-md">
          <p className="text-xs uppercase">positive</p>
          <h5 className="text-4xl text-green-800">
            {status === "loading"
              ? "0"
              : status === "error"
              ? "0"
              : data.Total_Positive}
          </h5>
        </div>
        <div className="flex flex-col justify-center px-4 py-6 space-y-4 font-bold text-center text-gray-500 border border-gray-400 rounded-md">
          <p className="text-xs uppercase">negative</p>
          <h5 className="text-4xl text-red-800">
            {status === "loading"
              ? "0"
              : status === "error"
              ? "0"
              : data.Total_Negative}
          </h5>
        </div>
        <div className="flex flex-col justify-center px-4 py-6 space-y-4 font-bold text-center text-gray-500 border border-gray-400 rounded-md">
          <p className="text-xs uppercase">total reviews</p>
          <h5 className="text-4xl text-black">
            {status === "loading"
              ? "0"
              : status === "error"
              ? "0"
              : data.Total_Review}
          </h5>
        </div>
        <div className="px-2.5 py-2 flex flex-col justify-between font-bold text-center text-gray-500 border border-gray-400 rounded-md">
          <div className="flex items-end justify-between uppercase">
            <p className="text-xs">average rating</p>
            <p className="text-lg">
              {ratings.status === "loading"
                ? "0"
                : ratings.status === "error"
                ? "0"
                : ratings.data.average_ratings}
            </p>
          </div>
          <div className="flex-1 scale-75">
            <RatingChart
              total={
                ratings.status === "loading"
                  ? 0
                  : ratings.status === "error"
                  ? 0
                  : Number(ratings.data.total_reviews)
              }
              rating={
                ratings.status === "loading"
                  ? {}
                  : ratings.status === "error"
                  ? {}
                  : ratings.data.review_by_class ?? {}
              }
            />
          </div>
          <p className="flex items-end justify-start lg:justify-end space-x-0.5 lg:space-x-1 text-xs font-medium text-gray-400">
            <span>Last 7 days</span>
            <span
              className={classNames(
                average_rating.Trend_Sign !== "Red"
                  ? "text-green-400"
                  : "text-red-400"
              )}
            >
              {average_rating.Seven_Days_Average}{" "}
              {average_rating.Trend_Sign !== "Red" ? (
                <HiOutlineArrowNarrowUp size={12} className="inline-flex" />
              ) : (
                <HiOutlineArrowNarrowDown size={12} className="inline-flex" />
              )}
            </span>
          </p>
        </div>
      </div>
      <div className="flex-col hidden h-full overflow-hidden md:flex">
        <h4 className="mb-3 text-base font-bold text-black capitalize">
          Reviews
        </h4>
        <div className="items-center justify-between hidden mb-3 space-x-4 ">
          <div className="relative w-3/4">
            <input
              type="text"
              value={searchComment}
              onChange={(e) => setSearchComment(e.target.value)}
              placeholder="Search reviews"
              className="w-full px-4 py-2 text-sm font-medium text-gray-500 border border-gray-300 rounded-md outline-none"
            />
            <BiSearch
              size={24}
              className="absolute top-2 right-4 fill-gray-500 placeholder:text-gray-400"
            />
          </div>
          <button className="p-2 text-xs font-semibold text-gray-500 bg-transparent border border-gray-300 rounded-md lg:text-sm lg:px-3 lg:py-2">
            {dateRange}
          </button>
        </div>
        <div className="relative flex-1 h-full pt-2 pb-4 space-y-4 overflow-y-auto">
          {status === "loading" || (isRefetching && filter === 1) ? (
            <div className="flex items-center justify-start h-full py-5">
              <ImSpinner3
                size={18}
                className="mx-auto animate-spin fill-primary"
              />
            </div>
          ) : status === "error" ? (
            <span>Error: {error.message}</span>
          ) : (
            comments.length > 0 &&
            comments.map((comment: any, index: number) => {
              const similarity_score: string = "0.00";
              const source = comment.commentSource;
              const sentiment_tag: string = comment.commentLabel;

              return (
                <div
                  key={index}
                  className={classNames(
                    "space-y-1",
                    handleReviewToggle.isLoading &&
                      currentReview === comment.commentId
                      ? "opacity-40"
                      : "opacity-100"
                  )}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-1.5 text-xs font-bold">
                      <p className="text-black">
                        {comment.customer !== ""
                          ? comment.customer
                          : "Anonymous"}
                      </p>
                      <span>
                        {comment.reviewStatus === "" ||
                        comment.reviewStatus === "public" ? (
                          <BsEye size={12} />
                        ) : (
                          <BsEyeSlash size={12} />
                        )}
                      </span>
                      {/* <p className={classNames("px-2.5 py-0.5 font-medium text-white capitalize rounded-full", sentiment_tag === "NEG" ? "bg-red-600" : sentiment_tag === "POS" ? "bg-green-700"
                      : "bg-orange-500")}>{sentiment_tag === "NEG"
                      ? "Negative"
                      : sentiment_tag === "POS"
                      ? "Positive"
                      : "Undecided"} - 80%</p> */}
                      <BsFillCircleFill
                        size={8}
                        className={classNames(
                          sentiment_tag === "NEG"
                            ? "fill-red-600"
                            : sentiment_tag === "POS"
                            ? "fill-green-700"
                            : "fill-orange-500"
                        )}
                      />
                      {/* <div>{ratingsImage(comment.userRatings)}</div> */}
                    </div>
                    <div className="flex items-center md:items-start space-x-2.5 text-xs font-semibold">
                      <p className="text-black">
                        {moment(comment.uploadTime).format("MM/DD/YYYY")}
                      </p>
                      <MoreButton
                        customer={
                          comment.customer !== ""
                            ? comment.customer
                            : "Anonymous"
                        }
                        public={
                          comment.reviewStatus === "" ||
                          comment.reviewStatus === "public"
                        }
                        toggleStatus={() => {
                          setCurrentReview(comment.commentId);
                          handleReviewToggle.mutate({
                            commentId: comment.commentId,
                            status:
                              comment.reviewStatus === "" ||
                              comment.reviewStatus === "public"
                                ? "private"
                                : "public",
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-10/12 text-xs font-medium text-gray-500 truncate">
                    <p>{comment.userReview}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {comments.length === 0 && status !== "loading" && !isRefetching ? (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-lg text-gray-400">
              {filter === 1
                ? "No Review found"
                : "You don't have any reviews yet"}
            </p>
          </div>
        ) : null}
        {comments.length > 0 && (
          <div className="block w-full">
            <Link
              to="/feedback-analysis/customer-review/all"
              className="block w-24 py-1.5 mx-auto text-xs font-semibold text-center text-white capitalize rounded-md bg-primary"
            >
              See All
            </Link>
          </div>
        )}
      </div>
      <Toaster position="top-center" containerClassName="" gutter={200} />
    </div>
  );
};

export default CustomerReview1;
