import React from 'react'
import NewEstore from './NewEstore';
import EchoWebpage1 from './EchoWebpage1';
import { Toaster } from 'react-hot-toast';

const StoreCustomizer = () => {
  return (
    <div>
      <div className='customizer_css'>
      <NewEstore />
      </div>
      <div className='customizer_mobile_css'>
      <EchoWebpage1 />
      </div>
      <Toaster position="top-center" containerClassName="" gutter={200} />
    </div>
  )
}

export default StoreCustomizer;