import React from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";
import DeliveryMethods from "../../../Home/EchoWebpage/components/ProductsSection/DeliveryMethods";

const EstoreDeliveryEdit = () => {
  return (
    <>
      <div className="col-span-3 h-full bg-white p-3 hidden">
        <Link
          to="/estore/settings/delivery"
          className="flex items-center gap-2"
        >
          <MdOutlineKeyboardBackspace />
          <span className="text-gray-800 font-medium">Delivery rates</span>
        </Link>
        <div className="mt-4">
          <p className="text-sm">Add your delivery location and rates</p>
          <div className="space-y-4 mt-4">
            {[1, 2, 3].map((index: number) => (
              <div className="grid grid-cols-3 gap-6" key={index}>
                <input
                  type="text"
                  className="p-2 border border-gray-300 rounded col-span-2"
                />
                <input
                  type="text"
                  className="p-2 border border-gray-300 rounded"
                />
              </div>
            ))}
          </div>
          <div className="mt-2">
            <button className="py-2.5 rounded px-12 bg-[#184695] text-white text-sm">
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-span-3 h-full bg-white p-3">
        <DeliveryMethods />
      </div>
    </>
  );
};

export default EstoreDeliveryEdit;
