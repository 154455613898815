import { useState } from "react";
import Modal from "../../Dashboard/Modal";
import { Link, useLocation, useParams } from "react-router-dom";
import { uploadComplaint } from "../../../api/Utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMessage from "../../../components/Auth/Forms/InputMessage";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { ImSpinner8 } from "react-icons/im";
import { classNames, formatSocialUrl, formatStoreName } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import {
  getAboutUs,
  getOpeningHours,
  getSiteTitleAndTagLine,
  getStoreAddress,
  getStoreFooterDetails,
} from "../../../api/Menu";
import { Toaster } from "react-hot-toast";

const initialValues = {
  // name: "",
  email: "",
  phone: "",
  complaint: "",
};

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const validationSchema = Yup.object().shape({
  // name: Yup.string()
  // .min(3, "Minimum 3 letters")
  // .max(50, "Maximum 50 letters")
  // .required("Name is required"),
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  complaint: Yup.string().max(100).required("Please type in your complaint"),
});

const Footer = () => {
  const { salt, slug } = useParams();
  const [showComplaintModal, setShowComplaintModal] = useState(false);
  const [successComplaint, setSuccessComplaint] = useState(false);
  const queryKey = `/${salt}/${slug}`;
  const [allOpeningHours, setAllOpeningHours] = useState<any[]>([]);
  const location = useLocation();
  let aboutUs: string = "";

  let storeAddress = "32b ologun agbaje st, victoria island";

  let storeFooterDetails: any = {
    Contact_Number: "",
    Contact_Email: "",
    Linkedin_Url: "",
    Facebook_Url: "",
    Twitter_Url: "",
    Instagram_Url: "",
  };

  const storeName = formatStoreName(queryKey);
  let siteName: string = storeName;

  const siteTitleAndTagLine = useQuery<any, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(queryKey);
      return response.data;
    },
  });

  const allStoreFooterDetails = useQuery<any, Error>({
    queryKey: ["getFooterStoreDetails"],
    queryFn: async () => {
      const response = await getStoreFooterDetails(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data)
    },
  });

  const address = useQuery<any, Error>({
    queryKey: ["getStoreAddress"],
    queryFn: async () => {
      const response = await getStoreAddress(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  const openingHours = useQuery<any, Error>({
    queryKey: ["getOpeningHours"],
    queryFn: async () => {
      const response = await getOpeningHours(queryKey);
      return response.data;
    },
    onSuccess: (data: any) => {
      setAllOpeningHours([
        { ...data.Opening_Hours[0].Monday[0], day: "Monday" },
        { ...data.Opening_Hours[1].Tuesday[0], day: "Tuesday" },
        { ...data.Opening_Hours[2].Wednesday[0], day: "Wednesday" },
        { ...data.Opening_Hours[3].Thursday[0], day: "Thursday" },
        { ...data.Opening_Hours[4].Friday[0], day: "Friday" },
        { ...data.Opening_Hours[5].Saturday[0], day: "Saturday" },
        { ...data.Opening_Hours[6].Sunday[0], day: "Sunday" },
      ]);
    },
  });

  const storeAboutUs = useQuery<any, Error>({
    queryKey: ["getStoreAboutUs"],
    queryFn: async () => {
      const response = await getAboutUs(queryKey);
      return response.data;
    },
  });

  if (storeAboutUs.data) {
    aboutUs = storeAboutUs.data.About_Us;
    // console.log(aboutUs);
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      const data = {
        // platform_account: platFormId,
        // verified_email: userEmail,
        slug_url: queryKey,
        // complaint_name: values.name,
        complaint_phone: values.phone,
        complaint_email: values.email,
        complaint: values.complaint,
        complaint_source: "web",
      };

      const response = await uploadComplaint(data);
      // console.log({response});

      if (response.data.status_code === 200) {
        setSubmitting(false);
        resetForm({});
        setSuccessComplaint(true);
        setTimeout(() => {
          setShowComplaintModal(false);
          setSuccessComplaint(false);
        }, 2000);
        return;
      }

      setSubmitting(false);
    },
  });

  if (allStoreFooterDetails.data) {
    storeFooterDetails = allStoreFooterDetails.data;
    // console.log(storeFooterDetails);
  }

  if (address.data) {
    storeAddress = address.data.Store_Address;
  }

  if (siteTitleAndTagLine.data) {
    siteName = siteTitleAndTagLine.data.Site_Title;
  }


  // console.log(storeFooterDetails);

  return (
    <>
      <footer
        className={classNames(
          "bg-[#ECF0FF] font-dm-sans pt-16 pb-4",
          location.search.includes("reference") ? "hidden" : "",
          location.pathname.includes("checkout") ? "hidden" : ""
        )}
      >
        <div className="container text-gray-800 max-w-[1200px] mx-auto">
          <div className="space-y-10 lg:space-y-0 lg:flex gap-10 mb-4 pb-8 border-b-[1px] border-gray-300">
            <div className="space-y-3.5 col-span-1 lg:w-1/2 pr-4 lg:border-r-[1px] border-gray-300">
              <h6 className="text-2xl md:text-3xl font-semibold text-gray-800">
                {siteName || storeName}
              </h6>
              <div className="space-y-3">
                <p className="text-sm leading-5 text-gray-700 max-w-[450px]">
                  {storeAboutUs.status === "loading"
                    ? "Loading..."
                    : storeAboutUs.status === "error"
                    ? `Error: ${storeAboutUs.error.message}`
                    : aboutUs === "Tell your customers about yourself on your website." ? "" : aboutUs}
                </p>
              </div>
            </div>
            <div className="max-w-[700px] lg:w-1/2 flex flex-col sm:flex-row gap-10 justify-between ">
              <div className="space-y-3.5 col-span-1">
                {storeAddress !== "" && (
                  <h6 className="text-lg font-semibold text-gray-800" >
                    Contact us
                  </h6>
                )}

                <div className="space-y-3 text-gray-700">
                  <p className="text-sm">
                    {address.status === "loading"
                      ? "Loading..."
                      : address.status === "error"
                      ? `Error: ${address.error.message}`
                      : storeAddress}
                  </p>
                  <a
                    href={`tel:${storeFooterDetails.Contact_Phone}`}
                    target="_blank"
                    rel="noreferrer"
                    className="block text-sm"
                  >
                    {storeFooterDetails.Contact_Phone ?? ""}
                  </a>
                  <a
                    href={`mailto:${storeFooterDetails.Contact_Email}`}
                    target="_blank"
                    rel="noreferrer"
                    className="block text-sm"
                  >
                    {storeFooterDetails.Contact_Email ?? ""}
                  </a>
                  <div className="flex items-center mt-3 space-x-2.5 fill-white">
                    {storeFooterDetails.Facebook_Url !== "" && (
                      <a
                        href={formatSocialUrl(
                          `${
                            storeFooterDetails.Facebook_Url ??
                            "https://facebook.com/"
                          }`
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaFacebookF size={16} />
                      </a>
                    )}
                    {storeFooterDetails.Twitter_Url !== "" && (
                      <a
                        href={formatSocialUrl(
                          `${
                            storeFooterDetails.Twitter_Url ??
                            "https://twitter.com/"
                          }`
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsTwitter size={19} />
                      </a>
                    )}
                    {storeFooterDetails.Linkedin_Url !== "" && (
                      <a
                        href={formatSocialUrl(
                          `${
                            storeFooterDetails.Linkedin_Url ??
                            "https://linkedin.com/"
                          }`
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedinIn size={20} />
                      </a>
                    )}
                    {storeFooterDetails.Instagram_Url !== "" && (
                      <a
                        href={formatSocialUrl(
                          `${
                            storeFooterDetails.Instagram_Url ??
                            "https://instagram.com/"
                          }`
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsInstagram size={20} />
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="space-y-3.5 max-w-[250px]">
                {allOpeningHours.filter((day: any) => day.switch_button === "1")
                  .length > 0 && (
                  <h6
                    className={classNames(
                      "text-lg font-semibold text-gray-800"
                    )}
                  >
                    Opening hours
                  </h6>
                )}

                <div className="space-y-3 text-gray-700">
                  {allOpeningHours.filter(
                    (day: any, allIndex: number) => day.switch_button === "1"
                  ).length > 0 &&
                    allOpeningHours
                      .filter(
                        (day: any, allIndex: number) =>
                          day.switch_button === "1"
                      )
                      .map((day: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center gap-4 justify-between"
                          >
                            <div className="text-sm font-medium capitalize">
                              {day.day}
                            </div>
                            <div className="text-left">
                              <div className="flex items-center space-x-1 text-sm text-gray-800">
                                <p className="text-left border-gray-500 rounded-lg">
                                  {day.start_time}
                                </p>
                                <p>-</p>
                                <p className="text-left border-gray-500 rounded-lg">
                                  {day.close_time}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  {allOpeningHours.filter(
                    (day: any, allIndex: number) => day.switch_button === "1"
                  ).length === 0 && (
                    <div className="h-full text-center">
                      <p className="text-sm font-medium text-center text-gray-700"></p>
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-3.5 hidden">
                <h6 className="text-lg font-semibold text-gray-800">Socials</h6>
                <div className="space-y-3 text-gray-700">
                  <div className="flex flex-col gap-3 mt-3 font-medium text-sm">
                    {storeFooterDetails.Facebook_Url !== "" && (
                      <a
                        href={formatSocialUrl(
                          `${
                            storeFooterDetails.Facebook_Url ??
                            "https://facebook.com/"
                          }`
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Facebook
                      </a>
                    )}
                    {storeFooterDetails.Twitter_Url !== "" && (
                      <a
                        href={formatSocialUrl(
                          `${
                            storeFooterDetails.Twitter_Url ??
                            "https://twitter.com/"
                          }`
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Twitter
                      </a>
                    )}
                    {storeFooterDetails.Linkedin_Url !== "" && (
                      <a
                        href={formatSocialUrl(
                          `${
                            storeFooterDetails.Linkedin_Url ??
                            "https://linkedin.com/"
                          }`
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        LinkedIn
                      </a>
                    )}
                    {storeFooterDetails.Instagram_Url !== "" && (
                      <a
                        href={formatSocialUrl(
                          `${
                            storeFooterDetails.Instagram_Url ??
                            "https://instagram.com/"
                          }`
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="space-y-3.5">
                <h6 className="text-lg font-semibold text-gray-800">Business</h6>
                <div className="space-y-3">
                  <p
                    onClick={() => setShowComplaintModal(true)}
                    className="text-sm cursor-pointer whitespace-nowrap"
                  >
                    Leave a complaint
                  </p> 
                  <Link
                    to={`/store/${salt}/${slug}/order-status`}
                    className="block text-sm cursor-pointer"
                  >
                    Order status
                  </Link>
                  <Link to={`/store/${salt}/${slug}/cancel-order`} className="block text-sm cursor-pointer">Cancel order</Link>
                  
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center px-8 py-4 space-y-10 md:px-20 md:py-5 md:space-y-0 md:flex-row">
            <div className="text-base font-semibold text-gray-800">
              <p>
                Powered by{" "}
                <a
                  href="https://www.cusecho.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline underline-offset-2"
                >
                  Cusecho
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      <Modal
        show={showComplaintModal}
        onToggle={() => {
          formik.resetForm({});
          setShowComplaintModal(false);
        }}
        size="medium"
      >
        <div className="relative flex flex-col font-dm-sans">
          <div className="h-[30%] bg-[#ECF0FF] text-center text-gray-900 py-6 md:py-8">
            <h4 className="text-2xl font-semibold">Leave a complaint</h4>
            <p className="text-sm">We are looking to improve</p>
          </div>
          <div className="p-6 md:py-6 md:px-5">
            {!successComplaint && (
              <form className="space-y-4" onSubmit={formik.handleSubmit}>
                <div className="flex flex-col space-y-0.5">
                  <label className="text-[12.5px] text-gray-600">
                    Email address
                  </label>
                  <input
                    type="text"
                    {...formik.getFieldProps("email")}
                    placeholder="example@example.com"
                    className="px-4 py-2 text-black border border-gray-100 rounded-md outline-none placeholder:text-gray-500 placeholder:text-sm placeholder:font-light"
                  />
                  {formik.errors.email ? (
                    <small>
                      <InputMessage message={formik.errors.email} />
                    </small>
                  ) : null}
                </div>
                <div className="flex flex-col space-y-0.5">
                  <label className="text-[12.5px] text-gray-600">
                    Phone number
                  </label>
                  <input
                    type="text"
                    {...formik.getFieldProps("phone")}
                    placeholder="+XXXXXXXXXX"
                    className="px-4 py-2.5 text-gray-900 border border-gray-100 rounded-md outline-none placeholder:text-gray-500 placeholder:text-sm placeholder:font-light"
                  />
                  {formik.errors.phone ? (
                    <small>
                      <InputMessage message={formik.errors.phone} />
                    </small>
                  ) : null}
                </div>
                <div className="flex flex-col space-y-0.5">
                  <label className="text-[12.5px] text-gray-600">
                    Complaint
                  </label>
                  <textarea
                    rows={3}
                    {...formik.getFieldProps("complaint")}
                    placeholder="Please leave a complaint"
                    className="px-4 py-2.5 text-gray-900 border border-gray-100 rounded-md outline-none placeholder:text-gray-500 placeholder:text-sm placeholder:font-light"
                  ></textarea>
                  {formik.errors.complaint ? (
                    <small>
                      <InputMessage message={formik.errors.complaint} />
                    </small>
                  ) : null}
                </div>
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="text-sm w-2/3 py-3 text-white rounded bg-blue-700 mx-auto block"
                >
                  {formik.isSubmitting ? (
                    <ImSpinner8
                      size={24}
                      className="mx-auto animate-spin fill-white"
                    />
                  ) : (
                    "Submit complaint"
                  )}
                </button>
              </form>
            )}
            {successComplaint && (
              <div className="relative flex flex-col h-full">
                <div className="mt-2 text-center md:mt-4">
                  <img
                    src="/media/svg/check.svg"
                    alt="icon"
                    className="w-24 mx-auto"
                  />
                </div>
                <div className="mt-2 space-y-3.5 text-center md:mt-4">
                  <h1 className="font-semibold md:text-xl">
                    {"We've received your complaint"}
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Toaster position="top-center" containerClassName="" gutter={200} />
    </>
  );
};

export default Footer;
