import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IAuthState {
  isLoggedIn: boolean;
  sessionState: "active" | "expired";
  userData: { username: string, password: string };
  token: string;
  apiKey: string;

}

const initialState: IAuthState =  {
  isLoggedIn: false,
  sessionState: "expired",
  userData: { username: "", password: "" },
  token: "",
  apiKey: "",
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOGIN: (state, action: PayloadAction<{ username: string, password: string }>) => {
      state.isLoggedIn = true;
      if (action.payload.password && action.payload.username)
        state.userData = action.payload
        state.sessionState = "active"
    },
    LOGOUT: () => {
      return initialState;
    },
    EXPIRE_SESSION: (state) => {
      state.sessionState = "expired"
    },
    SET_TOKEN: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
    },
    SET_APIKEY: (state, action: PayloadAction<{ apiKey: string }>) => {
      state.apiKey = action.payload.apiKey;
    }
  }
});

export const { LOGIN, LOGOUT, EXPIRE_SESSION, SET_TOKEN, SET_APIKEY } = authSlice.actions;

export default authSlice.reducer;