// @ts-nocheck

import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { useDispatch } from "react-redux";
import { StoreModel, UserProfileModel } from "../../../../../../models";
import { useAppSelector } from "../../../../../../hooks";
import { useFormik } from "formik";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import UserPool from "../../../../../../setup/userpool";
import { SET_USER } from "../../../../../../redux/User";
import { FiChevronRight } from "react-icons/fi";
import { ImSpinner2 } from "react-icons/im";

const EstoreBusinessInformation = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const general: UserProfileModel = useAppSelector((state) => state.user);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const [loading, setLoading] = useState(false);

  let str: string;

  str = store.name.split("/") || "";
  var newstore = str[str.length - 1];
  newstore = newstore.replaceAll("-", " ");
  var newStore1 = newstore.split(" ");

  for (var i = 0; i < newStore1.length; i++) {
    newStore1[i] = newStore1[i].charAt(0).toUpperCase() + newStore1[i].slice(1);
  }
  const newStore2 = newStore1.join(" ");

  const initialValues1 = {
    fullName: user.fullName,
    country: store.country ?? "",
    employeeNumber: store.employeeNumber ?? "",
    phoneNumber: store.phoneNumber === "N/A" ? "" : store.phoneNumber ?? "",
    storeName: store.name === "N/A" ? "" : newStore2 ?? "",
    address: store.address === "N/A" ? "" : store.address ?? "",
    currency: "Nigeria naira",
  };

  const formik = useFormik({
    initialValues: initialValues1,
    // validationSchema: completeProfile1Schema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      // console.log(values);
      setSubmitting(true);
      setLoading(true);
      // setError("");
      await new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(async (err, session) => {
            if (err) {
              reject();
            } else {
              const attributes = await new Promise((resolve, reject) => {
                user.getUserAttributes((err, attributes) => {
                  if (err) {
                    reject(err);
                  } else {
                    const results = {};

                    for (let attribute of attributes) {
                      const { Name, Value } = attribute;
                      results[Name] = Value;
                    }

                    resolve(results);
                  }
                });
              });

              resolve({
                user,
                ...session,
                ...attributes,
              });
            }
          });
        } else {
          reject();
        }
      }).then(({ user }) => {
        // console.log(user);
        const attributes = [
          new CognitoUserAttribute({ Name: "address", Value: values.address }),
          new CognitoUserAttribute({
            Name: "locale",
            Value: values.country,
          }),
          // new CognitoUserAttribute({
          //   Name: "name",
          //   Value: values.firstName,
          // }),
          new CognitoUserAttribute({
            Name: "phone_number",
            Value: values.phoneNumber,
          }),

          // new CognitoUserAttribute({
          //   Name: "given_name",
          //   Value: values.storeName,
          // }),

          new CognitoUserAttribute({
            Name: "custom:employee",
            Value: values.employeeNumber.toString(),
          }),
        ];

        const userData: UserProfileModel = {
          ...user,
          // firstName: values.firstName,
          lastName: values.lastName,
          address: values.address,
          country: values.country,
        };

        const storeData: StoreModel = {
          ...store,
          address: values.address,
          country: values.country,
          currency: store.currency,
          phoneNumber:
            values.phoneNumber === "N/A" ? "N/A" : values.phoneNumber,
          employeeNumber: values.employeeNumber,
        };

        user.updateAttributes(attributes, (err, result) => {
          if (err) {
            setSubmitting(false);
            setLoading(false);
          } else {
            setSubmitting(false);
            setLoading(false);
            dispatch(SET_USER({ ...general, store: storeData }));
            setStep(1);
          }
        });
      });
    },
  });

  return (
    <>
      {step === 1 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800"
            // onClick={() => setStep(2)}
          >
            Business details
          </p>
          <div className="mt-4">
            <p className="text-blue-700">Business information</p>
            <div className="flex items-center justify-between">
              <div className="text-sm space-y-1 mt-4">
                <p>Business name</p>
                <p className="text-gray-600">{user.fullName}</p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  setStep(2);
                }}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm space-y-1 mt-4">
                <p>Address</p>
                <p className="text-gray-600">{store.address}</p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  setStep(2);
                }}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm space-y-1 mt-4">
                <p>State</p>
                <p className="text-gray-600">{store.country}</p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  setStep(2);
                }}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm space-y-1 mt-4">
                <p>Country</p>
                <p className="text-gray-600">{store.country}</p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  setStep(2);
                }}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm space-y-1 mt-4">
                <p>Currency</p>
                {/* <p className="text-gray-600">{store.currency}</p> */}
                <p className="text-gray-600">Nigeria naira</p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  setStep(2);
                }}
              />
            </div>
          </div>
          <div className=" space-y-2 text-sm mt-4 py-2 hidden">
            <p className="text-blue-600">Store preference</p>
            <p>E-Kiosk</p>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(1)}
          >
            <MdKeyboardBackspace className="" />
            Back
          </p>
          <form className="mt-4" onSubmit={formik.handleSubmit}>
            <p className="text-blue-700">Business information</p>
            <div className="text-sm space-y-0.5 mt-4">
              <p className="text-xs">Business name</p>
              <input
                type="text"
                placeholder={user.fullName}
                {...formik.getFieldProps("fullName")}
                disabled
                className="p-2 border border-gray-300 rounded w-[450px] outline-none"
              />
              {formik.errors.fullName ? (
                <p className="text-red-600 text-[13px]">
                  {formik.errors.fullName}
                </p>
              ) : null}
            </div>
            <div className="text-sm space-y-0.5 mt-4">
              <p className="text-xs">Address</p>
              <input
                type="text"
                placeholder={store.address}
                {...formik.getFieldProps("address")}
                className="p-2 border border-gray-300 rounded w-[450px] outline-none"
              />
              {formik.errors.address ? (
                <p className="text-red-600 text-[13px]">
                  {formik.errors.address}
                </p>
              ) : null}
            </div>
            <div className="text-sm space-y-0.5 mt-4">
              <p className="text-xs">State</p>
              <input
                type="text"
                {...formik.getFieldProps("address")}
                className="p-2 border border-gray-300 rounded w-[450px] outline-none"
              />
              {formik.errors.address ? (
                <p className="text-red-600 text-[13px]">
                  {formik.errors.address}
                </p>
              ) : null}
            </div>
            <div className="text-sm space-y-0.5 mt-4">
              <p className="text-xs">Country</p>
              <input
                type="text"
                placeholder={store.country}
                {...formik.getFieldProps("country")}
                className="p-2 border border-gray-300 rounded w-[450px] outline-none"
              />
              {formik.errors.country ? (
                <p className="text-red-600 text-[13px]">
                  {formik.errors.country}
                </p>
              ) : null}
            </div>
            <div className="text-sm space-y-0.5 mt-4">
              <p className="text-xs">Currency</p>
              <input
                type="text"
                placeholder={store.currency}
                {...formik.getFieldProps("currency")}
                disabled
                className="p-2 border border-gray-300 rounded w-[450px] outline-none"
              />
              {formik.errors.currency ? (
                <p className="text-red-600 text-[13px]">
                  {formik.errors.currency}
                </p>
              ) : null}
            </div>

            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="bg-blue-600 text-white rounded p-2.5 px-6 mt-4"
            >
              {formik.isSubmitting ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Update"
              )}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EstoreBusinessInformation;
