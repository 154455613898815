import React from "react";
import { BiChevronLeft, BiChevronUp } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import Modal from "../../../../../../components/Dashboard/Modal";

const AllRoomsPage = () => {
  const [dropdown1, setDropdown1] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Modal show={showDeleteModal}>
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-4">
            <FiTrash2 size={50} className="mx-auto text-red-700 w-28" />
          </div>
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-xl">
              You are about to delete this item from your menu
            </p>
          </div>
          <div className="flex justify-center gap-2 mt-5 md:mt-8">
            <div>
              <button
                className="px-4 py-2 text-sm font-semibold text-center text-white bg-red-700 border rounded-md w-44 md:px-6 lg:px-8"
                onClick={() => setShowDeleteModal(false)}
                // onClick={(e) => {
                //   handleKPIDelete.mutate({
                //     e,
                //     tag_name: currentMetric.tag_name,
                //     archive_status: archiveStatus ? "1" : "0",
                //     question_tag: currentMetric.question_tag,
                //   });
                // }}
              >
                {/* {handleKPIDelete.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )} */}
                Delete
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 text-sm font-semibold text-center text-gray-800 bg-white border rounded-md w-44 md:px-6 lg:px-8"
                onClick={() => setShowDeleteModal(false)}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="space-y-2 max-w-[80%] mx-auto">
        <div className="flex items-center gap-3 py-1">
          <div
            className="p-2 text-gray-800 bg-white rounded cursor-pointer"
            onClick={() => navigate("/home/echo-booking")}
          >
            <BiChevronLeft />
          </div>
          <h4 className="text-xl text-gray-400 capitalize">View Rooms</h4>
        </div>
        <div className="py-4 px-8 bg-white rounded shadow-md space-y-4 h-[80vh] overflow-y-auto">
          <p className="text-center text-xl font-semibold">Hotel Rooms</p>
          <div className="space-y-3">
              {[1, 2, 3, 4, 5].map((index) => (
               <div className="flex items-center gap-4" key={index}>
               <div className="h-[50px] w-[50px] bg-gray-100 rounded-md"></div>
               <div className="">
                 <div className="flex items-center gap-2">
                   <p className="text-sm font-semibold">Standard Room</p>
                   <p className="text-xs">$120/Night</p>
                 </div>
                 <p className="text-xs">
                   Contains 1 Bed, 2 Chairs, 1 Bathroom and a Tv set
                 </p>
               </div>
              <div className="ml-auto flex items-center gap-4" >
              <AiOutlineEdit
                //  onClick={() => setShowDeleteModal(true)}
                 size={24}
                 className="cursor-pointer"
               />
              <MdDeleteOutline
                 onClick={() => setShowDeleteModal(true)}
                 size={24}
                 className="cursor-pointer"
               />
              </div>
             </div>
              ))}
            </div>
        </div>
      </div>
    </>
  );
};

export default AllRoomsPage;
