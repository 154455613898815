import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { UserProfileModel } from "../../../../../../models";
import { useAppSelector } from "../../../../../../hooks";
import { useNavigate } from "react-router-dom";
import { getOrders } from "../../../../../../api/Menu";
import { getRelativeTime } from "../../../../../../utils";

const OrdersHomerSummary = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = `${user.queryKey}`;
  const platformKey = queryKey.replace("%26", "&");
  const navigate = useNavigate();
  const [open, setOpen] = useState(-1);
  let orderNotifications: any[] = [];
  let queueIds: any[] = [];

  let orders: any = [];

  const allOrders = useQuery<any, Error>({
    queryKey: ["getOrders"],
    queryFn: async () => {
      const paginationKey = "";
      const response = await getOrders(queryKey, paginationKey, "Pending");
      return response.data;
    },
  });

  if (allOrders.data) {
    const nonCompletedOrders = allOrders.data.Order_Catalog.filter(
      (c: any) => c.orderStatus !== "Completed"
    );

    const nonCancelled = nonCompletedOrders.filter(
      (c: any) => c.orderStatus !== "Cancelled"
    );
    orders = nonCancelled.slice(0, 3);
    console.log(orders);
  }

  return (
    <div>
      <p className="text-sm font-semibold mb-2.5">Orders</p>
      <div className="space-y-2">
        {orders.map((order: any, index: number) => (
          <div className="p-2 bg-white rounded-md" key={index}>
            <div className="flex items-center justify-between">
              <p className="text-sm mb-1">{order.billingDetail.fullName}</p>
              <p className="text-xs text-gray-500">{getRelativeTime(order.creationTimestamp)}</p>
            </div>
            <p className="text-xs text-blue-600">
              Order received from {order.billingDetail.fullName}
            </p>
            {order.products.map((item: any, index: number) => (
              <p className="text-xs py-2" key={index}>
                {`${index + 1}. ${item.itemName} x${item.quantityOrder}`}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrdersHomerSummary;
