// @ts-nocheck

import { MdDeleteOutline, MdOutlineCancelPresentation } from "react-icons/md";
import { useEffect, useState } from "react";
import { FiTrash2, FiUpload } from "react-icons/fi";
import { BiChevronLeft, BiChevronUp } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import Compressor from "compressorjs";
import { AiOutlineEdit } from "react-icons/ai";
import { classNames } from "../../../../../utils";
import Modal from "../../../../../components/Dashboard/Modal";
import InputMessage from "../../../../../components/Auth/Forms/InputMessage";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeHolder: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
}

const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
}: InputProps) => {
  return (
    <>
      <label className="block mb-1 text-xs font-normal text-gray-800">
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        disabled={disabled}
        className={classNames(
          className,
          "border border-gray-300 py-2 px-5 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm"
        )}
      />
    </>
  );
};

const initialValues = {
  roomName: "",
  details: "",
  price: "",
};

const EchoReservationPage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, formikHelpers) => {
      setShowModal(true);
      console.log(values);
    },
  });

  const handleCompressedUpload = (e: any) => {
    const image = e.target.files[0];
    setSelectedImage(e.target.files[0]);
    // new Compressor(image, {
    //   quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
    //   success: (compressedResult) => {
    //     console.log(compressedResult);
    //     // compressedResult has the compressed file.
    //     // Use the compressed file to upload the images to your server.
    //     // setCompressedFile(compressedResult);
    //   },
    // });
  };

  // useEffect(() => {
  //   if (!selectedImage) {
  //     setPreview(null);
  //     return;
  //   }

  //   const objectUrl = URL.createObjectURL(selectedImage);
  //   setPreview(objectUrl);

  //   // free memory when ever this component is unmounted
  //   return () => URL.revokeObjectURL(objectUrl);
  // }, [selectedImage]);

  return (
    <>
      <Modal show={showDeleteModal}>
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-4">
            <FiTrash2 size={50} className="mx-auto text-red-700 w-28" />
          </div>
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-xl">
              You Are About To Delete This Room From Your List.
            </p>
          </div>
          <div className="flex justify-center gap-2 mt-5 md:mt-8">
            <div>
              <button
                className="px-4 py-2 text-sm font-semibold text-center text-white bg-red-700 border rounded-md w-44 md:px-6 lg:px-8"
                onClick={() => setShowDeleteModal(false)}
                // onClick={(e) => {
                //   handleKPIDelete.mutate({
                //     e,
                //     tag_name: currentMetric.tag_name,
                //     archive_status: archiveStatus ? "1" : "0",
                //     question_tag: currentMetric.question_tag,
                //   });
                // }}
              >
                {/* {handleKPIDelete.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )} */}
                Delete
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 text-sm font-semibold text-center text-gray-800 bg-white border rounded-md w-44 md:px-6 lg:px-8"
                onClick={() => setShowDeleteModal(false)}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={showModal}>
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img
              src="/media/svg/check.svg"
              alt="icon"
              className="mx-auto w-24"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-2xl">
              You Have Successfully Added To The Menu
            </h1>
            <p className="text-gray-500 md:text-sm">
              This Item Will Now Reflect On The Menu.
            </p>
          </div>
          <span
            className="absolute cursor-pointer top-2 right-2"
            onClick={() => setShowModal((prevState) => !prevState)}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                fill="#5A5A5A"
              />
            </svg>
          </span>
        </div>
      </Modal>
      <div className="space-y-2 max-w-[80%] mx-auto">
        <div className="flex items-center gap-3 py-1">
          <div
            className="p-2 text-gray-800 bg-white rounded cursor-pointer"
            onClick={() => navigate("/home")}
          >
            <BiChevronLeft />
          </div>
          <h4 className="text-xl text-gray-400 capitalize">Echo Booking</h4>
        </div>
        <div className="py-4 px-8 bg-white rounded shadow-md flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Echo Reservation Setup</p>
            <p className="text-xs text-gray-500">
              Allow Your Customers Make Reservations From The QR Scanner
            </p>
          </div>
          <button
            onClick={() => navigate("/home/echo-booking/rooms")}
            className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2"
          >
            View Rooms
          </button>
        </div>
        <div className="py-4 px-8 bg-white rounded shadow-md ">
          <p className="font-semibold">
            Upload URL{" "}
            <span className="text-gray-500 font-normal text-sm">
              (Optional)
            </span>
          </p>
          <p className="text-xs text-gray-500">
            If You Have A Funtioning Booking Site, Upload Your Current URl here
          </p>
          <input
            type="text"
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-3"
            placeholder="Enter URL"
          ></input>
        </div>
        <div className="py-4 px-8 bg-white rounded shadow-md ">
          <div className="flex items-center justify-between">
            <div className="">
              <p className="font-semibold">Add Rooms</p>
              <p className="text-xs text-gray-500">
                Add Available Rooms At Your Hotel
              </p>
            </div>
            <BiChevronUp
              className={`${
                dropdown ? "" : "rotate-180 transform"
              } cursor-pointer`}
              size={25}
              onClick={() => setDropdown((prevState) => !prevState)}
            />
          </div>
          {dropdown && (
            <form className="my-4">
              <div className="space-y-3">
                <div className="w-full">
                  <Input
                    formik={formik}
                    name="roomName"
                    label="Room Name"
                    type="text"
                    placeHolder="Enter Room Name"
                  />
                  {formik.values.roomName && formik.errors.roomName && (
                    <InputMessage message={formik.errors.roomName} />
                  )}
                </div>
                <div className="w-full">
                  <Input
                    formik={formik}
                    name="details"
                    label="Details"
                    type="text"
                    placeHolder="Enter Item Details"
                  />
                  {formik.values.details && formik.errors.details && (
                    <InputMessage message={formik.errors.details} />
                  )}
                </div>
                <div>
                  <label className="block mb-1 text-xs font-normal text-gray-800">
                    Image
                  </label>
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-64 border border-gray-300 rounded-md cursor-pointer bg-gray-white "
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          className="w-8 h-8 mb-4 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500">SVG, PNG or JPG</p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(event) => handleCompressedUpload(event)}
                      />
                    </label>
                  </div>
                  {/* {!selectedImage || !preview ? (
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-64 border border-gray-300 rounded-md cursor-pointer bg-gray-white "
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-gray-500"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500">
                            SVG, PNG or JPG
                          </p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          accept="image/*"
                          onChange={(event) => handleCompressedUpload(event)}
                        />
                      </label>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={preview}
                        alt=""
                        className="w-full h-64 object-cover"
                      />
                      <div className="flex justify-end mt-6">
                        <div className="flex items-center gap-4">
                          <button
                            type="submit"
                            className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-16 py-2 "
                            onClick={(e) => {
                              e.preventDefault();
                              setShowModal(true);
                            }}
                          >
                            Save Room
                          </button>
                          <button
                            type="submit"
                            className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-16 py-2 "
                            onClick={(e) => {
                              e.preventDefault();
                              setShowModal(true);
                            }}
                          >
                            Upload Image
                          </button>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>

                <div className="w-full">
                  <Input
                    formik={formik}
                    name="price"
                    label="Price"
                    type="number"
                    placeHolder="Enter Item Price"
                  />
                  {formik.values.price && formik.errors.price && (
                    <InputMessage message={formik.errors.price} />
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center mt-12 mb-8">
                <button
                  type="submit"
                  className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-16 py-2 "
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}
                >
                  Save Room
                </button>
              </div>
            </form>
          )}
        </div>
        <div className="py-4 px-8 bg-white rounded shadow-md space-y-4">
          <div className="flex items-center justify-between">
            <div className="">
              <p className="font-semibold">All Items</p>
              <p className="text-xs text-gray-500">
                View All Items Saved On Your Menu
              </p>
            </div>
            <BiChevronUp
              size={25}
              className={`${
                dropdown1 ? "" : "rotate-180 transform"
              } cursor-pointer`}
              onClick={() => setDropdown1((prevState) => !prevState)}
            />
          </div>
          {dropdown1 && (
            <div className="space-y-3">
              {[1, 2, 3, 4, 5].map((index) => (
                <div className="flex items-center gap-4" key={index}>
                  <div className="h-[50px] w-[50px] bg-gray-100 rounded-md"></div>
                  <div className="">
                    <div className="flex items-center gap-2">
                      <p className="text-sm font-semibold">Standard Room</p>
                      <p className="text-xs">$120/Night</p>
                    </div>
                    <p className="text-xs">
                      Contains 1 Bed, 2 Chairs, 1 Bathroom and a Tv set
                    </p>
                  </div>
                  <div className="ml-auto flex items-center gap-4">
                    <AiOutlineEdit
                      //  onClick={() => setShowDeleteModal(true)}
                      size={24}
                      className="cursor-pointer"
                    />
                    <MdDeleteOutline
                      onClick={() => setShowDeleteModal(true)}
                      size={24}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EchoReservationPage;
