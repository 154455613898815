// @ts-nocheck

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState, Fragment } from "react";
import { StoreModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import {
  BASE_URL_WEBPAGE_IMAGE,
  getAllCategories,
  getAllMenuCategories,
  uploadSingleMenu,
} from "../../../../api/Menu";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../../components/Dashboard/Modal";
import {
  MdChevronRight,
  MdClear,
  MdInfoOutline,
  MdOutlineChevronRight,
  MdOutlineDelete,
  MdOutlineKeyboardBackspace,
} from "react-icons/md";
import { classNames, getImage } from "../../../../utils";
import { Input } from "../Home/EchoWebpage/EchoWebpage1";
import InputMessage from "../../../../components/Auth/Forms/InputMessage";
import { Combobox, Menu, Transition } from "@headlessui/react";
import {
  AiOutlineCloudUpload,
  AiOutlineEdit,
  AiOutlinePlus,
} from "react-icons/ai";
import { ImCancelCircle, ImSpinner2 } from "react-icons/im";
import { FiPlus, FiUpload } from "react-icons/fi";
import { BiChevronDown } from "react-icons/bi";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import { IoArrowBackOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";

const initialValues = {
  itemName: "",
  details: "",
  price: "",
  category: "",
  available_count: "",
  discountPrice: "",
};

const itemSchema = Yup.object().shape({
  itemName: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Item name is required"),
  details: Yup.string()
    .min(5, "Minimum 5 letters")
    .max(140, "Maximum 140 letters"),
  price: Yup.string().required("Price is required"),
  category: Yup.string(),
  available_count: Yup.number(),
  discountPrice: Yup.number(),
});

const AddProduct = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [generalInputs, setGeneralInputs] = useState([
    { variant_type: "", children: [""] },
  ]);
  const [selected, setSelected] = useState({ id: "", name: "" });
  const [imagesArray, setImagesArray] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedItem, setSelectedItem] = useState({});
  const [itemVariations, setItemVariations] = useState([]);
  const [showInfoDetails, setShowInfoDetails] = useState(false);

  const [inputValues, setInputValues] = useState({
    price: "",
    discountPrice: "",
    stock: 0,
    color: "",
    size: "",
  });

  let result = [];

  let categories = [];

  const allCategories = useQuery<any, Error>({
    queryKey: ["getAllCategories"],
    queryFn: async () => {
      const response = await getAllCategories();
      return response.data;
    },
    onSuccess: (data) => {},
  });

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: itemSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      // console.log(values);
      setSubmitting(true);
      // if (storeItemCount > 24) {
      //   setShowModal(true)
      //   return;
      // }

      if (imagesArray.length < 1) {
        alert("Default Image is compulsory");
        return;
      }

      const cloudfront = "https://d1ntmcvl0pjnmq.cloudfront.net";

      const payload = {
        platformKey,
        slugUrl: storeKey,
        itemName: values.itemName.trim(),
        available_stock: values.available_count,
        menuItemId: "None",
        s3ImageUrl: imagesArray[0].replace(cloudfront, "/cusecho") || "",
        s3ImageUrlOne: imagesArray[1]?.replace(cloudfront, "/cusecho") || "",
        s3ImageUrlTwo: imagesArray[2]?.replace(cloudfront, "/cusecho") || "",
        s3ImageUrlThree: imagesArray[3]?.replace(cloudfront, "/cusecho") || "",
        itemPrice: values.price.toString(),
        description: values.details.trim(),
        itemCategory: values.category,
        itemPriceDigit: values.price.toFixed(2),
        uploadTimeStamp: Date.now().toFixed(2),
        uploadTime: "None",
        itemUrl: "None",
        uploadFlag: "create",
        freeDelivery: "0",
        itemSizes: [],
        itemColors: [],
        // itemVariations: result,
        // itemVariations:
        //   itemVariations.map((item) => {
        //     const { variantId, ...newItem } = item; // Destructure and omit variantId
        //     return newItem;
        //   }) || [],
        itemVariations:
          itemVariations.map((item) => {
            const { variantId, discountPrice, price, ...newItem } = item; // Destructure and omit variantId
            return {
              ...newItem,
              price: price === "" ? Number(values.price) : price,
              discountPrice:
                discountPrice === ""
                  ? Number(values.discountPrice)
                  : discountPrice,
            };
          }) || [],
        discountPrice: values.discountPrice,
      };

      // console.log(payload);

      try {
        const response = await uploadSingleMenu(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries(["getStoreItemCount"]);
          queryClient.invalidateQueries("getAllMenuCategories");
          queryClient.invalidateQueries("getMenuByCategory");
          navigate("/estore/products?type=items");
          setSubmitting(false);
          setPreview(null);
          setSuccess(true);
          resetForm({});
          setImagesArray([]);
          setItemSizes([]);
          setItemColors([]);
          setItemVariations([]);
          
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
           // Redirect to products page
          return;
        }
        setError(response.data.message);
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  const imageHandler = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    setSelectedImage(e.target.files[0]);
  };

  const handleCompressedUpload = async (file: File) => {
    setIsLoading(true);
    if (selectedImage?.size > 2072576) {
      alert("Please select an image with a size of less than or equal to 2MB");
      setSelectedImage(null);
      setIsLoading(false);
      return;
    }

    new Compressor(file, {
      quality: 0.2,
      success: (compressedResult: any) => {
        let path = "/cusecho" + storeKey + "/products/" + uuidv4();
        const category = selected.name.replace(/[^a-zA-Z0-9 ]/g, "");

        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };

        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log(data);
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then((res) => {
                const cloudfront = "https://d1ntmcvl0pjnmq.cloudfront.net";
                const imagePreview = cloudfront + path.replace("/cusecho", "");
                setImagesArray([...imagesArray, imagePreview]);
                setImageUploadSuccess(true);
                setSelectedImage(null);
                setPreview(null);
                setTimeout(() => {
                  setImageUploadSuccess(false);
                }, 2000);
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };

  if (allCategories.data) {
    categories = allCategories.data;
  }

  useEffect(() => {
    if (!selectedImage) {
      setPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setPreview(objectUrl);
    // return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const handleAddInput = (index) => {
    // setGeneralInputs([...generalInputs, { variant_type: "", children: [""] },]);
    setGeneralInputs(
      generalInputs.map((item, i) =>
        i === index ? { ...item, children: [...item.children, ""] } : item
      )
    );
  };

  const handleAddVariant = () => {
    setGeneralInputs([...generalInputs, { variant_type: "", children: [""] }]);
  };

  const handleChange = (event, index, parentIndex) => {
    let { name, value } = event.target;
    const newArray = [...generalInputs[parentIndex].children];
    newArray[index] = value;
    // setGeneralInputs(prev => [{...prev, children: [...prev.chreild] }]);
    setGeneralInputs(
      generalInputs.map((item, index) =>
        index === parentIndex ? { ...item, children: newArray } : item
      )
    );
  };

  const handleVariantType = (value, parentIndex) => {
    setGeneralInputs(
      generalInputs.map((item, index) =>
        index === parentIndex ? { ...item, variant_type: value } : item
      )
    );
  };

  const handleDeleteInput = (childIndex, parentIndex) => {
    const newArray = [...generalInputs[parentIndex].children];
    newArray.splice(childIndex, 1);

    setGeneralInputs(
      generalInputs.map((item, index) =>
        index === parentIndex ? { ...item, children: newArray } : item
      )
    );
  };

  if (
    generalInputs.find((x) => x.variant_type === "Size") ||
    generalInputs.find((x) => x.variant_type === "Color")
  ) {
    const size = generalInputs.find((x) => x.variant_type === "Size")
      .children || [""];
    var color = generalInputs.find((x) => x.variant_type === "Color")
      ?.children || [""];

    result = size.reduce((prev, current) => {
      var list = color.map((c, index) => {
        return {
          size: current,
          color: c,
          price: "",
          discountPrice: "",
          stock: 0,
          variantId: uuidv4(),
        };
      });
      return prev.concat(list);
    }, []);
  }

  const uniqueColors = new Set(
    itemVariations.map((variation) => variation.color).filter((x) => !!x)
  );

  const uniqueSizes = new Set(
    itemVariations.map((variation) => variation.size).filter((x) => !!x)
  );

  console.log(generalInputs);

  return (
    <>
      <Modal
        size="medium"
        show={showModal}
        onToggle={() => {
          setShowModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 ">
          <div className="text-center">
            <img
              src={getImage("connection-error")}
              alt="icon"
              className="w-24 mx-auto"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-lg">
              Maximum of 25 products allowed.
            </h1>
            <p>
              To increase this limit, contact our{" "}
              <Link
                to="/support/limit-increase"
                className="text-red-600 hover:text-red-800"
              >
                support team.
              </Link>
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        size="medium"
        show={showModal1}
        onToggle={() => {
          setShowModal1(false);
        }}
      >
        {step === 1 && (
          <div className="relative flex flex-col p-6 space-y-3">
            <div className="flex items-center gap-3">
              <p className="text-sm font-semibold">Variations</p>
            </div>

            {generalInputs.map((item: any, index: number) => (
              <div key={index}>
                <form className="w-full mx-auto">
                  <label
                    htmlFor="variation"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select an option
                  </label>
                  <select
                    id="variation"
                    onChange={(e) => {
                      // setVariantType(e.target.value);
                      handleVariantType(e.target.value, index);
                      // setGeneralInputs(prev => [{...prev, variant_type: e.target.value }]);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  >
                    <option defaultValue="Select variant type">
                      Select variant type
                    </option>
                    <option value="Size">Size</option>
                    <option value="Color">Color</option>
                  </select>
                </form>

                {item.children.map((child, childIndex) => (
                  <div className="mt-2.5" key={childIndex}>
                    <div className="w-full relative">
                      <input
                        name="variant_attribute"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        type="text"
                        value={child}
                        onChange={(event) =>
                          handleChange(event, childIndex, index)
                        }
                      />
                      <button
                        className="absolute inset-y-0 end-0 flex items-center px-3 cursor-pointer"
                        onClick={() => handleDeleteInput(childIndex, index)}
                      >
                        <MdOutlineDelete />
                      </button>
                    </div>
                  </div>
                ))}

                <div
                  className="flex items-center gap-2 font-light cursor-pointer mt-3"
                  onClick={() => handleAddInput(index)}
                >
                  <FaPlus /> <p className="text-sm">Add new variant</p>
                </div>
              </div>
            ))}

            <div className="flex items-center gap-8 w-full mt-12">
              <button
                className={classNames(
                  "text-sm p-2.5 border border-blue-500 rounded-md text-blue-500 w-1/2",
                  generalInputs.length < 2 ? "" : "opacity-0"
                )}
                // onClick={() => setGeneralInputs((prev) => [...prev, inputs])}
                onClick={() => handleAddVariant()}
              >
                New variant type
              </button>
              <button
                className="text-white text-sm p-2.5 border bg-blue-500 border-blue-500 rounded-md w-1/2"
                onClick={() => {
                  setItemVariations(result);
                  setStep(2);
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="relative flex flex-col p-6 space-y-3">
            <div className="flex items-center justify-between">
              <div
                className="flex items-center gap-3 cursor-pointer"
                // onClick={() => setStep(1)}
              >
                {/* <IoArrowBackOutline /> */}
                <p className="text-sm font-semibold">Variant option</p>
              </div>
              <AiOutlineEdit
                size={20}
                className="cursor-pointer"
                onClick={() => {
                  setStep(1);
                }}
              />
              {deleteMode ? (
                <p
                  className="text-sm font-semibold cursor-pointer hidden"
                  onClick={() => setDeleteMode(false)}
                >
                  Done
                </p>
              ) : (
                <div className="flex items-center gap-2 ml-auto hidden">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="w-full text-blue-800 mt-1">
                        <IoMdMore />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-1 w-36 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-10">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-slate-100 font-semibold"
                                    : "text-gray-900 font-medium"
                                } w-full rounded-md px-4 py-2 text-xs text-left`}
                                onClick={() => {
                                  setDeleteMode(false);
                                  setStep(1);
                                }}
                              >
                                Edit variants
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-slate-100 font-semibold"
                                    : "text-gray-900 font-medium"
                                } w-full rounded-md px-4 py-2 text-xs text-left`}
                                onClick={() => {
                                  setDeleteMode(true);
                                }}
                              >
                                Delete variants
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
            <div className="space-y-2">
              {itemVariations.map((item: any, index: number) => (
                <div
                  className={classNames(
                    "flex items-center justify-between p-3 text-sm rounded-md border border-gray-200",
                    deleteMode ? "" : "cursor-pointer"
                  )}
                  key={index}
                  onClick={() => {
                    setSelectedItem(item);
                    setInputValues({
                      price: item.price || "",
                      discountPrice: item.discountPrice || "",
                      stock: item.stock || 0,
                      color: item.color || "",
                      size: item.size || "",
                    });
                    setStep(3);
                  }}
                >
                  <div className="space-y-1.5" key={index}>
                    <p className="font-semibold">
                      {item.size}, {item.color}
                    </p>
                    <p className="inline-flex items-center gap-2">
                      Price: {item.price}{" "}
                      <span className="line-through">{item.discountPrice}</span>
                    </p>
                    <p>Stock: {item.stock}</p>
                  </div>
                  {deleteMode ? (
                    <MdOutlineDelete
                      size={22}
                      className="cursor-pointer text-red-600"
                      onClick={() => {
                        const newVariations = itemVariations.filter(
                          (variation) => variation.variantId !== item.variantId
                        );
                        // console.log(newVariations);
                        // console.log(itemVariations);
                        setItemVariations(newVariations);
                      }}
                    />
                  ) : (
                    <MdChevronRight
                      size={22}
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedItem(item);
                        setInputValues({
                          price: item.price || "",
                          discountPrice: item.discountPrice || "",
                          stock: item.stock || 0,
                          color: item.color || "",
                          size: item.size || "",
                        });
                        setStep(3);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="flex items-center justify-center">
              <button
                className="p-2.5 rounded-md bg-blue-700 text-white w-[200px] text-sm"
                onClick={() => setShowModal1(false)}
              >
                Save
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="relative flex flex-col p-6 space-y-3">
            <div className="flex items-center justify-between">
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={() => {
                  setStep(2);
                  setSelectedItem({});
                }}
              >
                <IoArrowBackOutline />
                <p className="text-sm font-semibold">
                  {selectedItem.size},{selectedItem.color}
                </p>
              </div>
            </div>
            <div className="space-y-4">
              <div className="space-y-2">
                <div className="text-sm">
                  <div className="space-y-2.5">
                    <div className="hidden">
                      <label htmlFor="" className="text-xs">
                        Size
                      </label>
                      <input
                        type="text"
                        value={inputValues.size}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            size: e.target.value,
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                    <div className="hidden">
                      <label htmlFor="" className="text-xs">
                        Color
                      </label>
                      <input
                        type="text"
                        value={inputValues.color}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            color: e.target.value,
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                    <div>
                      <label htmlFor="" className="text-xs">
                        Price
                      </label>
                      <input
                        type="text"
                        value={inputValues.price}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            price: e.target.value,
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className="text-xs inline-flex items-center gap-2"
                      >
                        Compare-at price{" "}
                        <MdInfoOutline
                          className="cursor-pointer"
                          onClick={() => setShowInfoDetails((prev) => !prev)}
                        />
                      </label>
                      {showInfoDetails && (
                        <p className="text-xs py-3 px-2.5 bg-gray-100 rounded mb-2 max-w-[350px]">
                          Compare-at price will substitute ‘Price’ as the main
                          price of this item.
                        </p>
                      )}

                      <input
                        type="text"
                        value={inputValues.discountPrice}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            discountPrice: e.target.value,
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                    <div>
                      <label htmlFor="" className="text-xs">
                        Stock
                      </label>
                      <input
                        type="number"
                        value={inputValues.stock}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            stock: Number(e.target.value),
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="p-2.5 rounded-md bg-blue-700 text-white w-[200px] text-sm"
                onClick={() => {
                  const indexToReplace = itemVariations.findIndex(
                    (variant) => variant.variantId === selectedItem.variantId
                  );

                  if (indexToReplace !== -1) {
                    const updatedItemVariationsSplice = [...itemVariations];
                    updatedItemVariationsSplice.splice(indexToReplace, 1, {
                      ...itemVariations[indexToReplace],
                      color: inputValues.color,
                      size: inputValues.size,
                      price: inputValues.price,
                      discountPrice: inputValues.discountPrice,
                      stock: inputValues.stock,
                      variantId: selectedItem.variantId,
                    });

                    // console.log(updatedItemVariationsSplice);
                    setItemVariations(updatedItemVariationsSplice);
                    // setGeneralInputs(updatedItemVariationsSplice);
                  }

                  setSelectedItem({});

                  setInputValues({
                    price: "",
                    discountPrice: "",
                    stock: 0,
                    color: "",
                    size: "",
                  });
                  setStep(2);
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </Modal>

      <div>
        <div className="flex items-center justify-between mb-3">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              navigate("/estore/products?type=items");
            }}
          >
            <MdOutlineKeyboardBackspace className="" />
            <p className="text-lg font-semibold">Back</p>
          </div>
        </div>

        <div className="max-w-[720px] mx-auto">
          <form onSubmit={formik.handleSubmit} className="">
            <div className="space-y-3">
              <div className="w-full">
                <Input
                  formik={formik}
                  name="itemName"
                  label="Item Name"
                  type="text"
                  placeholder="Please enter item name"
                />
                {formik.errors.itemName && (
                  <InputMessage message={formik.errors.itemName} />
                )}
              </div>
              <div className="w-full">
                <label className="block mb-1 text-xs font-normal text-gray-800">
                  Description
                </label>
                <textarea
                  id=""
                  name="details"
                  value={formik.values.details}
                  onChange={formik.handleChange}
                  className="h-[100px] text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                  placeholder="Please enter details of item"
                ></textarea>
                {formik.errors.details && (
                  <InputMessage message={formik.errors.details} />
                )}
              </div>
              <div className="w-full">
                <Input
                  formik={formik}
                  name="price"
                  label="Price"
                  type="number"
                  placeholder="Please enter price"
                />
                {formik.errors.price && (
                  <InputMessage message={formik.errors.price} />
                )}
              </div>

              <div className="w-full">
                <label className="mb-1 text-xs font-normal text-gray-800 inline-flex items-center gap-2">
                  Compare-at price{" "}
                  <MdInfoOutline
                    size={20}
                    className="cursor-pointer text-500"
                    onClick={() => setShowInfoDetails((prev) => !prev)}
                  />
                </label>
                {showInfoDetails && (
                  <p className="text-xs py-3 px-2.5 bg-gray-100 rounded mb-2 w-fit">
                    Compare-at price will substitute ‘Price’ as the main price
                    of this item.
                  </p>
                )}

                <input
                  formik={formik}
                  name="discountPrice"
                  label="Discount"
                  type="number"
                  {...formik.getFieldProps("discountPrice")}
                  className={classNames(
                    "text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                  )}
                  placeholder="Please enter product's discounted price"
                />
                {formik.errors.discountPrice && (
                  <InputMessage message={formik.errors.discountPrice} />
                )}
              </div>

              <div>
                <label className="block mb-1 text-xs font-normal text-gray-800">
                  Image
                </label>
                <div className="flex items-center gap-10 ">
                  {imagesArray.map((imagePreview: string, index: number) => (
                    <div className="w-[150px] h-[100px] relative" key={index}>
                      <img
                        src={imagePreview}
                        alt=""
                        className="object-contain w-full h-full overflow-clip"
                      />
                      <div
                        className={classNames(
                          "absolute z-10 top-0 -right-4 m-auto font-semibold w-8 h-8 bg-red-400 rounded-full flex items-center justify-center cursor-pointer",
                          selectedImage ? "hidden" : ""
                        )}
                        onClick={() => {
                          setImagesArray((prevState) =>
                            prevState.filter((i) => i !== imagePreview)
                          );
                        }}
                      >
                        <span className="text-[13px] text-white">
                          <MdClear size={22} />
                        </span>
                      </div>
                    </div>
                  ))}
                  {!selectedImage || !preview ? (
                    <div
                      className={classNames(
                        "w-full",
                        imagesArray.length > 3 ? "hidden" : ""
                      )}
                    >
                      <label
                        htmlFor="dropzone-file"
                        className="w-[80px] h-[80px] rounded-md bg-gray-200 flex items-center justify-center cursor-pointer"
                      >
                        <FiUpload size={22} className="text-gray-700" />
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          accept="image/jpeg, image/png, image/jpg"
                          onChange={imageHandler}
                        />
                      </label>
                    </div>
                  ) : null}
                  {!selectedImage || !preview ? null : (
                    <div className="flex items-center gap-10">
                      <img
                        src={preview}
                        alt=""
                        className="w-[120px] h-[100px] object-contain"
                      />
                      <div>
                        <div className="flex flex-col items-center gap-2">
                          <button
                            type="button"
                            className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-red-700 bg-transparent border border-red-700 rounded-md"
                            onClick={(e) => {
                              setSelectedImage(null);
                              setPreview(null);
                              setImageUploadSuccess(false);
                            }}
                          >
                            <span>Cancel</span>
                            <span>
                              <MdClear />
                            </span>
                          </button>
                          <button
                            type="button"
                            className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-white border rounded-md bg-blue-700 hover:bg-blue-800 border-primary"
                            onClick={() => {
                              handleCompressedUpload(selectedImage);
                            }}
                          >
                            <span>Upload</span>{" "}
                            {isLoading ? (
                              <ImSpinner2
                                size={20}
                                className="mx-auto animate-spin"
                              />
                            ) : (
                              <AiOutlineCloudUpload />
                            )}
                          </button>
                        </div>
                        {imageUploadSuccess && (
                          <p className="mt-2 text-xs text-center text-green-500">
                            Upload successful
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full">
                <Input
                  formik={formik}
                  name="available_count"
                  label="Available Stock"
                  type="number"
                  placeholder="Please enter available stock"
                />
                {formik.errors.available_count && (
                  <InputMessage message={formik.errors.available_count} />
                )}
              </div>

              <div className="w-full">
                <label
                  htmlFor="category"
                  className="block mb-1 text-xs font-normal text-gray-800"
                >
                  Category
                </label>
                <select
                  name="category"
                  id="category"
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      category: e.target.value,
                    });
                  }}
                  className="text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                >
                  <option
                    value="choose"
                    className="text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                  >
                    Choose a category
                  </option>
                  {categories.map((category: string, index: number) => (
                    <option
                      key={category.id}
                      value={category.categoryName}
                      className="text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                    >
                      {category.categoryName}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <div
                  className="w-full p-2.5 border border-gray-200 rounded flex items-center justify-between cursor-pointer"
                  onClick={() => setShowModal1(true)}
                >
                  <p className="text-sm text-gray-800">Manage variation</p>
                  <MdOutlineChevronRight size={20} className="" />
                </div>
                {itemVariations.length > 0 && (
                  <div
                    className="mt-4 border border-gray-300 rounded p-4 cursor-pointer text-sm "
                    onClick={() => setShowModal1(true)}
                  >
                    <div className="flex items-center gap-4">
                      {[...uniqueColors].map((i: any, index: number) => (
                        <p
                          className={classNames(
                            i.color === "null" ? "hidden" : "",
                            i.color === "" ? "hidden" : "",
                            "border border-gray-300 px-2 py-1.5 rounded"
                          )}
                          key={index}
                        >
                          {i}
                        </p>
                      ))}
                    </div>
                    <br />
                    <div className="flex items-center gap-4">
                      {[...uniqueSizes].map((i: any, index: number) => (
                        <p
                          className={classNames(
                            i === "null" ? "hidden" : "",
                            i === "" ? "hidden" : "",
                            "border border-gray-300 px-2 py-1.5 rounded"
                          )}
                          key={index}
                        >
                          {i}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <button
                type="submit"
                // disabled={formik.isSubmitting}
                className="text-xs w-[200px] py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
              >
                {formik.isSubmitting ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Save Item"
                )}
              </button>
            </div>
            <p className="mt-1 text-center text-green-500 capitalize">
              {success && <small>Saved successfully!</small>}
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
