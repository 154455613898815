import { useState } from "react";
import { Input } from "../../EchoWebpage1";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import {
  getDeliveryDetail,
  getFreeDeliveryStatus,
  uploadDeliveryDetails,
  uploadFreeDeliveryStatus,
} from "../../../../../../../api/Menu";
import { ImSpinner2 } from "react-icons/im";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";

const deliveryMethodInitialValues: any = {
  pickup_location: "",
  location_tag1: "",
  location_tag2: "",
  location_tag3: "",
  location_tag4: "",
  location_tag5: "",
  location_tag6: "",
  location_price1: "",
  location_price2: "",
  location_price3: "",
  location_price4: "",
  location_price5: "",
  location_price6: "",
  pickup_status: false,
  delivery_status: false,
};

const DeliveryMethods = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  let deliveryDetailsFetched: any = {};
  const [freeDelivery, setFreeDelivery] = useState("0");
  const [deliveryMethod, setDeliveryMethod] = useState({
    pickup: false,
    delivery: false,
  });
  let deliveryStatus = "";

  const formik4 = useFormik({
    initialValues: deliveryMethodInitialValues,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const key1 =
        values.location_tag1 === "" ? "dummylocation1" : values.location_tag1;
      const key2 =
        values.location_tag2 === "" ? "dummylocation2" : values.location_tag2;
      const key3 =
        values.location_tag3 === "" ? "dummylocation3" : values.location_tag3;
      const key4 =
        values.location_tag4 === "" ? "dummylocation4" : values.location_tag4;
      const key5 =
        values.location_tag5 === "" ? "dummylocation5" : values.location_tag5;
      const key6 =
        values.location_tag6 === "" ? "dummylocation6" : values.location_tag6;
      setSubmitting(true);
      const payload = {
        platformKey,
        pickup_location: values.pickup_location,
        location_tag: {
          [key1]: key1 === "dummylocation1" ? "" : values.location_price1,
          [key2]: key2 === "dummylocation2" ? "" : values.location_price2,
          [key3]: key3 === "dummylocation3" ? "" : values.location_price3,
          [key4]: key4 === "dummylocation4" ? "" : values.location_price4,
          [key5]: key5 === "dummylocation5" ? "" : values.location_price5,
          [key6]: key6 === "dummylocation6" ? "" : values.location_price6,
        },
        pickup_status: deliveryMethod.pickup ? "1" : "0",
        delivery_status: deliveryMethod.delivery ? "1" : "0",
      };

      // console.log(payload);

      try {
        const response = await uploadDeliveryDetails(payload);
        handleUploadFreeDeliveryStatus.mutate({
          deliveryStatus: freeDelivery,
        });
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries({ queryKey: ["getDeliveryDetail"] });
          resetForm({});
          setSubmitting(false);
          return;
        }
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  const deliveryDetails = useQuery<any, Error>({
    queryKey: ["getDeliveryDetail"],
    queryFn: async () => {
      const response = await getDeliveryDetail(`${storeKey}`);
      return response.data;
    },
    onSuccess: (data) => {
      if (Object.keys(data.Location_Tag).length !== 0) {
        let newA = Object.entries(data.Location_Tag);
        const realLocations = newA.filter(
          (x: any) => !x.at(0).includes("dummylocation")
        );
        const fakeLocations = newA.filter((x: any) =>
          x.at(0).includes("dummylocation")
        );
        const newB = [...realLocations, ...fakeLocations];
        formik4.setValues({
          pickup_location: data.Pickup_Location,
          pickup_status: data.Pickup_Status === "0" ? false : true,
          delivery_status: data.Delivery_Status === "0" ? false : true,
          location_tag1: newB[0][0].includes("dummylocation") ? "" : newB[0][0],
          location_tag2: newB[1][0].includes("dummylocation") ? "" : newB[1][0],
          location_tag3: newB[2][0].includes("dummylocation") ? "" : newB[2][0],
          location_tag4: newB[3][0].includes("dummylocation") ? "" : newB[3][0],
          location_tag5: newB[4][0].includes("dummylocation") ? "" : newB[4][0],
          location_tag6: newB[5][0].includes("dummylocation") ? "" : newB[5][0],
          location_price1: newB[0][1],
          location_price2: newB[1][1],
          location_price3: newB[2][1],
          location_price4: newB[3][1],
          location_price5: newB[4][1],
          location_price6: newB[5][1],
        });
      }

      setDeliveryMethod({
        pickup: data.Pickup_Status === "0" ? false : true,
        delivery: data.Delivery_Status === "0" ? false : true,
      });
    },
  });

  const freeDeliveryStatus = useQuery<any, Error>({
    queryKey: ["freeDeliveryStatus"],
    queryFn: async () => {
      const response = await getFreeDeliveryStatus(`${storeKey}`);
      return response.data;
    },
    onSuccess: (data) => {
      setFreeDelivery(data.Delivery_Status);
    }
  });

  const handleUploadFreeDeliveryStatus = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        platformKey,
        deliveryStatus: data.deliveryStatus.toString(),
      };
      return await uploadFreeDeliveryStatus(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["freeDeliveryStatus"]);
    },
  });

  if (freeDeliveryStatus.data) {
    deliveryStatus = freeDeliveryStatus.data.Delivery_Status;
  }

  if (deliveryDetails.data) {
    deliveryDetailsFetched = {
      delivery: deliveryDetails.data.Delivery_Status,
      pickup: deliveryDetails.data.Pickup_Status,
    };
  }

  return (
    <div className="">
      <div className="max-w-[600px] ">
        <h1
          className="flex items-center gap-2 mb-1 text-lg font-semibold cursor-pointer hidden">
          Delivery method
        </h1>
        <p className="mb-2 text-xs text-gray-600 hidden">
          How will your customers receive your product/services? (select all that
          apply)
        </p>
        <Link
          to="/estore/settings/delivery"
          className="flex items-center gap-2"
        >
          <MdOutlineKeyboardBackspace />
          <span className="text-gray-800 font-medium">Delivery rates</span>
        </Link>
        <form className="" onSubmit={formik4.handleSubmit}>
          <div className="grid grid-cols-1 gap-4 my-4">
            <div className="">
              {deliveryDetails?.data && (
                <div className="flex items-center mb-1.5 hidden">
                  <input
                    defaultChecked={(Number(deliveryDetailsFetched.pickup) === 1)}
                    id="checked-checkbox"
                    type="checkbox"
                    value="pickup_location"
                    onChange={(e) =>
                      setDeliveryMethod({
                        ...deliveryMethod,
                        pickup: e.target.checked,
                      })
                    }
                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="checked-checkbox"
                    className="ml-2 text-xs font-semibold text-gray-900"
                  >
                    Pickup <span className="font-normal text-gray-400">(Set pickup location)</span>
                  </label>
                </div>
              )}
              <div className="flex items-center justify-between mb-0.5">
                <p className="text-xs">Pickup location</p>
              </div>
              <div className="w-full">
                <Input
                  formik={formik4}
                  showLabel={false}
                  type="text"
                  name="pickup_location"
                  label="Location"
                  placeHolder="Enter pickup address"
                />
              </div>
            </div>
            <div className="">
              {deliveryDetails?.data && (
                <div className="flex items-center mb-1.5 hidden" >
                  <input
                    defaultChecked={(Number(deliveryDetailsFetched.delivery) === 1)}
                    id="checked-checkbox"
                    type="checkbox"
                    value=""
                    onChange={(e) =>
                      setDeliveryMethod({
                        ...deliveryMethod,
                        delivery: e.target.checked,
                      })
                    }
                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="checked-checkbox"
                    className="ml-2 text-xs font-semibold text-gray-900"
                  >
                    Delivery <span className="font-normal text-gray-400">(Set delivery area and amount)</span>
                  </label>
                </div>
              )}
              <div>
                <div className="flex items-center justify-between mb-0.5">
                  <p className="text-xs">Delivery locations</p>
                  <p className="text-xs">Amount</p>
                </div>
                <div className="space-y-1.5">
                  <div className="flex items-center justify-between gap-2">
                    <div className="w-3/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="text"
                        placeHolder="Location1"
                        name="location_tag1"
                      />
                    </div>
                    <div className="w-1/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="number"
                        name="location_price1"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <div className="w-3/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="text"
                        placeHolder="Location2"
                        className="py-1 px-2.5"
                        name="location_tag2"
                      />
                    </div>
                    <div className="w-1/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="number"
                        name="location_price2"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <div className="w-3/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="text"
                        placeHolder="Location3"
                        name="location_tag3"
                      />
                    </div>
                    <div className="w-1/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="number"
                        name="location_price3"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <div className="w-3/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="text"
                        placeHolder="Location4"
                        name="location_tag4"
                      />
                    </div>
                    <div className="w-1/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="number"
                        name="location_price4"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <div className="w-3/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="text"
                        placeHolder="Location5"
                        name="location_tag5"
                      />
                    </div>
                    <div className="w-1/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="number"
                        name="location_price5"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between gap-2">
                    <div className="w-3/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="text"
                        placeHolder="Location6"
                        name="location_tag6"
                      />
                    </div>
                    <div className="w-1/4">
                      <Input
                        formik={formik4}
                        showLabel={false}
                        type="number"
                        name="location_price6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {freeDeliveryStatus?.data && (
                <div className="flex items-center mb-1.5 hidden" >
                  <input
                    defaultChecked={(Number(deliveryStatus) === 1)}
                    type="checkbox"
                    value="deliveryStatus"
                    onChange={(e) => {
                      if (Number(freeDelivery) === 0) {
                        setFreeDelivery("1");
                        return;
                      }
  
                      if (Number(freeDelivery) === 1) {
                        setFreeDelivery("0");
                        return;
                      }
                    }
                    }
                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="checked-checkbox"
                    className="ml-2 text-xs font-semibold text-gray-900"
                  >
                    Free delivery <span className="font-normal text-gray-400">{"(Click to enable free delivery for selected store products)"}</span>
                  </label>
                </div>
              )}
            </div>
          </div>
          <button
            type="submit"
            disabled={formik4.isSubmitting}
            className="text-xs w-[100px] py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
          >
            {formik4.isSubmitting || handleUploadFreeDeliveryStatus.isLoading  ? (
              <ImSpinner2 size={20} className="mx-auto animate-spin" />
            ) : (
              "Save"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DeliveryMethods;
