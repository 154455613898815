import axios from "axios";
const BASE_URL = "https://p40c60ttre.execute-api.eu-west-2.amazonaws.com/Prod/api/v1"


export const getAttentionTrigger = async (queryKey: any) => axios.get(`${BASE_URL}/get_attention_trigger?platformKey=${queryKey}`);
export const getSwitchState = async (queryKey: any) => axios.get(`${BASE_URL}/get_switch_state?platformKey=${queryKey}`);
export const getFeedbackMessage = async (queryKey: any) => axios.get(`${BASE_URL}/feedback_message_download?platformKey=${queryKey}`);
export const getSingleMessage = async (messageId: any) => axios.get(`${BASE_URL}/get_single_message?messageId=${messageId}`);
export const getMessages = async (queryKey: any, pageKey: string, recipentEmail : string) => axios.get(`${BASE_URL}/get_messages_endpoint?platformKey=${queryKey}&pageKey=${pageKey}&recipientMail=${recipentEmail}`);

export const updateAttentionTrigger = async (payload: any) => axios.post(`${BASE_URL}/update_attention_trigger`, payload);
export const updateCustomizeMessagingSwitch = async (payload: any) => axios.post(`${BASE_URL}/customize_messaging_switch`, payload);
export const uploadFeedbackMesssage = async (payload: any) => axios.post(`${BASE_URL}/feedback_message_upload`, payload);
export const sendMessage = async (payload: any) => axios.post(`${BASE_URL}/client_messaging`, payload);
export const updateFeedbackAndAttentionTrigger = async (payload: any) => axios.post(`${BASE_URL}/feedback_and_attention_update`, payload);
export const loadSidebarMessages = async (payload: any) => axios.post(`${BASE_URL}/load_message_side_bar`, payload);
