// @ts-nocheck

import { MdOutlineClear } from "react-icons/md";
import { classNames, ratingsToNumber } from "../../../../../../../utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import { getCustomerSatisfactionScore, getPublicFeedBacks } from "../../../../../../../api/Utils";
import { ReviewStars } from "../../../../../CustomerReviewPage/CustomerReview1";
import { ImSpinner3, ImSpinner8 } from "react-icons/im";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";

const ReviewsSection = ({
  setSidePanel,
  sidePanel,
  setSelectedSection,
  setShowDeleteModal,
  templateSections
}) => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const queryKey = user.queryKey;
  const storeKey = store.name;

  let comments: any[] = [];

  const satisfaction = useQuery<any, Error>({
    queryKey: ["getPublicFeedBacks"],
    queryFn: async () => {
      const response = await getPublicFeedBacks(storeKey);
      return response.data;
    },
    onSuccess: (data) => {
    },
  });

  if(satisfaction.data){
    comments = satisfaction.data.Public_Feedbacks;
  }

  return (
    <section
      className={classNames(
        "cursor-pointer",
        sidePanel === "reviews" ? "border-2 border-blue-800 p-3" : "", !templateSections.includes("Reviews") === true ? "" : "hidden"
      )}
      onClick={() => {
        setSidePanel("reviews");
      }}
    >
      <div  className={classNames(
        "relative px-4 py-6 space-y-6 bg-white cursor-pointer")} >
      <div className="text-center">
        <p className="text-lg font-semibold">Reviews</p>
        <p className="text-sm text-gray-500 hidden">
          Show Feedback From Customers On Your Website
        </p>
      </div>
      {satisfaction.status === "loading" ? (
        <div className="flex items-center justify-start py-5">
          <ImSpinner3 size={20} className="mx-auto animate-spin fill-primary" />
        </div>
      ) : satisfaction.status === "error" ? (
        <span>Error: {satisfaction.error.message}</span>
      ) : comments.length > 0 ? (
        <div className="">
          <div className="max-w-[95%] mx-auto grid grid-cols-1 gap-4 md:grid-cols-2">
            {comments.slice(0, 4).map((comment: any, index: number) => (
              <div
                className="p-3 space-y-2 border-b-[1px] border-gray-200 rounded-2xl shadow-sm"
                key={index}
              >
                <div className="flex items-center justify-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
                    <span className="text-gray-800 font-bold text-xl">{comment.customer.charAt(0).toUpperCase()}</span>
                  </div>
                </div>
                <p className="text-gray-500 text-xs text-center px-2">
                  {comment.userReview}
                </p>
                <div className="border-b-[1px] border-gray-200 pb-3">
                  <div className="max-w-[100px] mx-auto">
                    <ReviewStars
                      reviews={ratingsToNumber(comment.userRatings)}
                    />
                  </div>
                </div>
                <p className="text-center text-xs font-sembold text-gray-900">
                  {comment.customer !== "" ? comment.customer : "Anonymous"}
                </p>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center gap-12 mt-6 hidden">
            <HiOutlineArrowNarrowLeft className="" />
            <HiOutlineArrowNarrowRight className="" />
          </div>
        </div>
      ) : (
        <p className="mt-6 font-semibold text-center text-gray-800">
          No feedback available
        </p>
      )}
      <MdOutlineClear
        size={18}
        className="absolute text-blue-800 cursor-pointer right-4 top-2"
        onClick={(e) => {
          e.stopPropagation();
          setShowDeleteModal(true);
          setSelectedSection("Reviews");
        }}
      />
      </div>
    </section>
  );
};

export default ReviewsSection;
