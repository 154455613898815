// @ts-nocheck

import { useEffect, useState, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "../Dashboard/Modules/Home/EchoWebpage/EchoWebpage1";
import InputMessage from "../../components/Auth/Forms/InputMessage";
import { MdCheck, MdClear } from "react-icons/md";
import { AiOutlineCloudUpload, AiOutlinePlus } from "react-icons/ai";
import { ImSpinner2 } from "react-icons/im";
import { BASE_URL_WEBPAGE_IMAGE } from "../../api/Menu";
import { classNames } from "../../utils";
import Compressor from "compressorjs";
import { Listbox, Transition } from "@headlessui/react";
import { CgChevronDown } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

const categories = [
  {
    mainCategory: "Appliances",
    subCategories: [
      {
        name: "Large Appliances",
        sub_subCategories: [
          { name: "Fridges" },
          { name: "Washers" },
          { name: "Oven & stove" },
          { name: "Water dispenser" },
          { name: "Generators" },
        ],
      },
      {
        name: "Small Appliances",
        sub_subCategories: [
          { name: "Blenders" },
          { name: "Air fryers" },
          { name: "Microwaves" },
          { name: "Sewing machines" },
          { name: "Vacuum cleaners" },
          { name: "Air coolers, fans & heaters" },
          { name: "Food mixers" },
          { name: "Irons" },
          { name: "Kettles" },
          { name: "Toaster" },
        ],
      },
    ],
  },
  {
    mainCategory: "Baby products",
    subCategories: [
      {
        name: "Baby clothes",
        sub_subCategories: [{ name: "Boys" }, { name: "Girls" }],
      },
      {
        name: "Baby accessories",
        sub_subCategories: [
          { name: "Baby baths" },
          { name: "Baby carriages" },
          { name: "Baby monitor" },
          { name: "Baby toys" },
          { name: "Baby seat" },
          { name: "Nappies & wipes" },
          { name: "Baby blankets" },
        ],
      },
      {
        name: "Baby feeding",
        sub_subCategories: [
          { name: "Food storage" },
          { name: "Baby food" },
          { name: "Baby towels & bibs" },
        ],
      },
    ],
  },
  {
    mainCategory: "Beverages & alcohol",
    subCategories: [
      {
        name: "Beverages",
        sub_subCategories: [
          { name: "Coffee" },
          { name: "Tea" },
          { name: "Hot chocolate" },
          { name: "Milk" },
        ],
      },
      {
        name: "soft drinks & juices",
        sub_subCategories: [
          { name: "Water" },
          { name: "Juice" },
          { name: "Soft drinks" },
          { name: "Energy drinks" },
          { name: "Iced tea" },
        ],
      },
      {
        name: "Spirits",
        sub_subCategories: [
          { name: "Whisky" },
          { name: "Gin" },
          { name: "Brandy" },
          { name: "Vodka" },
          { name: "Run" },
          { name: "Non-alcoholic spirit" },
        ],
      },
      {
        name: "Wines",
        sub_subCategories: [
          { name: "Red wine" },
          { name: "White wine" },
          { name: "Sparkling wine" },
        ],
      },
      {
        name: "Beer",
        sub_subCategories: [
          { name: "Alcoholic beer" },
          { name: "Non-alcoholic beer" },
        ],
      },
      {
        name: "Beer accessories",
        sub_subCategories: [
          { name: "Wine opener" },
          { name: "Cocktail mixer" },
        ],
      },
    ],
  },
  {
    mainCategory: "Books",
    subCategories: [
      {
        name: "Children",
        sub_subCategories: [
          { name: "Baby & toddlers" },
          { name: "Children books" },
          { name: "Young adults" },
        ],
      },
      {
        name: "Educational",
        sub_subCategories: [
          { name: "School textbooks" },
          { name: "Historical" },
          { name: "Research books" },
        ],
      },
      {
        name: "General fiction",
        sub_subCategories: [
          { name: "Crime" },
          { name: "Short stories" },
          { name: "Romance" },
          { name: "Thriller" },
          { name: "Comics" },
          { name: "Drama" },
        ],
      },
      {
        name: "Non-fiction",
        sub_subCategories: [
          { name: "Art books" },
          { name: "Memoirs" },
          { name: "Cook books" },
          { name: "Health" },
          { name: "Humour & trivia" },
          { name: "Poetry" },
          { name: "Self-help" },
          { name: "Current affairs" },
          { name: "Reference" },
          { name: "Sport" },
        ],
      },
    ],
  },
  {
    mainCategory: "Electronics & computers",
    subCategories: [
      {
        name: "Televisions",
        sub_subCategories: [{ name: "LED" }, { name: "Projectors" }],
      },
      {
        name: "Computer",
        sub_subCategories: [
          { name: "Tablets" },
          { name: "Software" },
          { name: "Monitors" },
          { name: "Storage" },
          { name: "CPU" },
          { name: "UPS" },
          { name: "Printers" },
          { name: "Wearables" },
          { name: "Wifi & Networking" },
          { name: "GPS & navigation" },
        ],
      },
      {
        name: "Phones",
        sub_subCategories: [
          { name: "Samasung" },
          { name: "Apple" },
          { name: "Techno" },
          { name: "Infinix" },
          { name: "Huawei" },
          { name: "Itel" },
          { name: "Nokia" },
        ],
      },
      {
        name: "Cameras",
        sub_subCategories: [
          { name: "Batteries" },
          { name: "Binoculars" },
          { name: "Lenses" },
          { name: "Digital cameras" },
        ],
      },
      {
        name: "Laptops",
        sub_subCategories: [
          { name: "HP" },
          { name: "Apple" },
          { name: "Acer" },
          { name: "Sony" },
          { name: "Dell" },
          { name: "Lenovo" },
          { name: "Asus" },
          { name: "Microsoft" },
        ],
      },
      {
        name: "Audio",
        sub_subCategories: [
          { name: "Soundbars" },
          { name: "Home theatre system" },
          { name: "Lenses" },
          { name: "Digital cameras" },
        ],
      },
    ],
  },
  {
    mainCategory: "Games",
    subCategories: [
      {
        name: "Video games",
        sub_subCategories: [
          { name: "Software" },
          { name: "Accessories" },
          { name: "Consoles" },
        ],
      },
      {
        name: "Games",
        sub_subCategories: [
          { name: "Boards games" },
          { name: "Darts" },
          { name: "Football & table games" },
          { name: "Garden & beach games" },
          { name: "Snooker & poo" },
          { name: "Table tennis" },
          { name: "Children's toys & games" },
        ],
      },
    ],
  },
  {
    mainCategory: "Health & beauty",
    subCategories: [
      {
        name: "Body care",
        sub_subCategories: [
          { name: "Eye care" },
          { name: "Foot care" },
          { name: "Tissues" },
          { name: "Toilet paper" },
        ],
      },
      {
        name: "Bath & bathing accessories",
        sub_subCategories: [
          { name: "Soap" },
          { name: "Bodywash" },
          { name: "Hand wash" },
          { name: "Bathfoams" },
          { name: "Oils" },
        ],
      },
      {
        name: "Antiperspirant & deodorant",
        sub_subCategories: [
          { name: "Ladies antiperspirant" },
          { name: "Men antiperspirant" },
        ],
      },
      {
        name: "Oral care",
        sub_subCategories: [
          { name: "Teeth whitener" },
          { name: "Brush" },
          { name: "Toothpaste" },
        ],
      },
      {
        name: "Hair care",
        sub_subCategories: [
          { name: "Hair cream" },
          { name: "Shampoo" },
          { name: "Combs & brushes" },
        ],
      },
      {
        name: "Shaving & grooming",
        sub_subCategories: [
          { name: "Shaving powder" },
          { name: "Shaving sticks" },
        ],
      },
      {
        name: "Skincare",
        sub_subCategories: [{ name: "Creams" }, { name: "Oils" }],
      },
      {
        name: "Shoe care",
        sub_subCategories: [{ name: "Shoe polish" }, { name: "Shoe brush" }],
      },
      {
        name: "Makeup",
        sub_subCategories: [
          { name: "Concealers" },
          { name: "Foundation" },
          { name: "Powder" },
          { name: "Eyeliner" },
          { name: "Mascara" },
        ],
      },
    ],
  },
  {
    mainCategory: "Household",
    subCategories: [
      {
        name: "Cleaning supplies",
        sub_subCategories: [
          { name: "Air fresheners" },
          { name: "Bleach" },
          { name: "Toilet paper" },
          { name: "Disinfectant" },
          { name: "Furniture cleaning" },
          { name: "Window & floor cleaners" },
          { name: "Detergent" },
        ],
      },
      {
        name: "Pest control",
        sub_subCategories: [
          { name: "Insecticide" },
          { name: "Pesticide" },
          { name: "Repellant lotion" },
        ],
      },
      {
        name: "Kitchen supplies",
        sub_subCategories: [
          { name: "Kitchen cleaning" },
          { name: "Refuse bags" },
        ],
      },
      {
        name: "Home supplies",
        sub_subCategories: [
          { name: "Bathroom accessories" },
          { name: "Towels" },
          { name: "Plumbing" },
          { name: "Beds & mattresses" },
          { name: "Bed sheets" },
          { name: "Duvet" },
          { name: "Pillow covers" },
          { name: "Bins" },
        ],
      },
    ],
  },
  {
    mainCategory: "Sportings",
    subCategories: [
      {
        name: "Equipment",
        sub_subCategories: [
          { name: "Dumbbells & bars" },
          { name: "Abdominal trainers" },
          { name: "Exercise bikes" },
          { name: "Treadmills" },
        ],
      },
      {
        name: "Sporting accessories",
        sub_subCategories: [
          { name: "Knee brace" },
          { name: "Sports top" },
          { name: "Sports shorts" },
          { name: "Trainers" },
        ],
      },
      {
        name: "Outdoor",
        sub_subCategories: [{ name: "Cycling" }, { name: "Running" }],
      },
    ],
  },
  {
    mainCategory: "Stationery & office supplies",
    subCategories: [
      {
        name: "Office furniture",
        sub_subCategories: [{ name: "Chairs" }, { name: "Desks" }],
      },
      {
        name: "Office well-being",
        sub_subCategories: [
          { name: "" },
          { name: "" },
          { name: "" },
          { name: "" },
        ],
      },
      {
        name: "Office storage",
        sub_subCategories: [
          { name: "" },
          { name: "" },
          { name: "" },
          { name: "" },
        ],
      },
      {
        name: "Office accessories",
        sub_subCategories: [
          { name: "" },
          { name: "" },
          { name: "" },
          { name: "" },
        ],
      },
    ],
  },
  {
    mainCategory: "Food",
    subCategories: [
      {
        name: "Cooking",
        sub_subCategories: [
          { name: "Packaged food" },
          { name: "Rice" },
          { name: "Pasta" },
          { name: "Salt" },
          { name: "Herbs & spices" },
          { name: "Stock" },
          { name: "Sugar & sweeteners" },
          { name: "Oil & vinegar" },
        ],
      },
      {
        name: "Cereals",
        sub_subCategories: [
          { name: "Cornflakes" },
          { name: "Granola" },
          { name: "Custard" },
          { name: "Oats" },
          { name: "Golden morn" },
        ],
      },
      {
        name: "Baking",
        sub_subCategories: [
          { name: "Food coloring" },
          { name: "Flour" },
          { name: "Mixes" },
          { name: "Toppings" },
          { name: "Yeast" },
          { name: "Baking powder" },
        ],
      },
      {
        name: "Snacks",
        sub_subCategories: [
          { name: "Biscuits" },
          { name: "Health bars" },
          { name: "Popcorn & chips" },
          { name: "Chocolates" },
          { name: "Nuts" },
          { name: "Sweets" },
        ],
      },
    ],
  },
  {
    mainCategory: "Meals",
    subCategories: [
      {
        name: "Swallow",
        sub_subCategories: [
          { name: "Eba" },
          { name: "Fufu" },
          { name: "Amala" },
          { name: "Tuwo shinkafa" },
          { name: "Pounded yam" },
        ],
      },
      {
        name: "Soups",
        sub_subCategories: [
          { name: "Banga" },
          { name: "Egusi" },
          { name: "Ewedu" },
          { name: "Efo-riro" },
          { name: "Ogbono" },
          { name: "Afang" },
        ],
      },
      {
        name: "African food",
        sub_subCategories: [
          { name: "Jollof rice" },
          { name: "Fried rice" },
          { name: "Porridge yam" },
          { name: "Porridge plantain" },
          { name: "Suya" },
        ],
      },
      {
        name: "Snacks",
        sub_subCategories: [
          { name: "Egg roll" },
          { name: "Chin chin" },
          { name: "Puff puff" },
          { name: "Small chops" },
        ],
      },
    ],
  },
];

const initialValues = {
  itemName: "",
  details: "",
  price: "",
  category: "",
};

const itemSchema = Yup.object().shape({
  itemName: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Item name is required"),
  details: Yup.string()
    .min(5, "Minimum 5 letters")
    .max(200, "Maximum 2000 letters"),
  category: Yup.string(),
});

const UploadProduct = () => {
  const navigate = useNavigate();
  const [preview, setPreview] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selected, setSelected] = useState(categories[0]);
  const [subCategories, setSubCategories] = useState(selected.subCategories);
  const [selectedSub, setSelectedSub] = useState(subCategories[0]);
  const [subSubCategories, setSubSubCategories] = useState(
    selectedSub.sub_subCategories
  );
  const [selectedSubSub, setSelectedSubSub] = useState(subSubCategories[0]);
  const [imagesArray, setImagesArray] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: itemSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      // setSubmitting(true);
      setIsLoadingSubmit(true);

      if (imagesArray.length < 1) {
        alert("Select an image for upload");
        setIsLoadingSubmit(false);
        return;
      }

      if (selectedImage?.size > 2072576) {
        alert(
          "Please select an image with a size of less than or equal to 2MB"
        );
        setSelectedImage(null);
        setIsLoadingSubmit(false);
        return;
      }

      let path;

      path =
        "uploadedProducts/" +
        selected.mainCategory.replace(/[^a-zA-Z0-9 ]/g, "") +
        "/" +
        selectedSub.name.replace(/[^a-zA-Z0-9 ]/g, "") +
        "/" +
        selectedSubSub.name.replace(/[^a-zA-Z0-9 ]/g, "") +
        "/" +
        values.itemName.replace(/[^a-zA-Z0-9 ]/g, "") +
        "/productDetails/" +
        uuidv4();

      const token = localStorage.getItem("imageToken");
      const headers = { Authorization: `Bearer ${token}` };

      const productDetails = {
        productName: values.itemName,
        productDescription: values.details.trim(),
        productCategory: selected.mainCategory,
        subCategory: selectedSub.name,
        sub_subCategory: selectedSubSub.name,
      };

      const payload = JSON.stringify(productDetails, null, 2);

      try {
        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", payload);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then(() => {
                setSelectedImage(null);
                setPreview(null);
                formik.resetForm({});
                setImagesArray([]);
                setSuccess(true);
                setIsLoadingSubmit(false);
                setTimeout(() => {
                  setSuccess(false);
                }, 3500);
              })
              .catch((err) => {
                setIsLoadingSubmit(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  const imageHandler = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(null);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const handleCompressedUpload = async (file: File) => {
    setIsLoading(true);
    if (selectedImage?.size > 2072576) {
      alert("Please select an image with a size of less than or equal to 2MB");
      setSelectedImage(null);
      setIsLoading(false);
      return;
    }

    if (formik.values.itemName === "" || formik.values.itemName.length < 3) {
      alert("Input a product name(minimum of 3 characters)");
      setIsLoading(false);
      return;
    }

    new Compressor(file, {
      quality: 0.2,
      success: (compressedResult: any) => {
        let path;
        path =
          "uploadedProducts/" +
          selected.mainCategory.replace(/[^a-zA-Z0-9 ]/g, "") +
          "/" +
          selectedSub.name.replace(/[^a-zA-Z0-9 ]/g, "") +
          "/" +
          selectedSubSub.name.replace(/[^a-zA-Z0-9 ]/g, "") +
          "/" +
          formik.values.itemName.replace(/[^a-zA-Z0-9 ]/g, "") +
          "/productImages/" +
          uuidv4();

        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };

        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then((res) => {
                // console.log(res.url + path)
                // // imagesArray.push(preview)
                setImagesArray([...imagesArray, preview]);
                setImageUploadSuccess(true);
                setSelectedImage(null);
                setPreview(null);
                setTimeout(() => {
                  setImageUploadSuccess(false);
                }, 2000);
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };

  useEffect(() => {
    if (!selectedImage) {
      setPreview(null);
      return;
    }
    const objectUrl: string = URL.createObjectURL(selectedImage);
    setPreview(objectUrl);
    // return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  useEffect(() => {
    const index = categories.indexOf(selected);
    setSubCategories(categories[index].subCategories);
    setSelectedSub(categories[index].subCategories[0]);
  }, [selected]);

  useEffect(() => {
    const index = subCategories.indexOf(selectedSub);
    setSubSubCategories(selectedSub.sub_subCategories);
    setSelectedSubSub(selectedSub.sub_subCategories[index]);
  }, [selectedSub]);

  return (
    <div className="w-full h-[85vh] overflow-y-auto bg-white p-3">
      <div className="max-w-[650px] mx-auto">
       <div className="flex items-center justify-between">
       <h2 className="text-center font-semibold text-2xl mt-2">
          Upload Products
        </h2>
        <button
          className="text-xs py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
          onClick={() => navigate("/home/upload-product/preview")}
        >
          View Images
        </button>
       </div>
        <form className="pt-4" onSubmit={formik.handleSubmit}>
          <div className="space-y-3">
            <div className="w-full">
              <Input
                formik={formik}
                name="itemName"
                label="Product Name"
                type="text"
                placeHolder="Please enter item name"
              />
              {formik.errors.itemName && (
                <InputMessage message={formik.errors.itemName} />
              )}
            </div>
            <div className="w-full">
              <label className="block mb-1 text-xs font-normal text-gray-800">
                Details
              </label>
              <textarea
                id=""
                name="details"
                value={formik.values.details}
                onChange={formik.handleChange}
                className="h-[100px] text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                placeholder="Please enter details of item"
              ></textarea>
              {formik.errors.details && (
                <InputMessage message={formik.errors.details} />
              )}
            </div>

            <div className="grid grid-cols-2 gap-3">
              <div className="w-full">
                <label className="block mb-1 text-xs font-normal text-gray-800">
                  Category
                </label>
                <Listbox value={selected} onChange={setSelected}>
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 sm:text-sm">
                      <span className="block truncate">
                        {selected.mainCategory}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <CgChevronDown className="h-5 w-5 text-gray-400" />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base  border border-gray-300  focus:outline-none sm:text-sm z-20">
                        {categories.map((category, categoryIdx) => (
                          <Listbox.Option
                            key={categoryIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-blue-100 text-blue-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={category}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {category.mainCategory}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                    <MdCheck className="h-5 w-5" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>

              <div className="w-full">
                <label className="block mb-1 text-xs font-normal text-gray-800">
                  Sub category
                </label>
                <Listbox value={selectedSub} onChange={setSelectedSub}>
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 sm:text-sm">
                      <span className="block truncate">
                        {selectedSub?.name}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <CgChevronDown className="h-5 w-5 text-gray-400" />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base  border border-gray-300  focus:outline-none sm:text-sm z-40">
                        {subCategories?.map((category, categoryIdx) => (
                          <Listbox.Option
                            key={categoryIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-blue-100 text-blue-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={category}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {category?.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                    <MdCheck className="h-5 w-5" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <div className="w-full">
              <label className="block mb-1 text-xs font-normal text-gray-800">
                Sub sub category
              </label>
              <Listbox value={selectedSubSub} onChange={setSelectedSubSub}>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 sm:text-sm">
                    <span className="block truncate">
                      {selectedSubSub?.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <CgChevronDown className="h-5 w-5 text-gray-400" />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base  border border-gray-300  focus:outline-none sm:text-sm z-40">
                      {subSubCategories?.map((category, categoryIdx) => (
                        <Listbox.Option
                          key={categoryIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-blue-100 text-blue-900"
                                : "text-gray-900"
                            }`
                          }
                          value={category}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {category?.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                  <MdCheck className="h-5 w-5" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>

            <div>
              <label className="block mb-1 text-xs font-normal text-gray-800">
                Image
              </label>
              <div className="flex items-center justify-evenly gap-2">
                {imagesArray.map((imagePreview: string, index: number) => (
                  <div className="w-full h-[80px] relative" key={index}>
                    <img
                      src={imagePreview}
                      alt=""
                      className="w-full h-full object-contain"
                    />
                    <div
                      className={classNames(
                        "absolute z-10 inset-0 m-auto font-semibold rounded-full bg-red-400 hover:bg-red-600 w-6 h-6 flex items-center justify-center cursor-pointer",
                        selectedImage ? "hidden" : ""
                      )}
                      onClick={() => {
                        setImagesArray((prevState) =>
                          prevState.filter((i) => i !== imagePreview)
                        );
                      }}
                    >
                      <span className="text-[13px] text-white">
                        <MdClear size={22} />
                      </span>
                    </div>
                  </div>
                ))}
                {!selectedImage || !preview ? (
                  <div
                    className={classNames(
                      "w-full",
                      imagesArray.length > 3 ? "hidden" : ""
                    )}
                  >
                    <label
                      htmlFor="dropzone-file"
                      className="w-[80px] h-[80px]  bg-gray-100 flex items-center justify-center cursor-pointer"
                    >
                      <AiOutlinePlus className="text0gray-800" size={28} />
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        accept="image/jpeg, image/png, image/jpg"
                        onChange={imageHandler}
                      />
                    </label>
                  </div>
                ) : null}
              </div>
              {!selectedImage || !preview ? null : (
                <div className="mt-2 flex items-center gap-4">
                  <img
                    src={preview}
                    alt=""
                    className="w-[50%] h-[80px] object-contain"
                  />
                  <div>
                    <div className="flex items-center flex-col gap-2">
                      <button
                        type="button"
                        className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-red-700 bg-transparent border border-red-700 rounded-md"
                        onClick={(e) => {
                          setSelectedImage(null);
                          setPreview(null);
                          setImageUploadSuccess(false);
                        }}
                      >
                        <span>Cancel</span>
                        <span>
                          <MdClear />
                        </span>
                      </button>
                      <button
                        disabled={
                          !selectedImage || selectedImage === null || isLoading
                        }
                        type="button"
                        className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-white border rounded-md bg-blue-700 hover:bg-blue-800 border-primary"
                        onClick={() => {
                          handleCompressedUpload(selectedImage);
                        }}
                      >
                        <span>Upload</span>{" "}
                        {isLoading ? (
                          <ImSpinner2
                            size={20}
                            className="mx-auto animate-spin"
                          />
                        ) : (
                          <AiOutlineCloudUpload />
                        )}
                      </button>
                    </div>
                    {imageUploadSuccess && (
                      <p className="mt-2 text-xs text-center text-green-500">
                        Upload successful
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center mt-4">
            <button
              disabled={isLoadingSubmit}
              type="submit"
              className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
            >
              {isLoadingSubmit ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Save Product"
              )}
            </button>
          </div>
          <p className="mt-1 text-center text-green-500 capitalize">
            {success && <small>Saved successfully!</small>}
          </p>
        </form>
      </div>
    </div>
  );
};

export default UploadProduct;
