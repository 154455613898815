export enum ChartDataInterval {
    Daily = "daily",
    Weekly = "weekly",
    Monthly = "monthly",
};

export enum ButtonActive {
    Rating = "ratings",
    Sentiment = "sentiments",
    Inline = "inline",
    PopUp = "popup",
    Floating = "floating",
};

export enum SupportedImageFormat {
    PNG = "png",
    JPG = "jpg",
    SVG = "svg",
};
  
export enum RatingActive {
    Stars = "stars",
    Emoji = "emoji",
    Toggle = "toggle",
}