import moment from "moment";
import Modal from "../../../../../components/Dashboard/Modal";
import {
  BsThreeDotsVertical,
} from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAllTickets,
  getTicketCount,
  updateTicketPriorityLevel,
  updateTicketStatus,
} from "../../../../../api/Tickets";
import { ImSpinner3 } from "react-icons/im";
import { UserProfileModel } from "../../../../../models";
import { useAppSelector } from "../../../../../hooks";
import { BiChevronDown, BiChevronUp, BiFilterAlt } from "react-icons/bi";
import { classNames } from "../../../../../utils";
import { useState } from "react";
import { Link } from "react-router-dom";

export enum ButtonActive {
  Priority = "priority",
  Status = "status",
  Source = "source",
}

const defaultObject = {
  priorityLevel: ["Low", "Medium", "High"],
  ticketStatus: "None",
  ticketSource: [
    "site_data",
    "web",
    "facebook",
    "sms",
    "voice",
    "twitter",
    "instagram",
    "linkedin",
  ],
  current: 0,
  paramsArray: [""],
};

type DropdownTypes = "priorityDropdown" | "statusDropdown" | "sourceDropdown";
type DropdownTypes1 = "ticketStatus" | "ticketPriority";


const TicketsPage = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const platformKey = `${user.platformAccountId}&${user.email}`;
  let dateRange: string = "March - Present";
  const [truncateId, setTruncateId] = useState(-1);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState(0);
  const [modalObject, setModalObject] = useState({
    email: "",
    phoneNumber: "",
    complaint: "",
  });
  let ticketsCountObject = {
    resolvedTickets: 0,
    pendingTickets: 0,
    totalTickets: 0,
  };

  const [dropdown, setDropdown] = useState<DropdownTypes | null>(null);
  const [dropdown1, setDropdown1] = useState<DropdownTypes1 | null>(null);

  const [active1, setActive1] = useState<ButtonActive | null>(null);
  const [active2, setActive2] = useState<ButtonActive | null>(null);

  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [selectedPriority, setSelectedPriority] = useState("Priority");

  const [defaultSortingObject, setDefaultSortingObject] =
    useState(defaultObject);
  const [backPressed, setBackPressed] = useState(false);

  let ticketsArr: any[] = [];
  let ticketsSaved: any[] = [];
  const [paramsArrays, setParamsArrays] = useState([""]);

  const ticketss = useQuery<any, Error>({
    queryKey: ["getAllTicketss"],
    queryFn: async () => {
      const payload = {
        platformKey,
        assignee: "None",
        ticketStatus: defaultSortingObject.ticketStatus,
        pagination_key: paramsArrays[defaultSortingObject.current],
        priorityLevel: defaultSortingObject.priorityLevel,
        ticketSource: defaultSortingObject.ticketSource,
      };
      const response = await getAllTickets(payload);
      return response.data;
    },
    onSuccess: (data) => { },
  });

  if (ticketss.data) {
    ticketsSaved = ticketss.data.Platform_Tickets.Items;
  }

  const tickets = useQuery<any, Error>({
    queryKey: ["getAllTickets", defaultSortingObject, filter, backPressed],
    queryFn: async () => {
      const payload = {
        platformKey,
        assignee: "None",
        ticketStatus: defaultSortingObject.ticketStatus,
        pagination_key: paramsArrays[defaultSortingObject.current],
        priorityLevel: defaultSortingObject.priorityLevel,
        ticketSource: defaultSortingObject.ticketSource,
      };
      const response = await getAllTickets(payload);
      return response.data;
    },
    onSuccess: (data) => {
      setDropdown(null);
      setDropdown1(null);
      setActiveDropdown(null);
      if (backPressed === false) {
        setParamsArrays((prevState) => [...prevState, data.LastEvaluatedKey]);
      }
      if (backPressed === true) {
        setParamsArrays(paramsArrays.slice(0, -1));
      }
    },
  });

  if (tickets.data) {
    ticketsArr = tickets.data.Platform_Tickets.Items;
    dateRange = `${moment(ticketsArr[(ticketsArr.length - 1)]?.uploadTimestamp).format("MMMM")} - Present`;
  }

  const ticketsCount = useQuery<any, Error>({
    queryKey: ["getTicketCount"],
    queryFn: async () => {
      const response = await getTicketCount(user.queryKey);
      return response.data;
    },
  });

  const handleUpdateTicketStatus = useMutation({
    mutationFn: async (data: any) => {
      const body = {
        platformKey,
        ticketId: data.ticketId,
        customerId: data.customerId,
      };

      return await updateTicketStatus(body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getTicketCount"]);
      if (filter === 0) {
        setFilter(1);
      } else {
        setFilter(0);
      }
      queryClient.invalidateQueries(["getAllTickets"]);
    },
  });

  const handleUpdateTicketPriorityLevel = useMutation({
    mutationFn: async (data: any) => {
      const body = {
        platformKey,
        ticketId: data.ticketId,
        priorityStatus: data.priorityStatus,
      };
      return await updateTicketPriorityLevel(body);
    },
    onSuccess: () => {
      if (filter === 0) {
        setFilter(1);
      } else {
        setFilter(0);
      }
      queryClient.invalidateQueries(["getAlTickets"]);
    },
  });

  if (ticketsCount.data) {
    // console.log(ticketsCount.data)
    const { resolved_ticket_count, pending_ticket_count } = ticketsCount.data;
    ticketsCountObject = {
      resolvedTickets: resolved_ticket_count ?? 0,
      pendingTickets: pending_ticket_count ?? 0,
      totalTickets: pending_ticket_count + resolved_ticket_count ?? 0,
    };
  }

  return (
    <>
      <Modal
        show={showModal}
        onToggle={() => {
          setShowModal((prevState) => !prevState);
        }}
      >
        <div className="relative p-3 text-sm text-black sm:py-6 sm:px-8 spce-y-3 sm:space-y-6">
          <div>
            <p className="text-lg font-semibold">Email</p>
            <p>{modalObject.email}</p>
          </div>
          <div className="">
            <p className="text-lg font-semibold">Phone Number</p>
            <p>{modalObject.phoneNumber}</p>
          </div>
          <div>
            <p className="text-lg font-semibold">Complaint</p>
            <p>{modalObject.complaint}</p>
          </div>
        </div>
      </Modal>
      <div className="h-[85vh] bg-white rounded-md py-3 overflow-hidden relative flex flex-col space-y-2">

        <div className="sticky top-0 z-10">
          <div className="hidden max-w-full grid-cols-3 gap-4 mx-auto mb-4 sm:grid">
            <div className="border rounded h-[120px] flex items-center justify-center">
              <div className="space-y-1 text-center">
                <p className="text-xs font-semibold text-gray-800">
                  Pending tickets
                </p>
                <p className="text-4xl font-bold text-black">
                  {ticketsCountObject.pendingTickets}
                </p>
              </div>
            </div>
            <div className="border rounded h-[120px] flex items-center justify-center">
              <div className="space-y-1 text-center">
                <p className="text-xs font-semibold text-gray-800">
                  Resolved tickets
                </p>
                <p className="text-4xl font-bold text-black">
                  {ticketsCountObject.resolvedTickets}
                </p>
              </div>
            </div>
            <div className="border rounded h-[120px] flex items-center justify-center">
              <div className="space-y-1 text-center">
                <p className="text-xs font-semibold text-gray-800">
                  Total tickets
                </p>
                <p className="text-4xl font-bold text-black">
                  {ticketsCountObject.totalTickets}
                </p>
              </div>
            </div>
          </div>

          <div className="flex max-w-full gap-2 pb-2 mx-auto mb-3 overflow-x-auto sm:hidden ticketsCardsScrollbar">
            <div className="border rounded h-[120px] flex items-center justify-center min-w-[300px]">
              <div className="space-y-1 text-center">
                <p className="text-xs font-semibold text-gray-800">
                  Pending tickets
                </p>
                <p className="text-4xl font-bold text-black">
                  {ticketsCountObject.pendingTickets}
                </p>
              </div>
            </div>
            <div className="border rounded h-[120px] flex items-center justify-center min-w-[300px]">
              <div className="space-y-1 text-center">
                <p className="text-xs font-semibold text-gray-800">
                  Resolved tickets
                </p>
                <p className="text-4xl font-bold text-black">
                  {ticketsCountObject.resolvedTickets}
                </p>
              </div>
            </div>
            <div className="border rounded h-[120px] flex items-center justify-center min-w-[300px]">
              <div className="space-y-1 text-center">
                <p className="text-xs font-semibold text-gray-800">
                  Total tickets
                </p>
                <p className="text-4xl font-bold text-black">
                  {ticketsCountObject.totalTickets}
                </p>
              </div>
            </div>
          </div>

          {/* {ticketsSaved.length > 0 && (
            <div className="flex items-center gap-4">
              <div className="flex flex-wrap items-center gap-3 font-semibold lg:gap-5">
                <div className="relative">
                  <button
                    onClick={() => {
                      setActive1(ButtonActive.Status);
                      setDropdown((prevState) =>
                        prevState === "statusDropdown" ? null : "statusDropdown"
                      );
                    }}
                    className={
                      active1 === ButtonActive.Status
                        ? "px-2 sm:px-4 py-2 rounded-md text-xs bg-primary text-white flex items-center gap-2 sm:gap-4"
                        : "px-2 sm:px-4 py-2 rounded-md text-xs bg-gray-100 flex items-center gap-2 sm:gap-4"
                    }
                  >
                    <span>{selectedStatus}</span> <BiChevronDown size={22} />
                  </button>
                  {dropdown === "statusDropdown" && (
                    <div className="absolute z-20 text-sm border rounded top-10 bg-gray-50">
                      <button
                        onClick={() => {
                          setActive1(ButtonActive.Status);
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            ticketStatus: "None",
                            current: 0,
                            paramsArray: [""],
                          });
                          setParamsArrays([""]);
                          setBackPressed(false);
                          setSelectedStatus("All Status");
                          if (filter === 0) {
                            setFilter(1);
                          } else {
                            setFilter(0);
                          }
                        }}
                        className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
                      >
                        All Status
                      </button>
                      <button
                        onClick={() => {
                          setActive1(ButtonActive.Status);
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            ticketStatus: "Pending",
                            current: 0,
                            paramsArray: [""],
                          });
                          setParamsArrays([""]);
                          setBackPressed(false);
                          setSelectedStatus("Resolved");
                        }}
                        className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
                      >
                        Resolved
                      </button>
                      <button
                        onClick={() => {
                          setParamsArrays([""]);
                          setBackPressed(false);
                          setActive1(ButtonActive.Status);
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            ticketStatus: "Resolved",
                            current: 0,
                            paramsArray: [""],
                          });
                          setSelectedStatus("Pending");
                        }}
                        className="w-full px-4 py-2 hover:bg-primary hover:text-white"
                      >
                        Pending
                      </button>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <button
                    onClick={() => {
                      setActive2(ButtonActive.Priority);
                      setDropdown((prevState) =>
                        prevState === "priorityDropdown"
                          ? null
                          : "priorityDropdown"
                      );
                    }}
                    className={
                      active2 === ButtonActive.Priority
                        ? "px-2 sm:px-4 py-2 rounded-md text-xs bg-primary text-white flex items-center gap-2 sm:gap-4"
                        : "px-2 sm:px-4 py-2 rounded-md text-xs bg-gray-100 flex items-center gap-2 sm:gap-4"
                    }
                  >
                    <span>{selectedPriority}</span> <BiChevronDown size={22} />
                  </button>
                  {dropdown === "priorityDropdown" && (
                    <div className="text-sm absolute top-10 z-20 border rounded w-[120px] bg-gray-50">
                      <button
                        onClick={() => {
                          setBackPressed(false);
                          setParamsArrays([""]);
                          setActive2(ButtonActive.Priority);
                          setSelectedPriority("All Priorities");
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            priorityLevel: ["Low", "Medium", "High"],
                            current: 0,
                          });
                        }}
                        className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
                      >
                        All priorities
                      </button>
                      <button
                        onClick={() => {
                          setBackPressed(false);
                          setParamsArrays([""]);
                          setActive2(ButtonActive.Priority);
                          setSelectedPriority("High");
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            priorityLevel: ["High"],
                            current: 0,
                            paramsArray: [""],
                          });
                        }}
                        className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
                      >
                        High
                      </button>
                      <button
                        onClick={() => {
                          setParamsArrays([""]);
                          setBackPressed(false);
                          setActive2(ButtonActive.Priority);
                          setSelectedPriority("Medium");
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            priorityLevel: ["Medium"],
                            current: 0,
                          });
                        }}
                        className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
                      >
                        Medium
                      </button>
                      <button
                        onClick={() => {
                          setBackPressed(false);
                          setParamsArrays([""]);
                          setActive2(ButtonActive.Priority);
                          setSelectedPriority("Low");
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            priorityLevel: ["Low"],
                            current: 0,
                            paramsArray: [""],
                          });
                        }}
                        className="w-full px-4 py-2 hover:bg-primary hover:text-white"
                      >
                        Low
                      </button>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <button
                    onClick={() => {
                      setDropdown((prevState) =>
                        prevState === "sourceDropdown" ? null : "sourceDropdown"
                      );
                    }}
                    className={
                      active2 === ButtonActive.Source
                        ? "px-2 sm:px-4 py-2 rounded-md text-xs bg-primary text-white flex items-center gap-2 sm:gap-4"
                        : "px-2 sm:px-4 py-2 rounded-md text-xs bg-gray-100 flex items-center gap-2 sm:gap-4"
                    }
                  >
                    <span>Source</span> <BiChevronDown size={22} />
                  </button>
                  {dropdown === "sourceDropdown" && (
                    <div className="text-sm absolute top-10 z-20 border rounded w-[120px] bg-gray-50">
                      <button
                        onClick={() => {
                          setParamsArrays([""]);
                          setBackPressed(false);
                          // setActive2(ButtonActive.Priority);
                          setSelectedPriority("All Priorities");
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            ticketSource: defaultObject.ticketSource,
                          });
                          if (filter === 0) {
                            setFilter(1);
                          } else {
                            setFilter(0);
                          }
                        }}
                        className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
                      >
                        All sources
                      </button>
                      <button
                        onClick={() => {
                          // setActive2(ButtonActive.Priority);
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            ticketSource: ["site_data"],
                          });
                        }}
                        className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
                      >
                        QR Code
                      </button>
                      <button
                        onClick={() => {
                          // setActive2(ButtonActive.Priority);
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            ticketSource: ["web"],
                          });
                        }}
                        className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
                      >
                        Website
                      </button>
                      <button
                        disabled
                        onClick={() => {
                          // setActive2(ButtonActive.Priority);
                          setSelectedPriority("Low");
                          setDefaultSortingObject({
                            ...defaultSortingObject,
                            ticketSource: ["sms"],
                          });
                        }}
                        className="w-full px-4 py-2 hover:bg-primary hover:text-white"
                      >
                        SMS
                      </button>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => {
                    setBackPressed(false);
                    setParamsArrays([""]);
                    setActive1(null);
                    setActive2(null);
                    setSelectedPriority("Priority");
                    setSelectedStatus("Status");
                    setDefaultSortingObject(defaultObject);
                    if (filter === 0) {
                      setFilter(1);
                    } else {
                      setFilter(0);
                    }
                  }}
                  className="hidden sm:block text-xs pl-4 border-l-[2px] border-gray-600"
                >
                  Clear all fields
                </button>
                <MdRefresh
                  size={20}
                  className="cursor-pointer sm:hidden"
                  onClick={() => {
                    setBackPressed(false);
                    setParamsArrays([""]);
                    setActive1(null);
                    setActive2(null);
                    setSelectedPriority("Priority");
                    setSelectedStatus("Status");
                    setDefaultSortingObject(defaultObject);
                    if (filter === 0) {
                      setFilter(1);
                    } else {
                      setFilter(0);
                    }
                  }}
                />
              </div>
            </div>
          )} */}

          {ticketsSaved.length > 0 && (<div className="flex items-center justify-start pt-2 pb-5 space-x-3 sm:space-x-5 sm:justify-end">
            <button className="inline-block px-4 py-1.5 text-sm font-semibold text-gray-500 bg-transparent border border-gray-300 rounded-md sm:py-2 lg:px-3 lg:py-2 sm:hidden outline-none">
              {dateRange}
            </button>
            <button onClick={() => {
              if (selectedStatus === "Resolved") {
                setBackPressed(false);
                setParamsArrays([""]);
                setActive1(null);
                setActive2(null);
                setSelectedPriority("Priority");
                setSelectedStatus("Status");
                setDefaultSortingObject(defaultObject);
                if (filter === 0) {
                  setFilter(1);
                } else {
                  setFilter(0);
                }
                return;
              }
              setActive1(ButtonActive.Status);
              setDefaultSortingObject({
                ...defaultSortingObject,
                ticketStatus: "Pending",
                current: 0,
                paramsArray: [""],
              });
              setParamsArrays([""]);
              setBackPressed(false);
              setSelectedStatus("Resolved");
            }} className={classNames("px-4 py-1.5 sm:py-2 text-xs font-semibold border rounded-md lg:px-3 lg:py-2 outline-none", selectedStatus === "Resolved" ? "text-blue-500 bg-blue-100 border-blue-500" : "border-gray-300 text-gray-500 bg-transparent")}>
              Resolved
            </button>
            <button className="hidden text-center w-40 py-1.5 sm:py-2 text-xs font-semibold text-gray-500 bg-transparent border border-gray-300 rounded-md lg:px-3 lg:py-2 sm:inline-block outline-none">
              {dateRange}
            </button>
          </div>)}
        </div>

        {/* for tab and desktops */}
        <div className="relative flex-1 h-full overflow-x-auto overflow-y-auto">
          {tickets.status === "loading" ||
            tickets.isRefetching ||
            handleUpdateTicketStatus.isLoading ||
            handleUpdateTicketPriorityLevel.isLoading ? (
            <div className="flex items-center justify-start py-5">
              <ImSpinner3
                size={18}
                className="mx-auto animate-spin fill-primary"
              />
            </div>
          ) : tickets.status === "error" ? (
            <p className="mt-8 font-semibold text-center text-gray-400">
              {tickets.error.message}
            </p>
          ) : ticketsArr.length > 0 ? (
            <>
              <table className="hidden w-full overflow-auto text-xs text-left text-gray-500 border rounded-md sm:table">
                <thead className="text-gray-700 capitalize bg-gray-50">
                  <tr className="text-xs text-center capitalize">
                    <th scope="col" className="py-3 sm:px-6 whitespace-nowrap">
                      Date Created
                    </th>
                    <th scope="col" className="py-3 sm:px-6">
                      Email
                    </th>
                    <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                      Phone
                    </th>
                    <th scope="col" className="px-2 py-3 sm:px-6">
                      Complaint
                    </th>
                    <th scope="col" className="flex items-center justify-center px-2 py-3 sm:px-6">
                      <div className="relative group">
                        <button onClick={() => { }} className="flex items-center justify-center space-x-1">
                          <span>Priority</span>
                          <BiFilterAlt size={12} />
                        </button>
                        <div className="absolute top-0 z-10 hidden mt-8 origin-center bg-white rounded-md shadow -right-9 group-focus-within:block w-36 shadow-gray-300">
                          <div className="px-1 py-2 text-sm text-gray-700">
                            <button
                              className="w-full px-4 py-2 transition duration-200 ease-linear rounded hover:bg-gray-50"
                              onClick={() => {
                                setBackPressed(false);
                                setParamsArrays([""]);
                                setActive2(ButtonActive.Priority);
                                setSelectedPriority("All Priorities");
                                setDefaultSortingObject({
                                  ...defaultSortingObject,
                                  priorityLevel: ["Low", "Medium", "High"],
                                  current: 0,
                                });
                              }}
                            >
                              <span>All</span>
                            </button>
                            <button
                              className="flex items-center justify-center w-full px-4 py-2 space-x-0.5 transition duration-200 ease-linear rounded hover:bg-gray-50"
                              onClick={() => {
                                setBackPressed(false);
                                setParamsArrays([""]);
                                setActive2(ButtonActive.Priority);
                                setSelectedPriority("High");
                                setDefaultSortingObject({
                                  ...defaultSortingObject,
                                  priorityLevel: ["High"],
                                  current: 0,
                                  paramsArray: [""],
                                });
                              }}
                            >
                              <BiChevronUp size={18} className="fill-red-500" />
                              <span>High</span>
                            </button>
                            <button
                              className="flex items-center justify-center w-full px-4 py-2 space-x-1 transition duration-200 ease-linear rounded hover:bg-gray-50"
                              onClick={() => {
                                setParamsArrays([""]);
                                setBackPressed(false);
                                setActive2(ButtonActive.Priority);
                                setSelectedPriority("Medium");
                                setDefaultSortingObject({
                                  ...defaultSortingObject,
                                  priorityLevel: ["Medium"],
                                  current: 0,
                                });
                              }}
                            >
                              <span className="text-sm font-extrabold text-orange-500">=</span>
                              <span>Medium</span>
                            </button>
                            <button
                              className="flex items-center justify-center w-full px-4 py-2 space-x-0.5 transition duration-200 ease-linear rounded hover:bg-gray-50"
                              onClick={() => {
                                setBackPressed(false);
                                setParamsArrays([""]);
                                setActive2(ButtonActive.Priority);
                                setSelectedPriority("Low");
                                setDefaultSortingObject({
                                  ...defaultSortingObject,
                                  priorityLevel: ["Low"],
                                  current: 0,
                                  paramsArray: [""],
                                });
                              }}
                            >
                              <BiChevronDown size={18} className="fill-green-500" />
                              <span>Low</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th scope="col" className="hidden px-6 py-3 sm:table-cell"></th>
                  </tr>
                </thead>
                <tbody className="">
                  {ticketsArr.map((ticket: any, index: number) => (
                    <tr className={classNames("border-b", handleUpdateTicketStatus.isLoading && "opacity-50")} key={ticket.ticketId}>
                      <td className="px-2 py-2 border-x">
                        <p className="text-center whitespace-nowrap">
                          {moment(ticket.uploadTimestamp).format(
                            "MMM Do, YYYY"
                          )}
                        </p>
                      </td>
                      <td className="px-6 py-2 border-x">
                        <p className="text-left">
                          {ticket.customerId}
                        </p>
                      </td>
                      <td className="px-6 py-2 border-x">
                        <p className="text-center">
                          {ticket.customerNumber}
                        </p>
                      </td>
                      <td className="px-6 py-2 border-x relative min-w-[300px]" >
                        <p className="text-left ">
                          {/* {ticket.complaint}  */}
                          {ticket.complaint.length > 100 && truncateId !== ticket.ticketId ?  `${ticket.complaint.substring(0,97)} ...` : truncateId === ticket.ticketId ? `${ticket.complaint}` : ticket.complaint }
                          <span className={classNames("absolute bottom-2 right-2 cursor-pointer",ticket.complaint.length > 100 ? "" : "hidden")} onClick={() => {
                            if(truncateId === ticket.ticketId) {
                              setTruncateId(-1);
                              return;
                            }
                            setTruncateId(ticket.ticketId);
                          }}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.4993 18.9587H7.49935C2.97435 18.9587 1.04102 17.0253 1.04102 12.5003V7.50032C1.04102 2.97533 2.97435 1.04199 7.49935 1.04199H12.4993C17.0243 1.04199 18.9577 2.97533 18.9577 7.50032V12.5003C18.9577 17.0253 17.0243 18.9587 12.4993 18.9587ZM7.49935 2.29199C3.65768 2.29199 2.29102 3.65866 2.29102 7.50032V12.5003C2.29102 16.342 3.65768 17.7087 7.49935 17.7087H12.4993C16.341 17.7087 17.7077 16.342 17.7077 12.5003V7.50032C17.7077 3.65866 16.341 2.29199 12.4993 2.29199H7.49935Z" fill="#292D32" />
                            <path d="M10.0001 12.4249C9.8418 12.4249 9.68346 12.3666 9.55846 12.2416L6.6168 9.29993C6.37513 9.05827 6.37513 8.65827 6.6168 8.4166C6.85846 8.17493 7.25846 8.17493 7.50013 8.4166L10.0001 10.9166L12.5001 8.4166C12.7418 8.17493 13.1418 8.17493 13.3835 8.4166C13.6251 8.65827 13.6251 9.05827 13.3835 9.29993L10.4418 12.2416C10.3168 12.3666 10.1585 12.4249 10.0001 12.4249Z" fill="#292D32" />
                          </svg></span></p>
                      </td>
                      <td className="px-6 py-2 border-x">
                        <p className={classNames("text-center w-16 mx-auto py-1.5 rounded border text-[10px] uppercase", ticket.priorityLevel === "High" ? "bg-red-100 border-red-500 text-red-500" : ticket.priorityLevel === "Medium" ? "bg-blue-100 border-blue-500 text-blue-500" : "bg-gray-100 border-gray-500 text-gray-500")}>
                          {ticket.priorityLevel}
                        </p>
                      </td>
                      <td className="px-6 py-2 border-x">
                        <div className="relative group">
                          <button onClick={() => {
                            // if (ticket.ticketStatus !== "Resolved") {
                            //   return;
                            // }
                          }} disabled={handleUpdateTicketStatus.isLoading}>
                            <BsThreeDotsVertical
                              size={18}
                              className="text-gray-600 cursor-pointer"
                            />
                          </button>
                          <div className="absolute top-0 right-0 z-10 hidden mt-6 origin-top-right bg-white rounded-md shadow group-focus-within:block w-36 shadow-gray-300">
                            <div className="py-2 text-xs font-medium text-gray-700 text-center px-1.5">
                              {ticket.ticketStatus !== "Resolved" && (<button
                                className="w-full px-4 py-2 transition duration-200 ease-linear rounded hover:bg-gray-100"
                                onClick={() => {
                                  handleUpdateTicketStatus.mutate({
                                    ticketId: ticket.ticketId,
                                    customerId: ticket.customerId,
                                  });
                                  // if (filter === 0) {
                                  //   setFilter(1);
                                  // } else {
                                  //   setFilter(0);
                                  // }
                                  // setDropdown1(null);
                                  // setActiveDropdown(null);
                                }}
                                disabled={handleUpdateTicketStatus.isLoading}
                              >
                                Resolve
                              </button>)}
                              <Link to={`/feedback-analysis/messages/new-message/${ticket.customerId}`} className="block w-full px-4 py-2 transition duration-200 ease-linear rounded hover:bg-gray-100">Message</Link>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {
                ticketsArr.map((ticket: any, index: number) => (
                  <div key={ticket.ticketId} className="block sm:hidden text-sm py-3 px-2.5 space-y-2.5 border-t border-gray-200 w-full">
                    <div className="flex items-start justify-between">
                      <div className="-mt-1 space-y-1 text-gray-500">
                        <p className="w-10/12">{ticket.customerId}</p>
                        <p>{ticket.customerNumber}</p>
                        <p className={classNames("w-14 text-center p-1 rounded border uppercase text-xs", ticket.priorityLevel === "High" ? "bg-red-100 border-red-500 text-red-500" : ticket.priorityLevel === "Medium" ? "bg-blue-100 border-blue-500 text-blue-500" : "bg-gray-100 border-gray-500 text-gray-500")}>
                          {ticket.priorityLevel}
                        </p>
                      </div>
                      <div className="flex items-start justify-end space-x-1 text-xs text-gray-500">
                        <div className="-mt-0.5">{moment(ticket.uploadTimestamp).format(
                          "MMM Do, YYYY"
                        )}</div>
                        <div className="relative group">
                          <button onClick={() => {
                            if (ticket.ticketStatus !== "Resolved") {
                              return;
                            }
                          }} disabled={handleUpdateTicketStatus.isLoading}>
                            <BsThreeDotsVertical
                              size={17}
                              className="text-gray-600 cursor-pointer"
                            />
                          </button>
                          <div className="absolute top-0 right-0 z-10 hidden mt-6 origin-top-right bg-white rounded-md shadow group-focus-within:block w-36 shadow-gray-300">
                            <div className="py-2 text-sm font-medium text-gray-700 text-center px-1.5">
                              {ticket.ticketStatus !== "Resolved" && (<button
                                className="w-full px-4 py-2 transition duration-200 ease-linear rounded hover:bg-gray-100"
                                onClick={() => {
                                  handleUpdateTicketStatus.mutate({
                                    ticketId: ticket.ticketId,
                                    customerId: ticket.customerId,
                                  });
                                  // if (filter === 0) {
                                  //   setFilter(1);
                                  // } else {
                                  //   setFilter(0);
                                  // }
                                  // setDropdown1(null);
                                  // setActiveDropdown(null);
                                }}
                                disabled={handleUpdateTicketStatus.isLoading}
                              >
                                Resolve
                              </button>)}
                              <Link to={`/feedback-analysis/messages/new-message/${ticket.customerId}`} className="block w-full px-4 py-2 transition duration-200 ease-linear rounded hover:bg-gray-100">Message</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-sm">{ticket.complaint}</p>
                  </div>
                ))
              }
            </>) : (
            <p className="mt-8 text-sm font-semibold text-center text-gray-400">
              You don't have any complaints yet
            </p>
          )}
        </div>

        {/* for tab and desktops */}
        {ticketsSaved.length > 0 && (
          <div className="hidden sm:block">
            <div className="flex items-center justify-end mt-2">
              <div className="flex items-center space-x-2.5 text-xs">
                <button
                  disabled={defaultSortingObject.current === 0}
                  onClick={() => {
                    setBackPressed(true);
                    setDefaultSortingObject({
                      ...defaultSortingObject,
                      current: defaultSortingObject.current - 1,
                    });
                  }}
                  className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
                >
                  <span>Prev</span>{" "}
                </button>
                <button
                  disabled={paramsArrays[paramsArrays.length - 1] === ""}
                  onClick={() => {
                    setDefaultSortingObject({
                      ...defaultSortingObject,
                      current: defaultSortingObject.current + 1,
                    });
                    setBackPressed(false);
                  }}
                  className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
                >
                  <span>Next</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* for small Screen */}
      {ticketsSaved.length > 0 && (
        <div className="flex items-center justify-end mt-2 sm:hidden">
          <div className="flex items-center space-x-2.5 text-sm">
            <button
              disabled={defaultSortingObject.current === 0}
              onClick={() => {
                setBackPressed(true);
                setDefaultSortingObject({
                  ...defaultSortingObject,
                  current: defaultSortingObject.current - 1,
                });
              }}
              className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
            >
              <span>Prev</span>{" "}
            </button>
            <button
              disabled={paramsArrays[paramsArrays.length - 1] === ""}
              onClick={() => {
                setDefaultSortingObject({
                  ...defaultSortingObject,
                  current: defaultSortingObject.current + 1,
                });
                setBackPressed(false);
              }}
              className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
            >
              <span>Next</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

// const TicketsPage = () => {
//   const queryClient = useQueryClient();
//   const user: UserProfileModel = useAppSelector((state) => state.user.profile);
//   const platformKey = `${user.platformAccountId}&${user.email}`;

//   const [activeDropdown, setActiveDropdown] = useState();

//   const [showModal, setShowModal] = useState(false);
//   const [filter, setFilter] = useState(0);
//   const [modalObject, setModalObject] = useState({
//     email: "",
//     phoneNumber: "",
//     complaint: "",
//   });
//   let ticketsCountObject = {
//     resolvedTickets: 0,
//     pendingTickets: 0,
//     totalTickets: 0,
//   };

//   const [dropdown, setDropdown] = useState<DropdownTypes | null>(null);
//   const [dropdown1, setDropdown1] = useState<DropdownTypes1 | null>(null);

//   const [active1, setActive1] = useState();
//   const [active2, setActive2] = useState();

//   const [selectedStatus, setSelectedStatus] = useState("Status");
//   const [selectedPriority, setSelectedPriority] = useState("Priority");

//   const [defaultSortingObject, setDefaultSortingObject] =
//     useState(defaultObject);
//   const [backPressed, setBackPressed] = useState(false);

//   let ticketsArr: any[] = [];
//   let ticketsSaved: any[] = [];
//   const [paramsArrays, setParamsArrays] = useState([""]);

//   const ticketss = useQuery<any, Error>({
//     queryKey: ["getAllTicketss"],
//     queryFn: async () => {
//       const payload = {
//         platformKey,
//         assignee: "None",
//         ticketStatus: defaultSortingObject.ticketStatus,
//         pagination_key: paramsArrays[defaultSortingObject.current],
//         priorityLevel: defaultSortingObject.priorityLevel,
//         ticketSource: defaultSortingObject.ticketSource,
//       };
//       const response = await getAllTickets(payload);
//       return response.data;
//     },
//     onSuccess: (data) => {},
//   });

//   if (ticketss.data) {
//     ticketsSaved = ticketss.data.Platform_Tickets.Items;
//   }

//   const tickets = useQuery<any, Error>({
//     queryKey: ["getAllTickets", defaultSortingObject, filter, backPressed],
//     queryFn: async () => {
//       const payload = {
//         platformKey,
//         assignee: "None",
//         ticketStatus: defaultSortingObject.ticketStatus,
//         pagination_key: paramsArrays[defaultSortingObject.current],
//         priorityLevel: defaultSortingObject.priorityLevel,
//         ticketSource: defaultSortingObject.ticketSource,
//       };
//       const response = await getAllTickets(payload);
//       return response.data;
//     },
//     onSuccess: (data) => {
//       setDropdown(null);
//       setDropdown1(null);
//       setActiveDropdown(null);
//       if (backPressed === false) {
//         setParamsArrays((prevState) => [...prevState, data.LastEvaluatedKey]);
//       }
//       if (backPressed === true) {
//         setParamsArrays(paramsArrays.slice(0, -1));
//       }
//     },
//   });

//   if (tickets.data) {
//     ticketsArr = tickets.data.Platform_Tickets.Items;
//   }

//   const ticketsCount = useQuery<any, Error>({
//     queryKey: ["getTicketCount"],
//     queryFn: async () => {
//       const response = await getTicketCount(user.queryKey);
//       return response.data;
//     },
//   });

//   const handleUpdateTicketStatus = useMutation({
//     mutationFn: async (data) => {
//       const body = {
//         platformKey,
//         ticketId: data.ticketId,
//         customerId: data.customerId,
//       };

//       return await updateTicketStatus(body);
//     },
//     onSuccess: () => {
//       queryClient.invalidateQueries("getTicketCount");
//       if (filter === 0) {
//         setFilter(1);
//       } else {
//         setFilter(0);
//       }
//       queryClient.invalidateQueries("getAllTickets");
//     },
//   });

//   const handleUpdateTicketPriorityLevel = useMutation({
//     mutationFn: async (data) => {
//       const body = {
//         platformKey,
//         ticketId: data.ticketId,
//         priorityStatus: data.priorityStatus,
//       };
//       return await updateTicketPriorityLevel(body);
//     },
//     onSuccess: () => {
//       if (filter === 0) {
//         setFilter(1);
//       } else {
//         setFilter(0);
//       }
//       queryClient.invalidateQueries("getAlTickets");
//     },
//   });

//   if (ticketsCount.data) {
//     const { resolved_ticket_count, pending_ticket_count } = ticketsCount.data;
//     ticketsCountObject = {
//       resolvedTickets: resolved_ticket_count ?? 0,
//       pendingTickets: pending_ticket_count ?? 0,
//       totalTickets: pending_ticket_count + resolved_ticket_count ?? 0,
//     };
//   }

//   return (
//     <>
//       <Modal
//         show={showModal}
//         onToggle={() => {
//           setShowModal((prevState) => !prevState);
//         }}
//       >
//         <div className="relative p-3 text-sm text-black sm:py-6 sm:px-8 spce-y-3 sm:space-y-6">
//           <div>
//             <p className="text-lg font-semibold">Email</p>
//             <p>{modalObject.email}</p>
//           </div>
//           <div className="">
//             <p className="text-lg font-semibold">Phone Number</p>
//             <p>{modalObject.phoneNumber}</p>
//           </div>
//           <div>
//             <p className="text-lg font-semibold">Complaint</p>
//             <p>{modalObject.complaint}</p>
//           </div>
//         </div>
//       </Modal>
//       <div className="h-[85vh] bg-white rounded-md shadow-md py-3 px-2 sm:px-4 overflow-y-auto relative flex flex-col space-y-2">

//         <div className="sticky top-0 z-10">
//           <div className="hidden max-w-full grid-cols-3 gap-4 mx-auto mb-4 sm:grid">
//             <div className="border rounded h-[120px] flex items-center justify-center">
//               <div className="space-y-1 text-center">
//                 <p className="text-sm font-semibold text-gray-800">
//                   Pending tickets
//                 </p>
//                 <p className="text-4xl font-bold text-red-700">
//                   {ticketsCountObject.pendingTickets}
//                 </p>
//               </div>
//             </div>
//             <div className="border rounded h-[120px] flex items-center justify-center">
//               <div className="space-y-1 text-center">
//                 <p className="text-sm font-semibold text-gray-800">
//                   Resolved tickets
//                 </p>
//                 <p className="text-4xl font-bold text-green-700">
//                   {ticketsCountObject.resolvedTickets}
//                 </p>
//               </div>
//             </div>
//             <div className="border rounded h-[120px] flex items-center justify-center">
//               <div className="space-y-1 text-center">
//                 <p className="text-sm font-semibold text-gray-800">
//                   Total tickets
//                 </p>
//                 <p className="text-4xl font-bold text-black">
//                   {ticketsCountObject.totalTickets}
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div className="flex max-w-full gap-2 pb-2 mx-auto mb-3 overflow-x-auto sm:hidden ticketsCardsScrollbar">
//             <div className="border rounded h-[120px] flex items-center justify-center min-w-[300px]">
//               <div className="space-y-1 text-center">
//                 <p className="text-sm font-semibold text-gray-800">
//                   Pending tickets
//                 </p>
//                 <p className="text-4xl font-bold text-red-700">
//                   {ticketsCountObject.pendingTickets}
//                 </p>
//               </div>
//             </div>
//             <div className="border rounded h-[120px] flex items-center justify-center min-w-[300px]">
//               <div className="space-y-1 text-center">
//                 <p className="text-sm font-semibold text-gray-800">
//                   Resolved tickets
//                 </p>
//                 <p className="text-4xl font-bold text-green-700">
//                   {ticketsCountObject.resolvedTickets}
//                 </p>
//               </div>
//             </div>
//             <div className="border rounded h-[120px] flex items-center justify-center min-w-[300px]">
//               <div className="space-y-1 text-center">
//                 <p className="text-sm font-semibold text-gray-800">
//                   Total tickets
//                 </p>
//                 <p className="text-4xl font-bold text-black">
//                   {ticketsCountObject.totalTickets}
//                 </p>
//               </div>
//             </div>
//           </div>

//           {ticketsSaved.length > 0 && (
//             <div className="flex items-center gap-4">
//               <div className="flex flex-wrap items-center gap-3 font-semibold lg:gap-5">
//                 <div className="relative">
//                   <button
//                     onClick={() => {
//                       setActive1(ButtonActive.Status);
//                       setDropdown((prevState) =>
//                         prevState === "statusDropdown" ? null : "statusDropdown"
//                       );
//                     }}
//                     className={
//                       active1 === ButtonActive.Status
//                         ? "px-2 sm:px-4 py-2 rounded-md text-xs bg-primary text-white flex items-center gap-2 sm:gap-4"
//                         : "px-2 sm:px-4 py-2 rounded-md text-xs bg-gray-100 flex items-center gap-2 sm:gap-4"
//                     }
//                   >
//                     <span>{selectedStatus}</span> <BiChevronDown size={22} />
//                   </button>
//                   {dropdown === "statusDropdown" && (
//                     <div className="absolute z-20 text-sm border rounded top-10 bg-gray-50">
//                       <button
//                         onClick={() => {
//                           setActive1(ButtonActive.Status);
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             ticketStatus: "None",
//                             current: 0,
//                             paramsArray: [""],
//                           });
//                           setParamsArrays([""]);
//                           setBackPressed(false);
//                           setSelectedStatus("All Status");
//                           if (filter === 0) {
//                             setFilter(1);
//                           } else {
//                             setFilter(0);
//                           }
//                         }}
//                         className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
//                       >
//                         All Status
//                       </button>
//                       <button
//                         onClick={() => {
//                           setActive1(ButtonActive.Status);
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             ticketStatus: "Pending",
//                             current: 0,
//                             paramsArray: [""],
//                           });
//                           setParamsArrays([""]);
//                           setBackPressed(false);
//                           setSelectedStatus("Resolved");
//                         }}
//                         className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
//                       >
//                         Resolved
//                       </button>
//                       <button
//                         onClick={() => {
//                           setParamsArrays([""]);
//                           setBackPressed(false);
//                           setActive1(ButtonActive.Status);
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             ticketStatus: "Resolved",
//                             current: 0,
//                             paramsArray: [""],
//                           });
//                           setSelectedStatus("Pending");
//                         }}
//                         className="w-full px-4 py-2 hover:bg-primary hover:text-white"
//                       >
//                         Pending
//                       </button>
//                     </div>
//                   )}
//                 </div>
//                 <div className="relative">
//                   <button
//                     onClick={() => {
//                       setActive2(ButtonActive.Priority);
//                       setDropdown((prevState) =>
//                         prevState === "priorityDropdown"
//                           ? null
//                           : "priorityDropdown"
//                       );
//                     }}
//                     className={
//                       active2 === ButtonActive.Priority
//                         ? "px-2 sm:px-4 py-2 rounded-md text-xs bg-primary text-white flex items-center gap-2 sm:gap-4"
//                         : "px-2 sm:px-4 py-2 rounded-md text-xs bg-gray-100 flex items-center gap-2 sm:gap-4"
//                     }
//                   >
//                     <span>{selectedPriority}</span> <BiChevronDown size={22} />
//                   </button>
//                   {dropdown === "priorityDropdown" && (
//                     <div className="text-sm absolute top-10 z-20 border rounded w-[120px] bg-gray-50">
//                       <button
//                         onClick={() => {
//                           setBackPressed(false);
//                           setParamsArrays([""]);
//                           setActive2(ButtonActive.Priority);
//                           setSelectedPriority("All Priorities");
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             priorityLevel: ["Low", "Medium", "High"],
//                             current: 0,
//                           });
//                         }}
//                         className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
//                       >
//                         All priorities
//                       </button>
//                       <button
//                         onClick={() => {
//                           setBackPressed(false);
//                           setParamsArrays([""]);
//                           setActive2(ButtonActive.Priority);
//                           setSelectedPriority("High");
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             priorityLevel: ["High"],
//                             current: 0,
//                             paramsArray: [""],
//                           });
//                         }}
//                         className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
//                       >
//                         High
//                       </button>
//                       <button
//                         onClick={() => {
//                           setParamsArrays([""]);
//                           setBackPressed(false);
//                           setActive2(ButtonActive.Priority);
//                           setSelectedPriority("Medium");
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             priorityLevel: ["Medium"],
//                             current: 0,
//                           });
//                         }}
//                         className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
//                       >
//                         Medium
//                       </button>
//                       <button
//                         onClick={() => {
//                           setBackPressed(false);
//                           setParamsArrays([""]);
//                           setActive2(ButtonActive.Priority);
//                           setSelectedPriority("Low");
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             priorityLevel: ["Low"],
//                             current: 0,
//                             paramsArray: [""],
//                           });
//                         }}
//                         className="w-full px-4 py-2 hover:bg-primary hover:text-white"
//                       >
//                         Low
//                       </button>
//                     </div>
//                   )}
//                 </div>
//                 <div className="relative">
//                   <button
//                     onClick={() => {
//                       setDropdown((prevState) =>
//                         prevState === "sourceDropdown" ? null : "sourceDropdown"
//                       );
//                     }}
//                     className={
//                       active2 === ButtonActive.Source
//                         ? "px-2 sm:px-4 py-2 rounded-md text-xs bg-primary text-white flex items-center gap-2 sm:gap-4"
//                         : "px-2 sm:px-4 py-2 rounded-md text-xs bg-gray-100 flex items-center gap-2 sm:gap-4"
//                     }
//                   >
//                     <span>Source</span> <BiChevronDown size={22} />
//                   </button>
//                   {dropdown === "sourceDropdown" && (
//                     <div className="text-sm absolute top-10 z-20 border rounded w-[120px] bg-gray-50">
//                       <button
//                         onClick={() => {
//                           setParamsArrays([""]);
//                           setBackPressed(false);
//                           // setActive2(ButtonActive.Priority);
//                           setSelectedPriority("All Priorities");
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             ticketSource: defaultObject.ticketSource,
//                           });
//                           if (filter === 0) {
//                             setFilter(1);
//                           } else {
//                             setFilter(0);
//                           }
//                         }}
//                         className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
//                       >
//                         All sources
//                       </button>
//                       <button
//                         onClick={() => {
//                           // setActive2(ButtonActive.Priority);
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             ticketSource: ["site_data"],
//                           });
//                         }}
//                         className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
//                       >
//                         QR Code
//                       </button>
//                       <button
//                         onClick={() => {
//                           // setActive2(ButtonActive.Priority);
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             ticketSource: ["web"],
//                           });
//                         }}
//                         className="py-2 px-4 w-full hover:bg-primary hover:text-white border-b-[1px]"
//                       >
//                         Website
//                       </button>
//                       <button
//                         disabled
//                         onClick={() => {
//                           // setActive2(ButtonActive.Priority);
//                           setSelectedPriority("Low");
//                           setDefaultSortingObject({
//                             ...defaultSortingObject,
//                             ticketSource: ["sms"],
//                           });
//                         }}
//                         className="w-full px-4 py-2 hover:bg-primary hover:text-white"
//                       >
//                         SMS
//                       </button>
//                     </div>
//                   )}
//                 </div>
//                 <button
//                   onClick={() => {
//                     setBackPressed(false);
//                     setParamsArrays([""]);
//                     setActive1(null);
//                     setActive2(null);
//                     setSelectedPriority("Priority");
//                     setSelectedStatus("Status");
//                     setDefaultSortingObject(defaultObject);
//                     if (filter === 0) {
//                       setFilter(1);
//                     } else {
//                       setFilter(0);
//                     }
//                   }}
//                   className="hidden sm:block text-xs pl-4 border-l-[2px] border-gray-600"
//                 >
//                   Clear all fields
//                 </button>
//                 <MdRefresh
//                   size={20}
//                   className="cursor-pointer sm:hidden"
//                   onClick={() => {
//                     setBackPressed(false);
//                     setParamsArrays([""]);
//                     setActive1(null);
//                     setActive2(null);
//                     setSelectedPriority("Priority");
//                     setSelectedStatus("Status");
//                     setDefaultSortingObject(defaultObject);
//                     if (filter === 0) {
//                       setFilter(1);
//                     } else {
//                       setFilter(0);
//                     }
//                   }}
//                 />
//               </div>
//             </div>
//           )}
//         </div>

//         {/* for tab and desktops */}
//         <div className="relative flex-1 hidden h-full pb-10 overflow-x-auto overflow-y-auto sm:block">
//           {tickets.status === "loading" ||
//           tickets.isRefetching ||
//           handleUpdateTicketStatus.isLoading ||
//           handleUpdateTicketPriorityLevel.isLoading ? (
//             <div className="flex items-center justify-start py-5">
//               <ImSpinner3
//                 size={18}
//                 className="mx-auto animate-spin fill-primary"
//               />
//             </div>
//           ) : tickets.status === "error" ? (
//             <p className="mt-8 font-semibold text-center text-gray-400">
//               {tickets.error.message}
//             </p>
//           ) : ticketsArr.length > 0 ? (
//             <div className="h-[80%]">
//               <table className="w-full text-sm text-left text-gray-500 border rounded-md">
//                 <thead className="text-gray-700 bg-gray-50">
//                   <tr className="text-sm text-center">
//                     <th scope="col" className="px-6 py-3">
//                       Created on
//                     </th>
//                     <th scope="col" className="px-6 py-3">
//                       ID
//                     </th>
//                     <th scope="col" className="px-6 py-3">
//                       Reporter
//                     </th>
//                     <th scope="col" className="px-6 py-3">
//                       Assignee
//                     </th>
//                     <th scope="col" className="px-6 py-3">
//                       Status
//                     </th>
//                     <th scope="col" className="px-6 py-3">
//                       Priority
//                     </th>
//                     <th scope="col" className="px-6 py-3">
//                       Source
//                     </th>
//                     <th scope="col" className="px-6 py-3">
//                       Resolved on
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody className="">
//                   {ticketsArr.map((ticket: any, index: number) => (
//                     <tr
//                       className={classNames(
//                         "border-b",
//                         ticket.ticketStatus !== "Pending"
//                           ? "bg-white"
//                           : "bg-gray-100"
//                       )}
//                       key={ticket.ticketId}
//                     >
//                       <td scope="row" className="px-6 py-2 ">
//                         <div className="">
//                           <p className="text-sm font-medium text-left whitespace-nowrap">
//                             {moment(ticket.uploadTimestamp).format(
//                               "MMMM Do, YYYY"
//                             )}
//                           </p>
//                         </div>
//                       </td>
//                       <td className="px-6 py-2 border-x">
//                         {ticket.ticketNumber}
//                       </td>
//                       <td className="px-6 py-2 border-x">
//                         <hr />
//                       </td>
//                       <td className="px-6 py-2 border-x">
//                         <hr />
//                       </td>
//                       <td className="px-6 py-2 border-r">
//                         <div className="relative">
//                           <div
//                             className={classNames(
//                               "flex items-center gap-2 justify-center py-1 px-1 bg-transparent rounded cursor-pointer text-gray-800 font-medium",
//                               ticket.ticketStatus === "Resolved"
//                                 ? "bg-green-800"
//                                 : "bg-[#ECB22E]"
//                             )}
//                             onClick={() => {
//                               setDropdown1((prevState) =>
//                                 prevState === "ticketStatus"
//                                   ? null
//                                   : "ticketStatus"
//                               );
//                               setActiveDropdown(ticket.ticketNumber);
//                             }}
//                           >
//                             <span>{ticket.ticketStatus}</span>
//                             <MdOutlineKeyboardArrowDown
//                               size={20}
//                               className={
//                                 ticket.ticketStatus !== "Pending"
//                                   ? "opacity-0"
//                                   : ""
//                               }
//                             />
//                           </div>

//                           {dropdown1 === "ticketStatus" &&
//                             activeDropdown === ticket.ticketNumber &&
//                             ticket.ticketStatus !== "Resolved" && (
//                               <div
//                                 id="dropdown"
//                                 className={`absolute top-8 z-30 shadow rounded bg-slate-200`}
//                               >
//                                 <ul className="py-2 text-sm text-gray-700">
//                                   <li>
//                                     <div
//                                       className="block px-4 cursor-pointer"
//                                       onClick={() => {
//                                         handleUpdateTicketStatus.mutate({
//                                           ticketId: ticket.ticketId,
//                                           customerId: ticket.customerId,
//                                         });
//                                         if (filter === 0) {
//                                           setFilter(1);
//                                         } else {
//                                           setFilter(0);
//                                         }
//                                         setDropdown1(null);
//                                         setActiveDropdown(null);
//                                       }}
//                                     >
//                                       <div className="flex items-center gap-2">
//                                         <BsCircleFill className="text-green-800 " />
//                                         <span>Resolved</span>
//                                       </div>{" "}
//                                     </div>
//                                   </li>
//                                 </ul>
//                               </div>
//                             )}
//                         </div>
//                       </td>

//                       <td className="px-6 py-2 border-x">
//                         <div className="relative">
//                           <div
//                             className={classNames(
//                               "flex items-center justify-center py-1 px-1 rounded cursor-pointer text-white",
//                               ticket.priorityLevel === "High"
//                                 ? "bg-red-700"
//                                 : ticket.priorityLevel === "Medium"
//                                 ? "bg-blue-700"
//                                 : "bg-gray-600"
//                             )}
//                             onClick={() => {
//                               setDropdown1((prevState) =>
//                                 prevState === "ticketPriority"
//                                   ? null
//                                   : "ticketPriority"
//                               );
//                               setActiveDropdown(ticket.ticketNumber);
//                             }}
//                           >
//                             <span>{ticket.priorityLevel}</span>
//                             <MdOutlineKeyboardArrowDown size={20} />
//                           </div>
//                           {dropdown1 === "ticketPriority" &&
//                             activeDropdown === ticket.ticketNumber && (
//                               <div
//                                 className={`absolute top-8 z-30 shadow rounded bg-slate-200`}
//                               >
//                                 <div
//                                   className="flex items-center justify-between gap-2 px-4 py-2 cursor-pointer hover:bg-white"
//                                   onClick={() => {
//                                     handleUpdateTicketPriorityLevel.mutate({
//                                       ticketId: ticket.ticketId,
//                                       priorityStatus: "High",
//                                     });
//                                     if (filter === 0) {
//                                       setFilter(1);
//                                     } else {
//                                       setFilter(0);
//                                     }
//                                     setDropdown1(null);
//                                     setActiveDropdown(null);
//                                   }}
//                                 >
//                                   <BiChevronUp
//                                     size={22}
//                                     className="text-red-800 "
//                                   />
//                                   <span>High</span>
//                                 </div>
//                                 <div
//                                   className="flex items-center justify-between gap-2 px-4 py-2 cursor-pointer hover:bg-white"
//                                   onClick={() => {
//                                     handleUpdateTicketPriorityLevel.mutate({
//                                       ticketId: ticket.ticketId,
//                                       priorityStatus: "Medium",
//                                     });
//                                     if (filter === 0) {
//                                       setFilter(1);
//                                     } else {
//                                       setFilter(0);
//                                     }
//                                     setDropdown1(null);
//                                     setActiveDropdown(null);
//                                   }}
//                                 >
//                                   <svg
//                                     className="ml-1"
//                                     width="16"
//                                     height="16"
//                                     viewBox="0 0 12 12"
//                                     fill="none"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                   >
//                                     <path
//                                       d="M2 4H10M2 8H10"
//                                       stroke="#ECB22E"
//                                       strokeLinecap="round"
//                                       strokeLinejoin="round"
//                                     />
//                                   </svg>
//                                   <span>Medium</span>
//                                 </div>
//                                 <div
//                                   className="flex items-center justify-between gap-2 px-4 py-2 cursor-pointer hover:bg-white"
//                                   onClick={() => {
//                                     handleUpdateTicketPriorityLevel.mutate({
//                                       ticketId: ticket.ticketId,
//                                       priorityStatus: "Low",
//                                     });
//                                     if (filter === 0) {
//                                       setFilter(1);
//                                     } else {
//                                       setFilter(0);
//                                     }
//                                     setDropdown1(null);
//                                     setActiveDropdown(null);
//                                   }}
//                                 >
//                                   <BiChevronDown
//                                     size={22}
//                                     className="text-green-800 "
//                                   />
//                                   <span>Low</span>
//                                 </div>
//                               </div>
//                             )}
//                         </div>
//                       </td>
//                       <td className="flex items-center justify-center px-6 py-4 border-x">
//                         <SourceIcon source={ticket.ticketSource} />
//                       </td>
//                       <td className="px-6 py-4">
//                         <div className="flex items-center justify-between gap-2">
//                           <p className="whitespace-nowrap">
//                             {ticket.resolvedDate === "ACTIVE"
//                               ? "ACTIVE"
//                               : moment(ticket.resolvedDate).format(
//                                   "MMMM Do, YYYY"
//                                 )}
//                           </p>
//                           <BsThreeDotsVertical
//                             size={20}
//                             onClick={() => {
//                               setModalObject({
//                                 email: ticket.customerId,
//                                 phoneNumber: ticket.customerNumber,
//                                 complaint: ticket.complaint,
//                               });
//                               setShowModal(true);
//                             }}
//                             className="text-gray-600 cursor-pointer"
//                           />
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           ) : (
//             <p className="mt-8 font-semibold text-center text-gray-400">
//               You don't have any complaints yet
//             </p>
//           )}
//         </div>

//         {/* for small screens */}
//         <div className="relative flex-1 h-full pb-10 overflow-x-auto overflow-y-auto sm:hidden">
//           {tickets.status === "loading" ||
//           tickets.isRefetching ||
//           handleUpdateTicketStatus.isLoading ||
//           handleUpdateTicketPriorityLevel.isLoading ? (
//             <div className="flex items-center justify-start py-5">
//               <ImSpinner3
//                 size={18}
//                 className="mx-auto animate-spin fill-primary"
//               />
//             </div>
//           ) : tickets.status === "error" ? (
//             <p className="mt-8 font-semibold text-center text-gray-400">
//               {tickets.error.message}
//             </p>
//           ) : ticketsArr.length > 0 ? (
//             <div className="h-[80%]">
//               <table className="w-full text-sm text-left text-gray-500 border rounded-md">
//                 <thead className="text-gray-700 capitalize bg-gray-50">
//                   <tr className="text-sm text-center">
//                     <th scope="col" className="px-6 py-3">
//                       Date
//                     </th>
//                     <th scope="col" className="px-6 py-3">
//                       ID
//                     </th>
//                     <th scope="col" className="px-6 py-3">
//                       Priority
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody className="">
//                   {ticketsArr.map((ticket: any, index: number) => (
//                     <tr
//                       className={classNames(
//                         "border-b",
//                         ticket.ticketStatus !== "Pending"
//                           ? "bg-white"
//                           : "bg-gray-100"
//                       )}
//                       key={ticket.ticketId}
//                     >
//                       <td scope="row" className="px-3 py-2 ">
//                         <div className="">
//                           <p className="text-sm font-medium text-left whitespace-nowrap">
//                             {moment(ticket.uploadTimestamp).format("M/D/YYYY")}
//                           </p>
//                         </div>
//                       </td>
//                       <td className="px-3 py-2 border-x">
//                         {ticket.ticketNumber}
//                       </td>

//                       <td className="flex justify-between gap-2 px-3 py-2 border-x">
//                         <div className="relative">
//                           <div
//                             className={classNames(
//                               "flex items-center justify-center py-1 px-1 rounded cursor-pointer text-white w-20",
//                               ticket.priorityLevel === "High"
//                                 ? "bg-red-700"
//                                 : ticket.priorityLevel === "Medium"
//                                 ? "bg-blue-700"
//                                 : "bg-gray-600"
//                             )}
//                             onClick={() => {
//                               setDropdown1((prevState) =>
//                                 prevState === "ticketPriority"
//                                   ? null
//                                   : "ticketPriority"
//                               );
//                               setActiveDropdown(ticket.ticketNumber);
//                             }}
//                           >
//                             <span>{ticket.priorityLevel}</span>
//                             <MdOutlineKeyboardArrowDown size={20} />
//                           </div>
//                           {dropdown1 === "ticketPriority" &&
//                             activeDropdown === ticket.ticketNumber && (
//                               <div
//                                 className={`absolute top-8 z-30 shadow rounded bg-slate-200`}
//                               >
//                                 <div
//                                   className="flex items-center justify-between gap-2 px-4 py-2 cursor-pointer hover:bg-white"
//                                   onClick={() => {
//                                     handleUpdateTicketPriorityLevel.mutate({
//                                       ticketId: ticket.ticketId,
//                                       priorityStatus: "High",
//                                     });
//                                     if (filter === 0) {
//                                       setFilter(1);
//                                     } else {
//                                       setFilter(0);
//                                     }
//                                     setDropdown1(null);
//                                     setActiveDropdown(null);
//                                   }}
//                                 >
//                                   <BiChevronUp
//                                     size={22}
//                                     className="text-red-800 "
//                                   />
//                                   <span>High</span>
//                                 </div>
//                                 <div
//                                   className="flex items-center justify-between gap-2 px-4 py-2 cursor-pointer hover:bg-white"
//                                   onClick={() => {
//                                     handleUpdateTicketPriorityLevel.mutate({
//                                       ticketId: ticket.ticketId,
//                                       priorityStatus: "Medium",
//                                     });
//                                     if (filter === 0) {
//                                       setFilter(1);
//                                     } else {
//                                       setFilter(0);
//                                     }
//                                     setDropdown1(null);
//                                     setActiveDropdown(null);
//                                   }}
//                                 >
//                                   <svg
//                                     className="ml-1"
//                                     width="16"
//                                     height="16"
//                                     viewBox="0 0 12 12"
//                                     fill="none"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                   >
//                                     <path
//                                       d="M2 4H10M2 8H10"
//                                       stroke="#ECB22E"
//                                       strokeLinecap="round"
//                                       strokeLinejoin="round"
//                                     />
//                                   </svg>
//                                   <span>Medium</span>
//                                 </div>
//                                 <div
//                                   className="flex items-center justify-between gap-2 px-4 py-2 cursor-pointer hover:bg-white"
//                                   onClick={() => {
//                                     handleUpdateTicketPriorityLevel.mutate({
//                                       ticketId: ticket.ticketId,
//                                       priorityStatus: "Low",
//                                     });
//                                     if (filter === 0) {
//                                       setFilter(1);
//                                     } else {
//                                       setFilter(0);
//                                     }
//                                     setDropdown1(null);
//                                     setActiveDropdown(null);
//                                   }}
//                                 >
//                                   <BiChevronDown
//                                     size={22}
//                                     className="text-green-800 "
//                                   />
//                                   <span>Low</span>
//                                 </div>
//                               </div>
//                             )}
//                         </div>
//                         <BsThreeDotsVertical
//                           size={20}
//                           onClick={() => {
//                             setModalObject({
//                               email: ticket.customerId,
//                               phoneNumber: ticket.customerNumber,
//                               complaint: ticket.complaint,
//                             });
//                             setShowModal(true);
//                           }}
//                           className="text-gray-600 cursor-pointer"
//                         />
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           ) : (
//             <p className="mt-8 font-semibold text-center text-gray-400">
//               You don't have any complaints yet
//             </p>
//           )}
//         </div>

//         {/* for tab and desktops */}
//         {ticketsSaved.length > 0 && (
//           <div className="hidden sm:block">
//             <div className="flex items-center justify-end mt-2">
//               <div className="flex items-center gap-4 text-sm">
//                 <button
//                   disabled={defaultSortingObject.current === 0}
//                   onClick={() => {
//                     setBackPressed(true);
//                     setDefaultSortingObject({
//                       ...defaultSortingObject,
//                       current: defaultSortingObject.current - 1,
//                     });
//                   }}
//                   className="py-1.5 px-4 border rounded bg-primary text-white disabled:opacity-80"
//                 >
//                   <span>Prev</span>{" "}
//                 </button>
//                 <button
//                   disabled={paramsArrays[paramsArrays.length - 1] === ""}
//                   onClick={() => {
//                     setDefaultSortingObject({
//                       ...defaultSortingObject,
//                       current: defaultSortingObject.current + 1,
//                     });
//                     setBackPressed(false);
//                   }}
//                   className="py-1.5 px-4 border rounded bg-primary text-white disabled:opacity-80"
//                 >
//                   <span>Next</span>
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       {/* for small Screen */}
//       {ticketsSaved.length > 0 && (
//         <div className="flex items-center justify-end mt-2 sm:hidden">
//           <div className="flex items-center gap-4 text-sm">
//             <button
//               disabled={defaultSortingObject.current === 0}
//               onClick={() => {
//                 setBackPressed(true);
//                 setDefaultSortingObject({
//                   ...defaultSortingObject,
//                   current: defaultSortingObject.current - 1,
//                 });
//               }}
//               className="py-1.5 px-4 border rounded bg-primary text-white disabled:opacity-80"
//             >
//               <span>Prev</span>{" "}
//             </button>
//             <button
//               disabled={paramsArrays[paramsArrays.length - 1] === ""}
//               onClick={() => {
//                 setDefaultSortingObject({
//                   ...defaultSortingObject,
//                   current: defaultSortingObject.current + 1,
//                 });
//                 setBackPressed(false);
//               }}
//               className="py-1.5 px-4 border rounded bg-primary text-white disabled:opacity-80"
//             >
//               <span>Next</span> 
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

export default TicketsPage;
