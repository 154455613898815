import { Link } from "react-router-dom";

interface Props {
    title: string;
    linkHref?: string;
    linkText?: string;
}

const PageHeader = ({ title, linkHref, linkText } : Props) => {
    return(
        <div className="flex items-center justify-between py-1">
            <h4 className="text-lg text-gray-400">{title}</h4>
            {linkText && <Link to={linkHref ?? "/"} className="px-8 py-3 text-xs text-white rounded-md bg-primary">
                {linkText}
            </Link>}
        </div>
    );
};

export default PageHeader;