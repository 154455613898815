//@ts-nocheck

import { HiStar } from "react-icons/hi";
import { AiOutlineMail } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ImSpinner3 } from "react-icons/im";
import moment from "moment";
import {
  getCustomerReviewHistory,
  getReviewersHistory,
} from "../../../../../api/Reviews";
import SkeletonLoader from "../../../../../components/Dashboard/SkeletonLoader";
import Modal from "../../../../../components/Dashboard/Modal";
import { classNames, getSvg, ratingsToNumber } from "../../../../../utils";
import { useAppSelector } from "../../../../../hooks";
import { UserProfileModel } from "../../../../../models";
import { BiChevronLeft, BiChevronDown } from "react-icons/bi";
import { useFormik } from "formik";
import { BsTrash3 } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeHolder: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
}

const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
}: InputProps) => {
  return (
    <>
      <label className="block mb-1 text-xs font-normal text-gray-800">
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        disabled={disabled}
        className={classNames(
          className,
          "border border-gray-300 py-2 px-5 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm"
        )}
      />
    </>
  );
};

const initialValues = {
  name: "",
  phoneNumber: "",
  reward: "",
};

const Table = (props : { onDelete: () => void }) => {
  const [selectedStatus, setSelectedStatus] = useState("Active");
  
  return (
    <table className="w-full overflow-auto text-sm text-left text-gray-500 border">
      <thead className="text-gray-700 capitalize bg-gray-50">
        <tr className="text-sm text-center uppercase">
          <th scope="col" className="hidden px-6 py-3 sm:table-cell">
            Name
          </th>
          <th scope="col" className="hidden px-6 py-3 sm:table-cell">
            Discount
          </th>
          <th scope="col" className="hidden px-6 py-3 sm:table-cell">
            Type
          </th>
          <th scope="col" className="px-6 py-3">
            validity date
          </th>
          <th scope="col" className="px-6 py-3">
            status
          </th>
          <th scope="col" className="px-6 py-3"></th>
        </tr>
      </thead>
      <tbody className="">
        {
          [1,2,3].map((item: any, index: number) => {
            return (
              <tr key={index} className={classNames("border-b text-black")}>
                <td className="hidden px-6 py-2 uppercase sm:table-cell border-x">Christmas Sale</td>
                <td className="hidden px-6 py-2 uppercase sm:table-cell border-x">{"10% off"}</td>
                <td className="hidden px-6 py-2 text-center uppercase sm:table-cell border-x">All products</td>
                <td className="hidden px-6 py-2 text-center uppercase sm:table-cell border-x">8th june 2023</td>
                <td className="hidden px-6 py-2 text-center uppercase sm:table-cell border-x">
                  <div className="relative group">
                    <button
                      onClick={() => {}}
                      className={classNames("mx-auto px-2 sm:px-4 py-1.5 rounded-md text-xs font-semibold border-primary border text-black flex items-center gap-2 sm:gap-3")}
                    >
                      <FaCircle size={10} className={classNames(selectedStatus === "Active" ? "fill-yellow-500" : "fill-green-500")} /> <span>{selectedStatus}</span> <BiChevronDown size={22} />
                    </button>
                    <div className="absolute z-50 hidden mx-auto origin-top-right bg-white divide-y divide-gray-300 rounded shadow w-36 top-10 group-focus-within:block shadow-gray-300">
                      {
                        [selectedStatus === "Active" ? "Claimed" : "Active"].map((status: string, index: number) => (
                          <button key={index} onClick={() => setSelectedStatus(status)} className="flex items-center gap-2 px-2 py-2 text-xs font-semibold text-black sm:gap-3 sm:px-4">
                            <FaCircle size={10} className={classNames(status === "Active" ? "fill-yellow-500" : "fill-green-500")} /> <span>{status}</span>
                          </button>
                        ))
                      }
                    </div>
                  </div>
                </td>
                <td className="hidden px-6 py-2 text-center uppercase sm:table-cell border-x">
                    <button onClick={props.onDelete}><BsTrash3 size={20} /></button>
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

// const DirectCustomerLoyaltyPage = () => {
//   const { email } = useParams();
//   const user: UserProfileModel = useAppSelector((state) => state.user.profile);
//   const queryKey = user.queryKey;
//   const [categoryDropdown, setCategoryDropdown] = useState(false);
//   const [showSendMessageModal, setShowSendMessageModal] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [step, setStep] = useState(1);
//   const navigate = useNavigate();
//   let customerDetails: any = {};
//   let body: any = {};
//   const defaultCommentList = ["jtjjfgfg", "tyfgysfy"];

//   const formik = useFormik({
//     initialValues: initialValues,
//     validateOnBlur: false,
//     validateOnMount: false,
//     validateOnChange: false,
//     onSubmit: async (values, formikHelpers) => {
//       setStep(2);
//       console.log(values);
//     },
//   });

//   //   const { status, data, error } = useQuery<any, Error>({
//   //     queryKey: ["getReviewersHistory"],
//   //     queryFn: async () => {
//   //       const response = await getReviewersHistory(queryKey);
//   //       return response.data;
//   //     },
//   //     select(data) {
//   //       const customers = "Customers" in data ? data.Customers : [];
//   //       delete customers.customer_vault;
//   //       delete customers.customer_id;
//   //       delete customers.reference_link;

//   //       const result = Object.values(customers);
//   //       return result;
//   //     },
//   //   });

//   //   if (data) {
//   //     customerDetails = data ? data?.find((a) => a.customer_email === email) : {};

//   //     body = {
//   //       platformKey: {
//   //         reference_key: { S: queryKey },
//   //       },
//   //       commentList: defaultCommentList,
//   //     };

//   //     if ("comment_history" in customerDetails?.metadata) {
//   //       body = {
//   //         ...body,
//   //         commentList:
//   //           customerDetails?.metadata.comment_history.length > 0
//   //             ? customerDetails.metadata.comment_history
//   //             : defaultCommentList,
//   //       };
//   //     }
//   //   }

//   //   const history = useQuery<any, Error>({
//   //     queryKey: ["getCustomerReviewHistory", email],
//   //     queryFn: async () => {
//   //       const response = await getCustomerReviewHistory(body);
//   //       return response.data;
//   //     },
//   //     select(data) {
//   //       // console.log(data)
//   //       const items = "Item" in data.res ? data.res.Item : [];
//   //       const result = items ? Object.values(items) : [];
//   //       return result;
//   //     },
//   //     enabled: !!customerDetails?.metadata,
//   //   });

//   //   if (status === "loading")
//   //     return <SkeletonLoader className="rounded-md h-[80vh]" />;

//   //   if (status === "error") return <span>Error: {error.message}</span>;

//   return (
//     <>
//       <Modal
//         show={showModal}
//         onToggle={() => {
//           setStep(1);
//           setShowModal(false);
//         }}
//       >
//         {step === 1 ? (
//           <div className="relative flex flex-col p-6 md:py-12 md:px-16">
//             <p className="text=gray-800 font-semibold mb-2">
//               Create New Coupon
//             </p>
//             <form onSubmit={formik.handleSubmit}>
//               <div className="w-full">
//                 <Input
//                   formik={formik}
//                   name="reward"
//                   label="Reward"
//                   type="text"
//                   placeHolder="Please Enter Reward"
//                 />
//                 {/* {formik.values.email && formik.errors.email && (
//               <InputMessage message={formik.errors.email} />
//             )} */}
//               </div>
//               <p className="my-3 mt-6 font-semibold text-gray-800">
//                 Who is Elligible
//               </p>
//               <div className="space-y-4">
//                 <div className="w-full">
//                   <Input
//                     formik={formik}
//                     name="name"
//                     label="Name"
//                     type="text"
//                     placeHolder="Please Enter Name"
//                   />
//                   {/* {formik.values.email && formik.errors.email && (
//               <InputMessage message={formik.errors.email} />
//             )} */}
//                 </div>
//                 <div className="w-full">
//                   <Input
//                     formik={formik}
//                     name="phoneNumber"
//                     label="Phone Number"
//                     type="text"
//                     placeHolder="Please Enter Phonenumber"
//                   />
//                   {/* {formik.values.email && formik.errors.email && (
//               <InputMessage message={formik.errors.email} />
//             )} */}
//                 </div>
//               </div>
//               <div className="flex items-center justify-center mt-10">
//                 <button
//                   type="submit"
//                   className="px-16 py-2 text-sm font-medium text-white rounded-md bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 "
//                   // onClick={(e) => {
//                   //   e.preventDefault();
//                   //   setShowModal(true);
//                   // }}
//                 >
//                   Create Card
//                 </button>
//               </div>
//             </form>
//           </div>
//         ) : (
//           <div className="relative flex flex-col p-6 md:py-12 md:px-16">
//             <div className="mt-5 text-center md:mt-8">
//               <img
//                 src="/media/svg/check.svg"
//                 alt="icon"
//                 className="w-24 mx-auto"
//               />
//             </div>
//             <div className="mt-2 space-y-2 text-center md:mt-4">
//               <h1 className="font-semibold md:text-2xl">
//                 Card Creation Successful
//               </h1>
//               <p className="text-gray-500 md:text-sm">The Card ID is #4573G5</p>
//             </div>
//             <span
//               className="absolute cursor-pointer top-2 right-2"
//               onClick={() => {
//                 setStep(1);
//                 setShowModal((prevState) => !prevState);
//               }}
//             >
//               <svg
//                 width="28"
//                 height="28"
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
//                   fill="#5A5A5A"
//                 />
//               </svg>
//             </span>
//           </div>
//         )}
//       </Modal>
//       <Modal
//         show={showSendMessageModal}
//         onToggle={() => setShowSendMessageModal(false)}
//       >
//         <div className="relative flex flex-col p-6 md:py-12 md:px-16">
//           <div className="mt-5 text-center md:mt-8">
//             <h1 className="font-medium text-gray-800 md:text-xl">
//               This Customer has provided a phone number.
//             </h1>
//             <h1 className="font-medium text-gray-800 md:text-xl">
//               Would You like to send an SMS?
//             </h1>
//           </div>
//           <div className="flex justify-center gap-2 mt-6 md:mt-10">
//             <div>
//               <button
//                 disabled
//                 className="px-4 py-2 font-bold text-center text-white border rounded-md w-44 md:px-6 lg:px-8 bg-primary"
//                 onClick={() =>
//                   navigate(`/feedback-analysis/messages/sms/${email}`)
//                 }
//               >
//                 Yes Send SMS
//               </button>
//             </div>
//             <div>
//               <button
//                 type="button"
//                 disabled
//                 className="px-4 py-2 font-bold text-center text-gray-800 bg-transparent border rounded-md w-44 md:px-6 lg:px-8"
//                 onClick={() => navigate(`/feedback-analysis/messages/${email}`)}
//               >
//                 No, Send Email
//               </button>
//             </div>
//           </div>
//         </div>
//       </Modal>
//       <div className="space-y-2">
//         <div className="flex items-center justify-between">
//           <div className="flex items-center gap-3 py-1">
//             <div
//               className="p-2 text-gray-800 bg-white rounded cursor-pointer"
//               onClick={() => navigate("/feedback-analysis/customer-loyalty")}
//             >
//               <BiChevronLeft />
//             </div>
//             <h4 className="text-xl text-gray-400 capitalize">
//               Loyalty Details
//             </h4>
//           </div>

//           <button
//             disabled
//             onClick={() => setShowModal(true)}
//             className="hidden px-4 py-2 text-sm font-medium text-white rounded-md bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300"
//           >
//             Create New Loyalty Card
//           </button>
//         </div>
//         <div className="">
//           <div className="flex items-center justify-between p-3 bg-white h-15vh">
//             <div className="relative">
//               <form className="flex items-center gap-4">
//                 <div className="relative w-[280px]">
//                   <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
//                     <svg
//                       className="w-4 h-4 text-gray-500 dark:text-gray-400"
//                       aria-hidden="true"
//                       xmlns="http://www.w3.org/2000/svg"
//                       fill="none"
//                       viewBox="0 0 20 20"
//                     >
//                       <path
//                         stroke="currentColor"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth="2"
//                         d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
//                       />
//                     </svg>
//                   </div>
//                   <input
//                     type="search"
//                     id="search"
//                     className="block w-full p-4 py-2 pl-10 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500"
//                     placeholder="Search Email"
//                     required
//                   />
//                 </div>
//                 <button
//                   type="submit"
//                   className="hidden px-4 py-2 text-sm font-medium text-white rounded-md bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300"
//                 >
//                   Search
//                 </button>
//               </form>
//             </div>
//             <button
//               onClick={() => setShowModal(true)}
//               className="px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary/80"
//             >
//               Create New Coupon
//             </button>
//           </div>
//           <div className="grid content-center justify-center grid-cols-7">
//             <div className="h-[70vh] bg-white col-span-2 overflow-y-auto striped border-r-2 border-r-gray-20">
//               {[1, 2, 3].map((index) => (
//                 <Link
//                   key={index}
//                   to={`/feedback-analysis/customer-loyalty/${1}`}
//                   className={classNames(
//                     "flex items-center gap-3 px-4 py-4 text-sm font-medium odd:bg-gray-50 even:bg-gray-100 border-l-4 border-transparent"
//                   )}
//                 >
//                   <p>chikeobi@gmail.com</p>
//                 </Link>
//               ))}
//               {/* {data.map((customer: any, index: number) => (
//               <Link
//                 key={index}
//                 to={`/feedback-analysis/customer-log/${customer.customer_email}`}
//                 className={classNames(
//                   "flex items-center gap-3 px-4 py-4 text-sm font-medium odd:bg-gray-50 even:bg-gray-100 border-l-4",
//                   customer.customer_email === email
//                     ? "border-primary"
//                     : "border-transparent"
//                 )}
//               >
//                 <p>{customer.customer_email}</p>
//               </Link>
//             ))} */}
//             </div>
//             <div className="h-[70vh] bg-white col-span-5 px-6">
//               <div className="flex gap-3">
//                 <div className="w-[30px] h-[30px] bg-gray-800 rounded-md"></div>
//                 <div className="text-sm leading-4 ">
//                   <p>chikeobi@gmail.com</p>
//                   <p>+2346745893256</p>
//                   <p className="text-xs font-normal mt-1.5">
//                     <span className="pr-2 text-primary">Scanner</span>
//                     <span className="px-2 text-[#ECB22E] border-slate-400 border-x">
//                       <span className="border px-2 py-0.5 rounded">
//                         2 Pending
//                       </span>
//                     </span>
//                     <span className="px-2 text-green-600">
//                       <span className="border px-2 py-0.5 rounded">
//                         3 Claimed
//                       </span>
//                     </span>
//                   </p>
//                 </div>
//               </div>
//               <br />
//               <div className="relative overflow-x-auto overflow-y-auto h-1/2">
//                 <table className="w-full text-sm text-left text-gray-500 border rounded-lg">
//                   <thead className="text-gray-700 capitalize bg-gray-50">
//                     <tr className="text-sm">
//                       <th scope="col" className="px-6 py-3">
//                         Coupon
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Coupon Summary
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Status
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Expiry Data
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {[1, 2, 3, 4].map((history: any, index: number) => (
//                       <tr className="bg-white border-b" key={index}>
//                         <th
//                           scope="row"
//                           className="p-3 pr-1 font-medium text-gray-900 max-w-[350px]"
//                         >
//                           <div className="flex items-center gap-4">
//                             <p>#1234TY</p>
//                           </div>
//                         </th>
//                         <td className="px-6 py-4 border-x">
//                           <p>
//                             Lorem ipsum dolor sit amet, consectetur adipisicing
//                             elit. In, officia.
//                           </p>
//                         </td>
//                         <td className="px-6 py-4 border-r">
//                           <div
//                             className={classNames(
//                               "border py-2 px-2 rounded cursor-pointer text-white text-center bg-green-800"
//                             )}
//                           >
//                             <span>Resolved</span>
//                           </div>
//                         </td>
//                         <td className="px-6 py-4">
//                           <p>8th July. 2023</p>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 {/* {history.status === "loading" || history.isRefetching ? (
//                 <div className="flex items-center justify-start py-5">
//                   <ImSpinner3
//                     size={18}
//                     className="mx-auto animate-spin fill-primary"
//                   />
//                 </div>
//               ) : history.status === "error" ? (
//                 <span>Error: {history.error.message}</span>
//               ) : history.data.length > 0 ? (
//                 <table className="w-full text-sm text-left text-gray-500 border rounded-lg">
//                   <thead className="text-gray-700 capitalize bg-gray-50">
//                     <tr className="text-sm">
//                       <th scope="col" className="px-6 py-3">
//                         Coupon
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Coupon Summary
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Status
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Expiry Data
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {history.data.map((history: any, index: number) => (
//                       <tr className="bg-white border-b" key={index}>
//                         <th
//                           scope="row"
//                           className="p-3 pr-1 font-medium text-gray-900 max-w-[350px]"
//                         >
//                           <div className="flex items-center gap-4">
//                             <p>#1234TY</p>
//                           </div>
//                         </th>
//                         <td className="px-6 py-4 border-x">
//                           <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. In, officia.</p>
//                         </td>
//                         <td className="px-6 py-4 border-r">
//                         <div
//                             className={classNames(
//                               "border py-2 px-2 rounded cursor-pointer text-white text-center bg-green-800",
//                             )}
//                           >
//                             <span>Resolved</span>
//                           </div>
//                         </td>
//                         <td className="px-6 py-4">
//                           <p>
//                             8th July. 2023
//                           </p>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               ) : (
//                 <p className="text-sm text-gray-400">No reviews yet</p>
//               )} */}
//               </div>
//               <div className="bg-gray-50 p-3 flex gap-4 mt-6 max-w-[320px]">
//                 <AiOutlineMail size={30} />
//                 <div>
//                   <p className="text-sm">
//                     Would you like to send this customer a message?
//                   </p>
//                   <button
//                     type="button"
//                     className="px-4 py-2 mt-3 text-sm font-medium text-left text-white rounded-lg bg-primary hover:bg-primary/80"
//                     onClick={() => setShowSendMessageModal(true)}
//                   >
//                     Send Message
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

const DirectCustomerLoyaltyPage = () => {
  return (
    <div className="bg-white rounded-md h-[80vh] p-3 space-y-5 sm:space-y-10 overflow-hidden flex flex-col">
      <div className="">
        <Link to={-1}>
          <p
            className="flex items-center text-sm font-medium text-black rounded-lg space-x-"
          >
            <BiChevronLeft size={24} />
            <span className="font-semibold">{"Back"}</span>
          </p>
        </Link>
      </div>
      <div className="flex flex-col space-y-2.5 sm:items-center sm:flex-row sm:space-y-0 sm:space-x-5">
        <h6>Email: <span className="font-semibold">{"example@example.com"}</span></h6>
        <h6>Phone: <span className="font-semibold">{"+2349078656434"}</span></h6>
        <h6>Loyalty ID: <span className="font-semibold">{"#Cynthiaobi-8375"}</span></h6>
      </div>
      <div className="relative flex-1 h-full overflow-x-auto overflow-y-auto rounded-md">
        <Table onDelete={() => {}} />
      </div>
    </div>
  );
};

export default DirectCustomerLoyaltyPage;
