
const TaskManagerHomeSummary = () => {
  return (
    <div>
      <p className="text-sm font-semibold mb-2.5">Task manager</p>
      <div className="space-y-2">
        {[1, 2, 3, 4, 5].map((index: number) => (
          <div
            className="p-2 py-3.5 bg-white rounded-md flex items-center justify-between"
            key={index}
          >
            <p className="text-[12.5px]">Delivery to Chinedu Ahmed</p>
            <div className="text-xs flex items-center gap-4">
              <p>10th August 2023</p>
              <p>High</p>
              <p>Created by</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskManagerHomeSummary;
