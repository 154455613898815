// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import { MdClear, MdDelete, MdOutlineKeyboardBackspace } from "react-icons/md";
import InputMessage from "../../../../../../../components/Auth/Forms/InputMessage";
import { Input } from "../../EchoWebpage1";
import {
  BASE_URL_WEBPAGE_IMAGE,
  delectS3Object,
  getAllMenuCategories,
  getMenuByCategory,
  updateSingleProduct,
  uploadSingleMenu,
} from "../../../../../../../api/Menu";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useFormik } from "formik";
import { useAppSelector } from "../../../../../../../hooks";
import * as Yup from "yup";
import { ImSpinner2 } from "react-icons/im";
import Compressor from "compressorjs";
import { AiOutlineCloudUpload, AiOutlinePlus } from "react-icons/ai";
import { Combobox, Transition } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
import { classNames } from "../../../../../../../utils";
import ItemOptions from "./ItemOptions";
import { FiChevronRight } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";

const itemSchema = Yup.object().shape({
  itemName: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Item name is required"),
  details: Yup.string()
    .min(5, "Minimum 5 letters")
    .max(140, "Maximum 140 letters"),
  price: Yup.string().required("Price is required"),
  category: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(30, "Maximum 30 letters")
    .required("Category is required"),
});

const EditItemInCategory = ({
  setStep,
  selectedObject,
  setSelectedObject,
  setProductSidePanelStep,
  showModals,
  setShowModals,
}) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [preview, setPreview] = useState(
    selectedObject?.s3ImageUrl[0]?.Image_Upload_Url
  );
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [s3ImageUrl, setS3ImageUrl] = useState(
    selectedObject?.s3ImageUrl[0]?.imageIdentifier ?? ""
  );
  const [savedS3ImageUrl, setSavedS3ImageUrl] = useState(
    selectedObject?.s3ImageUrl[0]?.imageIdentifier ?? ""
  );
  const s1 =
    selectedObject.s3ImageUrlOne === undefined
      ? ""
      : selectedObject?.s3ImageUrlOne[0]?.imageIdentifier ?? "";
  const s2 =
    selectedObject.s3ImageUrlTwo === undefined
      ? ""
      : selectedObject?.s3ImageUrlTwo[0]?.imageIdentifier ?? "";
  const s3 =
    selectedObject.s3ImageUrlThree === undefined
      ? ""
      : selectedObject?.s3ImageUrlThree[0]?.imageIdentifier ?? "";

  const u1 =
    selectedObject.s3ImageUrlOne === undefined
      ? ""
      : selectedObject?.s3ImageUrlOne[0]?.Image_Upload_Url ?? "";
  const u2 =
    selectedObject.s3ImageUrlTwo === undefined
      ? ""
      : selectedObject?.s3ImageUrlTwo[0]?.Image_Upload_Url ?? "";
  const u3 =
    selectedObject.s3ImageUrlThree === undefined
      ? ""
      : selectedObject?.s3ImageUrlThree[0]?.Image_Upload_Url ?? "";

  const [s3ImageUrl1, setS3ImageUrl1] = useState(s1);
  const [s3ImageUrl2, setS3ImageUrl2] = useState(s2);
  const [s3ImageUrl3, setS3ImageUrl3] = useState(s3);

  let uArray = [selectedObject?.s3ImageUrl[0]?.Image_Upload_Url, u1, u2, u3];
  let sArray = [selectedObject?.s3ImageUrl[0]?.imageIdentifier, s1, s2, s3];
  const [savedImagesArray, setSavedImagesArray] = useState([
    selectedObject?.s3ImageUrl[0]?.Image_Upload_Url,
    u1,
    u2,
    u3,
  ]);
  // console.log(sArray)
  // uArray = uArray.filter(u => u !== "")
  // sArray = sArray.filter(s => s !== "")

  // console.log(uArray);

  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState({
    id: selectedObject.itemCategory,
    name: selectedObject.itemCategory,
  });
  const [query, setQuery] = useState("");
  const [freeDelivery, setFreeDelivery] = useState(
    selectedObject.freeDelivery ?? "0"
  );
  const freeDeliveryStatus: string = useAppSelector(
    (state) => state.user.store.freeDelivery
  );
  const [page, setPage] = useState(1);
  const [imagesArray, setImagesArray] = useState(uArray);
  const [imagesIdentifierArray, setImagesIdentifierArray] = useState(sArray);
  const [itemSizes, setItemSizes] = useState(selectedObject.itemSizes ?? []);
  const [itemColors, setItemColors] = useState(selectedObject.itemColors ?? []);

  const allMenuCategories = useQuery<any, Error>({
    queryKey: ["getAllStoreCategories"],
    queryFn: async () => {
      const response = await getAllMenuCategories(storeKey);
      return response.data;
    },
    onSuccess: (data) => {
      if (data.Menu_Categories.length > 0) {
        const newCategories = data.Menu_Categories.map((cat) => {
          return {
            id: cat.Category,
            name: cat.Category,
          };
        });
        const people = newCategories;
        setCategories(newCategories);
        if (!people.length > 0) {
          setSelected(people[0]);
        }
      }
    },
  });

  const initialValues = {
    itemName: selectedObject.itemName ?? "",
    details: selectedObject.description ?? "",
    price: selectedObject.itemPrice ?? "",
    category: selectedObject.itemCategory ?? "",
  };

  // console.log(imagesIdentifierArray)

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: itemSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      setIsLoading1(true);
      // console.log(imagesArray)
      // console.log(imagesIdentifierArray)

      // if (imagesArray.length < 1) {
      //   // console.log(1)
      //   alert("Default Image is compulsory")
      //   setIsLoading1(false);
      //   return;
      // }

      let updateQuery = {
        itemPrice: values.price.toString(),
        itemName: values.itemName.trim(),
        itemCategory: selected.name.trim(),
        itemOldCategory: selected.name.trim(),
        s3ImageUrl: s3ImageUrl,
        s3ImageUrlOne: s3ImageUrl1,
        s3ImageUrlTwo: s3ImageUrl2,
        s3ImageUrlThree: s3ImageUrl3,
        description: values.details.trim(),
        freeDelivery,
        itemSizes,
        itemColors,
      };

      let payload = {
        productId: selectedObject.menuItemId,
        updateQuery: updateQuery,
        slugUrl: storeKey,
      };

      // const s1 = updateQuery.s3ImageUrlOne;
      // const s2 = updateQuery.s3ImageUrlTwo;
      // const s3 = updateQuery.s3ImageUrlThree;

      const s1 = imagesIdentifierArray[1];
      const s2 = imagesIdentifierArray[2];
      const s3 = imagesIdentifierArray[3];

      if (updateQuery.s3ImageUrl === "") {
        if (s1 !== "") {
          updateQuery = { ...updateQuery, s3ImageUrl: s1, s3ImageUrlOne: "" };
          payload = { ...payload, updateQuery: updateQuery };
        } else if (s2 !== "") {
          updateQuery = { ...updateQuery, s3ImageUrl: s2, s3ImageUrlTwo: "" };
          payload = { ...payload, updateQuery: updateQuery };
        } else if (s3 !== "") {
          updateQuery = { ...updateQuery, s3ImageUrl: s3, s3ImageUrlThree: "" };
          payload = { ...payload, updateQuery: updateQuery };
        } else {
          alert("Default Image is compulsory");
          setIsLoading1(false);
          return;
        }
      }

      if (updateQuery.s3ImageUrl === "") {
        alert("Default Image is compulsory");
        setIsLoading1(false);
        return;
      }

      try {
        const response = await updateSingleProduct(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries({ queryKey: ["getStoreItems"] });
          resetForm({});
          if (showModals === null || showModals === undefined) {
            setPreview(null);
            resetForm({});
            setSavedS3ImageUrl(null);
            setS3ImageUrl("");
            setTimeout(() => {
              setIsLoading1(false);
              setProductSidePanelStep(1);
            }, 2000);
          }

          if (showModals === "item") {
            setShowModals(null);
            setIsLoading1(false);
            setStep(1);
            resetForm({});
            // setPreview(null);
            // setSuccess(true);
            // resetForm({});
            // setSavedS3ImageUrl(null);
            // setS3ImageUrl("");
            // setSelectedObject({});
          }

          return;
        }
        setError(response.data.message);
      } catch (err) {
        console.log({ err });
        setIsLoading1(false);
      }
      setIsLoading1(false);
    },
  });

  const imageHandler = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(null);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const handleCompressedUpload = async (file: any) => {
    setIsLoading(true);
    new Compressor(file, {
      quality: 0.2,
      success: (compressedResult: any) => {
        let path: string;

        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };
        const category = selected.name.replace(/[^a-zA-Z0-9 ]/g, "");

        if (savedS3ImageUrl.includes("/no-image")) {
          path = savedS3ImageUrl.replace("/no-image", "");
        } else if (s3ImageUrl1 === "") {
          path =
            "/cusecho" + storeKey + "/products/" + category + "/" + uuidv4();
        } else if (s3ImageUrl2 === "") {
          path =
            "/cusecho" + storeKey + "/products/" + category + "/" + uuidv4();
        } else {
          path =
            "/cusecho" + storeKey + "/products/" + category + "/" + uuidv4();
        }

        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then(() => {
                setIsLoading(false);
                setImageUploadSuccess(true);
                // if(imagesArray.length)

                setImageUploadSuccess(true);
                setSelectedImage(null);
                setPreview(null);
                setTimeout(() => {
                  setImageUploadSuccess(false);
                }, 2000);
                setIsLoading(false);

                if (imagesArray.length < 1) {
                  setS3ImageUrl(path);

                  setImagesArray([...imagesArray, preview]);
                  return;
                } else if (s3ImageUrl1 === "") {
                  setS3ImageUrl1(path);
                  const newArray = [
                    imagesIdentifierArray[0],
                    path,
                    imagesIdentifierArray[2],
                    imagesIdentifierArray[3],
                  ];
                  setImagesIdentifierArray(newArray);
                  // imagesIdentifierArray[1] = path
                  setImagesArray([...imagesArray, preview]);
                  return;
                } else if (s3ImageUrl2 === "") {
                  setS3ImageUrl2(path);
                  const newArray = [
                    imagesIdentifierArray[0],
                    imagesIdentifierArray[1],
                    path,
                    imagesIdentifierArray[3],
                  ];
                  setImagesIdentifierArray(newArray);
                  // imagesIdentifierArray[2] = path
                  setImagesArray([...imagesArray, preview]);
                  return;
                } else {
                  setS3ImageUrl3(path);
                  const newArray = [
                    imagesIdentifierArray[0],
                    imagesIdentifierArray[1],
                    imagesIdentifierArray[2],
                    path,
                  ];
                  setImagesIdentifierArray(newArray);
                  // imagesIdentifierArray[3] = path
                  setImagesArray([...imagesArray, preview]);
                  return;
                }
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        objectKey: data.objectKey,
      };
      return await delectS3Object(payload);
    },
  });

  useEffect(() => {
    if (!selectedImage) {
      setPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setPreview(objectUrl);
    // return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  return (
    <>
      {page === 1 ? (
        <div className="p-3">
          <div className="flex items-center gap-2 mb-3">
            <MdOutlineKeyboardBackspace
              className="cursor-pointer hidden md:block"
              onClick={() => {
                setProductSidePanelStep(1);
              }}
            />
            <p className="text-lg font-semibold">Edit Item</p>
          </div>

          <form onSubmit={formik.handleSubmit} className="">
            <div className="space-y-3">
              <div className="w-full">
                <Input
                  formik={formik}
                  name="itemName"
                  label="Item Name"
                  type="text"
                  placeholder="Please Enter Item Name"
                />
                {formik.values.itemName && formik.errors.itemName && (
                  <InputMessage message={formik.errors.itemName} />
                )}
              </div>
              <div className="w-full">
                <label className="block mb-1 text-xs font-normal text-gray-800">
                  Details
                </label>
                <textarea
                  id=""
                  name="details"
                  value={formik.values.details}
                  onChange={formik.handleChange}
                  className="h-[100px] text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                  placeholder="Please Enter details of item"
                ></textarea>

                {formik.values.details && formik.errors.details && (
                  <InputMessage message={formik.errors.details} />
                )}
                {formik.values.details && formik.errors.details && (
                  <InputMessage message={formik.errors.details} />
                )}
              </div>
              <div className="w-full">
                <Input
                  formik={formik}
                  name="price"
                  label="Price"
                  type="number"
                  placeholder="Please Enter Price"
                />
                {formik.values.price && formik.errors.price && (
                  <InputMessage message={formik.errors.price} />
                )}
              </div>
              <div className="w-full">
                <label className="block mb-1 text-xs font-normal text-gray-800">
                  Category
                </label>
                {!allMenuCategories.isLoading && (
                  <Combobox value={selected} onChange={setSelected}>
                    <div className="relative mt-1">
                      <div className="border border-gray-300 relative w-full cursor-default overflow-hidden rounded-md bg-white text-left shadow-sm sm:text-xs">
                        <Combobox.Input
                          className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 outline-none"
                          displayValue={(person) => person.name}
                          onChange={(event) => setQuery(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <BiChevronDown
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                      </div>
                      <Transition
                        // as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => {
                          if (selected.name !== query) {
                            setQuery("");
                          }
                          if (query !== "") {
                            setSelected({ id: query, name: query });
                          }
                          // setQuery(query);
                        }}
                      >
                        {categories.length > 0 && (
                          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-xs">
                            {categories.map((person) => (
                              <Combobox.Option
                                key={person.id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? "bg-blue-600 text-white"
                                      : "text-gray-900"
                                  }`
                                }
                                value={person}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {person.name}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                          active
                                            ? "text-white"
                                            : "text-blue-600"
                                        }`}
                                      >
                                        {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        )}
                      </Transition>
                    </div>
                  </Combobox>
                )}
              </div>

              <div className="">
                <label className="block mb-1 text-xs font-normal text-gray-800">
                  Image
                </label>
                <div className="flex items-center justify-evenly gap-2">
                  {imagesArray.map((imagePreview: string, index: number) => (
                    <div className="w-full h-[80px] relative" key={index}>
                      <img
                        src={imagePreview}
                        alt=""
                        onError={(e) =>
                          (e.currentTarget.src = "/media/image/productBg.png")
                        }
                        // onError={(e) =>
                        //   (e.target.style.display =
                        //     "none")
                        //   }
                        className="w-full h-full object-contain"
                      />
                      <div
                        className={classNames(
                          "absolute z-10 inset-0 m-auto font-semibold rounded-full bg-red-400 hover:bg-red-600 w-6 h-6 flex items-center justify-center cursor-pointer",
                          selectedImage ? "hidden" : ""
                        )}
                        onClick={() => {
                          // if (imagesIdentifierArray[index] !== "" || imagesIdentifierArray[index] !== undefined) {
                          //   handleDeleteS3Object.mutate({
                          //     objectKey: imagesIdentifierArray[index],
                          //   })
                          // }

                          const t = savedImagesArray.indexOf(imagePreview);

                          if (t === 3) {
                            setS3ImageUrl3("");
                            const newArray = [
                              imagesIdentifierArray[0],
                              imagesIdentifierArray[1],
                              imagesIdentifierArray[2],
                              "",
                            ];
                            setImagesIdentifierArray(newArray);
                          }
                          if (t === 2) {
                            setS3ImageUrl2("");
                            const newArray = [
                              imagesIdentifierArray[0],
                              imagesIdentifierArray[1],
                              "",
                              imagesIdentifierArray[3],
                            ];
                            setImagesIdentifierArray(newArray);
                          }
                          if (t === 1) {
                            setS3ImageUrl1("");
                            const newArray = [
                              imagesIdentifierArray[0],
                              "",
                              imagesIdentifierArray[2],
                              imagesIdentifierArray[3],
                            ];
                            setImagesIdentifierArray(newArray);
                            // imagesIdentifierArray[1] = "";
                          }
                          if (t === 0) {
                            setS3ImageUrl("");
                          }
                          setImagesArray(
                            imagesArray.filter((i) => i !== imagePreview)
                          );

                          // setImagesArray(prevState => prevState.filter((_, i) => i !== index));
                          // setImagesIdentifierArray(prevState => prevState.filter((_, i) => i !== index));
                        }}
                      >
                        <span className="text-[13px] text-white">
                          <MdClear size={22} />
                        </span>
                      </div>
                    </div>
                  ))}
                  {!selectedImage || !preview ? (
                    <div
                      className={classNames(
                        "w-full",
                        imagesArray.length > 3 ? "hidden" : ""
                      )}
                    >
                      <label
                        htmlFor="dropzone-file"
                        className="w-[60px] h-[60px] rounded-full bg-gray-200 flex items-center justify-center cursor-pointer"
                      >
                        <AiOutlinePlus size={28} />
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          accept="image/jpeg, image/png, image/jpg"
                          onChange={imageHandler}
                        />
                      </label>
                    </div>
                  ) : null}
                </div>
                {!selectedImage || !preview ? null : (
                  <div className="mt-2 flex items-center gap-4">
                    <img
                      src={preview}
                      alt=""
                      className="w-[50%] h-[80px] object-contain"
                    />
                    <div>
                      <div className="flex items-center flex-col gap-2">
                        <button
                          type="button"
                          className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-red-700 bg-transparent border border-red-700 rounded-md"
                          onClick={(e) => {
                            setSelectedImage(null);
                            setPreview(null);
                            // setS3ImageUrl("");
                            setImageUploadSuccess(false);
                          }}
                        >
                          <span>Cancel</span>
                          <span>
                            <MdClear />
                          </span>
                        </button>
                        <button
                          // disabled={!selectedImage || selectedImage === null}
                          type="button"
                          className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-white border rounded-md bg-blue-700 hover:bg-blue-800 border-primary"
                          onClick={() => {
                            handleCompressedUpload(selectedImage);
                          }}
                        >
                          <span>Upload</span>{" "}
                          {isLoading ? (
                            <ImSpinner2
                              size={20}
                              className="mx-auto animate-spin"
                            />
                          ) : (
                            <AiOutlineCloudUpload />
                          )}
                        </button>
                      </div>
                      {imageUploadSuccess && (
                        <p className="mt-2 text-xs text-center text-green-500">
                          Upload successful
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center justify-between gap-2 ">
                <div className="text-xs">
                  <p className="font-semibold text-gray-800">Item option</p>
                  <p className="text-gray-400 font-normal">
                    Edit item sizes and colours
                  </p>
                </div>
                <FiChevronRight
                  size={22}
                  className="cursor-pointer"
                  onClick={() => {
                    setPage(2);
                  }}
                />
              </div>
              <div className={classNames("mb-1.5 flex items-center")}>
                <input
                  checked={Number(freeDelivery)}
                  onChange={(e) => {
                    if (Number(freeDelivery) === 0) {
                      setFreeDelivery("1");
                      return;
                    }

                    if (Number(freeDelivery) === 1) {
                      setFreeDelivery("0");
                      return;
                    }
                  }}
                  type="checkbox"
                  value="delivery"
                  // disabled={(freeDeliveryStatus !== "1")}
                  className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-xs font-semibold text-gray-900"
                >
                  Free delivery{" "}
                  <span className="text-gray-400 font-normal">
                    {
                      "(Click to enable free delivery for selected store products)"
                    }
                  </span>
                </label>
              </div>
            </div>
            <div className="flex items-center justify-center mt-4">
              <button
                type="submit"
                disabled={isLoading1}
                className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
              >
                {isLoading1 ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Edit Item"
                )}
              </button>
            </div>
            <p className="mt-1 text-center text-green-500 capitalize">
              {success && <small>saved successfully!</small>}
            </p>
          </form>
        </div>
      ) : (
        <div className="p-3">
          <ItemOptions
            setPage={setPage}
            itemColors={itemColors}
            setItemColors={setItemColors}
            setItemSizes={setItemSizes}
            itemSizes={itemSizes}
          />
        </div>
      )}
    </>
  );
};

export default EditItemInCategory;
