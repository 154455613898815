import { Link, useLocation, useParams } from "react-router-dom";
import { base64Encode, classNames, formatStoreName } from "../../../utils";
import {
  getFreeDeliveryStatus,
  getSiteTitleAndTagLine,
  getTemplateSections,
} from "../../../api/Menu";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import Cart from "../Cart";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { FaTimes } from "react-icons/fa";
import MobileMenuWrapper from "../MobileMenuWrapper";
import { BiChevronDown } from "react-icons/bi";
import { useAppDispatch } from "../../../hooks";
import { SET_DELIVERY_STATUS } from "../../../redux/User";

interface Props {
  className?: string;
}

export type MenuItem = {
  Category?: string;
  image?: string;
  link?: string;
};

interface DropdownProps {
  title: string;
  items: MenuItem[];
  className?: string;
}

const MobileNavDropdown = (props: DropdownProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`relative inline-block text-left cursor-pointer group outline-none ${props.className}`}
    >
      <button
        onClick={() => setOpen(!open)}
        className={`flex items-center space-x-0.5 ${props.className}`}
      >
        <span>{props.title}</span>
        <BiChevronDown size={20} />
      </button>
      {props.items.length > 0 && open && (
        <div className="px-2 pt-4 space-y-6 outline-none">
          {[].map((item: MenuItem, index: number) => {
            return (
              <div key={index}>
                {item.link === "" ? (
                  <p
                    className={`cursor-pointer hover:text-primary transition duration-300 ease-linear text-themeBlack text-base font-medium rounded-xl flex flex-col`}
                  >
                    <span>{item.Category}</span>
                    {/* {item.desc !== undefined && (
                      <span className="text-xs text-left font-normal mt-0.5">
                        {item.desc}
                      </span>
                    )} */}
                  </p>
                ) : (
                  <Link
                    to={item.link as string}
                    className={`cursor-pointer hover:text-primary transition duration-300 ease-linear text-themeBlack text-base font-medium rounded-xl flex flex-col`}
                  >
                    <span>{item.Category}</span>
                    {/* {item.desc !== undefined && (
                      <span className="text-xs text-left font-normal mt-0.5">
                        {item.desc}
                      </span>
                    )} */}
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Header = ({ className = "" }: Props) => {
  const { salt, slug, section } = useParams();
  const queryKey = `/${salt}/${slug}`;
  const location = useLocation();
  // console.log(location.search);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const storeName = formatStoreName(queryKey);

  const [heroDetails, setHeroDetails] = useState({
    siteTitle: storeName,
    tagLine: "[SITE_TAGLINE_HERE]",
  });

  let siteName: string = storeName;

  let templateSections: any[] = [];
  let categories: any[] = [];
  let mobileCategories: MenuItem[] = [];

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (section === undefined) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location, section]);

  const siteTitleAndTagLine = useQuery<any, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(queryKey);
      return response.data;
    },
    onSuccess: (data: any) => {
      if (data.Site_Title !== "" || data.Tagline !== "") {
        setHeroDetails({
          siteTitle: data.Site_Title,
          tagLine: data.Tagline,
        });
      }
    },
  });

  const loadTemplateSections = useQuery<any, Error>({
    queryKey: ["getTemplateSections"],
    queryFn: async () => {
      const response = await getTemplateSections(queryKey);
      return response.data;
    },
  });

  if (loadTemplateSections.data) {
    templateSections = loadTemplateSections.data.Template_Sections;
  }

  if (siteTitleAndTagLine.data) {
    siteName = siteTitleAndTagLine.data.Site_Title;
  }

  return (
    <>
      <header
        className={classNames(
          "top-0 z-50 mb-0 w-full bg-[#FAFAFA] hidden md:block font-dm-sans",
          location.search.includes("reference") ? "hidden" : "fixed h-[70px]"
        )}
      >
        <nav
          className={classNames(
            "container flex space-y-6 md:space-y-0 flex-col md:flex-row items-center justify-between p-3.5",
            location.search.includes("reference") ? "hidden" : ""
          )}
        >
          <div className="text-center md:text-left">
            <Link
              to={`/store/${salt}/${slug}`}
              className="text-xl block font-semibold capitalize whitespace-nowrap"
              // onClick={() =>
              //   window.location.assign(
              //     window.location.origin + `/store/${salt}/${slug}`
              //   )
              // }
            >
              {siteName || storeName}
            </Link>
          </div>
          <div className="grow flex items-center justify-between w-full text-sm font-semibold md:justify-end md:space-x-6 lg:space-x-8">
            <Link
              to={`/store/${salt}/${slug}/all-products`}
              className={classNames(
                !templateSections.includes("Product") === true ? "" : "hidden"
              )}
            >
              All Products
            </Link>
            <Cart />
          </div>
        </nav>
      </header>
      <header
        className={classNames(
          "fixed h-[70px] top-0 mb-0 w-full bg-[#FAFAFA] block md:hidden z-50",
          location.search.includes("reference") ? "hidden" : ""
        )}
      >
        <nav className="container flex items-center justify-between p-3.5">
          <div className="flex items-center space-x-2.5 grow">
            <div className="flex flex-col items-center justify-center">
              <button
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                className="outline-none stroke-black fill-black hidden"
              >
                {showMobileMenu ? (
                  <FaTimes size={32} stroke="1" />
                ) : (
                  <HiOutlineMenuAlt3 size={32} className="-scale-x-100" />
                )}
              </button>
            </div>
            <div className="text-left grow">
              <Link
                to={`/store/${salt}/${slug}`}
                className="block text-xl sm:text-xl font-semibold capitalize"
              >
                {heroDetails.siteTitle}
              </Link>
            </div>
          </div>
          <div>
            <Cart />
          </div>
        </nav>
      </header>
      <MobileMenuWrapper
        show={showMobileMenu}
        onToggle={() => setShowMobileMenu(false)}
      >
        <div className="flex flex-col w-screen h-screen pt-16 pb-2 bg-white shadow-sm shadow-gray-300">
          <div className="container flex flex-col items-start flex-1 px-5 mt-5 space-y-8 overflow-y-auto text-lg font-medium outline-none font-inter text-themeBlack">
            <Link
              to={`/store/${salt}/${slug}/all-products`}
              className={classNames(
                !templateSections.includes("Product") === true ? "" : "hidden"
              )}
            >
              All Products
            </Link>
            <div className="hidden">
              <MobileNavDropdown
                title="Categories"
                items={mobileCategories}
                className="justify-between w-full"
              />
            </div>
          </div>
          <div className="py-3 hidden font-inter text-[#263C6B] text-2xl font-medium w-10/12 mx-auto">
            <Cart onOpen={() => setShowMobileMenu(false)} />
          </div>
        </div>
      </MobileMenuWrapper>
    </>
  );
};

export default Header;
