import { RiBarChartLine, RiVoiceprintLine, RiFacebookFill, RiLinkedinBoxFill, RiTwitterFill, RiInstagramLine } from "react-icons/ri";
import { FaSms } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { BsQrCodeScan } from "react-icons/bs";
import { BiQrScan } from "react-icons/bi";
import { TbWorldWww } from "react-icons/tb";
import { getSvg } from "../../../utils";

interface Props { 
  source: string;
};

const SourceIcon = ({ source } : Props) => {
    
    let icon = <RiBarChartLine className="mx-auto" size={20} />;
  
    switch (source) {
      case "voice":
        icon = <RiVoiceprintLine className="mx-auto" size={20} />
        break;

      case "site_data":
        // icon = <BiQrScan className="mx-auto" size={22} />
        icon = <img src={getSvg("QRCode")} alt="" className="w-[16px] fill-gray-600" />
        break;

      case "web":
        // icon = <TbWorldWww className="mx-auto" size={22} />
        icon = <img src={getSvg("Web")} alt="" className="w-[22px] fill-gray-600" />
        break;
  
      case "sms":
        icon = <FaSms className="mx-auto" size={20} />
        break;
  
      case "facebook":
        icon = <RiFacebookFill className="mx-auto" size={20} />
        break;
  
      case "linkedin":
        icon = <RiLinkedinBoxFill className="mx-auto" size={20} />
        break;
  
      case "twitter":
        icon = <RiTwitterFill className="mx-auto" size={20} />
        break;
  
      case "instagram":
        icon = <RiInstagramLine className="mx-auto" size={20} />
        break;
  
      default:
        break;
    }
  
    return icon;
}

export default SourceIcon;