import React from "react";

interface Props {
  item: any;
  deliveryStatus: any;
}

const CategoryCard = ({ item, deliveryStatus }: Props) => {
  return (
    <div>
      <div className="rounded-md cursor-pointer relative h-[150px]" >
        <img
          src={item?.s3ImageUrl}
          loading="lazy"
          onError={(e) => (e.currentTarget.src = "/media/image/productBg.png")}
          alt="Item image"
          className="absolute object-cover w-full h-full rounded-md bg-gray-50"
        />
        {(deliveryStatus === "1" && item.freeDelivery === "1") && (<p className="absolute top-3 right-2 py-1 px-1.5 bg-blue-700 text-white rounded text-xs">Free delivery</p>)}
        {/* {deliveryStatus === "1" ? (<p className="absolute top-3 right-2 py-1 px-1.5 bg-blue-700 text-white rounded text-xs">Free delivery</p>) : item.freeDelivery === "1" ? (<p className="absolute top-3 right-2 py-1 px-1.5 bg-blue-700 text-white rounded text-xs">Free delivery</p>) : null} */}

        {/* {item.freeDelivery === "1" && deliveryStatus !== "0" ? (<p className="absolute top-3 right-2 py-1 px-1.5 bg-blue-700 text-white rounded text-xs">Free delivery</p>) : null} */}
        <div className="absolute w-full h-full transition bg-black rounded-md bg-opacity-20 duration-350 hover:bg-opacity-30"></div>
      </div>
      <div className="flex gap-3 justify-between mt-1 text-xs" >
        <p className="font-semibold text-gray-900 capitalize">
          {item.itemName}
        </p>
        <p className="font-semibold text-gray-900 ">
          &#x20A6;{item.itemPriceDigit}
        </p>
      </div>
    </div>
  );
};

export default CategoryCard;
