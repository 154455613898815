import React, { useState } from "react";
import { BiCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../../../../utils";

const WebPage = () => {
  const navigate = useNavigate();
  // const [selected, setSelected] = useState(-1);
  const [selected, setSelected] = useState(-1);

  return (
    <div className="w-full rounded-md h-[85vh] bg-white p-6 px-4 sm:px-10">
      <div className="">
        <div className="space-y-2 text-left">
          <h2 className="text-xl text-black font-semibold">
            Sell your products with Cusecho
          </h2>
          <p className="text-gray-600 text-[13px] font-medium max-w-[550px]">
            Begin your retail journey on Cusecho! Create your store or enter
            your store link if you already have one to start selling.
          </p>
        </div>
      </div>

      <div className="space-y-3 sm:space-y-0 sm:flex items-center gap-6 mt-6">
        <div
          className={classNames(
            "p-2 border-2 space-y-1 sm:max-w-[280px] rounded-md cursor-pointer",
            selected === 1 ? "bg-[#729BE1] bg-opacity-40 border-[#6194ec]" : ""
          )}
          onClick={() => setSelected(1)}
        >
          <p className="text-base font-semibold">Create store</p>
          <p className="text-xs font-medium">
          Create your store using our customizable web builder.
          </p>
        </div>
        <div
          className={classNames(
            "p-2 border-2 space-y-1 sm:max-w-[280px] rounded-md cursor-pointer",
            selected === 2 ? "bg-[#729BE1] bg-opacity-40 border-[#6194ec]" : ""
          )}
          onClick={() => setSelected(2)}
        >
          <p className="text-base font-semibold">Upload store link</p>
          <p className="text-xs font-medium">
            Already have a website? Upload your website URL here.
          </p>
        </div>
      </div>

      <div className="mt-6">
        <button
          disabled={selected === -1}
          className={classNames(
            "w-full sm:w-auto px-6 py-2 text-sm text-center  text-white bg-blue-700 hover:bg-blue-800 rounded",
            selected === -1 ? "cursor-not-allowed" : ""
          )}
          onClick={() => {
            if (selected === 1) {
              navigate("/home/choose-product-type");
            }
            if (selected === 2) {
              navigate("/home/echo-webpage/upload-url");
            }
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default WebPage;
