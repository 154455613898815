import { useState } from "react";
import BackButton from "../../../components/Auth/Forms/BackButton";
import OTPInput from "../../../components/Auth/Forms/OTPInput";
import { useNavigate, useParams } from "react-router-dom";
import UserPool from "../../../setup/userpool/index";
import {
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import Alert from "../../../components/Auth/Forms/Alert/indext";
import { ImSpinner2 } from "react-icons/im";
import { accountSetup } from "../../../api/Auth";
import { decode } from "js-base64";
import Modal from "../../../components/Dashboard/Modal";

const CompleteRegisterationPage = () => {
  const { email, passcode } = useParams();
  const decodedPasscode = decode(passcode as string) || "";
  const [showModal, setShowModal] = useState(true);
  // console.log(decodedPasscode);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [code, setCode] = useState("");

  const handleChange = (value: string) => {
    setCode(value);
  };

  const confirmSignUp = (e: any) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const cognitoUserPool = () => UserPool;
    const userPool = cognitoUserPool();
    const userData = {
      Username: email as string,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, function (err, result) {
        if (err) {
          reject(err);
          setError(err.message);
          setIsLoading(false);
        } else {
          resolve(result);
          if (result) {
            const authDetails = new AuthenticationDetails({
              Username: email as string,
              Password: decodedPasscode as string,
            });
            cognitoUser.authenticateUser(authDetails, {
              onSuccess: async (data) => {
                // console.log(data);
                // console.log("signed In");
                resolve(data);
                // navigate("/login");
                const jwt = data.getIdToken().getJwtToken();
                // console.log("jwt", jwt);

                const requestOptions = {
                  method: "POST",
                  headers: {
                    Authorization: jwt,
                  },
                  body: null,
                };

                try {
                  const response = await accountSetup(requestOptions);
                  if (response) {
                    const accountId = response.Platform_Account;
                    const userRole = response.User_Role;
                    const platformSlug = response.Platform_Slug_Url;
                    var attributeList = [];
                    var updatedProfileAttribute = {
                      Name: "profile",
                      Value: accountId,
                    };
                    var updatedPlatformSlug = {
                      Name: "given_name",
                      Value: platformSlug,
                    };
                    var updatedUserRoleAttribute = {
                      Name: "nickname",
                      Value: userRole,
                    };
                    var updateProfile = new CognitoUserAttribute(
                      updatedProfileAttribute
                    );
                    var updatePlatformSlug = new CognitoUserAttribute(
                      updatedPlatformSlug
                    );
                    var updateUserRole = new CognitoUserAttribute(
                      updatedUserRoleAttribute
                    );
                    attributeList.push(updateProfile);
                    attributeList.push(updateUserRole);
                    attributeList.push(updatePlatformSlug);

                    cognitoUser.updateAttributes(
                      attributeList,
                      function (err, result) {
                        if (err) {
                          alert(err.message || JSON.stringify(err));
                          return;
                        }
                        // console.log("call result: " + result);
                      }
                    );
                    localStorage.setItem("customerEchoNewUser", "1");
                    const user = UserPool.getCurrentUser();
                    user?.signOut();
                    // console.log("User Signed Out");
                    setIsLoading(false);
                    navigate("/login");
                  }
                } catch (err) {
                  console.log(err);
                }
              },
              onFailure: (err) => {
                console.log("err fetch", err);
              },
            });
          }
        }
      });
    });
  };

  const resendCode = () => {
    // console.log("1234")
    const cognitoUserPool = () => UserPool;
    const userPool = cognitoUserPool();
    const userData = {
      Username: email as string,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode(function (err, result) {
      setShowModal(true);
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
    });
  };

  return (
    <>
      <Modal show={showModal} onToggle={setShowModal}>
        <div className="w-full p-6">
          <div className="flex items-center justify-center">
            <img
              src="/media/svg/check.svg"
              alt="Success icon"
              className="h-[100px]"
            />
          </div>
          <div className="mt-4 text-center">
            <p className="text-xl text-[#3464be] font-semibold">
              Check Your Email
            </p>
            <p className="text-[13px] text-gray-800">
              We've sent a verification code to your email. Please check your
              inbox and enter it to finish setting up your account.
            </p>
          </div>
          <button
            type="button"
            className=" w-full text-white bg-[#3464be] font-medium rounded-lg text-sm px-8 py-3 mt-4"
            onClick={() => setShowModal(false)}
          >
            Continue
          </button>
        </div>
      </Modal>
      <div className="flex items-center justify-center w-full h-screen px-6 py-12">
        <div className="max-w-[500px] mx-auto max-h-full my-auto">
          <div className="absolute left-5 top-14 md:left-5 md:top-10 lg:left-8">
            <BackButton onClick={() => navigate("/login")} />
          </div>
          <div className="text-center">
            <h3 className="font-semibold text-2xl text-[#3464be]">
              Verify your email address
            </h3>
            <p className="text-sm text-gray-700">
              Enter the 6 digit verification code sent to your email address.
            </p>
          </div>
          <div className="mt-4">
            {error && <Alert type="error" message={error} />}
            <form onSubmit={confirmSignUp}>
              <div className="w-full px-3 mt-4 md:px-5">
                <OTPInput label="Enter code" onChange={handleChange} />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="px-3 py-1 mt-2 text-sm font-semibold text-right text-red-700 cursor-pointer md:px-5"
                  onClick={() => resendCode()}
                >
                  Resend code
                </button>
              </div>
              <div className="w-full mt-4">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#3464be] w-full py-2 text-lg font-semibold text-center text-white outline-none rounded-md"
                >
                  {isLoading ? (
                    <ImSpinner2 size={28} className="mx-auto animate-spin" />
                  ) : (
                    "Verify"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteRegisterationPage;
