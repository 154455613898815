import { getImage } from "../../../utils";
import { Link } from "react-router-dom";

const StoreAnalyticsPage = () => {
  return (
    <div className="space-y-1">
    <div className="overflow-y-hidden relative">
      <div className="w-full">
        <div className="h-full mx-auto">
          <img
            src={getImage("coming-soon-bg")}
            alt=""
            className="max-w-full mx-auto"
          />
        </div>
      </div>
      <div className="mt-10 absolute bottom-16 pl-10 text-[#132C58] ">
        <h4 className="text-6xl font-bold uppercase ">Coming soon</h4>
        <p className="mt-2 text-lg font-medium max-w-[480px]" >
          We’re working hard and fast to bring you features that will help
          scale your business.
        </p>
      </div>
    </div>
  </div>
  );
};

export default StoreAnalyticsPage;
