// @ts-nocheck

import { useState } from "react";
import { AiOutlineEdit, AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../../components/Dashboard/Modal";
import { BiChevronLeft, BiPlus } from "react-icons/bi";
import Input from "../../../../../components/Auth/Forms/Input";
import { MdOutlineDelete, MdOutlineKeyboardBackspace } from "react-icons/md";
import OTPInput from "../../../../../components/Auth/Forms/OTPInput";
import { getSvg } from "../../../../../utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { StoreModel, UserProfileModel } from "../../../../../models";
import { useAppSelector } from "../../../../../hooks";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImSpinner2 } from "react-icons/im";
import { v4 as uuidv4 } from "uuid";
import { postAccountDetail } from "../../../../../api/AccountDetails";

const paymentDetailsValues = {
  bankName: "",
  accountName: "",
  accountNumber: "",
};

const paymentDetailsSchema = Yup.object().shape({
  bankName: Yup.string()
    .min(2, "Minimum 2 letters")
    .max(50, "Maximum 50 letters")
    .required("Bank Name is required"),
  accountName: Yup.string(),
  accountNumber: Yup.string()
});

type ModalTypes = "transfer" | "bankDetails" | "verification" | "details";

const PayoutPage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<ModalTypes | null>(null);
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const [showModal1, setShowModal1] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // console.log(apiKey)
  const [code, setCode] = useState("");
  const [step, setStep] = useState(1);
  const [dummyInput, setDummyInput] = useState("");
  let paymentDetails: any[] = [];
  // const [selectedAccountPosition, setSelectedAccountPosition] = useState(null);
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const handleChange = (value: string) => {
    setCode(value);
  };
  const [editAccount, setEditAccount] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: paymentDetailsValues,
    validationSchema: paymentDetailsSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      setIsLoading(true);
      const payload = {
        platformKey,
        paymentInfo: [
          {
            accountNumber: values.accountNumber.toString(),
            accountName: values.accountName,
            bankName: values.bankName,
            id: uuidv4(),
          },
        ],
        updateFlag: "create",
      };

      try {
        postAccountDetail(payload, apiKey);
        setTimeout(() => {
          setSubmitting(false);
          resetForm({});
          setShowModal1(false);
          setIsLoading(false);
          queryClient.invalidateQueries({ queryKey: ["getPaymentDetails"] });
        }, 3000);
      } catch (err) {
        setSubmitting(false);
        setIsLoading(false);
        console.log(err);
      }
      //   setSubmitting(false);
    },
  });

  const formik1 = useFormik({
    initialValues: paymentDetailsValues,
    validationSchema: paymentDetailsSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      setIsLoading(true);
      const acct = paymentDetails.findIndex((obj) => obj.id === selectedId);
      paymentDetails[acct] = {
        accountNumber: values.accountNumber.toString(),
        accountName: values.accountName,
        bankName: values.bankName,
        id: uuidv4(),
      };
      //   console.log(acct);
      const payload = {
        platformKey,
        paymentInfo: paymentDetails,
        updateFlag: "",
      };

      try {
        postAccountDetail(payload, apiKey);
        setTimeout(() => {
          setSubmitting(false);
          resetForm({});
          setSelectedId("");
          setShowEditModal(false);
          setIsLoading(false);
          queryClient.invalidateQueries({ queryKey: ["getPaymentDetails"] });
        }, 3000);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setSubmitting(false);
      }
      //   setSubmitting(false);
    },
  });


  const getPaymentDetail = async () => {
    return fetch(
      `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/get_payment_detail?platformKey=${store.name}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("paymentToken")}`,
          "X-API-KEY": apiKey,
        },
      }
    ).then((response) => response.json());
  };

  const allPaymentDetails = useQuery<any, Error>({
    queryKey: ["getPaymentDetails"],
    queryFn: getPaymentDetail,
  });

  if (allPaymentDetails.data) {
    if (allPaymentDetails.data?.Payment_Details) {
      paymentDetails = allPaymentDetails.data?.Payment_Details;
    } else {
      paymentDetails = [];
    }
  }

  const handleDeleteAccount = useMutation({
    mutationFn: async (data) => {
      // console.log(data.id)
      const acct = paymentDetails.filter((p) => p.id !== selectedId);
      const payload = {
        platformKey,
        paymentInfo: acct,
        updateFlag: "",
      };
      return await postAccountDetail(payload, apiKey);
    },
    onSuccess: () => {
      //   setSelectedObject({});
      queryClient.invalidateQueries({ queryKey: ["getPaymentDetails"] });
      setShowDeleteModal(false);
    },
  });

  return (
    <>
      <Modal
        show={showDeleteModal}
        onToggle={() => {
          setShowDeleteModal(false);
          //   setSelectedObject({});
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-xl">
              You are about to delete this account number
            </p>
          </div>
          <div className="flex flex-col justify-center md:flex-row gap-2 mt-5 md:mt-8">
            <div>
              <button
                className="px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 hover:bg-blue-800 border rounded-md w-full md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  handleDeleteAccount.mutate();
                }}
              >
                {handleDeleteAccount.isLoading ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 text-sm font-semibold text-center text-blue-700 bg-white hover:bg-gray-50 border border-blue-700 rounded-md  w-full md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedId("");
                  setShowDeleteModal(false);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showModal1}
        onToggle={() => {
          setEditAccount(false);
          setShowModal1(false);
          formik.resetForm({});
        }}
      >
        <div className="relative flex flex-col p-3 px-6">
          <div className="flex items-center justify-between mb-6">
            <p className="font-semibold">Bank Details</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-3">
              <div className="w-full">
                <Input
                  formik={formik}
                  type="text"
                  name="bankName"
                  placeHolder="Enter bank name"
                  label="Bank name"
                />
              </div>
              <div className="w-full">
                <Input
                  formik={formik}
                  type="text"
                  name="accountName"
                  placeHolder="Enter account name"
                  label="Account name"
                />
              </div>
              <div className="w-full">
                <Input
                  formik={formik}
                  type="text"
                  name="accountNumber"
                  placeHolder="Enter account number"
                  label="Account number"
                />
              </div>
            </div>
            <div className="flex items-center justify-center mt-6">
              <button
                type="submit"
                className="items-center w-48 gap-3 px-5 py-2 text-sm text-center text-white rounded-md bg-blue-700 hover:bg-blue-800"
              >
                {isLoading ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        show={showEditModal}
        onToggle={() => {
          setEditAccount(false);
          setShowEditModal(false);
          formik1.resetForm({});
        }}
      >
        <div className="relative flex flex-col p-3 px-6">
          <div className="flex items-center justify-between mb-6">
            <p className="font-semibold">Edit Bank Details</p>
          </div>
          <form onSubmit={formik1.handleSubmit}>
            <div className="space-y-3">
              <div className="w-full">
                <Input
                  formik={formik1}
                  type="text"
                  name="bankName"
                  placeHolder="Enter bank name"
                  label="Bank name"
                />
              </div>
              <div className="w-full">
                <Input
                  formik={formik1}
                  type="text"
                  name="accountName"
                  placeHolder="Enter account name"
                  label="Account name"
                />
              </div>
              <div className="w-full">
                <Input
                  formik={formik1}
                  type="text"
                  name="accountNumber"
                  placeHolder="Enter account number"
                  label="Account number"
                />
              </div>
            </div>
            <div className="flex items-center justify-center mt-6">
              <button
                // disabled={formik1.isSubmitting}
                type="submit"
                className="items-center w-48 gap-3 px-5 py-2 text-sm text-center text-white rounded-md bg-blue-700 hover:bg-blue-800"
              >
                {isLoading ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Save changes"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        size="medium"
        show={showModal === "transfer"}
        onToggle={() => {
          setShowModal(null);
        }}
      >
        <div className="relative flex flex-col p-8 px-8">
          <div className="p-4 bg-[#FDE6E9] rounded-md">
            <p className="font-semibold text-[#7D5901] text-center italic">
              Only a Super Admin is authorised to make transfers out of this
              account. To make a transfer, request permission.
            </p>
          </div>
        </div>
      </Modal>
      <Modal
      size="medium"
        show={showModal === "bankDetails"}
        onToggle={() => {
          setShowModal(null);
        }}
      >
        <div className="relative flex flex-col p-3 px-6">
          <div className="flex items-center justify-between mb-6">
            <p className="font-semibold">Bank Details</p>
          </div>
          <div className="grid grid-cols-1 gap-4 mb-6 lg:grid-cols-2">
            {paymentDetails.slice(0,2).map((account: any, index: number) => (
              <div className="p-2.5 border rounded-md" key={index}>
                <div className="flex items-center justify-between mb-3">
                  <p className="text-xl font-semibold">Account {index + 1}</p>
                  <div className="flex items-center gap-3">
                    <AiOutlineEdit size={20} className="cursor-pointer"  onClick={() => {
                          setEditAccount(true);
                          setShowModal(null);
                          formik1.setFieldValue("bankName", account.bankName);
                          formik1.setFieldValue(
                            "accountName",
                            account.accountName
                          );
                          formik1.setFieldValue(
                            "accountNumber",
                            account.accountNumber
                          );
                          setSelectedId(account.id);
                          setShowEditModal(true);
                        }}/>
                    <MdOutlineDelete size={20} className="cursor-pointer" onClick={() => {
                      setShowModal(null);
                          setSelectedId(account.id);
                          setShowDeleteModal(true);
                        }}/>
                  </div>
                </div>
                <div className="space-y-1 text-sm font-semibold">
                  <p>Bank name: {account.bankName}</p>
                  <p>Account number: {account.accountNumber}</p>
                  <p>Account name: {account.accountName}</p>
                </div>
              </div>
            ))}
             {paymentDetails.length > 1 || allPaymentDetails.isLoading ? null : (
            <div className="p-2.5 border rounded-md flex items-center justify-center bg-gray-50 hover:bg-gray-100">
              <button
                className="texxt-gray-800 font-semibold inline-flex gap-2 items-center"
                onClick={() => { 
                  setShowModal(null); 
                  setShowModal1(true)}}
              >
                <BiPlus size={20} /> Add account details
              </button>
            </div>
          )}
          </div>
        </div>
      </Modal>
      <Modal
        size="medium"
        show={showModal === "verification"}
        onToggle={() => {
          setShowModal(null);
        }}
      >
        {step === 1 ? (
          <div className="relative flex flex-col p-8 px-8">
            <div className="mb-6 text-center">
              <p className="font-semibold mb-1.5">Verification</p>
              <p className="text-sm">Input Authentication Code Below</p>
            </div>
            <div className="w-full px-3 md:px-5">
              <OTPInput label="Enter code" onChange={handleChange} />
            </div>
            <div className="grid grid-cols-2 gap-2 px-3 my-6 mt-6 md:px-5">
              <button
                className="px-6 py-2 text-sm text-white rounded bg-primary"
                onClick={() => setStep(2)}
              >
                Continue
              </button>
              <button className="px-6 py-1.5 text-sm rounded bg-transparent border border-red-700 text-red-700">
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="relative flex flex-col p-6 md:py-12 md:px-16">
            <div className="mt-5 text-center md:mt-8">
              <img src={getSvg("check")} alt="icon" className="w-24 mx-auto" />
            </div>
            <div className="mt-2 space-y-2 text-center md:mt-4">
              <h1 className="font-semibold md:text-2xl">
                Transaction Complete
              </h1>
            </div>
            <span
              className="absolute cursor-pointer top-2 right-2"
              onClick={() => {
                setStep(1);
                setShowModal(null);
              }}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                  fill="#5A5A5A"
                />
              </svg>
            </span>
          </div>
        )}
      </Modal>
      <div className="h-[85vh] bg-white rounded-md shadow p-3 flex items-center justify-center hidden">
        <div className="">
          <p className="text-lg font-semibold text-center text-gray-900">
            No payout history available
          </p>
          <p className="text-center">
            You don't have an online store yet. Add your store URL or create an
            online store.
          </p>
          <p className="text-sm text-center">
            Click{" "}
            <span
              className="cursor-pointer text-primary"
              onClick={() => navigate("/home/echo-webpage/customizer")}
            >
              here
            </span>{" "}
            to create one
          </p>
        </div>
      </div>
      <div className="h-[85vh] bg-white rounded-md shadow p-3 relative overflow-x-hidden ">
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => {
            navigate("/home/wallet");
          }}
        >
          <MdOutlineKeyboardBackspace size={22} />
          <h4 className="text-gray-800 capitalize text-xl font-semibold">
            Back
          </h4>
        </div>
        <p className="mb-3 text-lg font-semibold text-center uppercase">
          Payout
        </p>
        <div className="flex items-center justify-center">
          <div className="px-12 py-6 border rounded shadow-md shadow-gray-300">
            <p className="mb-1 text-4xl font-semibold">&#x20A6; 0.00</p>
            <p className="flex items-center gap-3">
              <span className="text-xs">Available in Withdrawal</span>{" "}
              <AiOutlineInfoCircle
              // onClick={() => {
              //   setShowModal("transfer");
              // }}
              />
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between items-center gap-3 mt-3 lg:flex-row">
          <div className="space-y-1 text-center lg:text-left text-sm">
            {paymentDetails.length > 0 && (
              <p className="max-w-[650px]">
                Payout will be sent to{" "}
                <strong>{paymentDetails[0]?.accountName}</strong> -{" "}
                {paymentDetails[0].bankName} {paymentDetails[0]?.accountNumber}
              </p>
            )}
            <p className="italic">
              Not the right account?{" "}
              <span
                className="text-blue-600 cursor-pointer"
                onClick={() => {
                  setShowModal("bankDetails");
                }}
              >
                Change details
              </span>
            </p>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="mb-1 text-xs text-center lg:text-right"
            >
              Enter amount (N){" "}
            </label>
            <input
              type="text"
              placeholder=""
              className="w-full lg:w-40 px-3 py-2 text-gray-800 border rounded-md"
              value={dummyInput}
              onChange={(e) => setDummyInput(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center justify-center mt-3">
          <button
            disabled
            className="items-center w-48 gap-3 px-5 py-2 text-sm text-center text-white rounded-md cursor-not-allowed bg-primary"
            onClick={() => {
              setShowModal("verification");
            }}
          >
            Transfer
          </button>
        </div>
        <div className="hidden">
          <p className="hidden mb-2 text-lg font-semibold">
            Transaction History
          </p>
          <div className="w-full h-[35vh] overflow-auto ">
            <div className="min-w-[1000px] mx-auto">
              <div className="flex w-full overflow-x-auto text-sm font-semibold border">
                <div className="w-[2.3%] py-1.5 px-2 border-r">S</div>
                <div className="w-[10%] py-1.5 px-2 border-r text-center">
                  AMOUNT
                </div>
                <div className="w-[20%] py-1.5 px-2 border-r">SENDER</div>
                <div className="w-[34.3%] py-1.5 px-2 border-r">
                  DESCRIPTION
                </div>
                <div className="w-[33.4%] grid grid-cols-3">
                  <p className="text-center py-1.5 px-2 border-r">DATE</p>
                  <p className="text-center py-1.5 px-2 border-r">STATUS</p>
                  <p className="text-center py-1.5 px-2"></p>
                </div>
              </div>
              {[1, 2, 3].map((index: number) => (
                <div
                  className="flex w-full overflow-x-auto text-sm border"
                  key={index}
                >
                  <div className="w-[2.3%] py-1.5 px-2 border-r">{index}</div>
                  <div className="w-[10%] py-1.5 px-2 border-r text-center ">
                    N12,000
                  </div>
                  <div className="w-[20%] py-1.5 px-2 border-r">
                    Adekunle Damilola
                  </div>
                  <div className="w-[34.3%] py-1.5 px-2 border-r">
                    Lorem ipsum dolor sit amet consectetur adipisicing.Lorem
                    ipsum dolor sit amet consectetur adipisicing.
                  </div>
                  <div className="w-[33.4%] grid grid-cols-3">
                    <p className="text-center py-1.5 px-2 border-r">
                      11/08/2023
                    </p>
                    <p className="text-center py-1.5 px-2 border-r">Pending</p>
                    <button className="text-center py-1.5 px-2 cursor-pointer">
                      Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            className="text-gray-800 font-semibold py-1.5 px-3 flex items-center gap-1.5 rounded absolute top-2 left-3"
            onClick={() => navigate("/home/wallet")}
          >
            <BiChevronLeft size={22} />
            <span>Wallet</span>
          </button>
        </div>
        <p className="mt-16 text-sm font-medium text-center text-gray-500">
          You don’t have any transactions yet. Start selling to earn.
        </p>
      </div>
    </>
  );
};

export default PayoutPage;
