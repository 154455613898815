// @ts-nocheck

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { UserProfileModel } from "../../../../../../models";
import { useAppSelector } from "../../../../../../hooks";
import { useQuery } from "@tanstack/react-query";
import {
  downloadNPSResponses,
  getQuestionFeedbackChart,
} from "../../../../../../api/Utils";
import SkeletonLoader from "../../../../../../components/Dashboard/SkeletonLoader";
import { classNames } from "../../../../../../utils";

const KPITracker = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = `${user.platformAccountId}%26${user.email}`;
  let nps: any;
  let width = {
    promoters: null,
    passive: null,
    detractors: null,
  };

  const npsResponses = useQuery<any, Error>({
    queryKey: ["getNPSResponses"],
    queryFn: async () => {
      const response = await downloadNPSResponses(queryKey);
      // 1002820710%26admin@cloudpactai.com
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  if (npsResponses.data) {
    nps = npsResponses.data;
    // console.log(nps);
    width = {
      promoters: Number(((nps.Promoters * 100) / nps.NPS_Responses).toFixed()),
      passive: Number(((nps.Passives * 100) / nps.NPS_Responses).toFixed()),
      detractors: Number(
        ((nps.Detractors * 100) / nps.NPS_Responses).toFixed()
      ),
    };
    
  }

  if (npsResponses.status === "loading")
    return <SkeletonLoader className="w-full h-full rounded-md" />;

  if (npsResponses.status === "error")
    return <span>Error: {npsResponses.error.message}</span>;

  // console.log(nps);

  return (
    <>
      <div className="">
        <div className="h-full bg-gray-50/70 p-3 rounded-lg text-sm border border-gray-100">
          <p className="font-semibold mb-0.5">NPS responses </p>
          <p className="text-2xl font-semibold inline-flex gap-1 items-center">
            {nps.NPS_Responses}
            <span className="text-xs text-gray-400 font-normal">Responses</span>
          </p>

          <div className="border-l-[1px] border-b-[1px] border-gray-200 h-[145px] py-3 flex flex-col justify-between">
            <div
            style={{width: width.promoters + "%"}}
              className={classNames(
                width.promoters === null ? "hidden" : "",
                width.promoters === 0 ? "hidden" : "",
                isNaN(width.promoters) ? "hidden" : "",
                `h-[30px] bg-[#3C85F2] flex justify-center items-center relative text-sm`
              )}
            >
              {width.promoters > 40 ? (
                <p className="font-semibold text-white">Promoters({nps.Promoters})</p>
              ) : (
                <p className="font-semibold absolute -right-28">Promoters({nps.Promoters})</p>
              )}
            </div>
            <div
            style={{width: width.passive + "%"}}
              className={classNames(
                `h-[30px] bg-gray-200 flex justify-center items-center relative text-sm`,
                width.passive === null ? "hidden" : "",
                width.passive === 0 ? "hidden" : "",
                isNaN(width.passive) ? "hidden" : ""
              )}
            >
              {width.passive > 40 ? (
                <p className="font-semibold text-gray-800">Passives({nps.Passives})</p>
              ) : (
                <p className="font-semibold absolute -right-24">Passives({nps.Passives})</p>
              )}
            </div>
            <div
            style={{width: width.detractors + "%"}}
              className={classNames(
                `h-[30px] bg-red-500 flex justify-center items-center relative text-sm`,
                width.detractors === null ? "hidden" : "",
                width.detractors === 0 ? "hidden" : "",
                isNaN(width.detractors) ? "hidden" : ""
              )}
            >
              {width.detractors > 40 ? (
                <p className="font-semibold text-white">Detractors({nps.Detractors})</p>
              ) : (
                <p className="font-semibold absolute -right-24">Detractors({nps.Detractors})</p>
              )}
            </div>
          </div>

          <div className="mt-1.5 text-xs flex items-center justify-between">
            <span>0%</span>
            <span className="text-gray-700">Percentage of responses</span>
            <span>100%</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default KPITracker;
