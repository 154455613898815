// @ts-nocheck

import React, { useState, Fragment } from "react";
import { ImSpinner2, ImSpinner3 } from "react-icons/im";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import {
  delectS3Object,
  deleteSingleMenu,
  getMenuByCategory,
} from "../../../../../../../api/Menu";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../../../../hooks";
import { UserProfileModel } from "../../../../../../../models";
import { IoMdMore } from "react-icons/io";
import Modal from "../../../../../../../components/Dashboard/Modal";
import { Menu, Transition } from "@headlessui/react";

const ItemsInCategory = ({
  activeCategory,
  setActiveCategory,
  setStep,
  selectedObject,
  setSelectedObject,
  setProductSidePanelStep,
}) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: UserProfileModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const queryKey = user.queryKey;
  let itemsInCategory: number = 0;
  let menusByCategory: any[] = [];

  const allMenuByCategory = useQuery<any, Error>({
    queryKey: ["getMenuByCategory", activeCategory],
    queryFn: async () => {
      const response = await getMenuByCategory(storeKey, activeCategory);
      return response.data;
    },
    enabled: !!activeCategory,
  });

// console.log(activeCategory);

  const deleteMenu = useMutation({
    mutationFn: async (data) => {
      const payload = {
        itemId: data.itemId,
      };
      return await deleteSingleMenu(payload);
    },
    onSuccess: () => {
      setSelectedObject({});
      queryClient.invalidateQueries({ queryKey: ["getMenuByCategory"] });
      queryClient.invalidateQueries({ queryKey: ["getStoreItems"] });
      setDeleteItemModal(false);
    },
  });

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        objectKey: data.objectKey,
      };
      return await delectS3Object(payload);
    },
    onSuccess: () => {
      
    },
  });

  if (allMenuByCategory.data) {
    menusByCategory = allMenuByCategory.data.Menu_Response;
    itemsInCategory = menusByCategory.length;
  }



  return (
    <>
      <Modal
        show={deleteItemModal}
        onToggle={() => {
          setDeleteItemModal(false);
          setSelectedObject({});
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-xl">
              You are about to delete this item from {activeCategory}
            </p>
          </div>
          <div className="flex flex-col justify-center md:flex-row gap-2 mt-5 md:mt-8">
            <div>
              <button
                disabled={deleteMenu.isLoading}
                className="px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 hover:bg-blue-800 border rounded-md w-full md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  deleteMenu.mutate({
                    itemId: selectedObject.itemId,
                  });
                  handleDeleteS3Object.mutate({
                    objectKey: selectedObject.s3ImageUrl[0].imageIdentifier,
                  });
                }}
              >
                {deleteMenu.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 text-sm font-semibold text-center text-blue-700 bg-white hover:bg-gray-50 border border-blue-700 rounded-md  w-full md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedObject({});
                  setDeleteItemModal(false);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="p-3">
        <h1
          className="text-xl mb-3 font-semibold flex items-center gap-3 cursor-pointer"
          onClick={() => {
            setSelectedObject({});
            // setStep(3);
            setActiveCategory("");
            setProductSidePanelStep(1)

          }}
        >
          {" "}
          <MdOutlineKeyboardBackspace /> <span>{activeCategory}</span>
        </h1>
        {allMenuByCategory.status === "loading" ? (
          <div className="flex items-center justify-start py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : allMenuByCategory.status === "error" ? (
          <p className="mt-8 font-semibold text-center text-gray-400">
            {allMenuByCategory.error.message}
          </p>
        ) : menusByCategory.length > 0 ? (
          <div>
            <div className="space-y-5">
              {menusByCategory.map((item, index) => {
                return (
                  <div className="flex items-center gap-4" key={item.itemId}>
                    <div className="h-[60px] w-[60px] min-w-[60px] bg-gray-100 rounded-md">
                        <img
                          src={item.s3ImageUrl[0].Image_Upload_Url}
                          alt="No image"
                          onError={(e) =>
                            (e.currentTarget.src =
                              "/media/image/productBg.png")
                          }
                          className="object-cover w-full h-full rounded"
                        />
                      </div>
                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-semibold truncate">{item.itemName.length > 18 ? item.itemName.substring(0, 18) + "..." : item.itemName}</p>
                        <div className="flex items-center gap-2 ml-auto">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <div>
                              <Menu.Button className="w-full text-blue-800 mt-1">
                                <IoMdMore />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 mt-1 w-36 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-10">
                                <div className="px-1 py-1 ">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={`${
                                          active
                                            ? "bg-slate-100 font-semibold"
                                            : "text-gray-900 font-medium"
                                        } w-full rounded-md px-4 py-2 text-sm text-left`}
                                        onClick={() => {
                                          setSelectedObject(item);
                                          setProductSidePanelStep(4);
                                        }}
                                      >
                                        Edit
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={`${
                                          active
                                            ? "bg-slate-100 font-semibold"
                                            : "text-gray-900 font-medium"
                                        } w-full rounded-md px-4 py-2 text-sm text-left`}
                                        onClick={() => {
                                          setSelectedObject(item);
                                          setDeleteItemModal(true);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                      <p className="text-xs">&#x20A6;{item.itemPrice}</p>
                      <p className="text-xs truncate">
                      {item.itemDescription.length > 25 ? item.itemDescription.substring(0, 25) + "..." : item.itemDescription}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <p className="mt-8 font-semibold text-center text-gray-400">
            No Item Available For This Category
          </p>
        )}

        <button
          type="button"
          className="text-sm w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4"
          onClick={() => {
            setStep(2);
            setProductSidePanelStep(2);
          }}
        >
          Add An Item
        </button>
      </div>
    </>
  );
};

export default ItemsInCategory;
