import { useLocation, useParams } from "react-router-dom";
import { classNames } from "../../../utils";
import Footer from "../Footer";
import Header from "../Header";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useIdleTimer } from "react-idle-timer";
import { useMutation } from "@tanstack/react-query";
import { sessionDetails } from "../../../api/Menu";

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

const Layout = ({ children }: Props) => {
  const { salt, slug, section } = useParams();
  const queryKey = `/${salt}/${slug}`;
  const [state, setState] = useState<string>("Active");
  const [count, setCount] = useState<number>(0);
  const [remaining, setRemaining] = useState<number>(0);
  const [clientIpAddress, setClientIpAddress] = useState("");
  

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const location = useLocation();

  const handleSessionDetails = useMutation({
    mutationFn: async (data: any) => {
      return await sessionDetails(data);
    },
    onSuccess: () => {
      // alert("Sent");
    },
    onError: (err) => {
      console.log(err);
    },
  });

  async function getclientIpAddress() {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    if (data.ip) {
      setClientIpAddress(data.ip);
      // console.log("Your IP Address:", data.ip);
    } else {
      console.error("Error fetching IP address");
    }
  }

  useEffect(() => {
    getclientIpAddress();
    if (
      !localStorage.getItem("cusecho-storeuser-sessionId") &&
      !localStorage.getItem("cusecho-storename") &&
      clientIpAddress !== ""
    ) {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;
      const deviceScreenWidth = window.innerWidth;
      // const sessionId = Math.random().toString(20).substring(2, 34);
      const sessionId = uuidv4();
      localStorage.setItem("cusecho-storeuser-sessionId", sessionId);
      localStorage.setItem("cusecho-storename", queryKey);
      const deviceID = `${userAgent}-${platform}-${sessionId}`;
      const obj = {
        sessionId,
        userAgent,
        platform,
        clientIpAddress,
        deviceScreenWidth,
        slugUrl: queryKey,
      };
      // console.log(obj);
      handleSessionDetails.mutate(obj);
    }
  }, [clientIpAddress]);

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 3600_000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);
    if (remaining === 1) {
      localStorage.removeItem("cusecho-storeuser-sessionId");
      localStorage.removeItem("cusecho-storename");
    }

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <Header />
      <main
        className={classNames(
          "h-full font-dm-sans",
          location.search.includes("reference") ? "" : "pt-[70px]"
        )}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
