import { useEffect, useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { MdMoreVert } from "react-icons/md";
import { AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai";
import { formatStoreName, getEmailTemplate } from "../../../../../utils";
import { getSingleMessage, sendMessage } from "../../../../../api/settings";
import { StoreModel, UserProfileModel } from "../../../../../models";
import { useAppSelector } from "../../../../../hooks";
import Modal from "../../../../../components/Dashboard/Modal";
import { BiChevronDown, BiChevronLeft } from "react-icons/bi";
import { TbMinusVertical } from "react-icons/tb";
import "react-datepicker/dist/react-datepicker.css";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import moment from "moment";

const array1 = [
  "Select All",
  "2348130609629",
  "2348130609629",
  "2348130609629",
  "2348130609629",
  "2348130609629",
  "2348130609629",
];

const csvArray = [
  "Best customer list.csv",
  "Worst customer list.csv",
  "Top 5 customers.csv",
  "Loyalty List.csv",
  "Worst customer list.csv",
  "Top 5 customers.csv",
  "Loyalty List.csv",
];

const NewMessage = () => {
  const { id } = useParams();
  const { recipientEmail } = useParams();
  const navigate = useNavigate();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeName = store.name;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [emailObject, setEmailObject] = useState({
    email: "",
    subject: "",
    bodyText: "",
  });
  const [showPopOver, setShowPopOver] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [time, setTime] = useState("");
  const [showSchedulePopup, setShowSchedulePopup] = useState(false);

  let footer = <p>Please pick a day.</p>;
  if (startDate) {
    footer = <p>You picked {moment(startDate).format("dd MMM, yyyy")}.</p>;
  }

  useEffect(() => {
    if (recipientEmail) {
      setEmailObject({ ...emailObject, email: recipientEmail });
    }
  }, []);

  const handleClientMessage = useMutation({
    mutationFn: async (data) => {
      const payload = {
        platformKey,
        recipientEmail: emailObject.email,
        storeName,
        subject: emailObject.subject,
        bodyText: emailObject.bodyText,
        bodyHtml: getEmailTemplate(
          emailObject.subject,
          emailObject.bodyText,
          formatStoreName(`${storeName}`),
        ),
      };

      return await sendMessage(payload);
    },
    onSuccess: () => {
      setShowSuccessModal(true);
      setEmailObject({
        ...emailObject,
        subject: "",
        bodyText: "",
      });
    },
  });

  const singleMessage = useQuery<any, Error>({
    queryKey: ["getSingleMessage"],
    queryFn: async () => {
      const response = await getSingleMessage(id);
      return response.data;
    },
    enabled: !!id,
    onSuccess: (data) => {
      const { recipientEmail, messageSubject } = data.Message.Item;
      setEmailObject({
        email: recipientEmail,
        subject: messageSubject,
        bodyText: "",
      });
    },
  });

  return (
    <>
      <Modal
        show={showSuccessModal}
        onToggle={() => setShowSuccessModal(false)}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img
              src="/media/svg/check.svg"
              alt="icon"
              className="mx-auto w-28"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-2xl">
              Message Successfully Sent!
            </h1>
          </div>
        </div>
      </Modal>
      <Modal
        show={showContactModal}
        onToggle={() => setShowContactModal(false)}
      // size="normal"
      >
        <div className="relative flex flex-col px-4 py-6">
          <h1 className="mb-5 text-xl font-semibold">Contacts</h1>
          <div className="space-y-2.5">
            {array1.map((s: string, index: number) => (
              <div className="flex items-center" key={index}>
                <input
                  checked
                  type="radio"
                  value=""
                  name="disabled-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                />
                <label className="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">
                  {s}
                </label>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center">
            <button className="px-6 py-2 w-[120px] text-sm text-white rounded font-semibold bg-blue-700 hover:bg-blue-800 mt-6">
              Select
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={showFileModal}
        onToggle={() => setShowFileModal(false)}
      // size="normal"
      >
        <div className="relative flex flex-col px-4 py-6">
          <h1 className="mb-5 text-xl font-semibold">CSV File</h1>
          <div className="space-y-2.5">
            {csvArray.map((s: string, index: number) => (
              <div className="flex items-center" key={index} >
                <input
                  checked
                  type="radio"
                  value=""
                  name="disabled-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                />
                <label className="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">
                  {s}
                </label>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center">
            <button className="px-6 py-2 w-[120px] text-sm text-white rounded font-semibold bg-blue-700 hover:bg-blue-800 mt-6">
              Import
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={showDateModal}
        onToggle={() => {
          setShowDateModal(false);
          setShowSchedulePopup(false);
        }}
      // size="normal"
      >
        <div className="px-4 py-6">
          <h1 className="text-xl font-semibold text-center">Set time</h1>
          <div className="flex flex-col">
            <div className="flex justify-center w-full max-w-full">
              <DayPicker mode="single" selected={startDate} onSelect={(date: any) => setStartDate(date)} footer={footer} showOutsideDays className="p-2 border-2 border-gray-300 rounded-md" />
            </div>
            <div className="flex justify-center w-full max-w-full" >
              <input
                type="time"
                name="appt-time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className="w-[300px] px-4 py-2 border rounded-md border-gray-300 outline-none"
              />
            </div>
            {/* <DatePicker selected={startDate} onChange={(date:any) => setStartDate(date)} inline /> */}
          </div>
          <div className="flex items-center justify-center mt-3">
            <button className="px-6 py-2 w-[220px] text-sm text-white rounded font-semibold bg-blue-700 hover:bg-blue-800 mt-6">
              Save
            </button>
          </div>
        </div>
      </Modal>
      <div className="space-y-1 h-[85vh] flex flex-col">
        <div className="flex items-center justify-between py-1">
          <h4 className="text-base font-semibold text-gray-800 capitalize">New Message</h4>
        </div>
        <div className="flex flex-col h-full py-4 space-y-2 bg-white rounded-md">
          <div className="flex items-center justify-between">
            <BiChevronLeft
              size={24}
              onClick={() => navigate(-1)}
              className="cursor-pointer"
            />
            <div className="flex items-center hidden">
              <MdMoreVert
                size={22}
              // onClick={() => {
              //   setShowFileModal(true);
              // }}
              />

            </div>
          </div>
          <form className="flex flex-col flex-1 h-full space-y-2">
            <div className="space-y-3">
              <div className="flex flex-col w-full mx-auto sm:gap-2 sm:items-center sm:flex-row">
                <label className="block text-xs font-normal text-gray-600 sm:w-1/6">
                  Enter Email
                </label>
                <div className="relative w-full">
                  <input
                    type="text"
                    name="email"
                    value={emailObject.email}
                    onChange={(e) =>
                      setEmailObject({
                        ...emailObject,
                        email: e.target.value,
                      })
                    }
                    className="w-full p-2 text-gray-900 border border-gray-300 rounded-lg outline-none sm:text-md focus:ring-blue-500 focus:border-blue-500"
                  />
                  <AiOutlinePlus
                    className="absolute inset-y-3.5 right-2 cursor-pointer z-10 hidden"
                    onClick={() => {
                      setShowContactModal(true);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col w-full mx-auto sm:gap-2 sm:items-center sm:flex-row">
                <label className="block text-xs font-normal text-gray-600 sm:w-1/6">
                  Enter Subject
                </label>
                <input
                  type="text"
                  name="subject"
                  value={emailObject.subject}
                  onChange={(e) =>
                    setEmailObject({
                      ...emailObject,
                      subject: e.target.value,
                    })
                  }
                  className="w-full p-2 text-gray-900 border border-gray-300 rounded-lg outline-none sm:text-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <textarea
              id="message"
              name="message_body"
              rows={5}
              minLength={100}
              maxLength={1000}
              value={emailObject.bodyText}
              onChange={(e) =>
                setEmailObject({ ...emailObject, bodyText: e.target.value })
              }
              className="flex-1 block w-full p-3 text-sm text-gray-900 bg-transparent border rounded-md outline-none focus:border-blue-500"
            ></textarea>

            <div className="flex items-center gap-2">
              <div className="relative">
                <div className="flex items-center gap-4">
                  <button
                    type="submit"
                    disabled={handleClientMessage.isLoading}
                    className="inline-flex items-center p-2 text-sm font-semibold text-white bg-blue-700 border rounded-md shadow-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      if (emailObject.bodyText === "" || emailObject.subject === "") {
                        alert("All fields are mandatory")
                        return;
                      }
                      if (
                        store.name === undefined ||
                        store.name === "N/A" ||
                        store.name === ""
                      ) {
                        setShowPopOver((prevState) => !prevState);
                        return;
                      }
                      handleClientMessage.mutate();
                    }}
                  >
                    {handleClientMessage.isLoading ? (
                      <ImSpinner2 size={28} className="mx-auto animate-spin" />
                    ) : (
                      "Send"
                    )}
                    {/* <span>Send</span> */}
                    <TbMinusVertical size={24} className="hidden mx-2" />{" "}
                    <BiChevronDown
                      className="hidden"
                      size={22}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowSchedulePopup((prev) => !prev);
                      }}
                    />
                  </button>

                  <div className="flex items-center hidden gap-3">
                    <svg
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        setShowFileModal(true);
                      }}
                    >
                      <path
                        d="M19.5 10.715L10.575 19.715C10.2287 20.0678 9.81555 20.3481 9.35968 20.5394C8.90382 20.7308 8.41439 20.8293 7.91999 20.8293C7.4256 20.8293 6.93617 20.7308 6.48031 20.5394C6.02444 20.3481 5.61129 20.0678 5.26499 19.715L2.59499 17C1.89603 16.29 1.50427 15.3337 1.50427 14.3375C1.50427 13.3412 1.89603 12.3849 2.59499 11.675L12.105 2.13495C12.3839 1.85377 12.7157 1.63059 13.0813 1.47828C13.4468 1.32597 13.839 1.24756 14.235 1.24756C14.631 1.24756 15.0231 1.32597 15.3887 1.47828C15.7543 1.63059 16.0861 1.85377 16.365 2.13495L17.43 3.19995C17.7112 3.47884 17.9344 3.81065 18.0867 4.17622C18.239 4.5418 18.3174 4.93392 18.3174 5.32995C18.3174 5.72599 18.239 6.11811 18.0867 6.48368C17.9344 6.84926 17.7112 7.18106 17.43 7.45995L8.99999 15.92C8.86055 16.0605 8.69465 16.1721 8.51186 16.2483C8.32907 16.3244 8.13301 16.3637 7.93499 16.3637C7.73698 16.3637 7.54092 16.3244 7.35813 16.2483C7.17534 16.1721 7.00944 16.0605 6.86999 15.92L6.34499 15.38C6.2044 15.2405 6.09281 15.0746 6.01666 14.8918C5.9405 14.709 5.9013 14.513 5.9013 14.315C5.9013 14.1169 5.9405 13.9209 6.01666 13.7381C6.09281 13.5553 6.2044 13.3894 6.34499 13.25L12 7.63995"
                        stroke="black"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.26501 20.75L18.765 20.75C19.5934 20.75 20.265 20.0784 20.265 19.25L20.265 2.75C20.265 1.92157 19.5934 1.25 18.765 1.25L2.26501 1.25C1.43659 1.25 0.765015 1.92157 0.765015 2.75L0.765015 19.25C0.765015 20.0784 1.43659 20.75 2.26501 20.75Z"
                        stroke="black"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.875 10.25C15.3247 10.25 16.5 9.07475 16.5 7.625C16.5 6.17525 15.3247 5 13.875 5C12.4253 5 11.25 6.17525 11.25 7.625C11.25 9.07475 12.4253 10.25 13.875 10.25Z"
                        stroke="black"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.1 20.7499C13.617 18.1774 12.2357 15.8602 10.2026 14.2117C8.16948 12.5632 5.61679 11.6907 3 11.7499C2.24501 11.7479 1.49157 11.8182 0.75 11.9599"
                        stroke="black"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20.2501 15.365C19.0419 14.957 17.7752 14.7493 16.5001 14.75C14.886 14.7465 13.2882 15.0732 11.8051 15.71"
                        stroke="black"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                {showPopOver && (
                  <div className="bg-white w-[350px] absolute z-100 bottom-12 left-4 rounded-lg shadow-lg p-4">
                    <div className="flex gap-3 mb-4">
                      <AiOutlineInfoCircle size={30} />
                      <p className="text-sm font-medium text-gray-800">
                        You need to add a store name before you can send a
                        message
                      </p>
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        onClick={() => navigate("/settings/profile")}
                        className="px-5 py-2 text-sm text-white bg-blue-700 rounded-md hover:bg-blue-800"
                      >
                        Add Store Name
                      </button>
                    </div>
                  </div>
                )}
                {showSchedulePopup && (
                  <div
                    className="absolute z-100 top-8 left-8 rounded-lg shadow-lg p-2.5 bg-white text-gray-800 w-[130px] cursor-pointer"
                    onClick={() => {
                      setShowSchedulePopup(false);
                      setShowDateModal(true);
                    }}
                  >
                    Schedule send
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewMessage;
