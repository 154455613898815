import axios from "axios";
import { API_BASE_URL } from "../../utils";

const UTILS_URL = API_BASE_URL;

export const getCustomerSatisfactionScore = async (data: any) =>
  axios.post(`${UTILS_URL}/satisfaction_score`, data);
export const getQuestionFeedbackChart = async (data: any) =>
  axios.get(`${UTILS_URL}/quiz_feedback_chart?queryKey=${data}`);
export const refreshSatisfactionScore = async (
  queryKey: any,
  refreshButton: any
) =>
  axios.post(
    `${UTILS_URL}/satisfaction_score_refresh?queryKey=${queryKey}&refreshButton=${refreshButton}`
  );
export const getRealtimePerceptionScores = async (data: any) =>
  axios.post(`${UTILS_URL}/bpt_realtime_request`, data);
export const filterRealtimePerceptionScores = async (data: any) =>
  axios.post(`${UTILS_URL}/bpt_filter_request`, data);
export const uploadWebUrl = async (data: any) =>
  axios.post(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/upload_web_url`,
    data
  );
export const downloadWebUrl = async (platformKey: any) =>
  axios.get(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/download_web_url?platformKey=${platformKey}`
  );

export const downloadNPSResponses = async (platformKey: any) =>
  axios.get(
    `https://hs3gurese8.execute-api.eu-west-2.amazonaws.com/Prod/cusecho_sbrp/feedback_service/api/v1/load_nps_responses?platformKey=${platformKey}`
  );

export const placeProductOrder = async (data: any) =>
  axios.post(
    `https://0n8rvglrkd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/send_product_order`,
    data
  );
export const retrieveOrder = async (data: any) =>
  axios.post(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/retrieve_order`,
    data
  );
export const updateOrder = async (data: any) =>
  axios.post(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/update_order`,
    data
  );
export const cancelOrder = async (data: any) =>
  axios.post(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/cancel_order`,
    data
  );
export const uploadComplaint = async (data: any) =>
  axios.post(
    `https://0n8rvglrkd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/upload_web_complaint`,
    data
  );
export const updateReviewStatus = async (data: any) =>
  axios.post(
    `https://t2kqqkr425.execute-api.eu-west-2.amazonaws.com/dev/v1/update_review_status`,
    data
  );
export const getPublicFeedBacks = async (platformKey: any) =>
  axios.get(
    `https://0n8rvglrkd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/load_public_feedbacks?platformKey=${platformKey}`
  );
export const getSevenDayAverage = async (platformKey: any) =>
  axios.get(
    `https://t2kqqkr425.execute-api.eu-west-2.amazonaws.com/dev/v1/seven_day_ratings_average?platformKey=${platformKey}`
  );
export const getPublicServices = async (slugUrl: string) =>
  axios.get(
    `https://0n8rvglrkd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/service_download?slugUrl=${slugUrl}`
  );
export const getAllPublicProducts = async (slugUrl: string, pageKey: string) =>
  axios.get(
    `https://0n8rvglrkd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/store_item_list_download?slugUrl=${slugUrl}&pageKey=${pageKey}`
  );
export const cancelOrderStatus = async (data: any) =>
  axios.post(
    `https://0n8rvglrkd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/cancel_order_status`,
    data
  );
export const getOrderDetails = async (orderID: string, email: string) =>
  axios.get(
    `https://0n8rvglrkd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/load_order_payload?orderId=${orderID}&emailAddress=${email}`
  );
export const initiatePayment = async (data: any) =>
  axios.post(
    `https://7tmn09vm4i.execute-api.us-east-1.amazonaws.com/Prod/api/v1/payments/accept_payment`,
    data
  );
export const confirmPayment = async (referenceId: string, paymentId: string) =>
  axios.post(
    `https://7tmn09vm4i.execute-api.us-east-1.amazonaws.com/Prod/api/v1/payments/confirm_payment?reference=${referenceId}&paymentId=${paymentId}`,
    null
  );
