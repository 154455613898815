// @ts-nocheck

import React, { useState, Fragment } from "react";
import { BiChevronDown } from "react-icons/bi";
import { FiMoreVertical } from "react-icons/fi";
import { classNames } from "../../../../../../../utils";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import {
  addStoreAddress,
  getOpeningHours,
  getStoreAddress,
  getStoreFooterDetails,
  updateOpeningHour,
  uploadStoreFooterDetails,
} from "../../../../../../../api/Menu";
import InputMessage from "../../../../../../../components/Auth/Forms/InputMessage";
import { Input } from "../../EchoWebpage1";
import { ImSpinner2 } from "react-icons/im";
import { IoChevronBack } from "react-icons/io5";
import { Menu, Transition } from "@headlessui/react";
import { IoMdMore } from "react-icons/io";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const Socials = ["LinkedIn", "Twitter", "Facebook", "Phone Number", "Email", "Instagram", "TikTok"];

const addressValues = {
  address: "",
};

const footerInitialValues = {
  phoneNumber: "",
  email: "",
  linkedIn_url: "",
  facebook_url: "",
  twitter_url: "",
  instagram_url: "",
  tiktok_url: "",
};

const SocialsSidePanel = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [step, setStep] = useState(1);
  const [selectedSocial, setSelectedSocial] = useState("");
  let allOpeningHours = [];
  const [eventType, setEventType] = useState(-1);
  let storeAddress: string = "";
  const [selectedObject, setSelectedObject] = useState({});
  const [openingDaysFields, setOpeningDaysFields] = useState({
    start_time: "",
    close_time: "",
    switch_button: "0",
  });

  let storeFooterDetails: any = {
    Contact_Phone: "",
    Contact_Email: "",
    Linkedin_Url: "",
    Facebook_Url: "",
    Twitter_Url: "",
    Instagram_Url: "",
  };

  const formik5 = useFormik({
    initialValues: footerInitialValues,
    // validationSchema: footerValidationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const payload = {
        platformKey,
        contact_number: values.phoneNumber,
        contact_email: values.email,
        linkedin_url: values.linkedIn_url,
        facebook_url: values.facebook_url,
        twitter_url: values.twitter_url,
        instagram_url: values.instagram_url,
      };

      try {
        const response = await uploadStoreFooterDetails(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries({
            queryKey: ["getStoreSocials"],
          });
          resetForm({});
          setSubmitting(false);
          setStep(1);
          return;
        }
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  const formik3 = useFormik({
    initialValues: addressValues,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (values.service === "") {
        return;
      }
      setSubmitting(true);
      const payload = {
        platformKey,
        store_address: values.address,
      };

      try {
        const response = await addStoreAddress(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries("getStoreAddress");
          resetForm({});
          setSubmitting(false);
          setStep(1);
          return;
        }
        setError(response.data.message);
      } catch (err) {
        console.log({ err });
        // setError(err.message);
      }
      setSubmitting(false);
    },
  });

  const address = useQuery<any, Error>({
    queryKey: ["getStoreAddress"],
    queryFn: async () => {
      const response = await getStoreAddress(storeKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data)
      formik3.setFieldValue("address", data.Store_Address)
    },
  });

  const allStoreFooterDetails = useQuery<any, Error>({
    queryKey: ["getStoreSocials"],
    queryFn: async () => {
      const response = await getStoreFooterDetails(storeKey);
      return response.data;
    },
  });

  if (allStoreFooterDetails.data) {
    storeFooterDetails = allStoreFooterDetails.data;
  }

  const openingHours = useQuery<any, Error>({
    queryKey: ["getOpeningHours"],
    queryFn: async () => {
      const response = await getOpeningHours(storeKey);
      return response.data;
    },
  });

  const handleUpdateOpeningHour = useMutation({
    mutationFn: async (data) => {
      const payload = {
        platformKey,
        openingDay: data.openingDay,
        eventPayload: {
          close_time: data.close_time,
          start_time: data.start_time,
          switch_button: data.switch_button,
        },
        eventType: data.eventType,
        switchButton: data.switchButton,
      };
      return await updateOpeningHour(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getOpeningHours");
      setStep(1);
      setSelectedObject({});
      setEventType(-1);
    },
  });

  if (openingHours.data) {
    const opening = openingHours.data.Opening_Hours;
    // console.log(opening)
    if(Array.isArray(opening)){
      const result = openingHours.data.Opening_Hours.map((x) => {
        const day = Object.keys(x)[0];
        const dayObj = x[day][0];
        return { ...dayObj, day };
      });
      allOpeningHours = result;
    }
  }

  if (address.data) {
    storeAddress = address.data.Store_Address;
  }

  return (
    <>
      {step === 1 ? (
        <div className="p-3 overflow-y-auto">
          <p className="text-[20px] font-semibold mb-3">Footer</p>
          <div className="space-y-3">
            <div className="flex items-center justify-between mb-3">
              <p className="text-gray-900 text-[15px] font-semibold">Contact</p>
              <BiChevronDown size={22} className="hidden" />
            </div>
            <div className="space-y-3">
              <div
                className={classNames("flex justify-between items-center")}
              >
                <p className="text-gray-900 font-semibold text-xs">Address</p>
                <FiMoreVertical
                  size={20}
                  className="cursor-pointer"
                  onClick={() => {
                    setStep(4);
                    formik3.setFieldValue(
                      "address",
                      storeAddress
                    );
                  }}
                />
              </div>
              {Socials.map((social, index: number) => (
                <div
                  className={classNames("flex justify-between items-center")}
                  key={social}
                >
                  <p className="text-gray-900 font-semibold text-xs">{social}</p>
                  <FiMoreVertical
                    size={20}
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedSocial(social);
                      setStep(2);
                      formik5.setValues({
                        phoneNumber: storeFooterDetails.Contact_Phone,
                        email: storeFooterDetails.Contact_Email,
                        linkedIn_url: storeFooterDetails.Linkedin_Url,
                        facebook_url: storeFooterDetails.Facebook_Url,
                        twitter_url: storeFooterDetails.Twitter_Url,
                        instagram_url: storeFooterDetails.Instagram_Url,
                        tiktok_url: storeFooterDetails.TikTok_Url ?? ""
                      });
                    }}
                  />
                </div>
              ))}
            </div>
            <div>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <p className="text-gray-900 text-[15px] font-semibold">Operating Hours</p>
                  <BiChevronDown
                    size={20}
                    className="hidden"
                  />
                </div>
                <div className="space-y-2">
                  {allOpeningHours?.map((day, index: number) => (
                    <div
                      className={classNames("flex justify-between items-center")}
                      key={index}
                    >
                      <p
                        className={classNames(
                          "text-gray-900 font-semibold text-xs",
                          day.switch_button === "0" ? "opacity-60" : ""
                        )}
                      >
                        {day.day}
                      </p>
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="w-full text-gray-900">
                            <FiMoreVertical size={20} />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-1 w-28 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-20">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${active
                                        ? "bg-slate-100 font-semibold"
                                        : "text-gray-900 font-medium"
                                      } w-full rounded-md px-4 py-2 text-xs text-left`}
                                    onClick={() => {
                                      setSelectedObject(day);
                                      setOpeningDaysFields({
                                        start_time: day.start_time,
                                        close_time: day.close_time,
                                      });
                                      setStep(3);
                                    }}
                                  >
                                    Edit
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${active
                                        ? "bg-slate-100 font-semibold"
                                        : "text-gray-900 font-medium"
                                      } w-full rounded-md px-4 py-2 text-xs text-left`}
                                    onClick={() => {
                                      handleUpdateOpeningHour.mutate({
                                        openingDay: day.day,
                                        close_time: day.close_time,
                                        start_time: day.start_time,
                                        switch_button:
                                          day.switch_button === "0" ? "1" : "0",
                                        eventType: "1",
                                        switchButton:
                                          day.switch_button === "0" ? "1" : "0",
                                      });
                                    }}
                                  >
                                    {day.switch_button === "0"
                                      ? "Turn on"
                                      : "Turn off"}
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : step === 2 ? (
        <div className="p-3">
          <div className="space-y-3">
            <div className="flex items-center gap-4">
              <IoChevronBack
                className="cursor-pointer"
                size={22}
                onClick={() => {
                  setStep(1);
                  setSelectedSocial("");
                }}
              />
              <p className="text-sm text-gray-800 font-semibold">{selectedSocial}</p>
            </div>
            <div className="space-y-3">
              <form onSubmit={formik5.handleSubmit} className="">
                <div className="space-y-3">
                  <div
                    className={classNames(
                      "w-full",
                      selectedSocial !== "Phone Number" ? "hidden" : ""
                    )}
                  >
                    <Input
                      formik={formik5}
                      name="phoneNumber"
                      label="Phone"
                      type="text"
                      placeHolder="+XXXXXXXXXXX"
                    />
                    {formik5.values.phoneNumber &&
                      formik5.errors.phoneNumber && (
                        <InputMessage message={formik5.errors.phoneNumber} />
                      )}
                  </div>
                  <div
                    className={classNames(
                      "w-full",
                      selectedSocial !== "Email" ? "hidden" : ""
                    )}
                  >
                    <Input
                      formik={formik5}
                      name="email"
                      label="Email"
                      type="email"
                      placeHolder=""
                    />
                    {formik5.values.email && formik5.errors.email && (
                      <InputMessage message={formik5.errors.email} />
                    )}
                  </div>
                  <div
                    className={classNames(
                      "w-full",
                      selectedSocial !== "LinkedIn" ? "hidden" : ""
                    )}
                  >
                    <Input
                      formik={formik5}
                      name="linkedIn_url"
                      label="LinkedIn URL"
                      type="text"
                      placeHolder=""
                    />
                    {formik5.values.linkedIn_url &&
                      formik5.errors.linkedIn_url && (
                        <InputMessage message={formik5.errors.linkedIn_url} />
                      )}
                  </div>
                  <div
                    className={classNames(
                      "w-full",
                      selectedSocial !== "Facebook" ? "hidden" : ""
                    )}
                  >
                    <Input
                      formik={formik5}
                      name="facebook_url"
                      label="Facebook URL"
                      type="text"
                      placeHolder=""
                    />
                    {formik5.values.facebook_url &&
                      formik5.errors.facebook_url && (
                        <InputMessage message={formik5.errors.facebook_url} />
                      )}
                  </div>
                  <div
                    className={classNames(
                      "w-full",
                      selectedSocial !== "Twitter" ? "hidden" : ""
                    )}
                  >
                    <Input
                      formik={formik5}
                      name="twitter_url"
                      label="Twitter URL"
                      type="text"
                      placeHolder=""
                    />
                    {formik5.values.twitter_url &&
                      formik5.errors.twitter_url && (
                        <InputMessage message={formik5.errors.twitter_url} />
                      )}
                  </div>
                  <div
                    className={classNames(
                      "w-full",
                      selectedSocial !== "Instagram" ? "hidden" : ""
                    )}
                  >
                    <Input
                      formik={formik5}
                      name="instagram_url"
                      label="Instagram URL"
                      type="text"
                      placeHolder=""
                    />
                    {formik5.values.instagram_url &&
                      formik5.errors.instagram_url && (
                        <InputMessage message={formik5.errors.instagram_url} />
                      )}
                  </div>
                  <div
                    className={classNames(
                      "w-full",
                      selectedSocial !== "TikTok" ? "hidden" : ""
                    )}
                  >
                    <Input
                      formik={formik5}
                      name="tiktok_url"
                      label="TikTok URL"
                      type="text"
                      placeHolder=""
                    />
                    {formik5.values.tiktok_url &&
                      formik5.errors.tiktok_url && (
                        <InputMessage message={formik5.errors.tiktok_url} />
                      )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4"
                >
                  {formik5.isSubmitting ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Save"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : step === 3 ? (
        <div className="p-3">
          <h1
            className="text-lg text-gray-800 mb-3 font-semibold flex items-center gap-4 cursor-pointer"
            onClick={() => setStep(1)}
          >
            {" "}
            <MdOutlineKeyboardBackspace /> <span>{selectedObject.day}</span>
          </h1>
          <div className="flex items-center gap-4 justify-between">
            <input
              type="startTime"
              placeholder=""
              className="p-2 text-xs border rounded-md outline-none w-1/2"
              value={openingDaysFields.start_time}
              onChange={(e) =>
                setOpeningDaysFields({
                  ...openingDaysFields,
                  start_time: e.target.value,
                })
              }
            />
            <input
              type="closeTime"
              placeholder=""
              className="p-2 text-xs border rounded-md outline-none w-1/2"
              value={openingDaysFields.close_time}
              onChange={(e) =>
                setOpeningDaysFields({
                  ...openingDaysFields,
                  close_time: e.target.value,
                })
              }
            />
            <div className="flex items-center gap-2 ml-auto hidden">
              {handleUpdateOpeningHour.isLoading && eventType === 1 ? (
                <ImSpinner2 size={18} className="mr-1 animate-spin" />
              ) : null}
              <label className="relative inline-flex items-center cursor-pointer ">
                <input
                  type="checkbox"
                  checked={Number(selectedObject.switch_button)}
                  className="sr-only peer"
                  onChange={() => {
                    setEventType(1);
                    handleUpdateOpeningHour.mutate({
                      openingDay: selectedObject.day,
                      close_time: selectedObject.close_time,
                      start_time: selectedObject.start_time,
                      switch_button: selectedObject.switch_button,
                      eventType: "1",
                      switchButton:
                        selectedObject.switch_button === "0" ? "1" : "0",
                    });
                  }}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
          <button
            type="submit"
            onClick={() => {
              setEventType(0);
              handleUpdateOpeningHour.mutate({
                openingDay: selectedObject.day,
                close_time: openingDaysFields.close_time,
                start_time: openingDaysFields.start_time,
                switch_button: selectedObject.switch_button,
                eventType: "0",
                switchButton: selectedObject.switch_button === "0" ? "1" : "0",
              });
            }}
            className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4"
          >
            {handleUpdateOpeningHour.isLoading && eventType === 0 ? (
              <ImSpinner2 size={20} className="mx-auto animate-spin" />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      ) : (
        <div className="p-3">
          <h1
            className="text-lg mb-1 font-semibold flex items-center gap-4 cursor-pointer"
            onClick={() => setStep(1)}
          >
            <MdOutlineKeyboardBackspace /> <span>Address</span>
          </h1>
          <form onSubmit={formik3.handleSubmit} className="">
            <div className="w-full">
              <Input
                formik={formik3}
                name="address"
                placeholder="Address"
                type="text"
              />
            </div>
            <button
              type="submit"
              className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4"
            >
              {formik3.isSubmitting ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Save"
              )}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default SocialsSidePanel;
