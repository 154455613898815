import React from 'react'
import DeliveryMethods from '../EchoWebpage/components/ProductsSection/DeliveryMethods'

const DeliveryPage = () => {
  return (
    <div className='w-full rounded-md h-[85vh] bg-white p-4 overflow-y-auto'>
     <DeliveryMethods />
    </div>
  )
}

export default DeliveryPage