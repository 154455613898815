// @ts-nocheck

import React, { useState, useEffect, useRef } from "react";
import { Input } from "../../EchoWebpage1";
import { useFormik } from "formik";
import { ImSpinner2 } from "react-icons/im";
import { MdDelete, MdOutlineKeyboardBackspace } from "react-icons/md";
import InputMessage from "../../../../../../../components/Auth/Forms/InputMessage";
import * as Yup from "yup";
import { useAppSelector } from "../../../../../../../hooks";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BASE_URL_WEBPAGE_IMAGE,
  getMenuByCategory,
  uploadSingleMenu,
} from "../../../../../../../api/Menu";

import Compressor from "compressorjs";
import AddCategory from "./AddCategory";
import Modal from "../../../../../../../components/Dashboard/Modal";
import { getImage } from "../../../../../../../utils";
import { Link } from "react-router-dom";

const initialValues = {
  itemName: "",
  details: "",
  price: "",
  category: "",
};

const itemSchema = Yup.object().shape({
  itemName: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Item name is required"),
  details: Yup.string()
    .min(5, "Minimum 5 letters")
    .max(140, "Maximum 140 letters"),
  price: Yup.string().required("Price is required"),
  category: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(30, "Maximum 30 letters")
    .required("Category is required"),
});

const AddItem = ({
  setProductSidePanelStep,
  setStep,
  step,
  activeCategory,
}) => {
  const [success, setSuccess] = useState(false);
  const [s3ImageUrl, setS3ImageUrl] = useState("");
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const queryKey = user.queryKey;
  const storeKey = store.name;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [savedStep, setSavedStep] = useState(1);
  const inputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  let itemsInCategory: number = 0;
  let menusByCategory: any[] = [];

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: itemSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      setSubmitting(true);
      if (itemsInCategory > 7) {
        setShowModal(true);
        return;
      }

      const category = activeCategory ? activeCategory : values.category;
      const itemImagePath =
        "/cusecho" +
        storeKey +
        "/products/" +
        category +
        "/no-image/item" +
        (itemsInCategory + 1) +
        "/" +
        Date.now();

      const payload = {
        platformKey,
        slugUrl: storeKey,
        itemName: values.itemName.trim(),
        menuItemId: "None",
        s3ImageUrl: s3ImageUrl === "" ? itemImagePath : s3ImageUrl,
        itemPrice: values.price.toString(),
        description: values.details.trim(),
        itemCategory: activeCategory ? activeCategory : values.category,
        itemPriceDigit: values.price.toFixed(2),
        uploadTimeStamp: Date.now().toFixed(2),
        uploadTime: "None",
        itemUrl: "None",
        uploadFlag: "create",
      };

      try {
        const response = await uploadSingleMenu(payload);
        if (response.data.status_code === 200) {
          !activeCategory &&
            queryClient.invalidateQueries("getAllMenuCategories");
          activeCategory && queryClient.invalidateQueries("getMenuByCategory");
          activeCategory && queryClient.invalidateQueries("getStoreItems");
          setPreview(null);
          setSuccess(true);
          resetForm({});
          setS3ImageUrl("");
          activeCategory && setFieldValue("category", activeCategory);
          if (!activeCategory) {
            setShowModals(null);
          }
          setTimeout(() => {
            setSuccess(false);
          }, 2000)
          setSubmitting(false);
          return;
        }
        setError(response.data.message);
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  const imageHandler = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(null);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const handleCompressedUpload = async (file: any) => {
    setIsLoading(true);
    if (selectedImage?.size > 2072576) {
      alert("Please select an image with a size of less than or equal to 2MB");
      setSelectedImage(null);
      return;
    }
    new Compressor(file, {
      quality: 0.2,
      success: (compressedResult: any) => {
        let path: string;
        const itemImagePath =
          "/cusecho" +
          storeKey +
          "/products/" +
          formik.values.category +
          "/item" +
          (itemsInCategory + 1) +
          "/" +
          Date.now();

        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };

        path = itemImagePath;

        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then(() => {
                setIsLoading(false);
                setImageUploadSuccess(true);
                inputRef.current.value = null;
                setSelectedImage(null);
                setPreview(null);
                setTimeout(() => {
                  setImageUploadSuccess(false);
                }, 2000);
                setS3ImageUrl(`${path}`);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };

  const uploadImage = useMutation({
    mutationFn: async (data: File) => {
      return handleCompressedUpload(data);
    },
  });

  const allMenuByCategory = useQuery<any, Error>({
    queryKey: ["getMenuByCategory", activeCategory],
    queryFn: async () => {
      const response = await getMenuByCategory(storeKey, activeCategory);
      return response.data;
    },
    enabled: !!activeCategory,
  });

  if (allMenuByCategory.data) {
    menusByCategory = allMenuByCategory.data.Menu_Response;
    itemsInCategory = menusByCategory.length;
  }

  return (
    <>
      <Modal
        size="medium"
        show={showModal}
        onToggle={() => {
          setShowModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 ">
          <div className="text-center">
            <img
              src={getImage("connection-error")}
              alt="icon"
              className="w-24 mx-auto"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-xl">
              Maximum of 25 products allowed.
            </h1>
            <p>
              To increase this limit, contact our <Link to="/support/limit-increase" className="text-red-600 hover:text-red-800" >support team.</Link>
            </p>
          </div>
        </div>
      </Modal>
      <div>
        {step === 1 ? (
          <AddCategory
            formik={formik}
            setProductSidePanelStep={setProductSidePanelStep}
            setStep={setStep}
          />
        ) : (
          <div>
            <h1
              className="text-xl text-gray-800 mb-3 font-semibold flex items-center gap-4 cursor-pointer"
              onClick={() => {
                if (activeCategory === null || activeCategory === "") {
                  setStep(1);
                  setProductSidePanelStep(2);
                  return;
                }
                setProductSidePanelStep(3);
              }}
            >
              {" "}
              <MdOutlineKeyboardBackspace />{" "}
              <span>{activeCategory === "" ? "Item" : activeCategory}</span>
            </h1>

            <div className="space-y-2 mb-2">
              <p>Type of product</p>
              <div className="flex items-center">
                <input
                  checked="checked"
                  type="radio"
                  value=""
                  name="physical"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                />
                <label className="ml-2 text-sm font-medium text-gray-900 ">
                  Physical
                </label>
              </div>
              <div className="flex items-center">
                <input
                  disabled
                  type="radio"
                  value=""
                  name="digital"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                />
                <label className="ml-2 text-sm font-medium text-gray-900 ">
                  Digital
                </label>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} className="">
              <div className="space-y-4">
                <div className="w-full">
                  <Input
                    formik={formik}
                    name="itemName"
                    label="Item Name"
                    type="text"
                    placeholder="Please Enter Item Name"
                  />
                  {formik.values.itemName && formik.errors.itemName && (
                    <InputMessage message={formik.errors.itemName} />
                  )}
                </div>
                <div className="w-full">
                  <label className="block mb-1 text-xs font-normal text-gray-800">
                    Details</label>
                  <textarea id="" name="details" value={formik.values.details} onChange={formik.handleChange} className="h-[100px] text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm" placeHolder="Please Enter details of item"></textarea>

                  {formik.values.details && formik.errors.details && (
                    <InputMessage message={formik.errors.details} />
                  )}
                  {formik.values.details && formik.errors.details && (
                    <InputMessage message={formik.errors.details} />
                  )}
                </div>
                <div className="w-full">
                  <Input
                    formik={formik}
                    name="price"
                    label="Price"
                    type="number"
                    placeHolder="Please Enter Price"
                  />
                  {formik.values.price && formik.errors.price && (
                    <InputMessage message={formik.errors.price} />
                  )}
                </div>
                <div className="my-3 space-y-2.5">
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="formFile"
                        className="mb-0.5 inline-block text-gray-900 text-sm font-semibold"
                      >
                        Upload item image
                      </label>
                      <MdDelete
                        className="cursor-pointer text-red-500"
                        onClick={() => {
                          setSelectedImage(null);
                          setPreview(null);
                          inputRef.current.value = null;
                          setS3ImageUrl("");
                          setImageUploadSuccess(false);
                        }}
                      />
                    </div>
                    <input
                      ref={inputRef}
                      className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-medium text-gray-900 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none "
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      onChange={imageHandler}
                    />
                  </div>
                  <button
                    type="button"
                    className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
                    onClick={() => {
                      if (!selectedImage) {
                        alert("Select an image");
                        return;
                      }
                      uploadImage.mutate(selectedImage);
                    }}
                  >
                    {uploadImage.isLoading || isLoading ? (
                      <ImSpinner2 size={20} className="mx-auto animate-spin" />
                    ) : (
                      "Upload"
                    )}
                  </button>
                  {imageUploadSuccess && (
                    <p className="mt-2 text-sm text-center text-green-500">
                      Image upload was successful
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center mt-10">
                <button
                  type="submit"
                  // disabled={formik.isSubmitting}
                  className="text-sm w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
                >
                  {formik.isSubmitting ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Save Item"
                  )}
                </button>
              </div>
              <p className="mt-1 text-center text-green-500 capitalize">
                {success && <small>Saved successfully!</small>}
              </p>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default AddItem;
