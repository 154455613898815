import { RiFileDownloadLine } from "react-icons/ri";
import { useAppSelector } from "../../../hooks";
import { CartProps } from "../../../redux/Utils";

const WebStorePaymentCallbackPage = () => {
  const cart: CartProps = useAppSelector((state) => state.utils.cart);
  const getCart = () => Object.values(cart || {});

  // console.log(getCart());

  return (
    <div className="font-dm-sans">
      <div className="w-full sm:w-[95%] lg:w-[65%] mx-auto space-y-10 hidden">
        <div className="relative flex flex-col p-2 sm:p-4 md:py-12">
          <h2 className="text-base font-medium text-center text-black uppercase md:text-lg">
            Check out
          </h2>
          <div className="flex items-start justify-between md:items-end">
            <div className="mt-5 md:mt-10 flex items-start space-x-2 md:space-x-2.5">
              <img
                src="/media/svg/check.svg"
                alt="icon"
                className="w-7 h-7 -mt-0.5 md:-mt-1 md:w-10 md:h-10"
              />
              <div className="space-y-1">
                <h1 className="text-lg font-medium sm:text-xl md:text-2xl">
                  Order received - ID {`(#123ADVFS)`}
                </h1>
                {/* <h1 className="text-xl font-medium md:text-2xl">Order received</h1> */}
                <h6 className="text-sm font-normal md:text-base">
                  Thank you. Your order has been received.
                </h6>
              </div>
            </div>
            <button
              // onClick={generateInvoice}
              className="bg-gray-300 text-black font-semibold text-sm xl:text-base rounded-md p-2 flex items-center space-x-1.5 text-center"
            >
              <RiFileDownloadLine size={18} />
              <span className="hidden md:inline-block">Download invoice</span>
            </button>
          </div>
          <div className="w-full mt-5 space-y-1.5 md:mt-10">
            <h4 className="mb-2.5 text-sm md:text-base font-semibold text-gray-800 uppercase">
              Order summary
            </h4>
            <p className="text-xs font-medium text-gray-800 md:text-sm">
              Order ID - (#123ADVFS)
            </p>
            <div className="flex items-end justify-between text-xs font-medium text-gray-800 md:text-sm">
              <h6 className="">Sub-total</h6>
              <h6 className="">&#8358; 13,000</h6>
            </div>
            <div className="flex items-end justify-between text-xs font-medium text-gray-800 md:text-sm">
              <h6 className=""></h6>
              <h6 className="">&#8358; 20,000</h6>
            </div>
            <div className="w-[98%] mx-auto border-t my-2 border-gray-500"></div>
            <div className="flex items-end justify-between text-xs font-medium text-gray-800 md:text-sm">
              <h6 className="text-sm font-semibold uppercase md:text-base">
                Total
              </h6>
              <h6 className="text-base font-semibold">&#8358;12,000</h6>
            </div>
          </div>

          <div className="w-full mt-4 space-y-1.5 md:mt-8">
            <h4 className="mb-2.5 text-base font-semibold text-gray-800 uppercase">
              Delivery summary
            </h4>
            <div className="flex items-start space-x-2 text-sm font-medium text-gray-800">
              <h6 className="font-semibold">Name:</h6>
              <h6 className="">Joshua Zino</h6>
            </div>
            <div className="flex items-start space-x-2 text-sm font-medium text-gray-800">
              <h6 className="font-semibold">Address:</h6>
              <h6 className="">9, Essumei street</h6>
            </div>
            <div className="flex items-start space-x-2 text-sm font-medium text-gray-800">
              <h6 className="font-semibold">Phone:</h6>
              <h6 className="">+233481305050</h6>
            </div>
          </div>
          <div className="w-full mt-4 space-y-1.5 md:mt-8">
            <h4 className="mb-2.5 text-sm md:text-base font-semibold text-gray-800 uppercase">
              Payment option
            </h4>
            <div className="space-y-1 text-sm font-medium text-gray-800">
              <h6 className="font-semibold">Direct bank transfer</h6>
              <p className="w-full text-xs md:text-sm sm:w-[90%]">
                Make your payment directly into any of our bank account. Please
                use your order ID as the payment reference. Your order will not
                be shipped until funds have cleared in our account.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-12">
      <div className="border-2 border-gray-300 rounded max-w-[600px] mx-auto text-gray-800">
        <div className="py-3 px-4 bg-slate-100 flex items-center justify-between gap-4 font-medium">
          <p>Order I.D: #12QW34R</p>
          <p>Order date: 12/02/2021</p>
        </div>
        <div className="p-4">
          <p className="font-semibold text-xl text-gray-900">
            Payment information
          </p>
          <div className="border mt-3 border-gray-300 rounded p-3">
            <p className="font-medium mb-2">
              <span className="font-semibold">Payment method:</span> Bank
              transfer
            </p>
            <div>
              <p className="font-semibold">Bank details:</p>
              <div className="grid grid-cols-2 gap-4 mt-2.5">
                <div className="rounded bg-gray-300 p-3 h-[150px]"></div>
                <div className="rounded bg-gray-300 p-3 h-[150px]"></div>
              </div>
              <p className="text-sm font-medium text-gray-800 mt-2">
                Use the order ID as the payment reference. Your order will not
                be shipped until funds have cleared in our account
              </p>
            </div>
          </div>
        </div>
        <div className="p-3">
          <p className="font-semibold text-xl text-gray-900">Order summary</p>
          <div className="border mt-1.5 border-gray-300 rounded py-3">
            <div className="">
              {[1,2,3].map((index: number) => (
                <div className="px-3 border-b-[1px] border-gray-400">
                  <div className="flex gap-2 py-2 items-center " key={index} >
                  <div className="">
                  <img src="https://images.pexels.com/photos/757432/pexels-photo-757432.jpeg" alt="" className="w-20 h-20 rounded-md" />
                  </div>
                  <div className="flex flex-col gap-1 text-sm font-medium" >
                    <p className="font-semibold">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Size: EU: 44</p>
                    <p>Color: Red</p>
                  </div>
                </div>
                </div>
              ))}
            </div>
            <div className="space-y-2 border-b-[1px] pb-3 mb-2 border-gray-400 m-3">
              <div className="flex items-center justify-between gap-4">
                <p>Subtotal</p>
                <p>N 24,000</p>
              </div>
              <div className="flex items-center justify-between gap-4">
                <p>Shipping</p>
                <p>N 2,000</p>
              </div>
              <div className="flex items-center justify-between gap-4">
                <div>
                  <p>Coupon (QW12RE34)</p>
                </div>
                <p>N -7,000</p>
              </div>
            </div>
            <div className="flex items-center justify-between gap-4 p-3">
              <p>Total</p>
              <p>N 24,000</p>
            </div>
          </div>
        </div>
        <div className="p-3">
          <p className="font-semibold text-xl text-gray-900">
            Delivery details
          </p>
          <div className="border mt-1.5 border-gray-300 rounded p-3">
            <div className="flex items-center gap-6">
              <p>Name:</p>
              <p>Jonathan Doherty</p>
            </div>
            <div className="flex items-center gap-6">
              <p>Address:</p>
              <p>1, Abayomi Akerele street, Off Ogudu, Lagos</p>
            </div>
            <div className="flex items-center gap-6">
              <p>Phone:</p>
              <p>+2348130609629</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-center">
        <button className="border bg-primary w-[300px] py-2.5 font-medium text-white rounded-md">Download Invoice</button>
      </div>
      </div>
    </div>
  );
};

export default WebStorePaymentCallbackPage;
