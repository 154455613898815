import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
import { BsCheck } from "react-icons/bs";
import { useFormik } from "formik";
import { UserProfileModel } from "../../../models";
import { useAppSelector } from "../../../hooks";
import { useParams } from "react-router-dom";
import { sendSupportMessage } from "../../../api/Support";
import { getSvg } from "../../../utils";
import Modal from "../../../components/Dashboard/Modal";
import { ImSpinner2 } from "react-icons/im";
import * as Yup from "yup";
import InputMessage from "../../../components/Auth/Forms/InputMessage";

const complaints = [
  { issue: "Bug issue" },
  { issue: "Limit increase" },
  { issue: "Payment issue" },
  { issue: "Others" },
];

const initialValues = {
  message: "",
};

const SupportSchema = Yup.object().shape({
  message: Yup.string()
    .min(8, "Minimum 8 letters")
    .required("Message is required"),
});

const SupportPage = () => {
  const { reason } = useParams();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [selected, setSelected] = useState(complaints[0]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);


  useEffect(() => {
    if (reason === "limit-increase") {
      setSelected(complaints[1]);
    }
  }, [])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: SupportSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      setSubmitting(true)
      const payload = {
        platformKey,
        reasonForMessage: selected.issue,
        message: values.message,
      };

      try {
        const response = await sendSupportMessage(payload);
        // console.log(response)
        if (response.status === 200) {
          setTimeout(() => {
            setSubmitting(false);
            setShowSuccessModal(true);
            resetForm({});
          }, 3500)
        }

      } catch (error) {
        setSubmitting(false);
        alert(error);
      }
      // setSubmitting(false);
    },
  });

  return (
    <>
      <Modal
        size="medium"
        show={showSuccessModal}
        onToggle={() => {
          setShowSuccessModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="text-center">
            <img src={getSvg("check")} alt="icon" className="w-24 mx-auto" />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-xl">
              Your message has been sent.
            </h1>
          </div>
        </div>
      </Modal>
      <div className="h-full min-h-[85vh] sm:h-[85vh] py-3 px-4 bg-white rounded-md shadow-md sm:overflow-y-auto space-y-2 flex flex-col">
        <div>
          <h4 className="text-lg font-bold text-black capitalize">Help center</h4>
          <p className="text-gray-500 text-sm">
            Kindly send us a message so we can help.
          </p>
        </div>
        <form onSubmit={formik.handleSubmit} className="space-y-2">
          <div>
            <label className="text-gray-600 text-xs">Reason for contact</label>
            <div className="w-52">
              <Listbox value={selected} onChange={setSelected}>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default border border-gray-300 rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm">
                    <span className="block truncate">{selected.issue}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <BiChevronDown className="h-5 w-5 text-gray-400" />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {complaints.map((issue, issueIdx) => (
                        <Listbox.Option
                          key={issueIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                              ? "bg-blue-100 text-blue-900"
                              : "text-gray-900"
                            }`
                          }
                          value={issue}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${selected ? "font-medium" : "font-normal"
                                  }`}
                              >
                                {issue.issue}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                  <BsCheck className="h-5 w-5" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
          <div>
            <label className="text-gray-700 text-xs">Message</label>
            <textarea
              id="message"
              name="message"
              rows={5}
              onChange={formik.handleChange}
              value={formik.values.message}
              className="block w-full h-[300px] max-w-[600px] p-3 text-base text-gray-900 bg-transparent shadow-sm border rounded-md outline-none focus:border-blue-500"
            ></textarea>
            {formik.errors.message && (
              <InputMessage message={formik.errors.message} />
            )}
          </div>
          <button
            type="submit"
            className="w-28 text-center p-2 text-sm font-semibold text-white bg-blue-700 border rounded-md shadow-sm"
          >
            {formik.isSubmitting ? (
              <ImSpinner2 size={20} className="mx-auto animate-spin" />
            ) : "Send"}

          </button>
        </form>
      </div>
    </>
  );
};

export default SupportPage;
