import React from "react";
import { getImage } from "../../../../../utils";

const MessagesPageNew = () => {
  return (
    <div>
      {" "}
      <div className="space-y-1">
        <div className="overflow-y-hidden relative">
          <div className="w-full">
            <div className="h-full mx-auto">
              <img
                src={getImage("updating-bg")}
                alt=""
                className="max-w-full mx-auto"
              />
            </div>
          </div>
          <div className="mt-10 absolute bottom-16 pl-10 text-[#132C58] ">
            <h4 className="text-6xl font-bold uppercase ">Updating</h4>
            <p className="mt-2 text-lg font-medium max-w-[480px]">
              This feature is temporarily unavailable. We’re improving to give
              you a better experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesPageNew;
