import { useState, Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "../../../utils";

interface Props {
    show: boolean;
    size?: "xl" | "large" | "medium" | "normal";
    onToggle?: (value: boolean) => void;
    children?: React.ReactNode;
    position?: "left" | "right",
}

const MobileMenuWrapper = ( {position = "left", ...props}: Props) => {
    const [show, setShow] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    const closeModal = () => {
        if (props.onToggle !== undefined) {
            props.onToggle(false);
        }
        setShow(false);
    }

    useEffect(() => {

        setShow(props.show);
    }, [props.show]);

  return (
    <Transition show={show}
            enter="transition-opacity duration-75"
            enterFrom="opacity-100"
            enterTo="opacity-100"
            leave="transition-opacity duration-75 delay-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div style={{ zIndex: 10000 }} ref={ref} onClick={(event) => event.target === ref.current && closeModal()} className={classNames("fixed top-0 left-0 bg-black bg-opacity-40 h-screen w-full flex", position === "left" ? "justify-start" : "justify-end")}>
                <Transition.Child
                    className={""}
                    enter="ease-out duration-200 delay-75"
                    enterFrom={classNames("opacity-0", position === "left" ? "-translate-x-10" : "translate-x-10")}
                    enterTo={classNames("opacity-100", position === "left" ? "translate-x-0" : "-translate-x-0")}
                    leave="ease-out duration-200"
                    leaveFrom={classNames("opacity-100", position === "left" ? "translate-x-0" : "-translate-x-0")}
                    leaveTo={classNames("opacity-0", position === "left" ? "-translate-x-10" : "translate-x-10")}
                >
                    {props.children}
                </Transition.Child>
            </div>
        </Transition>
  )
}

export default MobileMenuWrapper;