//@ts-nocheck

import { useQuery } from "@tanstack/react-query";
import { BiCopy } from "react-icons/bi";
import { UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { getPlatformReferralList, getReferralCode } from "../../../../api/Referrals";
import { ImSpinner2 } from "react-icons/im";
import { useState } from "react";
import moment from "moment";

const ReferralSettings = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const [copied, setCopied] = useState(false);
  const queryKey = user.queryKey;
  let referralCode = "";
  let referralLists = [];
  // "1002820587%26ngameduruuchenna@gmail.com"

  const platformReferralCode = useQuery<any, Error>({
    queryKey: ["getReferralCode"],
    queryFn: async () => {
      const response = await getReferralCode(queryKey)
      return response.data;
    }
  })

  const platformReferralList = useQuery<any, Error>({
    queryKey: ["getPlatformReferralList"],
    queryFn: async () => {
      const response = await getPlatformReferralList(queryKey)
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data)
    }
  })

  if (platformReferralCode.data) {
    referralCode = platformReferralCode.data.referral_code;
  }

  if (platformReferralList.data) {
    referralLists = platformReferralList.data;
  }

  return (
    <div className="h-full bg-white flex flex-col space-y-4">
      <div className="flex items-center space-x-3">
        <h4 className="text-xs text-gray-700 font-semibold">Referral code: <strong className="text-sm uppercase">{referralCode ?? ""}</strong></h4>
        <button disabled={referralCode === ""} className="py-1 px-3 rounded-md flex items-center space-x-1 border border-primary text-primary font-semibold bg-transparent text-xs" onClick={() => {
          navigator.clipboard.writeText(referralCode);
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 3000)
        }}>
          <BiCopy size={16} />
          <span>Copy</span>
        </button>
        {copied && (
          <p className="text-green-600 text-center text-xs font-medium">Copied to clipboard</p>
        )}
      </div>
      <div className="flex-1 h-full overflow-auto">
        <table className="w-full overflow-auto text-xs text-left text-gray-500 border rounded-md">
          <thead className="text-gray-700 capitalize bg-gray-50">
            <tr className="text-xs text-center">
              <th scope="col" className="px-6 py-3 table-cell">
                Referrals
              </th>
              <th scope="col" className="px-6 py-3 table-cell">
                Email {`(${referralLists.length})` ?? ""}
              </th>
              <th scope="col" className="px-6 py-3 hidden sm:table-cell">
                Time created
              </th>
            </tr>
          </thead>
          {platformReferralList.isLoading ? (
            <tbody>
              <tr>
                <td className="text-center p-3" ></td>
                <td className="text-center flex justify-center items-center p-3 font-semibold" ><ImSpinner2 size={24} className="animate-spin" /></td>
                <td className="hidden sm:block text-center p-3" ></td>
              </tr>
            </tbody>
          ) : platformReferralList.isError ? (<p>Error</p>) : referralLists.length > 0 ? (referralLists?.map((referee: any, index: number) => (
            <tbody key={index} >
              <tr className="text-center bg-white border-b" >
                <td className="px-6 py-2.5 border-x">
                  {referee.Referee_Name}
                </td>
                <td className="px-6 py-2.5 border-x">
                  {referee.Referee_Email}
                </td>
                <td className="hidden sm:block px-6 py-2.5 border-x">
                {moment(referee.Time).format(
                        "Do MMMM YYYY"
                      )}
                </td>
              </tr>
            </tbody>
          ))) : (
            <tbody>
              <tr>
                <td className="text-center p-3" ></td>
                <td className="text-center p-3 font-semibold" >No referree yet</td>
                <td className="text-center p-3" ></td>
              </tr>
            </tbody>
          )}

        </table>
      </div>
    </div>
  );
};

export default ReferralSettings;