import { Link } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getDeliveryDetail } from "../../../../../../api/Menu";
import { StoreModel, UserProfileModel } from "../../../../../../models";
import { useAppSelector } from "../../../../../../hooks";

const EstoreDelivery = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  let Pickup_Location:string = "";
  let Delivery_Location: any = [];

  const deliveryDetails = useQuery<any, Error>({
    queryKey: ["getDeliveryDetail"],
    queryFn: async () => {
      const response = await getDeliveryDetail(`${storeKey}`);
      return response.data;
    },
    onSuccess: (data) => {
      if (Object.keys(data.Location_Tag).length !== 0) {
        let newA = Object.entries(data.Location_Tag);
        const realLocations = newA.filter(
          (x: any) => !x.at(0).includes("dummylocation")
        );
        const fakeLocations = newA.filter((x: any) =>
          x.at(0).includes("dummylocation")
        );
        const newB = [...realLocations, ...fakeLocations];
      }
    },
  });

  if(deliveryDetails.data){
    // console.log(deliveryDetails.data);
    Pickup_Location = deliveryDetails.data.Pickup_Location || "";
    const a = deliveryDetails.data.Location_Tag;
    const b = Object.keys(a).filter(x => !x.includes("dummylocation"))
    Delivery_Location = b.join(", ");
    // console.log(Delivery_Location);F
  }

  return (
    <>
      <div className="col-span-3 h-full bg-white p-3">
        <p className="text-gray-800">Delivery</p>{" "}
        <div className="mt-4">
          <p className="text-blue-700">Shipping type</p>{" "}
          <div className="flex items-center justify-between">
            <div className="text-sm space-y-1 mt-4">
              <p>Customer pick-up</p>
              <p className="text-gray-600">{Pickup_Location}</p>
            </div>
            <Link
              to={"/estore/settings/delivery/edit"}
              className="text-gray-800"
            >
              <MdOutlineChevronRight />
            </Link>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm space-y-1 mt-4">
              <p className="text-gray-800">Delivery locations </p>
              <p className="text-gray-600">
                {Delivery_Location}
              </p>{" "}
            </div>
            <Link
              to={"/estore/settings/delivery/edit"}
              className="text-gray-800"
            >
              <MdOutlineChevronRight />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default EstoreDelivery;
