// @ts-nocheck

import { MdOutlineClear } from "react-icons/md";
import { classNames } from "../../../../../../../utils";
import { ImSpinner3 } from "react-icons/im";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks";
import {
  downloadStoreItems,
  getAllMenuCategories,
  getFreeDeliveryStatus,
} from "../../../../../../../api/Menu";
import CategoryCard from "./CategoryCard";
import { SET_DELIVERY_STATUS } from "../../../../../../../redux/User";


const ProductsSection = ({
  setSidePanel,
  sidePanel,
  setSelectedSection,
  setShowDeleteModal,
  setProductSidePanelStep,
  templateSections,
  setStep,
  activeCategory,
  setActiveCategory,
}) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const dispatch = useAppDispatch();

  let categories: any[] = [];
  let deliveryStatus = "0";
  let allItems: any[] = [];

  const allMenuCategories = useQuery<any, Error>({
    queryKey: ["getAllStoreCategories"],
    queryFn: async () => {
      const response = await getAllMenuCategories(storeKey);
      return response.data;
    },
    onSuccess: async (data) => { },
  });

  const freeDeliveryStatus = useQuery<any, Error>({
    queryKey: ["freeDeliveryStatus"],
    queryFn: async () => {
      const response = await getFreeDeliveryStatus(storeKey);
      return response.data;
    },
    onSuccess: (data) => {
      // setFreeDelivery(data.Delivery_Status);
      dispatch(SET_DELIVERY_STATUS(data.Delivery_Status));
    }
  });

  if (freeDeliveryStatus.data) {
    deliveryStatus = freeDeliveryStatus.data.Delivery_Status;
  }

  if (allMenuCategories.data) {
    categories = allMenuCategories.data.Menu_Categories;
  }

  const getStoreItems = useInfiniteQuery<any, Error>({
    queryKey: ["getStoreItems"],
    queryFn: async ({ pageParam = "" }) => {
      const paginationKey = pageParam;
      const response = await downloadStoreItems(queryKey, paginationKey);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      // console.log(lastPage)
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
  });

  if (getStoreItems.data) {
    if ("pages" in getStoreItems.data) {
      getStoreItems.data.pages.forEach((page) => {
        if ("StoreItems" in page) {
          allItems = [...allItems, ...page.StoreItems];
          // console.log(allItems)
        }
      });
    }
  }


  return (
    <section
      className={classNames(
        "cursor-pointer",
        sidePanel === "products" ? "border-2 border-blue-800 p-3" : "",
        !templateSections.includes("Product") === true ? "" : "hidden"
      )}
      onClick={() => {
        setProductSidePanelStep(1);
        setSidePanel("products");
      }}
    >
      <div
        className={classNames(
          "relative px-4 py-6 space-y-6 bg-white cursor-pointer"
        )}
      >
        <div className="text-center">
          <p className="text-lg font-semibold">Products</p>
        </div>
        {getStoreItems.status === "loading" ? (
          <div className="flex items-center justify-start py-5">
            <ImSpinner3
              size={20}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : getStoreItems.status === "error" ? (
          <p className="mt-8 font-semibold text-center text-gray-400">
            {getStoreItems.error.message}
          </p>
        ) : allItems.length > 0 ? (
          <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
            {allItems.map((item, index: number) => {
              return (
                <CategoryCard key={item.menuItemId} item={item} deliveryStatus={deliveryStatus} />
              );
            })}
          </div>
        ) : (
          <p className="mt-4 font-semibold text-center text-gray-800">
            No Product Available
          </p>
        )}
        
        {(allItems.length > 0 && getStoreItems.hasNextPage) && (<button onClick={() => getStoreItems.fetchNextPage()}
          disabled={
            !getStoreItems.hasNextPage || getStoreItems.isFetchingNextPage
          } className="text-sm block w-40 py-1.5 mx-auto mt-12 text-center text-white bg-blue-700 hover:bg-blue-800 rounded">{getStoreItems.isFetchingNextPage ? "Please wait..." : "See more"}</button>)}
        {(allItems.length > 0 && !getStoreItems.hasNextPage) && (<p className="mt-12 text-base font-medium text-center text-gray-400">That's it!</p>)}

        <MdOutlineClear
          size={18}
          className="absolute text-blue-800 cursor-pointer right-4 top-2"
          onClick={(e) => {
            e.stopPropagation();
            setShowDeleteModal(true);
            setSelectedSection("Product");
          }}
        />
      </div>
    </section>
  );
};

export default ProductsSection;
