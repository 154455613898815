import { MdDeleteOutline, MdOutlineCancelPresentation } from "react-icons/md";
import { classNames, getSvg } from "../../../../../utils";
import Modal from "../../../../../components/Dashboard/Modal";
import { useState } from "react";
import { FiUpload } from "react-icons/fi";
import { BiChevronLeft, BiChevronUp } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeHolder: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
}

const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
}: InputProps) => {
  return (
    <>
      <label className="block mb-1 text-xs font-normal text-gray-800">
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        disabled={disabled}
        className={classNames(
          className,
          "border border-gray-300 py-2 px-5 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm"
        )}
      />
    </>
  );
};

const DashBoardPage = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Modal show={showModal}>
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img src={getSvg("check")} alt="icon" className="mx-auto w-24" />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-2xl">
              You Have Successfully Added To The Menu
            </h1>
            <p className="text-gray-500 md:text-sm">
              This Item Will Now Reflect On The Menu.
            </p>
          </div>
          <span
            className="absolute cursor-pointer top-2 right-2"
            onClick={() => setShowModal((prevState) => !prevState)}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                fill="#5A5A5A"
              />
            </svg>
          </span>
        </div>
      </Modal>
      <div className="space-y-2 max-w-[80%] mx-auto">
        {/* <PageHeader title="Content Manager" /> */}
        {/* <div className="h-[85vh] py-3 px-4 bg-white rounded-md shadow-md overflow-x-hidden">
        
      </div> */}
        <div className="flex items-center gap-3 py-1">
          <div
            className="p-2 text-gray-800 bg-white rounded cursor-pointer"
            onClick={() => navigate("/home")}
          >
            <BiChevronLeft />
          </div>
          <h4 className="text-xl text-gray-400 capitalize">Echo Menu</h4>
        </div>
        <div className="py-4 px-8 bg-white rounded shadow-md flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Echo Menu Setup</p>
            <p className="text-xs text-gray-500">
              Get Your Menu and Order Placing Running
            </p>
          </div>
          <button className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2">
            View Menu
          </button>
        </div>
        <div className="py-4 px-8 bg-white rounded shadow-md ">
          <p className="font-semibold">
            Upload URL <span className="text-gray-500">(Optional)</span>
          </p>
          <p className="text-xs text-gray-500">
            If You Have A Funtioning Menu, Upload Your Current URl here
          </p>
          <input
            type="text"
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-3"
            placeholder="Enter URL"
          ></input>
        </div>
        <div className="py-4 px-8 bg-white rounded shadow-md ">
          <div className="flex items-center justify-between">
            <div className="">
              <p className="font-semibold">Add Items</p>
              <p className="text-xs text-gray-500">Add Items To Your Menu</p>
            </div>
            <BiChevronUp size={25} />
          </div>
          <form className="space-y-3 mt-4">
            <div className="w-full">
              <Input
                // formik={formik}
                name="itemName"
                label="Item Name"
                type="text"
                placeHolder="Enter Item Name"
              />
              {/* {formik.values.email && formik.errors.email && (
              <InputMessage message={formik.errors.email} />
            )} */}
            </div>
            <div className="w-full">
              <Input
                // formik={formik}
                name="details"
                label="Details"
                type="text"
                placeHolder="Enter Item Details"
              />
              {/* {formik.values.email && formik.errors.email && (
              <InputMessage message={formik.errors.email} />
            )} */}
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="w-full">
                <Input
                  // formik={formik}
                  name="price"
                  label="Price"
                  type="text"
                  placeHolder="Enter Item Price"
                />
                {/* {formik.values.email && formik.errors.email && (
              <InputMessage message={formik.errors.email} />
            )} */}
              </div>
              <div className="w-full">
                <Input
                  // formik={formik}
                  name="category"
                  label="Category"
                  type="text"
                  placeHolder="Enter Item Category"
                />
                {/* {formik.values.email && formik.errors.email && (
              <InputMessage message={formik.errors.email} />
            )} */}
              </div>
            </div>
            <div>
              <label className="block mb-1 text-xs font-normal text-gray-800">
                Image
              </label>
              {/* <div className="w-full h-[180px] border flex items-center justify-center">
              <div className="text-center space-y-1">
                <div className="flex place-items-center">
                  <FiUpload size={20} className="mx-auto" />
                </div>
                <input type="file" className="" placeholder="Select File" />
                <p className="text-gray-700 text-sm">
                  Or drop files in this place
                </p>
              </div>
            </div> */}

              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-64 border border-gray-300 rounded-md cursor-pointer bg-gray-white "
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500">SVG, PNG or JPG</p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    disabled
                  />
                </label>
              </div>
            </div>
            <div className="flex items-center justify-center mt-6 mb-4">
              <button className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-16 py-2 ">
                Save Item
              </button>
            </div>
          </form>
        </div>
        <div className="py-4 px-8 bg-white rounded shadow-md space-y-4">
          <div className="flex items-center justify-between">
            <div className="">
              <p className="font-semibold">All Items</p>
              <p className="text-xs text-gray-500">
                View All Items Saved On Your Menu
              </p>
            </div>
            <BiChevronUp size={25} />
          </div>
          <div className="space-y-3">
            {[1, 2, 3, 4, 5].map((index) => (
              <div className="flex items-center gap-4" key={index}>
                <div className="h-[50px] w-[50px] bg-gray-100 rounded-md"></div>
                <div className="">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-semibold">Spicy Lobster</p>
                    <p className="text-xs">$120</p>
                    <p className="text-xs">Appetizer</p>
                  </div>
                  <p className="text-xs">
                    Lorem ipsum dolor sit amet consectetur adipisicing.
                  </p>
                </div>
                <MdDeleteOutline size={24} className="ml-auto cursor-pointer" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardPage;
