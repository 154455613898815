import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

interface Props {
  orderID: string;
  finalTotalPrice: string;
  deliveryFee: string;
  name: string;
  address: string;
  phone: string;
  email: string;
  paymentDetails: any;
  orderPayload: any;
  referenceValue?: any;
}

const MyPrintComponent = ({
  orderID,
  finalTotalPrice,
  deliveryFee,
  name,
  address,
  phone,
  email,
  paymentDetails,
  referenceValue,
}: Props) => {
  // console.log(paymentDetails);
  const ref: any = useRef<HTMLDivElement>();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const type = JSON.parse(sessionStorage.getItem("orderPayload") || "{}");
  // console.log(type.slugUrl.split("/"));
  
  return (
    <>
      <div className="flex items-center justify-center my-8" >
        <button
          onClick={() => {
            handlePrint();
          }}
          className="border bg-primary w-[300px] h-[50px] font-medium text-white rounded-md text-sm"
        >
          <span className="">Download invoice</span>
        </button>
      </div>

      <div style={{ display: "none" }}>
        <div
          ref={ref}
          className="font-nunito"
          style={{ margin: 0, padding: 0, height: "100vh" }}
        >
          <div className="h-full flex flex-col justify-between">
            <div className="p-12">
              <div className="mb-16">
                <div className="flex items center justify-between text-xl">
                  <p className="text-[#254B9A] font-semibold text-2xl">
                    Invoice
                  </p>
                  <p className="font-semibold capitalize">{type.slugUrl.split("/")[2]}</p>
                </div>
                <div className="flex justify-between mt-6">
                  <div className="space-y-1">
                    <p>
                      Order Id: <span>{orderID}</span>
                    </p>
                    {referenceValue && <p>Invoice no: {referenceValue}</p>}
                    <p>Date: {new Date().toDateString()}</p>
                  </div>
                  <div className="space-y-1 text-right">
                    <p>Bill to:</p>
                    <p>{name}</p>
                    <p>{phone}</p>
                    <p>{address}</p>
                    <p>{email}</p>
                  </div>
                </div>
                <div className="mt-8">
                  <div>
                    <div className="flex items-center justify-between w-full pb-2 border-b-[1px] mb-2 border-gray-200 font-semibold">
                      <p className="w-[70%]">Item name</p>
                      <div className="grid grid-cols-3 ml-auto w-[35%] space-x-2">
                        <p>Price</p>
                        <p>Qty</p>
                        <p>Total</p>
                      </div>
                    </div>
                    {type.orderPayload.map((item: any, index: number) => (
                      <div
                        className="flex items-center justify-between w-full mb-2"
                        key={index}
                      >
                        <p className="w-[70%]">{item.itemName}</p>
                        <div className="grid grid-cols-3 ml-auto w-[35%] space-x-2">
                          <p>{item.itemPrice}</p>
                          <p>{item.quantityOrder}</p>
                          <p>&#8358; {item.itemPrice * item.quantityOrder}</p>
                        </div>
                      </div>
                    ))}
                    <div className="flex items-center justify-between w-full my-2">
                      <p className="w-[70%]"></p>
                      <div className="ml-auto w-[35%] border-b-[1px] border-gray-200"></div>
                    </div>
                    <div className="flex items-center justify-between w-full my-2">
                      <p className="w-[70%]"></p>
                      <div className="ml-auto w-[35%] space-y-2">
                        <div className="grid grid-cols-3 space-x-2">
                          <p>Subtotal</p>
                          <div></div>
                          {type.billingDetail.deliveryDetail.fee ? (
                            <p>
                              &#8358; {type.transactionPayload.totalAmount - type.billingDetail.deliveryDetail.fee}
                            </p>
                          ) : (
                            <p>&#8358; {type.transactionPayload.totalAmount}</p>
                          )}
                        </div>
                        <div className="grid grid-cols-3 space-x-2">
                          <p>Shipping</p>
                          <div></div>
                          <p>&#8358; {deliveryFee || 0.00}</p>
                        </div>
                        <div className="grid grid-cols-3 space-x-2 pb-1.5 border-b-[1px] border-gray-200">
                          <p>Coupon</p>
                          <div></div>
                          <p>&#8358; 0.000</p>
                        </div>
                        <div className="grid grid-cols-3 space-x-2 border-b-[1px] pt-0.5 pb-2 border-gray-200">
                          <p>Total</p>
                          <div></div>
                          <p>&#8358; {type.transactionPayload.totalAmount}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {paymentDetails?.length > 0 && (
              <div className="bg-[#F1F1F1] p-12 text-sm text-gray-900">
                <p className="mb-4">
                  Please make payment to any of the following account(s):
                </p>
                <div className="grid grid-cols-2">
                  {paymentDetails.slice(0,1).map((detail: any, index: number) => (
                    <div className="space-y-1.5" key={index}>
                      <p>Account number {index + 1}</p>
                      <div className="flex items-center gap-2">
                        <p className="w-[110px]">Account name:</p>
                        <p>{detail.accountName}</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="w-[110px]">Account number:</p>
                        <p>{detail.accountNumber}</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="w-[110px]">Bank name:</p>
                        <p>{detail.bankName}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <p className="mt-3">
                  Use the order ID as the payment reference. Your order will not
                  be shipped until funds have cleared in our account.{" "}
                </p>
                <div className="flex justify-center items-center gap-2.5 mt-12">
                  <p>Powered by </p>
                  <img src="/media/image/receipt-logo.png" alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPrintComponent;
