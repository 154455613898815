import { Navigate, Outlet } from "react-router-dom";
import { selectAuthState, useAppSelector } from "../../hooks";


//Protected Route
const RequireAuth = () => {
  
const isLoggedIn: boolean = useAppSelector(selectAuthState).isLoggedIn;

  return (isLoggedIn ? <Outlet /> : <Navigate to='/login' />);
}

export default RequireAuth;

