export const homeSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 15.5V13"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.39172 2.85053L2.61672 7.47553C1.96672 7.99219 1.55006 9.08386 1.69172 9.90053L2.80006 16.5339C3.00006 17.7172 4.13339 18.6755 5.33339 18.6755H14.6667C15.8584 18.6755 17.0001 17.7089 17.2001 16.5339L18.3084 9.90053C18.4417 9.08386 18.0251 7.99219 17.3834 7.47553L11.6084 2.85886C10.7167 2.14219 9.27506 2.14219 8.39172 2.85053Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const closeMenuSVG = (
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1L1 6L6 11"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const productsSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 18.8327C14.6022 18.8327 18.3332 15.1017 18.3332 10.4993C18.3332 5.89698 14.6022 2.16602 9.99984 2.16602C5.39746 2.16602 1.6665 5.89698 1.6665 10.4993C1.6665 15.1017 5.39746 18.8327 9.99984 18.8327Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M9.99984 2.16602C7.86003 4.41281 6.6665 7.39663 6.6665 10.4993C6.6665 13.6021 7.86003 16.5859 9.99984 18.8327C12.1396 16.5859 13.3332 13.6021 13.3332 10.4993C13.3332 7.39663 12.1396 4.41281 9.99984 2.16602Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path d="M1.6665 10.5H18.3332" stroke="white" strokeLinejoin="round" />
  </svg>
);

export const ordersSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 8.83293V7.16626C17.4997 6.87399 17.4225 6.58693 17.2763 6.3339C17.13 6.08086 16.9198 5.87073 16.6667 5.72459L10.8333 2.39126C10.58 2.24498 10.2926 2.16797 10 2.16797C9.70744 2.16797 9.42003 2.24498 9.16667 2.39126L3.33333 5.72459C3.08022 5.87073 2.86998 6.08086 2.72372 6.3339C2.57745 6.58693 2.5003 6.87399 2.5 7.16626V13.8329C2.5003 14.1252 2.57745 14.4123 2.72372 14.6653C2.86998 14.9183 3.08022 15.1285 3.33333 15.2746L9.16667 18.6079C9.42003 18.7542 9.70744 18.8312 10 18.8312C10.2926 18.8312 10.58 18.7542 10.8333 18.6079L12.5 17.6579"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 4.05859L13.75 8.35026"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M2.7417 6.33398L10 10.5007L17.2584 6.33398"
      stroke="white"
      strokeLinejoin="round"
    />
    <path d="M10 18.8333V10.5" stroke="white" strokeLinejoin="round" />
    <path
      d="M15.4168 15.5007C16.5674 15.5007 17.5002 14.5679 17.5002 13.4173C17.5002 12.2667 16.5674 11.334 15.4168 11.334C14.2662 11.334 13.3335 12.2667 13.3335 13.4173C13.3335 14.5679 14.2662 15.5007 15.4168 15.5007Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M16.8916 14.8926L18.3333 16.3342"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export const payoutSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6665 4.66602H3.33317C2.4127 4.66602 1.6665 5.41221 1.6665 6.33268V14.666C1.6665 15.5865 2.4127 16.3327 3.33317 16.3327H16.6665C17.587 16.3327 18.3332 15.5865 18.3332 14.666V6.33268C18.3332 5.41221 17.587 4.66602 16.6665 4.66602Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path d="M1.6665 8.83398H18.3332" stroke="white" strokeLinejoin="round" />
  </svg>
);

export const customerRewardSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99984 3H14.9998L18.3332 8L9.99984 18.8333L1.6665 8L4.99984 3Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M9.1665 3L6.6665 8L9.99984 18.8333L13.3332 8L10.8332 3"
      stroke="white"
      strokeLinejoin="round"
    />
    <path d="M1.6665 8H18.3332" stroke="white" strokeLinejoin="round" />
  </svg>
);

export const customerReviewSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 2.16602L12.5748 7.38268L18.3332 8.22435L14.1665 12.2827L15.1498 18.016L9.99984 15.3077L4.84984 18.016L5.83317 12.2827L1.6665 8.22435L7.42484 7.38268L9.99984 2.16602Z"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export const customerSatisfactionSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.5 3V18H17.5" stroke="white" strokeLinejoin="round" />
    <path
      d="M15.8335 8L11.6668 12.1667L8.3335 8.83333L5.8335 11.3333"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export const feedbackSourcesSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3335 5.5L16.6668 17.1667"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
    <path
      d="M10 5.5V17.1667"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
    <path
      d="M6.6665 7.16602V17.166"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
    <path
      d="M3.3335 3.83398V17.1673"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
  </svg>
);

export const helpDeskSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 9.66601H5C5.44203 9.66601 5.86595 9.84161 6.17851 10.1542C6.49107 10.4667 6.66667 10.8907 6.66667 11.3327V13.8327C6.66667 14.2747 6.49107 14.6986 6.17851 15.0112C5.86595 15.3238 5.44203 15.4993 5 15.4993H4.16667C3.72464 15.4993 3.30072 15.3238 2.98816 15.0112C2.67559 14.6986 2.5 14.2747 2.5 13.8327V9.66601ZM2.5 9.66601C2.5 8.6811 2.69399 7.70583 3.0709 6.79589C3.44781 5.88595 4.00026 5.05915 4.6967 4.36271C5.39314 3.66628 6.21993 3.11383 7.12987 2.73692C8.03982 2.36001 9.01509 2.16602 10 2.16602C10.9849 2.16602 11.9602 2.36001 12.8701 2.73692C13.7801 3.11383 14.6069 3.66628 15.3033 4.36271C15.9997 5.05915 16.5522 5.88595 16.9291 6.79589C17.306 7.70583 17.5 8.6811 17.5 9.66601M17.5 9.66601V13.8327C17.5 14.2747 17.3244 14.6986 17.0118 15.0112C16.6993 15.3238 16.2754 15.4993 15.8333 15.4993H15C14.558 15.4993 14.134 15.3238 13.8215 15.0112C13.5089 14.6986 13.3333 14.2747 13.3333 13.8327V11.3327C13.3333 10.8907 13.5089 10.4667 13.8215 10.1542C14.134 9.84161 14.558 9.66601 15 9.66601H17.5Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 13.834V15.5007C17.5 16.3847 17.1488 17.2326 16.5237 17.8577C15.8986 18.4828 15.0507 18.834 14.1667 18.834H10"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export const messagingSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6665 3.83398H3.33317C2.4127 3.83398 1.6665 4.58018 1.6665 5.50065V15.5007C1.6665 16.4211 2.4127 17.1673 3.33317 17.1673H16.6665C17.587 17.1673 18.3332 16.4211 18.3332 15.5007V5.50065C18.3332 4.58018 17.587 3.83398 16.6665 3.83398Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M18.3332 6.33398L10.8582 11.084C10.6009 11.2452 10.3034 11.3307 9.99984 11.3307C9.69624 11.3307 9.39878 11.2452 9.1415 11.084L1.6665 6.33398"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export const accessManagerSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 18.8327C14.6022 18.8327 18.3332 15.1017 18.3332 10.4993C18.3332 5.89698 14.6022 2.16602 9.99984 2.16602C5.39746 2.16602 1.6665 5.89698 1.6665 10.4993C1.6665 15.1017 5.39746 18.8327 9.99984 18.8327Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M10 15.5C12.7614 15.5 15 13.2614 15 10.5C15 7.73858 12.7614 5.5 10 5.5C7.23858 5.5 5 7.73858 5 10.5C5 13.2614 7.23858 15.5 10 15.5Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M10.0002 12.1673C10.9206 12.1673 11.6668 11.4211 11.6668 10.5007C11.6668 9.58018 10.9206 8.83398 10.0002 8.83398C9.07969 8.83398 8.3335 9.58018 8.3335 10.5007C8.3335 11.4211 9.07969 12.1673 10.0002 12.1673Z"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export const taskManagerSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3332 9.73355V10.5002C18.3321 12.2972 17.7503 14.0458 16.6743 15.4851C15.5983 16.9244 14.0859 17.9773 12.3626 18.4868C10.6394 18.9963 8.79755 18.9351 7.1119 18.3124C5.42624 17.6896 3.98705 16.5386 3.00897 15.0311C2.03089 13.5236 1.56633 11.7403 1.68457 9.94714C1.80281 8.15402 2.49751 6.44715 3.66507 5.08111C4.83263 3.71506 6.41049 2.76303 8.16333 2.367C9.91616 1.97097 11.7501 2.15216 13.3915 2.88355"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 9.66732L10 12.1673L18.3333 3.83398"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export const businessMetricsSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 6.33333V4.66667C2.5 4.22464 2.67559 3.80072 2.98816 3.48816C3.30072 3.17559 3.72464 3 4.16667 3H5.83333"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
    <path
      d="M14.1665 3H15.8332C16.2752 3 16.6991 3.17559 17.0117 3.48816C17.3242 3.80072 17.4998 4.22464 17.4998 4.66667V6.33333"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
    <path
      d="M17.4998 14.666V16.3327C17.4998 16.7747 17.3242 17.1986 17.0117 17.5112C16.6991 17.8238 16.2752 17.9993 15.8332 17.9993H14.1665"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
    <path
      d="M5.83333 17.9993H4.16667C3.72464 17.9993 3.30072 17.8238 2.98816 17.5112C2.67559 17.1986 2.5 16.7747 2.5 16.3327V14.666"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
    <path
      d="M9.99984 11.3327C10.4601 11.3327 10.8332 10.9596 10.8332 10.4993C10.8332 10.0391 10.4601 9.66602 9.99984 9.66602C9.5396 9.66602 9.1665 10.0391 9.1665 10.4993C9.1665 10.9596 9.5396 11.3327 9.99984 11.3327Z"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
    <path
      d="M4.1665 10.5007C4.1665 10.5007 6.24984 6.33398 9.99984 6.33398C13.7498 6.33398 15.8332 10.5007 15.8332 10.5007C15.8332 10.5007 13.7498 14.6673 9.99984 14.6673C6.24984 14.6673 4.1665 10.5007 4.1665 10.5007Z"
      stroke="white"
      strokeWidth="0.833333"
      strokeLinejoin="round"
    />
  </svg>
);

export const smartPricingSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6665 5.5H3.33317C2.4127 5.5 1.6665 6.24619 1.6665 7.16667V13.8333C1.6665 14.7538 2.4127 15.5 3.33317 15.5H16.6665C17.587 15.5 18.3332 14.7538 18.3332 13.8333V7.16667C18.3332 6.24619 17.587 5.5 16.6665 5.5Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M10.0002 12.1673C10.9206 12.1673 11.6668 11.4211 11.6668 10.5007C11.6668 9.58018 10.9206 8.83398 10.0002 8.83398C9.07969 8.83398 8.3335 9.58018 8.3335 10.5007C8.3335 11.4211 9.07969 12.1673 10.0002 12.1673Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M5 10.5H5.00833M15 10.5H15.0083"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export const storeAnalyticsSVG = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5002 2.16602H5.00016C4.55814 2.16602 4.13421 2.34161 3.82165 2.65417C3.50909 2.96673 3.3335 3.39065 3.3335 3.83268V17.166C3.3335 17.608 3.50909 18.032 3.82165 18.3445C4.13421 18.6571 4.55814 18.8327 5.00016 18.8327H15.0002C15.4422 18.8327 15.8661 18.6571 16.1787 18.3445C16.4912 18.032 16.6668 17.608 16.6668 17.166V6.33268L12.5002 2.16602Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M11.6665 2.16602V5.49935C11.6665 5.94138 11.8421 6.3653 12.1547 6.67786C12.4672 6.99042 12.8911 7.16602 13.3332 7.16602H16.6665"
      stroke="white"
      strokeLinejoin="round"
    />
    <path
      d="M13.3332 11.334L10.4165 14.2507L8.74984 12.584L6.6665 14.6673"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
);

export const estoreArray = [
  {
    title: "Products",
    icon: productsSVG,
    // href: "/home/echo-webpage/customizer",
    href: "/estore/products?type=items",
  },
  {
    title: "Orders",
    icon: ordersSVG,
    href: "/orders?type=pending",
  },
  {
    title: "Wallet",
    icon: payoutSVG,
    href: "/home/payments",
  },
  {
    title: "Customer reward",
    icon: customerRewardSVG,
    href: "/feedback-analysis/customer-loyalty",
  },
];

export const feedbackAnalysisArray = [
  {
    title: "Customer review",
    icon: customerReviewSVG,
    href: "/feedback-analysis/customer-review",
  },
  {
    title: "Customer satisfaction",
    icon: customerSatisfactionSVG,
    href: "/feedback-analysis/customer-satisfaction",
  },
  {
    title: "Feedback sources",
    icon: feedbackSourcesSVG,
    href: "/feedback-analysis/ingestion-library",
  },
];

export const operationsArray = [
  {
    title: "Help desk",
    icon: helpDeskSVG,
    href: "/feedback-analysis/help-desk",
  },
  {
    title: "Business metrics",
    icon: businessMetricsSVG,
    href: "/feedback-analysis/business-metrics",
  },
  {
    title: "Messaging",
    icon: messagingSVG,
    href: "/feedback-analysis/messages",
  },
  // {
  //   title: "Access manager",
  //   icon: accessManagerSVG,
  //   href: "/access-manager/groups",
  // },
  // {
  //   title: "Task manager",
  //   icon: taskManagerSVG,
  //   href: "/task-wizard",
  // },
  // {
  //   title: "Training repository",
  //   icon: taskManagerSVG,
  //   href: "/training-repository",
  // },
  // {
  //   title: "Content manager",
  //   icon: taskManagerSVG,
  //   href: "/content-manager",
  // },
];

export const lensArray = [
  // {
  //   title: "Business metrics",
  //   icon: businessMetricsSVG,
  //   href: "/feedback-analysis/update-business-metrics",
  // },
  {
    title: "Business metrics",
    icon: businessMetricsSVG,
    href: "/feedback-analysis/business-metrics",
  },
  {
    title: "Smart pricing",
    icon: smartPricingSVG,
    href: "/estore/smart-pricing",
  },
  {
    title: "Store analytics",
    icon: storeAnalyticsSVG,
    href: "/estore/store-analytics",
  },
];
