import { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { classNames } from "../../../../utils";

interface ChartProps {
  data: any[];
}

interface ChartProps {
  data: any[];
}

const Chart1 = ({ data }: ChartProps) => {
  const chartRef = useRef(HTMLDivElement.prototype);

  const newArray = data.map((x) => {
    const y = x.commentLabel;
    return y;
  });

  var total_positive = newArray.filter((str) => str.includes("POS")).length;
  var total_negative = newArray.filter((str) => str.includes("NEG")).length;

  useEffect(() => {
    let options = {
      chart: {
        type: "pie",
        width: "100%",
        height: 200,
        // offsetY: -20,
      },
      title: {
        text: "Overall Analytics",
        style: {
          color: "#11182780",
        },
      },
      colors:
        total_positive === 0 && total_negative === 0
          ? ["#f0f0f0"]
          : ["#3DB832", "#CD151E"],
      series:
        total_positive === 0 && total_negative === 0
          ? [1]
          : [total_positive, total_negative],
      labels:
        total_positive === 0 && total_negative === 0
          ? ["No Data"]
          : ["Positive", "Negative"],
      legend: {
        position: "left",
        offsetY: 70,
      },
    };

    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, total_positive, total_negative]);

  return <div ref={chartRef} id="oa-chart" className=""></div>;
};

const Chart2 = ({ data }: ChartProps) => {
  const chartRef = useRef(HTMLDivElement.prototype);

  const t = data.map(({ commentLabel, similarityScore }) => {
    return {
      commentLabel,
      similarityScore: Number(similarityScore.slice(0, -1)),
    };
  });

  const class1P = t.filter(
    (x) =>
      x.commentLabel === "POS" &&
      x.similarityScore > 0 &&
      x.similarityScore < 25
  ).length;
  const class2P = t.filter(
    (x) =>
      x.commentLabel === "POS" &&
      x.similarityScore > 25 &&
      x.similarityScore < 50
  ).length;
  const class3P = t.filter(
    (x) =>
      x.commentLabel === "POS" &&
      x.similarityScore > 50 &&
      x.similarityScore < 75
  ).length;
  const class4P = t.filter(
    (x) =>
      x.commentLabel === "POS" &&
      x.similarityScore > 75 &&
      x.similarityScore < 100
  ).length;

  const class1N = t.filter(
    (x) =>
      x.commentLabel === "NEG" &&
      x.similarityScore > 0 &&
      x.similarityScore < 25
  ).length;
  const class2N = t.filter(
    (x) =>
      x.commentLabel === "NEG" &&
      x.similarityScore > 25 &&
      x.similarityScore < 50
  ).length;
  const class3N = t.filter(
    (x) =>
      x.commentLabel === "NEG" &&
      x.similarityScore > 50 &&
      x.similarityScore < 75
  ).length;
  const class4N = t.filter(
    (x) =>
      x.commentLabel === "NEG" &&
      x.similarityScore > 75 &&
      x.similarityScore < 100
  ).length;

  useEffect(() => {
    let options = {
      chart: {
        type: "bar",
        width: "100%",
        height: 200,
        toolbar: {
          show: false,
        },
        // offsetY: -20,
      },
      title: {
        text: "Similarity Score Analytics",
        style: {
          color: "#11182780",
        },
      },
      colors: ["#3DB832", "#CD151E"],
      series: [
        {
          name: "Positive",
          data: [class1P, class2P, class3P, class4P],
        },
        {
          name: "Negative",
          data: [class1N, class2N, class3N, class4N],
        },
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["0% - 25%", "25% - 50%", "50% - 75%", "75% - 100%"],
        labels: {
          style: {
            fontSize: "8px",
            fontWeight: 500,
          },
        },
      },
    };

    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <div ref={chartRef} id="ssc-chart" className=""></div>;
};

const SearchReviewsEmptyState = () => {
  let tracebacks: any[] = [];
  const trest = [1];

  return (
    <>
      {tracebacks.length < 1 && (
        <div className="sticky space-y-2 bg-white top-10 ">
          <div className="grid grid-cols-3 gap-x-2 h-[30%] overflow-hidden mt-2">
            <div className="p-4 overflow-hidden border border-gray-200 rounded-md">
              {tracebacks && <Chart1 data={tracebacks} />}
            </div>
            <div className="p-4 overflow-hidden border border-gray-200 rounded-md">
              {tracebacks && <Chart2 data={tracebacks} />}
            </div>
            <div className="p-2 border border-gray-200 rounded-md">
              <p className="text-lg mb-4 font-semibold text-gray-500">
                Keyword Analytics
              </p>
              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 rounded-lg overflow-x-auto">
                  <thead className="text-gray-800 border-b border-t font-semibold">
                    <tr className="text-base grid grid-cols-4 text-center">
                      <th
                        scope="col"
                        className="p-1 text-xs  border-r flex items-center justify-center"
                      >
                        <p> Keyword</p>
                      </th>
                      <th
                        scope="col"
                        className="p-1 text-xs border-r flex items-center justify-center"
                      >
                        <p>Occurence count</p>
                      </th>
                      <th
                        scope="col"
                        className="p-1 text-xs col-span-2 flex items-center justify-center"
                      >
                        <p>Sources</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {trest.map((index) => (
                      <tr
                        className="bg-white border-b grid grid-cols-4 text-xs"
                        key={index}
                      >
                        <td className="flex items-center justify-center px-2 py-2.5 border-x">
                          <p className="text-gray-200">Promotion</p>
                        </td>
                        <td className="flex items-center justify-center  px-2 border-r">
                          <p>0</p>
                        </td>
                        <td className="p-2 col-span-2">
                          <div className="flex gap-1 flex-wrap">
                            <span className="p-1 bg-secondary-2 text-xs text-gray-900 font-semibold rounded">
                              Instagram
                            </span>
                            <span className="p-1 bg-secondary-2 text-xs text-gray-900 font-semibold rounded">
                              Voice
                            </span>
                            <span className="p-1 bg-secondary-2 text-xs text-gray-900 font-semibold rounded">
                              SMS
                            </span>
                            <span className="p-1 bg-secondary-2 text-xs text-gray-900 font-semibold rounded">
                              Facebook
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="py-2 pb-2">
            <p className="text-sm text-gray-400">
              Reviews That Match Your Search:
            </p>
          </div>
        </div>
      )}

      <div className="h-[50%]">
        <div
          className={classNames(
            "flex mb-2 space-x-4 border-none rounded-xl p-2"
          )}
        >
          <div className="flex flex-col items-center justify-center mt-4 w-12 h-12 border border-gray-300 rounded-full text-primary bg-gray-100">
            {/* <SourceIcon source={source} /> */}
          </div>
          <div className="flex flex-col flex-1 gap-2.5 mt-4">
            <div className="flex items-center gap-4">
              <h2 className="text-base font-medium h-[2rem] w-1/4 bg-gray-100"></h2>
              <p className="text-gray-300">0 mins ago</p>
            </div>
            <h2 className="text-base font-medium h-[2rem] bg-gray-100"></h2>
            <p
              className={classNames(
                "text-sm font-normal leading-normal h-[2rem] bg-gray-100"
              )}
            ></p>
            <div className="flex items-center justify-between px-5 py-2 bg-gray-100 rounded-full w-[24rem]">
              <p className="text-base font-normal text-gray-600">
                Comment Percentage:{" "}
                <span
                  className={classNames("ml-2 font-semibold text-gray-700")}
                >
                  0%
                </span>
              </p>
              <div className="inline-flex items-center">
                <p className="text-base font-normal text-gray-600">
                  Sentiment Tag{" "}
                </p>
                <div
                  className={classNames(
                    "h-4 w-4 ml-2 font-semibold rounded-full bg-gray-200"
                  )}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchReviewsEmptyState;
