import { combineReducers } from 'redux';
import authReducer from '../../redux/Auth';
import userReducer from '../../redux/User';
import utilsReducer from '../../redux/Utils';

export const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    utils: utilsReducer
});

export type RootState = ReturnType<typeof rootReducer>;