import { useState } from "react";
import { BiChevronRight, BiComment, BiSearch } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { APP_PERMISSIONS, classNames } from "../../../../utils";

const CreateGroupAccessPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalObject, setModalObject] = useState({
    email: "",
    phoneNumber: "",
    tickets: 0,
    reviews: 0,
  });
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [level, setLevel] = useState(0);
  const [active, setActive] = useState(0);

  return (
    <>
      <div className="flex bg-white rounded-md h-[85vh] p-3 space-y-5 sm:space-y-8 overflow-y-auto overflow-x-hidden flex-col">
        {level === 0 && (
          <>
            <div className="flex items-center space-x-0.5 text-sm text-gray-400 font-medium">
              <button onClick={() => navigate(-1)}>Groups</button>
              <span>
                <BiChevronRight size={14} />
              </span>
              <span className="text-black">New group</span>
            </div>
            <div className="">
              <h4 className="text-lg font-bold text-black">
                Create a new group
              </h4>
              <p className="text-[12.5px] font-normal text-[#303030]">
                Create a new group. An an existing user and assign roles to
                them.
              </p>
            </div>
            <div>
              <div className="relative w-full sm:w-3/5 lg:w-3/5">
                <label className="text-base font-medium text-black">
                  Group name
                </label>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    // if (e.target.value.length > 3) {
                    setSearchQuery(e.target.value);
                    // }
                  }}
                  placeholder="Enter a name to identify the group"
                  className="w-full px-4 py-2 mt-1 text-xs font-medium text-gray-500 border border-gray-300 rounded-md outline-none"
                />
                <BiSearch
                  size={21}
                  className="absolute top-9 right-2.5 fill-gray-500 placeholder:text-gray-400"
                />
              </div>
            </div>
            <div className="">
              <h4 className="text-base font-semibold text-black">Add users</h4>
              <p className="text-[12.5px] font-normal text-[#303030]">
                Assign which members that will use the same permissions.
              </p>
            </div>
            <div>
              <div className="relative flex-1 h-full overflow-y-auto overflow-x-hiddeon">
                <table className="w-full overflow-auto text-xs text-left text-gray-900 border rounded-md">
                  <thead className="text-gray-800 capitalize bg-gray-50">
                    <tr className="text-xs text-left capitalize">
                      <th scope="col" className="py-3 text-center sm:px-1">
                        <input
                          type="checkbox"
                          checked={checkedList.length === 6}
                          onChange={() => {
                            let newArray: number[] = [1, 2, 3, 4, 5, 6];

                            if (checkedList.length === 6) {
                              newArray = [];
                            }

                            setCheckedList(newArray);
                          }}
                          className="w-4 h-4 bg-white border border-black rounded-lg cursor-pointer accent-white checked:bg-white checked:border-black"
                        />
                      </th>
                      <th
                        scope="col"
                        className="hidden px-6 py-3 sm:table-cell"
                      >
                        User Name
                      </th>
                      <th scope="col" className="px-2 py-3 sm:px-6">
                        Email
                      </th>
                      <th scope="col" className="px-2 py-3 sm:px-6">
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="hidden px-6 py-3 normal-case sm:table-cell"
                      >
                        {"Group(s)"}
                      </th>
                      <th
                        scope="col"
                        className="hidden px-6 py-3 sm:table-cell"
                      >
                        Last active
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-xs">
                    {[1, 2, 3, 4, 5, 6].map((review: any, index: number) => (
                      <tr className={classNames("border-b")} key={index}>
                        <td
                          scope="row"
                          className="px-1 py-2 text-center sm:px-6"
                        >
                          <input
                            type="checkbox"
                            checked={checkedList.includes(index + 1)}
                            onChange={() => {
                              let newArray: number[] = checkedList;

                              if (checkedList.includes(index + 1)) {
                                newArray = newArray.filter(
                                  (item) => item !== index + 1
                                );
                              } else {
                                newArray = [...newArray, index + 1];
                              }

                              setCheckedList(newArray);
                            }}
                            className="w-4 h-4 border border-black rounded-lg cursor-pointer accent-white checked:bg-white checked:border-black"
                          />
                        </td>
                        <td className="hidden px-6 py-2 border-x sm:table-cell">
                          <p className="text-xs">{"Chidinma Okonkwo"}</p>
                        </td>
                        <td className="hidden px-6 py-2 border-x sm:table-cell">
                          <p className="text-xs">
                            {"chidinmaokonkwogmail.com"}
                          </p>
                        </td>
                        <td className="hidden px-6 py-2 border-x sm:table-cell">
                          <p className="text-xs">{"0801 234 567"}</p>
                        </td>
                        <td className="hidden px-6 py-2 border-x sm:table-cell">
                          <p className="text-xs">{"Marketing-team"}</p>
                        </td>
                        <td className="hidden px-6 py-2 border-r sm:table-cell">
                          {"2 months ago"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {level === 1 && (
          <>
            <div className="">
              <h4 className="text-lg font-bold text-black">
                Assign permissions{" "}
                <span className="italic font-medium">- optional</span>
              </h4>
              <p className="text-sm font-normal text-[#303030]">
                Assign key permissions for the group members.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-[750px]">
              {[0, 1].map((item: number, index: number) => (
                <div
                  key={index}
                  onClick={() => setActive(item)}
                  className={classNames(
                    "group cursor-pointer px-4 py-3 flex items-start space-x-4 border rounded",
                    active === item
                      ? "border-primary bg-primary/5"
                      : "border-gray-300"
                  )}
                >
                  <div className="py-3">
                    <input
                      type="radio"
                      checked={active === item}
                      className="w-4 h-4 border border-black accent-primary"
                    />
                  </div>
                  <div className="grow">
                    <h4 className="text-sm font-semibold text-black mb-1">
                      {index === 0
                        ? "Create new permissions"
                        : "Assign created role"}
                    </h4>
                    <p className="text-[12.5px] font-normal text-[#303030]">
                      {index === 0
                        ? "Create new permissions for this group. everyone in this group will have equal permission level."
                        : "Assign permissions that have already been created to this group."}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div>
              {active === 0 && (
                <div className="relative flex-1 h-full space-y-10">
                  <div>
                    <h4 className="mb-2 text-sm font-semibold text-black">
                      Mobile application permissions
                    </h4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                      {APP_PERMISSIONS.MOBILE.map(
                        (item: string, index: number) => (
                          <div key={index}>
                            <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                              <input
                                type="checkbox"
                                checked
                                className="w-3 h-3 border border-black rounded accent-primary"
                              />
                              <span>{item}</span>
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <h4 className="mb-2 text-sm font-semibold text-black">
                      Feedback permissions
                    </h4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                      {APP_PERMISSIONS.FEEDBACK.map(
                        (item: string, index: number) => (
                          <div key={index}>
                            <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                              <input
                                type="checkbox"
                                checked
                                className="w-3 h-3 border border-black rounded accent-primary"
                              />
                              <span>{item}</span>
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <h4 className="mb-2 text-sm font-semibold text-black">
                      Customer reward permissions
                    </h4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                      {APP_PERMISSIONS.REWARD.map(
                        (item: string, index: number) => (
                          <div key={index}>
                            <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                              <input
                                type="checkbox"
                                checked
                                className="w-3 h-3 border border-black rounded accent-primary"
                              />
                              <span>{item}</span>
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <h4 className="mb-2 text-sm font-semibold text-black">
                      Message permissions
                    </h4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 place-items-start">
                      {APP_PERMISSIONS.MESSAGE.map(
                        (item: string, index: number) => (
                          <div key={index}>
                            <label className="text-[12.5px] font-normal text-black cursor-pointer space-x-1.5 flex items-center">
                              <input
                                type="checkbox"
                                checked
                                className="w-3 h-3 border border-black rounded accent-primary"
                              />
                              <span>{item}</span>
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
              {active === 1 && (
                <div className="relative flex-1 h-full overflow-x-hidden overflow-y-auto">
                  <table className="w-full overflow-auto text-xs text-left text-gray-900 border rounded-md">
                    <thead className="text-gray-800 capitalize bg-gray-50">
                      <tr className="text-xs text-left capitalize">
                        <th
                          scope="col"
                          className="py-3 text-center sm:px-1"
                        ></th>
                        <th
                          scope="col"
                          className="hidden px-6 py-3 sm:table-cell"
                        >
                          Role Name
                        </th>
                        <th scope="col" className="px-2 py-3 sm:px-6">
                          Permissions
                        </th>
                        <th
                          scope="col"
                          className="hidden px-6 py-3 normal-case sm:table-cell"
                        >
                          {"User(s)"}
                        </th>
                        <th
                          scope="col"
                          className="hidden px-6 py-3 normal-case sm:table-cell"
                        >
                          {"Group(s)"}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-xs">
                      {[1, 2, 3, 4, 5, 6].map((review: any, index: number) => (
                        <tr className={classNames("border-b")} key={index}>
                          <td
                            scope="row"
                            className="px-1 py-2 text-center sm:px-6"
                          >
                            <input
                              type="checkbox"
                              checked={checkedList.includes(index + 1)}
                              onChange={() => {
                                let newArray: number[] = checkedList;

                                if (checkedList.includes(index + 1)) {
                                  newArray = newArray.filter(
                                    (item) => item !== index + 1
                                  );
                                } else {
                                  newArray = [...newArray, index + 1];
                                }

                                setCheckedList(newArray);
                              }}
                              className="w-4 h-4 border border-black rounded-lg cursor-pointer accent-white checked:bg-white checked:border-black"
                            />
                          </td>
                          <td className="hidden px-6 py-2 border-x sm:table-cell">
                            <p className="text-xs">{"Marketing-team"}</p>
                          </td>
                          <td className="relative px-2 py-2 cursor-pointer sm:px-6 border-x group">
                            <div className="text-xs cursor-pointer">
                              {"edit mobile app settings, view pro..."}
                            </div>
                            <div className="absolute top-0 z-[200] hidden w-56 pt-6 origin-top-right group-hover:block">
                              <div className="bg-white rounded-xl shadow-gray-200 shadow-md p-3.5 w-full space-y-2 text-xs">
                                <p className="">Can edit products & webpage</p>
                                <p className="">Can edit orders</p>
                                <p className="">Can edit wallets</p>
                                <p className="">Can edit security</p>
                                <p className="">Can edit store information</p>
                                <p className="">Can edit delivery</p>
                                <p className="">Can edit analytics</p>
                                <p className="">Can edit payment information</p>
                                <p className="">Can edit reviews</p>
                              </div>
                            </div>
                          </td>
                          <td className="relative px-2 py-2 cursor-pointer sm:px-6 border-x group">
                            <div className="text-xs cursor-pointer">{"15"}</div>
                            <div className="absolute top-0 z-50 hidden w-40 pt-6 origin-top-right group-hover:block -left-16">
                              <div className="bg-white rounded-xl shadow-gray-200 shadow-md p-3.5 w-full space-y-2">
                                <p className="text-xs">Stephen Joseph</p>
                                <p className="text-xs">Joshua Ubiri</p>
                              </div>
                            </div>
                          </td>
                          <td className="relative px-2 py-2 cursor-pointer sm:px-6 border-x group">
                            <div className="text-xs cursor-pointer">{"16"}</div>
                            <div className="absolute top-0 z-50 hidden w-40 pt-6 origin-top-right group-hover:block -left-16">
                              <div className="bg-white rounded-xl shadow-gray-200 shadow-md p-3.5 w-full space-y-2">
                                <p className="text-xs">Stephen Joseph</p>
                                <p className="text-xs">Joshua Ubiri</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </>
        )}
        <div className="flex items-center justify-end space-x-2.5">
          <button
            onClick={() => {
              if (level !== 0) {
                setLevel(level - 1);
              }
            }}
            disabled={false}
            className="py-1.5 text-xs w-12 font-semibold text-center text-primary border border-primary capitalize rounded-md bg-transparent disabled:opacity-60"
          >
            back
          </button>
          <button
            onClick={() => setLevel(level + 1)}
            disabled={false}
            className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
          >
            {level === 0 ? "next" : "finish"}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateGroupAccessPage;
