/* eslint-disable array-callback-return */

import { useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import {
  getCustomerSatisfactionScore,
  refreshSatisfactionScore,
} from "../../../../api/Utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SkeletonLoader from "../../SkeletonLoader";
import { getPlatformRatings } from "../../../../api/Ratings";
import { PlatformRatingModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { ButtonActive } from "../../../../enums";
import { getSvg } from "../../../../utils";

const CustomerSatisfactionComponentNew = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  const [active, setActive] = useState<ButtonActive>(ButtonActive.Sentiment);
  const toggleActive = (value: ButtonActive) => {
    setActive(value);
  };

  const queryClient = useQueryClient();

  const chartRef = useRef(HTMLDivElement.prototype);

  const { status, data, error } = useQuery<any, Error>({
    queryKey: ["getCustomerSatisfactionScore"],
    queryFn: async () => {
      const payload = {
        platformKey: {
          account_reference: { S: queryKey },
        },
        pagination_key: "",
        timestamp: ["0.00", `${new Date(Date.now()).getTime() / 1000}`],
        commentSource: "NONE",
        commentLabel: "No Data",
      };

      const response = await getCustomerSatisfactionScore(payload);
      return response.data;
    },
  });

  const plRatings = useQuery<PlatformRatingModel, Error>({
    queryKey: ["getPlatformRatings"],
    queryFn: async () => {
      const response = await getPlatformRatings(`${queryKey}`);
      return response.data;
    },
  });

  const handleRefresh = useMutation({
    mutationFn: async (data) => {
      return await refreshSatisfactionScore(queryKey, "");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getCustomerSatisfactionScorePaginated"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getCustomerSatisfactionScore"],
      });
      queryClient.invalidateQueries({ queryKey: ["getPlatformRatings"] });
    },
  });

  const ratingsPercent = (data: number) => {
    return (data / 5) * 100;
  };

  useEffect(() => {
    if (!data || !plRatings.data) {
      return;
    }

    let chartData: any = {
      sentiments: {
        categories: [0],
      },
      ratings: {
        categories: [0],
      },
    };

    if ("Satisfaction_Score" in data) {
      chartData = {
        ...chartData,
        sentiments: {
          categories: [
            parseInt(data?.Satisfaction_Score.toString().replace("%", "")),
          ],
        },
      };
    }

    if ("average_ratings" in plRatings.data) {
      chartData = {
        ...chartData,
        ratings: {
          categories: [ratingsPercent(Number(plRatings.data?.average_ratings))],
        },
      };
    }

    const getEmoji = (data: number) => {
      let value = "emojis/frowning-face";

      if (data >= 0 && data <= 25) value = "emojis/frowning-face";
      if (data >= 25 && data <= 50) value = "emojis/slightly-smiling-face";
      if (data >= 50 && data <= 75)
        value = "emojis/beaming-face-with-smiling-eyes";
      if (data >= 75 && data <= 100)
        value = "emojis/grinning-face-with-big-eyes";

      return value;
    };

    // const satisfaction_score = (data.Satisfaction_Score) ? parseFloat(data.Satisfaction_Score.toString().replace("%", "")) : 0.00;

    let options = {
      chart: {
        type: "radialBar",
        width: "100%",
        height: 120,
        // offsetY: -20,
      },
      colors: [chartData[active].categories[0] >= 50 ? "#3DB832" : "#DEDEDE"],
      series: chartData[active].categories,
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radialBar: {
          startAngle: -95,
          endAngle: 95,
          hollow: {
            margin: 15,
            size: "55%",
            // image: `/media/svg/${(getEmoji(chartData[active].categories[0]))}.svg`,
            image: getSvg(getEmoji(chartData[active].categories[0])),
            imageWidth: 25,
            imageHeight: 25,
            imageClipped: false,
            imageOffsetY: -5,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: 30,
              fontSize: "16px",
              color: "#000",
              formatter: function (val: string) {
                return `${parseInt(val).toFixed(0)}%`;
              },
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      grid: {
        padding: {
          top: 10,
        },
      },
    };

    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, active, plRatings.data]);

  if (status === "loading")
    return <SkeletonLoader className="w-full h-full rounded-md" />;

  if (status === "error") return <span>Error: {error.message}</span>;

  if (plRatings.status === "loading")
    return <SkeletonLoader className="w-full h-full rounded-md" />;

  if (plRatings.status === "error")
    return <span>Error: {plRatings.error.message}</span>;

  // console.log(data);

  return (
    <div className="text-gray-800 relative bg-gray-50/70 p-2 rounded-lg text-sm border border-gray-100 flex flex-col h-full"> 
      <div className="space-y-0.5">
      <p className="font-semibold">Satisfaction tracker</p>
      <p className="text-2xl font-semibold">{data?.Satisfaction_Score}</p>
      <p className="text-xs">+7% (12 people) more since last month</p>
      </div>
      <div className="block overflow-hidden flex-1">
        <div className="mx-auto" >
          <div
            ref={chartRef}
            id="cs-chart"
            className="scale-110 2xl:scale-125"
          ></div>
        </div>
      </div>
      <p className="text-[11px] mt-auto">
        There is an 82% level of satisfaction in the last 30 days which
        indicates ths customers are very happy.
      </p>
    </div>
  );
};

export default CustomerSatisfactionComponentNew;
