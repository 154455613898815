import { ReviewTrackerModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { useEffect, useRef } from "react";
import { getReviewTracker } from "../../../../api/Reviews";
import { calcPercentage } from "../../../../utils";
import ApexCharts from "apexcharts";
import { useQuery } from "@tanstack/react-query";
import SkeletonLoader from "../../SkeletonLoader";
import { getCustomerSatisfactionScore } from "../../../../api/Utils";



const CommentTracker = () => {
  const chartRef = useRef(HTMLDivElement.prototype);
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;

  const { status, data, error } = useQuery<ReviewTrackerModel, Error>({
    queryKey: ["getReviewTracker"],
    queryFn: async () => {
      const response = await getReviewTracker(queryKey);
      return response.data;
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    let site_data = calcPercentage(data.count_per_source?.site_data ?? 0, data.total_uploaded_comment ?? 0);
    let linkedin = calcPercentage(data.count_per_source?.linkedin ?? 0, data.total_uploaded_comment ?? 0);
    let twitter = calcPercentage(data.count_per_source?.twitter ?? 0, data.total_uploaded_comment ?? 0);
    let instagram = calcPercentage(data.count_per_source?.instagram ?? 0, data.total_uploaded_comment ?? 0);
    let voice = calcPercentage(data.count_per_source?.voice ?? 0, data.total_uploaded_comment ?? 0);
    let sms = calcPercentage(data.count_per_source?.sms ?? 0, data.total_uploaded_comment ?? 0);
    let facebook = calcPercentage(data.count_per_source?.facebook ?? 0, data.total_uploaded_comment ?? 0);

    let options = {
      chart: {
        type: "bar",
        width: "100%",
        height: 135,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
      },
      colors: ["#FFFFFF"],
      series: [
        {
          name: "Comments",
          data: [site_data, linkedin, twitter, instagram, voice, sms, facebook],
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "27%",
          endingShape: "rounded",
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "\uF7D4",
          "\uF7D6",
          "\uF7D5",
          "\uF7D4",
          "\uF7D6",
          "\uF7D5",
          "\uF7D4",
        ],
        labels: {
          show: false,
        },
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: 100,
        labels: {
          style: {
            colors: ["#EEEEEE"],
          },
          formatter: (value: any) => {
            return `${value as string}%`;
          },
        },
      },
    };
    
    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    }

  }, [data]);


  const reviewTracker = useQuery<any, Error>({
    queryKey: ["getCustomerSatisfactionScore"],
    queryFn: async () => {
      const payload = {
        platformKey: {
          account_reference: { S: queryKey },
        },
        pagination_key: "",
        timestamp: ["0.00", `${(new Date(Date.now()).getTime() / 1000)}`],
        commentSource: "NONE",
        commentLabel: "No Data",
      };

      const response = await getCustomerSatisfactionScore(payload);
      return response.data;
    },
  });

  if (reviewTracker.status === "loading") return <SkeletonLoader className="rounded-md" />;

  if (reviewTracker.status === "error") return <span>Error: {reviewTracker.error.message}</span>;
  

  if (status === 'loading') return <SkeletonLoader className="h-full rounded-md" />;

  if (status === 'error') return <span>Error: {error.message}</span>;
  // console.log(reviewTracker.data)

  return (
    <div className="flex flex-col h-full gap-1 p-2 pb-4 overflow-hidden rounded-md shadow-md text-gray-50 bg-gradient-secondary">
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-xs font-semibold lg:text-sm">Review tracker</h4>
        <div className="flex items-center gap-3">
          {/* <h2 className="text-2xl text-white">{data.total_uploaded_comment ?? 0}</h2> */}
          <h2 className="text-lg text-white lg:text-xl">{reviewTracker.data.Total_Review ?? 0}</h2>
          <div className="inline-flex flex-col lg:flex-row gap-1 px-1 py-0.5 text-xs bg-white border border-gray-200 rounded-md">
            <button className="px-1 py-1 rounded-l rounded-r lg:rounded-r-none text-primary bg-gray-50">
              Monthly
            </button>
            <button className="px-1 py-1 bg-transparent rounded-l text-primary">
              Weekly
            </button>
          </div>
        </div>
      </div>
      <div className="relative flex-1 pb-2 text-black">
        <div ref={chartRef} id="comment-tracker-chart" className="-mt-6 -mb-4"></div>
        <div className="grid grid-cols-7 absolute -bottom-1.5 right-2 w-4/5 pl-2">
          <img src="/media/svg/V1.svg" alt="img" className="w-[23px]" />
          <img src="/media/svg/V2.svg" alt="img" className="w-[23px] opacity-40" />
          <img src="/media/svg/V3.svg" alt="img" className="w-[23px] opacity-40" />
          <img src="/media/svg/V4.svg" alt="img" className="w-[23px] opacity-40" />
          <img src="/media/svg/V5.svg" alt="img" className="w-[23px] opacity-40" />
          <img src="/media/svg/V6.svg" alt="img" className="w-[23px] opacity-40" />
          <img src="/media/svg/V7.svg" alt="img" className="w-[23px] opacity-40" />
        </div>
      </div>
    </div>
  );
};

export default CommentTracker;
