import { useState } from "react";
import HeroSection from "./components/HeroSection";
import AddSections from "./components/AddSections";
import FooterSection from "./components/FooterSection";
import ServicesSection from "./components/ServicesSection";
import ReviewsSection from "./components/ReviewsSection";
import HeroSidePanel from "./components/HeroSection/SidePanel";
import ProductsSidePanel from "./components/ProductsSection/SidePanel";
import ServicesSidePanel from "./components/ServicesSection/SidePanel";
import ProductsSection from "./components/ProductsSection";
import ReviewsSidePanel from "./components/ReviewsSection/SidePanel";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../../hooks";
import { StoreModel, UserProfileModel } from "../../../../../models";
import SocialsSidePanel from "./components/FooterSection/SidePanel";
import { ImSpinner2 } from "react-icons/im";
import {
  getTemplateSections,
  updateTemplateSection,
} from "../../../../../api/Menu";
import Modal from "../../../../../components/Dashboard/Modal";
import { uploadWebUrl } from "../../../../../api/Utils";
import { getSvg } from "../../../../../utils";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AboutUsSection from "./components/AboutUsSection";
import AboutUsSidePanel from "./components/AboutUsSection/SidePanel";

type SidePanelTypes =
  | "products"
  | "reviews"
  | "contact"
  | "services"
  | "hero"
  | "aboutus"
  | "socials";

const NewEstore = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = `${store.name}`;
  const text = `${window.location.origin}/store${storeKey}`;
  const queryKey = `${user.queryKey}`;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [productSidePanelStep, setProductSidePanelStep] = useState(1);
  const [step, setStep] = useState(5);
  const [copied, setCopied] = useState(false);

  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishedModal, setShowPublishedModal] = useState(false);
  let templateSections: any[] = [];

  const [sidePanel, setSidePanel] = useState<SidePanelTypes | null>("hero");

  const handleUpdateTemplateSection = useMutation<any, Error>({
    mutationFn: async (data: any) => {
      return await updateTemplateSection(queryKey, data.sectionVar, "0");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getStoreTemplateSections"] });
      setShowDeleteModal(false);
      setSelectedSection(null);
      setSidePanel("hero");
    },
  });

  const loadTemplateSections = useQuery<any, Error>({
    queryKey: ["getStoreTemplateSections"],
    queryFn: async () => {
      const response = await getTemplateSections(storeKey);
      return response.data;
    },
  });

  const handlePublishWebPage = useMutation<any, Error>({
    mutationFn: async () => {
      const data = {
        platformKey: queryKey.replaceAll("%26", "&"),
        webLink: "",
      };
      return await uploadWebUrl(data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["downloadWebUrl"]);
      setShowPublishedModal(true);
    },
  });

  if (loadTemplateSections.data) {
    templateSections = loadTemplateSections.data.Template_Sections;
  }

  return (
    <>
      <Modal
        show={showDeleteModal}
        onToggle={() => {
          setShowDeleteModal(false);
          setSelectedSection(null);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700 md:text-xl">
              Would you like to remove this section?
            </p>
          </div>
          <div className="flex flex-col justify-center gap-2 mt-5 md:flex-row md:mt-8">
            <div>
              <button
                className="w-full px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 border rounded-md hover:bg-blue-800 md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  const payload: any = {
                    sectionVar: selectedSection,
                  };

                  handleUpdateTemplateSection.mutate(payload);
                }}
              >
                {handleUpdateTemplateSection.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="w-full px-4 py-2 text-sm font-semibold text-center text-blue-700 bg-white border border-blue-700 rounded-md hover:bg-gray-50 md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedSection(null);
                  setShowDeleteModal(false);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        size="medium"
        show={showPublishedModal}
        onToggle={() => {
          setShowPublishedModal(false);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="text-center">
            <img src={getSvg("check")} alt="icon" className="w-24 mx-auto" />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-2xl">
              Your store has been created successfully
            </h1>
          </div>
          <div className="flex flex-col justify-center gap-2 mt-5 md:flex-row md:mt-8">
            <button
              className="w-full px-4 py-2 text-sm font-semibold text-center text-blue-600 bg-white border border-blue-700 rounded-md hover:bg-gray-50 md:w-44 md:px-6 lg:px-8"
              onClick={() => {
                navigator.clipboard.writeText(text);
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 4000);
              }}
            >
              Copy store link
            </button>
            <button
              type="button"
              className="w-full px-4 py-2 text-sm font-semibold text-center text-white bg-blue-700 border rounded-md hover:bg-blue-800 border-primary md:w-44 md:px-6 lg:px-8"
              onClick={() => {
                setShowPublishedModal(false);
                window.open(`/store${storeKey}`);
              }}
            >
              View Store
            </button>
          </div>
          {copied && (
            <p className="mt-2 text-sm text-center text-green-700">
              Store link copied to clipboard
            </p>
          )}
        </div>
      </Modal>
      <div className="">
        <div className="relative grid grid-cols-7 gap-8">
          <div className="col-span-5">
            <div className="flex items-center justify-between mb-2">
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => {
                  navigate("/home/echo-webpage");
                }}
              >
                <MdOutlineKeyboardBackspace size={22} />
                <h4 className="text-lg font-semibold text-gray-800 capitalize">
                  Back
                </h4>
              </div>
              <div className="flex items-center gap-4">
                <button
                  className="px-2.5 py-1.5 text-sm text-blue-600 font-semibold rounded bg-white hover:bg-gray-50 border border-blue-700"
                  onClick={() => {
                    window.open(`/store${storeKey}`);
                  }}
                >
                  View store
                </button>
                <button
                  className="px-6 py-2 text-sm font-semibold text-white bg-blue-700 rounded hover:bg-blue-800"
                  onClick={() => {
                    handlePublishWebPage.mutate();
                  }}
                >
                  {handlePublishWebPage.isLoading ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Publish"
                  )}
                </button>
              </div>
            </div>
            <HeroSection setSidePanel={setSidePanel} sidePanel={sidePanel} />
            <br />
            <AddSections setSidePanel={setSidePanel} sidePanel={sidePanel} />
            <br />
            <ProductsSection
              setSidePanel={setSidePanel}
              sidePanel={sidePanel}
              setSelectedSection={setSelectedSection}
              setShowDeleteModal={setShowDeleteModal}
              setProductSidePanelStep={setProductSidePanelStep}
              setStep={setStep}
              templateSections={templateSections}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
            />
            <ServicesSection
              setSidePanel={setSidePanel}
              sidePanel={sidePanel}
              setSelectedSection={setSelectedSection}
              setShowDeleteModal={setShowDeleteModal}
              templateSections={templateSections}
            />
            <AboutUsSection
              setSidePanel={setSidePanel}
              sidePanel={sidePanel}
              setSelectedSection={setSelectedSection}
              setShowDeleteModal={setShowDeleteModal}
              templateSections={templateSections}
              showModals={undefined}
              setShowModals={undefined}
            />
            <ReviewsSection
              setSidePanel={setSidePanel}
              sidePanel={sidePanel}
              setSelectedSection={setSelectedSection}
              setShowDeleteModal={setShowDeleteModal}
              templateSections={templateSections}
            />
            {/* <ContactSection
              setSidePanel={setSidePanel}
              sidePanel={sidePanel}
              setSelectedSection={setSelectedSection}
              setShowDeleteModal={setShowDeleteModal}
              templateSections={templateSections}
            /> */}
            <FooterSection
              setSidePanel={setSidePanel}
              sidePanel={sidePanel}
              setShowModals={undefined}
              showModals={undefined}
              formik5={undefined}
              setSelectedObject={undefined}
              setOpeningDaysFields={undefined}
              formik3={undefined}
            />
          </div>
          <div className="col-span-2 bg-white fixed top-22 w-[20%] md:right-4 lg:right-8 h-[82vh] overflow-y-auto">
            <div className="w-full">
              {sidePanel === "hero" && <HeroSidePanel />}
              {sidePanel === "products" && (
                <ProductsSidePanel
                  productSidePanelStep={productSidePanelStep}
                  setProductSidePanelStep={setProductSidePanelStep}
                  step={step}
                  setStep={setStep}
                  activeCategory={activeCategory}
                  setActiveCategory={setActiveCategory}
                />
              )}
              {sidePanel === "services" && <ServicesSidePanel />}
              {/* {sidePanel === "contact" && <ContactSidePanel />} */}
              {sidePanel === "aboutus" && (
                <AboutUsSidePanel
                  setShowModals={undefined}
                  showModals={undefined}
                />
              )}
              {sidePanel === "reviews" && <ReviewsSidePanel />}
              {sidePanel === "socials" && <SocialsSidePanel />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewEstore;
