import React, { useState, Fragment } from "react";
import { StoreModel, UserProfileModel } from "../../../../../../models";
import { useAppSelector } from "../../../../../../hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MdKeyboardBackspace } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { postAccountDetail } from "../../../../../../api/AccountDetails";
import OTPInput from "../../../../../../components/Auth/Forms/OTPInput";
import { FiChevronRight } from "react-icons/fi";
import OTPInput4 from "../../../../../../components/Auth/Forms/OTPInput/OTPInput4";
import {
  confirmPasscode,
  downloadPinEmbedder,
  handleGeneratePin,
  loadPaymentDetails,
  resetPin,
  viewWalletPin,
  viewWalletPinKey,
} from "../../../../../../api/Menu";
import { ImSpinner2 } from "react-icons/im";
import { base64Decode } from "../../../../../../utils";
import { IoMdMore } from "react-icons/io";
import { Menu, Transition } from "@headlessui/react";
import toast, { Toaster } from "react-hot-toast";

const paymentDetailsValues = {
  bankName: "",
  accountName: "",
  accountNumber: "",
};

const paymentDetailsSchema = Yup.object().shape({
  bankName: Yup.string()
    .min(2, "Minimum 2 letters")
    .max(50, "Maximum 50 letters")
    .required("Bank Name is required"),
  accountName: Yup.string(),
  accountNumber: Yup.string(),
  // .min(8, "Minimum 8 digits")
  // .max(10, "Maximum 10 digits")
  // .required("Account Number is required"),
});

const EstorePaymentInformation = () => {
  const [step, setStep] = useState(1);
  const queryClient = useQueryClient();
  const [code, setCode] = useState("");
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [showPinKey, setShowPinKey] = useState(true);
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name || "";
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const platformKey1 = `${user.platformAccountId}%26${user.email}`;
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const [loading, setLoading] = useState(false);
  let securityToken: string = "";
  let pinKey: string = "";
  let t = `b'eydQT1NfMSc6IDI4NywgJ1BPU18yJzogMTIxLCAnUE9TXzMnOiAzOTEsICdQT1NfNCc6IDE3Nn0='`;
  const ts = t.substring(2).slice(0, -1);

  let paymentDetails: any[] = [];
  let pinEmbedderObj: any;
  let position: any;

  // console.log(code1);
  const handleChange = (value: string) => {
    setCode(value);
  };
  const handleChange1 = (value: string) => {
    // console.log(value);
    setCode1(value);
  };
  const handleChange2 = (value: string) => {
    // console.log(value);
    setCode2(value);
  };
  const handleChange3 = (value: string) => {
    // console.log(value);
    setCode3(value);
  };

  const handlePaymentDetails = useQuery<any, Error>({
    queryKey: ["getPaymentDetails"],
    // queryFn: loadTransactions3,
    queryFn: async () => {
      const response = await loadPaymentDetails(storeKey, apiKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
    },
  });

  const formik = useFormik({
    initialValues: paymentDetailsValues,
    validationSchema: paymentDetailsSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const payload = {
        platformKey,
        // paymentInfo: [
        //   {
        //     accountNumber: values.accountNumber.toString(),
        //     accountName: values.accountName,
        //     bankName: values.bankName,
        //     id: uuidv4(),
        //   },
        // ],
        paymentInfo: [],
        updateFlag: "update",
      };

      try {
        postAccountDetail(payload, apiKey);
        setTimeout(() => {
          setSubmitting(false);
          resetForm({});
          queryClient.invalidateQueries({ queryKey: ["getPaymentDetails"] });
        }, 3000);
        toast.success("Account details updated successfully");
        setStep(1);
      } catch (err) {
        setSubmitting(false);
        // console.log(err);
      }
      //   setSubmitting(false);
    },
  });

  const handleConfirmPasscode = useMutation({
    mutationFn: async (data) => {
      return await confirmPasscode(platformKey1, code);
    },
    onSuccess: (data) => {
      console.log(data.data);
      if (data.data.request_challenge === "failed") {
        toast.error("Wrong passcode");
        setStep(1);
      }
      if (data.data.request_challenge === "success") {
        // alert("Wrong passcode");
        setStep(2);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleConfirmPasscode1 = useMutation({
    mutationFn: async (data) => {
      return await confirmPasscode(platformKey1, code);
    },
    onSuccess: (data) => {
      console.log(data.data);
      if (data.data.request_challenge === "failed") {
        // alert("Wrong passcode");
        toast.error("Wrong passcode");
        setStep(1);
      }
      if (data.data.request_challenge === "success") {
        // alert("Wrong passcode");
        setStep(3);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleResetPin = useMutation({
    mutationFn: async (data) => {
      const payload = {
        platformKey,
        newToken: code3,
      };
      return await resetPin(payload, code2);
    },
    onSuccess: (data) => {
      console.log(data);
      alert("Pin reset successful");
      // if (data.data.request_challenge === "failed") {
      //   alert("Wrong passcode");
      //   setStep(1);
      // }
      // if (data.data.request_challenge === "success") {
      //   // alert("Wrong passcode");
      //   setStep(2);
      // }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  if (handlePaymentDetails.data) {
    paymentDetails = handlePaymentDetails.data.Payment_Details || [];
  }

  const handlePinEmbedder = useQuery<any, Error>({
    queryKey: ["getPinEmbedder"],
    queryFn: async () => {
      const response = await downloadPinEmbedder(platformKey1);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
      // if (data.key_state === "none") {
      //   setShowModal1(true);
      // }
    },
  });

  const handleViewWalletPin = useQuery<any, Error>({
    queryKey: ["getWalletPin"],
    queryFn: async () => {
      const response = await viewWalletPin(platformKey1);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
      // if (data.key_state === "none") {
      //   setShowModal1(true);
      // }
    },
  });

  const handleViewWalletPinKey = useQuery<any, Error>({
    queryKey: ["getWalletPinKey"],
    queryFn: async () => {
      const response = await viewWalletPinKey(platformKey1);
      return response.headers;
    },
    onSuccess: (data) => {
      console.log(data);
      // if (data.key_state === "none") {
      //   setShowModal1(true);
      // }
    },
  });

  if (handleViewWalletPin.data) {
    securityToken = handleViewWalletPin.data.securityToken;
  }

  if (handleViewWalletPinKey.data) {
    const a = base64Decode(ts);
    const c = a.replaceAll("'", '"');
    let b = JSON.parse(c);
    position = b;
    // console.log(position);
    const positions = [b.POS_1, b.POS_2, b.POS_3, b.POS_4];
    // console.log(positions);
    // console.log(securityToken);
    pinKey =
      securityToken[positions[0]] +
      securityToken[positions[1]] +
      securityToken[positions[2]] +
      securityToken[positions[3]];
    // console.log(pinKey);
  }

  if (handlePinEmbedder.data) {
    pinEmbedderObj = handlePinEmbedder.data;
    // console.log(pinEmbedderObj);
    if (handlePinEmbedder.data.key_state === "none") {
      const a = base64Decode(pinEmbedderObj.decryption_key);
      const c = a.replaceAll("'", '"');
      let b = JSON.parse(c);

      position = b;
    }
    // console.log(pinEmbedderObj)
  }

  function replaceAtPositions(str: string, positions: any, replacements: any) {
    // Check if the length of positions and replacements arrays match
    if (positions.length !== replacements.length) {
      throw new Error(
        "Positions and replacements arrays must have the same length"
      );
    }

    // Loop through each position and replacement
    for (let i = 0; i < positions.length; i++) {
      const position = positions[i];
      const replacement = replacements[i];

      // Check if position is within the string length
      if (position < 0 || position >= str.length) {
        console.warn(`Skipping invalid position: ${position}`);
        continue;
      }

      // Create a new string with the replacement
      str =
        str.substring(0, position) + replacement + str.substring(position + 1);
    }

    return str;
  }

  const handleSubmitPin = async () => {
    setLoading(true);
    // if (code !== code1) {
    //   alert("You inputed a wrong code");
    //   setLoading(false);
    //   return;
    // }

    const p1 = position.POS_1;
    const p2 = position.POS_2;
    const p3 = position.POS_3;
    const p4 = position.POS_4;

    const positionss = [p1, p2, p3, p4];

    const replacements = code1.split("");
    // console.log(positionss);
    // console.log(replacements);

    let s = replaceAtPositions(
      pinEmbedderObj.embedding_token,
      positionss,
      replacements
    );
    // console.log(s);

    const payload = {
      platformKey: platformKey,
      encryptString: [s],
    };

    // console.log(payload);

    try {
      const response = await handleGeneratePin(payload);
      setLoading(false);
      alert("Pin updated successfully");
      setStep(6);
      console.log(response);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      {step === 1 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800 cursor-pointer"
            onClick={() => {
              formik.setValues({
                accountName: paymentDetails[0]?.accountName || "",
                accountNumber: paymentDetails[0]?.accountNumber || "",
                bankName: paymentDetails[0]?.bankName || "",
              });
              setStep(2);
            }}
          >
            Payment information
          </p>
          <div className="mt-4">
            <p className="text-blue-700">Bank details</p>
            <div className="flex items-center justify-between mt-4">
              <div className="text-sm space-y-1 ">
                <p>Beneficiary name</p>
                <p className="text-gray-600">
                  {!(paymentDetails.length < 1)
                    ? paymentDetails[0]?.accountName
                    : ""}
                  {/* {paymentDetails[0]?.accountName || ""} */}
                </p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  formik.setValues({
                    accountName: paymentDetails[0]?.accountName || "",
                    accountNumber: paymentDetails[0]?.accountNumber || "",
                    bankName: paymentDetails[0]?.bankName || "",
                  });
                  setStep(4);
                }}
              />
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className="text-sm space-y-1">
                <p>Bank name</p>
                <p className="text-gray-600">
                  {!(paymentDetails.length < 1)
                    ? paymentDetails[0]?.bankName
                    : ""}
                  {/* {paymentDetails[0]?.bankName || ""} */}
                </p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  formik.setValues({
                    accountName: paymentDetails[0]?.accountName || "",
                    accountNumber: paymentDetails[0]?.accountNumber || "",
                    bankName: paymentDetails[0]?.bankName || "",
                  });
                  setStep(4);
                }}
              />
            </div>

            <div className="flex items-center justify-between mt-4">
              <div className="text-sm space-y-1">
                <p>Bank number</p>
                <p className="text-gray-600">
                  {!(paymentDetails.length < 1)
                    ? paymentDetails[0]?.accountNumber
                    : ""}
                  {/* {paymentDetails[0]?.accountNumber || ""} */}
                </p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  formik.setValues({
                    accountName: paymentDetails[0]?.accountName || "",
                    accountNumber: paymentDetails[0]?.accountNumber || "",
                    bankName: paymentDetails[0]?.bankName || "",
                  });
                  setStep(4);
                }}
              />
            </div>

            <div className="text-sm space-y-1 mt-4">
              <p>Bank information certificate</p>
              <p className="text-gray-600 bg-red-200 inline p-0.5">
                Coming soon
              </p>
            </div>
            <div className="text-sm space-y-1 mt-4">
              <p>BVN</p>
              <p className="text-gray-600 bg-red-200 inline p-0.5">
                Coming soon
              </p>
            </div>
            <div className="text-sm space-y-1 mt-4">
              <p>CAC</p>
              <p className="text-gray-600 bg-red-200 inline p-0.5">
                Coming soon
              </p>
            </div>
            <div
              className="flex items-center justify-between mt-4 cursor-pointer"
              onClick={() => setStep(5)}
            >
              <div className="text-sm space-y-1 ">
                <p>Pin</p>
                <p className="text-gray-600">********</p>
              </div>
              <FiChevronRight size={20} />
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(1)}
          >
            <MdKeyboardBackspace className="" />
            Back
          </p>
          <form className="mt-4" onSubmit={formik.handleSubmit}>
            <p className="text-blue-700">Bank details</p>
            <div className="text-sm space-y-1 mt-4">
              <p>Beneficiary name</p>
              <input
                type="text"
                {...formik.getFieldProps("accountName")}
                className="p-2 border border-gray-300 rounded w-[450px] outline-none"
              />
              {formik.errors.accountName ? (
                <p className="text-red-600 text-[13px]">
                  {formik.errors.accountName}
                </p>
              ) : null}
            </div>
            <div className="text-sm space-y-1 mt-4">
              <p>Bank name</p>
              <input
                type="text"
                {...formik.getFieldProps("bankName")}
                className="p-2 border border-gray-300 rounded w-[450px] outline-none"
              />
              {formik.errors.bankName ? (
                <p className="text-red-600 text-[13px]">
                  {formik.errors.bankName}
                </p>
              ) : null}
            </div>
            <div className="text-sm space-y-1 mt-4">
              <p>Bank number</p>
              <input
                type="text"
                {...formik.getFieldProps("accountNumber")}
                className="p-2 border border-gray-300 rounded w-[450px] outline-none"
              />
              {formik.errors.accountNumber ? (
                <p className="text-red-600 text-[13px]">
                  {formik.errors.accountNumber}
                </p>
              ) : null}
            </div>
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="bg-blue-600 text-white rounded p-2.5 px-6 mt-4"
            >
              {formik.isSubmitting ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Save"
              )}
            </button>
          </form>
        </div>
      )}

      {step === 3 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(1)}
          >
            <MdKeyboardBackspace className="" />
            Back
          </p>
          <div className="h-full flex items-center justify-center">
            <div className="p-6 pt-3 text-sm space-y-1 max-w-[400px] mx-auto">
              <div>
                <OTPInput4
                  label="Create a pin to allow you transfer to your personal account from your wallet."
                  onChange={handleChange1}
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={code1.length < 4}
                  onClick={() => {
                    // alert(1);
                    handleSubmitPin();
                  }}
                  className="text-xs w-[150px] py-3 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4 "
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 4 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(1)}
          >
            <MdKeyboardBackspace className="" />
            Back
          </p>
          <div className="h-full flex items-center justify-center">
            <div className="p-6 pt-3 text-sm space-y-1 max-w-[500px] mx-auto">
              <p className="font-semibold text-gray-900 hidden">
                Enter a 6 digit code
              </p>
              <div>
                <OTPInput
                  label="Enter your 6-digit encrypted passcode"
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={code.length < 6}
                  onClick={() => {
                    // alert(1);
                    handleConfirmPasscode.mutate();
                  }}
                  className="text-xs w-[150px] py-3 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4 "
                >
                  {handleConfirmPasscode.isLoading ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 5 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(1)}
          >
            <MdKeyboardBackspace className="" />
            Back
          </p>
          <div className="h-full flex items-center justify-center">
            <div className="p-6 pt-3 text-sm space-y-1 max-w-[500px] mx-auto">
              <p className="font-semibold text-gray-900 hidden">
                Enter a 6 digit code
              </p>
              <div>
                <OTPInput
                  label="Enter your 6-digit encrypted passcode"
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={code.length < 6}
                  onClick={() => {
                    // alert(1);
                    handleConfirmPasscode1.mutate();
                  }}
                  className="text-xs w-[150px] py-3 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4 "
                >
                  {handleConfirmPasscode1.isLoading ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 6 && (
        <div className="col-span-3 h-full bg-white p-3">
          <div className="flex items-center justify-between">
            <p
              className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
              onClick={() => setStep(1)}
            >
              <MdKeyboardBackspace className="" />
              Transfer pin
            </p>

            <div className="flex items-center gap-2 ml-auto">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="w-full text-blue-800 mt-1">
                    <IoMdMore />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-1 w-36 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-10">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-slate-100 font-semibold"
                                : "text-gray-900 font-medium"
                            } w-full rounded-md px-4 py-2 text-xs text-left`}
                            onClick={() => {
                              setShowPinKey((prev) => !prev);
                            }}
                          >
                            Show/Hide pin
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-slate-100 font-semibold"
                                : "text-gray-900 font-medium"
                            } w-full rounded-md px-4 py-2 text-xs text-left`}
                            onClick={() => {
                              setStep(7);
                            }}
                          >
                            Reset pin
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <div className="h-full flex items-center justify-center">
            <div className="p-6 pt-3 text-sm space-y-1 max-w-[400px] mx-auto">
              <div>
                <div className="flex items-center gap-2">
                  <div className="border border-gray-300 rounded h-[60px] w-[60px] flex items-center justify-center">
                    {showPinKey ? "*" : `${pinKey[0]}`}
                  </div>
                  <div className="border border-gray-300 rounded h-[60px] w-[60px] flex items-center justify-center">
                    {showPinKey ? "*" : `${pinKey[1]}`}
                  </div>
                  <div className="border border-gray-300 rounded h-[60px] w-[60px] flex items-center justify-center">
                    {showPinKey ? "*" : `${pinKey[2]}`}
                  </div>
                  <div className="border border-gray-300 rounded h-[60px] w-[60px] flex items-center justify-center">
                    {showPinKey ? "*" : `${pinKey[3]}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 7 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(6)}
          >
            <MdKeyboardBackspace className="" />
            Back
          </p>
          <div className="h-full flex items-center justify-center">
            <div className="p-6 pt-3 text-sm space-y-1 max-w-[400px] mx-auto">
              <div>
                <OTPInput4 label="Old pin" onChange={handleChange2} />
              </div>
              <br />
              <br />
              <div>
                <OTPInput4 label="New pin" onChange={handleChange3} />
              </div>

              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={
                    code2.length < 4 ||
                    code3.length < 4 ||
                    handleResetPin.isLoading
                  }
                  onClick={() => {
                    handleResetPin.mutate();
                  }}
                  className="text-xs w-[150px] py-3 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4 "
                >
                  {handleResetPin.isLoading ? "Loading..." : "Reset pin"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Toaster position="top-center" containerClassName="" gutter={200} />
    </>
  );
};

export default EstorePaymentInformation;
