// @ts-nocheck

import React, { useState, useRef } from "react";
import {
  BASE_URL_WEBPAGE_IMAGE,
  addProductCategory,
} from "../../../../../../../api/Menu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../../../../hooks";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { ImSpinner2 } from "react-icons/im";
import { Input } from "../../EchoWebpage1";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import Compressor from "compressorjs";
import UploadCategoryimage from "./UploadCategoryimage";
import { useFormik } from "formik";

const categoryInitialValue = {
  category: "",
};

const AddCategory = ({
  setProductSidePanelStep,
  setStep,
  showModals,
  setShowModals,
}) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const inputRef = useRef(null);

  const imageHandler = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(null);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const handleCompressedUpload = async (file: any) => {
    setIsLoading(true);
    new Compressor(file, {
      quality: 0.2,
      success: (compressedResult: any) => {
        let path: string;
        const itemImagePath =
          "/cusecho" + storeKey + "/products/" + formik.values.category;

        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };

        path = itemImagePath;

        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then(() => {
                setIsLoading(false);
                setImageUploadSuccess(true);
                inputRef.current.value = null;
                setSelectedImage(null);
                setTimeout(() => {
                  setImageUploadSuccess(false);
                }, 3000);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };

  const uploadImage = useMutation({
    mutationFn: async (data: File) => {
      return handleCompressedUpload(data);
    },
  });

  const formik = useFormik({
    initialValues: categoryInitialValue,
    // validationSchema: itemSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      setSubmitting(true);
      if (selectedImage?.size > 2072576) {
        alert(
          "Please select an image with a size of less than or equal to 2MB"
        );
        setSelectedImage(null);
        return;
      }

      uploadImage.mutate(selectedImage);
      const payload = {
        slugUrl: storeKey,
        categoryName: values.category.trim(),
      };
      try {
        const response = await addProductCategory(payload);
        // console.log(response)
        if (response.status === 200) {
          queryClient.invalidateQueries("getAllMenuCategories");
          queryClient.invalidateQueries("getAllStoreCategories");
          resetForm({});
          if (showModals === "item") {
            setTimeout(() => {
              setShowModals(null);
            }, 3000);
          }
          setSubmitting(false);
          setProductSidePanelStep(1);
          return;
        }
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  return (
    <div className="">
      {!showModals === "item" && (
        <h1
          className="text-xl text-gray-800 mb-3 font-semibold flex items-center gap-4 cursor-pointer"
          onClick={() => {
            setProductSidePanelStep(1);
          }}
        >
          <MdOutlineKeyboardBackspace /> <span>Category</span>
        </h1>
      )}

      <form onSubmit={formik.handleSubmit} className="space-y-4">
        <div className="space-y-4">
          <div className="w-full">
            <Input
              formik={formik}
              name="category"
              label="Category Name"
              type="text"
              placeHolder="Please enter category name"
            />
          </div>
        </div>

        <div className="my-3 space-y-2.5">
          <div>
            <label
              htmlFor="formFile"
              className="mb-0.5 inline-block text-gray-900 text-sm font-semibold"
            >
              Choose category image
            </label>
            <input
              ref={inputRef}
              className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-medium text-gray-900 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none "
              type="file"
              accept="image/jpeg, image/png, image/jpg"
              onChange={imageHandler}
            />
          </div>
          <button
            type="button"
            className="text-xs w-[100px] py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white hidden"
            onClick={() => {
              if (!selectedImage) {
                alert("Select an image");
                return;
              }
              uploadImage.mutate(selectedImage);
            }}
          >
            {uploadImage.isLoading || isLoading ? (
              <ImSpinner2 size={20} className="mx-auto animate-spin" />
            ) : (
              "Upload"
            )}
          </button>
          {imageUploadSuccess && (
            <p className="mt-2 text-sm text-center text-green-500">
              Image upload was successful
            </p>
          )}
        </div>

        {/* <UploadCategoryimage formik={formik} /> */}

        <button
          type="submit"
          className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 mt-6 rounded text-white"
        >
          {formik.isSubmitting ? (
            <ImSpinner2 size={20} className="mx-auto animate-spin" />
          ) : (
            "Add"
          )}
        </button>
      </form>
    </div>
  );
};

export default AddCategory;
