// @ts-nocheck

import React, { useState, useRef, useEffect } from "react";
import {
  BASE_URL_WEBPAGE_IMAGE,
  editProductCategory,
} from "../../../../../../../api/Menu";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { classNames } from "../../../../../../../utils";
import { ImSpinner2 } from "react-icons/im";
import Compressor from "compressorjs";

const EditCategory = ({
  activeCategory,
  setActiveCategory,
  setProductSidePanelStep,
  setStep,
  showModals,
  setShowModals,
  selectedObject,
  setSelectedObject,
}) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [newCategory, setNewCategory] = useState(activeCategory);
  const inputRef = useRef(null);
  // console.log(selectedObject.Category_S3ImageUrl[0].Image_Upload_Url);

  const imageHandler = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(null);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  // const convertUrlToFile = () => {
  //   fetch(selectedObject.Category_S3ImageUrl[0].Image_Upload_Url)
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       console.log(blob)
  //       const file = new File([blob], "image", { type: blob.type });
  //     //   const myFile = new File([myBlob], 'image.jpeg', {
  //     //     type: myBlob.type,
  //     // });
  //       setSelectedImage(file)
  //     }).catch((err) => {
  //       console.log(err)
  //     })
  // };

  // useEffect(() => {
  //   convertUrlToFile();
  // },[])

  const handleCompressedUpload = async (file: any) => {
    setIsLoading(true);
    new Compressor(file, {
      quality: 0.2,
      success: (compressedResult: any) => {
        let path: string;
        const itemImagePath =
          "/cusecho" + storeKey + "/products/" + newCategory;

        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };

        path = itemImagePath;

        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then(() => {
                setIsLoading(false);
                setImageUploadSuccess(true);
                inputRef.current.value = null;
                setSelectedImage(null);
                setTimeout(() => {
                  setImageUploadSuccess(false);
                }, 3000);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };

  const uploadImage = useMutation({
    mutationFn: async (data: File) => {
      return handleCompressedUpload(data);
    },
  });

  const handleEditProductCategory = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        platformKey,
        oldCategory: activeCategory,
        newCategory: newCategory,
        slugUrl: storeKey,
      };
      return await editProductCategory(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getAllMenuCategories");
      if (showModals === "item") {
        setShowModals(null);
        return;
      }
      setActiveCategory();
      setProductSidePanelStep(1);
    },
  });

  return (
    <div className="p-3">
      <h1
        className="text-xl text-gray-800 mb-3 font-semibold flex items-center gap-4 cursor-pointer"
        onClick={() => {
          if (showModals === "item") {
            setStep(1);
          }
          setActiveCategory();
          setSelectedObject({});
          setProductSidePanelStep(1);
        }}
      >
        <MdOutlineKeyboardBackspace /> <span>Edit Category</span>
      </h1>
      <form className="space-y-4">
        <div className="space-y-4">
          <div className="w-full">
            <>
              <label className="block mb-1 text-xs font-normal text-gray-800">
                Category
              </label>
              <input
                type="text"
                placeholder="Enter new category name"
                className={classNames(
                  "text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm"
                )}
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
            </>
          </div>
        </div>
      </form>

      <div className="my-3 space-y-2.5">
        <div>
          <label
            htmlFor="formFile"
            className="mb-0.5 inline-block text-gray-900 text-sm font-semibold"
          >
            Choose category image
          </label>
          <input
            ref={inputRef}
            className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-medium text-gray-900 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none "
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            onChange={imageHandler}
          />
        </div>
        {imageUploadSuccess && (
          <p className="mt-2 text-sm text-center text-green-500">
            Image Upload was successful
          </p>
        )}
      </div>

      <button
        type="submit"
        onClickCapture={() => {
          if (selectedImage?.size > 2072576) {
            alert(
              "Please select an image with a size of less than or equal to 2MB"
            );
            setSelectedImage(null);
            return;
          }
          uploadImage.mutate(selectedImage);
          handleEditProductCategory.mutate();
        }}
        className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 mt-6 rounded text-white"
      >
        {handleEditProductCategory.isLoading || isLoading ? (
          <ImSpinner2 size={20} className="mx-auto animate-spin" />
        ) : (
          "Edit Category"
        )}
      </button>
    </div>
  );
};

export default EditCategory;
