import React from "react";
import { IoChevronForward } from "react-icons/io5";

const EstoreSmartPricing = () => {
  return (
    <div className="col-span-3 h-full bg-white p-3">
      <p className="text-gray-800 font-semibold">Smart pricing</p>
      <div className="mt-2">
        <input
          type="text"
          className="p-2 border border-gray-200 rounded-md w-full placeholder:text-sm"
          placeholder="Search item"
        />
      </div>
      <div className="grid grid-cols-2 gap-6 mt-4">
        {[1, 2, 3, 4, 5, 6, 7].map((index: number) => (
          <div className="border border-gray-200 rounded-md p-3 text-sm flex items-center gap-4 justify-between" key={index} >
            <div className="space-y-2">
              <p className="font-semibold">Diorshow Iconic Overcurl Mascara</p>
              <div className="space-y-1 text-[13px]" >
                <p>Your price: 2000</p>
                <p>Market price: 3000</p>
              </div>
            </div>
            <IoChevronForward />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EstoreSmartPricing;
