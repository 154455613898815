import { classNames } from "../../../../utils";

interface Props {
  type?: "error" | "success";
  message: string;
}

const InputMessage = ({ type = "error", message }: Props) => {
  return (
    <div className="">
      <span
        className={classNames("text-xs",
          type === "error" ? "text-red-600" : "text-green-600"
        )}
      >
        {message}
      </span>
    </div>
  );
};

export default InputMessage;
