import BusinessPerceptionTracker from "../../../../../components/Dashboard/DashBoardContainer/BusinessPerceptionTracker";
import CustomerSatisfaction from "../../../../../components/Dashboard/DashBoardContainer/CustomerSatisfaction";
import CommentTracker from "../../../../../components/Dashboard/DashBoardContainer/CommentTracker";
import KPI from "../../../../../components/Dashboard/DashBoardContainer/KPI";
import Reviews from "../../../../../components/Dashboard/DashBoardContainer/Reviews";
import { ImSpinner3 } from "react-icons/im";
import { useEffect, useState } from "react";
import Modal from "../../../../../components/Dashboard/Modal";
import { FiInfo } from "react-icons/fi";

export const Loader = () => {
  const [showLoadingModal, setShowLoadingModal] = useState(true);
  return (
    <>
      <Modal show={showLoadingModal} size="normal">
        <div className="relative flex flex-col p-6 ">
          <div className="flex items-center justify-center">
            <ImSpinner3
              size={40}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const CustomerSatisfactionPage = () => {
  useEffect(() => {
    localStorage.removeItem("completeRegistration");
  }, []);

  return (
    <>
      <div className="space-y-1">
        <div className="grid content-center justify-center grid-cols-1 gap-5 md:grid-cols-3">
          <div className="customer-satisfaction">
            <CustomerSatisfaction />
          </div>
          <div className="comment-tracker">
            <CommentTracker />
          </div>
          <div className="review">
            <Reviews />
          </div>
          <div className="hidden sm:block md:col-span-2 business-perception-tracker">
            <BusinessPerceptionTracker />
          </div>
          <div className="hidden kpi sm:block">
            <KPI />
          </div>
        </div>
        <div className="w-full h-2.5"></div>
        <p className="bg-white text-xs text-gray-800 font-medium max-w-[600px] mx-auto  py-1 px-2 flex gap-3 items-center rounded my-1">
          <FiInfo size={20} className="hidden sm:block"/>{" "}
          <span>
            Please bear in mind that as we continue to optimize our platform,
            there may be some inconsistencies in the information provided.
          </span>
        </p>
      </div>
    </>
  );
};

export default CustomerSatisfactionPage;
