import { classNames } from "../../../../utils";
import { HiChevronDown } from "react-icons/hi";

export interface ISelectFields {
  value: string;
  display: string;
}

interface SelectInputProps {
  name: string;
  label: string;
  placeHolder?: string;
  options: ISelectFields[];
  formik?: any;
  className?: any;
  onChange?: (value: string) => void;
}

const SelectInput = ({name, label, placeHolder, options, formik, className, onChange }: SelectInputProps) => {
  return (
    <>
      <label className="block mb-0.5 text-xs font-medium text-gray-900">
        {label}
      </label>
      <div className="relative w-full">
        <select name={name} {...(formik ? formik.getFieldProps(name) : [])} className={classNames(className , "border border-gray-300 py-2.5 px-5 bg-white rounded-md w-full outline-none text-gray-400 appearance-none")}>
          {placeHolder && <option value="" className="py-3">{placeHolder}</option>}
          {options.map((option, index) => (
            <option key={index + option.display} value={option.value} className="py-3">
              {option.display}
            </option>
          ))}
        </select>
        <span className="absolute h-full text-gray-900 right-4 inset-y-4"><HiChevronDown size={24} /></span>
      </div>
    </>
  );
};

export default SelectInput;
