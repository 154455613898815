// @ts-nocheck

import React, { useState } from "react";
import {
  BASE_URL_WEBPAGE_IMAGE,
  getSiteTitleAndTagLine,
} from "../../../../../../../api/Menu";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import { useQuery } from "@tanstack/react-query";
import { classNames, formatStoreName } from "../../../../../../../utils";


const HeroSection = ({ setSidePanel, sidePanel }) => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const queryKey = store.name;
  const storeName = formatStoreName(queryKey);

 let heroDetails = {
    siteTitle: "[SITE_TITLE]",
    tagLine: "[TAG_LINE]",
  }

  let heroImageUrl: string =
  "https://d1ntmcvl0pjnmq.cloudfront.net" + queryKey + "/heroImage/image.png";

  const siteTitleAndTagLine = useQuery<any, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(queryKey);
      return response.data;
    },
  });

  if (siteTitleAndTagLine.data){
    const siteTitle = siteTitleAndTagLine.data.Site_Title === "" ? storeName : siteTitleAndTagLine.data.Site_Title
    heroDetails = {
      siteTitle: siteTitle,
      tagLine: siteTitleAndTagLine.data.Tagline,
    }
  }

  return (
    <section
      className={classNames(
        "cursor-pointer",
        sidePanel === "hero" ? "border-2 border-blue-800 p-3" : ""
      )}
    >
      <div
        className={classNames("p-3 pb-7 bg-white relative cursor-pointer")}
        onClick={() => {
          setSidePanel("hero");
        }}
      >
        <div className="rounded-md text-center h-[240px] relative">
          <div className="absolute top-0 w-full h-full z-20 flex justify-center items-center p-3" >
            <div className="text-white">
              <h1 className="text-4xl font-semibold">
                {heroDetails.siteTitle}
              </h1>
              <p className="text-xl">
                {heroDetails.tagLine === ""
                  ? "[SITE_TAG_LINE]"
                  : heroDetails.tagLine}
              </p>
            </div>
          </div>
          <img
              src={heroImageUrl}
              alt="Store hero image"
              onError={(e) =>
                (e.currentTarget.src =
                  "/media/image/servicesBg.png")
              }
              className="absolute top-0 w-full h-full object-cover rounded-md bg-gray-50"
            />
          <div className="absolute top-0 w-full h-full bg-black opacity-30"></div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
