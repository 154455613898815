import { useEffect, useState, useRef } from "react";
import PageHeader from "../../../../components/Dashboard/PageHeader";
import { METRICS_COLORS, classNames } from "../../../../utils";
import { getActiveKPIs, getKPIs } from "../../../../api/KPIs";
import { useAppSelector } from "../../../../hooks";
import { UserProfileModel } from "../../../../models";
import { TbChevronRight } from "react-icons/tb";
import ApexCharts from "apexcharts";
import SkeletonLoader from "../../../../components/Dashboard/SkeletonLoader";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import SourceIcon from "../../../../components/Dashboard/SourceIcon";
import moment from "moment";
import { ImSpinner3 } from "react-icons/im";
import { useParams } from "react-router-dom";
import { FiInfo } from "react-icons/fi";

interface ChartProps {
  data: any[];
}

const Chart1 = ({ data }: ChartProps) => {
  const chartRef = useRef(HTMLDivElement.prototype);
  const total_negative = data[0]?.NEG;
  const total_positive = data[0]?.POS;

  useEffect(() => {
    let options = {
      chart: {
        type: "pie",
        width: "100%",
        height: 200,
        // offsetY: -20,
      },
      title: {
        text: "Overall Analytics",
        style: {
          color: "#11182780",
        },
      },
      colors:
        total_positive === 0 && total_negative === 0
          ? ["#f0f0f0"]
          : ["#3DB832", "#CD151E"],
      series:
        total_positive === 0 && total_negative === 0
          ? [1]
          : [total_positive, total_negative],
      labels:
        total_positive === 0 && total_negative === 0
          ? ["No Data"]
          : ["Positive", "Negative"],
      legend: {
        position: "left",
        offsetY: 70,
      },
    };

    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, total_positive, total_negative]);

  return <div ref={chartRef} id="oa-chart" className=""></div>;
};

const Chart2 = ({ data }: ChartProps) => {
  const chartRef = useRef(HTMLDivElement.prototype);
  const ss = data[0]?.similarity_score[0];
  const getPercent = (x: number, total: number) => {
    if (x === 0 || total === 0) {
      return 0;
    }
    return (x / total) * 1;
  };

  useEffect(() => {
    let options = {
      chart: {
        type: "bar",
        width: "100%",
        height: 200,
        toolbar: {
          show: false,
        },
        // offsetY: -20,
      },
      title: {
        text: "Similarity Score Analytics",
        style: {
          color: "#11182780",
        },
      },
      colors: ["#3DB832", "#CD151E"],
      series: [
        {
          name: "Positive",
          data: [
            getPercent(ss.class1[0].POS, ss.class1[0].POS + ss.class1[0].NEG),
            getPercent(ss.class2[0].POS, ss.class2[0].POS + ss.class2[0].NEG),
            getPercent(ss.class3[0].POS, ss.class3[0].POS + ss.class3[0].NEG),
            getPercent(ss.class4[0].POS, ss.class4[0].POS + ss.class4[0].NEG),
          ],
        },
        {
          name: "Negative",
          data: [
            getPercent(ss.class1[0].NEG, ss.class1[0].POS + ss.class1[0].NEG),
            getPercent(ss.class2[0].NEG, ss.class2[0].POS + ss.class2[0].NEG),
            getPercent(ss.class3[0].NEG, ss.class3[0].POS + ss.class3[0].NEG),
            getPercent(ss.class4[0].NEG, ss.class4[0].POS + ss.class4[0].NEG),
          ],
        },
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["0% - 25%", "25% - 50%", "50% - 75%", "75% - 100%"],
      },
      yaxis: {
        tickAmount: 2,
        min: 0,
        max: 1,
        labels: {
          formatter: function (val: any) {
            return val.toFixed(1);
          },
        },
      },
    };

    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <div ref={chartRef} id="ssc-chart" className=""></div>;
};

const DisplayBoard = () => {
  const { successMetric } = useParams();
  const [showDropdown, setShowDropdown] = useState(false);
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = `${user.queryKey}`;
  const platformKey = queryKey.replace("%26", "&");
  const queryClient = useQueryClient();
  const firstId = queryClient.getQueryData<any>(["getKPIs"]);
  const [activeTab, setActiveTab] = useState(Number(successMetric ?? 1));
  const [activeColor, setActiveColor] = useState(METRICS_COLORS[0]);
  const [filterSimilarityScore, setFilterSimilarityScore] = useState("NONE");
  const [filterReviewType, setFilterReviewType] = useState("NONE");
  const [startDateTimeStamp, setStartDateTimeStamp] = useState("0.000");          
  const [endDateTimeStamp, setEndDateTimeStamp] = useState(`${new Date(Date.now()).getTime() / 1000}`);          
  const [fireFilter, setFireFilter] = useState(1);
  const maxKpis = 5;

  const handleFilterSimilarityScoreChange = (event: any) => {
    setFilterSimilarityScore(event.target.value);
  };

  const handlefilterReviewTypeChange = (event: any) => {
    setFilterReviewType(event.target.value);
  };

  const handleClearFilter = (e: any) => {
    e.preventDefault();
    setFilterSimilarityScore("NONE");
    setFilterReviewType("NONE");
    setStartDateTimeStamp("0.000");
    setEndDateTimeStamp(`${new Date(Date.now()).getTime() / 1000}`);
    if (fireFilter === 1) {
      setFireFilter(2);
    } else setFireFilter(1);
  };

  let kpi_load_items: any[] = [];
  let kpiStats: any[] = [];
  let kpiTabs: number[] = [];

  const kpis = useQuery<any, Error>({
    queryKey: ["getKPIs"],
    queryFn: async () => {
      const response = await getKPIs(queryKey);
      return response.data;
    },
    select: (data) => {
      const saved = data.Item;
      delete saved.kpi_metrics;
      delete saved.platform_key;
      delete saved.kpi_metadata;
      const newData = Object.values(saved);
      return newData;
    },
    onSuccess: (data) => {
      // console.log(data);
      // console.log(data[0][0].tag_name.replace("Success_Metric_", ""));
      const newData = data.filter((metric: any, index: number) => metric[0].status === "1");
      let firstId = 1;
       
      if (newData.length > 0) {
        firstId = newData[0][0].tag_name.replace("Success_Metric_", "");
      }

      if(activeTab === 1 && Number(firstId) !== 1) {
        setActiveTab(Number(firstId));
      }
    },
  });

  if (kpis.data) {
    kpiTabs = kpis.data
      .filter((metric: any, index: number) => metric[0].status === "1")
      .slice(0, maxKpis)
      .map((r: any) => +r[0].tag_name.replace("Success_Metric_", ""));

      // console.log(kpiTabs);
  }

  const activekpis = useInfiniteQuery<any, Error>({
    queryKey: ["getActiveKPIs", activeTab, fireFilter, kpiTabs],
    queryFn: async ({ pageParam = "" }) => {
      const data = {
        // tagName: "Success_Metric_" + kpiTabs[activeTab - 1],
        tagName: "Success_Metric_" + activeTab,
        platformKey,
        timestamp: [startDateTimeStamp, endDateTimeStamp],
        similarityScore: filterSimilarityScore,
        labelTag: filterReviewType,
        pagination_key: pageParam,
      };
      // console.log(data)
      const response = await getActiveKPIs(data);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      // console.log(lastPage)
      if (lastPage.LastEvaluatedKey === "" || lastPage.kpi_load.Items.length < 1) {
        return false;
      }
      return lastPage.LastEvaluatedKey;
    },
  });

  useEffect(() => {
    if (kpis.data) {
      
      if(kpis.data.filter(
        (metric: any, index: number) => metric[0].status === "1"
      ).length === 0) {
        setActiveTab(1);
        setActiveColor(METRICS_COLORS[0]);
        return;
      }
      
      const index = kpis.data.filter(
        (metric: any, index: number) => metric[0].status === "1"
      ).findIndex((metric: any) => Number(metric[0].tag_name.replace("Success_Metric_", " ")) === activeTab);
    // console.log(activeTab)
    // console.log(index)
    setActiveColor(METRICS_COLORS[index]);
    }
  }, [kpis.data, activeTab]);

  if (kpis.status === "loading")
    return <SkeletonLoader className="rounded-md h-[80vh]" />;

  if (kpis.status === "error") return <span>Error: {kpis.error.message}</span>;

  if (activekpis.data) {
    kpiStats = activekpis.data.pages[0].kpi_stats;
    activekpis.data.pages.forEach((page) => {
      kpi_load_items = [...kpi_load_items, ...page.kpi_load.Items];
    });

    // console.log(activekpis.data.pages[0].kpi_load.Items);
  }

  if (!activekpis.data && kpis.data.length < 0) {
    return (
      <div className="h-[80vh] bg-white rounded-md shadow-md py-2 px-4 overflow-hidden flex items-center justify-center">
        <p className="text-xl text-gray-400">No Active KPI Available</p>
      </div>
    );
  }

  return (
    <div className="space-y-1">
      <div className="flex items-center justify-between">
        <PageHeader title="Business metrics reviews" />

        {kpis.data.filter(
          (metric: any, index: number) => metric[0].status === "1"
        ).length > 0 && (
          <div className="relative pb-1">
            <button
              onClick={() => setShowDropdown((prevState) => !prevState)}
              className="inline-flex items-center px-3 py-1 text-sm font-medium truncate border rounded-md shadow-sm border-primary text-primary"
            >
              Filter by <TbChevronRight className="mt-0.5 ml-4" strokeWidth={3} />
            </button>
            {showDropdown && (
              <div
                id="dropdown"
                className="absolute text-sm right-0 mt-2 z-10 bg-white rounded-lg shadow w-[280px]"
              >
                <div className="p-2 bg-[#eff4fe] text-gray-400">Date</div>
                <div className="flex items-center p-2 space-x-2 bg-white">
                  <div date-rangepicker="true">
                    <span className="text-xs text-gray-500">Start Date</span>
                    <div className="relative">
                      <input
                        name="start"
                        type="date"
                        onChange={(e) =>
                          setStartDateTimeStamp(
                            (new Date(e.target.value).getTime() / 1000).toFixed(3)
                          )
                        }
                        className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                        placeholder="Select date"
                      />
                    </div>
                  </div>
                  <div date-rangepicker="true">
                    <span className="text-xs text-gray-500">End Date</span>
                    <div className="relative">
                      <input
                        name="end"
                        type="date"
                        onChange={(e) =>
                          setEndDateTimeStamp(
                           ( new Date(e.target.value).getTime() / 1000).toFixed(3)
                          )
                        }
                        className="block w-full p-2 text-xs text-gray-900 border border-gray-300 rounded bg-gray-50"
                        placeholder="Select date"
                      />
                    </div>
                  </div>
                </div>
                <div className="p-2 bg-[#eff4fe] text-gray-400">
                  Review Type
                </div>
                <div className="flex items-center gap-2 p-2 text-gray-400 bg-white">
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center h-5">
                      <input
                        type="radio"
                        value="NEG"
                        checked={filterReviewType === "NEG"}
                        onChange={handlefilterReviewTypeChange}
                        className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-xs">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-600"
                      >
                        Positive
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center h-5">
                      <input
                        type="radio"
                        value="POS"
                        checked={filterReviewType === "POS"}
                        onChange={handlefilterReviewTypeChange}
                        className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-xs">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-600"
                      >
                        Negative
                      </label>
                    </div>
                  </div>
                </div>
                <div className="p-2 bg-[#eff4fe] text-gray-400">
                  Similarity Score
                </div>
                <div className="flex items-center gap-2 p-2 text-gray-400 bg-white">
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center h-5">
                      <input
                        type="radio"
                        value="class1"
                        checked={filterSimilarityScore === "class1"}
                        onChange={handleFilterSimilarityScoreChange}
                        className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-xs">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-900"
                      >
                        0-25
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center h-5">
                      <input
                        type="radio"
                        value="class2"
                        checked={filterSimilarityScore === "class2"}
                        onChange={handleFilterSimilarityScoreChange}
                        className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-xs">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-900"
                      >
                        25-50
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center h-5">
                      <input
                        type="radio"
                        value="class3"
                        checked={filterSimilarityScore === "class3"}
                        onChange={handleFilterSimilarityScoreChange}
                        className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-xs">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-900"
                      >
                        50-75
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center h-5">
                      <input
                        type="radio"
                        value="class4"
                        checked={filterSimilarityScore === "class4"}
                        onChange={handleFilterSimilarityScoreChange}
                        className="w-3.5 h-3.5 text-blue-600 bg-gray-100 border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-xs">
                      <label
                        htmlFor="helper-radio"
                        className="font-medium text-gray-900"
                      >
                        75-100
                      </label>
                    </div>
                  </div>
                </div>
                <div className="bg-[#eff4fe] flex items-center justify-center p-2 text-xs space-x-2">
                  <button
                    className="w-1/2 px-4 py-2 font-bold text-gray-700 truncate bg-transparent border rounded"
                    onClick={(e) => {
                      handleClearFilter(e);
                      setShowDropdown(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-1/2 px-4 py-2 font-bold text-white truncate rounded bg-primary"
                    onClick={() => {
                      if (fireFilter === 1) {
                        setFireFilter(2);
                      } else setFireFilter(1);
                      setShowDropdown(false);
                    }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className="flex flex-col h-[80vh] bg-white rounded-md shadow-md py-2 px-4 overflow-hidden border-l-[10px]"
        style={{ borderColor: activeColor }}
      >
        <div className="sticky w-full overflow-x-auto bg-white -top-2">
          {kpis.data
            .filter((metric: any, index: number) => metric[0].status === "1")
            .slice(0, maxKpis)
            .map((metric: any, index: number) => {
              const newIndex: number = index + 1;
              const tagId = Number(
                metric[0].tag_name.replace("Success_Metric_", " ")
              );
              return (
                <button
                  key={index}
                  onClick={(e) => {
                    setActiveTab(tagId);
                    setActiveColor(METRICS_COLORS[index]);
                    handleClearFilter(e);
                  }}
                  className={classNames(
                    "w-1/5 text-sm font-semibold border px-2 py-1 border-gray-200 capitalize truncate",
                    activeTab === tagId || (newIndex === 1 && activeTab === 1)
                      ? "text-primary"
                      : "text-gray-400",
                    newIndex === 1
                      ? "rounded-l-md"
                      : newIndex ===
                        kpis.data.filter(
                          (metric: any, index: number) =>
                            metric[0].status === "1"
                        ).length
                      ? "rounded-r-md"
                      : "",
                    newIndex === 1 &&
                      newIndex ===
                        kpis.data.filter(
                          (metric: any, index: number) =>
                            metric[0].status === "1"
                        ).length
                      ? "rounded-r-md"
                      : ""
                  )}
                >
                  {metric[0].metric}
                </button>
              );
            })}
        </div>

        {activekpis.status === "loading" || kpis.isRefetching && (
          <div className="flex items-center justify-start py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        )}

        {(activekpis.status === "error" || kpiTabs.length === 0 ) && (
          <p className="mt-8 text-sm font-semibold text-center text-gray-400">
            No active business KPI Available
          </p>
        )}

        {kpiStats.length > 0 && (
          <div className="sticky my-2 space-y-1 top-10">
            <div className="grid grid-cols-2 gap-x-4">
              <div className="px-4 py-2 overflow-hidden border border-gray-200 rounded-md">
                {kpiStats && <Chart1 data={kpiStats} />}
              </div>
              <div className="px-4 py-2 overflow-hidden border border-gray-200 rounded-md">
                {kpiStats && <Chart2 data={kpiStats} />}
              </div>
            </div>
          </div>
        )}

        {kpi_load_items.length > 0 && (
          <div className="relative flex-1 overflow-y-scroll">
            {kpi_load_items.map((comment: any, index: number) => {
              const similarity_score: string = comment.similarityScore;
              const source = comment.commentSource;
              const sentiment_tag: string = comment.sentimentTag;
              return (
                <div
                  key={index}
                  className={classNames(
                    "flex mb-2 space-x-2 border-none rounded-xl px-4 py-2"
                  )}
                >
                  <div className="flex flex-col items-center justify-center w-12 h-12 border border-gray-300 rounded-full text-primary">
                    <SourceIcon source={source} />
                  </div>
                  <div className="flex flex-col flex-1 gap-1">
                    <h2 className="text-sm font-medium text-black">
                      {comment.customer}
                      <span className="ml-2 text-xs text-gray-400">
                        {moment(comment.uploadTime).format("MM/DD/YYYY")}
                      </span>
                    </h2>
                    <p
                      className={classNames(
                        "text-xs font-normal leading-normal text-gray-500"
                      )}
                    >
                      {comment.userReview}
                    </p>
                    <div className="flex items-center justify-between px-5 py-2 bg-gray-100 rounded-full w-[21rem]">
                      <p className="text-sm font-normal text-gray-400">
                        Similarity Score{" "}
                        <span
                          className={classNames(
                            "ml-2 font-semibold text-black"
                          )}
                        >
                          {similarity_score}
                        </span>
                      </p>
                      <div className="inline-flex items-center">
                        <p className="text-sm font-normal text-gray-400">
                          Sentiment Tag{" "}
                        </p>
                        <div
                          className={classNames(
                            "h-3.5 w-3.5 ml-1.5 font-semibold text-black rounded-full",
                            sentiment_tag === "NEG"
                              ? "bg-red-600"
                              : sentiment_tag === "POS"
                              ? "bg-green-600"
                              : "bg-gray-400"
                          )}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {kpiTabs.length !== 0 &&
              kpi_load_items.length === 0 &&
              (kpiStats[0]?.POS || kpiStats[0]?.NEG !== 0) &&
              activekpis.status !== "loading" && (
                <p className="mt-8 text-lg font-semibold text-center text-gray-400">
                  No Reviews
                </p>
              )}

            {kpiStats[0]?.NEG === 0 && kpiStats[0]?.POS === 0 && (
              <div className="mt-8 text-center">
                <p className="text-xl text-gray-400">You don't have any business KPIs yet</p>
              </div>
            )}

            {kpi_load_items.length > 0 && (
              <div className="p-2 text-center bg-white">
                <button
                  onClick={() => activekpis.fetchNextPage()}
                  disabled={
                    !activekpis.hasNextPage || activekpis.isFetchingNextPage
                  }
                  className="text-sm text-blue-500"
                >
                  {activekpis.isFetchingNextPage
                    ? "Loading more..."
                    : activekpis.hasNextPage
                    ? "Load More"
                    : "That's all we got!"}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <p className="bg-white text-xs text-gray-800 font-medium max-w-[600px] mx-auto  py-1 px-2 flex gap-3 items-center rounded my-2">
        <FiInfo size={20} />{" "}
        <span>
          Please bear in mind that as we continue to optimize our platform,
          there may be some inconsistencies in the information provided.
        </span>
      </p>
    </div>
  );
};

export default DisplayBoard;
