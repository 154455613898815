import React from "react";

const EstoreOrdersArchive = () => {
  return (
    <div className="col-span-3 h-full bg-white p-3">
      <p className="text-gray-800">Orders archive</p>
      <p className="text-gray-600 text-sm">
        Select period of orders you want to view.
      </p>
      <div className="mt-4">
        <div date-rangepicker className="flex items-center">
          <div>
            <p className="text-xs text-gray-500">Start date</p>
            <div className="relative w-full">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>
              <input
                name="start"
                type="text"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full ps-10 p-2.5  "
                placeholder="Select date start"
              />
            </div>
          </div>
          <span className="mx-4 text-gray-500">to</span>
          <div>
            <p className="text-xs text-gray-500">End date</p>
            <div className="relative w-full">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>
              <input
                name="end"
                type="text"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full ps-10 p-2.5  "
                placeholder="Select date end"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex item-center justify-center mt-8 hidden" >
        <button className="py-2.5 px-6 rounded bg-blue-500 text-white text-sm">View orders</button>
      </div>
    </div>
  );
};

export default EstoreOrdersArchive;
