import { UserProfileModel } from "../../../../../../models";
import { useAppSelector } from "../../../../../../hooks";
import { useQuery } from "@tanstack/react-query";
import { getCustomerSatisfactionScore } from "../../../../../../api/Utils";
import moment from "moment";

const ReviewsHomeSummary = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  let userReviews = [];

  const reviews = useQuery<any, Error>({
    queryKey: ["getCustomerSatisfactionScore", ""],
    queryFn: async () => {
      const payload = {
        platformKey: {
          account_reference: { S: queryKey },
        },
        pagination_key: "",
        timestamp: ["0.00", `${new Date(Date.now()).getTime() / 1000}`],
        commentSource: "NONE",
        commentLabel: "No Data",
      };

      const response = await getCustomerSatisfactionScore(payload);
      return response.data;
    },
  });

  if (reviews.data) {
    userReviews = reviews.data.Reviewers_Board;
  }

  return (
    <>
      <div className="gap-4 bg-gray-100 min-h-[calc(90vh-6.5rem)] w-[26%] p-2.5">
        <p className="text-sm font-semibold mb-2.5">Reviews</p>
        <div className="space-y-2">
          {userReviews.map((review: any, index: number) => (
            <div className="p-2 bg-white rounded-md text-gray-900" key={review.commentId}>
              <div className="flex justify-between items-center">
              <p className="text-[13px] mb-1.5 font-medium">{review.customer}</p>
              <p className="text-xs text-gray-500">{moment(review.uploadTime).format("MM/DD/YYYY")}</p>
              </div>
              <p className="text-xs">{review.userReview}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ReviewsHomeSummary;
