import OTPInput from "../../../../components/Auth/Forms/OTPInput";
import { classNames, getSvg } from "../../../../utils";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserPool from "../../../../setup/userpool";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import InputMessage from "../../../../components/Auth/Forms/InputMessage";
import Button from "../../../../components/Auth/Forms/Button";
import { ImSpinner2 } from "react-icons/im";
import { UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Alert from "../../../../components/Auth/Forms/Alert/indext";
import Modal from "../../../../components/Dashboard/Modal";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeHolder: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
}

const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
}: InputProps) => {
  return (
    <div className="w-full">
      <label className="block mt-4 text-xs font-medium text-gray-500">
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        disabled={disabled}
        className={classNames(
          className,
          "border border-gray-300 py-2.5 px-3 bg-white rounded-md w-full outline-none text-gray-800 text-sm "
        )}
      />
    </div>
  );
};

const initialValues = {
  code: "",
  nPassword: "",
  cNPassword: "",
};

const changePasswordSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, "Minimum 6 letters")
    .max(6, "Maximum 6 letters")
    .required("Code is required"),
  nPassword: Yup.string()
    .min(8, "Minimum 8 letters")
    .max(20, "Maximum 20 letters")
    .required("Password is required"),
  cNPassword: Yup.string()
    .oneOf([Yup.ref("nPassword")], "Passwords must match")
    .required("Required"),
});

const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;

const PasswordSettings = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const userEmail = user.email;
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [nPasswordIsVisible, setNPasswordIsVisible] = useState(false);
  const [cNPasswordIsVisible, setCNPasswordIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      setError("");
      const user = new CognitoUser({
        Username: `${userEmail}`,
        Pool: UserPool,
      });
      setError("");
      setLoading(true);
      user.confirmPassword(values.code, values.nPassword, {
        onSuccess: (data) => {
          setLoading(false);
          setSubmitting(false);
          resetForm({});
          setShowModal(true);
        },
        onFailure: (err) => {
          console.log(err);
          setLoading(false);
          setError(err.message);
          setSubmitting(false);
        },
      });
    },
  });

  const sendCode = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    if (email === "") {
      setError("This is a required field");
      setLoading(false);
      return;
    }

    if (email !== userEmail) {
      setError("This does not match the registered email");
      setLoading(false);
      return;
    }

    if (regEx.test(email)) {
      user.forgotPassword({
        onSuccess: (data) => {
          console.log(data);
          setLoading(false);
        },
        onFailure: (err) => {
          console.log(err);
          setLoading(false);
          setError(err.message);
        },

        inputVerificationCode: (data) => {
          console.log(data);
          setStep(2);
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
      setError("invalid email");
    }
  };

  return (
    <>
      <Modal
        size="medium"
        show={showModal}
        onToggle={() => {
          setShowModal(false);
          setStep(1);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img src={getSvg("check")} alt="icon" className="w-24 mx-auto" />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-medium">
              Your password has been successfully changed.
            </h1>
          </div>
        </div>
      </Modal>
      {step === 1 ? (
        <div className="relative mt-16">
          <div className="">
            <form className="max-w-[500px] px-4 mx-auto">
              <div className="">
                <div className="w-full mb-4">
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Enter your email address
                  </label>
                  <input
                    type="email"
                    placeholder="Enter email"
                    className="w-full px-2 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-md outline-none placeholder:text-sm"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <p className="text-red-600">{error}</p>
                </div>
              </div>
              <div className="flex items-center justify-center mt-8">
                <button
                  type="submit"
                  className="py-3 text-xs text-center text-white bg-blue-700 rounded-md outline-none  w-44"
                  onClick={sendCode}
                >
                  {loading ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Send verification code"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : step === 2 ? (
        <div className="relative h-full">
          <div className="max-w-[500px] px-4 mx-auto mt-4">
            <div className="flex items-center justify-center">
              <img src="/media/svg/sentMessage.svg" alt="reset password" className=""/>
            </div>
            <p className="max-w-full mx-auto text-sm text-center text-gray-500">
              We have sent a verifcation code to {email}
            </p>
           <div className="flex items-center justify-center mt-4" >
           <button
              type="button"
              className=" w-[60%] text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-sm px-8 py-2.5"
              onClick={() => setStep(3)}
            >
              Continue
            </button>
           </div>
          </div>
        </div>
        
      ) : (
        <div className="relative h-full">
          <div className="w-full">
            <form className="max-w-[500px] mx-auto" onSubmit={formik.handleSubmit}>
              {error && <Alert type="error" message={error} />}
              <div className="">
                <Input
                  formik={formik}
                  name="code"
                  label="Verification Code"
                  type="text"
                  placeHolder="Enter the 6 digit code sent to your email"
                />
                {formik.values.code && formik.errors.code && (
                  <InputMessage message={formik.errors.code} />
                )}
                <div className="relative">
                  <Input
                    formik={formik}
                    name="nPassword"
                    label="New Password"
                    // type={nPasswordIsVisible ? "text" : "password"}
                    type="text"
                    placeHolder=""
                  />
                  <button
                    type="button"
                    onClick={() => setNPasswordIsVisible(!nPasswordIsVisible)}
                    className="absolute hidden outline-none text-secondary right-4 inset-y-7"
                  >
                    {nPasswordIsVisible ? (
                      <AiOutlineEye size={25} />
                    ) : (
                      <AiOutlineEyeInvisible size={25} />
                    )}
                  </button>
                </div>
                {formik.values.nPassword && formik.errors.nPassword && (
                  <InputMessage message={formik.errors.nPassword} />
                )}
                <div className="relative">
                  <Input
                    formik={formik}
                    name="cNPassword"
                    label="Confirm New Password"
                    // type={cNPasswordIsVisible ? "text" : "password"}
                    type="text"
                    placeHolder=""
                  />
                  <button
                    type="button"
                    onClick={() => setCNPasswordIsVisible(!cNPasswordIsVisible)}
                    className="absolute hidden outline-none text-secondary right-4 inset-y-7"
                  >
                    {cNPasswordIsVisible ? (
                      <AiOutlineEye size={25} />
                    ) : (
                      <AiOutlineEyeInvisible size={25} />
                    )}
                  </button>
                </div>

                {formik.values.cNPassword && formik.errors.cNPassword && (
                  <InputMessage message={formik.errors.cNPassword} />
                )}
              </div>
              <div className="w-[190px] my-4">
                <p className="text-sm font-semibold text-[#0E4A67]">
                  Password Should Contain
                </p>
                <div className="mt-3">
                  <ul className="space-y-2 text-xs text-gray-700">
                    <li className="flex items-center gap-2">
                      {" "}
                      <img src="/media/svg/checkGrayBg.svg" alt="" />
                      <span>At least 8 characters</span>
                    </li>
                    <li className="flex items-center gap-2">
                      {" "}
                      <img src="/media/svg/checkGrayBg.svg" alt="" />
                      <span>An Uppercase Letter</span>
                    </li>
                    <li className="flex items-center gap-2">
                      {" "}
                      <img src="/media/svg/checkGrayBg.svg" alt="" />
                      <span>A Number</span>
                    </li>
                    <li className="flex items-center gap-2">
                      {" "}
                      <img src="/media/svg/checkGrayBg.svg" alt="" />
                      <span>A special Character e.g !,.,-,?</span>
                    </li>
                  </ul>
                </div>
              </div>
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="bg-blue-700 text-white text-center text-sm py-2.5 px-5 w-full sm:w-48 rounded-md"
              >
                 {formik.isSubmitting ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordSettings;
