import DataTable from "react-data-table-component";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineEdit } from "react-icons/md";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import PageHeader from "../../../components/Dashboard/PageHeader";
import { Searchbar } from "../../../components/Dashboard/Navbar";
import { classNames } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeHolder: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
}

const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
}: InputProps) => {
  return (
    <div className="w-full">
      <label className="block mb-1 text-xs font-normal text-gray-500">
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        disabled={disabled}
        className={classNames(
          className,
          "border-2 border-gray-500 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-400"
        )}
      />
    </div>
  );
};

const RoleManagerPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const columns = [
    {
      name: "Avatar",
      selector:(row: { avatar: any }) => <img className="my-2 rounded-full w-14 h-14" src={row.avatar} alt="avatar"/>,
    },
    {
      name: "Name",
      selector: (row: { name: any }) => row.name,
    },
    {
      name: "Contact",
      selector: (row: { contact: any }) => row.contact,
    },
    {
      name: "Address",
      selector: (row: { address: any }) => row.address,
    },
    {
      name: "Status",
      selector: (row: { status: any }) => row.status,
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      cell: (row: any) => (
        <div className="flex items-baseline gap-2">
          <MdOutlineEdit
            className="text-blue-500 hover:text-blue-700"
            size={22}
            onClick={(e) => handleButtonClick(e, row.id)}
          />
          <AiOutlineDelete
            className="text-red-500 hover:text-red-700"
            size={22}
            onClick={(e) => handleButtonClick(e, row.id)}
          />
        </div>
      ),
    },
  ];

  const handleButtonClick = (e: any, id: any) => {
    e.preventDefault();
    setIsOpen(true);
    console.log("Row Id", id);
  };

  const data = [
    {
      id: 1,
      avatar: "https://images.pexels.com/photos/16024824/pexels-photo-16024824.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      name: "Esther Howard",
      contact: "+2346797778012",
      address: "Southwest, Washington",
      status: "Admin",
    },
  ];

  const [filteredData, setFilteredData] = useState([]);
  const handleFilter = () => {};
  const [showModal, setShowModal] = useState(true);

  const navigate = useNavigate();
  return (
    <>
      <div className="space-y-1">
        <PageHeader title="Role Manager" />
        <div className="h-[80vh] bg-white rounded-md shadow-md text-2xl overflow-x-hidden">
          <div className="flex items-center justify-between p-2">
            <div className="w-full text-sm lg:w-1/3">
              <Searchbar />
            </div>

            <button
              onClick={() => setOpenAddUser(true)}
              className="p-2 px-4 py-2 text-sm font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
            >
              Create User
            </button>
          </div>
          <DataTable
            className="text-base"
            // @ts-ignore
            columns={columns}
            data={filteredData}
            selectableRows
            fixedHeader
            pagination
          />
        </div>

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <div className="flex justify-center m-4">
                      <img src="/media/image/delete_bin.png" alt="bin" />
                    </div>
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      You are about to delete this admin, are you sure you want to
                      continue?
                    </Dialog.Title>

                    <div className="grid grid-cols-2 gap-4 mt-4 text-white">
                      <button
                        type="button"
                        className="col-span-1 px-4 py-2 text-sm font-medium rounded-md bg-primary"
                        onClick={closeModal}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className=" rounded-md bg-[#B6B6B6] px-4 py-2 text-sm font-medium hover:bg-red-600"
                        onClick={closeModal}
                      >
                        No
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        <Transition appear show={openAddUser} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setOpenAddUser(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-xl p-6 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-xl">
                    <h1 className="text-gray-500">PROFILE</h1>
                    <div className="flex flex-col items-center justify-center gap-2 mb-4">
                      {/* <input type="file" className="hidden" /> */}
                      <img
                        className="w-20 h-20 rounded-full"
                        src="https://images.pexels.com/photos/16024824/pexels-photo-16024824.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt="Rounded avatar"
                      />
                      <p className="text-gray-500 font-sm">
                        Select an avatar to personalise your account
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <Input
                        // formik={formik}
                        name="firstName"
                        label="First Name"
                        type="text"
                        placeHolder="Ayo"
                      />
                      <Input
                        // formik={formik}
                        name="lastName"
                        label="Last Name"
                        type="text"
                        placeHolder="Bolu"
                      />
                      <Input
                        // formik={formik}
                        name="phoneNumber"
                        label="Phone Number"
                        type="text"
                        placeHolder="0810XXXXXXXXX"
                      />
                      <Input
                        // formik={formik}
                        name="email"
                        label="Email"
                        type="text"
                        placeHolder="@gmail.com"
                      />
                      <Input
                        // formik={formik}
                        name="address"
                        label="Address"
                        type="text"
                        placeHolder="40, Abuja Street"
                      />
                      <Input
                        // formik={formik}
                        name="country"
                        label="Country"
                        type="text"
                        placeHolder="Nigeria"
                      />
                    </div>
                    <Input
                      // formik={formik}
                      name="selectUser"
                      label="Select User"
                      type="text"
                      placeHolder="Select User"
                    />
                    <div className="grid grid-cols-2 gap-2 mt-4">
                    
                      <button
                        onClick={() => setOpenAddUser(false)}
                        className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setOpenAddUser(false)}
                        className="bg-transparent border-2 hover:bg-[#c62828] text-gray-500 hover:text-white font-bold py-2 px-4 rounded"
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      <Transition appear show={showModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[200]"
            onClose={() => {
              // setShowModal(false);
              navigate(-1);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[380px] md:w-[460px] lg:w-[520px] max-w-2xl transform text-center overflow-hidden rounded-md bg-white px-6 align-middle shadow-xl transition-all">
                    <div className="text-center my-14">
                      <h4 className="text-3xl font-semibold">Add More User Accounts</h4>
                      <p className="mt-2 text-base font-medium leading-tight text-gray-700">
                          You are currently on the free plan but getting more features is easy
                      </p>
                      <Link to="/settings/subscriptions" className="inline-block px-10 py-4 mt-8 text-xl font-semibold text-center text-white rounded-md bg-primary">Request An Upgrade</Link>
                      <p className="mt-3 text-base font-medium leading-tight text-gray-700">
                          Learn about our plans and pricing <Link to="/settings/subscriptions" className="underline text-primary underline-offset-1">here</Link>
                      </p>
                  </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
      </Transition>
    </>
  );
};

export default RoleManagerPage;
