// @ts-nocheck
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { base64Decode, classNames } from "../../../../utils";
import { useQuery } from "@tanstack/react-query";
import { getMenuByCategory, getSingleItem } from "../../../../api/Menu";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartItem, CartProps, SET_CART } from "../../../../redux/Utils";
import { ImSpinner8 } from "react-icons/im";
import toast from "react-hot-toast";
import ProductCard from "../../../../components/WebStore/Products/ProductCard";
import Layout from "../../../../components/WebStore/Layout";
import { useState } from "react";
import { StoreItem } from "../../../../models";
import { FaCircle } from "react-icons/fa";

const WebStoreSingleProductPage = () => {
  const { salt, slug, category, id } = useParams();
  const navigate = useNavigate();
  const selectedCategory = base64Decode(`${category}`);
  const productID = base64Decode(`${id}`);
  const queryKey = `/${salt}/${slug}`;
  let products: StoreItem[] = [];
  const dispatch = useAppDispatch();
  const cart: CartProps = useAppSelector((state) => state.utils.cart);
  let item: CartItem = {};
  const [itemColors, setItemColors] = useState([]);
  const [itemSizes, setItemSizes] = useState([]);

  let currentProduct: CartItem = {};
  const [options, setOptions] = useState({
    size: 0,
    color: "",
    variantArray: [],
  });

  const [imageUrl, setImageUrl] = useState("");
  const [activeImg, setActiveImg] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [itemDiscountPrice, setItemDiscountPrice] = useState(0);
  const [itemDefaultPrice, setItemDefaultPrice] = useState(0);
  const [itemAvailableStock, setItemAvailableStock] = useState(0);

  const getCart = () => Object.values(cart || {});

  const addToCart = (item: CartItem): void => {
    const itemCopy = { ...item, itemPrice: itemPrice };
    itemCopy.qty = 1;
    itemCopy.selectedPriceForItem = itemDiscountPrice
      ? itemDiscountPrice
      : itemPrice;
    itemCopy["itemSize"] = options.size;
    itemCopy["itemColor"] = options.color;
    itemCopy["itemVariantArray"] = options.variantArray;
    const key = `${itemCopy.itemName}-${itemCopy.itemSize}-${itemCopy.itemColor}`;
    itemCopy["key"] = key;

    const updatedCart = { [key]: itemCopy, ...cart };
    // console.log("updatedCart");
    // console.log(updatedCart);

    dispatch(SET_CART(updatedCart));
    toast.success(`${itemCopy.itemName} has been added to your cart.`);
  };

  const handleRemoveCartProduct = (itemName: string): void => {
    const updatedCart = { ...cart };
    delete updatedCart[itemName];

    dispatch(SET_CART(updatedCart));
  };

  const handleUpdateCartQuantity = (item: CartItem, operation: string) => {
    const updatedCart: CartProps = { ...cart };
    const key = `${item.itemName}-${options.size}-${options.color}`;
    const itemName = key;

    if (updatedCart[itemName]) {
      updatedCart[itemName] = {
        ...updatedCart[itemName],
        itemSize: options.size,
      };
      updatedCart[itemName] = {
        ...updatedCart[itemName],
        itemColor: options.color,
      };

      if (operation === "increase" && updatedCart !== undefined) {
        updatedCart[itemName] = {
          ...updatedCart[itemName],
          qty: updatedCart[itemName].qty + 1,
        };
      } else {
        updatedCart[itemName] = {
          ...updatedCart[itemName],
          qty: updatedCart[itemName].qty - 1,
        };
      }
    }

    dispatch(SET_CART(updatedCart));
  };

  const formatItemName = (name: string) => {
    return name.replaceAll("_", " ").toLowerCase();
  };

  const loadSingleItem = useQuery<any, Error>({
    queryKey: ["getSingleItem", productID],
    queryFn: async () => {
      const response = await getSingleItem(productID);
      return response.data;
    },
    enabled: !!productID,
    onSuccess: (data) => {
      // console.log(data);
      setImageUrl(data.Menu_Item.s3ImageUrl);
      setItemPrice(data.Menu_Item.itemPrice);
      setItemDiscountPrice(data.Menu_Item.discountPrice);
      setItemDefaultPrice(data.Menu_Item.itemPrice);
      setItemAvailableStock(data.Menu_Item?.available_stock);
    },
  });

  const allMenuByCategory = useQuery<any, Error>({
    queryKey: ["getMenuByCategory", loadSingleItem.data],
    queryFn: async () => {
      const response = await getMenuByCategory(queryKey, selectedCategory);
      return response.data;
    },
    enabled: !!loadSingleItem.data,
  });

  if (loadSingleItem.data) {
    item = loadSingleItem.data.Menu_Item;
    // console.log(item.itemVariations);
    currentProduct = getCart().find(
      (cartItem: CartItem) =>
        cartItem?.itemName?.toLowerCase() === item.itemName?.toLowerCase()
    );
    // console.log(item);
  }

  if (allMenuByCategory.data) {
    products = allMenuByCategory.data.Menu_Response?.filter(
      (product: CartItem) =>
        product?.itemName?.toLowerCase() !== item.itemName?.toLowerCase()
    );
  }

  const freeDeliveryStatus: string = useAppSelector(
    (state) => `${state.user.store.freeDelivery}`
  );

  // console.log(itemAvailableStock < 1);

  return (
    <Layout>
      <section className="bg-white">
        <div className="container px-4 py-2.5 md:py-6 md:px-14">
          <div className="flex flex-col items-start space-y-4 md:items-center md:flex-row md:space-y-0">
            <div
              onClick={() => navigate(-1)}
              className="flex items-center space-x-2 capitalize md:w-[10%] cursor-pointer"
            >
              <BsArrowLeft size={18} />
              <span>Back</span>
            </div>
          </div>
        </div>
        {loadSingleItem.status === "loading" ? (
          <div className="flex items-start justify-start h-screen py-5">
            <ImSpinner8
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : loadSingleItem.status === "error" ? (
          <div className="flex items-start justify-start h-screen py-5">
            Error: {loadSingleItem.error.message}
          </div>
        ) : (
          <div className="container flex flex-col items-start px-6 space-x-0 md:space-x-4 lg:space-x-5 md:flex-row md:px-20 lg:px-24">
            <div className="w-full space-y-3 md:w-1/2">
              <div className="relative">
                <img
                  src={imageUrl}
                  alt="item-image"
                  className="relative w-full bg-black/10 hover:bg-black/20 object-center bg-no-repeat object-cover rounded-md h-[20rem] sm:h-[22rem] md:h-[26rem] lg:h-[26rem] bg-blend-overlay transition ease-linear duration-200"
                />
                {freeDeliveryStatus === "1" && item.freeDelivery !== "0" && (
                  <p className="absolute top-3 right-2 py-2.5 px-3 bg-blue-600 text-white rounded text-xs">
                    Free delivery
                  </p>
                )}
              </div>
              <div className="grid grid-cols-4 gap-3 sm:gap-5 2xl:gap-3 justify-items-center place-items-center">
                {item.s3ImageUrl !== "" && (
                  <img
                    src={item.s3ImageUrl}
                    alt=""
                    onError={(e) => (e.target.style.display = "none")}
                    className={classNames(
                      "w-16 sm:w-20 lg:w-24 xl:w-28 max-w-[12rem] h-16 rounded-md object-cover cursor-pointer sm:h-20 xl:h-24 bg-black/10",
                      activeImg === 0 &&
                        imageUrl === item.s3ImageUrl &&
                        "border border-gray-300"
                    )}
                    onClick={() => {
                      setImageUrl(item.s3ImageUrl);
                      setActiveImg(0);
                    }}
                  />
                )}
                {item.s3ImageUrlOne !== "" && (
                  <img
                    src={item.s3ImageUrlOne}
                    alt=""
                    onError={(e) => (e.target.style.display = "none")}
                    className={classNames(
                      "w-16 sm:w-20 lg:w-24 xl:w-28 max-w-[12rem] h-16 rounded-md object-cover cursor-pointer sm:h-20 xl:h-24 bg-black/10",
                      activeImg === 1 &&
                        imageUrl === item.s3ImageUrlOne &&
                        "border border-gray-300"
                    )}
                    onClick={() => {
                      setImageUrl(item.s3ImageUrlOne);
                      setActiveImg(1);
                    }}
                  />
                )}
                {item.s3ImageUrlTwo !== "" && (
                  <img
                    src={item.s3ImageUrlTwo}
                    alt=""
                    onError={(e) => (e.target.style.display = "none")}
                    className={classNames(
                      "w-16 sm:w-20 lg:w-24 xl:w-28 max-w-[12rem] h-16 rounded-md object-cover cursor-pointer sm:h-20 xl:h-24 bg-black/10",
                      activeImg === 2 &&
                        imageUrl === item.s3ImageUrlTwo &&
                        "border border-gray-300"
                    )}
                    onClick={() => {
                      setImageUrl(item.s3ImageUrlTwo);
                      setActiveImg(2);
                    }}
                  />
                )}
                {item.s3ImageUrlThree !== "" && (
                  <img
                    src={item.s3ImageUrlThree}
                    alt=""
                    onError={(e) => (e.target.style.display = "none")}
                    className={classNames(
                      "w-16 sm:w-20 lg:w-24 xl:w-28 max-w-[12rem] h-16 rounded-md object-cover cursor-pointer sm:h-20 xl:h-24 bg-black/10",
                      activeImg === 3 &&
                        imageUrl === item.s3ImageUrlThree &&
                        "border border-gray-300"
                    )}
                    onClick={() => {
                      setImageUrl(item.s3ImageUrlThree);
                      setActiveImg(3);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="w-full py-5 space-y-2 md:space-y-2.5 lg:space-y-2 text-2xl text-gray-800 md:py-1 md:w-1/2">
              <h4 className="capitalize text-xl">
                {formatItemName(item.itemName)}
              </h4>
              <div className="flex items-center gap-2">
                {itemDiscountPrice && (
                  <h6 className="text-2xl font-medium text-blue-600">
                    &#8358;{itemDiscountPrice}
                  </h6>
                )}
                <h6
                  className={classNames(
                    "",
                    itemDiscountPrice
                      ? "line-through text-gray-600 text-lg font-normal"
                      : "text-blue-600 text-2xl font-medium"
                  )}
                >
                  &#8358;{itemPrice}
                </h6>
              </div>
              <p className="text-sm font-normal text-gray-700">
                {item.itemDescription || item.description}
              </p>
              <div className="">
                {item?.itemVariations?.length > 0 && (
                  <div className="">
                    <p className="text-sm text-gray-800 font-medium mb-1">
                      Variations
                    </p>
                    {item?.itemVariations?.length > 0 && (
                      <div className="flex gap-2 flex-wrap">
                        {item.itemVariations.map((item: any, index: number) => (
                          <button
                            key={index}
                            onClick={() => {
                              setOptions({
                                ...options,
                                color: item.color,
                                size: item.size,
                                variantArray: [{ variantId: item.variantId }],
                              });
                              setItemAvailableStock(item.stock);
                              setItemPrice(item.price);
                              setItemDiscountPrice(item.discountPrice);
                            }}
                            className={classNames(
                              "border text-xs font-medium uppercase rounded-md px-2 py-1.5 disabled:cursor-default",
                              options?.variantArray[0]?.variantId ===
                                item?.variantId
                                ? "border-blue-500 text-blue-500"
                                : item.stock > 0
                                ? "text-gray-800 border-gray-500"
                                : "text-gray-400 border-gray-400"
                            )}
                          >
                            {item.color !== "null" && item.color}
                            {(item.color !== null || item.size !== null) &&
                              ", "}
                            {item.size !== "null" && item.size}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {itemAvailableStock < 1 ? null : (
                <div className="flex items-center gap-1 mb-4">
                  <FaCircle size={18} className="text-green-700" />
                  <p className="text-sm font-semibold ">
                    Only {itemAvailableStock} left
                  </p>
                </div>
              )}

              <div className="mt-10 space-y-3">
                <button
                  // disabled={itemAvailableStock === 0}
                  type="button"
                  onClick={() => {
                    const key = `${item.itemName}-${options.size}-${options.color}`;

                    if (getCart().find((cartItem) => cartItem.key === key)) {
                      handleUpdateCartQuantity(item, "increase");
                      toast.success(
                        `${item.itemName} has been added to your cart.`
                      );
                      return;
                    }
                    addToCart(item);
                  }}
                  className="md:w-[42%] lg:w-[30%] w-[45%] py-2.5 px-3 text-sm font-medium text-center hover:text-blue-600 text-white bg-blue-600 hover:bg-transparent border border-blue-600 rounded-md transition duration-200 ease-linear disabled:bg-blue-200 disabled:border-none disabled:cursor-not-allowed"
                >
                  Add to cart
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="container px-4 pt-8 pb-16 mt-6 md:px-14">
          <h5 className="mb-6 text-lg font-semibold text-gray-800">
            Other Products
          </h5>
          <div className="grid grid-cols-2 max-w-[1100px] mx-auto gap-x-4 sm:gap-x-6 gap-6 lg:gap-10 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {allMenuByCategory.status === "loading" ? (
              [1, 2, 3, 4, 5].map((index: number) => (
                <div
                  className="bg-gray-100 h-[230px] rounded-md"
                  key={index}
                ></div>
              ))
            ) : allMenuByCategory.status === "error" ? (
              <span>Error: {allMenuByCategory.error.message}</span>
            ) : products.length > 0 ? (
              products.map((product: CartItem, index: number) => {
                return <ProductCard key={index} product={product} />;
              })
            ) : (
              <h5 className="text-base text-left text-gray-600">
                No Other Products yet.
              </h5>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default WebStoreSingleProductPage;
