import React from "react";
import CustomerSatisfactionComponentNew from "../../../../../../components/Dashboard/DashBoardContainer/CustomerSatisfaction/CustomerSatisfactionComponentNew";

const SatisfactionTracker = () => {
  return (
    <>
      <CustomerSatisfactionComponentNew />
    </>
  );
};

export default SatisfactionTracker;
