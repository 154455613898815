import { useEffect, useRef, useState } from "react";
import Layout from "../../../../components/WebStore/Layout";
import Modal from "../../../../components/Dashboard/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMessage from "../../../../components/Auth/Forms/InputMessage";
import { ImSpinner8 } from "react-icons/im";
import { useParams } from "react-router-dom";
import { cancelOrderStatus, getOrderDetails } from "../../../../api/Utils";
import { CartItem } from "../../../../redux/Utils";

const initialValues = {
  orderID: "",
  email: "",
};

const validationSchema = Yup.object().shape({
  orderID: Yup.string().max(40).required("Order ID is required"),
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
});

const WebStoreCancelOrderPage = () => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover1, setShowPopover1] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const { salt, slug, category } = useParams();
  const queryKey: string = `/${salt}/${slug}`;
  const [selectedItemId, setSelectedItemId] = useState(-1);
  const [orderInfo, setOrderInfo] = useState<any>({});

  const popoverRef = useRef<any>();

  const handleClickOutside = (e: any) => {
    if (!popoverRef.current.contains(e.target)) {
      setSelectedItemId(-1);
      setShowPopover(false);
      setShowPopover1(false);
    }
  };

  const handleClickInside = () => {
    setSelectedItemId(-1);
    setShowPopover(false);
    setShowPopover1(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      setMessage("");

      const response = await getOrderDetails(values.orderID, values.email);

      if (response.data.status_code === 403) {
        setMessage("Invalid order details");
        setSubmitting(false);
        return;
      }

      if (response.data.status_code === 200) {
        setOrderInfo(response.data.status_payload);
        resetForm({});
        setSubmitting(false);
        setSuccess(true);
        return;
      }

      setSubmitting(false);
    },
  });

  const handleCancelOrder = async () => {
    // setShowCancelModal(false);
    // setSuccess(true);
    setLoading(true);

    const payload = {
      slugUrl: queryKey,
      // orderId: orderInfo.orderId,
      orderId: selectedItemId,
      emailAddress: orderInfo.billingDetail.email,
    };

    const response = await cancelOrderStatus(payload);

    if (response.data.status_code === 200) {
      setLoading(false);
      setShowCancelModal(false);
      setSelectedItemId(-1);
      setShowSuccessModal(true);
      return;
    }

    setLoading(false);
  };


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <Layout>
      <section className="bg-white">
        <div className="container px-4 pb-28 md:pb-14 pt-10 md:pt-14 md:px-14 mt-10 my-20">
          {!success && (
            <div className="w-full md:w-[60%] mx-auto">
              <div className="w-full mb-8 space-y-1 text-left">
                <h4 className="text-gray-900 text-xl font-semibold sm:text-2xl lg:text-3xl">
                  Order status
                </h4>
                <p className="text-sm font-medium text-gray-700 max-w-[500px]">
                  To check your order status please enter your Order ID and
                  email address to process cancellation.
                </p>
              </div>
              <div>
                {message !== "" && (
                  <div className="px-2 py-2 mb-4 text-sm text-red-500 border border-red-300 rounded-md bg-red-50 md:text-base">
                    {message}
                  </div>
                )}
                <form onSubmit={formik.handleSubmit} className="space-y-4">
                  <div className="flex flex-col space-y-0.5">
                    <label className="text-xs text-gray-700">Order ID</label>
                    <input
                      type="text"
                      {...formik.getFieldProps("orderID")}
                      className="px-3.5 py-2.5 text-black border border-gray-200 rounded-md outline-none placeholder:text-gray-400"
                    />
                    {formik.errors.orderID ? (
                      <div className="text-xs">
                        <InputMessage message={formik.errors.orderID} />
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col space-y-0.5">
                    <label className="text-xs text-gray-700">
                      Email Address
                    </label>
                    <input
                      type="text"
                      {...formik.getFieldProps("email")}
                      className="px-3.5 py-2.5 text-black border border-gray-200 rounded-md outline-none placeholder:text-gray-400"
                    />
                    {formik.errors.email ? (
                      <div className="text-xs">
                        <InputMessage message={formik.errors.email} />
                      </div>
                    ) : null}
                  </div>
                  <div className="pt-4">
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className="block py-3 text-sm text-center text-white bg-blue-600 rounded w-48"
                    >
                      {formik.isSubmitting ? (
                        <ImSpinner8 className="w-6 h-6 mx-auto animate-spin" />
                      ) : (
                        "Proceed"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {success && (
            <div className="w-full md:max-w-[950px] mx-auto flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h4 className="font-semibold text-lg">
                  Order ID: {orderInfo.orderId}
                </h4>
                <button
                  type="button"
                  onClick={() => setShowCancelModal(true)}
                  className="text-xs sm:text-sm text-red-600 py-1.5 px-3 rounded-md border border-red-600 hidden"
                >
                  Cancel order
                </button>
              </div>
              <div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div className="col-span-1 md:col-span-3 pl-0 p-3">
                    <h5 className="text-base text-gray-800 font-semibold mb-3 p-1 border-b-[1px] border-gray-300">
                      Items in your order
                    </h5>
                    <div className="flex flex-col gap-4">
                      {orderInfo?.orderRecord?.map(
                        (item: any, index: number) => {
                          const imgUrl = item.imageId.replace(
                            "/cusecho",
                            "https://d1ntmcvl0pjnmq.cloudfront.net"
                          );

                          return (
                            <div
                              key={index}
                              className="flex items-center justify-between gap-2"
                            >
                              <div className="flex gap-4">
                                <div className="">
                                  <img
                                    src={imgUrl}
                                    alt="img"
                                    className="w-16 h-16 rounded-md sm:h-24 sm:w-24"
                                  />
                                </div>
                                <div className="py-1">
                                  <h6 className="text-[13px] font-medium sm:text-sm">
                                    {item.itemName} x{item.quantityOrder}
                                  </h6>
                                  <h4 className="text-xs font-medium">
                                    &#8358;{item.itemPrice}
                                  </h4>
                                  <h4 className="text-xs font-medium text-gray-500 inline-flex items-center gap-2">
                                    Order status:{" "}
                                    <span>{item.orderStatus}</span>
                                  </h4>
                                  <div className="flex flex-col">
                                    {"itemSize" in item &&
                                      item?.itemSize !== "" && (
                                        <h6 className="text-xs font-medium text-gray-500 inline-flex items-center gap-2">
                                          Size: {item.itemSize}
                                        </h6>
                                      )}
                                    {"itemColor" in item &&
                                      item?.itemColor !== "" && (
                                        <h6 className="text-xs font-medium text-gray-500 inline-flex items-center gap-2">
                                          Color: {item.itemColor}
                                        </h6>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="relative">
                                <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    // if (
                                    //   orderInfo.paymentDescription.includes(
                                    //     "Bank Transfer"
                                    //   )
                                    // ) {
                                    //   setSelectedItemId(item.order_specific_id);
                                    //   setShowPopover(true);
                                    //   return;
                                    // }
                                    // if (item.orderStatus !== "Pending") {
                                    //   setSelectedItemId(item.order_specific_id);
                                    //   setShowPopover1(true);
                                    //   return;
                                    // }
                                    setSelectedItemId(item.order_specific_id);
                                    setShowCancelModal(true);
                                  }}
                                  className="text-xs text-red-600 py-2 px-3 rounded-md border border-red-600 hidden sm:block"
                                >
                                  Cancel order
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      orderInfo.paymentDescription.includes(
                                        "Bank Transfer"
                                      )
                                    ) {
                                      setSelectedItemId(item.order_specific_id);
                                      setShowPopover(true);
                                      return;
                                    }
                                    if (item.orderStatus !== "Pending") {
                                      setSelectedItemId(item.order_specific_id);
                                      setShowPopover1(true);
                                      return;
                                    }
                                    setSelectedItemId(item.order_specific_id);
                                    setShowCancelModal(true);
                                  }}
                                  className="text-xs text-red-600 sm:hidden"
                                >
                                  Cancel
                                </button>
                                </div>
                                {showPopover &&
                                  selectedItemId === item.order_specific_id && (
                                    <div
                                      className="p-2 text-xs text-gray-600 rounded border border-gray-50 absolute w-[220px] z-10 top-10 right-0 bg-white shadow-md"
                                      ref={popoverRef}
                                      onClick={handleClickOutside}
                                    >
                                      <p>
                                        Payment for this order was done via Bank
                                        Transfer. Contact the vendor for
                                        cancellation.
                                      </p>
                                    </div>
                                  )}
                                {showPopover1 &&
                                  selectedItemId === item.order_specific_id && (
                                    <div
                                      className="p-2 text-xs text-gray-600 rounded border border-gray-50 absolute w-[220px] z-10 top-10 right-0 bg-white shadow-md"
                                      ref={popoverRef}
                                      onClick={handleClickOutside}
                                    >
                                      <p>
                                        Order processing has started. This order
                                        cannot be cancelled.
                                      </p>
                                    </div>
                                  )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 md:col-span-1 flex flex-col gap-4 md:p-3 md:border border-gray-200 rounded-md h-fit">
                    <div className="text-sm font-normal flex-col gap-2">
                      <h4 className="text-sm text-[#494949]">Payment method</h4>
                      <p>{orderInfo.paymentDescription}</p>
                    </div>

                    <div className="text-[#494949]">
                      <h4 className="mb-1 text-sm text-[#494949]">
                        Order details
                      </h4>
                      <div className="flex flex-col gap-1 text-sm ">
                        <div className="flex items-center justify-between gap-1">
                          <p className="text-gray-500">Item name</p>
                          <p className="text-gray-500">Total</p>
                        </div>
                        <div className="flex items-center justify-between gap-1">
                          <p className="whitespace-nowrap">Sub total</p>
                          <p>&#8358;{orderInfo.transactionData.totalAmount}</p>
                        </div>
                        {Object.keys(orderInfo.billingDetail.deliveryDetail)
                          .length !== 0 && (
                          <div className="flex items-center justify-between gap-1">
                            <p className="whitespace-nowrap">Delivery</p>
                            <p>
                              &#8358;
                              {orderInfo.billingDetail.deliveryDetail.fee}
                            </p>
                          </div>
                        )}

                        <div className="flex items-center justify-between gap-1">
                          <p className="whitespace-nowrap">Coupon</p>
                          <p className="">-&#8358;0.00</p>
                        </div>
                        <div className="flex items-center justify-between gap-1">
                          <p className="whitespace-nowrap">Total</p>
                          <p>
                            &#8358;
                            {Object.keys(orderInfo.billingDetail.deliveryDetail)
                              .length === 0
                              ? orderInfo.transactionData.totalAmount
                              : Number(orderInfo.transactionData.totalAmount) +
                                Number(
                                  orderInfo.billingDetail.deliveryDetail.fee
                                )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="text-sm font-normal flex-col gap-2">
                      <h4 className="text-sm text-[#494949]">
                        Delivery method
                      </h4>
                      <p>
                        {Object.keys(orderInfo.billingDetail.deliveryDetail)
                          .length === 0
                          ? "Pickup"
                          : "Delivery"}
                      </p>
                    </div>
                    {Object.keys(orderInfo.billingDetail.deliveryDetail)
                      .length !== 0 && (
                      <div className="text-sm font-normal flex-col gap-2">
                        <h4 className="text-sm text-[#494949]">
                          shipping address
                        </h4>
                        <p>{orderInfo.billingDetail.address}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <Modal
        show={showCancelModal}
        onToggle={() => {
          // formik.resetForm({});
          setSelectedItemId(-1);
          setShowCancelModal(false);
        }}
        size="medium"
      >
        <div className="relative flex flex-col h-full px-4 py-5">
          <div className="my-4 space-y-3 text-center md:my-6">
            <h1 className="font-medium text-base text-gray-800">
              Are you sure you want to cancel this order?
            </h1>
          </div>
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={() => setShowCancelModal(false)}
              className="block py-2 px-3 text-sm text-blue-600 bg-transparent border border-blue-600 rounded-md w-fit"
            >
              {"No"}
            </button>
            <button
              type="button"
              onClick={handleCancelOrder}
              className="block py-2 text-sm text-white bg-red-600 rounded-md w-32 border border-red-500"
            >
              {isLoading ? (
                <ImSpinner8 className="w-5 h-5 mx-auto animate-spin" />
              ) : (
                "Cancel order"
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={showSuccessModal}
        onToggle={() => {
          // formik.resetForm({});
          setShowSuccessModal(false);
        }}
        size="medium"
      >
        <div className="relative flex flex-col h-full px-4 py-5">
          <div className="mt-2 text-center md:mt-4">
            <img
              src="/media/svg/check.svg"
              alt="icon"
              className="w-24 mx-auto"
            />
          </div>
          <div className="my-2 space-y-2 text-center md:my-6">
            <h1 className="font-semibold md:text-2xl text-gray-800">
              {"Order cancelled"}
            </h1>
            <p className="text-sm text-gray-700 font-light">
              The vendor of the product would reach out to you shortly.
            </p>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default WebStoreCancelOrderPage;
