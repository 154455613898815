import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdMoreVert } from "react-icons/md";
import { StoreModel, UserProfileModel } from "../../../../../models";
import { useAppSelector } from "../../../../../hooks";
import Modal from "../../../../../components/Dashboard/Modal";
import { BiChevronLeft } from "react-icons/bi";
import { AiOutlineInfoCircle } from "react-icons/ai";

const SendSMSMessage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeName = store.name;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [emailObject, setEmailObject] = useState({
    email: "",
    subject: "",
    bodyText: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPopOver, setShowPopOver] = useState(false);

  return (
    <>
      <Modal
        show={showSuccessModal}
        onToggle={() => setShowSuccessModal(false)}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img
              src="/media/svg/check.svg"
              alt="icon"
              className="mx-auto w-28"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-2xl">
              Message Successfully Sent!
            </h1>
          </div>
        </div>
      </Modal>
      <div className="space-y-1">
        <div className="flex items-center justify-between py-1">
          <h4 className="text-base text-gray-800 capitalize">New Message</h4>
          
        </div>
        <div className="h-[75vh] bg-white rounded-md flex flex-col space-y-2 p-4">
          <div className="flex items-center justify-between">
            <BiChevronLeft
              size={24}
              onClick={() => navigate(-1)}
              className="cursor-pointer"
            />
            <MdMoreVert size={22} className="hidden" />
          </div>
          <form className="flex flex-col flex-1 h-full space-y-2">
            <div className="flex flex-col w-full mx-auto mb-2 sm:mb-4 sm:gap-2 sm:items-center sm:flex-row">
              <label className="block text-sm font-normal text-gray-600 sm:w-1/6">
                Phone Number
              </label>
              <input
                type="text"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full p-2 text-gray-900 border border-gray-300 rounded-lg outline-none sm:text-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <textarea
              id="message"
              name="message_body"
              rows={5}
              minLength={100}
              maxLength={800}
              value={emailObject.bodyText}
              onChange={(e) =>
                setEmailObject({ ...emailObject, bodyText: e.target.value })
              }
              className="flex-1 block w-full p-3 text-sm text-gray-900 bg-transparent border rounded-md outline-none focus:border-blue-500"
            ></textarea>

            <div className="flex items-center gap-2">
              <div className="relative">
                <button
                  type="submit"
                  className="px-8 py-2 text-sm font-semibold bg-white border rounded-md hadow-sm text-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      store.name === undefined ||
                      store.name === "N/A" ||
                      store.name === ""
                    ) {
                      setShowPopOver((prevState) => !prevState);
                      return;
                    }
                  }}
                >
                  Send
                </button>
                {showPopOver && (
                  <div className="bg-white w-[350px] absolute z-100 bottom-12 right-0 rounded-lg shadow-lg p-4">
                    <div className="flex gap-3 mb-4">
                      <AiOutlineInfoCircle size={30} />
                      <p className="text-sm font-medium text-gray-800">
                        You need to add a store name before you can send a
                        message
                      </p>
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        onClick={() => navigate("/settings/profile")}
                        className="px-5 py-2 text-sm text-white rounded-md bg-primary"
                      >
                        Add Store Name
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SendSMSMessage;
