import axios from "axios";
import { API_BASE_URL, API_BASE_URL_PUBLIC } from "../../utils";

const REVIEW_URL = API_BASE_URL;

// export const addReview = async (data: any) => axios.post(`${API_BASE_URL_PUBLIC}/upload_review`, data);
export const addReview = async (data: any) => axios.post(`${API_BASE_URL_PUBLIC}/send_feedback_message`, data);
export const getReviewTracker = async (data: any) => axios.get(`${REVIEW_URL}/review_tracker?queryKey=${data}`);
// export const getReviewersHistory = async (data: any) => axios.get(`${REVIEW_URL}/reviewers_history?platformKey=${data}`);
export const getCustomerReviewHistory = async (data: any) => axios.post(`${REVIEW_URL}/customer_review_history`, data);
export const getReviewersHistory = async (platformKey: string, paginationKey: string) => axios.get(`${REVIEW_URL}/reviewers_history?platformKey=${platformKey}&pagination_key=${paginationKey}`);

