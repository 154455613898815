import { useEffect, useRef } from "react";
import { METRICS_COLORS } from "../../../../utils";
import { useAppSelector } from "../../../../hooks";
import { UserProfileModel } from "../../../../models";
import ApexCharts from "apexcharts";
import SkeletonLoader from "../../../../components/Dashboard/SkeletonLoader";
import { useQuery } from "@tanstack/react-query";
import { getQuestionFeedbackChart } from "../../../../api/Utils";


const Reviews = () => {
  const chartRef = useRef(HTMLDivElement.prototype);
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = `${user.platformAccountId}%26${user.email}`;

  const { data, status, error } = useQuery<any, Error>({
    queryKey: ["getQuestionFeedbackChart"],
    queryFn: async () => {
      const response = await getQuestionFeedbackChart(queryKey);
      return response.data;
    },
    select: (data) => {
      const newData = data?.reverse();
      return newData;
    },
  });

  // console.log("feedbacks", data)

  useEffect(() => {
    if (!data) {
      return;
    }
    
    let options = {
      chart: {
        type: "bar",
        width: "100%",
        height: "100%",
        toolbar: {
          show: false,
        },
      },
      colors: ["#3DB832", "#CD151E"],
      series: [
        {
          name: "Positive",
          data: [...data.map((question: any, index: number) => {
            return parseFloat(question.pos_count.toString().replaceAll("%", ""));
          })],
        },
        {
          name: "Negative",
          data: [...data.map((question: any, index: number) => {
            return parseFloat(question.neg_count.toString().replaceAll("%", ""));
          })],
        },
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [...data.map((question: any, index: number) => {
          // const sc = question.question_tag.toString().replaceAll("Question_", "SM ")
          // return sc;
          return question.question_tag.toString().replaceAll("_", " ");
        })],
        labels: {
          style: {
            colors: [...data.map((question: any, index: number) => {
              return `${METRICS_COLORS[index]}`;
            })]
          }
        }
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: 100,
        labels: {
          style: {
            colors: ["#aaaaaa"],
          },
        },
      },
    };

    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    
    return () => {
      chart.destroy();
    };
  }, [data]);

  if (status === "loading")
  return <SkeletonLoader className="h-[206px] sm:h-[210px] md:h-[206px] lg:h-[191px] 2xl:h-[202px] rounded-md" />;

  if (status === "error") return <span>Error: {error.message}</span>;

  return(
    <div className="py-2 bg-white rounded-md shadow-md h-[206px] sm:h-[210px] md:h-[206px] lg:h-[191px] 2xl:h-[202px] overflow-hidden">
      <div className="flex items-center justify-between px-3">
        <h4 className="text-xs font-semibold text-gray-500">Feedback</h4>
        {/* <h4 className="text-xs font-semibold text-gray-500">SM: Success Metric</h4> */}
      </div>
      <div
        className="h-full pb-2 xl:pb-4"
      >
        <div ref={chartRef} id="reviews-chart" className="w-full h-full"></div>
      </div>
    </div>
  );
};

export default Reviews;
