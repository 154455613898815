import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import moment from "moment";
import { LOGIN, LOGOUT, SET_TOKEN } from "../../../redux/Auth";
import { useInterval } from "react-use";
import Modal from "../Modal";
import { SESSION_TIME_KEY } from "../../../utils";
import { IoTimeOutline } from "react-icons/io5";
import { generateAuthToken } from "../../../api/Auth";
import { ImSpinner2 } from "react-icons/im";
import { useQueryClient } from "@tanstack/react-query";
import { REMOVE_USER } from "../../../redux/User";
import { RESET_UTILS } from "../../../redux/Utils";
import UserPool from "../../../setup/userpool";

interface Props {
  show: boolean;
}

const SessionTimeOut = (props: Props) => {
  const navigate = useNavigate();
  const userAuth = useAppSelector((state) => state.auth.userData);
  const [timer, setTimer] = useState(60);
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const reLogin = async () => {
    setLoading(true);
    // return await new Promise((resolve, reject) => {
    //     const Username = values.email;
    //     const Password = values.password;
    //     const user = new CognitoUser({ Username, Pool: UserPool });

    //     const authDetails = new AuthenticationDetails({
    //       Username,
    //       Password,
    //     });

    //     user.authenticateUser(authDetails, {
    //       onSuccess: (data) => {
    //         // console.log(data);
    //         resolve(data);
    //         const jwt = data.getIdToken().getJwtToken();
    //         // console.log(jwt);
    //         const headers = {
    //           Authorization: jwt,
    //         };
    //         fetch(
    //           "https://6xvjwq05xd.execute-api.eu-west-2.amazonaws.com/dev/generate_account_id",
    //           { headers }
    //         )
    //           .then((response) => response.json())
    //           .then((data) => {
    //             const acctId = data.Platform_Account;

    //             const requestOptions = {
    //               method: "POST",
    //               headers: {
    //                 Authorization: jwt,

    //               },
    //               body: null,
    //             };

    //             fetch(
    //               `https://6xvjwq05xd.execute-api.eu-west-2.amazonaws.com/dev/debugger?accountId=${acctId}`,
    //               requestOptions
    //             )
    //               .then((response) => response.json())
    //               .then((data) => {
    //                 console.log("success_post,");
    //               })

    //           })
    //           .catch((error) => {
    //             console.error("There was an error!", error);
    //           });

    //         user.getUserAttributes(function (err, result) {
    //           let data = result as CognitoUserAttribute[];

    //           // console.log(result);

    //           if (err) {
    //             setError(err.message);
    //             setSubmitting(false);
    //             return;
    //           }

    //           let _attributes: any = {};

    //           data.forEach((attr) => (_attributes[attr.Name] = attr.Value));

    //           const userData: UserProfileModel = {
    //             username: _attributes["sub"],
    //             firstName: _attributes["name"],
    //             emailVerified:
    //               _attributes["email_verified"] === "true" ? true : false,
    //             lastName: _attributes["family_name"],
    //             email: _attributes["email"],
    //             address: _attributes["address"],
    //             country: _attributes["locale"],
    //           };

    //           const storeData: StoreModel = {
    //             name: _attributes["custom:storeName"],
    //             address: _attributes["address"],
    //             country: _attributes["locale"],
    //             currency: _attributes["custom:currrency"],
    //           };

    //           if (data.length < 7) {
    //             localStorage.setItem("completeRegistration", "1");
    //             navigate("/register");
    //             dispatch(LOGIN({username: values.email ,password: values.password}));
    //             dispatch(SET_USER({ profile: userData, store: storeData }));
    //             return;
    //           }

    //           dispatch(LOGIN({username: values.email ,password: values.password}));
    //           dispatch(SET_USER({ profile: userData, store: storeData }));
    //           setSubmitting(false);
    //           navigate("/dashboard");
    //         });
    //       },
    //       onFailure: (err) => {
    //         // console.error(err);
    //         reject(err);
    //         setError(err.message);
    //         setSubmitting(false);
    //       },
    //       newPasswordRequired: (data) => {
    //         resolve(data);
    //         setSubmitting(false);
    //       },
    //     });
    // });

    try {
      localStorage.setItem(SESSION_TIME_KEY, moment().format());
      const response = await generateAuthToken();
      if (response) {
        dispatch(
          LOGIN({ username: userAuth.username, password: userAuth.password })
        );
        dispatch(SET_TOKEN({ token: response.access_token }));
        setLoading(false);
        setTimer(60);
      }
    } catch (err) {}

    if (isLoading) setLoading(false);
  };

  const logOut = (withTimer: boolean = true) => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
    } else {
      localStorage.removeItem("amplify-redirected-from-hosted-ui");
      localStorage.removeItem("amplify-signin-with-hostedUI");
      sessionStorage.removeItem("ouath_pkce_key");
      for (var i in localStorage) {
        for (var i in localStorage) {
          if (
            localStorage.hasOwnProperty(i) &&
            i.indexOf("CognitoIdentityServiceProvider") > -1
          ) {
            localStorage.removeItem(i);
          }
        }
      }
      localStorage.removeItem("customerEchoNewUser");
      if (withTimer) {
        setTimer(60);
      }
      dispatch(LOGOUT());
      dispatch(REMOVE_USER());
      dispatch(RESET_UTILS());
      queryClient.clear();
      navigate("/login", { replace: true });
    }
  };

  useInterval(() => {
    if (props.show) {
      if (timer <= 0) {
        logOut();
      }
      setTimer((prev) => --prev);
    }
  }, 1000);

  return (
    <Modal show={props.show} onToggle={(val) => logOut()}>
      <div className="flex flex-col p-6 md:py-12 md:px-16">
        <div>
          {/* <img className="mx-auto" src={getSVG("timeout.svg")} alt="timeout" /> */}
          <IoTimeOutline size={80} className="mx-auto text-red-600" />
        </div>
        <div className="mt-5 text-center md:mt-8">
          <h1 className="md:text-lg">
            You will be automatically logged out in{" "}
            <span className="font-bold text-primary">
              {timer < 0 ? 0 : timer}
            </span>{" "}
            seconds.
          </h1>
        </div>
        <div className="flex justify-center gap-2 mt-6 text-sm md:mt-10">
          <div>
            <button
              onClick={() => logOut()}
              className="w-full px-8 py-2 font-bold text-center text-white bg-red-500 border rounded-md"
            >
              Logout
            </button>
          </div>
          <div>
            <button
              onClick={() => reLogin()}
              className="px-3.5 py-2 font-bold text-center text-white border rounded-md w-44 md:px-5 lg:px-7 bg-primary"
            >
              {isLoading ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Stay logged in"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SessionTimeOut;
