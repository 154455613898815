import { classNames } from "../../../utils";

interface Props {
  className?: string;
}

const SkeletonLoader = (props: Props) => {
  return (
    <div
      role="status"
      className={classNames(
        "max-w-full animate-pulse bg-gray-100",
        props.className
      )}
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default SkeletonLoader;
