import { useState } from "react";
import { base64Decode, classNames } from "../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineSettings } from "react-icons/md";
import CategoriesType from "./_components/CategoriesType";
import ItemsType from "./_components/ItemsType";
import { StoreModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import Modal from "../../../../components/Dashboard/Modal";
import AddNewProduct from "./_components/AddNewProduct";
import OTPInput from "../../../../components/Auth/Forms/OTPInput";
import { useQuery } from "@tanstack/react-query";
import {
  downloadPasscodeEmbedder,
  handleGeneratePasscode,
} from "../../../../api/Menu";
import { ImSpinner2 } from "react-icons/im";
import toast, { Toaster } from "react-hot-toast";

const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [code1, setCode1] = useState("");
  const [loading, setLoading] = useState(false);

  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = `${store.name}`;
  const text = `${window.location.origin}/store${storeKey}`;
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const platformKey = `${user.platformAccountId}%26${user.email}`;
  const platformKey1 = `${user.platformAccountId}&${user.email}`;

  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  let passcodeEmbedderObj: any;
  let position: any;

  // console.log(code)
  // console.log(code1)

  const handleChange = (value: string) => {
    setCode(value);
  };
  const handleChange1 = (value: string) => {
    setCode1(value);
  };

  // console.log(code)

  const handlePasscodeEmbedder = useQuery<any, Error>({
    queryKey: ["getPasscodeEmbedder"],
    queryFn: async () => {
      const response = await downloadPasscodeEmbedder(platformKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
      if (data.key_state === "none") {
        setShowModal1(true);
      }
    },
  });

  if (handlePasscodeEmbedder.data) {
    passcodeEmbedderObj = handlePasscodeEmbedder.data;
    if (handlePasscodeEmbedder.data.key_state === "none") {
      const a = base64Decode(passcodeEmbedderObj.decryption_key);
      const c = a.replaceAll("'", '"');
      let b = JSON.parse(c);

      position = b;
    }
    // console.log(passcodeEmbedderObj)
  }
  const handleSubmitPasscode = async () => {
    setLoading(true);
    if (code !== code1) {
      alert("You inputed a wrong code");
      setLoading(false);
      return;
    }

    const p1 = position.POS_1;
    const p2 = position.POS_2;
    const p3 = position.POS_3;
    const p4 = position.POS_4;
    const p5 = position.POS_5;
    const p6 = position.POS_6;

    const positionss = [p1, p2, p3, p4, p5, p6];

    const replacements = code.split("");

    let token = passcodeEmbedderObj.embedding_token.split("");
    replacements.forEach((k, i) => {
      token[positionss[i]] = k;
    });

    const s = token.join("");

    const payload = {
      platformKey: platformKey1,
      encryptString: [s],
    };

    // console.log(payload);

    try {
      const response = await handleGeneratePasscode(payload);
      setLoading(false);
      toast.success("Passcode updated successfully");
      setShowModal1(false);
      console.log(response);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred, try again");
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onToggle={() => {
          setShowModal(false);
        }}
      >
        <div className="relative flex flex-col p-3 md:py-6 md:px-8 overflow-y-auto h-[75vh]">
          <div className="">
            <AddNewProduct />
          </div>
        </div>
      </Modal>

      <Modal
        size="medium"
        show={showModal1}
        onToggle={() => {
          setShowModal1(false);
        }}
      >
        {step === 1 && (
          <div className="relative flex flex-col">
            <div className="text-center">
              <img
                src={"/media/image/password-pin.png"}
                alt="icon"
                className="w-full"
              />
            </div>
            <div className="p-6 pt-3 text-sm space-y-1">
              <p className="font-semibold text-gray-900">Coded encryption</p>
              <p className="text-gray-800">
                Create a code to secure and access your sensitive data.
              </p>
              <p
                className="text-right font-semibold cursor-pointer"
                onClick={() => setStep(2)}
              >
                Continue
              </p>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="relative flex flex-col">
            <div className="p-6 pt-3 text-sm space-y-1">
              <p className="font-semibold text-gray-900 hidden">
                Enter a 6 digit code
              </p>
              <div>
                <OTPInput
                  label="Enter a 6 digit code"
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={code.length < 6}
                  onClick={() => setStep(3)}
                  className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4 "
                >
                  Save pin
                </button>
              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="relative flex flex-col">
            <div className="p-6 pt-3 text-sm space-y-1">
              <p className="font-semibold text-gray-900 hidden">
                Enter a 6 digit code
              </p>
              <div>
                <OTPInput
                  label="Re-enter a 6-digit code"
                  onChange={handleChange1}
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  // disabled={formik.isSubmitting}
                  onClick={() => {
                    handleSubmitPasscode();
                  }}
                  className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-4 "
                >
                  {loading ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Confirm pin"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <div className="min-h-[calc(100vh-6.5rem)] space-y-3">
        <div className="flex items-center justify-between">
          <p className="font-semibold">Products</p>
          <div className="flex gap-4 text-[12.5px]">
            <button
              className="px-2.5 py-1.5 text-gray-600 font-medium rounded bg-white border border-text-700"
              onClick={() => {
                window.open(`/store${storeKey}`);
              }}
            >
              View store
            </button>
            <button
              className="px-2.5 py-1.5 text-gray-600 font-medium rounded bg-white border border-text-700"
              onClick={() => {
                navigator.clipboard.writeText(text);
                toast.success("Copied to clipboard");
              }}
            >
              Copy link
            </button>
            <button
              className="px-2.5 py-1.5 text-white font-medium rounded border border-blue-700 bg-blue-600"
              onClick={() => {
                // setShowModal(true);
                navigate("/estore/products/add-product");
              }}
            >
              Add product
            </button>
            <button
              className="px-2 py-1.5 text-gray-700 font-medium rounded border border-gray-200"
              onClick={() => {
                navigate("/estore/settings/security");
              }}
            >
              <MdOutlineSettings size={20} />
            </button>
          </div>
        </div>
        <div className="flex items-center gap-2 text-sm text-gray-800">
          <p
            onClick={() => {
              navigate("/estore/products?type=items");
            }}
            className={classNames(
              "font-semibold pb-1 px-2 cursor-pointer",
              type === "items" ? " border-b-[1px] border-gray-900" : ""
            )}
          >
            Items
          </p>
          <p
            onClick={() => {
              navigate("/estore/products?type=categories");
            }}
            className={classNames(
              "font-semibold pb-1 px-2 cursor-pointer",
              type === "categories" ? " border-b-[1px] border-gray-900" : ""
            )}
          >
            Categories
          </p>
        </div>
        <div className="">
          {type === "items" && <ItemsType />}

          {type === "categories" && <CategoriesType />}
        </div>
      </div>

      <Toaster position="top-center" containerClassName="" gutter={200} />
    </>
  );
};

export default Products;
