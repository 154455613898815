import { useRef } from "react";

interface OTPIputProps {
  label: string;
  onChange: (value: string) => void;
}

const OTPInput = ({ label, onChange }: OTPIputProps) => {
  const input1Ref = useRef(HTMLInputElement.prototype);
  const input2Ref = useRef(HTMLInputElement.prototype);
  const input3Ref = useRef(HTMLInputElement.prototype);
  const input4Ref = useRef(HTMLInputElement.prototype);
  const input5Ref = useRef(HTMLInputElement.prototype);
  const input6Ref = useRef(HTMLInputElement.prototype);

  const value = useRef("");

  const inputValue = useRef({ 1: "", 2: "", 3: "", 4: "", 5: "", 6: "" });

  const handleChange = (index: 1 | 2 | 3 | 4 | 5 | 6, _value: string) => {
    inputValue.current[index] = _value;
    const inputs = [
      input1Ref,
      input2Ref,
      input3Ref,
      input4Ref,
      input5Ref,
      input6Ref,
    ];
    if (index !== 6) {
      if (_value !== "") inputs[index].current.focus();
    }

    value.current =
      inputValue.current[1].toString() +
      inputValue.current[2].toString() +
      inputValue.current[3].toString() +
      inputValue.current[4].toString() +
      inputValue.current[5].toString() +
      inputValue.current[6].toString();
    onChange(value.current);
  };

  return (
    <>
      <label className="block mb-2 text-xs font-normal text-gray-800" >
        {label}
      </label>
      <div className="grid grid-cols-6 gap-2 md:gap-3 lg:gap-5">
        <input
          ref={input1Ref}
          onChange={(event) => handleChange(1, (event.target.value))}
          type="password"
          pattern="[0-9]"
          minLength={0}
          maxLength={1}
          className={`border py-1.5 px-1 text-3xl text-center bg-white rounded-md outline-none text-gray-900 border-gray-400`}
        />
        <input
          ref={input2Ref}
          onKeyUp={(event) => { if (event.code.toLowerCase() === "backspace") input1Ref.current.focus() }}
          onChange={(event) => handleChange(2, (event.target.value))}
          type="password"
          pattern="[0-9]"
          minLength={0}
          maxLength={1}
          className={`border py-1.5 px-1 text-3xl text-center bg-white rounded-md outline-none text-gray-900 border-gray-400`}
        />
        <input
          ref={input3Ref}
          onKeyUp={(event) => { if (event.code.toLowerCase() === "backspace") input2Ref.current.focus() }}
          onChange={(event) => handleChange(3, (event.target.value))}
          type="password"
          pattern="[0-9]"
          minLength={0}
          maxLength={1}
          className={`border py-1.5 px-1 text-3xl text-center bg-white rounded-md outline-none text-gray-900 border-gray-400`}
        />
        <input
          ref={input4Ref}
          onKeyUp={(event) => { if (event.code.toLowerCase() === "backspace") input3Ref.current.focus() }}
          onChange={(event) => handleChange(4, (event.target.value))}
          type="password"
          pattern="[0-9]"
          minLength={0}
          maxLength={1}
          className={`border py-1.5 px-1 text-3xl text-center bg-white rounded-md outline-none text-gray-900 border-gray-400`}
        />
        <input
          ref={input5Ref}
          onKeyUp={(event) => { if (event.code.toLowerCase() === "backspace") input4Ref.current.focus() }}
          onChange={(event) => handleChange(5, (event.target.value))}
          type="password"
          pattern="[0-9]"
          minLength={0}
          maxLength={1}
          className={`border py-1.5 px-1 text-3xl text-center bg-white rounded-md outline-none text-gray-900 border-gray-400`}
        />
        <input
          ref={input6Ref}
          onKeyUp={(event) => { if (event.code.toLowerCase() === "backspace") input5Ref.current.focus() }}
          onChange={(event) => handleChange(6, (event.target.value))}
          type="password"
          pattern="[0-9]"
          minLength={0}
          maxLength={1}
          className={`border py-1.5 px-1 text-3xl text-center bg-white rounded-md outline-none text-gray-900 border-gray-400`}
        />
      </div>
    </>
  );
};

export default OTPInput;
