// @ts-nocheck

import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import React, { Fragment, useState } from "react";
import { MdMoreVert } from "react-icons/md";
import {
  delectS3Object,
  deleteSingleMenu,
  downloadStoreItems,
} from "../../../../../api/Menu";
import { StoreModel, UserProfileModel } from "../../../../../models";
import { useAppSelector } from "../../../../../hooks";
import { Menu, Transition } from "@headlessui/react";
import { IoMdMore } from "react-icons/io";
import Modal from "../../../../../components/Dashboard/Modal";
import { ImSpinner2, ImSpinner3 } from "react-icons/im";
import { useNavigate } from "react-router-dom";

const ItemsType = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey: string = user?.queryKey || "";
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const navigate = useNavigate();
  // console.log(queryKey)

  let allItems: any[] = [];

  const getStoreItems = useInfiniteQuery<any, Error>({
    queryKey: ["getStoreItems"],
    queryFn: async ({ pageParam = "" }) => {
      const paginationKey = pageParam;
      const response = await downloadStoreItems(queryKey, paginationKey);
      return response.data;
    },
    getNextPageParam: (lastPage, pages) => {
      // console.log(lastPage)
      if (lastPage !== null) {
        if ("LastEvaluatedKey" in lastPage) {
          if (lastPage.LastEvaluatedKey === "") {
            return false;
          }
          return lastPage.LastEvaluatedKey;
        }
      }
    },
  });

  const deleteMenu = useMutation({
    mutationFn: async (data) => {
      // const payload = {
      //   itemId: data.itemId,
      // };

      const payload = {
        itemId: data.itemId,
        slugUrl: data.slugUrl,
        categoryName: data.categoryName,
      };
      return await deleteSingleMenu(payload);
    },
    onSuccess: () => {
      setSelectedObject({});
      queryClient.invalidateQueries({ queryKey: ["getMenuByCategory"] });
      queryClient.invalidateQueries({ queryKey: ["getStoreItems"] });
      setDeleteItemModal(false);
    },
  });

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        objectKey: data.objectKey,
      };
      return await delectS3Object(payload);
    },
  });

  if (getStoreItems.data) {
    if ("pages" in getStoreItems.data) {
      getStoreItems.data.pages.forEach((page) => {
        if ("StoreItems" in page) {
          allItems = [...allItems, ...page.StoreItems];
          // console.log(allItems)
        }
      });
    }
  }

  // console.log(selectedObject)

  return (
    <>
      <Modal
        show={deleteItemModal}
        onToggle={() => {
          setDeleteItemModal(false);
          setSelectedObject({});
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-2 space-y-2 md:mt-2">
            <p className="font-semibold text-center text-gray-700">
              You are about to delete this item from{" "}
              {selectedObject.itemCategory}
            </p>
          </div>
          <div className="flex flex-col justify-center md:flex-row gap-2 mt-5 md:mt-8">
            <div>
              <button
                disabled={deleteMenu.isLoading}
                className="px-4 py-2 text-xs font-semibold text-center text-white bg-blue-700 hover:bg-blue-800 border rounded-md w-full md:w-44 md:px-6 lg:px-8"
                onClick={(e) => {
                  deleteMenu.mutate({
                    itemId: selectedObject.menuItemId,
                    slugUrl: selectedObject.slugUrl,
                    categoryName: selectedObject.itemCategory,
                  });
                  handleDeleteS3Object.mutate({
                    objectKey: selectedObject.s3ImageUrl[0].imageIdentifier,
                  });
                  // handleReduceItemCount.mutate();
                }}
              >
                {deleteMenu.isLoading ? (
                  <ImSpinner2 size={24} className="mx-auto animate-spin" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-2 text-xs font-semibold text-center text-blue-700 bg-white hover:bg-gray-50 border border-blue-700 rounded-md  w-full md:w-44 md:px-6 lg:px-8"
                onClick={() => {
                  setSelectedObject({});
                  setDeleteItemModal(false);
                }}
              >
                {"Cancel"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div>
        {getStoreItems.status === "loading" ? (
          <div className="flex items-center justify-start py-5">
            <ImSpinner3
              size={18}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        ) : getStoreItems.status === "error" ? (
          <p className="mt-8 text-sm font-semibold text-center text-gray-400">
            {getStoreItems.error.message}
          </p>
        ) : allItems.length > 0 ? (
          <div className="grid grid-cols-2 gap-8 gap-y-4">
            {allItems.map((item: any, index: number) => (
              <div
                className="flex gap-2.5 items-center pb-4 border-b-[1px] border-gray-300"
                key={index}
                // onClick={() => {
                //   navigate(
                //     `/estore/products/${item.menuItemId}`
                //   );
                // }}
              >
                <div className="">
                  <img
                    src={item.s3ImageUrl}
                    alt=""
                    className="w-[100px] h-[80px] object-cover"
                  />
                </div>
                <div className="flex justify-between w-full">
                  <div className="flex flex-col gap-0.5 text-sm w-[65%]">
                    <p className="font-semibold">{item.itemName}</p>
                    <p className="text-[13px] font-medium">
                      {item.description}
                    </p>
                    <p className="text-[13px] font-medium">
                      Available stock: {item.available_stock}
                    </p>
                  </div>
                  <div className="text-sm ml-auto">
                    <div className="flex items-center gap-4">
                      <p className="font-semibold">&#8358; {item.itemPrice}</p>
                      {/* <MdMoreVert className="text-gray-800 cursor-pointer" /> */}
                      <div className="flex items-center gap-2 ml-auto">
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="w-full text-blue-800 mt-1">
                              <IoMdMore />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 mt-1 w-36 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-10">
                              <div className="px-1 py-1 ">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${
                                        active
                                          ? "bg-slate-100 font-semibold"
                                          : "text-gray-900 font-medium"
                                      } w-full rounded-md px-4 py-2 text-xs text-left`}
                                      onClick={(e) => {
                                        navigate(
                                          `/estore/products/${item.menuItemId}`
                                        );
                                      }}
                                    >
                                      View Item
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${
                                        active
                                          ? "bg-slate-100 font-semibold"
                                          : "text-gray-900 font-medium"
                                      } w-full rounded-md px-4 py-2 text-xs text-left`}
                                      onClick={() => {
                                        navigate(
                                          `/estore/products/edit-product/${item.menuItemId}`
                                        );
                                      }}
                                    >
                                      Edit Item
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${
                                        active
                                          ? "bg-slate-100 font-semibold"
                                          : "text-gray-900 font-medium"
                                      } w-full rounded-md px-4 py-2 text-xs text-left`}
                                      onClick={() => {
                                        setSelectedObject(item);
                                        setDeleteItemModal(true);
                                      }}
                                    >
                                      Delete Item
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center mt-20" >
           <div>
           <img src="/media/image/empty-box-image.png" alt="empty-box" />
            <p className="mt-8 text-xs text-center text-gray-700">
            There are no products yet!
            </p>
           </div>
          </div>
        )}

        {allItems.length > 0 && getStoreItems.hasNextPage && (
          <button
            onClick={() => getStoreItems.fetchNextPage()}
            disabled={
              !getStoreItems.hasNextPage || getStoreItems.isFetchingNextPage
            }
            className="text-xs block w-40 py-1.5 mx-auto mt-12 text-center text-gray-800"
          >
            {getStoreItems.isFetchingNextPage ? "Please wait..." : "See more"}
          </button>
        )}
        {allItems.length > 0 && !getStoreItems.hasNextPage && (
          <p className="mt-12 text-sm font-medium text-center text-gray-400">
            That's it!
          </p>
        )}
      </div>
    </>
  );
};

export default ItemsType;
