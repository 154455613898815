import { useAppSelector } from "../../hooks";
import { generatePaymentToken } from "../Auth";


export const postAccountDetail = async (payload: any, apiKey:string) => {
    const response = await generatePaymentToken();
    const token = response.access_token;
    await fetch(
      `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/update_payment_detail`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-API-KEY": apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    ).then((response) => response.json());
  };