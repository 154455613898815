import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CartItem {
  itemName?: string;
  itemPrice?: number;
  qty?: number;
  menuItemId?: string;
  itemId?: string;
  itemDescription?: string;
  quantityOrder?: number;
  itemCategory?: string;
  freeDelivery?: string;
  itemSize?: string | number;
  itemColor?: string;
  key?: string;
  s3ImageUrl?: any;
  imageId?: any;
}

export interface CartProps {
  [productId: string]: CartItem;
}

export interface KPIEmbedState {
  status: "Pending" | "Completed" | "Cancelled";
  active: boolean;
};

export interface IUtilsState {
  bpt_scores: number[];
  cart: CartProps;
  kpi_embed: KPIEmbedState;
}

const initialState: IUtilsState = {
  bpt_scores: [],
  cart: {},
  kpi_embed: {
    status: "Pending",
    active: false,
  }
}

const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    SET_BPT_SCORES: (state, action: PayloadAction<number[]>) => {
      state.bpt_scores = action.payload;
    },
    SET_CART: (state, action: PayloadAction<CartProps>) => {
      state.cart = action.payload;
    },
    SET_KPI_EMBED: (state, action: PayloadAction<KPIEmbedState>) => {
      state.kpi_embed = action.payload;
    },
    RESET_UTILS: () => {
      return initialState;
    },
  }
});

export const { SET_BPT_SCORES, SET_CART, SET_KPI_EMBED, RESET_UTILS } = utilsSlice.actions;

export default utilsSlice.reducer;