// @ts-nocheck

import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTiktok } from "react-icons/fa";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import {
  getOpeningHours,
  getSiteTitleAndTagLine,
  getStoreAddress,
  getStoreFooterDetails,
} from "../../../../../../../api/Menu";
import { Link } from "react-router-dom";
import { classNames } from "../../../../../../../utils";
import { FiEdit2 } from "react-icons/fi";

const FooterSection = ({ setSidePanel, sidePanel, setShowModals, showModals, formik5, setSelectedObject, setOpeningDaysFields, formik3 }) => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  let storeAddress: string = "";
  let allOpeningHours = [];
  let siteTitle: string = "";

  let storeFooterDetails: any = {
    Contact_Phone: "",
    Contact_Email: "",
    Linkedin_Url: "",
    Facebook_Url: "",
    Twitter_Url: "",
    Instagram_Url: "",
  };


  const allStoreFooterDetails = useQuery<any, Error>({
    queryKey: ["getStoreSocials"],
    queryFn: async () => {
      const response = await getStoreFooterDetails(storeKey);
      return response.data;
    },
    onSuccess: (data) => { },
  });

  const siteTitleAndTagLine = useQuery<any, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(storeKey);
      return response.data;
    },
    onSuccess: (data) => { },
  });

  const openingHours = useQuery<any, Error>({
    queryKey: ["getOpeningHours"],
    queryFn: async () => {
      const response = await getOpeningHours(storeKey);
      return response.data;
    },
  });

  const address = useQuery<any, Error>({
    queryKey: ["getStoreAddress"],
    queryFn: async () => {
      const response = await getStoreAddress(storeKey);
      return response.data;
    },
  });

  if (address.data) {
    storeAddress = address.data.Store_Address;
  }

  if (siteTitleAndTagLine.data) {
    siteTitle = siteTitleAndTagLine.data.Site_Title;
  }

  if (allStoreFooterDetails.data) {
    storeFooterDetails = allStoreFooterDetails.data;
  }

  if (openingHours.data) {
    const opening = openingHours.data.Opening_Hours;
    // console.log(opening)
    if(Array.isArray(opening)){
      const result = openingHours.data.Opening_Hours.map((x) => {
        const day = Object.keys(x)[0];
        const dayObj = x[day][0];
        return { ...dayObj, day };
      });
      allOpeningHours = result;
    } 
  }

  return (
    <>
      <footer
        className={classNames(
          "relative cursor-pointer",
          sidePanel === "socials" ? "border-2 border-blue-800 p-3" : ""
        )}
        onClick={() => {
          setSidePanel("socials");
        }}
      >
        <div className="bg-[#111827] text-gray-300 px-4 pt-10 pb-8 relative">
          <div className="sm:grid sm:grid-cols-2 lg:grid-cols-3">
            <div className="flex flex-col sm:flex-row sm:justify-between gap-16 sm:gap-4 col-span-2">
              <div className="">
                <p className="mb-3 text-sm font-semibold text-gray-100 capitalize">Contact us</p>
                <div className="space-y-2 text-xs">
                  <div className="flex gap-2 items-center"><p>{storeAddress}</p><FiEdit2 size={18} className="cursor-pointer customizer_mobile_css" onClick={() => {
                    setShowModals("address");
                    formik3.setFieldValue("address", storeAddress);
                  }} /></div>
                  <p>{storeFooterDetails.Contact_Phone ?? ""}</p>
                  <p>{storeFooterDetails.Contact_Email ?? ""}</p>
                  <div className="flex items-center mt-2 space-x-2.5 fill-white">
                    {storeFooterDetails.Facebook_Url !== "" && (
                      <Link to={storeFooterDetails.Facebook_Url} target="_blank">
                        <FaFacebookF size={20} />
                      </Link>
                    )}

                    {storeFooterDetails.Twitter_Url !== "" && (
                      <Link to={storeFooterDetails.Twitter_Url} target="_blank">
                        <BsTwitter size={20} />
                      </Link>
                    )}

                    {storeFooterDetails.Linkedin_Url !== "" && (
                      <Link to={storeFooterDetails.Linkedin_Url} target="_blank">
                        <FaLinkedinIn size={20} />
                      </Link>
                    )}

                    {storeFooterDetails.Instagram_Url !== "" && (
                      <Link to={storeFooterDetails.Instagram_Url} target="_blank">
                        <FaInstagram size={20} />
                      </Link>
                    )}

                    {/* {storeFooterDetails.TikTok_Url !== "" && (
                      <Link to={storeFooterDetails.TikTok} target="_blank">
                        <FaTiktok size={20} />
                      </Link>
                    )} */}
                  </div>
                </div>

              </div>
              <div className="">
                <p className="mb-3 text-sm font-semibold text-gray-100 capitalize">Opening hours</p>
                <div className="space-y-2 text-xs">
                  {openingHours.isLoading ? (<>Loading</>) : openingHours.isError ? (<>Error</>) : Array.isArray(allOpeningHours) && allOpeningHours?.map((day, index: number) => (
                    <div
                      className={classNames(
                        "flex items-center gap-8 justify-between",
                        day.switch_button === "0" ? "opacity-70" : ""
                      )}
                      key={index}>
                      <p className="">{day.day}</p>
                      <div className="flex items-center gap-6"><p className="whitespace-nowrap">{day.start_time} - {day.close_time}</p><FiEdit2 size={18} onClick={() => {
                        setSelectedObject(day);
                        setOpeningDaysFields({
                          start_time: day.start_time,
                          close_time: day.close_time,
                        });
                        setShowModals("openingDays");
                      }} className="cursor-pointer customizer_mobile_css" /></div>
                    </div>
                  ))}

                  {/* {allOpeningHours?.map((day, index: number) => (
                    <div
                      className={classNames(
                        "flex items-center gap-8 justify-between",
                        day.switch_button === "0" ? "opacity-70" : ""
                      )}
                      key={index}>
                      <p className="">{day.day}</p>
                      <div className="flex items-center gap-6"><p className="whitespace-nowrap">{day.start_time} - {day.close_time}</p><FiEdit2 size={18} onClick={() => {
                        setSelectedObject(day);
                        setOpeningDaysFields({
                          start_time: day.start_time,
                          close_time: day.close_time,
                        });
                        setShowModals("openingDays");
                      }} className="cursor-pointer customizer_mobile_css" /></div>
                    </div>
                  ))} */}

                </div>
                <div>

                </div>
              </div>
            </div>
            <div className="col-span-1 w-full lg:w-[70%] max-w-full ml-auto mt-16 sm:mt-0">
              <p className="mb-3 text-sm font-semibold text-gray-100 capitalize">Help</p>
              <div className="space-y-2 text-xs">
                <p>Leave a complaint</p>
                <p>Order status</p>
                <p>Cancel order</p>
              </div>
            </div>
          </div>
          <p className="text-xs text-center mt-12 font-semibold">Powered by <a href="http://www.cusecho.com" className="underline">Cusecho</a></p>

          <p className="absolute top-10 sm:top-5 right-4 flex items-center gap-4 customizer_mobile_css cursor-pointer" onClick={(e) => {
            e.stopPropagation();
            setShowModals("footer");
            formik5.setValues({
              phoneNumber: storeFooterDetails.Contact_Phone,
              email: storeFooterDetails.Contact_Email,
              linkedIn_url: storeFooterDetails.Linkedin_Url,
              facebook_url: storeFooterDetails.Facebook_Url,
              twitter_url: storeFooterDetails.Twitter_Url,
              instagram_url: storeFooterDetails.Instagram_Url,
              tiktok_url: storeFooterDetails.TikTok_Url ?? ""
            });
          }} ><span className="text-sm">Edit Socials</span><FiEdit2 size={18} className="cursor-pointer" /></p>
        </div>

      </footer>
    </>
  );
};

export default FooterSection;
