import SatisfactionTracker from "./_components/SatisfactionTracker";
import CommentTracker from "./_components/CommentTracker";
import KPITracker from "./_components/KPITracker";
import { getImage } from "../../../../../utils";
import BPTComponent from "./_components/BPTComponent";


const CustomerSatisfactionPageNew = () => {
 

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 h-[250px]" > 
          <SatisfactionTracker />
          <CommentTracker />
          <KPITracker />
        </div>
        <BPTComponent />
      </div>
    </>
  );
};

export default CustomerSatisfactionPageNew;
