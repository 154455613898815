// @ts-nocheck

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React from 'react'
import { ImSpinner2 } from 'react-icons/im';
import { StoreModel, UserProfileModel } from '../../../../../../../models';
import { useAppSelector } from '../../../../../../../hooks';
import { getAboutUs, uploadAboutUs } from '../../../../../../../api/Menu';
import * as Yup from "yup";
import InputMessage from '../../../../../../../components/Auth/Forms/InputMessage';

const initialValues = {
  aboutUsDetails: "",
}

const ValidationSchema = Yup.object().shape({
  aboutUsDetails: Yup.string()
    .max(800, "Maximum of 800 characters")
});
const AboutUsSidePanel = ({ setShowModals, showModals }) => {

  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const queryKey = user.queryKey;
  const storeKey = store.name;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  let aboutUs: string = "";

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      setSubmitting(true);
      const payload = {
        platformKey,
        aboutUs: values.aboutUsDetails,
      }

      try {
        const response = await uploadAboutUs(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries({ queryKey: ["getStoreAboutUs"] });
          setSubmitting(false);
          resetForm({});
          if (showModals === "aboutUs") {
            setShowModals(null);
          }
          return;
        }
        // setError(response.data.message);
      } catch (err) {
        console.log({ err });
      }
      setSubmitting(false);
    },
  });

  const storeAboutUs = useQuery<any, Error>({
    queryKey: ["getStoreAboutUs"],
    queryFn: async () => {
      const response = await getAboutUs(storeKey);
      return response.data;
    },
    onSuccess: (data) => {
      if(data.About_Us !== "Tell your customers about yourself on your website.")
      formik.setFieldValue("aboutUsDetails", data.About_Us);
    }
  });

  if (storeAboutUs.data) {
    aboutUs = storeAboutUs.data.About_Us;
  }

  return (
    <>
      <div className='p-3'>
        <div>
          <div className="mb-3">
            <h1 className="text-xl text-gray-800 font-semibold">About</h1>
            <p className="text-sm text-gray-700" >Who are you? What are your brand goals and vision? </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <textarea name="aboutUsDetails" id="" cols={30} rows={10} className="text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-sm" value={formik.values.aboutUsDetails} onChange={formik.handleChange}></textarea>
            {formik.errors.aboutUsDetails && (
              <InputMessage message={formik.errors.aboutUsDetails} />
            )}
            <div className="flex items-center justify-center mt-4">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="text-sm w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
              >
                {formik.isSubmitting ? (
                  <ImSpinner2 size={20} className="mx-auto animate-spin" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AboutUsSidePanel;