// @ts-nocheck

import React, { useState, useRef } from "react";
import { Input } from "../../EchoWebpage1";
import { useFormik } from "formik";
import { StoreModel, UserProfileModel } from "../../../../../../../models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../../../../hooks";
import {
  BASE_URL_WEBPAGE_IMAGE,
  delectS3Object,
  getSiteTitleAndTagLine,
  uploadSiteTitleAndTagLine,
} from "../../../../../../../api/Menu";
import { ImSpinner2 } from "react-icons/im";
import HeroImage from "./HeroImage";
import { MdDelete } from "react-icons/md";
import Compressor from "compressorjs";

const initialValues = {
  siteTitle: "",
  tagLine: "",
};

const HeroSidePanel = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;

  const [heroDetails, setHeroDetails] = useState({
    siteTitle: "",
    tagLine: "",
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const inputRef = useRef(null);
  const heroImagePath = "/cusecho" + store.name + "/heroImage/image.png";

  const imageHandler = (e: any) => {
    // if (!e.target.files || e.target.files.length === 0) {
    //   setSelectedImage(null);
    //   return;
    // }
    setSelectedImage(e.target.files[0]);
  };

  const handleCompressedUpload = async (file: any) => {
    setIsLoading(true);
    new Compressor(file, {
      quality: 0.4,
      success: (compressedResult: any) => {
        let path: string;
        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };
        path = heroImagePath;
        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then(() => {
                setIsLoading(false);
                setImageUploadSuccess(true);
                inputRef.current.value = null;
                queryClient.invalidateQueries({
                  queryKey: ["getHeroBackgroundImageUrl"],
                });
                setSelectedImage(null);
                setPreview(null);
                setTimeout(() => {
                  setImageUploadSuccess(false);
                }, 3000);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };

  const uploadImage = useMutation({
    mutationFn: async (data: File) => {
      return handleCompressedUpload(data);
    },
  });

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data) => {
      const payload = {
        objectKey: heroImagePath,
      };
      return await delectS3Object(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getHeroBackgroundImageUrl"],
      });
    },
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (selectedImage?.size > 2072576) {
        alert(
          "Please select an image with a size of less than or equal to 2MB"
        );
        setSelectedImage(null);
        return;
      }
      setSubmitting(true);
      const payload = {
        platformKey,
        siteTitle:
          values.siteTitle === "" ? heroDetails.siteTitle : values.siteTitle,
        tagLine: values.tagLine === "" ? heroDetails.tagLine : values.tagLine,
      };

      try {
        const response = uploadSiteTitleAndTagLine(payload);
        if (selectedImage) {
          uploadImage.mutate(selectedImage);
        }
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ["downloadSiteTitleAndTagLine"],
          });
          setSubmitting(false);
          resetForm({});
        }, 3000);
      } catch (err) {
        console.log({ err });
        setSubmitting(false);
      }
    },
  });

  const siteTitleAndTagLine = useQuery<any, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(storeKey);
      return response.data;
    },
    onSuccess: (data) => {
      setHeroDetails({
        siteTitle: data.Site_Title,
        tagLine: data.Tagline,
      });
    },
  });

  return (
    <div className="p-3">
      <h1 className="text-lg text-gray-800 mb-3 font-semibold">Main</h1>
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        <div className="space-y-4">
          <div className="w-full">
            <Input
              formik={formik}
              name="siteTitle"
              label="Main Text"
              type="text"
              placeHolder="Please enter your store name"
            />
          </div>
          <div className="w-full">
            <Input
              formik={formik}
              name="tagLine"
              label="Tagline"
              type="text"
              placeHolder="Please enter tagline"
            />
          </div>
        </div>
        {/* <HeroImage /> */}
        <div className="my-3 space-y-2.5">
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="formFile"
                className="mb-0.5 inline-block text-gray-900 text-xs"
              >
                Choose background image
              </label>
              <MdDelete
                className="cursor-pointer text-red-500"
                onClick={() => {
                  handleDeleteS3Object.mutate();
                  setSelectedImage(null);
                  setPreview(null);
                  inputRef.current.value = null;
                }}
              />
            </div>
            <input
              ref={inputRef}
              className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-sm font-medium text-gray-900 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none "
              type="file"
              accept="image/jpeg, image/png, image/jpg"
              onChange={imageHandler}
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-sm w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
        >
          {formik.isSubmitting || isLoading ? (
            <ImSpinner2 size={20} className="mx-auto animate-spin" />
          ) : (
            "Save"
          )}
        </button>
      </form>
    </div>
  );
};

export default HeroSidePanel;
