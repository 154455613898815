// @ts-nocheck

import React from 'react'
import { classNames } from '../../../../../../../utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { StoreModel, UserProfileModel } from '../../../../../../../models';
import { useAppSelector } from '../../../../../../../hooks';
import { MdOutlineClear } from 'react-icons/md';
import { getAboutUs } from '../../../../../../../api/Menu';
import { ImSpinner2 } from 'react-icons/im';

const AboutUsSection = ({ setSidePanel, sidePanel, setSelectedSection, setShowDeleteModal, templateSections, showModals, setShowModals }) => {

  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const queryKey = user.queryKey;
  const storeKey = store.name;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  let aboutUs: string = "";

  const storeAboutUs = useQuery<any, Error>({
    queryKey: ["getStoreAboutUs"],
    queryFn: async () => {
      const response = await getAboutUs(storeKey);
      return response.data;
    },
  });

  if (storeAboutUs.data) {
    aboutUs = storeAboutUs.data.About_Us;
  }

  return (
    <section
      className={classNames(
        "cursor-pointer",
        sidePanel === "aboutus" ? "border-2 border-blue-800 p-3" : "", !templateSections.includes("About_Us") === true ? "" : "hidden"
      )}
    >
      <div
        className={classNames("px-4 py-6 bg-white relative cursor-pointer")}
        onClick={() => {
          setSidePanel("aboutus");
        }}
      >
        <div className="mb-4 text-center">
          <p className="text-lg font-semibold">About Us</p>
        </div>
        {storeAboutUs.isLoading ? (<ImSpinner2 size={20} className="mx-auto animate-spin" />) : storeAboutUs.isError ? <p className='text-xs text-gray-600'>An error occured</p> : aboutUs !== "" ? (<div className='max-w-[600px] mx-auto font-medium text-gray-800 text-center' >
          <p className='font-medium text-[13px]'>{aboutUs}</p>
        </div>) : <p className='text-sm text-gray-600 customizer_mobile_css'>Click on the add about us button below</p>}
        <div className="flex items-center justify-center mt-6 customizer_mobile_css">
          <button
            className="px-6 py-2 text-sm text-white rounded bg-blue-700 hover:bg-blue-800"
            onClick={(e) => {
              e.stopPropagation();
              setShowModals("aboutUs")
            }}
          >
            Add about us
          </button>
        </div>
        <MdOutlineClear
          size={18}
          className="absolute text-blue-800 cursor-pointer right-4 top-7 customizer_css"
          onClick={(e) => {
            e.stopPropagation();
            setShowDeleteModal(true);
            setSelectedSection("About_Us");
          }}
        />
        <MdOutlineClear
          size={24}
          className="absolute text-blue-800 cursor-pointer right-4 top-7 customizer_mobile_css"
          onClick={(e) => {
            e.stopPropagation();
            setShowModals("delete");
            setSelectedSection("About_Us");
          }}
        />
      </div>
    </section>
  )
}

export default AboutUsSection;