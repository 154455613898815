import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { colors } from '../../components/Dashboard/Navbar';
import { StoreModel, UserProfileModel } from '../../models';
import { randomNumberInRange } from '../../utils';

export interface IUserState {
  profile: UserProfileModel;
  store: StoreModel;
  sessionColor: number;
  subscription: {
    plan: string;
    expiryDate: number;
  } 
}

const initialState: IUserState = {
  profile: {
    fullName: "",
    username: "",
    email: "",
    emailVerified: false,
    preferredUsername: "",
    platformAccountId: "",
    queryKey: "",
    attentionTrigger: "",
  },
  store: {
    name: "",
    address: "",
    country: "",
    currency: "",
    phoneNumber: "",
    employeeNumber: "",
    freeDelivery: "0",
  },
  sessionColor: 0,
  subscription: {
    plan: "",
    expiryDate: 0,
  }
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_USER: (state, action: PayloadAction<{ profile: UserProfileModel, store: StoreModel }>) => {
      state.profile = action.payload.profile;
      state.store = action.payload.store;
      state.sessionColor = randomNumberInRange(0, (colors.length - 1));
      state.subscription.plan = "Free";
      state.subscription.expiryDate = Date.now();
    },
    SET_DELIVERY_STATUS: (state, action: PayloadAction<string>) => {
      state.store.freeDelivery = action.payload;
    }, 
    REMOVE_USER: () => {
      return initialState;
    },
  }
});

export const { SET_USER, SET_DELIVERY_STATUS, REMOVE_USER } = userSlice.actions;
export default userSlice.reducer;