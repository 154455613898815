// @ts-nocheck

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useAppSelector } from "../../../hooks";
import { StoreModel, UserProfileModel } from "../../../models";
import { ImSpinner2 } from "react-icons/im";
import {
  getAttentionTrigger,
  getFeedbackMessage,
  getSwitchState,
  updateCustomizeMessagingSwitch,
  updateFeedbackAndAttentionTrigger,
} from "../../../api/settings";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Modal from "../../../components/Dashboard/Modal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_USER } from "../../../redux/User";

interface Feedback {
  positiveFeedback: string;
  negativeFeedback: number;
}

const SettingsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const general: UserProfileModel = useAppSelector((state) => state.user);
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const queryKey = user.queryKey;
  let trigger: number = "";

  let switchStatus: number = 0;
  let feedback: Feedback = { positiveFeedback: "", negativeFeedback: "" };
  const [attentionValue, setAttentionValue] = useState("");
  const [positive, setPositive] = useState("");
  const [negative, setNegative] = useState("");
  const [edit, setEdit] = useState(false);
  const [showAttentionModal, setShowAttentionModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showPopOver, setShowPopOver] = useState(false);

  const [feedbackMessages, setFeedbackMessages] = useState({
    positiveMessage: "",
    negativeMessage: "",
  });

  const handleCustomizeMessagingSwitch = useMutation({
    mutationFn: async (data) => {
      const body = {
        platformKey,
        switchButton: data.status,
      };
      return await updateCustomizeMessagingSwitch(body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getSwitchState");
    },
  });

  const handleUpdateFeedbackAndAttentionTrigger = useMutation({
    mutationFn: async (data) => {
      const payload = {
        platformKey,
        ticketMessage: data.positiveM,
        negativeMessage: data.negativeM,
        attentionValue:
          data.attentionV === "" || data.attentionV < 1 ? 1 : data.attentionV,
      };
      return await updateFeedbackAndAttentionTrigger(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getFeedbackMessages");
      queryClient.invalidateQueries("getAttentionTrigger");
      setFeedbackMessages({
        positiveMessage: "",
        negativeMessage: "",
      });
      setShowFeedbackModal(true);
    },
  });

  const switchState = useQuery({
    queryKey: ["getSwitchState"],
    queryFn: async () => {
      return await getSwitchState(queryKey);
    },
  });

  const attentionTrigger = useQuery<any, Error>({
    queryKey: ["getAttentionTrigger"],
    queryFn: async () => {
      const response = await getAttentionTrigger(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      dispatch(
        SET_USER({
          ...general,
          profile: { ...user, attentionTrigger: data.Attention_Variable },
        })
      );

      // console.log(data);
    },
  });

  const feedbackMessageDownload = useQuery<any, Error>({
    queryKey: ["getFeedbckMessages"],
    queryFn: async () => {
      const response = await getFeedbackMessage(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      setPositive(data.Ticket_Feedback);
      setNegative(data.Negative_Feedback);
    },
  });

  if (attentionTrigger.data) {
    trigger = attentionTrigger.data.Attention_Variable;
    // console.log(attentionTrigger.data)
  }

  if (attentionTrigger.status === "error") {
    trigger = 5;
  }

  if (switchState.data) {
    switchStatus = switchState.data.data.Switch_Button ?? 0;
  }

  if (switchState.status === "error") {
    switchStatus = 0;
  }

  if (feedbackMessageDownload.data) {
    feedback = {
      positiveFeedback: feedbackMessageDownload.data.Ticket_Feedback,
      negativeFeedback: feedbackMessageDownload.data.Negative_Feedback,
    };
  }

  return (
    <>
      <Modal
        show={showAttentionModal}
        onToggle={() => setShowAttentionModal(false)}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img
              src="/media/svg/check.svg"
              alt="icon"
              className="mx-auto w-28"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-xl">
              Successfully Updated Attention Trigger.
            </h1>
          </div>
        </div>
      </Modal>
      <Modal
        show={showFeedbackModal}
        onToggle={() => setShowFeedbackModal(false)}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img
              src="/media/svg/check.svg"
              alt="icon"
              className="mx-auto w-28"
            />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-xl">
              Successfully updated feedback settings.
            </h1>
          </div>
        </div>
      </Modal>
      <div className="h-full max-w-[1000px]">
        <div className="">
          <div className="flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center py-3 border-b-[1px] border-gray-200">
            <div className="max-w-[400px] text-xs">
              <p className="font-semibold">Marketing emails</p>
              <p className="text-gray-500 font-medium">
                Kindly select if you want to receive marketing emails from us
              </p>
            </div>
            <div className="relative">
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
          <div className="flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center py-3 border-b-[1px] border-gray-200">
            <div className="sm:max-w-[280px] text-xs">
              <p className="font-semibold">Attention preference</p>
              <p className="text-gray-500 font-medium">
                Would you like to receive notifications about very serious
                issues that require your attention?
              </p>
            </div>
            <div className="text-xs sm:flex justify-end gap-3">
              <p className="text-gray-500 sm:mt-7">
                When the{" "}
                <span className="text-red-800 font-semibold">very serious</span>{" "}
                get's to
              </p>
              <form className="w-2/5 sm:w-1/5">
                <label className="mb-0.5 hidden md:block text-[10.5px] text-gray-800">
                  Enter number
                </label>
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg p-2.5 w-full outline-none focus:ring-blue-500 focus:border-blue-500 placeholder:text-xs"
                  placeholder="ex: 5"
                  // onClick={() => setEdit(true)}

                  defaultValue={trigger}
                  // value={!edit ? trigger : attentionValue}
                  onChange={(e) => setAttentionValue(e.target.value)}
                />
              </form>
            </div>
          </div>

          <div className="flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center py-3 border-b-[1px] border-gray-200">
            <div className="max-w-[400px] text-xs">
              <p className="font-semibold">Report preference</p>
              <p className="text-gray-500 font-medium">
                Turn off and on to change the report preference state
              </p>
            </div>
            <div className="relative">
              <div className="flex items-center">
                <div className="inline-flex items-center">
                  {handleCustomizeMessagingSwitch.isLoading ? (
                    <ImSpinner2 size={24} className="mr-4 animate-spin" />
                  ) : null}{" "}
                </div>
                <div className="relative">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={Number(switchStatus)}
                      // onClick={() => {

                      // }}
                      onChange={(e) => {
                        if (
                          store.name === undefined ||
                          store.name === "N/A" ||
                          store.name === ""
                        ) {
                          setShowPopOver((prevState) => !prevState);
                          return;
                        }
                        handleCustomizeMessagingSwitch.mutate({
                          status: e.target.checked ? 1 : 0,
                        });
                      }}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                  {showPopOver && (
                    <div className="bg-white w-[350px] absolute z-100 top-8 right-0 rounded-lg shadow-lg p-4">
                      <div className="flex gap-3 mb-4">
                        <AiOutlineInfoCircle size={30} />
                        <p className="text-xs font-medium text-gray-800">
                          You need to add a store name before you can send a
                          customized message
                        </p>
                      </div>
                      <div className="flex items-center justify-center">
                        <button
                          onClick={() => navigate("/settings/profile")}
                          className="py-2 px-5 bg-primary rounded-md text-xs text-white"
                        >
                          Add store name
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center py-3 border-b-[1px] border-gray-200">
            <div className="max-w-[400px] text-xs">
              <p className="font-semibold">Net Promoter Score</p>
              <p className="text-gray-500 font-medium">
                Choose the question that you want to ask your customers for your
                NPS score
              </p>
              <div className="my-4 flex flex-col gap-2.5" >
                <div class="flex items-center">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="default-radio"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                  />
                  <label
                    for="default-radio-1"
                    class="ms-2 text-xs font-medium text-gray-900"
                  >
                    Custom
                  </label>
                </div>
                <div class="flex items-center">
                  <input
                    checked
                    id="default-radio-2"
                    type="radio"
                    value=""
                    name="default-radio"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                  />
                  <label
                    for="default-radio-2"
                    class="ms-2 text-xs font-medium text-gray-900"
                  >
                    Default
                  </label>
                </div>
              </div>
            </div>
            <div className="max-w-[400px] p-3 bg-gray-100 border border-gray-200 rounded">
              <p className="text-xs font-medium">
                On a scale of 0 to 10, how likely are you to recommend our
                business to a friend or colleague?
              </p>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center hidden">
            <div className="sm:max-w-[280px] text-xs">
              <p className="font-semibold">Maximum similarity score</p>
              <p className="text-gray-500 font-medium">
                Select the level of relevance of your reviews to the set success
                metric.
              </p>
              <p className="text-gray-400">Default maximum is 42%</p>
            </div>
            <div className="max-w-[400px] w-full">
              <input
                type="range"
                value="42"
                readOnly
                min="0"
                max="100"
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:bg-blue-600"
                // disabled
              />
            </div>
          </div>

          <div className="max-w-[400px] text-xs py-3 ">
            <p className="font-semibold">Enable customized message</p>
            <p className="text-gray-500 font-medium">
              A message will be sent to customers after making a review
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="col-span-1">
              <label className="block mb-2 text-xs font-semibold text-gray-900">
                Negative feedback
              </label>
              <textarea
                id="message"
                rows={4}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 outline-none focus:ring-blue-500 focus:border-blue-500 placeholder:text-xs"
                placeholder="Enter customized message"
                defaultValue={feedback.negativeFeedback}
                // value={feedbackMessages.negativeMessage}
                onChange={(e) => setNegative(e.target.value)}
              ></textarea>
            </div>
            <div className="col-span-1 ">
              <label className="block mb-2 text-xs font-semibold text-gray-900">
                Ticket message
              </label>
              <textarea
                id="message"
                rows={4}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 outline-none focus:ring-blue-500 focus:border-blue-500 placeholder:text-xs"
                placeholder="Enter customized message"
                defaultValue={feedback.positiveFeedback}
                // value={feedbackMessages.positiveMessage}
                onChange={(e) => setPositive(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-xs px-8 py-2.5 text-center w-full sm:w-2/5 my-4"
              // disabled={
              //   feedbackMessages.negativeMessage === "" ||
              //   feedbackMessages.positiveMessage === ""
              // }

              // onClick={() => {
              //   handleFeedbackMessageUpload.mutate({
              //     positiveM:
              //       positive === "" ? feedback.positiveFeedback : positive,
              //     negativeM:
              //       negative === "" ? feedback.negativeFeedback : negative,
              //   });
              // }}

              onClick={() => {
                handleUpdateFeedbackAndAttentionTrigger.mutate({
                  attentionV: attentionValue === "" ? trigger : attentionValue,
                  positiveM:
                    positive === "" ? feedback.positiveFeedback : positive,
                  negativeM:
                    negative === "" ? feedback.negativeFeedback : negative,

                  // positiveM: positive,
                  // negativeM: negative,
                });
              }}
            >
              {handleUpdateFeedbackAndAttentionTrigger.isLoading ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
