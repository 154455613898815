// @ts-nocheck

import React, { useState } from 'react'
import { MdOutlineClear, MdOutlineKeyboardBackspace } from 'react-icons/md'
import { v4 as uuidv4 } from "uuid";

const ItemOptions = ({ setPage, itemColors, setItemColors, itemSizes, setItemSizes }) => {
  const [attributes, setAttributes] = useState<any>([]);
  const [colorAttributes, setColorAttributes] = useState<any>([]);
  const [options, setOptions] = useState("Sizes")
  const [attribute, setAttribute] = useState({
    size: "",
    count: "",
    id: "",
  })

  const [colorAttribute, setColorAttribute] = useState({
    color: "",
    count: "",
    id: "",
  })

  const onOptionChange = e => {
    setOptions(e.target.value)
  }


  return (
    <div className='flex flex-col h-full'>
      <div className="flex items-center gap-2 mb-3" >
        <MdOutlineKeyboardBackspace className="cursor-pointer" onClick={() => {
          setPage(1)
        }} />
        <p className="text-lg font-semibold">Item Options</p>
      </div>
      <div className='mb-3 space-y-1.5' >
        <div className="flex items-center">
          <input
            checked={options === "Sizes"}
            type="radio"
            value="Sizes"
            name="options"
            onChange={onOptionChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
          />
          <label className="ml-2 text-xs font-medium text-gray-900 ">
            Sizes
          </label>
        </div>
        <div className="flex items-center">
          <input
            checked={options === "Colors"}
            type="radio"
            value="Colors"
            name="options"
            onChange={onOptionChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
          />
          <label className="ml-2 text-xs font-medium text-gray-900 ">
            Colors
          </label>
        </div>
      </div>

      <div className='space-y-2'>
        {options === "Sizes" ? (<p className='text-xs text-gray-500'>Input size name and add available stock count.</p>) : (<p className='text-xs text-gray-500'>Input color and add available stock count.</p>)}

        {options === "Sizes" ? (
          itemSizes?.map((attribute: any, index: number) => (
            <div className="flex items-center justify-between gap-2" key={attribute.id} >
              <div className="w-3/4">
                <input
                  type="text"
                  readOnly
                  name=""
                  value={attribute.size}
                  className="text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-800 placeholder:text-xs"
                />
              </div>
              <div className="w-1/4">
                <input
                  type="number"
                  readOnly
                  name=""
                  value={attribute.count}
                  className="text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-800 placeholder:text-xs"
                />
              </div>
              <MdOutlineClear sizes={24} className='cursor-pointer' onClick={() => {
                setItemSizes(itemSizes.filter(a => a.id !== attribute.id))
              }} />
            </div>
          ))
        ) : (itemColors?.map((attribute: any, index: number) => (
          <div className="flex items-center justify-between gap-2" key={attribute.id} >
            <div className="w-3/4">
              <input
                type="text"
                readOnly
                name=""
                value={attribute.color}
                className="text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-800 placeholder:text-xs"
              />
            </div>
            <div className="w-1/4">
              <input
                type="number"
                readOnly
                name=""
                value={attribute.count}
                className="text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-800 placeholder:text-xs"
              />
            </div>
            <MdOutlineClear sizes={24} className='cursor-pointer' onClick={() => {
              setItemColors(itemColors.filter(a => a.id !== attribute.id))
            }} />
          </div>
        )))}

        {options === "Sizes" ? (
          <div className="flex items-center justify-between gap-2">
            <div className="w-3/4">
              <input
                type="text"
                name=""
                value={attribute.size}
                onChange={e => setAttribute({ ...attribute, size: e.target.value })}
                placeholder='Size'
                className="text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-800 placeholder:text-xs"
              />
            </div>
            <div className="w-1/4">
              <input
                type="number"
                name=""
                value={attribute.count}
                onChange={e => setAttribute({ ...attribute, count: e.target.value })}
                placeholder="1"
                className="text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-800 placeholder:text-xs"
              />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between gap-2">
            <div className="w-3/4">
              <input
                type="text"
                name=""
                value={colorAttribute.color}
                onChange={e => setColorAttribute({ ...colorAttribute, color: e.target.value })}
                placeholder='Color'
                className="text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-800 placeholder:text-xs"
              />
            </div>
            <div className="w-1/4">
              <input
                type="number"
                name=""
                value={colorAttribute.count}
                onChange={e => setColorAttribute({ ...colorAttribute, count: e.target.value })}
                placeholder="1"
                className="text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-800 placeholder:text-xs"
              />
            </div>
          </div>
        )}

      </div>
      {options === "Sizes" ? (
        <button
          type="submit"
          className="text-sm font-semibold w-full py-2.5 px-2.5 bg-white rounded text-blue-700 mt-3 border border-blue-600" onClick={() => {
            setAttributes([...attributes, {...attribute, id: uuidv4()}])
            setItemSizes([...attributes, {...attribute, id: uuidv4()}])
            setAttribute({
              size: "",
              count: "",
              id: "",
            })
          }} >Add new size
        </button>
      ) : (
        <button
          type="submit"
          className="font-semibold w-full py-2.5 px-2.5 bg-white rounded text-blue-700 mt-3 border border-blue-600" onClick={() => {
            setColorAttributes([...colorAttributes, {...colorAttribute, id: uuidv4() }])
            setItemColors([...colorAttributes, {...colorAttribute, id: uuidv4()}])
            setColorAttribute({
              color: "",
              count: "",
              id: "",
            })
          }} >Add new color
        </button>
      )}

      <button
        type="submit"
        onClick={() => {
          if(options === "Sizes" && attribute.size !== ""){
            setItemSizes([...itemSizes, {...attribute, id: uuidv4()}]);
            // setAttributes([...attributes, {...attribute, id: uuidv4()}]);
          }
          if(options === "Colors" && colorAttribute.color !== ""){
            setItemColors([...itemColors, {...colorAttribute, id: uuidv4()}]);
            // setAttributes([...attributes, {...colorAttribute, id: uuidv4()}]);
          }
          setPage(1)
        }}
        className="text-sm font-semibold w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white mt-8" >Save
      </button>

    </div>
  )
}

export default ItemOptions