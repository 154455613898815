import { Input } from "../../Home/EchoWebpage/EchoWebpage1";
import InputMessage from "../../../../../components/Auth/Forms/InputMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImSpinner2 } from "react-icons/im";

const initialValues = {
  itemName: "",
  details: "",
  price: "",
  category: "",
  available_count: "",
};

const itemSchema = Yup.object().shape({
  itemName: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Item name is required"),
  details: Yup.string()
    .min(5, "Minimum 5 letters")
    .max(140, "Maximum 140 letters"),
  price: Yup.string().required("Price is required"),
  category: Yup.string(),
  available_count: Yup.number(),
});

const AddNewProduct = () => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: itemSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      setSubmitting(true);

      setSubmitting(false);
    },
  });
  return (
    <div>
      <p className="font-semibold text-sm mb-4">New Item</p>
      <form onSubmit={formik.handleSubmit} className="">
        <div className="space-y-3">
          <div className="w-full">
            <Input
              formik={formik}
              name="itemName"
              label="Item Name"
              type="text"
              placeHolder="Please enter item name"
            />
            {formik.errors.itemName && (
              <InputMessage message={formik.errors.itemName} />
            )}
          </div>
          <div className="w-full">
            <label className="block mb-1 text-xs font-normal text-gray-800">
              Description
            </label>
            <textarea
              id=""
              name="details"
              value={formik.values.details}
              onChange={formik.handleChange}
              className="h-[100px] text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
              placeholder="Please enter details of item"
            ></textarea>
            {formik.errors.details && (
              <InputMessage message={formik.errors.details} />
            )}
          </div>
          <div className="w-full">
            <Input
              formik={formik}
              name="price"
              label="Price"
              type="number"
              placeHolder="Please enter price"
            />
            {formik.errors.price && (
              <InputMessage message={formik.errors.price} />
            )}
          </div>
          <div className="w-full">
            <Input
              formik={formik}
              name="price"
              label="Discount"
              type="number"
              placeHolder="Please enter price"
            />
            {formik.errors.price && (
              <InputMessage message={formik.errors.price} />
            )}
          </div>
          <div className="w-full">
            <Input
              formik={formik}
              name="available_count"
              label="Available Stock"
              type="number"
              placeHolder="Please enter available stock"
            />
            {formik.errors.available_count && (
              <InputMessage message={formik.errors.available_count} />
            )}
          </div>
          <div className="w-full">
            <Input
              formik={formik}
              name="available_count"
              label="Category"
              type="text"
              placeHolder="Please enter category"
            />
            {formik.errors.available_count && (
              <InputMessage message={formik.errors.available_count} />
            )}
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="w-full">
              <Input
                formik={formik}
                name="available_count"
                label="Variation"
                type="text"
                placeHolder="Please enter category"
              />
              {formik.errors.available_count && (
                <InputMessage message={formik.errors.available_count} />
              )}
            </div>
            <div className="w-full">
              <Input
                formik={formik}
                name="available_count"
                label="Stock"
                type="text"
                placeHolder="Please enter category"
              />
              {formik.errors.available_count && (
                <InputMessage message={formik.errors.available_count} />
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-4">
          <button
            type="submit"
            // disabled={formik.isSubmitting}
            className="text-xs w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
          >
            {formik.isSubmitting ? (
              <ImSpinner2 size={20} className="mx-auto animate-spin" />
            ) : (
              "Save Item"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewProduct;
