import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { UserProfileModel } from "../../../../../../models";
import { useAppSelector } from "../../../../../../hooks";
import { getAllTickets } from "../../../../../../api/Tickets";
import moment from "moment";

const defaultObject = {
  priorityLevel: ["Low", "Medium", "High"],
  ticketStatus: "None",
  ticketSource: [
    "site_data",
    "web",
    "facebook",
    "sms",
    "voice",
    "twitter",
    "instagram",
    "linkedin",
  ],
  current: 0,
  paramsArray: [""],
};

const HelpDeskHomeSummary = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const platformKey = `${user.platformAccountId}&${user.email}`;
  let dateRange: string = "March - Present";
  const [activeDropdown, setActiveDropdown] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState(0);
  const [modalObject, setModalObject] = useState({
    email: "",
    phoneNumber: "",
    complaint: "",
  });
  let ticketsCountObject = {
    resolvedTickets: 0,
    pendingTickets: 0,
    totalTickets: 0,
  };

  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [selectedPriority, setSelectedPriority] = useState("Priority");

  const [defaultSortingObject, setDefaultSortingObject] =
    useState(defaultObject);
  const [backPressed, setBackPressed] = useState(false);

  let ticketsArr: any[] = [];
  let ticketsSaved: any[] = [];
  const [paramsArrays, setParamsArrays] = useState([""]);

  const tickets = useQuery<any, Error>({
    queryKey: ["getAllTickets", defaultSortingObject, filter, backPressed],
    queryFn: async () => {
      const payload = {
        platformKey,
        assignee: "None",
        ticketStatus: defaultSortingObject.ticketStatus,
        pagination_key: paramsArrays[defaultSortingObject.current],
        priorityLevel: defaultSortingObject.priorityLevel,
        ticketSource: defaultSortingObject.ticketSource,
      };
      const response = await getAllTickets(payload);
      return response.data;
    },
    onSuccess: (data) => {
      setActiveDropdown(null);
      if (backPressed === false) {
        setParamsArrays((prevState) => [...prevState, data.LastEvaluatedKey]);
      }
      if (backPressed === true) {
        setParamsArrays(paramsArrays.slice(0, -1));
      }
    },
  });

  if (tickets.data) {
    ticketsArr = tickets.data.Platform_Tickets.Items;
    console.log(ticketsArr);
    dateRange = `${moment(
      ticketsArr[ticketsArr.length - 1]?.uploadTimestamp
    ).format("MMMM")} - Present`;
  }

  return (
    <div>
      {" "}
      <p className="text-sm font-semibold mb-2.5">Help desk</p>
      <div className="space-y-2">
        {ticketsArr.slice(0, 3).map((ticket: any, index: number) => (
          <div className="p-2 bg-white rounded-md" key={index}>
            <div className="flex items-center justify-between">
              <div className="text-sm mb-1 flex gap-1.5 items-center">
                <p className="">{ticket.customerId}</p>
                <p className="border border-red-600 p-1.5 py-0.5 bg-red-200 rounded text-[10px] font-semibold uppercase">
                  {ticket.priorityLevel}
                </p>
              </div>
              <p className="text-xs text-gray-500">
                {moment(ticket.uploadTimestamp).format("MMM Do, YYYY")}
              </p>
            </div>
            <p className="text-xs">{ticket.complaint}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpDeskHomeSummary;
