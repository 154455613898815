// @ts-nocheck

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Customized,
  Cross,
  AreaChart,
  Area,
} from "recharts";
import { getRealtimePerceptionScores } from "../../../../../../api/Utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SET_BPT_SCORES } from "../../../../../../redux/Utils";
import { ChartDataInterval } from "../../../../../../enums";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { useEffect, useState } from "react";
import { UserProfileModel } from "../../../../../../models";
import SkeletonLoader from "../../../../../../components/Dashboard/SkeletonLoader";
import { classNames } from "../../../../../../utils";

const BPTComponent = () => {
  const [interval, setInterval] = useState<ChartDataInterval>(
    ChartDataInterval.Monthly
  );
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  const [startDateTimeStamp, setStartDateTimeStamp] = useState("0.000");
  const [endDateTimeStamp, setEndDateTimeStamp] = useState(
    `${new Date(Date.now()).getTime() / 1000}`
  );
  const queryclient = useQueryClient();
  const [filter, setFilter] = useState(0);
  const scores: number[] = useAppSelector((state) => state.utils.bpt_scores);
  const dispatch = useAppDispatch();
  const [tempScores, setTempScores] = useState<number[]>([]);
  const [randomData, setRandomData] = useState([]);
  const [numToAdd, setNumToAdd] = useState(40);
  let bptScoresArray = [];

  const toggleChartData = (value: ChartDataInterval) => {
    setInterval(value);
  };

  const handleClearFilter = (e: any) => {
    e.preventDefault();
    setFilter(0);
    setStartDateTimeStamp("");
    setEndDateTimeStamp("");
    if (filter === 1) {
      dispatch(SET_BPT_SCORES(tempScores));
      queryclient.refetchQueries(["getRealtimePerceptionScores"]);
    }
  };

  const { status, data, error, isRefetching, isFetching } = useQuery<
    any,
    Error
  >({
    queryKey: ["getRealtimePerceptionScores"],
    initialData: scores,
    queryFn: async () => {
      const payload = {
        platformKey: queryKey,
        // timestampData: [startDateTimeStamp, endDateTimeStamp],
      };

      const response = await getRealtimePerceptionScores(payload);
      return response.data;
    },
    select: (data) => {
      // console.log(data);
      if ("Items" in data) {
        if (data.Items.length > 0) {
          return {
            scores: data.Items.map((item: any, i: number) => {
              return item.perceptionScore?.toFixed(0);
            }),
            timestamps: data.Items.map((item: any, i: number) => {
              return item.uploadTime;
            }),
          };
        }
      }

      if ("bpt_index" in data) {
        // const prevScores = queryclient.getQueryData<any>(["getRealtimePerceptionScores"]);

        return {
          // scores: [data.bpt_index.perceptionScore?.toFixed(0), ...scores],
          scores: data.bpt_index.scoreProjections.map(
            (item: any, i: number) => {
              return item.perceptionScore?.toFixed(0);
            }
          ),
        };
      }

      return { scores: [], timestamps: [] };
    },
    onSuccess: (data) => {
      dispatch(SET_BPT_SCORES(data.scores));
    },
  });

  useEffect(() => {
    // Minimum and maximum values for the range
    const minVal = 10;
    const maxVal = 100;
    

    // Function to generate a random integer within the range
    function getRandomInt(min: any, max: any) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Generate random data using a loop
    const newData = [];
    for (let i = 0; i < numToAdd; i++) {
      newData.push(getRandomInt(minVal, maxVal));
    }

    const data = [...newData].map((d: any) => {
      return {
        score: Number(d),
      };
    });
    setRandomData(data);
    // console.log(data)
  }, [numToAdd]);

  if (!data)
    return <SkeletonLoader className="h-[calc(54vh_-_1.25rem)] rounded-md" />;

  if (status === "error") return <span>Error: {error.message}</span>;

  // console.log(data);
  if (data) {
    bptScoresArray = data.scores.map((d: any) => {
      return {
        score: Number(d),
      };
    });
  }

  return (
    <div className="w-full h-[350px] bg-tranparent p-3 rounded-lg text-sm border border-gray-200 overflow-hidden">
      <div className="flex items-center justify-between">
        <p className="font-semibold m-3">Business perception tracker</p>
        <div className="flex items-center gap-2 text-xs py-1 px-2 rounded border border-gray-200">
          <button
            className={classNames(
              "py-1.5 px-2 rounded border border-gray-200",
              interval === ChartDataInterval.Monthly
                ? "bg-gray-200 border-gray-600"
                : ""
            )}
            onClick={() => {
              toggleChartData(ChartDataInterval.Monthly);
              setNumToAdd(40);
            }}
          >
            Monthly
          </button>
          <button
            className={classNames(
              "py-1.5 px-2 rounded border border-gray-200 cursor-not-allowed",
              interval === ChartDataInterval.Weekly
                ? "bg-gray-200 border-gray-600"
                : ""
            )}
            onClick={() => {
              // toggleChartData(ChartDataInterval.Weekly);
              setNumToAdd(14);
            }}
          >
            Weekly
          </button>
          <button
            className={classNames(
              "py-1.5 px-2 rounded border border-gray-200 cursor-not-allowed",
              interval === ChartDataInterval.Daily
                ? "bg-gray-200 border-gray-600"
                : ""
            )}
            onClick={() => {
              // toggleChartData(ChartDataInterval.Daily);
              setNumToAdd(10)
            }}
          >
            Daily
          </button>
        </div>
      </div>
      <div className="h-[calc(350px-4rem)]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={bptScoresArray}
            // data={randomData}
            margin={{
              top: 10,
              right: 0,
              left: -30,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {/* <XAxis
            // dataKey="name"
            style={{
              fontSize: "8px",
            }}
          /> */}
            <YAxis
              style={{
                fontSize: "8px",
              }}
            />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="score"
              stroke="#1aa3de"
              fill="#D7F0FF"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="my-3 ml-4.5">
        <p className="text-xs text-gray-800">
          Business perception for January 1st to Dec 31 is now at 76% and had
          dropped below the expected positive score of 84%. The score has
          fluctuated positively and negatively which indicates and inconsistency
          in business management.
        </p>
      </div>
    </div>
  );
};

export default BPTComponent;
