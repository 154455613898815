import React, { useState } from "react";
import { BiCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { classNames, getImage } from "../../../../../utils";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const ChooseProductType = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(1);

  return (
    <div className="w-full rounded-md h-[85vh] bg-white p-6 px-4 sm:px-10">
      <div
        className="flex items-center gap-2 cursor-pointer mb-8"
        onClick={() => {
          navigate("/home/echo-webpage");
        }}
      >
        <MdOutlineKeyboardBackspace size={22} />
        <h4 className="text-gray-800 capitalize text-lg font-semibold">Back</h4>
      </div>
      <div className="">
        <div className="space-y-2 text-left">
          <h2 className="text-xl text-black font-semibold">
          What type of products will you be selling?
          </h2>
        </div>
      </div>

      <div className="space-y-3 sm:space-y-0 sm:flex items-center gap-6 mt-6">
        <div
          className={classNames(
            "p-2 py-4 border-2 space-y-1 sm:max-w-[380px] rounded-md cursor-pointer",
            selected === 1 ? "bg-[#729BE1] bg-opacity-40 border-[#6194ec]" : ""
          )}
          onClick={() => setSelected(1)}
        >
         <div className="flex gap-3">
          <img src={getImage("PhysicalItemIcon")} alt="" className="w-[50px] h-[50px]"  />
         <div>
         <p className="text-base font-semibold">Physical products</p>
          <p className="text-xs">
          Examples include food, clothing items, hardware, jewellery e.t.c
          </p>
         </div>
         </div>
        </div>
        <div
          className={classNames(
            "p-2 py-4 border-2 space-y-1 sm:max-w-[380px] rounded-md cursor-pointer",
            selected === 2 ? "bg-[#729BE1] bg-opacity-40 border-[#6194ec]" : ""
          )}
          // onClick={() => setSelected(2)}
        >
         <div className="flex gap-3">
         <img src={getImage("DigitalItemIcon")} alt="" className="w-[50px] h-[50px]"  />
         <div>
          <p className="text-base font-semibold">Digital products</p>
          <p className="text-xs">
          Examples include e-books, images, templates, files e.t.c
          </p>
          </div>
         </div>
        </div>
      </div>

      <div className="mt-6">
        <button
          disabled={selected === -1}
          className={classNames(
            "w-full sm:w-auto px-6 py-2 text-xs text-center  text-white bg-blue-700 hover:bg-blue-800 rounded",
            selected === -1 ? "cursor-not-allowed" : ""
          )}
          onClick={() => {
            if (selected === 1) {
              navigate("/home/echo-webpage/customizer");
            }
            if (selected === 2) {
              navigate("/home/echo-webpage/upload-url");
            }
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default ChooseProductType;
