import React from "react";
import { useNavigate } from "react-router-dom";

const ReviewsSidePanel = () => {
  const navigate = useNavigate();
  return (
    <div className="p-3">
      <h1 className="text-lg text-gray-800 mb-3 font-semibold">Feedback</h1>
      <p className="text-xs text-gray-700 mb-4" >
        Choose feedback that will be shown on your website
      </p>
      <button
        type="button"
        className="text-sm w-full py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
        onClick={() => navigate("/feedback-analysis/customer-review/all")}
      >
        Choose feedback
      </button>
    </div>
  );
};

export default ReviewsSidePanel;
