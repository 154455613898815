import { QRCodeSVG } from "qrcode.react";
import PageHeader from "../../../components/Dashboard/PageHeader";
import { AiOutlineQrcode, AiOutlinePlusSquare } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { UserProfileModel } from "../../../models";
import { useAppSelector } from "../../../hooks";
import { METRICS_COLORS, base64Encode, classNames, getImage, getSvg } from "../../../utils";
import { useState, useRef } from "react";
import { SupportedImageFormat } from "../../../enums";
import * as htmlToImage from "html-to-image";


const ScanCodePage = () => {
  const navigate = useNavigate();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const [activeBtn, setActiveBtn] = useState<SupportedImageFormat>(SupportedImageFormat.PNG);
  const qrElement = useRef<HTMLDivElement>(HTMLDivElement.prototype);
  const [text, setText] = useState("");
  const [color, setColor] = useState(1);
  
  const toggleActiveBtn = (value: SupportedImageFormat) => {
    setActiveBtn(value)
  }

  const handleQRCodeDownload = async () => {

    let dataUrl;
    
    switch (activeBtn) {
      case SupportedImageFormat.JPG:
        dataUrl = await htmlToImage.toJpeg(qrElement.current);
        break;
      case SupportedImageFormat.SVG:
        dataUrl = await htmlToImage.toSvg(qrElement.current);
        break;
      default:
        dataUrl = await htmlToImage.toPng(qrElement.current);
        break;
    }
 
    const link = document.createElement("a");
    link.download = `qr_code.${activeBtn}`;
    link.href = dataUrl;
    link.click();

  }

  const handleTextChange = (e:any) => {
    setText(e.target.value);
  }

  return (
    <div className="space-y-1">
      <PageHeader title="Scan & Review" />
      <div className="grid content-center justify-center grid-cols-4 gap-5">
        <div className="h-[80vh] bg-white rounded-md shadow-md py-3 px-5 col-span-2 overflow-y-scroll">
          <div>
            <h3 className="font-bold text-xl text-[#111827]">
              Customize Scanner
            </h3>
            <p className="text-base text-[#9A9A9A]">Customize your Qr code to fit your brand</p>
          </div>
          <div className="space-y-10 mt-14">
            <div className="flex flex-col space-y-1">
              <label className="text-base">Customize text</label>
              <input type="text" className="w-10/12 p-2 text-gray-400 bg-white border rounded-md outline-none placeholder:text-gray-300" value={text} onChange={handleTextChange} maxLength={25} placeholder="How did we do today?" />
            </div>
            <div>
              <div className="flex flex-col space-y-1">
                <label className="text-base">Choose color</label>
                <div className="flex space-x-2">
                  {
                    [0,1,2,3,4].map((items: any, index: number) => {
                      const newIndex = index + 1;
                      return(
                        <button key={index} onClick={() => setColor(newIndex)} className={classNames("p-6 border rounded-md border-gray-100", (color === newIndex) ? "shadow-sm shadow-black" : "")} style={{ backgroundColor: METRICS_COLORS[index] }}></button>
                      );
                    })
                  }
                </div>
              </div>
            </div>
            <div>
              <h6 className="font-semibold text-xl text-[#111827]">
                Download Options
              </h6>
              <div className="flex items-center justify-start gap-4 mt-5">
                <button onClick={() => toggleActiveBtn(SupportedImageFormat.PNG)} className={classNames("px-4 py-2 text-sm rounded-lg", activeBtn === SupportedImageFormat.PNG ? "bg-primary text-white " : "text-gray-500 bg-transparent border border-gray-400")}>
                  PNG
                </button>
                <button onClick={() => toggleActiveBtn(SupportedImageFormat.JPG)} className={classNames("px-4 py-2 text-sm rounded-lg", activeBtn === SupportedImageFormat.JPG ? "bg-primary text-white " : "text-gray-500 bg-transparent border border-gray-400")}>
                  JPG
                </button>
                <button onClick={() => toggleActiveBtn(SupportedImageFormat.SVG)} className={classNames("px-4 py-2 text-sm rounded-lg", activeBtn === SupportedImageFormat.SVG ? "bg-primary text-white " : "text-gray-500 bg-transparent border border-gray-400")}>
                  SVG
                </button>
              </div>
              <div className="flex justify-center mt-14">
                <button onClick={handleQRCodeDownload} className="px-12 py-2 font-bold text-white rounded-lg bg-primary hover:bg-blue-700">
                  Download Code
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[80vh] bg-white rounded-md shadow-md py-3 px-5 col-span-2 overflow-y-scroll">
          <div>
            <h3 className="font-bold text-xl text-[#111827]">
              Preview
            </h3>
            <p className="text-base text-[#9A9A9A]">Here Is A Preview of What Your Printed Scanner</p>
          </div>
          <div className="flex flex-col items-center justify-center h-[90%]">
            <div className="w-3/4 min-h-[27rem] mx-auto border-2 border-black rounded-lg overflow-hidden">
              <div id="qrElement" ref={qrElement} className="relative w-full h-full overflow-hidden bg-white bg-no-repeat bg-cover" style={{ backgroundImage: `url("${getImage("qr_bg")}")` }}>
                <div className="absolute w-[90%] px-4 space-y-4 top-12">
                  <div className="space-y-3 text-xl font-bold text-black uppercase">
                    <h6>Hi There,
                      <br/>
                      <span style={{ color: METRICS_COLORS[(color - 1)] }}>{(text !== "") ? text : "How did we do today?"}</span>
                    </h6>
                    <h6 className="text-[#FE9418]">SCAN AND
                      <br/>
                      SPEAK YOUR MIND
                    </h6>
                  </div>
                  <div className="">
                    <div className="relative z-10 w-48 p-4 border border-gray-200 rounded-lg bg-opacity-80 bg-gray-50">
                      <QRCodeSVG
                        id="qrCode"
                        value={`${window.location.host}/feedback/${base64Encode(`${user.platformAccountId}+${user.email}+${user.queryKey}`)}`}
                        size={160}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                      />
                    </div>
                  </div>
                </div>
                <div className="absolute top-0 left-0 w-[48%] py-4 rounded-br-3xl" style={{ backgroundColor: METRICS_COLORS[(color - 1)] }}></div>
                <div className="absolute bottom-0 right-0 px-4 rounded-tl-3xl h-1/3" style={{ backgroundColor: METRICS_COLORS[(color - 1)] }}></div>
                <img src={getSvg("arrow")} alt="" className="absolute w-12 inset-y-1/2 left-56 top-44" />
                <img src={getSvg("brand")} alt="" className="absolute left-0 w-1/2 bottom-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanCodePage;