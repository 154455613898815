// @ts-nocheck
import { classNames, formatStoreName, ratingsToString } from "../../utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMessage from "../../components/Auth/Forms/InputMessage";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import Button from "../../components/Auth/Forms/Button";
import {
  IWebUrlResponse,
  IStoreInfoResponse,
  IFeedbackQuestionResponse,
} from "../../models";
import { addReview } from "../../api/Reviews";
import Alert from "../../components/Auth/Forms/Alert/indext";
import { useNavigate, useParams } from "react-router-dom";
import { getActiveFeedbackQuestions } from "../../api/KPIs";
import { useQuery } from "@tanstack/react-query";
import { ImSpinner8 } from "react-icons/im";
import { downloadWebUrl } from "../../api/Utils";
import { getSiteTitleAndTagLine } from "../../api/Menu";
import { BiChevronLeft } from "react-icons/bi";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeHolder: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
}

const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
}: InputProps) => {
  return (
    <>
      <label className="block mb-0.5 text-xs font-medium text-gray-800">
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        disabled={disabled}
        className={classNames(
          className,
          "border border-gray-400 text-sm text-black placeholder:text-gray-300 py-1.5 px-2.5 rounded w-full outline-none"
        )}
      />
    </>
  );
};

// const phoneRegExp =
//   /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g;
// const phoneRegExp = new RegExp("^\\+[1-9]{1}[0-9]{0,2}[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{2}[0-9]{4}$");
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const initialValues = {
  phoneNumber: "",
  fullName: "",
  email: "",
  reviewMessage: "",
};

const ticketValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Email address is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  fullName: Yup.string().max(40),
  reviewMessage: Yup.string()
    .max(100)
    .required("Please type in your complaint"),
});

const reviewValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .when("phoneNumber", {
      is: (value: any) => value && value.length > 0,
      then: (schema) => schema.required("Email address is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  reviewMessage: Yup.string().max(100),
});

const FeedBackPageNew = () => {
  const [showModal, setShowModal] = useState(false);
  const [rate, setRate] = useState(0);
  const [error, setError] = useState("");
  const [questns, setQuestns] = useState<any>({
    1: "null",
    2: "null",
    3: "null",
    4: "null",
    5: "null",
  });
  const { salt, slug } = useParams();
  // const paramId = base64Decode(id).split(/[+]/);
  // const queryKey = paramId[2];
  const queryKey = `/${salt}/${slug}`;
  const [expType, setExpType] = useState(0);
  // const [uiSwitch, setUISwitch] = useState(2);
  const [uiSwitch, setUISwitch] = useState(0);
  const storeName = formatStoreName(queryKey);
  const [selectedRate, setSelectedRate] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);

  const [businessDetails, setBusinessDetails] = useState({
    siteTitle: storeName,
    tagLine: "[SITE_TAGLINE_HERE]",
  });

  const questions = useQuery<IFeedbackQuestionResponse[], Error>({
    queryKey: ["getActiveFeedbackQuestions"],
    queryFn: async () => {
      const response = await getActiveFeedbackQuestions(queryKey);
      return response.data;
    },
    select(data) {
      return data.filter(
        (question: any, index: number) => question.question !== ""
      );
    },
  });

  const webUrl = useQuery<IWebUrlResponse, Error>({
    queryKey: ["downloadWebUrl"],
    queryFn: async () => {
      const response = await downloadWebUrl(queryKey);
      return response.data;
    },
    select: (data) => {
      if ("Web_Link" in data) {
        return data.Web_Link;
      }

      return data;
    },
  });

  const siteTitleAndTagLine = useQuery<IStoreInfoResponse, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      if (data.Site_Title !== "" || data.Tagline !== "") {
        setBusinessDetails({
          siteTitle: data.Site_Title,
          tagLine: data.Tagline,
        });
      }
    },
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      expType === 1 ? ticketValidationSchema : reviewValidationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setError("");

      if (rate === 0) {
        setError("Please give a rating");
        return;
      }

      setSubmitting(true);

      let finalRate: number = rate;

      switch (rate) {
        case 0:
          finalRate = 1;
          break;
        case 1:
          finalRate = 1;
          break;
        case 2:
          finalRate = 1;
          break;
        case 3:
          finalRate = 2;
          break;
        case 4:
          finalRate = 2;
          break;
        case 5:
          finalRate = 3;
          break;
        case 6:
          finalRate = 3;
          break;
        case 7:
          finalRate = 4;
          break;
        case 8:
          finalRate = 4;
          break;
        case 9:
          finalRate = 5;
          break;
        case 10:
          finalRate = 5;
          break;
        default:
          finalRate = rate;
          break;
      }

      const userRatings = finalRate === 0 ? "" : ratingsToString(finalRate);
      const userNPSValue = `${rate}`;
      const userPhone = values.phoneNumber;
      const userFullname = values.fullName;
      const userReview = values.reviewMessage;
      const email_id = values.email;
      // const platform_account = paramId[0];
      // const verified_email = paramId[1];
      const reviewObject: any = {
        // platform_account: platform_account,
        // verified_email: verified_email,
        slug_url: queryKey,
        commentData: [
          {
            commentSource: "site_data",
            userRatings,
            userNPSValue,
            userReview: expType === 0 ? "" : expType === 1 ? "" : userReview,
            usageFrequency: "0",
            metaData: {},
            uploadTime: "",
            uploadTimestamp: "",
          },
        ],
        commentId: "",
        feedback_type:
          expType === 0 ? "ratings" : expType === 1 ? "ticket" : "review",
      };

      let allQuestns: any = {};

      if (expType === 0) {
        reviewObject["feedback_quiz"] = [allQuestns];

        reviewObject["complaintInformation"] = [{}];

        reviewObject["userInformation"] = [{}];
      }

      if (expType === 1) {
        reviewObject["feedback_quiz"] = [allQuestns];

        reviewObject["complaintInformation"] = [
          {
            customer_email: email_id,
            customer_number: userPhone,
            customer_complaint: userReview,
          },
        ];

        reviewObject["userInformation"] = [{}];
      }

      if (expType === 2) {
        if (questions.data) {
          questions.data.forEach((question: any, index: number) => {
            allQuestns[question.question_tag] = [
              {
                answer: `${questns[index + 1]}`,
              },
            ];
          });

          reviewObject["feedback_quiz"] = [allQuestns];
        } else {
          [1, 2, 3, 4, 5].forEach((metric: any, index: number) => {
            allQuestns[`Question_${index + 1}`] = [
              {
                answer: `${questns[index + 1]}`,
              },
            ];
          });

          reviewObject["feedback_quiz"] = [allQuestns];
        }

        reviewObject["userInformation"] = [
          isAnonymous ? {} : {
            email_id,
            userFullname,
            userPhone,
          },
        ];

        reviewObject["complaintInformation"] = [{}];
      }

      try {
        const response = await addReview(reviewObject);
        console.log({ response });

        if (response.data.status_code === 200) {
          setShowModal(true);
          resetForm({});
          setRate(0);
          setQuestns({ 1: "null", 2: "null", 3: "null", 4: "null", 5: "null" });
          setSelectedRate(false);
          setSubmitting(false);
          return;
        }

        setError(response.data.message);
      } catch (err: any) {
        console.log({ err });
        setError(err.message);
      }

      setSelectedRate(false);
      setSubmitting(false);
    },
  });

  const doReactionToggle = (index: number, reaction: "pos" | "neg" | null) => {
    setQuestns({ ...questns, [index]: reaction });
  };

  const goBack = () => {
    if (showModal) {
      setShowModal(false);
    }

    setExpType(0);
    setRate(0);
    setError("");
    setQuestns({
      1: "null",
      2: "null",
      3: "null",
      4: "null",
      5: "null",
    });
    formik.resetForm();
    setUISwitch(0);
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-[#eff4fe] h-screen overflow-auto sm:overflow-hidden flex items-center flex-col sm:flex-row sm:justify-center py-4 px-2.5 justify-start space-y-2 sm:space-y-0">
        <div className="w-full h-1/4 sm:relative sm:h-full sm:w-fit">
          <div className="flex items-center justify-start sm:mt-4 sm:ml-2 sm:absolute sm:-left-52 text-gray-50">
            {uiSwitch !== 0 && (
              <button
                onClick={goBack}
                className="inline-flex items-center text-sm font-semibold text-black"
              >
                <BiChevronLeft size={32} className="mr-1 fill-gray-400" /> Back
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full h-full space-y-5 sm:w-[45%] lg:w-[35%]">
          <div className="w-full h-full p-3 overflow-x-hidden overflow-y-auto bg-white border border-gray-400 rounded-md shadow-md sm:p-5">
            <div className="flex items-center justify-center">
              <form
                className="w-full p-3 px-3 md:px-2"
                onSubmit={formik.handleSubmit}
              >
                {error && (
                  <div className="max-w-full lg:max-w-[60%] mx-auto">
                    <Alert type="error" message={error} />
                  </div>
                )}
                <div className="mt-2">
                  <h2 className="mb-3.5 text-xl font-bold text-center text-gray-800 capitalize md:mb-8 md:text-2xl">
                    {uiSwitch !== 4
                      ? `${businessDetails.siteTitle}`
                      : `${businessDetails.siteTitle}`}
                  </h2>
                  {/* <h3 className="font-bold text-2xl text-[#0E4A67] capitalize text-center"> */}
                  {uiSwitch !== 4 && (
                    <h3 className="text-sm font-medium text-center text-gray-800">
                      On a scale of 0 to 10, how likely are you to recommend our
                      business to a friend or colleague?
                    </h3>
                  )}
                  {uiSwitch === 1 ||
                    (uiSwitch === 2 && (
                      <div className="mt-4 max-w-[70%] sm:max-w-[80%] lg:max-w-[80%] mx-auto hidden">
                        <div className="flex items-start justify-center space-x-4">
                          <div
                            onClick={() => setRate(1)}
                            className={classNames(
                              "text-center transition duration-300 ease-linear cursor-pointer",
                              rate === 0
                                ? "opacity-100"
                                : rate === 1
                                ? "opacity-100"
                                : "opacity-60 hover:opacity-100"
                            )}
                          >
                            <div className="flex items-center justify-center">
                              <img
                                src="/media/image/face-vomiting.png"
                                alt=""
                                className="w-6 md:w-8"
                              />
                            </div>
                          </div>
                          <div
                            onClick={() => setRate(2)}
                            className={classNames(
                              "text-center transition duration-300 ease-linear cursor-pointer",
                              rate === 0
                                ? "opacity-100"
                                : rate === 2
                                ? "opacity-100"
                                : "opacity-60 hover:opacity-100"
                            )}
                          >
                            <div className="flex items-center justify-center">
                              <img
                                src="/media/image/thumbs-down.png"
                                alt=""
                                className="w-6 md:w-8"
                              />
                            </div>
                            {/* <p className="text-xs font-semibold text-gray-900 md:text-sm">
                            Unsatisfied
                          </p> */}
                          </div>
                          <div
                            onClick={() => setRate(3)}
                            className={classNames(
                              "text-center transition duration-300 ease-linear cursor-pointer",
                              rate === 0
                                ? "opacity-100"
                                : rate === 3
                                ? "opacity-100"
                                : "opacity-60 hover:opacity-100"
                            )}
                          >
                            <div className="flex items-center justify-center">
                              <img
                                src="/media/image/straight-face.png"
                                alt=""
                                className="w-6 md:w-8"
                              />
                            </div>
                            {/* <p className="text-xs font-semibold text-gray-900 md:text-sm">
                            Neutral
                          </p> */}
                          </div>
                          <div
                            onClick={() => setRate(4)}
                            className={classNames(
                              "text-center transition duration-300 ease-linear cursor-pointer",
                              rate === 0
                                ? "opacity-100"
                                : rate === 4
                                ? "opacity-100"
                                : "opacity-60 hover:opacity-100"
                            )}
                          >
                            <div className="flex items-center justify-center">
                              <img
                                src="/media/image/thumbs-up.png"
                                alt=""
                                className="w-6 md:w-8"
                              />
                            </div>
                            {/* <p className="text-xs font-semibold text-gray-900 md:text-sm">
                            Satisfied
                          </p> */}
                          </div>
                          <div
                            onClick={() => setRate(5)}
                            className={classNames(
                              "text-center transition duration-300 ease-linear cursor-pointer",
                              rate === 0
                                ? "opacity-100"
                                : rate === 5
                                ? "opacity-100"
                                : "opacity-60 hover:opacity-100"
                            )}
                          >
                            <div className="flex items-center justify-center">
                              <img
                                src="/media/image/red-heart.png"
                                alt=""
                                className="w-6 md:w-8"
                              />
                            </div>
                            {/* <p className="text-xs font-semibold text-gray-900 md:text-sm">
                            Delightful
                          </p> */}
                          </div>
                        </div>
                      </div>
                    ))}

                  {uiSwitch === 0 && (
                    <div className="flex items-center justify-between w-full mt-6">
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                        (n: any, index: number) => (
                          <div
                            className={classNames("border-2 rounded-md px-2.5 py-1.5 text-sm text-gray-800 font-semibold cursor-pointer", rate === n ? "border-blue-800 bg-[#eff4fe]" : "border-gray-300")}
                            key={index}
                            onClick={() => {
                              // alert(n);
                              setRate(n);
                              setSelectedRate(true);
                            }}
                          >
                            {n}
                          </div>
                        )
                      )}
                    </div>
                  )}

                  {uiSwitch === 1 && (
                    <div className="flex items-center justify-between w-full mt-6">
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                        (n: any, index: number) => (
                          <div
                            className={classNames("border-2 rounded-md px-2.5 py-1.5 text-sm text-gray-800 font-semibold cursor-pointer", rate === n ? "border-blue-800 bg-[#eff4fe]" : "border-gray-300")}
                            key={index}
                            onClick={() => {
                              // alert(n);
                              setRate(n);
                              setSelectedRate(true);
                            }}
                          >
                            {n}
                          </div>
                        )
                      )}
                    </div>
                  )}

                  {selectedRate && uiSwitch === 0 && (<div className="">
                    <div className="mt-14 md:mt-10">
                      <div className="w-full mt-4">
                        <label className="block mb-0.5 text-xs font-medium text-gray-800" >
                          Review
                        </label>
                        <textarea
                          // name="reviewMessage"
                          rows={3}
                          {...formik.getFieldProps("reviewMessage")}
                          className="w-full px-2.5 py-1.5 text-sm text-black border border-gray-400 rounded outline-none placeholder:text-gray-500 placeholder:text-xs"
                          placeholder="Please enter or record your review"
                        ></textarea>
                        {formik.errors.reviewMessage ? (
                          <InputMessage message={formik.errors.reviewMessage} />
                        ) : null}
                      </div>
                      <div className="relative">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setIsAnonymous(!isAnonymous);
                            }}
                            className="sr-only peer"
                            checked={isAnonymous}
                          />
                          <div className="w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-2.5 after:w-2.5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          <span className="ml-2 text-xs font-medium text-black">Anonymous</span>
                        </label>
                      </div>
                      {!isAnonymous && (
                        <div className="flex flex-col items-start justify-between w-full mt-4 space-y-4 md:flex-row md:space-y-0 md:space-x-2 lg:space-x-4">
                        <div className="w-full">
                          <Input
                            formik={formik}
                            name="email"
                            label="Email"
                            type="text"
                            placeHolder="example@email.com"
                          />
                          {formik.errors.email ? (
                            <InputMessage message={formik.errors.email} />
                          ) : null}
                        </div>
                        <div className="w-full">
                          <Input
                            formik={formik}
                            name="phoneNumber"
                            label="Phone number"
                            type="tel"
                            placeHolder="+XXXXXXXXXX"
                          />
                          {formik.errors.phoneNumber ? (
                            <InputMessage message={formik.errors.phoneNumber} />
                          ) : null}
                        </div>
                      </div>
                      )}
                      <div className="hidden">
                        {questions.status === "loading" ? (
                          <div className="flex items-center justify-start py-5">
                            <ImSpinner8
                              size={38}
                              className="mx-auto animate-spin fill-primary"
                            />
                          </div>
                        ) : questions.status === "error" ? (
                          <span>Error: {questions.error.message}</span>
                        ) : questions.data.length > 0 ? (
                          questions.data.map((question: any, index: number) => {
                            return (
                              <div
                                key={index}
                                className="flex flex-col mt-4 space-y-1"
                              >
                                <div className="w-full">
                                  <p className="block text-xs font-medium text-black">
                                    {question.question}
                                  </p>
                                </div>
                                <div className="flex flex-col space-y-0.5">
                                  <div>
                                    <label className="flex items-center space-x-1 text-xs font-medium cursor-pointer">
                                      <input
                                        type="radio"
                                        value={"pos"}
                                        className="w-3 h-3 cursor-pointer"
                                        checked={questns[index + 1] === "pos"}
                                        onChange={(e: any) => {
                                          doReactionToggle(
                                            index + 1,
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <span>Yes</span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="flex items-center space-x-1 text-xs font-medium cursor-pointer">
                                      <input
                                        type="radio"
                                        value={"neg"}
                                        className="w-3 h-3 cursor-pointer"
                                        checked={questns[index + 1] === "neg"}
                                        onChange={(e: any) => {
                                          doReactionToggle(
                                            index + 1,
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <span>No</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-base text-gray-800">
                            No questions yet
                          </p>
                        )}
                      </div>

                      <div className="flex items-center justify-end mt-4 space-x-3">
                        {/* <div className="w-16">
                          <button onClick={() => {
                            setSelectedRate(false);
                          }} className={classNames("w-full py-2 text-sm font-semibold text-center text-black outline-none")}>Skip</button>
                        </div> */}
                        <div className="w-20">
                          <Button
                            type="button"
                            value="Submit"
                            disabled={formik.isSubmitting}
                            isLoading={formik.isSubmitting}
                            onClick={() => {
                              if (formik.values === formik.initialValues) {
                                setExpType(0);
                                formik.submitForm();
                                return;
                              }
                              setExpType(2);
                              formik.submitForm();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>)}

                  <hr className="w-full mt-8 mb-3 border-t-2 border-gray-200" />

                  {/* {uiSwitch === 0 && (
                    <div className="w-32 mx-auto mt-4">
                      <Button
                        type="button"
                        value="Submit"
                        disabled={formik.isSubmitting}
                        isLoading={formik.isSubmitting}
                        onClick={() => {
                          setExpType(0);
                          formik.submitForm();
                        }}
                      />
                    </div>
                  )} */}
                </div>

                {uiSwitch === 0 && (
                  <div className="">
                    <div className="">
                      <div className="grid grid-cols-1 gap-y-3">
                        <div className="w-full mx-auto mt-5">
                          {webUrl.status === "loading" ? (
                            <div className="flex items-center justify-start py-5">
                              <ImSpinner8
                                size={38}
                                className="mx-auto animate-spin fill-primary"
                              />
                            </div>
                          ) : webUrl.status === "error" ? (
                            <span>Error: {webUrl.error.message}</span>
                          ) : (
                            <div
                              onClick={() => {
                                if (webUrl.data !== "") {
                                  window.open(webUrl.data);
                                  return;
                                }
                                // window.open(`${window.location.origin}/templates/starter/${id}`);
                                window.open(
                                  `${window.location.origin}/store/${salt}/${slug}`
                                );
                              }}
                              className="h-full p-0.5 rounded-md shadow-sm cursor-pointer bg-white border border-gray-400 w-full"
                            >
                              <div className="h-full p-3 text-left text-black bg-white rounded-md">
                                <h6 className="mb-0.5 text-base font-bold">
                                  Visit our e-store
                                </h6>
                                <p className="text-xs font-medium">
                                  Check out our products and services at the
                                  best prices.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        {[1].map((item: number, index: number) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                // setRate(n);
                                // setSelectedRate(false);
                                setError("");
                                setUISwitch(item);
                                setExpType(item);
                              }}
                              className="h-full p-0.5 rounded-md shadow-sm cursor-pointer bg-white border border-gray-400 w-full"
                            >
                              <div className="h-full p-3 text-left text-black bg-white rounded-md">
                                <h6 className="mb-0.5 text-base font-bold">
                                  {item === 1
                                    ? "Report an issue"
                                    : "Give a review"}
                                </h6>
                                <p className="text-xs font-medium">
                                  {item === 1
                                    ? "Do you need urgent support? Let us know what went wrong."
                                    : "Help us be better by sharing your opinions about us."}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                {uiSwitch === 1 && (
                  <div className="">
                    <div className="mt-14 md:mt-10">
                      <div className="flex flex-col items-center justify-between w-full mt-4 space-y-4 sm:space-x-4 sm:space-y-0 sm:items-start sm:flex-row">
                        <div className="w-full">
                          <Input
                            formik={formik}
                            name="email"
                            label="Email"
                            type="text"
                            placeHolder="example@email.com"
                          />
                          {formik.errors.email ? (
                            <InputMessage message={formik.errors.email} />
                          ) : null}
                        </div>
                        <div className="w-full">
                          <Input
                            formik={formik}
                            name="phoneNumber"
                            label="Phone number"
                            type="tel"
                            placeHolder="+XXXXXXXXXX"
                          />
                          {formik.errors.phoneNumber ? (
                            <InputMessage message={formik.errors.phoneNumber} />
                          ) : null}
                        </div>
                      </div>
                      <div className="w-full mt-4">
                        <label className="block mb-1 text-xs font-semibold text-black">
                          Report an issue
                        </label>
                        <textarea
                          // name="reviewMessage"
                          rows={3}
                          {...formik.getFieldProps("reviewMessage")}
                          className="w-full px-2.5 py-1.5 text-sm text-black border border-gray-400 rounded outline-none placeholder:text-gray-300"
                          placeholder="Write something..."
                        ></textarea>
                        {formik.errors.reviewMessage ? (
                          <InputMessage message={formik.errors.reviewMessage} />
                        ) : null}
                      </div>
                      <div className="w-32 mx-auto mt-4">
                        <Button
                          type="button"
                          value="Submit"
                          disabled={formik.isSubmitting}
                          isLoading={formik.isSubmitting}
                          onClick={() => {
                            if (formik.values === formik.initialValues) {
                              setExpType(0);
                              formik.submitForm();
                              return;
                            }
                            setExpType(1);
                            formik.submitForm();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {uiSwitch === 2 && (
                  <div className="">
                    <div className="mt-14 md:mt-10">
                      <div className="w-full mt-4">
                        <label className="block mb-0.5 text-xs font-medium text-gray-800" >
                          Review
                        </label>
                        <textarea
                          // name="reviewMessage"
                          rows={3}
                          {...formik.getFieldProps("reviewMessage")}
                          className="w-full px-2.5 py-1.5 text-sm text-black border border-gray-400 rounded outline-none placeholder:text-gray-500 placeholder:text-xs"
                          placeholder="Please enter or record your review"
                        ></textarea>
                        {formik.errors.reviewMessage ? (
                          <InputMessage message={formik.errors.reviewMessage} />
                        ) : null}
                      </div>
                      <div className="flex flex-col items-start justify-between w-full mt-4 space-y-4 md:flex-row md:space-y-0 md:space-x-2 lg:space-x-4">
                        <div className="w-full">
                          <Input
                            formik={formik}
                            name="email"
                            label="Email"
                            type="text"
                            placeHolder="example@email.com"
                          />
                          {formik.errors.email ? (
                            <InputMessage message={formik.errors.email} />
                          ) : null}
                        </div>
                        <div className="w-full">
                          <Input
                            formik={formik}
                            name="phoneNumber"
                            label="Phone number"
                            type="tel"
                            placeHolder="+XXXXXXXXXX"
                          />
                          {formik.errors.phoneNumber ? (
                            <InputMessage message={formik.errors.phoneNumber} />
                          ) : null}
                        </div>
                      </div>
                      <div className="hidden">
                        {questions.status === "loading" ? (
                          <div className="flex items-center justify-start py-5">
                            <ImSpinner8
                              size={38}
                              className="mx-auto animate-spin fill-primary"
                            />
                          </div>
                        ) : questions.status === "error" ? (
                          <span>Error: {questions.error.message}</span>
                        ) : questions.data.length > 0 ? (
                          questions.data.map((question: any, index: number) => {
                            return (
                              <div
                                key={index}
                                className="flex flex-col mt-4 space-y-1"
                              >
                                <div className="w-full">
                                  <p className="block text-xs font-medium text-black">
                                    {question.question}
                                  </p>
                                </div>
                                <div className="flex flex-col space-y-0.5">
                                  <div>
                                    <label className="flex items-center space-x-1 text-xs font-medium cursor-pointer">
                                      <input
                                        type="radio"
                                        value={"pos"}
                                        className="w-3 h-3 cursor-pointer"
                                        checked={questns[index + 1] === "pos"}
                                        onChange={(e: any) => {
                                          doReactionToggle(
                                            index + 1,
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <span>Yes</span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="flex items-center space-x-1 text-xs font-medium cursor-pointer">
                                      <input
                                        type="radio"
                                        value={"neg"}
                                        className="w-3 h-3 cursor-pointer"
                                        checked={questns[index + 1] === "neg"}
                                        onChange={(e: any) => {
                                          doReactionToggle(
                                            index + 1,
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <span>No</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-base text-gray-800">
                            No questions yet
                          </p>
                        )}
                      </div>

                      <div className="w-32 mx-auto mt-4">
                        <Button
                          type="button"
                          value="Submit"
                          disabled={formik.isSubmitting}
                          isLoading={formik.isSubmitting}
                          onClick={() => {
                            if (formik.values === formik.initialValues) {
                              setExpType(0);
                              formik.submitForm();
                              return;
                            }
                            setExpType(2);
                            formik.submitForm();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>

          <div className="w-full text-center">
            <p className="text-xs font-bold text-gray-800">
              Powered by{" "}
              <span className="underline text-primary underline-offset-2">
                <a
                  href="https://www.cusecho.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  cusecho.com
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={goBack}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[290px] md:w-[360px] lg:w-[420px] max-w-lg transform text-center overflow-hidden rounded-md bg-white px-6 align-middle shadow-xl transition-all">
                  <div className="flex justify-center m-4">
                    <img
                      src="/media/image/emoji-feedback-interaction.png"
                      alt="emoji-feedback-interaction"
                      className="w-28 h-28"
                    />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="mb-4 text-sm font-semibold leading-6 text-gray-800"
                  >
                    Thank you for your Feedback
                  </Dialog.Title>
                  <div className="w-full my-8">
                    <button
                      onClick={goBack}
                      className="w-2/3 py-2 mx-auto text-xs font-semibold leading-6 text-center text-white rounded-md outline-none lg:w-1/2 md:text-sm bg-primary"
                    >
                      {/* Go back to home page */}
                      Go back
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default FeedBackPageNew;
