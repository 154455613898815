import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CognitoUser
} from "amazon-cognito-identity-js";
import { Link, useNavigate } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { classNames } from "../../../utils";
import UserPool from "../../../setup/userpool";
import InputMessage from "../../../components/Auth/Forms/InputMessage";
import Button from "../../../components/Auth/Forms/Button";
import Alert from "../../../components/Auth/Forms/Alert/indext";
import Modal from "../../../components/Dashboard/Modal";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

interface InputProps {
  name: string;
  label: string;
  type?: string;
  placeHolder: string;
  className?: any;
  formik?: any;
  disabled?: boolean;
}

const Input = ({
  name,
  label,
  type = "text",
  placeHolder,
  className,
  formik,
  disabled = false,
}: InputProps) => {
  return (
    <div className="w-full">
      <label className="block text-xs mb-0.5 font-medium text-gray-600 mt-4">
        {label}
      </label>
      <input
        name={name}
        type={type}
        placeholder={placeHolder}
        {...(formik ? formik.getFieldProps(name) : [])}
        disabled={disabled}
        className={classNames(
          className,
          "border border-gray-500 py-2 px-5 bg-white rounded-md w-full outline-none text-gray-800 placeholder:text-sm "
        )}
      />
    </div>
  );
};

const initialValues = {
  code: "",
  nPassword: "",
  cNPassword: "",
};

const changePasswordSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, "Minimum 6 letters")
    .max(6, "Maximum 6 letters")
    .required("Code is required"),
  nPassword: Yup.string()
    .min(8, "Minimum 8 letters")
    .max(20, "Maximum 20 letters")
    .required("Password is required"),
  cNPassword: Yup.string()
    .oneOf([Yup.ref("nPassword")], "Passwords must match")
    .required("Required"),
});

const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;

const PasswordPage1 = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [nPasswordIsVisible, setNPasswordIsVisible] = useState(false);
  const [cNPasswordIsVisible, setCNPasswordIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      setError("");
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });
      setError("");
      setLoading(true);
      user.confirmPassword(values.code, values.nPassword, {
        onSuccess: (data) => {
          console.log(data);
          setLoading(false);
          setSubmitting(false);
          setShowModal(true);
        },
        onFailure: (err) => {
          console.log(err);
          setLoading(false);
          setError(err.message);
          setSubmitting(false);
        },
      });
    },
  });

  const sendCode = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    if (email === "") {
      setError("This is a required field");
      setLoading(false);
      return;
    }

    if (regEx.test(email)) {
      user.forgotPassword({
        onSuccess: (data) => {
          console.log(data);
          setLoading(false);
        },
        onFailure: (err) => {
          console.log(err);
          setLoading(false);
          setError(err.message);
        },

        inputVerificationCode: (data) => {
          console.log(data);
          setStep(2);
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
      setError("invalid email");
    }
  };

  return step === 1 ? (
    <div className="w-full h-screen flex justify-center items-center py-12 px-4" >
      <div className="w-[500px] max-w-[500px] mx-auto" >
      <div className=" absolute top-4 left-[4%] flex">
      <Link to="https://www.cusecho.com/" target="_blank" ><img src="media/image/cusecho-onboarding-logo.png" alt="" className="w-[130px]" /></Link>
       </div>
      <div className="absolute top-4 right-[4%] flex">
       <button className="text-sm py-2 px-6 font-semibold text-center text-white outline-none rounded-md bg-[#263C6B]" onClick={() => navigate("/register")}>Sign Up</button>
       </div>
      <div className="text-left">
      <h3 className="font-semibold text-xl text-[#263C6B]" >
        Enter your email address
      </h3>
        <p className="text-sm text-gray-800 font-medium" >
        We need this to send a verification code.
        </p>
      </div>
      <div className="mt-4">
        <form className="w-full">
          <div className="">
            <div className="w-full mb-4">
              <label className="block mb-0.5 text-xs font-semibold text-gray-900">
                Email
              </label>
              <input
                type="email"
                placeholder="Enter email"
                className="border border-gray-300 py-2.5 px-5 bg-white rounded-md w-full outline-none text-gray-900 text-base"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <p className="text-red-600">{error}</p>
            </div>
          </div>
          <button
            type="submit"
            className="text-sm w-full py-2.5 text font-semibold text-center text-white outline-none rounded-md bg-[#263C6B]"
            onClick={sendCode}
          >
            {loading ? (
              <ImSpinner2 size={22} className="mx-auto animate-spin" />
            ) : (
              "Send Verification Code"
            )}
          </button>
        </form>
      </div>
    </div>
    </div>
  ) : step === 2 ? (
    <div className="w-full h-screen flex justify-center items-center py-12 px-4" >
      <div className=" absolute top-4 left-[4%] flex">
      <Link to="https://www.cusecho.com/" target="_blank" ><img src="media/image/cusecho-onboarding-logo.png" alt="" className="w-[130px]" /></Link>
       </div>
      <div className="absolute top-4 right-[4%]">
       <button className="py-2 px-6 font-semibold text-center text-white outline-none rounded-md bg-[#263C6B]" onClick={() => navigate("/register")}>Sign up</button>
       </div>
       <div className="w-full">
       <p className="w-full ml-4 sm:ml-[10%] md:[15%] font-semibold flex items-center gap-2 mt-4 cursor-pointer text-gray-400" onClick={() => setStep(1)} ><MdOutlineKeyboardBackspace /><span>Back</span></p>
      <div className="max-w-[500px] mx-auto">
      <div className="flex items-center justify-center ">
      <img src="/media/svg/sentMessage.svg" alt="reset password" className=""/>
      </div>
      <div className="text-center mt-4">
        <p className="text-xl text-[#263C6B] font-semibold">Check Your Email</p>
        <p className="text-sm text-gray-800 font-medium">
          A verification code has been sent to {email}
        </p>
      </div>
      <button
        type="button"
        className=" w-full text-white bg-[#263C6B] font-medium rounded px-8 py-3 mt-4"
        onClick={() => setStep(3)}
      >
        Continue
      </button>
       </div>
    </div>
    </div>
    
  ) : (
    <div className="w-full h-screen flex justify-center items-center py-12 px-4">
      <div className=" absolute top-4 left-[4%] flex">
      <Link to="https://www.cusecho.com/" target="_blank" ><img src="media/image/cusecho-onboarding-logo.png" alt="" className="w-[130px]" /></Link>
       </div>
      <div className="absolute top-4 right-[4%] flex">
       <button className="text-sm py-2 px-6 font-semibold text-center text-white outline-none rounded-md bg-[#263C6B]" onClick={() => navigate("/register")}>Sign up</button>
       </div>
    <div className="w-[500px] max-w-[500px] mx-auto">
      <div className="text-left">
        <p className="text-xl text-[#263C6B] font-semibold">OTP</p>
        <p className="text-sm text-gray-800 font-medium">
          Enter the 6 digits code that was sent to you
        </p>
      </div>
      <div className="mt-4 w-full">
        <form className="" onSubmit={formik.handleSubmit}>
          {error && <Alert type="error" message={error} />}
          <div className="">
            <Input
              formik={formik}
              name="code"
              label="Verification Code"
              type="text"
              placeHolder="Enter the 6 digit code"
            />
            {formik.values.code && formik.errors.code && (
              <InputMessage message={formik.errors.code} />
            )}
            <div className="relative">
              <Input
                formik={formik}
                name="nPassword"
                label="New Password"
                type={nPasswordIsVisible ? "text" : "password"}
                placeHolder="**********"
              />
              <button
                type="button"
                onClick={() => setNPasswordIsVisible(!nPasswordIsVisible)}
                className="absolute outline-none text-[#263C6B] right-4 bottom-2"
              >
                {nPasswordIsVisible ? (
                  <AiOutlineEye size={25} />
                ) : (
                  <AiOutlineEyeInvisible size={25} />
                )}
              </button>
            </div>
            {formik.values.nPassword && formik.errors.nPassword && (
              <InputMessage message={formik.errors.nPassword} />
            )}
            <div className="relative">
              <Input
                formik={formik}
                name="cNPassword"
                label="Confirm New Password"
                type={cNPasswordIsVisible ? "text" : "password"}
                placeHolder="**********"
              />
              <button
                type="button"
                onClick={() => setCNPasswordIsVisible(!cNPasswordIsVisible)}
                className="absolute outline-none text-[#263C6B] right-4 bottom-2"
              >
                {cNPasswordIsVisible ? (
                  <AiOutlineEye size={24} />
                ) : (
                  <AiOutlineEyeInvisible size={24} />
                )}
              </button>
            </div>

            {formik.values.cNPassword && formik.errors.cNPassword && (
              <InputMessage message={formik.errors.cNPassword} />
            )}
          </div>
          <br />
          <Button
            type="submit"
            value="Change Password"
            changeBgColor={true}
            disabled={formik.isSubmitting}
            isLoading={formik.isSubmitting}
          />
        </form>
      </div>
      <p className="text-sm mt-3 text-gray-600 text-center hidden">
        Didn't receive any code?{" "}
        <span className="text-blue-700 hover:text-blue-800 cursor-pointer font-semibold" >Resend Code</span>
      </p>
    </div>
    <Modal show={showModal}>
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 space-y-2 text-center md:mt-8">
          <h1 className="font-semibold md:text-2xl">
              Cusecho
            </h1>
            <p className="md:text-base">
            Your Password has been successfully reset
            </p>
          </div>
          <div className="flex justify-center gap-2 mt-6 md:mt-10">
            <div>
              <button
                className="w-full px-8 py-2 font-bold text-center text-white bg-blue-700 hover:bg-blue-800 border rounded-md"
                onClick={() => {
                  setShowModal(false)
                  navigate('/login')
                }}
              >
               Go to Login
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  ) 
};

export default PasswordPage1;
