import React, { useState } from "react";
import Modal from "../../../../../../components/Dashboard/Modal";
import { getImage, getSvg } from "../../../../../../utils";
import { FiChevronRight } from "react-icons/fi";
import { MdKeyboardBackspace } from "react-icons/md";
import { useMutation } from "@tanstack/react-query";
import { useAppSelector } from "../../../../../../hooks";
import { UserProfileModel } from "../../../../../../models";
import { resetPasscode } from "../../../../../../api/Menu";
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../../../../../../setup/userpool";
import { ImSpinner2 } from "react-icons/im";
import Alert from "../../../../../../components/Auth/Forms/Alert/indext";
import { Input } from "../../../Home/EchoWebpage/EchoWebpage1";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import InputMessage from "../../../../../../components/Auth/Forms/InputMessage";
import toast, { Toaster } from "react-hot-toast";

const initialValues = {
  code: "",
  nPassword: "",
  cNPassword: "",
};

const changePasswordSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, "Minimum 6 letters")
    .max(6, "Maximum 6 letters")
    .required("Code is required"),
  nPassword: Yup.string()
    .min(8, "Minimum 8 letters")
    .max(20, "Maximum 20 letters")
    .required("Password is required"),
  cNPassword: Yup.string()
    .oneOf([Yup.ref("nPassword")], "Passwords must match")
    .required("Required"),
});

const EstoreSecurity = () => {
  const [showModal, setShowModal] = useState(false);
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const email = user.email;
  const platformKey = user.queryKey || undefined || "";
  // const platformKey = user.queryKey.replace("%26", "&") || ""
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [nPasswordIsVisible, setNPasswordIsVisible] = useState(false);
  const [cNPasswordIsVisible, setCNPasswordIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResetPasscode = useMutation({
    mutationFn: async (data) => {
      const payload = {
        platformKey: platformKey.replace("%26", "&"),
      };
      return await resetPasscode(payload);
    },
    onSuccess: () => {
      toast.success("Please check your email for a passcode reset link");
      setStep(1);
    },
  });

  const sendCode = (e: any) => {
    e.preventDefault();
    setLoading(true);
    // setError("");
    const user = new CognitoUser({
      Username: email || undefined || "",
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess: (data) => {
        // console.log(data);
        setLoading(false);
      },
      onFailure: (err) => {
        // console.log(err);
        setLoading(false);
        // setError(err.message);
      },

      inputVerificationCode: (data) => {
        // console.log(data);
        setStep(4);
        setLoading(false);
      },
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      setError("");
      const user = new CognitoUser({
        Username: email || undefined || "",
        Pool: UserPool,
      });
      setError("");
      setLoading(true);
      user.confirmPassword(values.code, values.nPassword, {
        onSuccess: (data) => {
          setLoading(false);
          setSubmitting(false);
          resetForm({});
          setShowModal(true);
        },
        onFailure: (err) => {
          console.log(err);
          setLoading(false);
          setError(err.message);
          setSubmitting(false);
        },
      });
    },
  });

  return (
    <>
      <Modal
        size="medium"
        show={showModal}
        onToggle={() => {
          setShowModal(false);
          setStep(1);
        }}
      >
        <div className="relative flex flex-col p-6 md:py-12 md:px-16">
          <div className="mt-5 text-center md:mt-8">
            <img src={getSvg("check")} alt="icon" className="w-24 mx-auto" />
          </div>
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-medium">
              Your password has been successfully changed.
            </h1>
          </div>
        </div>
      </Modal>
      {step === 1 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p className="text-gray-800">Security</p>
          <div className="mt-4">
            <p className="text-blue-700">Security settings</p>
            <div className="mt-4 flex items-center justify-between">
              <div className="text-sm space-y-1">
                <p>Reset Password</p>
                <p className="text-gray-600">*********</p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  setStep(3);
                }}
              />
            </div>
            <div className="mt-4 flex items-center justify-between">
              <div className="text-sm space-y-1">
                <p>Reset encrypted passcode</p>
                <p className="text-gray-600">*********</p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  setStep(2);
                }}
              />
            </div>
            <div className="mt-4 flex items-center justify-between hidden">
              <div className="text-sm space-y-1">
                <p>2 factor authentication</p>
                <p className="text-gray-600">connected</p>
              </div>
              <FiChevronRight
                className="cursor-pointer"
                size={20}
                // onClick={() => {
                //   setStep(2);
                // }}
              />
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(1)}
          >
            <MdKeyboardBackspace className="" />
            Passcode
          </p>
          <div className="mx-auto mt-32">
            <p className="max-w-[350px] mx-auto text-sm text-center">
              Reset your encrypted 6-digit passcode to secure sensitive data.
              You will get a reset line sent to your e-mail address.
            </p>
            <div className="flex items-start justify-center mt-4">
              <button
                // disabled
                className="w-[200px] py-2.5 text-white bg-blue-600 rounded-md"
                onClick={() => {
                  handleResetPasscode.mutate();
                }}
              >
                {handleResetPasscode.isLoading ? <ImSpinner2 size={20} className="mx-auto animate-spin" /> : "Reset passcode"}
              </button>
            </div>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="col-span-3 h-full bg-white p-3">
          <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(1)}
          >
            <MdKeyboardBackspace className="" />
            Password reset
          </p>
          <div className="mt-32">
            <form className="max-w-[500px] px-4 mx-auto">
              <div className="">
                <div className="w-full mb-4">
                  <label className="block mb-1 text-xs font-normal text-gray-500">
                    Email address
                  </label>
                  <input
                    type="email"
                    disabled
                    className="w-full px-2 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-md outline-none placeholder:text-sm"
                    defaultValue={email}
                  />
                  {/* <p className="text-red-600">{error}</p> */}
                </div>
              </div>
              <div className="flex items-center justify-center mt-8">
                <button
                  type="submit"
                  className="py-3 text-xs text-center text-white bg-blue-700 rounded-md outline-none  w-44"
                  onClick={sendCode}
                >
                  {loading ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Send verification code"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {step === 4 && (
        <div className="col-span-3 h-full bg-white p-3">
           <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(3)}
          >
            <MdKeyboardBackspace className="" />
            Back
          </p>
          <div className="relative h-full mt-12">
            <div className="max-w-[500px] px-4 mx-auto mt-4">
              <div className="flex items-center justify-center">
                <img
                  src="/media/svg/sentMessage.svg"
                  alt="reset password"
                  className=""
                />
              </div>
              <p className="max-w-full mx-auto text-sm text-center text-gray-500">
                We have sent a verifcation code to {email}
              </p>
              <div className="flex items-center justify-center mt-4">
                <button
                  type="button"
                  className=" w-[60%] text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-md text-sm px-8 py-2.5"
                  onClick={() => setStep(5)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 5 && (
        <div className="col-span-3 h-full bg-white p-3">
           <p
            className="text-gray-800 cursor-pointer inline-flex items-center gap-1.5"
            onClick={() => setStep(4)}
          >
            <MdKeyboardBackspace className="" />
            Bank
          </p>
          <div className="relative h-full mt-12">
            <div className="w-full">
              <form
                className="max-w-[400px] mx-auto"
                onSubmit={formik.handleSubmit}
              >
                {error && <Alert type="error" message={error} />}
                <div className="">
                  <Input
                    formik={formik}
                    name="code"
                    label="Verification Code"
                    type="text"
                    placeHolder="Enter the 6 digit code sent to your email"
                  />
                  {formik.values.code && formik.errors.code && (
                    <InputMessage message={formik.errors.code} />
                  )}
                  <div className="relative mt-4">
                    <Input
                      formik={formik}
                      name="nPassword"
                      label="New Password"
                      // type={nPasswordIsVisible ? "text" : "password"}
                      type="text"
                      placeHolder=""
                    />
                    <button
                      type="button"
                      onClick={() => setNPasswordIsVisible(!nPasswordIsVisible)}
                      className="absolute hidden outline-none text-secondary right-4 inset-y-7"
                    >
                      {nPasswordIsVisible ? (
                        <AiOutlineEye size={25} />
                      ) : (
                        <AiOutlineEyeInvisible size={25} />
                      )}
                    </button>
                  </div>
                  {formik.values.nPassword && formik.errors.nPassword && (
                    <InputMessage message={formik.errors.nPassword} />
                  )}
                  <div className="relative mt-4">
                    <Input
                      formik={formik}
                      name="cNPassword"
                      label="Confirm New Password"
                      // type={cNPasswordIsVisible ? "text" : "password"}
                      type="text"
                      placeHolder=""
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setCNPasswordIsVisible(!cNPasswordIsVisible)
                      }
                      className="absolute hidden outline-none text-secondary right-4 inset-y-7"
                    >
                      {cNPasswordIsVisible ? (
                        <AiOutlineEye size={25} />
                      ) : (
                        <AiOutlineEyeInvisible size={25} />
                      )}
                    </button>
                  </div>

                  {formik.values.cNPassword && formik.errors.cNPassword && (
                    <InputMessage message={formik.errors.cNPassword} />
                  )}
                </div>
                <div className="w-[190px] my-4 hidden">
                  <p className="text-sm font-semibold text-[#0E4A67]">
                    Password Should Contain
                  </p>
                  <div className="mt-3">
                    <ul className="space-y-2 text-xs text-gray-700">
                      <li className="flex items-center gap-2">
                        {" "}
                        <img src="/media/svg/checkGrayBg.svg" alt="" />
                        <span>At least 8 characters</span>
                      </li>
                      <li className="flex items-center gap-2">
                        {" "}
                        <img src="/media/svg/checkGrayBg.svg" alt="" />
                        <span>An Uppercase Letter</span>
                      </li>
                      <li className="flex items-center gap-2">
                        {" "}
                        <img src="/media/svg/checkGrayBg.svg" alt="" />
                        <span>A Number</span>
                      </li>
                      <li className="flex items-center gap-2">
                        {" "}
                        <img src="/media/svg/checkGrayBg.svg" alt="" />
                        <span>A special Character e.g !,.,-,?</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="bg-blue-700 text-white text-center text-sm py-2.5 px-5 w-full sm:w-48 rounded-md mt-8"
                >
                  {formik.isSubmitting ? (
                    <ImSpinner2 size={20} className="mx-auto animate-spin" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      
       <Toaster position="top-center" containerClassName="" gutter={200} />
    </>
  );
};

export default EstoreSecurity;
