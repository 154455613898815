// @ts-nocheck

import {useState} from "react";
import AddItem from "./AddItem";
import DeliveryMethods from "./DeliveryMethods";
import AllCategories from "./AllCategories";
import ItemsInCategory from "./ItemsInCategory";
import EditItemInCategory from "./EditItemInCategory";
import EditCategory from "./EditCategory";
import AddItemNew from "./AddItemNew";
import AllItemsNew from "./AllItemsNew";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { UserProfileModel } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../hooks";
import { downloadStoreItemCount, downloadStoreItems } from "../../../../../../../api/Menu";

const ProductsSidePanel = ({
  productSidePanelStep,
  setProductSidePanelStep,
  step,
  setStep,
  activeCategory,
  setActiveCategory,
}) => {
  const [selectedObject, setSelectedObject] = useState({})

  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  let storeItemCount = 0;

  const getStoreItemCount = useQuery<any, Error>({
    queryKey: ["getStoreItemCount"],
    queryFn: async () => {
      const response = await downloadStoreItemCount(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      
    }
  });

  if (getStoreItemCount.data){
    storeItemCount = getStoreItemCount.data.Store_Item_Count;
  }

  return (
    <>
      {productSidePanelStep === 1 ? (
        <div className="p-3">
          
          <AllItemsNew
          selectedObject={selectedObject} 
          setSelectedObject={setSelectedObject}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            setStep={setStep}
            setProductSidePanelStep={setProductSidePanelStep}
          />

        </div>
      ) : productSidePanelStep === 2 ? (
        <div className="p-3">
          <AddItem
            productSidePanelStep={productSidePanelStep}
            setProductSidePanelStep={setProductSidePanelStep}
            step={step}
            setStep={setStep}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        </div>
      ) : productSidePanelStep === 3 ? (
        <ItemsInCategory
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          setStep={setStep}
          setSelectedObject={setSelectedObject}
          selectedObject={selectedObject}
          setProductSidePanelStep={setProductSidePanelStep}
        />
      ) : productSidePanelStep === 4 ? (
        <EditItemInCategory
          setStep={setStep}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          setProductSidePanelStep={setProductSidePanelStep}
        />
      ) : productSidePanelStep === 5 ? (
        <DeliveryMethods />
      ) : productSidePanelStep === 6 ? (
        <EditCategory activeCategory={activeCategory} setActiveCategory={setActiveCategory} setProductSidePanelStep={setProductSidePanelStep} selectedObject={selectedObject} 
        setSelectedObject={setSelectedObject} />
      ) : (
        <div className="p-3">
        <AddItemNew
          setProductSidePanelStep={setProductSidePanelStep}
          storeItemCount={storeItemCount}
        />
      </div>
      )}
    </>
  );
};

export default ProductsSidePanel;
