// @ts-nocheck
/* eslint-disable array-callback-return */

import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useRef, useState, useEffect } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IStoreInfoResponse } from "../../models";
import { v4 as uuidv4 } from "uuid";
import {
  getAboutUs,
  getAllMenuCategories,
  getOpeningHours,
  getSiteTitleAndTagLine,
  getStoreAddress,
  getTemplateSections,
} from "../../api/Menu";
import { ImSpinner8 } from "react-icons/im";
import Slider from "react-slick";
import {
  SERVICE_SLIDE_SETTINGS,
  base64Encode,
  classNames,
  formatStoreName,
  maskEmail,
  ratingsToNumber,
} from "../../utils";
import {
  getAllPublicProducts,
  getPublicFeedBacks,
  getPublicServices,
} from "../../api/Utils";
import { ReviewStars } from "../../components/Dashboard/DashBoardContainer/ReviewUpdates";
import Layout from "../../components/WebStore/Layout";
import ProductCard from "../../components/WebStore/Products/ProductCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UAParser } from "ua-parser-js";
import { useIdleTimer } from "react-idle-timer";

const WebStoreLandingPage = () => {
  const { salt, slug, section } = useParams();
  const queryKey = `/${salt}/${slug}`;
  const storeName = formatStoreName(queryKey);

  const [success, setSuccess] = useState(false);
  const scrollRef = useRef(HTMLDivElement.prototype);

  const productDivRef = useRef(HTMLDivElement.prototype);
  const aboutDivRef = useRef(HTMLDivElement.prototype);
  let services: any[] = [];
  let templateSections: any[] = [];
  let storeAddress: string = "";
  let aboutUs: string = "";

  let comments: any[] = [];
  let products: any[] = [];
  let heroImageUrl: string =
    "https://d1ntmcvl0pjnmq.cloudfront.net" + queryKey + "/heroImage/image.png";
  let siteName: string = storeName;
  let siteTagLine: string = "[SITE_TAGLINE_HERE]";
  const [clientIpAddress, setClientIpAddress] = useState("");

  const [allOpeningHours, setAllOpeningHours] = useState<any[]>([]);

  const scroll = (offset: number) => {
    scrollRef.current.scrollLeft += offset;
  };

  const [state, setState] = useState<string>("Active");
  const [count, setCount] = useState<number>(0);
  const [remaining, setRemaining] = useState<number>(0);

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  useEffect(() => {
    localStorage.setItem("PLD", base64Encode(`/${salt}/${slug}`));
  }, [section]);

  const address = useQuery<any, Error>({
    queryKey: ["getStoreAddress"],
    queryFn: async () => {
      const response = await getStoreAddress(queryKey);
      return response.data;
    },
  });

  const siteTitleAndTagLine = useQuery<IStoreInfoResponse, Error>({
    queryKey: ["downloadSiteTitleAndTagLine"],
    queryFn: async () => {
      const response = await getSiteTitleAndTagLine(queryKey);
      return response.data;
    },
  });

  const loadTemplateSections = useQuery<any, Error>({
    queryKey: ["getTemplateSections"],
    queryFn: async () => {
      const response = await getTemplateSections(queryKey);
      return response.data;
    },
  });

  const allProducts = useQuery<any, Error>({
    queryKey: ["getAllPublicProducts"],
    queryFn: async () => {
      const pageKey = "";
      const response = await getAllPublicProducts(queryKey, pageKey);
      return response.data;
    },
  });

  const allServices = useQuery<any, Error>({
    queryKey: ["getServices", success],
    queryFn: async () => {
      const response = await getPublicServices(queryKey);
      return response.data;
    },
  });

  const openingHours = useQuery<any, Error>({
    queryKey: ["getOpeningHours"],
    queryFn: async () => {
      const response = await getOpeningHours(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      setAllOpeningHours([
        { ...data.Opening_Hours[0].Monday[0], day: "Monday" },
        { ...data.Opening_Hours[1].Tuesday[0], day: "Tuesday" },
        { ...data.Opening_Hours[2].Wednesday[0], day: "Wednesday" },
        { ...data.Opening_Hours[3].Thursday[0], day: "Thursday" },
        { ...data.Opening_Hours[4].Friday[0], day: "Friday" },
        { ...data.Opening_Hours[5].Saturday[0], day: "Saturday" },
        { ...data.Opening_Hours[6].Sunday[0], day: "Sunday" },
      ]);
    },
  });

  const satisfaction = useQuery<any, Error>({
    queryKey: ["getPublicFeedBacks"],
    queryFn: async () => {
      const response = await getPublicFeedBacks(queryKey);
      return response.data;
    },
    onSuccess: (data) => {
      if (data === null || !data) {
        comments = [];
      } else {
        comments = data;
      }
    },
  });

  const storeAboutUs = useQuery<any, Error>({
    queryKey: ["getStoreAboutUs"],
    queryFn: async () => {
      const response = await getAboutUs(queryKey);
      return response.data;
    },
  });

  if (loadTemplateSections.data) {
    templateSections = loadTemplateSections.data.Template_Sections;
  }

  if (allProducts.data) {
    // console.log("OUR RESULT: ", allProducts.data);
    if ("Response" in allProducts.data) {
      if ("StoreItems" in allProducts.data.Response) {
        products = allProducts.data.Response.StoreItems;
        // console.log("products", products);
      }
    }
  }

  if (allServices.data) {
    services = allServices.data.Business_Services;
  }

  if (satisfaction.data) {
    comments = satisfaction.data.Public_Feedbacks;
    // console.log("COMMENTS", comments);
  }

  if (siteTitleAndTagLine.data) {
    siteName = siteTitleAndTagLine.data.Site_Title;
    siteTagLine = siteTitleAndTagLine.data.Tagline;
  }

  if (address.data) {
    storeAddress = address.data.Store_Address;
  }

  if (storeAboutUs.data) {
    aboutUs = storeAboutUs.data.About_Us;
  }

  async function getclientIpAddress() {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    if (data.ip) {
      setClientIpAddress(data.ip);
      // console.log("Your IP Address:", data.ip);
    } else {
      console.error("Error fetching IP address");
    }
  }

  return (
    <Layout>
      {/* <p>Current State: {state}</p>
      <p>Action Events: {count}</p>
      <p>{remaining} seconds remaining</p> */}
      <section
        className="relative bg-center bg-no-repeat bg-cover bg-black/60 py-28 bg-blend-overlay md:py-24 lg:py-40"
        style={{
          backgroundImage: `url(${heroImageUrl})`,
        }}
      >
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="w-10/12 space-y-2 text-center text-white lg:w-2/3">
            <h4 className="text-4xl font-semibold lg:text-5xl">
              {siteName === "" ? storeName : siteName}
            </h4>
            <p className="text-base text-gray-200 md:text-lg">{siteTagLine}</p>
          </div>
        </div>
      </section>

      <section>
        {(loadTemplateSections.isLoading || allProducts.isLoading) && (
          <div className="container px-4 py-14 md:py-14 md:px-14">
            <div className="grid grid-cols-2 max-w-[1100px] mx-auto gap-x-4 sm:gap-x-6 gap-6 lg:gap-10 md:grid-cols-3 lg:grid-cols-4">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((index: number) => (
                <div
                  className="bg-gray-100 h-[230px] rounded-md"
                  key={index}
                ></div>
              ))}
            </div>
          </div>
        )}
      </section>

      <section
        className={classNames(
          "bg-[#FFFFFD]",
          !templateSections.includes("Product") === true && products.length > 0
            ? ""
            : "hidden"
        )}
      >
        <div
          ref={productDivRef}
          className="container px-4 py-14 md:py-14 md:px-14"
        >
          <h4 className="mb-6 text-2xl font-medium text-center text-gray-800 capitalize">
            Products
          </h4>
          {allProducts.status === "loading" ? (
            <div className="grid grid-cols-2 max-w-[1100px] mx-auto gap-x-4 sm:gap-x-6 gap-6 lg:gap-10 md:grid-cols-3 lg:grid-cols-4">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((index: number) => (
                <div
                  className="bg-gray-100 h-[230px] rounded-md"
                  key={index}
                ></div>
              ))}
            </div>
          ) : allProducts.status === "error" ? (
            <p className="mt-8 font-semibold text-center text-gray-400">
              {allProducts.error.message}
            </p>
          ) : products.length > 0 ? (
            <div className="grid grid-cols-2 max-w-[580px] sm:max-w-[1100px] place-content-evenly mx-auto gap-x-4 sm:gap-x-6 gap-6 lg:gap-10 sm:grid-cols-3 lg:grid-cols-4">
              {products.map((product: any, index: number) => {
                return <ProductCard key={index} product={product} />;
              })}
            </div>
          ) : (
            <p className="mt-8 text-lg text-center text-gray-400">
              No products available
            </p>
          )}
          {products.length > 0 && (
            <Link
              to={`/store/${salt}/${slug}/all-products`}
              className="block w-44 py-2.5 mx-auto text-center text-blue-700 underline underline-offset-2 mt-14 text-sm"
            >
              See all
            </Link>
          )}
        </div>
      </section>

      <section
        className={classNames(
          "bg-[#F1F6FF]",
          !templateSections.includes("Services") === true && services.length > 0
            ? ""
            : "hidden",
          services.length < 1 ? "hidden" : "",
          services.length < 2 ? "md:hidden" : "",
          services.length < 3 ? "xl:hidden" : ""
        )}
      >
        <div
          className={classNames(
            "container px-4 py-14 pb-20 md:px-14 max-w-[1100px] mx-auto",
            services.length < 4 ? "max-w-[1000px] mx-auto" : ""
          )}
        >
          <h4 className="mb-8 text-2xl font-medium text-center text-gray-800">
            Other services we offer
          </h4>
          {allServices.status === "loading" ? (
            <div className="flex items-center justify-start py-5">
              <ImSpinner8
                size={38}
                className="mx-auto animate-spin fill-black"
              />
            </div>
          ) : allServices.status === "error" ? (
            <p className="mt-8 font-semibold text-center text-gray-400">
              {allServices.error.message}
            </p>
          ) : services.length > 0 ? (
            <div className="slider-container">
              <Slider {...SERVICE_SLIDE_SETTINGS}>
                {services.map((service: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="py-2.5 px-4.5 text-sm font-medium bg-white text-center text-gray-800 border border-gray-100 rounded-full"
                    >
                      {service.Service}
                    </div>
                  );
                })}
              </Slider>
            </div>
          ) : (
            <p className="mt-8 font-semibold text-center text-gray-800">
              No Service Available
            </p>
          )}
        </div>
      </section>

      <section
        className={classNames(
          "bg-white",
          !templateSections.includes("Reviews") === true && comments.length > 0
            ? ""
            : "hidden"
        )}
      >
        <div className="container px-4 py-10 md:p-14">
          <div className="flex flex-col items-center justify-between text-center">
            <h4 className="mb-6 text-2xl font-medium text-center text-gray-800">
              What people are saying
            </h4>
          </div>

          {satisfaction.status === "loading" ? (
            <div className="max-w-[1020px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[1, 2, 3].map((index: number) => (
                <div
                  className="bg-gray-100 h-[230px] rounded-md"
                  key={index}
                ></div>
              ))}
            </div>
          ) : satisfaction.status === "error" ? (
            <span>Error: {satisfaction.error.message}</span>
          ) : (
            comments.length > 0 && (
              <div className="max-w-[1020px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {comments.slice(0, 3).map((comment: any, index: number) => (
                  <div
                    key={index}
                    className="min-w-sm py-3 sm:px-6 space-y-2.5 col-span-1"
                  >
                    <p className="text-sm text-center text-gray-800 font-medium">
                      {comment.customer === "Anonymous"
                        ? "Anonymous"
                        : maskEmail(comment.customer)}
                    </p>
                    <div className="">
                      <div className="max-w-[100px] mx-auto">
                        <ReviewStars
                          size={18}
                          reviews={Number(ratingsToNumber(comment.userRatings))}
                        />
                      </div>
                    </div>
                    <p className="text-gray-700 text-[13px] text-center px-2.5">
                      {comment.userReview}
                    </p>
                  </div>
                ))}
              </div>
            )
          )}
          {comments.length === 0 &&
          satisfaction.status !== "loading" &&
          !satisfaction.isRefetching ? (
            <div className="flex flex-col items-center justify-center h-full">
              <p className="text-base text-gray-600">{"No feedback yet"}</p>
            </div>
          ) : null}
        </div>
      </section>
    </Layout>
  );
};

export default WebStoreLandingPage;
