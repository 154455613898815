import { Link, NavLink } from "react-router-dom";
import { classNames } from "../../../../utils";
import { StoreModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import {
  closeMenuSVG,
  estoreArray,
  feedbackAnalysisArray,
  homeSVG,
  lensArray,
  operationsArray,
} from "./utils";
import { useState } from "react";
import Modal from "../../Modal";
import OTPInput from "../../../Auth/Forms/OTPInput";
import { FaTimes } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  confirmPasscode,
  getStoreConnectionStatus,
  updateStoreConnectionStatus,
} from "../../../../api/Menu";
import toast, { Toaster } from "react-hot-toast";
import { ImSpinner2 } from "react-icons/im";
import { FiMinusCircle } from "react-icons/fi";

const Aside2 = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const storeSlug: StoreModel = useAppSelector((state) => state.user.store);
  const platformKey = `${user.platformAccountId}%26${user.email}`;
  const [openMenu, setOpenMenu] = useState(true);
  const [showConnectStoreModal, setShowConnectStoreModal] = useState(false);
  const [showDisconnectStoreModal, setShowDisconnectStoreModal] =
    useState(false);
  const [passcode, setPasscode] = useState("");
  let estoreConnectionStatus;

  const handleChange = (value: string) => {
    // console.log(value);
    setPasscode(value);
  };

  const handleConfirmPasscode = useMutation({
    mutationFn: async (data) => {
      return await confirmPasscode(platformKey, passcode);
    },
    onSuccess: (data) => {
      // console.log(data.data);
      if (data.data.request_challenge === "failed") {
        toast.error("Wrong passcode");
      }
      if (data.data.request_challenge === "success") {
        toast.success("Correct passcode");
        setShowConnectStoreModal(false);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleUpdateStoreConnectionStatus = useMutation({
    mutationFn: async (data: any) => {
      const updateStatus = data.updateStatus;
      return await updateStoreConnectionStatus(platformKey, updateStatus);
    },
    onSuccess: (data) => {
      // console.log(data.data);
      queryClient.invalidateQueries({ queryKey: ["getStoreConnectionStatus"] });
      toast.success("updated store connection status");
      setShowDisconnectStoreModal(false);
      setShowConnectStoreModal(false);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleGetStoreConnectionStatus = useQuery<any, Error>({
    queryKey: ["getStoreConnectionStatus"],
    queryFn: async () => {
      const response = await getStoreConnectionStatus(platformKey);
      return response.data;
    },
    onSuccess: (data) => {
      // console.log(data);
      // if (data.key_state === "none") {
      //   setShowModal1(true);
      // }
    },
  });

  if (handleGetStoreConnectionStatus.data) {
    // console.log(handleGetStoreConnectionStatus.data.data)
    estoreConnectionStatus = handleGetStoreConnectionStatus.data.data;
    estoreConnectionStatus =
      Object.keys(handleGetStoreConnectionStatus.data.data).length === 0
        ? "0"
        : handleGetStoreConnectionStatus.data.data.storeConnectionStatus;
  }

  return (
    <>
      <Modal
        show={showConnectStoreModal}
        onToggle={() => {
          setShowConnectStoreModal(false);
        }}
      >
        <div className="bg-white p-6">
          <div className="mb-8 flex items-center justify-between gap-4">
            <p className="font-semibold text-gray-900">Connect online store</p>
            <button className="hidden sm:block" onClick={() => setShowConnectStoreModal(false)}>
              <FaTimes size={20} className="text-gray-500" />
            </button>
          </div>
          <div className="text-center mb-6">
            <p className="text-xs mb-1 ">You are signed in as</p>
            <div className="flex items-center justify-center">
              <div className="text-sm border border-gray-200 rounded-md flex items-center justify-between gap-6 w-full py-3.5 px-4">
                <p>{user.email}</p>
                <button className="">
                  <span className="text-blue-600 underline font-medium">
                    Sign out
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="space-y-4 text-center">
            <OTPInput label="Passcode" onChange={handleChange} />
            <button
              // disabled={passcode.length < 6}
              className="px-8 py-3.5 rounded-lg bg-blue-700 text-white text-sm w-[250px]"
              // onClick={() => {
              //   handleConfirmPasscode.mutate();
              // }}
              onClick={() => {
                handleUpdateStoreConnectionStatus.mutate({
                  updateStatus: "1",
                });
              }}
            >
              {handleUpdateStoreConnectionStatus.isLoading ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Connect"
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={showDisconnectStoreModal}
        onToggle={() => {
          setShowDisconnectStoreModal(false);
        }}
      >
        <div className="bg-white p-6">
          <div className="mb-1.5 flex items-center justify-between gap-4">
            <p className="font-semibold text-gray-900">Disconnect e-store</p>
          </div>
          <p className="text-sm text-gray-600 mb-5">
            Are you sure you want to disconnect the e-store from your dashboard?
          </p>
          <div className="flex items-center gap-4 justify-end">
            <button
              className="px-6 py-2.5 rounded-lg bg-transparent text-gray-700 border border-gray-400 text-sm"
              onClick={() => {
                setShowDisconnectStoreModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="px-6 py-2.5 rounded-lg bg-[#106BBA] text-white text-sm border border-[#106BBA]"
              onClick={() => {
                handleUpdateStoreConnectionStatus.mutate({
                  updateStatus: "0",
                });
              }}
            >
              {handleUpdateStoreConnectionStatus.isLoading ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Disconnect"
              )}
            </button>
          </div>
        </div>
      </Modal>

      <aside
        className={classNames(
          "hidden inset-0 z-40 md:flex min-h-full duration-300",
          openMenu ? "w-4/5 md:w-1/4 lg:w-[215px] 2xl:w-1/6" : "w-[70px]"
        )}
      >
        <div className="container relative flex flex-col bg-[#132C58] h-[100vh] asideScrollbar overflow-y-auto overflow-x-hidden overflow-hidden">
          <div className="sticky top-0 z-10 flex items-center w-full gap-4 pt-3 bg-[#132C58]">
            <Link to="/feedback-analysis/customer-satisfaction">
              {openMenu ? (
                <div className="">
                  <img
                    src="/media/svg/cusecho-sbrp-logo.svg"
                    alt="logo"
                    className="w-40 mx-auto"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center pb-2">
                  <img
                    src="/media/svg/logo-small.svg"
                    alt="logo"
                    className="w-[35px] mx-auto mb-2"
                  />
                </div>
              )}
            </Link>
          </div>
          <div className="flex-1 flex flex-col">
            <div
              className={classNames(
                "flex justify-end my-1 cursor-pointer sticky top-0"
              )}
              onClick={() => {
                setOpenMenu((prev) => !prev);
              }}
            >
              <div
                className={classNames(
                  "flex items-center duration-300 animate-pulse sticky top-0",
                  openMenu ? "" : "rotate-180"
                )}
              >
                {closeMenuSVG}
                {closeMenuSVG}
              </div>
            </div>
            <div className="border-b-[1px] border-[#9EBAEA] py-2">
              <NavLink
                to={"/home/features"}
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center gap-2 text-white py-2 pl-3 pr-2.5 bg-[#2960BF] rounded-lg"
                    : "flex items-center gap-2 text-white py-2 px-2.5"
                }
              >
                {homeSVG}
                <p className={classNames("text-xs", openMenu ? "" : "hidden")}>
                  Home
                </p>
              </NavLink>
            </div>

            <div className="border-b-[1px] border-[#9EBAEA] py-2">
              <p
                className={classNames(
                  "text-[#9EBAEA] mb-1.5 text-[11px] uppercase",
                  openMenu ? "" : "opacity-0"
                )}
              >
                Feedback Analysis
              </p>
              <div className="flex flex-col ">
                {feedbackAnalysisArray.map((item: any, index: number) => (
                  <NavLink
                    to={item.href}
                    className={({ isActive }) =>
                      isActive
                        ? "flex items-center gap-2 text-white py-2 pl-3 pr-2.5 bg-[#2960BF] rounded-lg"
                        : "flex items-center gap-2 text-white py-2 px-2.5"
                    }
                    key={index}
                  >
                    {item.icon}
                    <p
                      className={classNames(
                        "text-xs",
                        openMenu ? "" : "hidden"
                      )}
                    >
                      {item.title}
                    </p>
                  </NavLink>
                ))}
              </div>
            </div>

            <div className="border-b-[1px] border-[#9EBAEA] py-2">
              <p
                className={classNames(
                  "text-[#9EBAEA] mb-1.5 text-[11px] uppercase",
                  openMenu ? "" : "opacity-0"
                )}
              >
                Operations
              </p>
              <div className="flex flex-col ">
                {operationsArray.map((item: any, index: number) => (
                  <NavLink
                    to={item.href}
                    className={({ isActive }) =>
                      isActive
                        ? "flex items-center gap-2 text-white py-2 pl-3 pr-2.5 bg-[#2960BF] rounded-lg"
                        : "flex items-center gap-2 text-white py-2 px-2.5"
                    }
                    key={index}
                  >
                    {item.icon}
                    <p
                      className={classNames(
                        "text-xs",
                        openMenu ? "" : "hidden"
                      )}
                    >
                      {item.title}
                    </p>
                  </NavLink>
                ))}
              </div>
            </div>

            {estoreConnectionStatus && estoreConnectionStatus === "1" ? (
              <div className="py-2">
                <div className="flex items-center justify-between gap-2">
                  <p
                    className={classNames(
                      "text-[#9EBAEA] mb-1.5 text-[11px] uppercase",
                      openMenu ? "" : "opacity-0"
                    )}
                  >
                    ESTORE
                  </p>
                  <button
                    onClick={() => {
                      setShowDisconnectStoreModal(true);
                    }}
                  >
                    <FiMinusCircle
                      className={classNames(
                        "text-gray-100",
                        openMenu ? "" : "opacity-0"
                      )}
                    />
                  </button>
                </div>
                <div className="flex flex-col ">
                  {estoreArray.map((item: any, index: number) => (
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        isActive
                          ? "flex items-center gap-2 text-white py-2 pl-3 pr-2.5 bg-[#2960BF] rounded-lg"
                          : "flex items-center gap-2 text-white py-2 px-2.5"
                      }
                      key={index}
                    >
                      {item.icon}
                      <p
                        className={classNames(
                          "text-xs",
                          openMenu ? "" : "hidden"
                        )}
                      >
                        {item.title}
                      </p>
                    </NavLink>
                  ))}
                </div>
              </div>
            ) : null}

            {estoreConnectionStatus && estoreConnectionStatus === "0" ? (
              <div
                className={classNames(
                  "text-center text-white p-2.5 py-5 rounded-md bg-[#1A3E7C] mt-auto",
                  openMenu ? "" : "opacity-0"
                )}
              >
                <p className="text-xs font-semibold mb-2">
                  Connect Online Store
                </p>
                <p className="text-[10px] mb-5">
                  Manage your products, orders, and payment on the dashboard.
                </p>
                <div className="">
                  <button
                    className="py-2 px-2 rounded-md border border-white text-[11px]"
                    onClick={() => {
                      setShowConnectStoreModal(true);
                    }}
                  >
                    Connect store
                  </button>
                </div>
              </div>
            ) : null}

            <div className="py-2 hidden">
              <p
                className={classNames(
                  "text-[#9EBAEA] mb-1.5 text-[11px] uppercase",
                  openMenu ? "" : "opacity-0"
                )}
              >
                Lens
              </p>
              <div className="flex flex-col ">
                {lensArray.map((item: any, index: number) => (
                  <NavLink
                    to={item.href}
                    className={({ isActive }) =>
                      isActive
                        ? "flex items-center gap-2 text-white py-2 pl-3 pr-2.5 bg-[#2960BF] rounded-lg"
                        : "flex items-center gap-2 text-white py-2 px-2.5"
                    }
                    key={index}
                  >
                    {item.icon}
                    <p
                      className={classNames(
                        "text-xs",
                        openMenu ? "" : "hidden"
                      )}
                    >
                      {item.title}
                    </p>
                  </NavLink>
                ))}
              </div>
            </div>

            <div className="h-[15px]"></div>
          </div>
        </div>
      </aside>
      {/* <Toaster position="top-center" containerClassName="" gutter={200} /> */}
    </>
  );
};

export default Aside2;
