import React from "react";
import SettingsPage from ".";
import { useState } from "react";
import { classNames } from "../../../utils";
import NotificationsSettings from "./NoificationsSettings";
import ProfileSettings from "./ProfileSettings";
import BillingSettings from "./BillingSettings";
import PasswordSettings from "./PasswordSettings";
import ReferralSettings from "./ReferralSettings";

export enum SettingsButtonActive {
  GeneralSettings = "generalSettings",
  ProfileSettings = "profileSettings",
  NotificationsSettings = "notificationSettings",
  PasswordSettings = "passwordSettings",
  SubscriptionsSettings = "subscriptionsSettings",
  ReferralSettings = "referralSettings",
}

const Settings = () => {
  const [active, setActive] = useState<SettingsButtonActive>(
    SettingsButtonActive.ProfileSettings
  );

  return (
    <div className="h-full min-h-[85vh] rounded bg-white flex flex-col sm:overflow-y-auto">
      <div className="mb-3 py-2 overflow-x-auto">
       <div className="flex items-center sm:justify-normal gap-4 lg:gap-4">
       <button
          className={classNames(
            "text-sm font-semibold pb-1 md:px-1 lg:px-2 hidden",
            active === "generalSettings"
              ? "text-blue-600 border-b-4 border-blue-500"
              : "text-gray-500"
          )}
          onClick={() => {
            setActive(SettingsButtonActive.GeneralSettings);
          }}
        >
          General settings
        </button>
        <button
          className={classNames(
            "text-sm font-semibold pb-1 md:px-1 lg:px-2 border-b-4",
            active === "profileSettings"
              ? "text-blue-600  border-blue-500"
              : "text-gray-500 border-white"
          )}
          onClick={() => {
            setActive(SettingsButtonActive.ProfileSettings);
          }}
        >
          Profile <span className="hidden md:inline">settings</span>
        </button>
        <button
          className={classNames(
            "text-sm font-semibold pb-1 md:px-1 lg:px-2 border-b-4",
            active === "notificationSettings"
              ? "text-blue-600 border-blue-500"
              : "text-gray-500 border-white"
          )}
          onClick={() => {
            setActive(SettingsButtonActive.NotificationsSettings);
          }}
        >
          Configurations
        </button>
        <button
          className={classNames(
            "text-sm font-semibold pb-1 md:px-1 lg:px-2 border-b-4 whitespace-nowrap",
            active === "passwordSettings"
              ? "text-blue-600  border-blue-500"
              : "text-gray-500 border-white"
          )}
          onClick={() => {
            setActive(SettingsButtonActive.PasswordSettings);
          }}
        >
          Change password
        </button>
        <button
          className={classNames(
            "text-sm font-semibold pb-1 md:px-1 lg:px-2 border-b-4",
            active === "subscriptionsSettings"
              ? "text-blue-600 border-blue-500"
              : "text-gray-500 border-white"
          )}
          onClick={() => {
            setActive(SettingsButtonActive.SubscriptionsSettings);
          }}
        >
          Billings <span className="hidden lg:inline">& Plans</span>
        </button>
        <button
          className={classNames(
            "text-sm font-semibold pb-1 md:px-1 lg:px-2 border-b-4",
            active === "referralSettings"
              ? "text-blue-600  border-blue-500"
              : "text-gray-500 border-white"
          )}
          onClick={() => {
            setActive(SettingsButtonActive.ReferralSettings);
          }}
        >
          Referrals
        </button>
       </div>
      </div>
      <div>
        {/* {active === "generalSettings" && <NotificationsSettings /> } */}
        {active === "profileSettings" && <ProfileSettings />}
        {active === "notificationSettings" && <SettingsPage />}
        {active === "passwordSettings" && <PasswordSettings />}
        {active === "subscriptionsSettings" && <BillingSettings />}
        {active === "referralSettings" && <ReferralSettings />}
      </div>
    </div>
  );
};

export default Settings;
