import { CognitoUserPool } from "amazon-cognito-identity-js";

// const poolData = {
//   UserPoolId: "eu-west-2_6mOT0JQ8N",
//   ClientId: "41ejs9j9d9f1c419mc9p79ea19",
// };

const poolData = {
  UserPoolId: `${process.env.REACT_APP_COGNITO_USERPOOL_ID}`,
  ClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
};

const UserPool = new CognitoUserPool(poolData);
export default UserPool;

