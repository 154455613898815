import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { classNames } from "../../../utils";

interface Props {
  className?: string;
  selectorWrapper: JSX.Element;
  itemsWrapper: JSX.Element;
}

const DropDown = ({ className, selectorWrapper, itemsWrapper }: Props) => {
  return (
    <Menu as="div" className={classNames(className, "relative")}>
      <Menu.Button as="div" className="cursor-pointer">
        {selectorWrapper}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95 translate-y-10"
        enterTo="transform opacity-100 scale-100 translate-y-1"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100 translate-y-1"
        leaveTo="transform opacity-0 scale-95 translate-y-10"
      >
        <Menu.Items className="absolute right-0 mt-1.5 origin-top-right bg-white border rounded-md shadow-lg border-gray-50 shadow-gray-100 focus:outline-none">
          {itemsWrapper}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDown;
