import axios from "axios";
const BASE_URL = "https://c3nzf5vtp0.execute-api.eu-west-2.amazonaws.com/Prod/api/v1"
const BASE_URL1 = "https://t2kqqkr425.execute-api.eu-west-2.amazonaws.com/dev/api/v1/customer_ticket_history"
// https://c3nzf5vtp0.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/load_platform_tickets

export const getTicketCount = async (queryKey: any) => axios.get(`${BASE_URL}/get_ticket_count?platformKey=${queryKey}`);
export const getCustomerTicketHistory = async (data: any) => axios.post(`${BASE_URL1}`, data);
export const getAllTickets = async (payload: any) => axios.post(`${BASE_URL}/load_platform_tickets`, payload);

export const updateTicketStatus = async (payload: any) => axios.post(`${BASE_URL}/ticket_status_update`, payload);
export const updateTicketPriorityLevel = async (payload: any) => axios.post(`${BASE_URL}/ticket_priority_update`, payload);



