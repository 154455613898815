// @ts-nocheck

import axios from "axios";
import axiosInstance_Payments from "../../setup/axios/paymentSetup";

export const BASE_URL_WEBPAGE_API =
  "https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod";

export const BASE_URL_WEBPAGE_IMAGE = `${BASE_URL_WEBPAGE_API}/v1`;

export const getAllMenuCategories = async (queryKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/load_menu_categories?platformKey=${queryKey}`
  );

export const getAllCategories = async () =>
  axios.get(
    `https://3z53q7owza.execute-api.eu-west-1.amazonaws.com/Prod/api/v1/categories`
  );

export const getMenuByCategory = async (queryKey: string, category: any) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/menu_download_by_category?platformKey=${queryKey}&menuCategory=${category}`
  );

export const editProductCategory = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/edit_product_category`, payload);

export const addProductCategory = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/create_product_category`, payload);

export const deleteProductCategory = async (payload: any) =>
  axios.post(
    `${BASE_URL_WEBPAGE_API}/api/v1/delete_category_endpoint`,
    payload
  );

export const uploadSingleMenu = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/menu_item_upload`, payload);

export const getSingleItem = async (itemId: string) =>
  axios.get(`${BASE_URL_WEBPAGE_API}/api/v1/load_single_item?itemId=${itemId}`);

export const updateSingleProduct = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/product_update`, payload);

export const uploadSiteTitleAndTagLine = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/update_site_and_tagline`, payload);

export const getSiteTitleAndTagLine = async (queryKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/download_site_tagline?platformKey=${queryKey}`
  );

export const deleteSingleMenu = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/delete_menu_item`, payload);

export const getPaymentDetails = async (queryKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/get_payment_detail?platformKey=${queryKey}`
  );

export const updatePaymentDetails = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/update_payment_detail`, payload);

export const getAllItemsInCatalogue = async (
  queryKey: string,
  pageKey: string
) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/load_item_catalog?platformKey=${queryKey}&pageKey=${pageKey}`
  );

export const getServices = async (queryKey: string, slugUrl: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/service_download?platformKey=${queryKey}&slugUrl=${slugUrl}`
  );

export const uploadService = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/service_update`, payload);

export const deleteService = async (queryKey: string, serviceName: string) =>
  axios.delete(
    `${BASE_URL_WEBPAGE_API}/api/v1/delete_service?platformKey=${queryKey}&serviceName=${serviceName}`
  );

export const getOrders = async (
  queryKey: string,
  paginationKey: string,
  requestStatus: string
) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/load_orders?platformKey=${queryKey}&paginationKey=${paginationKey}&requestStatus=${requestStatus}`
  );

export const getSingleOrder = async (orderId: string) =>
  axios.get(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/load_single_order?orderId=${orderId}`
  );

// axios.get(
//   `${BASE_URL_WEBPAGE_API}/api/v1/load_orders?platformKey=${queryKey}&paginationKey=${paginationKey}&requestStatus=${requestStatus}`
// );

export const updateOrderStatus = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/update_order_status`, payload);

//https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/single_product_order_status

export const updateSingleOrderStatus = async (payload: any) =>
  axios.post(
    `${BASE_URL_WEBPAGE_API}/api/v1/single_product_order_status`,
    payload
  );

export const updatePaymentAcknowledgementStatus = async (payload: any) =>
  axios.post(
    `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/cusecho_mobile/payment_service/api/v1/switch_payment_acknowledgement`,
    payload
  );

export const updatePaymentAcknowledgementStatus1 = async (
  payload: any,
  apiKey: string
) => {
  return await fetch(
    `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/cusecho_mobile/payment_service/api/v1/switch_payment_acknowledgement`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("paymentToken")}`,
        "X-Api-Key": apiKey,
      },
      body: JSON.stringify(payload),
    }
  );
};

export const cancelSingleOrder = async (payload: any) =>
  axios.post(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/cancel_order`,
    payload
  );

export const deleteOrders = async (payload: any) =>
  axios.post(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/delete_order`,
    payload
  );

export const sessionDetails = async (payload: any) =>
  axios.post(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/cusecho_web/store_analytics/api/v1/track_website_visit`,
    payload
  );

export const archiveOrders = async (payload: any) =>
  axios.post(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/cusecho_app/order_service/api/v1/order_archive_route`,
    payload
  );

export const getTemplateSections = async (queryKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/download_template_section?platformKey=${queryKey}`
  );

export const updateTemplateSection = async (
  queryKey: string,
  sectionVar: string,
  requestType: string
) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/update_template_section?platformKey=${queryKey}&sectionVar=${sectionVar}&requestType=${requestType}`
  );

export const getOpeningHours = async (queryKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/business_hour_download?platformKey=${queryKey}`
  );

export const updateOpeningHour = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/update_opening_hour`, payload);

export const addStoreAddress = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/add_store_address`, payload);

export const getStoreAddress = async (queryKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/download_store_address?platformKey=${queryKey}`
  );

export const uploadDeliveryDetails = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/delivery_detail`, payload);

export const getDeliveryDetail = async (queryKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/download_delivery_detail?platformKey=${queryKey}`
  );

export const uploadFreeDeliveryStatus = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/free_delivery_status`, payload);

export const getFreeDeliveryStatus = async (slugUrl: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/download_free_delivery_status?slugUrl=${slugUrl}`
  );

export const uploadAboutUs = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/about_us`, payload);

export const getAboutUs = async (slugUrl: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/download_about_us?slugUrl=${slugUrl}`
  );

export const uploadStoreFooterDetails = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/upload_store_footer`, payload);

export const getStoreFooterDetails = async (queryKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/download_store_footer?platformKey=${queryKey}`
  );

export const delectS3Object = async (payload: any) =>
  axios.post(
    `${BASE_URL_WEBPAGE_API}/api/v1/s3_object_delete_operation`,
    payload
  );

export const getS3Object = async (imageIdentifier: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/v1/signed_url_for_download?s3ObjectKey=${imageIdentifier}`
  );

export const downloadStoreItems = async (
  platformKey: string,
  paginationKey: string
) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/download_store_items?platformKey=${platformKey}&paginationKey=${paginationKey}`
  );

export const downloadNotificationsCount = async (platformKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/download_notification_count?platformKey=${platformKey}`
  );

export const downloadNotifications = async (
  platformKey: string,
  paginationKey: string
) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/platform_notification_download?platformKey=${platformKey}&paginationKey=${paginationKey}`
  );

export const handleReadNotification = async (payload: any) =>
  axios.post(
    `${BASE_URL_WEBPAGE_API}/api/v1/update_notification_count`,
    payload
  );

export const handleUpdateStoreItemCount = async (payload: any) =>
  axios.post(`${BASE_URL_WEBPAGE_API}/api/v1/store_item_count_update`, payload);

export const downloadStoreItemCount = async (platformKey: string) =>
  axios.get(
    `${BASE_URL_WEBPAGE_API}/api/v1/store_item_count_download?platformKey=${platformKey}`
  );

export const downloadStoreMenuCategories = async (platformKey: string) =>
  axios.get(
    `https://7wd9577tdd.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/load_menu_categories?platformKey=${platformKey}`
  );

export const downloadPasscodeEmbedder = async (platformKey: string) =>
  axios.get(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/load_passcode_embedder?platformKey=${platformKey}`
  );

export const downloadPinEmbedder = async (platformKey: string) =>
  axios.get(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/wallet_pin_embedder?platformKey=${platformKey}`
  );

export const handleGeneratePasscode = async (payload: any) =>
  axios.post(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/generate_passcode`,
    payload
  );

export const handleGeneratePin = async (payload: any) =>
  axios.post(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/generate_transfer_pin`,
    payload
  );

export const confirmPasscode = async (platformKey: string, code: string) =>
  axios.get(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/implement_passcode_challenge?platformKey=${platformKey}`,
    {
      headers: {
        "app-token": code,
      },
    }
  );

export const confirmPin = async (platformKey: string, code: string) =>
  axios.get(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/generate_transfer_pin`,
    {
      headers: {
        "app-token": code,
      },
    }
  );

export const viewWalletPin = async (platformKey: string) =>
  axios.get(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/view_wallet_pin_str?platformKey=${platformKey}`
  );

export const viewWalletPinKey = async (platformKey: string) =>
  axios.get(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/view_wallet_pin_key?platformKey=${platformKey}`
  );

export const resetPin = async (payload: any, oldPin: string) =>
  axios.post(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/token_reset`,
    payload,
    {
      headers: {
        "app-token": oldPin,
      },
    }
  );

export const resetPasscode = async (payload: any) =>
  axios.post(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/passcode_reset_interface`,
    payload
  );

export const resetPasscode1 = async (resetId: string) =>
  axios.post(
    `https://wgopw1j76a.execute-api.eu-central-1.amazonaws.com/Prod/cusecho_mobile/token_service/api/v1/load_passcode_embedder?resetId=${resetId}&requestFlag=reset`,
    payload
  );

// export const loadTransactionsLogs = async (
//   platformKey: string,
//   apiKey: string
// ) => {
//   return fetch(
//     `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/load_transaction_logs?platformKey=${platformKey}&paginationKey=`,
//     {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("paymentToken")}`,
//         "X-Api-Key": apiKey,
//       },
//     }
//   );
// };


export const loadTransactionsLogs = async (platformKey: string, apiKey: string) =>
  axiosInstance_Payments.get(
    `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/load_transaction_logs?platformKey=${platformKey}&paginationKey=`,
    // {
    //   headers: {
    //     "X-Api-Key": apiKey,
    //   },
    // }
  );

export const loadWalletBalance = async (platformKey: string, apiKey: string) =>
  axiosInstance_Payments.get(
    `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/download_wallet_balance?platformKey=${platformKey}&paginationKey=""`,
    // {
    //   headers: {
    //     "X-Api-Key": apiKey,
    //   },
    // }
  );

export const loadPaymentDetails = async (storeKey: string, apiKey: string) =>
  axiosInstance_Payments.get(
    `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/get_payment_detail?platformKey=${storeKey}`,
    // {
    //   headers: {
    //     "X-Api-Key": apiKey,
    //   },
    // }
  );

// export const loadPaymentDetails = async (storeKey: string, apiKey: string) => {
//   return fetch(
//     `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/get_payment_detail?platformKey=${storeKey}`,
//     {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("paymentToken")}`,
//         "X-Api-Key": apiKey,
//       },
//     }
//   );
// };

export const loadSingleTransactionDetails = async (
  transactionLogId: string,
  timestamp: string,
  apiKey: string
) => {
  return fetch(
    `https://o9661qgzd9.execute-api.eu-west-2.amazonaws.com/Prod/api/v1/load_transaction_detail?transactionLogId=${transactionLogId}&timestamp=${timestamp}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("paymentToken")}`,
        "X-Api-Key": apiKey,
      },
    }
  );
};

export const getStoreConnectionStatus = async (platformKey: string) =>
  axios.get(
    `https://1zkpgiuw7l.execute-api.us-east-1.amazonaws.com/Prod/cusecho_web/store/api/v1/store_connection_status?platformKey=${platformKey}`
  );

export const updateStoreConnectionStatus = async (
  platformKey: string,
  updateValue: string
) =>
  axios.post(
    `https://1zkpgiuw7l.execute-api.us-east-1.amazonaws.com/Prod/cusecho_web/store/api/v1/store_connection_status?platformKey=${platformKey}&updateValue=${updateValue}`,
    null
  );
