// @ts-nocheck

import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { BASE_URL_WEBPAGE_IMAGE } from "../../api/Menu";
import Modal from "../../components/Dashboard/Modal";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: "eu-west-2",
});

const s3 = new AWS.S3();

const params = {
  Bucket: process.env.REACT_APP_S3_BUCKET_MENU_NAME,
  Delimiter: "",
  Prefix: "uploadedProducts/",
};

const ProductsPreviewPage = () => {
  const [listFiles, setListFiles] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [jsonLink,setJsonLink] = useState("")
  const [pD, setPD] = useState([]);
  const [pI, setPI] = useState([]);

  useEffect(() => {
    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        const y = data.Contents.filter(
          (y) => !y.Key.includes("productDetails")
        );
        const p = data.Contents.filter((y) => y.Key.includes("productDetails"));
        setListFiles(y);
        setProductDetails(p);
      }
    });
  }, []);

  const getImage = async (key) => {
    const token = localStorage.getItem("imageToken");
    const headers = { Authorization: `Bearer ${token}` };
    const productKey = key;
    const response = await fetch(
      `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_download?s3ObjectKey=${productKey}`,
      { headers }
    );
    return response.json();
  };

  const imageQuery = useQueries({
    queries: listFiles.map((user, index) => {
      return {
        queryKey: ["user", index],
        queryFn: () => getImage(user.Key),
      };
    }),
    enabled: !!listFiles.length < 1,
  });

  const handleProductDetails = useMutation({
    mutationKey: ["handleProductDetails"],
    mutationFn: async (data) => {
      const token = localStorage.getItem("imageToken");
      const headers = { Authorization: `Bearer ${token}` };
      const productKey = data.key;
      const response = await fetch(
        `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_download?s3ObjectKey=${productKey}`,
        { headers }
      );
      return response.json();
    },
    onSuccess: (data) => {
      // console.log(data[0].Image_Upload_Url.toJSON());
      // console.log(data[0].Image_Upload_Url);
      setJsonLink(data[0].Image_Upload_Url)
    },
  });

  async function listAllObjectsFromS3Bucket(bucket, prefix) {
    let isTruncated = true;
    let marker;
    const elements = [];
    while(isTruncated) {
      let params = { Bucket: bucket };
      if (prefix) params.Prefix = prefix;
      if (marker) params.Marker = marker;
      try {
        const response = await s3.listObjects(params).promise();
        response.Contents.forEach(item => {
          elements.push(item.Key);
        });
        isTruncated = response.IsTruncated;
        if (isTruncated) {
          marker = response.Contents.slice(-1)[0].Key;
        }
    } catch(error) {
        throw error;
      }
    }
    
    const d = elements.filter((y) => y.includes("productDetails"));
    setPD(d)
    const i = elements.filter((y) => !y.includes("productDetails"));
    setPI(i)
    
    // return {pD,pI};
  }

  useEffect(() => {
    listAllObjectsFromS3Bucket('cloudpactai-cusecho-menu-bucket', 'uploadedProducts/')
  },[])

  return (
    <>
      <Modal
        show={showModal}
        onToggle={() => {
          setShowModal(false);
          setJsonLink("")
        }}
      >
        <div className="relative p-5 text-center">
          <a href={jsonLink} target="_blank" rel="noreferrer" className="text-sm text-blue-600 underline">Download Product details</a>
        </div>
      </Modal>
      <div className="w-full h-[85vh] overflow-y-auto bg-white p-3">
        <div className="flex items-center gap-1 mb-1 text-sm font-semibold">
          <p>Total number of Products:</p>
          <p>{pD.length}</p>
        </div>
        <div className="flex items-center gap-1 mb-4 text-sm font-semibold">
          <p>Total number of images in bucket:</p>
          <p>{pI.length}</p>
        </div>

        <div className="grid grid-cols-4 gap-x-8 gap-y-8">
          {imageQuery.map((file: any, index: number) => {
            let t =
              file.data === undefined
                ? "/media/image/servicesBg.png"
                : file.data[0].Image_Upload_Url;
            let y = file.data === undefined ? "" : file.data[0].imageIdentifier;
            const x = file.data === undefined ? "" : y.split("/");
            const indexP = y.indexOf("productImages");
            const a = y.substring(0, indexP);

            const p = productDetails.find((f) => f.Key.includes(a));
            const k = p?.Key;
            return (
              <div
                className="space-y-0.5 mb-4 cursor-pointer"
                key={index}
                onClick={() => {
                  setShowModal(true)
                  handleProductDetails.mutate({ key: k });
                }}
              >
                <img
                  src={t}
                  alt=""
                  className="w-full h-auto bg-gray-100 shadow"
                />
                <div className="px-2 pt-1">
                  <p className="text-sm font-semibold">{x[1]}</p>
                  <p className="text-xs font-medium">{x[2]}</p>
                  <p className="text-xs">
                    {x[3] === "productImages" ? "" : x[3]}
                  </p>
                  <p className="text-xs">{x[4]?.length === 36 ? "" : x[4]}</p>
                  {/* <p className="text-xs">{p}</p> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProductsPreviewPage;
