import { AiOutlinePlusSquare } from "react-icons/ai";
import PageHeader from "../../../components/Dashboard/PageHeader";
import { base64Encode, classNames, decryptMessage, encryptMessage, formatAcctID, getGeneratedID } from "../../../utils";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { SupportedImageFormat } from "../../../enums";
import * as htmlToImage from "html-to-image";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImSpinner8 } from "react-icons/im";
import InputMessage from "../../../components/Auth/Forms/InputMessage";
import JSEncrypt from "jsencrypt";

const initialvalues = {
    platformId: "",
    email: "",
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = Yup.object().shape({
    platformId: Yup.string().required("Platform ID is required").matches(phoneRegExp, "Input is not valid").min(10, "Minimum 10 numbers"),
    // email: Yup.string()
    //     .email()
    //     .min(3, "Minimum 3 letters")
    //     .max(50, "Maximum 50 letters")
    //     .required("Email address is required"),
});

const QRGeneratorPage = () => {
    const [activeBtn, setActiveBtn] = useState<SupportedImageFormat>(SupportedImageFormat.PNG);
    const qrElement = useRef<HTMLDivElement>(HTMLDivElement.prototype);
    const [data, setData] = useState("");
    const [platformID, setPlatformID] = useState("0000000000");

    var publicKey = `-----BEGIN PUBLIC KEY-----
    MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBt6y1MqZ8Z87/dg6/5xnrw
    Qf2IyT/lHRBB0C2zUjY4sGsq8K3MsaO7SsddlGiqPxgQX7XeCm6/FQBfGRHmAC1R
    W81J52erS0GVbDVN+SdoQW4U0vWRvHpJyXUijLzqzQtzTKggFjIY0ZcADrggGAeI
    L5EHS6SDHKfpT8HhgY7XrzWA/DXvt6fwwlCTvLZk17CgZK5LHvMRDe4+KnZS+7EM
    nyDOKEzQ0E/sLq1z71HVWSNqBdVAp1vzmyngnFUexnfSnFsPS84sNzhSVlsWamyV
    7HNFgxz8jS0cc7XDVYPiX3OGAf+UAX8V2wGvl1T/yuLNjm58HIxadLcc9S4AKDtX
    AgMBAAE=
    -----END PUBLIC KEY-----`;

    var privateKey = `-----BEGIN RSA PRIVATE KEY-----
    MIIEogIBAAKCAQBt6y1MqZ8Z87/dg6/5xnrwQf2IyT/lHRBB0C2zUjY4sGsq8K3M
    saO7SsddlGiqPxgQX7XeCm6/FQBfGRHmAC1RW81J52erS0GVbDVN+SdoQW4U0vWR
    vHpJyXUijLzqzQtzTKggFjIY0ZcADrggGAeIL5EHS6SDHKfpT8HhgY7XrzWA/DXv
    t6fwwlCTvLZk17CgZK5LHvMRDe4+KnZS+7EMnyDOKEzQ0E/sLq1z71HVWSNqBdVA
    p1vzmyngnFUexnfSnFsPS84sNzhSVlsWamyV7HNFgxz8jS0cc7XDVYPiX3OGAf+U
    AX8V2wGvl1T/yuLNjm58HIxadLcc9S4AKDtXAgMBAAECggEAWnyTIxwz9MpbdYDW
    GloRlL3OeI+sAPHHE1bMeGXCazKYVpYhjXPjZOivbNMiwpwENF6GOk7+UmF4l9Ds
    khotNF/NhPxxtGrUh6y3yQPGNCZA9/We4SV2yk2FFzciwmyf83/0m82J2jKYCx0S
    6KC/epYl3wqfWKv7cq8M3ZapP9ZRhapIVjmsO10XG634KS5LhN/rz/+6s2Ok8+q6
    FsLWykeTsUw+90ModYXjcxjLRO0Tm5J50QVq4LGZKxZ7FB/W5Mh2P+TiqnxzxY+r
    zdu40ufIts8wm1EvwjqwPBq4TQzrxzRAyURIozCYUEjcxV/veEXDjyqggzJYp8s6
    vnnHGQKBgQCralcNFsBWph/M1WcERpvOiqNQrIOryZeAO1+5te+EGpBY57Zg4MjT
    +JOF8C/vHZ5BrgVbi5xAfYDXmbpPT5WC2Xp4B2rPBKiSQl65ulrFdDEx20pj3uwD
    U3UggPHP2hEbbHewPQd3nL8in7anCH2HqaLlTAIxT/r50k6C63gY5QKBgQCkKGbs
    a/QONyQBNCAbIUhm6Yn1k0cOJylUoblbCyNB8lElLJTyxKAuXLKdLq/OTuaa1x6R
    EJCSWRM6a5edZX7nWhiAQF9uOFXMC+E1TsIezIHus4Jfrb9ATtJWiRmxXefWdHvS
    xmsjJ0eJoMa7vphzrQvJ7MZMdJqatEzQQ69riwKBgAhcpqR30KgpcHGwk1btDtqz
    fqYH1GCD9AUIYHIRwv5hwVrk6sutC37zqFanUd9LRZYUpCREGjo3JRrfX+xQiI2a
    iFPkoLw6kPKZlD9hLN5m/aNd/DIFBORgpN0SDTnDfIQm9Wu+fmfCWLyap/FcA6uj
    hdPDsnOvYCHhtXzDeddVAoGARE0bJdt03EVNEcMiaKdcfJVG9Yql0dmxdKkleiAU
    N2gfIxx9jNUK4L9pAcVfGLPzdxFygmEmBf5R5cjGeJK2ToRKFXKzQiTmbdOzjH6M
    fkwQ88R0xmKAZL1m/uJkckvkHnKw4m30PdgmB7mBdrRCsj6Z3RZLG5yYhy3ll/HR
    lykCgYEAhDpi4tkGl45y9BT0jQtdqDRMtRkC5WfhgdlPxbYzR5nqvczKZIMYDWDT
    ZhYyf4bCFjxy0yTD6kNh3vfJwUiZ9LU43oMHqXSDCRl+wT5iMFgh3hBx53Jt+NFz
    Oz8P9qwxVbPc0lFLCgmvvd+AqPn1/diwgNDmzgozLlHutgF6Gl8=
    -----END RSA PRIVATE KEY-----`;

    var encryptedText = encryptMessage("Hello World", publicKey);
    var uncryptedText = decryptMessage(encryptedText.toString(), privateKey);
    
    const toggleActiveBtn = (value: SupportedImageFormat) => {
      setActiveBtn(value);
    };
  
    const handleQRCodeDownload = async () => {
  
      let dataUrl;
      
      switch (activeBtn) {
        case SupportedImageFormat.JPG:
          dataUrl = await htmlToImage.toJpeg(qrElement.current);
          break;
        case SupportedImageFormat.SVG:
          dataUrl = await htmlToImage.toSvg(qrElement.current);
          break;
        default:
          dataUrl = await htmlToImage.toPng(qrElement.current);
          break;
      }
   
      const link = document.createElement("a");
      link.download = `qr_code.${activeBtn}`;
      link.href = dataUrl;
      link.click();

      setPlatformID("0000000000");
      setData("");
    };

    const formik = useFormik({
        initialValues: initialvalues,
        // validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnMount: false,
        validateOnChange: false,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            setData("");
            setTimeout(() => {
                const newPlatformID = getGeneratedID();
                const email = "register.offline@customersecho.com";
                setPlatformID(`${newPlatformID}`);
                const queryKey = `${newPlatformID}%26${email}`;
                setData(`${window.location.host}/feedback/${base64Encode(`${newPlatformID}+${email}+${queryKey}`)}`);
                setSubmitting(false);
                resetForm({});
            }, 5000);
        },
    });

    const formik2 = useFormik({
        initialValues: initialvalues,
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnMount: false,
        validateOnChange: false,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            setData("");
            setTimeout(() => {
                const newPlatformID = values.platformId;
                const email = "register.offline@customersecho.com";
                setPlatformID(`${newPlatformID}`);
                const queryKey = `${newPlatformID}%26${email}`;
                setData(`${window.location.host}/feedback/${base64Encode(`${newPlatformID}+${email}+${queryKey}`)}`);
                setSubmitting(false);
                resetForm({});
            }, 5000);
        },
    });

    useEffect(() => {

        console.log({encryptedText});
        console.log({uncryptedText});
        
    }, [data]);
    
    return( 
        <div className="space-y-1">
            <PageHeader title="QR Generator" />
            <div className="h-[80vh] bg-white rounded-md shadow-md">
                <h3 className="font-bold text-lg text-[#111827] opacity-50 py-3 px-6 border">
                    Generate QR Code
                </h3>
                <div className="flex items-start justify-between px-4 mt-6 divide-x md:px-8 lg:px-12">
                    <div className="py-4 space-y-8">
                        <div>
                            {/* <h6 className="text-lg text-gray-800">Fill in the Business information to Re-Generate QRCode</h6> */}
                            <h6 className="text-base text-gray-800">Click the button to Re-Generate QRCode</h6>
                        </div>
                        <form action="" onSubmit={formik.handleSubmit}>
                            <div className="space-y-2">
                                <div>
                                    {/* <label className="block mb-1 text-base text-gray-400">Platform ID</label>
                                    <input type="text" {...formik.getFieldProps("platformId")} className="w-10/12 p-2 text-black border border-gray-500 rounded-md outline-none placeholder-shown:text-gray-200" placeholder="0086765397" maxLength={10} />
                                    {formik.values.platformId && formik.errors.platformId && (
                                        <InputMessage message={formik.errors.platformId} />
                                    )} */}
                                    <label className="block mb-1 text-sm text-gray-400">Platform ID</label>
                                    <h5 className="text-2xl font-semibold text-green-600">{formatAcctID(platformID)}</h5>
                                </div>
                                {/* <div>
                                    <label className="block mb-1 text-base text-gray-400">Email Address</label>
                                    <input type="text" {...formik.getFieldProps("email")} className="w-10/12 p-2 text-black border border-gray-500 rounded-md outline-none placeholder-shown:text-gray-200" placeholder="example@example.com" />
                                    {formik.values.email && formik.errors.email && (
                                        <InputMessage message={formik.errors.email} />
                                    )}
                                </div> */}
                                <div className="pt-5">
                                    <button type="submit" className="w-1/2 p-2 text-sm font-bold text-center text-white rounded-lg outline-none disabled:opacity-50 bg-primary" disabled={formik.isSubmitting}>
                                        {formik.isSubmitting ? <ImSpinner8 size={20} className="mx-auto animate-spin" /> : "Generate"}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form action="" onSubmit={formik2.handleSubmit} className="mt-10">
                            <div className="flex items-end space-x-2">
                                <div className="">
                                    <label className="block mb-1 text-sm text-gray-400">Platform ID</label>
                                    <input type="text" {...formik2.getFieldProps("platformId")} className="p-2 text-sm text-black border border-gray-500 rounded-md outline-none placeholder-shown:text-gray-200" placeholder="0086765397" maxLength={10} />
                                </div>
                                <button type="submit" className="w-1/2 p-3 text-xs font-bold text-center text-white rounded-lg outline-none disabled:opacity-50 bg-primary" disabled={formik2.isSubmitting}>
                                    {formik2.isSubmitting ? <ImSpinner8 size={16} className="mx-auto animate-spin" /> : "Re-Generate"}
                                </button> 
                            </div>
                            {formik2.values.platformId && formik2.errors.platformId && (
                                <InputMessage message={formik2.errors.platformId} />
                            )}
                        </form>
                    </div>
                    <div className="pl-10">
                        <div className="p-4 bg-white">
                            <div className="space-y-5 overflow-hidden border-2 border-gray-300 shadow-lg shadow-gray-200 rounded-3xl">
                                <div id="qrElement" ref={qrElement} className="p-2.5 bg-white">
                                    <div className="px-4 py-5 border-4 border-[#5591FF] rounded-3xl text-center space-y-2">
                                        <div className="flex flex-col items-center px-6 space-y-2.5">
                                            <div className="relative">
                                                <QRCodeSVG
                                                    id="qrCode"
                                                    value={data}
                                                    size={150}
                                                    bgColor={"#ffffff"}
                                                    fgColor={"#5591FF"}
                                                    level={"L"}
                                                    className={classNames(!data || formik.isSubmitting ? "opacity-10" : "")}
                                                />
                                                {formik.isSubmitting ? <ImSpinner8 size={32} className="absolute z-10 inset-20 animate-spin fill-primary" /> : null}
                                            </div>
                                        </div>
                                        <p className="mt-1 text-base text-center text-primary">{formatAcctID(platformID)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classNames("flex items-center justify-center gap-4 mt-10", formik.isSubmitting ? "opacity-10" : "")}>
                            <button onClick={() => toggleActiveBtn(SupportedImageFormat.PNG)} disabled={!data || formik.isSubmitting} className={classNames("px-4 py-2 text-xs rounded-lg disabled:opacity-50", activeBtn === SupportedImageFormat.PNG ? "bg-primary text-white " : "text-gray-500 bg-transparent border border-gray-400")}>
                            PNG
                            </button>
                            <button onClick={() => toggleActiveBtn(SupportedImageFormat.JPG)} disabled={!data || formik.isSubmitting} className={classNames("px-4 py-2 text-xs rounded-lg disabled:opacity-50", activeBtn === SupportedImageFormat.JPG ? "bg-primary text-white " : "text-gray-500 bg-transparent border border-gray-400")}>
                            JPG
                            </button>
                            <button onClick={() => toggleActiveBtn(SupportedImageFormat.SVG)} disabled={!data || formik.isSubmitting} className={classNames("px-4 py-2 text-xs rounded-lg disabled:opacity-50", activeBtn === SupportedImageFormat.SVG ? "bg-primary text-white " : "text-gray-500 bg-transparent border border-gray-400")}>
                            SVG
                            </button>
                        </div>
                        <div className="flex justify-center mt-8">
                            <button onClick={handleQRCodeDownload} disabled={!data || formik.isSubmitting} className="px-12 py-2 text-sm font-bold text-white rounded-lg bg-primary hover:bg-blue-700 disabled:opacity-50">
                                Download Code
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QRGeneratorPage;