import { classNames } from "../../../../utils";

interface Props {
  type?: "error" | "success";
  message: string;
}

const Alert = ({ type = "error", message }: Props) => {
  return (
    <div
      className={classNames("px-5 py-3 text-base rounded-xl my-4", 
        type === "error" ? "text-red-700 bg-red-100" : "text-green-700 bg-green-100"
      )}
      role="alert"
    >
      {message}
    </div>
  );
};

export default Alert;
