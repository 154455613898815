import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { generatePaymentToken } from "../../api/Auth";

let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

const isTokenExpired = (token: string) => {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return (decodedToken.exp as any) < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

const axiosInstance_Payments = axios.create({
  // baseURL: "https://jsonplaceholder.typicode.com",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance_Payments.interceptors.request.use(
  (request) => {
    const apiKey = store?.getState().auth.apiKey;
    // console.log(apiKey);
    const paymentToken: any = localStorage.getItem("paymentToken");
    if (isTokenExpired(paymentToken)) {
      (async () => {
        const resp = await generatePaymentToken();
        // console.log(response);
        if (resp) {
          localStorage.setItem("paymentToken", resp.access_token);
          axiosInstance_Payments.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${resp.access_token}`;
        }
      })();
    }
    if (paymentToken) {
      request.headers["Authorization"] = `Bearer ${paymentToken}`;
      request.headers["X-Api-Key"] = apiKey;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance_Payments.interceptors.response.use(
  (response) => response, // Directly return successful responses.
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.

      try {
        (async () => {
          const resp = await generatePaymentToken();
          // console.log(response);
          if (resp) {
            localStorage.setItem("paymentToken", resp.access_token);
            axiosInstance_Payments.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp.access_token}`;
            return axiosInstance_Payments(originalRequest); // Retry the original request with the new access token.
          }
        })();
      } catch (error) {
        console.log("Token refresh failed", error);
        return Promise.reject(error);
      }
      // try {
      //   const refreshToken = localStorage.getItem("refreshToken"); // Retrieve the stored refresh token.
      //   // Make a request to your auth server to refresh the token.
      //   const response = await axios.post("https://your.auth.server/refresh", {
      //     refreshToken,
      //   });
      //   const { accessToken, refreshToken: newRefreshToken } = response.data;
      //   // Store the new access and refresh tokens.
      //   localStorage.setItem("accessToken", accessToken);
      //   // localStorage.setItem("refreshToken", newRefreshToken);
      //   // Update the authorization header with the new access token.
      //   axiosInstance_Payments.defaults.headers.common[
      //     "Authorization"
      //   ] = `Bearer ${accessToken}`;
      //   return axiosInstance_Payments(originalRequest); // Retry the original request with the new access token.
      // } catch (refreshError) {
      //   // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
      //   console.error("Token refresh failed:", refreshError);
      //   localStorage.removeItem("accessToken");
      //   // localStorage.removeItem("refreshToken");
      //   // window.location.href = "/login";
      //   return Promise.reject(refreshError);
      // }
    }
    return Promise.reject(error); // For all other errors, return the error as is.
  }
);

export default axiosInstance_Payments;
