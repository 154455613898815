/* eslint-disable array-callback-return */

import { useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import { Link } from "react-router-dom";
import { IoRefresh } from "react-icons/io5";
import {
  getCustomerSatisfactionScore,
  refreshSatisfactionScore,
} from "../../../../api/Utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SkeletonLoader from "../../SkeletonLoader";
import { getPlatformRatings } from "../../../../api/Ratings";
import { PlatformRatingModel, UserProfileModel } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { ButtonActive } from "../../../../enums";
import { IoMdMore } from "react-icons/io";
import { classNames, getSvg } from "../../../../utils";

const CustomerSatisfaction = () => {
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  const [active, setActive] = useState<ButtonActive>(ButtonActive.Sentiment);
  const toggleActive = (value: ButtonActive) => {
    setActive(value);
  };

  const queryClient = useQueryClient();

  const chartRef = useRef(HTMLDivElement.prototype);

  const { status, data, error } = useQuery<any, Error>({
    queryKey: ["getCustomerSatisfactionScore"],
    queryFn: async () => {
      const payload = {
        platformKey: {
          account_reference: { S: queryKey },
        },
        pagination_key: "",
        timestamp: ["0.00", `${new Date(Date.now()).getTime() / 1000}`],
        commentSource: "NONE",
        commentLabel: "No Data",
      };

      const response = await getCustomerSatisfactionScore(payload);
      return response.data;
    },
  });

  const plRatings = useQuery<PlatformRatingModel, Error>({
    queryKey: ["getPlatformRatings"],
    queryFn: async () => {
      const response = await getPlatformRatings(`${queryKey}`);
      return response.data;
    },
  });

  const handleRefresh = useMutation({
    mutationFn: async (data) => {
      return await refreshSatisfactionScore(queryKey, "");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getCustomerSatisfactionScorePaginated"] });
      queryClient.invalidateQueries({ queryKey: ["getCustomerSatisfactionScore"] });
      queryClient.invalidateQueries({ queryKey: ["getPlatformRatings"] });
    },
  });

  const ratingsPercent = (data: number) => {
    return (data / 5) * 100;
  };

  useEffect(() => {
    if (!data || !plRatings.data) {
      return;
    }

    let chartData: any = {
      sentiments: {
        categories: [0],
      },
      ratings: {
        categories: [0],
      },
    };

    if ("Satisfaction_Score" in data) {
      chartData = {
        ...chartData,
        sentiments: {
          categories: [
            parseInt(data?.Satisfaction_Score.toString().replace("%", "")),
          ],
        },
      };
    }

    if ("average_ratings" in plRatings.data) {
      chartData = {
        ...chartData,
        ratings: {
          categories: [ratingsPercent(Number(plRatings.data?.average_ratings))],
        },
      };
    }

    const getEmoji = (data: number) => {
      let value = "emojis/frowning-face";

      if (data >= 0 && data <= 25) value = "emojis/frowning-face";
      if (data >= 25 && data <= 50) value = "emojis/slightly-smiling-face";
      if (data >= 50 && data <= 75)
        value = "emojis/beaming-face-with-smiling-eyes";
      if (data >= 75 && data <= 100)
        value = "emojis/grinning-face-with-big-eyes";

      return value;
    };

    // const satisfaction_score = (data.Satisfaction_Score) ? parseFloat(data.Satisfaction_Score.toString().replace("%", "")) : 0.00;

    let options = {
      chart: {
        type: "radialBar",
        width: "100%",
        height: 160,
        // offsetY: -20,
      },
      colors: [chartData[active].categories[0] >= 50 ? "#3DB832" : "#FF0000"],
      series: chartData[active].categories,
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radialBar: {
          startAngle: -95,
          endAngle: 95,
          hollow: {
            margin: 15,
            size: "60%",
            // image: `/media/svg/${(getEmoji(chartData[active].categories[0]))}.svg`,
            image: getSvg((getEmoji(chartData[active].categories[0]))),
            imageWidth: 25,
            imageHeight: 25,
            imageClipped: false,
            imageOffsetY: -5,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: 25,
              fontSize: "14px",
              color: "#FFFFFF",
              formatter: function (val: string) {
                return `${parseInt(val).toFixed(0)}%`;
              },
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      grid: {
        padding: {
          top: 10,
        },
      },
    };

    let chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, active, plRatings.data]);

  if (status === "loading")
    return <SkeletonLoader className="w-full h-full rounded-md" />;

  if (status === "error") return <span>Error: {error.message}</span>;

  if (plRatings.status === "loading")
    return <SkeletonLoader className="w-full h-full rounded-md" />;

  if (plRatings.status === "error")
    return <span>Error: {plRatings.error.message}</span>;

  return (
    <div className="px-2 py-2.5 rounded-md shadow-md bg-gradient-primary text-white relative h-[206px] sm:h-[100%]">
      <div className="flex items-center justify-between">
        <h4 className="mb-2 text-xs font-semibold lg:text-sm">
          Customer satisfaction
        </h4>
        <button
          onClick={() => {
            handleRefresh.mutate();
          }}
          className="p-1 px-3 text-sm text-center text-white border rounded-md"
        >
          <IoRefresh
            size={18}
            className={classNames(
              handleRefresh.status === "loading" ? "animate-spin" : ""
            )}
          />
        </button>
      </div>
      <div className="block overflow-hidden">
        <div className="mx-auto mb-1 sm:mb-2 md:mb-1.5 lg:mb-5 2xl:my-1 -mt-4 md:-mt-2.5 2xl:-mt-4.5">
          <div
            ref={chartRef}
            id="cs-chart"
            className="scale-110 2xl:scale-125"
          ></div>
        </div>
      </div>
      <div className="absolute flex items-center justify-between bottom-2.5 w-[95%]">
        <div className="inline-flex gap-1 -mb-1 text-xs border border-gray-200 rounded">
          <button
            onClick={() => toggleActive(ButtonActive.Sentiment)}
            className={
              active === ButtonActive.Sentiment
                ? "px-2 py-2 text-blue-600 bg-white rounded-r"
                : "px-2 py-2 text-white rounded-r"
            }
          >
            Sentiment
          </button>
          <button
            onClick={() => toggleActive(ButtonActive.Rating)}
            className={
              active === ButtonActive.Rating
                ? "px-2 py-2 text-blue-600 bg-white rounded-l"
                : "px-2 py-2 text-white rounded-r"
            }
          >
            Ratings
          </button>
        </div>
        <div className="-mb-1">
          <Link
            to="/feedback-analysis/customer-review"
            className="hidden w-full px-3 py-2 text-xs font-medium text-center text-gray-200 bg-transparent border border-gray-200 rounded xl:block hover:bg-white hover:text-blue-600"
          >
            Click here for more info
          </Link>
          <Link
            to="/feedback-analysis/customer-review"
            className="block w-full px-3 py-1.5 text-xs font-medium text-center text-gray-200 bg-transparent border border-gray-200 rounded xl:hidden hover:bg-white hover:text-blue-600"
          >
            <IoMdMore size={18} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CustomerSatisfaction;
