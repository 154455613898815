// @ts-nocheck

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Aside from "../Aside";
import Navbar from "../Navbar";
import SessionTimeOut from "../SessionTimeOut";
import {
  selectAuthState,
  useAppDispatch,
  useAppSelector,
} from "../../../hooks";
import { useEffect, useState } from "react";
import { useInterval } from "react-use";
import moment from "moment";
import { EXPIRE_SESSION, LOGOUT, SET_TOKEN } from "../../../redux/Auth";
import useWindowDimensions, {
  LAST_INTERACTION_TIME_KEY,
  SESSION_TIME_KEY,
  classNames,
} from "../../../utils";
import { useQueryClient } from "@tanstack/react-query";
import Modal from "../Modal";
import { REMOVE_USER, SET_USER } from "../../../redux/User";
import { RESET_UTILS } from "../../../redux/Utils";
import { Auth } from "aws-amplify";
import { StoreModel, UserProfileModel } from "../../../models";
import { generateAuthToken } from "../../../api/Auth";
import { ImSpinner3 } from "react-icons/im";
import Aside1 from "./Aside1/index";
import Aside2 from "./Aside2";
import Navbar1 from "../Navbar1";

document.addEventListener("click", (event) => {
  localStorage.setItem(LAST_INTERACTION_TIME_KEY, moment().format());
});

document.addEventListener("focus", (event) => {
  localStorage.setItem(LAST_INTERACTION_TIME_KEY, moment().format());
});

document.addEventListener("scroll", (event) => {
  localStorage.setItem(LAST_INTERACTION_TIME_KEY, moment().format());
});

export const Loader = () => {
  const [showLoadingModal, setShowLoadingModal] = useState(true);
  return (
    <>
      <Modal show={showLoadingModal} size="normal">
        <div className="relative flex flex-col p-6 ">
          <div className="flex items-center justify-center">
            <ImSpinner3
              size={40}
              className="mx-auto animate-spin fill-primary"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const DashBoardContainer = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const sessionState = useAppSelector((state) => state.auth.sessionState);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [enableBeacon, setEnableBeacon] = useState(false);
  const token: string = useAppSelector(selectAuthState).token;
  const [isLoading, setIsLoading] = useState(false);
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = user.queryKey;
  const [showSidebar, setShowSidebar] = useState(false);

  useInterval(() => {
    try {
      if (sessionState === "expired") return;

      const loginTime = moment(localStorage.getItem(SESSION_TIME_KEY));
      const currentTime = moment();

      if (currentTime.diff(loginTime, "minutes") >= 10) {
        const lastInteractionTime = moment(
          localStorage.getItem(LAST_INTERACTION_TIME_KEY)
        );

        if (currentTime.diff(lastInteractionTime, "minutes") > 6) {
          dispatch(EXPIRE_SESSION());
          return;
        }

        if (currentTime.diff(lastInteractionTime, "minutes") > 11) {
          dispatch(LOGOUT());
          dispatch(REMOVE_USER());
          dispatch(RESET_UTILS());
          queryClient.clear();
          navigate("/login", { replace: true });
          return;
        }
      }
    } catch (err) {
      dispatch(EXPIRE_SESSION());
    }
  }, 1000);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("enableWalkthroughBeacon")) {
      setShowWelcomeModal(true);
    }
  }, [localStorage.getItem("enableWalkthroughBeacon")]);

  useEffect(() => {
    if (localStorage.getItem("customerEchoNewUser") === "1") {
      setShowWelcomeModal(true);
    }
  }, []);

  const fetchToken = async () => {
    try {
      const response = await generateAuthToken();
      if (response) {
        dispatch(SET_TOKEN({ token: response.access_token }));
      }
    } catch (err) {
      console.log(err);
    }
    localStorage.setItem(SESSION_TIME_KEY, moment().format());
  };

  useEffect(() => {
    fetchToken();
  }, []);

  useEffect(() => {
    localStorage.removeItem("completeRegistration");
  }, []);

  return (
    <>
      <Modal show={showWelcomeModal} onToggle={() => {}}>
        <div className="relative flex flex-col p-6 md:py-8 md:px-10">
          <div className="mt-2 space-y-2 text-center md:mt-4">
            <h1 className="font-semibold md:text-2xl text-gray-800">Welcome to Cusecho</h1>
            <p className="text-gray-600 text-[13px]">
              An all-in-one tool that helps you manage all aspects of your
              business. Collect reviews, sell online, send messages, solve
              customer complaints, and manage your team all from one platform.
            </p>
          </div>
          <div className="flex justify-center gap-2 mt-6 md:mt-10">
            <div>
              <button
                className="px-4 py-2 font-bold text-center text-white border rounded-md w-44 md:px-6 lg:px-8 bg-[#2960BF]"
                onClick={() => {
                  setShowWelcomeModal(false);
                  // setEnableBeacon(true);
                  setEnableBeacon(false);
                  localStorage.removeItem("customerEchoNewUser");
                  localStorage.removeItem("enableWalkthroughBeacon");
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="flex flex-col h-screen" >
        <div className="relative flex flex-1 overflow-hidden" >
          <Aside2
            enableBeacon={enableBeacon}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />
          {isLoading || user.queryKey === "" ? (
            <Loader />
          ) : (
            <div className="flex flex-col flex-1 mainScrollbar overflow-y-auto">
              <Navbar1
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
              />
              <main className={classNames("flex-1", location.pathname.includes("/estore/settings") ? "bg-gray-100" : "bg-white")} >
                <div className={classNames(enableBeacon === true ? "" : "")}>
                  <div className="px-2.5 sm:px-4 shadow-inner md:px-4 lg:p-4 2xl:py-6 container shadow-gray-100">
                    <SessionTimeOut show={sessionState === "expired"} />
                    <Outlet />
                  </div>
                </div>
              </main>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashBoardContainer;
