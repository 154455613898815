import { AxiosStatic } from "axios";

export default function setupAxios(axios: AxiosStatic, store: any) {
    // axios.defaults.headers.common["Accept"] = 'application/json';
    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.interceptors.request.use(
        (config) => {
            const {
                auth: { token },
            } = store.getState()

            if (token) {

                if (config.headers) {
                    config.headers.Authorization = `Bearer ${token}`;
                }

            }
            // if (config.headers)
            //     config.headers["Access-Control-Allow-Origin"] = "*";
            return config;
        },

        (err: any) => Promise.reject(err)
    )

    axios.interceptors.response.use(
        (config) => {
            return config;
        },

        (err: any) => {
            if (err.code === "ERR_NETWORK") {
                throw new Error("Check your internet connection");
            } else if (err.code === "ERR_CANCELED") {
                throw new Error("Connection cancelled");
            }

            if(err.response) {
                // if (err.response.status === 422) {
                //     throw new Error("Validation Error");
                // }
            } else if (err.request) {
            }

            return Promise.reject(err);
        }
    )
}