import React from "react";
import { classNames } from "../../../../utils";

const BillingSettings = () => {

  return (
    <div className="h-full bg-white">
      <div className="flex gap-4 flex-col md:flex-row justify-between mb-3 font-semibold hidden" >
        <div className="text-xs">
          <p className="font-semibold">**** **** **** 8989 <span className="text-blue-600 cursor-pointer ml-6">Edit Card</span></p>
          <p className="text-gray-700 text-xs" >Your next billing date is on the 28th of august 2023</p>
        </div>

        <div className="text-xs">
          <p className="font-semibold">Growth Plan</p>
          <p className="text-gray-700 text-xs" >You’re currently on the Growth Plan subscription. <span className="text-blue-600 cursor-pointer">Change Plan</span></p>
        </div>

      </div>
      <div className="mb-3">
      <p className="text-gray-900 font-semibold">Billing History</p>
      </div>
      <div className="hidden sm:block">
      <table className="w-full overflow-auto text-xs text-left text-gray-500 border rounded-md">
        <thead className="text-gray-700 capitalize bg-gray-50">
          <tr className="text-xs text-left">
            <th scope="col" className="px-6 py-3">
              Invoice
            </th>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3">
              Phone number
            </th>
            <th scope="col" className="px-6 py-3">
              Amount
            </th>
            <th scope="col" className="px-6 py-3">
              Date
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="">
          {[1, 2, 3, 4, 5].map((index: number) => (
            <tr className={classNames("border-b text-left hidden")} key={index}>
              <td scope="row" className="px-6 py-2 ">
                <div className="">
                <p className="">Bronze Plan - Dec 2022</p>
                </div>
              </td>
              <td className="px-6 py-2 border-x">
                <p className="">Joseph Benjamin</p>
              </td>
              <td className="px-6 py-2 border-x">
                <p className="">josephb.benjamin.com</p>
              </td>
              <td className="px-6 py-2 border-r">
                <p className="">+2356909233478</p>
              </td>
              <td className="px-6 py-2 border-x">
                <p className="">$50.00</p>
              </td>
              <td className="px-6 py-2 border-x">
                <p className="">15 May 2020 8:30AM</p>
              </td>
              <td className="px-6 py-2 border-x">
                <p className="py-2 px-3 text-center text-white rounded bg-green-700">PAID</p>
              </td>
            </tr>
          ))}
          <tr className={classNames("border-b")}>
          <td colSpan={7} className="px-6 py-2 text-center border-x">No billing history available for now.</td>
        </tr>
        </tbody>
      </table>
      </div>
      <div className="sm:hidden">
      <table className="w-full overflow-auto text-xs text-left text-gray-500 border rounded-md">
        <thead className="text-gray-700 capitalize bg-gray-50">
          <tr className="text-xs text-left">
            <th scope="col" className="px-1.5 py-3">
              Invoice
            </th>
            <th scope="col" className="px-1.5 py-3">
              Amount
            </th>
            <th scope="col" className="px-1.5 py-3">
              Date
            </th>
            <th scope="col" className="px-1.5 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="text-xs hidden">
          {[1, 2, 3, 4, 5].map((index: number) => (
            <tr className={classNames("border-b text-left")} key={index}>
              <td scope="row" className="px-1.5 py-2 ">
                <div className="">
                <p className="">Dec 22</p>
                </div>
              </td>
              <td className="px-1.5 py-2 border-x">
                <p className="">$50.00</p>
              </td>
              <td className="px-1.5 py-2 border-x">
                <p className="">15/05/20 8:30AM</p>
              </td>
              <td className="px-1.5 py-2 border-x">
                <p className="py-1.5 px-2 text-center text-xs font-semibold text-white rounded bg-green-700">PAID</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default BillingSettings;
