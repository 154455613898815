import { useState } from "react";
import { BiComment, BiSearch } from "react-icons/bi";
import { classNames, getImage } from "../../../utils";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

const GroupAccessPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalObject, setModalObject] = useState({
    email: "",
    phoneNumber: "",
    tickets: 0,
    reviews: 0,
  });
  const navigate = useNavigate();

  return (
    <>
      <div className="space-y-1">
        <div className="overflow-y-hidden relative">
          <div className="w-full">
            <div className="h-full mx-auto">
              <img
                src={getImage("coming-soon-bg")}
                alt=""
                className="max-w-full mx-auto"
              />
            </div>
          </div>
          <div className="mt-10 absolute bottom-16 pl-10 text-[#132C58] ">
            <h4 className="text-6xl font-bold uppercase ">Coming soon</h4>
            <p className="mt-2 text-lg font-medium max-w-[480px]">
              We’re working hard and fast to bring you features that will help
              scale your business.
            </p>
          </div>
        </div>
      </div>
      <div className="flex bg-white rounded-md h-[85vh] p-3 space-y-5 sm:space-y-8 overflow-hidden flex-col hidden">
        <div className="flex items-center justify-between">
          <h4 className="text-lg font-bold text-black">Groups</h4>
          <Link
            to="/access-manager/groups/create"
            className="block px-5 py-2 text-xs font-semibold text-white rounded-md bg-primary"
          >
            Create team group
          </Link>
        </div>
        <div>
          <div className="relative w-full sm:w-3/5 lg:w-2/5">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                // if (e.target.value.length > 3) {
                setSearchQuery(e.target.value);
                // }
              }}
              placeholder="Search"
              className="w-full px-4 py-2 text-xs font-medium text-gray-500 border border-gray-300 rounded-md outline-none"
            />
            <BiSearch
              size={22}
              className="absolute top-1.5 right-2.5 fill-gray-500 placeholder:text-gray-400"
            />
          </div>
        </div>
        <div>
          <div className="relative flex-1 h-full overflow-y-auto overflow-x-hiddeon">
            <table className="w-full overflow-auto text-xs text-left text-gray-900 border rounded-md">
              <thead className="text-gray-800 capitalize bg-gray-50">
                <tr className="text-xs text-left capitalize">
                  <th scope="col" className="py-3 text-center sm:px-6">
                    S/N
                  </th>
                  <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                    Group Name
                  </th>
                  <th scope="col" className="px-2 py-3 sm:px-6">
                    Users
                  </th>
                  <th scope="col" className="px-2 py-3 sm:px-6">
                    Permissions
                  </th>
                  <th scope="col" className="hidden px-6 py-3 sm:table-cell">
                    Date Created
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {[1, 2, 3, 4, 5, 6].map((review: any, index: number) => (
                  <tr className={classNames("border-b")} key={index}>
                    <td scope="row" className="px-2 py-2 sm:px-6">
                      <div className="">
                        <p className="font-medium text-center whitespace-nowrap">
                          {review}
                        </p>
                      </div>
                    </td>
                    <td className="hidden px-6 py-2 border-x sm:table-cell">
                      <p className="text-xs">{"Marketing-team"}</p>
                    </td>
                    <td className="relative px-2 py-2 cursor-pointer sm:px-6 border-x group">
                      <div className="text-xs cursor-pointer">{"15"}</div>
                      <div className="absolute top-0 z-50 hidden w-40 pt-6 origin-top-right group-hover:block -left-16">
                        <div className="bg-white rounded-xl shadow-gray-200 shadow-md p-3.5 w-full space-y-2">
                          <Link
                            to="/access-manager/users/james-okonkwo"
                            className="block text-xs"
                          >
                            Stephen Joseph
                          </Link>
                          <Link
                            to="/access-manager/users/james-okonkwo"
                            className="block text-xs"
                          >
                            Joshua Ubiri
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="relative px-2 py-2 cursor-pointer sm:px-6 border-x group">
                      <div className="text-xs cursor-pointer">
                        {"edit mobile app settings, view pro..."}
                      </div>
                      <div className="absolute top-0 z-[200] hidden w-56 pt-6 origin-top-right group-hover:block">
                        <div className="bg-white rounded-xl shadow-gray-200 shadow-md p-3.5 w-full space-y-2 text-xs">
                          <p className="">Can edit products & webpage</p>
                          <p className="">Can edit orders</p>
                          <p className="">Can edit wallets</p>
                          <p className="">Can edit security</p>
                          <p className="">Can edit store information</p>
                          <p className="">Can edit delivery</p>
                          <p className="">Can edit analytics</p>
                          <p className="">Can edit payment information</p>
                          <p className="">Can edit reviews</p>
                        </div>
                      </div>
                    </td>
                    <td className="hidden px-6 py-2 border-r sm:table-cell">
                      {"13/12/2021 (2 months ago)"}
                    </td>
                    <td className="hidden px-6 py-2 border-x sm:table-cell">
                      <div className="flex items-center justify-center">
                        <BsThreeDotsVertical
                          size={20}
                          onClick={() => {
                            navigate("/access-manager/groups/marketing-team");
                            setModalObject({
                              email: `${review}`,
                              phoneNumber: `${review}`,
                              tickets: 0,
                              reviews: 0,
                            });
                            setShowModal(true);
                          }}
                          className="text-gray-600 cursor-pointer"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-2.5">
          <button
            onClick={() => {}}
            disabled={true}
            className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
          >
            prev
          </button>
          <button
            onClick={() => {}}
            disabled={false}
            className="py-1.5 text-xs w-12 font-semibold text-center text-white capitalize rounded-md bg-primary disabled:opacity-60"
          >
            next
          </button>
        </div>
      </div>
    </>
  );
};

export default GroupAccessPage;
