// @ts-nocheck

// import React, { useEffect, useRef, useState } from "react";
// import { MdClear, MdDelete, MdOutlineKeyboardBackspace } from "react-icons/md";
// import InputMessage from "../../../../../../../components/Auth/Forms/InputMessage";
// import { Input } from "../../EchoWebpage1";
// import {
//   BASE_URL_WEBPAGE_IMAGE,
//   delectS3Object,
//   getAllMenuCategories,
//   getMenuByCategory,
//   updateSingleProduct,
//   uploadSingleMenu,
// } from "../../../../../../../api/Menu";
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import { StoreModel, UserProfileModel } from "../../../../../../../models";
// import { useFormik } from "formik";
// import { useAppSelector } from "../../../../../../../hooks";

import React, { useEffect, useRef, useState, Fragment } from "react";
import * as Yup from "yup";
import { StoreModel, UserProfileModel } from "../../../../models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../hooks";
import { useFormik } from "formik";
import {
  BASE_URL_WEBPAGE_IMAGE,
  delectS3Object,
  getAllCategories,
  getAllMenuCategories,
  getSingleItem,
  updateSingleProduct,
} from "../../../../api/Menu";
import { useNavigate, useParams } from "react-router-dom";
import {
  MdChevronRight,
  MdClear,
  MdInfoOutline,
  MdOutlineChevronRight,
  MdOutlineDelete,
  MdOutlineKeyboardBackspace,
} from "react-icons/md";
import { ImSpinner2 } from "react-icons/im";
import { FiChevronRight, FiUpload } from "react-icons/fi";
import {
  AiOutlineCloudUpload,
  AiOutlineEdit,
  AiOutlinePlus,
} from "react-icons/ai";
import { classNames } from "../../../../utils";
import InputMessage from "../../../../components/Auth/Forms/InputMessage";
import { Input } from "../Home/EchoWebpage/EchoWebpage1";
// import { ImSpinner2 } from "react-icons/im";
import Compressor from "compressorjs";
// import { AiOutlineCloudUpload, AiOutlinePlus } from "react-icons/ai";
import { Combobox, Menu, Transition } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
// import { BiChevronDown } from "react-icons/bi";
// import { classNames } from "../../../../../../../utils";
// import ItemOptions from "./ItemOptions";
// import { FiChevronRight } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../../components/Dashboard/Modal";
import { IoArrowBackOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";

const itemSchema = Yup.object().shape({
  itemName: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(50, "Maximum 50 letters")
    .required("Item name is required"),
  details: Yup.string()
    .min(5, "Minimum 5 letters")
    .max(140, "Maximum 140 letters"),
  price: Yup.string().required("Price is required"),
  category: Yup.string()
    .min(3, "Minimum 3 letters")
    .max(30, "Maximum 30 letters")
    .required("Category is required"),
});

const EditProduct = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const store: StoreModel = useAppSelector((state) => state.user.store);
  const storeKey = store.name;
  const queryKey = user.queryKey;
  const platformKey = `${user.platformAccountId}&${user.email}`;
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [preview, setPreview] = useState(null);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [itemVariations, setItemVariations] = useState([]);
  const [success, setSuccess] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const productID: string = id || "";

  const [showModal1, setShowModal1] = useState(false);
  const [generalInputs, setGeneralInputs] = useState([
    { variant_type: "", children: [""] },
  ]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [step, setStep] = useState(2);
  const [inputValues, setInputValues] = useState({
    price: "",
    discountPrice: "",
    stock: 0,
    color: "",
    size: "",
  });
  // console.log(inputValues);
  const [selectedItem, setSelectedItem] = useState({});
  const [uniqueColors, setUniqueColors] = useState([]);
  const [uniqueSizes, setUniqueSizes] = useState([]);
  // console.log(uniqueColors);

  let item: any;

  const initialValues = {
    itemName: "",
    details: "",
    price: "",
    category: "",
    new_category: "",
    available_count: "",
    discountPrice: "",
    adzone_status: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: itemSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
      // console.log(values)
      setIsLoading1(true);
      // console.log(imagesArray);

      if (imagesArray.length < 1) {
        // console.log(1)
        alert("Default Image is compulsory");
        setIsLoading1(false);
        return;
      }

      const cloudfront = "https://d1ntmcvl0pjnmq.cloudfront.net";

      let updateQuery = {
        itemName: values.itemName.trim(),
        s3ImageUrl: imagesArray[0].replace(cloudfront, "/cusecho") || "",
        s3ImageUrlOne: imagesArray[1]?.replace(cloudfront, "/cusecho") || "",
        s3ImageUrlTwo: imagesArray[2]?.replace(cloudfront, "/cusecho") || "",
        s3ImageUrlThree: imagesArray[3]?.replace(cloudfront, "/cusecho") || "",
        itemPrice: values.price.toString(),
        description: values.details.trim(),
        itemCategory:
          values.new_category === "choose"
            ? values.category
            : values.new_category,
        freeDelivery: "0",
        itemSizes: [],
        itemColors: [],
        discountPrice: values.discountPrice,
        available_stock: values.available_count,
        discountPrice: values.discountPrice,
        itemVariations: itemVariations.map((variation) => {
          return { ...variation, stock: parseInt(variation.stock) };
        }),
        adzone_status: values.adzone_status,
        item_mode: "",
        publishProduct: "",
      };

      let payload = {
        productId: id,
        updateQuery: updateQuery,
        slugUrl: storeKey,
        itemOldCategory: values.category.trim(),
      };

      // console.log(payload);

      try {
        const response = await updateSingleProduct(payload);
        if (response.data.status_code === 200) {
          queryClient.invalidateQueries({ queryKey: ["getStoreItems"] });
          resetForm({});
          navigate("/estore/products?type=items");
        }
      } catch (err) {
        console.log({ err });
        setIsLoading1(false);
      }
      setIsLoading1(false);
    },
  });

  let categories = [];

  const allCategories = useQuery<any, Error>({
    queryKey: ["getAllCategories"],
    queryFn: async () => {
      const response = await getAllCategories();
      return response.data;
    },
    onSuccess: (data) => {},
  });

  if (allCategories.data) {
    categories = allCategories.data;
  }

  const loadSingleItem = useQuery<any, Error>({
    queryKey: ["getSingleItem", productID],
    queryFn: async () => {
      const response = await getSingleItem(productID);
      return response.data;
    },
    enabled: !!productID,
    onSuccess: (data) => {
      const d = data.Menu_Item;
      console.log(d);
      formik.setValues({
        itemName: d.itemName,
        details: d.description,
        price: d.itemPrice,
        category: d.itemCategory,
        new_category: d.itemCategory,
        available_count: d.available_stock,
        discountPrice: d.discountPrice,
        adzone_status: d.adzone_status,
      });

      const b = [
        d.s3ImageUrl || "",
        d.s3ImageUrlOne || "",
        d.s3ImageUrlTwo || "",
        d.s3ImageUrlThree || "",
      ];
      const c = b.filter((b) => b !== "");
      // console.log(c);
      setImagesArray(c);
      setItemVariations(
        d.itemVariations.map((i: any, index: number) => {
          return { ...i, variantId: i.variantId ? i.variantId : index };
        }) || []
      );
      const uniqueColors = new Set(
        d.itemVariations.map((variation) => variation.color).filter((x) => !!x)
      );
      // console.log(uniqueColors);

      const uniqueSizes = new Set(
        d.itemVariations.map((variation) => variation.size).filter((x) => !!x)
      );
      // console.log(uniqueSizes);

      // Convert Set to array (optional)
      const uniqueColorsList = Array.from(uniqueColors);
      const uniqueSizesList = Array.from(uniqueSizes);
      setUniqueColors(uniqueColorsList);
      setUniqueSizes(uniqueSizesList);
      setGeneralInputs(d.itemVariations || []);
    },
  });

  const imageHandler = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(null);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const handleCompressedUpload = async (file: File) => {
    setIsLoading(true);
    if (selectedImage?.size > 2072576) {
      alert("Please select an image with a size of less than or equal to 2MB");
      setSelectedImage(null);
      setIsLoading(false);
      return;
    }

    new Compressor(file, {
      quality: 0.2,
      success: (compressedResult: any) => {
        let path = "/cusecho" + storeKey + "/products/" + uuidv4();

        const token = localStorage.getItem("imageToken");
        const headers = { Authorization: `Bearer ${token}` };

        fetch(
          `${BASE_URL_WEBPAGE_IMAGE}/signed_url_for_upload?menuItemImageId=${path}`,
          {
            headers,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log(data);
            const { fields, url } =
              data.CusechoMenuItemImageId[0].Image_Upload_Url;
            const formData = new FormData();
            formData.append("key", fields.key);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append(
              "x-amz-security-token",
              fields["x-amz-security-token"]
            );
            formData.append("policy", fields.policy);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", compressedResult);

            fetch(url, {
              method: "post",
              body: formData,
            })
              .then((res) => {
                const cloudfront = "https://d1ntmcvl0pjnmq.cloudfront.net";
                const imagePreview = cloudfront + path.replace("/cusecho", "");
                setImagesArray([...imagesArray, imagePreview]);
                setImageUploadSuccess(true);
                setSelectedImage(null);
                setPreview(null);
                setTimeout(() => {
                  setImageUploadSuccess(false);
                }, 2000);
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("errorrrrrr", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("errorrrrrr", err);
          });
      },
    });
  };

  const handleDeleteS3Object = useMutation<any, Error>({
    mutationFn: async (data: any) => {
      const payload = {
        objectKey: data.objectKey,
      };
      return await delectS3Object(payload);
    },
  });

  useEffect(() => {
    if (!selectedImage) {
      setPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setPreview(objectUrl);
    // return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  console.log(uniqueColors);

  const handleAddInput = (index) => {
    // setGeneralInputs([...generalInputs, { variant_type: "", children: [""] },]);
    setGeneralInputs(
      generalInputs.map((item, i) =>
        i === index ? { ...item, children: [...item.children, ""] } : item
      )
    );
  };

  const handleAddVariant = () => {
    setGeneralInputs([...generalInputs, { variant_type: "", children: [""] }]);
  };

  const handleChange = (event, index, parentIndex) => {
    let { name, value } = event.target;
    const newArray = [...generalInputs[parentIndex].children];
    newArray[index] = value;
    // setGeneralInputs(prev => [{...prev, children: [...prev.chreild] }]);
    setGeneralInputs(
      generalInputs.map((item, index) =>
        index === parentIndex ? { ...item, children: newArray } : item
      )
    );
  };

  const handleVariantType = (value, parentIndex) => {
    setGeneralInputs(
      generalInputs.map((item, index) =>
        index === parentIndex ? { ...item, variant_type: value } : item
      )
    );
  };

  const handleDeleteInput = (childIndex, parentIndex) => {
    const newArray = [...generalInputs[parentIndex].children];
    newArray.splice(childIndex, 1);

    setGeneralInputs(
      generalInputs.map((item, index) =>
        index === parentIndex ? { ...item, children: newArray } : item
      )
    );
  };

  if (
    generalInputs.find((x) => x.variant_type === "Size") ||
    generalInputs.find((x) => x.variant_type === "Color")
  ) {
    const size = generalInputs.find((x) => x.variant_type === "Size")
      .children || [""];
    var color = generalInputs.find((x) => x.variant_type === "Color")
      ?.children || [""];

    result = size.reduce((prev, current) => {
      var list = color.map((c, index) => {
        return {
          size: current,
          color: c,
          price: "",
          discountPrice: "",
          stock: 0,
          variantId: uuidv4(),
        };
      });
      return prev.concat(list);
    }, []);
  }

  return (
    <>
      <Modal
        size="medium"
        show={showModal1}
        onToggle={() => {
          setShowModal1(false);
        }}
      >
        {/* {step === 1 && (
          <div className="relative flex flex-col p-6 space-y-3">
            <div className="flex items-center gap-3">
              <IoArrowBackOutline />
              <p className="text-sm font-semibold">Variations</p>
            </div>

            {generalInputs.map((item: any, index: number) => (
              <div key={index}>
                <form className="w-full mx-auto">
                  <label
                    htmlFor="variation"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select an option
                  </label>
                  <select
                    id="variation"
                    onChange={(e) => {
                      // setVariantType(e.target.value);
                      handleVariantType(e.target.value, index);
                      // setGeneralInputs(prev => [{...prev, variant_type: e.target.value }]);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  >
                    <option selected>Select variant type</option>
                    <option value="Size">Size</option>
                    <option value="Color">Color</option>
                  </select>
                </form>

                {item.children.map((child, childIndex) => (
                  <div className="mt-2.5" key={childIndex}>
                    <div className="w-full relative">
                      <input
                        name="variant_attribute"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        type="text"
                        value={child}
                        onChange={(event) =>
                          handleChange(event, childIndex, index)
                        }
                      />
                      <button
                        className="absolute inset-y-0 end-0 flex items-center px-3 cursor-pointer"
                        onClick={() => handleDeleteInput(childIndex, index)}
                      >
                        <MdOutlineDelete />
                      </button>
                    </div>
                  </div>
                ))}

                <div
                  className="flex items-center gap-2 font-light cursor-pointer mt-3"
                  onClick={() => handleAddInput(index)}
                >
                  <FaPlus /> <p className="text-sm">Add new variant</p>
                </div>
              </div>
            ))}

            <div className="flex items-center gap-8 w-full mt-12">
              <button
                className="text-sm p-2.5 border border-blue-500 rounded-md text-blue-500 w-1/2"
                // onClick={() => setGeneralInputs((prev) => [...prev, inputs])}
                onClick={() => handleAddVariant()}
              >
                New variant type
              </button>
              <button
                className="text-white text-sm p-2.5 border bg-blue-500 border-blue-500 rounded-md w-1/2"
                onClick={() => {
                  // setGeneralInputs(prev => [...prev, ...inputs])
                  setStep(2);
                }}
              >
                Next
              </button>
            </div>
          </div>
        )} */}

        {step === 1 && (
          <div className="relative flex flex-col p-6 space-y-3">
            <div className="flex items-center gap-3">
              <p className="text-sm font-semibold">Variations</p>
            </div>

            {generalInputs.map((item: any, index: number) => (
              <div key={index}>
                <form className="w-full mx-auto">
                  <label
                    htmlFor="variation"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select an option
                  </label>
                  <select
                    id="variation"
                    onChange={(e) => {
                      // setVariantType(e.target.value);
                      handleVariantType(e.target.value, index);
                      // setGeneralInputs(prev => [{...prev, variant_type: e.target.value }]);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  >
                    <option selected>Select variant type</option>
                    <option value="Size">Size</option>
                    <option value="Color">Color</option>
                  </select>
                </form>

                {item.children.map((child, childIndex) => (
                  <div className="mt-2.5" key={childIndex}>
                    <div className="w-full relative">
                      <input
                        name="variant_attribute"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        type="text"
                        value={child}
                        onChange={(event) =>
                          handleChange(event, childIndex, index)
                        }
                      />
                      <button
                        className="absolute inset-y-0 end-0 flex items-center px-3 cursor-pointer"
                        onClick={() => handleDeleteInput(childIndex, index)}
                      >
                        <MdOutlineDelete />
                      </button>
                    </div>
                  </div>
                ))}

                <div
                  className="flex items-center gap-2 font-light cursor-pointer mt-3"
                  onClick={() => handleAddInput(index)}
                >
                  <FaPlus /> <p className="text-sm">Add new variant</p>
                </div>
              </div>
            ))}

            <div className="flex items-center gap-8 w-full mt-12">
              <button
                className="text-sm p-2.5 border border-blue-500 rounded-md text-blue-500 w-1/2"
                // onClick={() => setGeneralInputs((prev) => [...prev, inputs])}
                onClick={() => handleAddVariant()}
              >
                New variant type
              </button>
              <button
                className="text-white text-sm p-2.5 border bg-blue-500 border-blue-500 rounded-md w-1/2"
                onClick={() => {
                  // const hasMatch = generalInputs.some(
                  //   (item) =>
                  //     item.variant_type === "size" ||
                  //     item.variant_type === "color" || !item.children.includes('')
                  // );
                  // if (!hasMatch) {
                  //   console.log(hasMatch2);

                  // }
                  setItemVariations(result);
                  setStep(2);
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="relative flex flex-col p-6 space-y-3">
            <div className="flex items-center justify-between">
              <div
                className="flex items-center gap-3"
                // onClick={() => setShowModal1(false)}
              >
                {/* <IoArrowBackOutline /> */}
                <p className="text-sm font-semibold">Variant option</p>
              </div>
              <AiOutlineEdit
                size={20}
                className="cursor-pointer hidden"
                onClick={() => {
                  setStep(1);
                }}
              />
              {deleteMode ? (
                <p
                  className="text-sm font-semibold cursor-pointer"
                  onClick={() => setDeleteMode(false)}
                >
                  Done
                </p>
              ) : (
                <div className="flex items-center gap-2 ml-auto">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="w-full text-blue-800 mt-1">
                        <IoMdMore />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-1 w-36 origin-top-right p-1 border border-gray-200 shadow-md rounded-md bg-white z-10">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-slate-100 font-semibold"
                                    : "text-gray-900 font-medium"
                                } w-full rounded-md px-4 py-2 text-xs text-left`}
                                onClick={() => {
                                  setDeleteMode(false);
                                }}
                              >
                                Edit variants
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${
                                  active
                                    ? "bg-slate-100 font-semibold"
                                    : "text-gray-900 font-medium"
                                } w-full rounded-md px-4 py-2 text-xs text-left`}
                                onClick={() => {
                                  setDeleteMode(true);
                                }}
                              >
                                Delete variants
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
            <div className="space-y-2">
              {itemVariations.map((item: any, index: number) => (
                <div
                  className={classNames(
                    "flex items-center justify-between p-3 text-sm rounded-md border border-gray-200",
                    deleteMode ? "" : "cursor-pointer"
                  )}
                  key={index}
                  onClick={() => {
                    if (!deleteMode) {
                      setSelectedItem(item);
                      setInputValues({
                        price: item.price || 0,
                        discountPrice: item.discountPrice || 0,
                        stock: item.stock || 0,
                        color: item.color || "",
                        size: item.size || "",
                      });
                      setStep(3);
                    }
                  }}
                >
                  <div className="space-y-1.5" key={index}>
                    <p className="font-semibold">
                      {item.size === "null" ? "" : item.size},
                      {item.color === "null" ? "" : item.color}
                    </p>
                    <p className="inline-flex items-center gap-2">
                      Price: {item.price}{" "}
                      <span className="line-through">{item.discountPrice}</span>
                    </p>
                    <p>Stock: {item?.stock}</p>
                  </div>
                  {deleteMode ? (
                    <MdOutlineDelete
                      size={22}
                      className="cursor-pointer text-red-600"
                      onClick={() => {
                        // let arr = generalInputs.filter(
                        //   (item, index) => item == generalInputs[index]
                        // );
                        const newVariations = itemVariations.filter(
                          (variation) => variation.variantId !== item.variantId
                        );

                        setItemVariations(newVariations);
                      }}
                    />
                  ) : (
                    <MdChevronRight
                      size={22}
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedItem(item);
                        setInputValues({
                          price: item.price || "",
                          discountPrice: item.discountPrice || "",
                          stock: item.stock || 0,
                          color: item.color || "",
                          size: item.size || "",
                        });
                        setStep(3);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="flex items-center justify-center">
              <button
                className="p-2.5 rounded-md bg-blue-700 text-white w-[200px] text-sm"
                onClick={() => setShowModal1(false)}
              >
                Save
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="relative flex flex-col p-6 space-y-3">
            <div className="flex items-center justify-between">
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={() => {
                  setStep(2);
                  setSelectedItem({});
                }}
              >
                <IoArrowBackOutline />
                <p className="text-sm font-semibold">
                  {selectedItem.size}, {selectedItem.color}
                </p>
              </div>
            </div>
            <div className="space-y-4">
              <div className="space-y-2">
                <div className="text-sm">
                  <div className="space-y-2.5">
                    <div className="hidden">
                      <label htmlFor="" className="text-xs">
                        Size
                      </label>
                      <input
                        type="text"
                        value={inputValues.size}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            size: e.target.value,
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                    <div className="hidden">
                      <label htmlFor="" className="text-xs">
                        Color
                      </label>
                      <input
                        type="text"
                        value={inputValues.color}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            color: e.target.value,
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                    <div>
                      <label htmlFor="" className="text-xs">
                        Price
                      </label>
                      <input
                        type="number"
                        value={inputValues.price}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            price: e.target.value,
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className="text-xs inline-flex items-center gap-2"
                      >
                        Compare-at price <MdInfoOutline />
                      </label>
                      <p className="text-xs py-3 px-2.5 bg-gray-100 rounded mb-2 max-w-[350px]">
                        Compare-at price will substitute ‘Price’ as the main
                        price of this item.
                      </p>
                      <input
                        type="number"
                        value={inputValues.discountPrice}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            discountPrice: e.target.value,
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                    <div>
                      <label htmlFor="" className="text-xs">
                        Stock
                      </label>
                      <input
                        type="number"
                        value={inputValues.stock}
                        onChange={(e) => {
                          setInputValues((prev) => ({
                            ...prev,
                            stock: Number(e.target.value),
                          }));
                        }}
                        className="p-2.5 w-full border border-gray-200 rounded outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="p-2.5 rounded-md bg-blue-700 text-white w-[200px] text-sm"
                onClick={() => {
                  const indexToReplace = itemVariations.findIndex(
                    (variant) => variant.variantId === selectedItem.variantId
                  );

                  if (indexToReplace !== -1) {
                    const updatedItemVariationsSplice = [...itemVariations];
                    updatedItemVariationsSplice.splice(indexToReplace, 1, {
                      ...itemVariations[indexToReplace],
                      color: inputValues.color,
                      size: inputValues.size,
                      price: inputValues.price,
                      discountPrice: inputValues.discountPrice,
                      stock: inputValues.stock,
                      variantId: selectedItem.variantId,
                    });

                    // console.log(updatedItemVariationsSplice);
                    setItemVariations(updatedItemVariationsSplice);
                    // setGeneralInputs(updatedItemVariationsSplice);
                  }

                  setSelectedItem({});

                  setInputValues({
                    price: "",
                    discountPrice: "",
                    stock: 0,
                    color: "",
                    size: "",
                  });
                  setStep(2);
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </Modal>
      <div className="p-3">
        <div
          className="flex items-center gap-2 mb-3 cursor-pointer"
          onClick={() => navigate("/estore/products?type=items")}
        >
          <MdOutlineKeyboardBackspace className="" />
          <p className="text-sm font-semibold">Back</p>
        </div>

        <form onSubmit={formik.handleSubmit} className="max-w-[750px] mx-auto">
          <div className="space-y-3">
            <div className="w-full">
              <Input
                formik={formik}
                name="itemName"
                label="Item Name"
                type="text"
                placeHolder="Please enter item name"
              />
              {formik.errors.itemName && (
                <InputMessage message={formik.errors.itemName} />
              )}
            </div>
            <div className="w-full">
              <label className="block mb-1 text-xs font-normal text-gray-800">
                Description
              </label>
              <textarea
                id=""
                name="details"
                value={formik.values.details}
                onChange={formik.handleChange}
                className="h-[100px] text-base border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                placeholder="Please enter details of item"
              ></textarea>
              {formik.errors.details && (
                <InputMessage message={formik.errors.details} />
              )}
            </div>
            <div className="w-full">
              <Input
                formik={formik}
                name="price"
                label="Price"
                type="number"
                placeHolder="Please enter price"
              />
              {formik.errors.price && (
                <InputMessage message={formik.errors.price} />
              )}
            </div>

            <div className="w-full">
              <Input
                formik={formik}
                name="discountPrice"
                label="Discount"
                type="number"
                placeHolder="Please enter product's discounted price"
              />
              {formik.errors.discountPrice && (
                <InputMessage message={formik.errors.discountPrice} />
              )}
            </div>

            <div>
              <label className="block mb-1 text-xs font-normal text-gray-800">
                Image
              </label>
              <div className="flex items-center gap-10 ">
                {imagesArray.map((imagePreview: string, index: number) => (
                  <div className="w-[150px] h-[100px] relative" key={index}>
                    <img
                      src={imagePreview}
                      alt=""
                      className="object-contain w-full h-full overflow-clip"
                    />
                    <div
                      className={classNames(
                        "absolute z-10 top-0 -right-4 m-auto font-semibold w-8 h-8 bg-red-400 rounded-full flex items-center justify-center cursor-pointer",
                        selectedImage ? "hidden" : ""
                      )}
                      onClick={() => {
                        setImagesArray((prevState) =>
                          prevState.filter((i) => i !== imagePreview)
                        );
                      }}
                    >
                      <span className="text-[13px] text-white">
                        <MdClear size={22} />
                      </span>
                    </div>
                  </div>
                ))}
                {!selectedImage || !preview ? (
                  <div
                    className={classNames(
                      "w-full",
                      imagesArray.length > 3 ? "hidden" : ""
                    )}
                  >
                    <label
                      htmlFor="dropzone-file"
                      className="w-[80px] h-[80px] rounded-md bg-gray-200 flex items-center justify-center cursor-pointer"
                    >
                      <FiUpload size={22} className="text-gray-700" />
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        accept="image/jpeg, image/png, image/jpg"
                        onChange={imageHandler}
                      />
                    </label>
                  </div>
                ) : null}
                {!selectedImage || !preview ? null : (
                  <div className="flex items-center gap-10">
                    <img
                      src={preview}
                      alt=""
                      className="w-[120px] h-[100px] object-contain"
                    />
                    <div>
                      <div className="flex flex-col items-center gap-2">
                        <button
                          type="button"
                          className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-red-700 bg-transparent border border-red-700 rounded-md"
                          onClick={(e) => {
                            setSelectedImage(null);
                            setPreview(null);
                            setImageUploadSuccess(false);
                          }}
                        >
                          <span>Cancel</span>
                          <span>
                            <MdClear />
                          </span>
                        </button>
                        <button
                          type="button"
                          className="flex items-center gap-2 px-2 py-1.5 text-xs font-semibold text-white border rounded-md bg-blue-700 hover:bg-blue-800 border-primary"
                          onClick={() => {
                            handleCompressedUpload(selectedImage);
                          }}
                        >
                          <span>Upload</span>{" "}
                          {isLoading ? (
                            <ImSpinner2
                              size={20}
                              className="mx-auto animate-spin"
                            />
                          ) : (
                            <AiOutlineCloudUpload />
                          )}
                        </button>
                      </div>
                      {imageUploadSuccess && (
                        <p className="mt-2 text-xs text-center text-green-500">
                          Upload successful
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full">
              <Input
                formik={formik}
                name="available_count"
                label="Available Stock"
                type="number"
                placeHolder="Please enter available stock"
              />
              {formik.errors.available_count && (
                <InputMessage message={formik.errors.available_count} />
              )}
            </div>

            <div className="w-full">
              <label
                htmlFor="category"
                className="block mb-1 text-xs font-normal text-gray-800"
              >
                Category
              </label>
              <select
                name="category"
                id="category"
                onChange={(e) => {
                  formik.setValues({
                    ...formik.values,
                    new_category: e.target.value,
                  });
                }}
                className="text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
              >
                <option
                  value="choose"
                  className="text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                >
                  Choose a category
                </option>
                {categories.map((category: string, index: number) => (
                  <option
                    key={category.id}
                    value={category.categoryName}
                    className="text-sm border border-gray-300 py-2 px-3 bg-white rounded-md w-full outline-none text-gray-900 placeholder:text-xs"
                  >
                    {category.categoryName}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <div
                className="w-full p-2.5 border border-gray-200 rounded flex items-center justify-between cursor-pointer"
                onClick={() => {
                  if (itemVariations.length < 1) {
                    setStep(1);
                    setShowModal1(true);
                  } else {
                    setShowModal1(true);
                  }
                }}
              >
                <p className="text-sm text-gray-800">Manage variation</p>
                <MdOutlineChevronRight size={20} className="" />
              </div>
              {itemVariations.length > 0 && (
                <div
                  className="mt-4 border border-gray-300 rounded p-4 cursor-pointer text-sm "
                  onClick={() => setShowModal1(true)}
                >
                  <div className="flex items-center gap-4">
                    {uniqueColors.map((i: any, index: number) => (
                      <p
                        className={classNames(
                          i.color === "null" ? "hidden" : "",
                          i.color === null ? "hidden" : "",
                          i.color === "" ? "hidden" : "",
                          "border border-gray-300 px-2 py-1.5 rounded"
                        )}
                        key={index}
                      >
                        {i}
                      </p>
                    ))}
                  </div>
                  <br />
                  <div className="flex items-center gap-4">
                    {uniqueSizes.map((i: any, index: number) => (
                      <p
                        className={classNames(
                          i === "null" ? "hidden" : "",
                          i === "" ? "hidden" : "",
                          "border border-gray-300 px-2 py-1.5 rounded"
                        )}
                        key={index}
                      >
                        {i}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <button
              type="submit"
              // disabled={formik.isSubmitting}
              className="text-xs w-[200px] py-2.5 px-2.5 bg-blue-600 hover:bg-blue-700 rounded text-white"
            >
              {formik.isSubmitting ? (
                <ImSpinner2 size={20} className="mx-auto animate-spin" />
              ) : (
                "Save Item"
              )}
            </button>
          </div>
          <p className="mt-1 text-center text-green-500 capitalize">
            {success && <small>Saved successfully!</small>}
          </p>
        </form>
      </div>
    </>
  );
};

export default EditProduct;
