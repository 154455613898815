import { BiChevronLeft, BiChevronUp } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { classNames, getRelativeTime } from "../../../utils";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UserProfileModel } from "../../../models";
import { useAppSelector } from "../../../hooks";
import { getOrders, updateOrderStatus } from "../../../api/Menu";
import { ImSpinner3 } from "react-icons/im";
import { FiFilter } from "react-icons/fi";

const OrderStatus = [
  { status: "Pending" },
  { status: "In Progress" },
  { status: "Completed" },
  { status: "Cancelled" },
];

const OrderStatusFilter = [
  { status: "Completed" },
  { status: "Cancelled" },
  { status: "All" },
];


const OrdersHistoryPage = () => {
  const queryClient = useQueryClient();
  const user: UserProfileModel = useAppSelector((state) => state.user.profile);
  const queryKey = `${user.queryKey}`;
  const navigate = useNavigate();
  const [open, setOpen] = useState(-1);
  const [openStatus, setOpenStatus] = useState(-1);
  const [showFilter, setShowFilter] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const allOrders = useQuery<any, Error>({
    queryKey: ["getOrders"],
    queryFn: async () => {
      const paginationKey = "";
      const response = await getOrders(queryKey, paginationKey, "Pending");
      return response.data;
    },
    onSuccess: (data: any) => {
      const completedOrders = data.Order_Catalog.filter(
        (c: any) => c.orderStatus === "Completed" || c.orderStatus === "Cancelled"
      );
      console.log(data.Order_Catalog)
      setOrders(completedOrders);
      setFilteredOrders(completedOrders);
    },
  });

  const handleOrderStatusUpdate = useMutation({
    mutationFn: async (data: any) => {
      const payload = {
        orderId: data.orderId,
        orderStatus: data.orderStatus,
      };

      return await updateOrderStatus(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getOrders"]);
      setOpenStatus(-1);
    },
  });

  // if (allOrders.data) {
  //   const completedOrders = allOrders.data.Order_Catalog.filter(
  //     (c) => c.orderStatus === "Completed" || c.orderStatus === "Cancelled"
  //   );
  //   orders = completedOrders;
  // }

  useEffect(() => { }, [filteredOrders]);
  return (
    <>
      <div className="flex items-center gap-3 py-1 mb-3 md:hidden">
        <div
          className="p-2 text-gray-800 bg-white rounded cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <BiChevronLeft />
        </div>
        <h4 className="text-xl text-gray-400 capitalize">Back</h4>
      </div>
      <div className="bg-white h-[85vh] p-3 relative rounded-md overflow-y-auto">
        <p className="text-lg font-semibold text-left uppercase md:text-center mb-7">
          Orders History
        </p>
        <div className="">
          {allOrders.status === "loading" || handleOrderStatusUpdate.isLoading ? (
            <div className="flex items-center justify-start py-5">
              <ImSpinner3
                size={18}
                className="mx-auto animate-spin fill-primary"
              />
            </div>
          ) : allOrders.status === "error" ? (
            <p className="mt-8 font-semibold text-center text-gray-400">
              {allOrders.error.message}
            </p>
          ) : filteredOrders.length > 0 ? (
            <div className="space-y-2.5">
              {filteredOrders.map((order: any, index: number) => (
                <div className="p-2 border rounded" key={order.orderId}>
                  <div className="flex gap-2 text-xs md:items-center sm:gap-4">
                    <BiChevronUp
                      size={25}
                      className={classNames(
                        "cursor-pointer",
                        open === order.orderId ? "rotate-0" : "rotate-180"
                      )}
                      onClick={() => {
                        if (open === order.orderId) {
                          setOpen(-1);
                          return;
                        }
                        setOpen(order.orderId);
                      }}
                    />
                    <div className="flex flex-col gap-0.5 md:flex-row md:items-center md:gap-4">
                      <p className="flex flex-col md:flex-row" >
                        <span className="hidden md:inline">Name:&nbsp;</span>
                        <span className="font-semibold">
                          {order.billingDetail === null ||
                            order.billingDetail === undefined
                            ? "No Name"
                            : order?.billingDetail.fullName}
                        </span>
                      </p>
                      <p className="flex flex-col md:flex-row">
                        <span className="hidden md:inline">Order Number:&nbsp;</span>
                        <span className="font-semibold">{order.orderNumber}</span>
                      </p>
                      <p className="flex">
                        <span className="hidden sm:inline">&#8226;&nbsp;&nbsp;</span>
                        <span className="text-[#57575]">{getRelativeTime(order.creationTimestamp)}</span>
                      </p>
                    </div>
                    <div className="relative ml-auto">
                      <button
                        className={classNames(
                          "rounded-md text-xs font-semibold w-[100px] py-2 px-3 flex items-center gap-4 border",
                          order.orderStatus === "Pending"
                            ? "bg-white text-[#4066B5] border-[#4066B5]"
                            : order.orderStatus === "In Progress"
                              ? "bg-[#F7E2B0] text-[#9e813b] border-[#9e813b] "
                              : order.orderStatus === "Completed"
                                ? "bg-[#C4E8A9] text-[#35561D] border-[#35561D]"
                                : "bg-[#F58A98] text-[#810011] border-[#810011]"
                        )}
                      >
                        <span>{order.orderStatus}</span>
                      </button>
                    </div>
                  </div>
                  {open === order.orderId && (
                    <>
                      <div className="mt-5 mb-2 border border-gray-400 rounded">
                        <div className="grid grid-cols-7 overflow-x-auto w-full border-b-[1px] border-gray-400 font-semibold">
                          <div className="flex col-span-6">
                            <p className="hidden sm:block w-[50px] text-[10px] md:text-xs text-center p-1.5">
                              S/N
                            </p>
                            <p className="text-[10px] md:text-xs w-full p-1.5 pl-4 border-r-[1px] sm:border-x-[1px] border-gray-400">
                              ITEMS
                            </p>
                          </div>
                          <p className="text-[10px] md:text-xs col-span-1 p-1.5 text-center">
                            QTY
                          </p>
                        </div>
                        {order.orderPayload.map((orderItem: any, index: any) => (
                          <div
                            className="grid grid-cols-7 overflow-x-auto w-full border-b-[1px] border-gray-400 last:border-b-0"
                            key={orderItem.itemId}
                          >
                            <div className="flex col-span-6 text-xs">
                              <p className="hidden sm:block w-[50px] text-center p-1.5">
                                {index + 1}
                              </p>
                              <p className="w-full p-1.5 pl-4 border-r-[1px] sm:border-x-[1px] border-gray-400 flex items-center font-medium gap-3">
                                <span className="">
                                  {orderItem.itemName.replace(/_/g, " ")}
                                </span>
                                <span className={classNames(orderItem.itemSize === "" || orderItem.itemSize === undefined ? "hidden" : "")} >
                                  -&nbsp;&nbsp;{orderItem.itemSize}
                                </span>
                                <span className={classNames(orderItem.itemSize === "" || orderItem.itemSize === undefined ? "hidden" : "")} >
                                  -&nbsp;&nbsp;{orderItem.itemColor}
                                </span>
                              </p>
                            </div>
                            <p className=" col-span-1 p-1.5 text-center">
                              {orderItem.quantityOrder}
                            </p>
                          </div>
                        ))}
                      </div>
                      {order.billingDetail === null ||
                        order.billingDetail === undefined ? (
                        <div className="space-y-1 text-xs">
                          <p className="flex items-center gap-0.5">
                            <span className="text-gray-800">
                              Delivery Option:{" "}
                            </span>
                            <span className="font-medium">Pickup</span>
                          </p>
                        </div>
                      ) : (
                        <div className="space-y-1 text-xs">
                          <p className="flex items-center gap-0.5">
                            <span className="text-gray-800">Delivery Option: </span>
                            <span className="font-medium">Drop Off</span>
                          </p>
                          <div className="flex flex-col gap-1 md:items-center md:flex-row md:gap-16">
                            <p className="flex items-center gap-0.5">
                              <span className="text-gray-800">Phone: </span>
                              <span className="font-medium">
                                {order.billingDetail.phone}
                              </span>
                            </p>
                            <p className="flex items-center gap-0.5">
                              <span className="text-gray-800">Email: </span>
                              <span className="font-medium">
                                {order.billingDetail.email}
                              </span>
                            </p>
                            {order.billingDetail.address === undefined ? null : (<p className="flex gap-0.5">
                              <span className="text-gray-800">Address: </span>
                              <span className="font-medium">
                                {order.billingDetail.address}
                              </span>
                            </p>)}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          ) : orders.length === 0 && !allOrders.isLoading && !allOrders.isRefetching ? (
            <p className="mt-8 text-xs font-semibold text-center text-gray-800">
              No orders history
            </p>
          ) : null}
        </div>
        <button
          className="text-gray-800 font-semibold py-1.5 px-3 hidden md:flex items-center gap-1.5 rounded absolute top-2 left-3"
          onClick={() => navigate(-1)}
        >
          <BiChevronLeft size={22} />
          <span>Orders</span>
        </button>
        {orders.length > 0 && (
          <div className="absolute top-2 right-3">
            <button
              className="text-gray-800 font-semibold py-2 px-4 flex items-center gap-2.5 rounded-md border"
              onClick={() => setShowFilter((prev) => !prev)}
            >
              <FiFilter size={20} />
              <span>Filter</span>
            </button>
            {showFilter && (
              <div className="py-2 px-1 absolute border top-10 right-0 z-10 w-[150px] bg-white rounded-md">
                {OrderStatusFilter.map((status: any, index: number) => (
                  <p
                    className="px-3 py-3 text-xs font-medium rounded-md cursor-pointer hover:bg-slate-100 hover:font-semibold"
                    onClick={() => {
                      if (status.status === "Completed") {
                        const completedOrders = orders.filter(
                          (c: any) => c.orderStatus === "Completed"
                        );
                        setFilteredOrders(completedOrders);
                        setShowFilter(false);
                        return;
                      }
                      if (status.status === "Cancelled") {
                        const cancelledOrders = orders.filter(
                          (c: any) => c.orderStatus === "Cancelled"
                        );
                        setFilteredOrders(cancelledOrders);
                        setShowFilter(false);
                        return;
                      }
                      if (status.status === "All") {
                        setFilteredOrders(orders);
                        setShowFilter(false);
                        return;
                      }
                    }}
                    key={index}
                  >
                    {status.status}
                  </p>
                ))}
              </div>
            )}
          </div>
        )}
      </div></>
  );
};

export default OrdersHistoryPage;
